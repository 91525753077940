<!-- Empty Cart -->
<ng-container *ngIf="cartObj?.items?.length < 1 && !isLoading">
    <div class="row" style="margin-top: 80px">
        <div class="col-12 d-flex flex-column justify-content-center align-items-center mb-4 mt-4">
            <i style="font-size: xxx-large" class="fa fa-shopping-cart" aria-hidden="true"></i>
            <h1 class="text-center" style="font-weight: 500" i18n>
                Your cart is currently <span style="color: red">empty!</span>
            </h1>
            <span i18n>Looks like you haven't added anything to your cart yet.</span>
            <!-- <span>Must add items to the cart before you proceed to check out.</span> -->
            <div>
                <!-- <button class="btn btn-primary">Browse by Catalog</button>
                    <button class="btn btn-primary">Search by Date Range</button> -->
            </div>
        </div>
    </div>

    <!-- <app-logo-transition></app-logo-transition> -->
</ng-container>

<!-- Loader -->
<ng-container *ngIf="isLoading">
    <div style="height: 400px;">
        <div style="position: absolute; left: 50%; bottom:50%; z-index: 3;">
            <div class="spinner-border" role="status" style="width: 3em !important; height: 3em !important">
                <span class="visually-hidden" i18n>Loading...</span>
            </div>
        </div>
    </div>
</ng-container>

<!-- Cart View -->
<ng-container *ngIf="!isLoading">
    <div *ngIf="cartObj?.items?.length > 0">
        <div class="container-fluid d-flex justify-content-between">
            <div>
                <h1 class="pe-1 projTitle" i18n>Cart</h1>
            </div>
            <div>
                <button (click)="manageCartOperations(CartOperation.ClearCart)" type="button" class="btn btn-danger" i18n-tooltip tooltip="Clear Cart">
                    <span id="button-text"><i class="fas fa-trash-alt"></i></span>
                </button>
            </div>
        </div>
        <form #cartForm="ngForm">
            <div class="row">
                <div class="col-md-9">
                    <!-- Cart Items -->
                    <div class="">
                        <div class="row ps-3 pb-3 pe-3"
                            *ngFor="let prod of cartObj?.items; let index; index as cartItemIndex; let i = index">
                            <div class="col-md-2 shadow-sm bg-light rounded-start cardimg border-end">
                                <div class="styleimgthumb justify-content-center align-items-center">
                                    <img [src]="!!productGroupMap[prod.parentId]?.groupImage ? productGroupMap[prod.parentId]?.groupImage : '/assets/images/notAvailable.png' "
                                        class="imginsidediv" alt="..." />
                                </div>
                            </div>
                            <div class="col-md-10 shadow-sm bg-light rounded-end">
                                <div class="row pt-1">
                                    <div class="col-12">
                                        <span *ngIf="prod.cartItemOutdated" class="badge bg-warning">
                                            <span i18n>Outdated Item</span> <i class="fas fa-clock"></i>
                                        </span>
                                        <span *ngIf="prod.unavailable" class="ms-1 badge bg-danger">
                                            <span i18n>Unavailable</span> <i class="fas fa-times-circle"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="row textcardtitle pt-1">
                                    <div class="col-8 text-left">
                                        {{ productGroupMap[prod.parentId]?.groupName }}
                                        -
                                        {{ prod.productSize }}
                                    </div>
                                    <div class="col-4 text-right">
                                        $<span>{{prod['daySpan'] | getIndividualRentalItemPrice :
                                            prod['selectedHours']
                                            :
                                            this.productGroupMap[prod['parentId']]}}</span>
                                    </div>
                                </div>
                                <!--addons-->
                                <div *ngIf="prod.totalCountAddons > 0" class="row textcardtitle pt-1">
                                    <div class="accordion-item bg-light" style="padding-left: 0; padding-right: 0;">
                                        <h2 class="accordion-header bg-light" id="panelsStayOpenAddons-heading{{i}}">
                                            <button class="accordion-button collapsed bg-light" type="button"
                                                data-bs-toggle="collapse"
                                                [attr.data-bs-target]="'#panelsStayOpenAddons-collapse' + i"
                                                aria-expanded="false" aria-controls="panelsStayOpenAddons-collapseTwo"
                                                style="padding-left: 10px; padding-right: 10px;">
                                                <span i18n>Add-ons</span>
                                                <span
                                                    class="ms-1 me-1 badge bg-secondary">{{prod.totalCountAddons}}</span>
                                                <b><span>{{prod.addonTotalwoProduct | currency}}</span></b>
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpenAddons-collapse{{i}}"
                                            class="accordion-collapse collapse bg-light"
                                            aria-labelledby="panelsStayOpenAddons-headingTwo">
                                            <div class="accordion-body">
                                                <div class="row">
                                                    <div class="col-12 text-left bg-darklight rounded addontext">
                                                        <ng-container
                                                            *ngFor="let widget of prod.widgetList; index as widgetIndex;">
                                                            <ng-container
                                                                *ngIf="widget.widgetType == WidgetType.quantity && widget?.element?.inputValue > 0">
                                                                • <b>{{widget?.element?.label}}:</b>
                                                                {{widget?.element?.inputValue}}
                                                            </ng-container>
                                                            <ng-container *ngIf="widget.widgetType == WidgetType.dropdown">
                                                                • <b>{{widget.element?.label}}</b>
                                                                {{widget?.selectedAddon.text}}
                                                            </ng-container>
                                                            <ng-container *ngIf="widget.widgetType == WidgetType.radios">
                                                                • <b>{{widget.element?.label}}</b>
                                                                {{widget?.selectedAddon.text}}
                                                            </ng-container>
                                                            <ng-container *ngIf="widget.widgetType == WidgetType.checkbox">
                                                                •<b>{{widget?.element?.label}}</b>
                                                                <span *ngIf="widget?.element?.inputValue; else widgetBlockNo" i18n>Yes</span>
                                                                <ng-template #widgetBlockNo i18n>No</ng-template>
                                                            </ng-container>
                                                            <ng-container *ngIf="widget.widgetType == WidgetType.price">
                                                                • {{widget?.element?.label}}
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="widget.widgetType == WidgetType.heightInput && (widget?.element?.feetInput > 0 || widget?.element?.inchInput > 0)">
                                                                • <b>{{widget?.element?.label}}:</b>
                                                                {{widget?.element?.feetInput == null ? 0 :
                                                                widget?.element?.feetInput}}ft
                                                                {{widget?.element?.inchInput == null ? 0 :
                                                                widget?.element?.inchInput}}in
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="widget.widgetType == WidgetType.weightInput && widget?.element?.inputValue > 0">
                                                                • <b>{{widget?.element?.label}}:</b>
                                                                {{widget?.element?.inputValue == null ? 0 :
                                                                widget?.element?.inputValue}}lbs
                                                            </ng-container>
                                                            <ng-container *ngIf="widget.widgetType == WidgetType.tip">
                                                                • {{widget?.element?.label}}
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--products attached-->
                                <div *ngIf="prod.totalCountProducts > 0" class="row textcardtitle bg-light pt-2">
                                    <div class="accordion-item bg-light" style="padding-left: 0; padding-right: 0;">
                                        <h2 class="accordion-header bg-light" id="panelsStayOpen-heading{{i}}">
                                            <button class="accordion-button collapsed bg-light" type="button"
                                                data-bs-toggle="collapse"
                                                [attr.data-bs-target]="'#panelsStayOpen-collapse' + i"
                                                aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo"
                                                style="padding-left: 10px; padding-right: 10px;">
                                                 <span i18n>Attached Products</span>
                                                <span
                                                    class="ms-1 me-1 badge bg-secondary">{{prod.totalCountProducts}}</span>
                                                <b><span>{{prod.addonTotalWProduct | currency}}</span></b>
                                                <span class="text-danger" style="margin-left: 3px;" *ngIf="prod?.unavailableWidget"> <i
                                                    class="fas fa-exclamation-circle"></i> </span>
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse{{i}}"
                                            class="accordion-collapse collapse bg-light"
                                            aria-labelledby="panelsStayOpen-headingTwo">
                                            <div class="accordion-body">
                                                <ng-container
                                                    *ngFor="let widget of prod.widgetProduct; let index = index;">
                                                    <ng-container>
                                                        <ng-container
                                                            *ngIf="widget.widgetType == 'product' && widget.element.isDropdown == true">
                                                            <ng-container *ngIf="widget.selectedAddon != undefined">
                                                                <div class="row mt-1">
                                                                    <div class="col-8 text-left">
                                                                        {{widget?.element?.name}} -
                                                                        {{widget?.selectedAddon?.size}}
                                                                    </div>
                                                                    <div class="col-4 text-right">
                                                                        {{widget?.selectedAddon?.price | currency}}
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="widget.widgetType == 'product' && widget.element.isDropdown == false">
                                                            <ng-container
                                                                *ngFor="let product of widget.element.options">
                                                                <ng-container *ngIf="product.inputValue > 0">
                                                                    <div class="row mt-1">
                                                                        <div class="col-8 text-left" [ngStyle]="{ 'color': widget?.unavailable ? 'red' : 'black'}">
                                                                            {{widget?.element?.name}} -
                                                                            {{product?.size}}
                                                                            <span
                                                                                class="badge bg-secondary">{{product?.inputValue}}
                                                                            </span>
                                                                            <!-- <span class="text-danger" style="margin-left: 3px;" *ngIf="widget?.unavailable"> <i
                                                                                class="fas fa-exclamation-circle"></i>
                                                                            </span> -->
                                                                        </div>
                                                                        <div class="col-4 text-right">
                                                                            {{(widget?.element?.price *
                                                                            product?.inputValue) | currency}}
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row pt-3 pb-2">
                                    <div class="col-12 text-right align-items-end">
                                        <button (click)="removeFromCart(prod.cartItemID)"
                                            class="btn btn-sm btn-danger me-1">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                        <button class="btn btn-sm btn-primary" [disabled]="prod.unavailable || prod.cartItemOutdated " (click)="editCartItem(i)" i18n>
                                            Edit Item
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ps-3 pt-2 pb-3 pe-3"
                        *ngIf="this.groupInventoryPage['widgetList'].length > 0 && !cartWidgetsLoading">
                        <h1 i18n>Cart Addons</h1>
                        <div class="col-md-12 shadow-sm rounded-start border-end bg-light" style="padding:0px;"
                            [style.pointer-events]="pmtLinkRentalDoc ? 'none' : 'auto'">
                            <app-inventory-page-view [groupInventoryPage]="groupInventoryPage" [companyID]="companyID"
                                [cartWidgets]="true" (widgetInputChanged)="manageCartOperations(CartOperation.CartWidgetInput)"
                                (productInputChanged)="manageCartOperations(CartOperation.CartProductWidgetInput)">
                            </app-inventory-page-view>
                        </div>
                    </div>
                    <div *ngIf="cartWidgetsLoading">
                        <div class="spinner-border" role="status" style="width: 3em !important; height: 3em !important">
                            <span class="visually-hidden" i18n>Loading...</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 ps-3 pb-3 pe-3">
                    <div class="container-fluid rounded bg-light">
                        <div class="row pt-3">
                            <div class="col-12 text-right">
                                <ng-container *ngIf="cartObj?.isCustomCart || pmtLinkRentalDoc">
                                    <span style="background-color: #bfd5e5;" class="badge rounded-pill bg-warning text-dark mr-1 hoverrow" [delay]="100"
                                    i18n-tooltip tooltip="This cart was created custom for you! Permissons and prices may be altered" i18n>Custom Cart</span>
                                </ng-container>
                                <span class="textcardtitle lightgray" i18n>Information</span>
                            </div>
                        </div>
                        <div class="row pt-3">
                            <ul class="datelist">
                                <li>{{cartObj.items[0].dayStart | luxonDisplayPipe: 'DATETIME_MED' :
                                    locationMap[cartObj.items[0]?.locationID].timezone }} <b class="dotlist"> •</b></li>
                                <li class="location" i18n>Pickup at {{locationMap[cartObj.items[0].locationID]?.name}} </li>
                                <li>{{cartObj.items[0].dayEnd | luxonDisplayPipe: 'DATETIME_MED' :
                                    locationMap[cartObj.items[0]?.locationID].timezone }} <b class="dotlist"> •</b></li>
                                <li class="location" i18n>Dropoff at {{locationMap[cartObj.items[0].locationID]?.name}} </li>
                            </ul>
                        </div>
                        <hr>
                        <!-- Comments -->
                        <div class="row text-right align-items-end pb-2">
                            <div class="col-12 offset-12 mb-2">
                                <label for="additionalComents"><b i18n>Additional Comments</b><i
                                        class="fas fa-question-circle hoverrow text-info asdf" [delay]="100"
                                        i18n-tooltip tooltip="Send additional comments and concerns to {{companyName}}"></i></label>
                                <div class="input-group" [style.pointer-events]="pmtLinkRentalDoc ? 'none' : 'auto'">
                                    <!-- [(ngModel) ]="additionalComments" -->
                                    <textarea (input)="manageCartOperations(CartOperation.CommentInput)" [(ngModel)]="cartObj.additionalComments"
                                        class="form-control" name="additionalComments" id="additionalComments" rows="2"
                                        i18n-placeholder placeholder="Type here...." value="cartObj?.additionalComments"></textarea>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="container-fluid rounded bg-light mt-2 pb-2">
                        <div class="row pt-3">
                            <div class="col-12 text-right">
                                <span class="textcardtitle lightgray" i18n>Total Breakdown</span>
                            </div>
                        </div>

                        <!-- Promo Code -->
                        <!-- <div class="row"> -->
                        <!-- Discount Code Section -->
                        <div class="row pt-3 text-right align-items-end">
                            <div class="col-12 offset-12 mb-2">
                                <label for="discountCode">
                                    <b i18n>Enter a promotional code</b>
                                    <i class="fas fa-question-circle hoverrow text-info asdf" [delay]="100"
                                    i18n-tooltip tooltip="If you have a promotional code, submit it below to have your discount applied!"></i>
                                </label>

                                <div *ngIf="cartObj.promoApplied">
                                    <span class="mr-1" style="font-weight: 500;" i18n>Promo code successfully applied!</span>
                                    <span id="tryAnotherCode" style="color: #33628b; font-weight: 500;"
                                        (click)="manageCartOperations(CartOperation.ClearDiscount)" i18n>Clear
                                        code?</span>
                                </div>

                                <div>
                                    <span *ngIf="showInvalidPromoCodeMsg && !cartObj.promoApplied"
                                        style="color: red; font-weight: 500;"><i
                                            class="fas fa-exclamation-circle mr-1"></i>
                                            <span i18n>This code is invalid or has expired.</span>
                                    </span>
                                </div>

                                <div class="input-group">
                                    <!-- [(ngModel)]="discountCode" -->
                                    <!-- [ngClass]="{ redBorder: isDiscountCodeAlert }" -->
                                    <!-- [disabled]="discount !== undefined && discount !== null" -->

                                    <!-- [(ngModel)]="cartObj.promocode" -->
                                    <input (keyup)="showInvalidPromoCodeMsg = false"
                                        [ngClass]="showInvalidPromoCodeMsg && !cartObj.promoApplied ? 'errorValidation': ''"
                                        class="form-control form-control-sm" [disabled]="cartObj.promoApplied"
                                        [(ngModel)]="cartObj.promocode" type="text" name="discountCode"
                                        [style.pointer-events]="pmtLinkRentalDoc ? 'none' : 'auto'"
                                        i18n-placeholder placeholder="Promo Code..." id="discountCodeInput" />
                                    <!--(focus)="openEmailModal()"-->

                                    <!-- [ngClass]="{'disabled': discount !== undefined && discount !== null}" -->
                                    <!-- (click)="checkDiscountCode()" -->
                                    <div class="input-group-append">
                                        <button type="button" (click)="manageCartOperations(CartOperation.ValidateDiscount)"
                                            [ngClass]="showInvalidPromoCodeMsg && !cartObj.promoApplied ? 'errorValidation': ''"
                                            class="btn btn-sm input-group-text promotionalButton"
                                            [disabled]="cartObj.promoApplied" id="basic-addon2" i18n>
                                            Apply Code
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- </div> -->
                        <hr>
                        <!-- Currency Totals -->
                        <div class="row pt-2">
                            <div class="col-12 offset-12 mb-2 text-right">
                                <div class="d-flex flex-column" *ngIf="!isLoadingPrice">

                                    <span>
                                        <b i18n>Subtotal: {{total | currency}}</b>
                                    </span>

                                    <span *ngIf="promoCodeDiscountTotal"><b class="badge bg-warning text-black" i18n>Promo
                                        Code
                                        Discount:
                                        -{{promoCodeDiscountTotal | currency }}</b>
                                    </span>

                                    <span>
                                        <b i18n>Taxes & Fees: +{{taxes | currency}}</b>
                                    </span>

                                    <span class="mt-3" style="color: #1b1e1dee!important; font-size: 20px;">
                                        <b>
                                           <span i18n>Total:</span>
                                             <span [ngClass]="{'reserved': isReservationPayment}">
                                                {{ cost | currency }}
                                            </span>
                                        </b>
                                    </span>

                                    <span *ngIf="isReservationPayment" class="mt-1" style="color: #1b1e1dee!important; font-size: 18px;">
                                        <b i18n>Reservation: {{ pendingCost | currency }}</b>
                                    </span>

                                </div>
                                <div *ngIf="isLoadingPrice">
                                    <div class="text-center justify-content-center align-items-center" >
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden" i18n>Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Checkout Btn -->
                        <!-- <div class="row"> -->
                        <div class="row pt-2">
                            <div class="col-12 offset-12 mb-2 text-right">

                                <span style="color: #24242b;" *ngIf="!availabilityDoneProcessing" i18n>
                                    Verifying item availability..
                                </span>

                                <div class="d-grid gap-2">

                                    <ng-container *ngIf="!isLoadingPrice">
                                        <button type="button" class="btn btn-primary ml-2" (click)="manageCartOperations(CartOperation.Checkout)"
                                            [disabled]="isCheckoutButtonDisabled">

                                            <span>
                                                <span *ngIf="updatingCart" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>

                                                <ng-container *ngIf="isReservationPayment; else normalCheckout" i18n>
                                                    Reserve: {{ pendingCost | currency }}
                                                </ng-container>

                                                <ng-template #normalCheckout>
                                                    <ng-container *ngIf="!pmtLinkRentalDoc || cartObj.isSkipPayment; else completePayment" i18n>
                                                        Checkout {{ cost | currency }}
                                                    </ng-container>
                                                    <ng-template #completePayment i18n>
                                                        Complete by {{ pendingCost | currency }}
                                                    </ng-template>
                                                </ng-template>
                                            </span>

                                        </button>
                                    </ng-container>

                                    <ng-container *ngIf="pendingCost <= 0">
                                        <span class="textcardtitle lightgray">
                                            <span i18n>This rental has been paid in full.</span>
                                            <span class="link textcardtitle" (click)="manageCartOperations(CartOperation.ClearCart)">
                                                <span i18n>Clear your cart</span>
                                            </span>
                                            <span i18n>to checkout additional items.</span>
                                        </span>
                                    </ng-container>

                                    <span class="text-danger" *ngIf="hasOutdatedItems || hasUnavailableItems">
                                        <i class="fas fa-exclamation-circle"></i>
                                        <span i18n>Please remove the unavailable products to continue with checkout</span>
                                    </span>

                                    <span class="text-danger" *ngIf="!cartWidgetsValid">
                                        <i class="fas fa-exclamation-circle"></i>
                                        <span i18n>Please fill out the required cart addons</span>
                                    </span>

                                    <span class="text-danger" *ngIf="!advenireWidgetValid">
                                        <i class="fas fa-exclamation-circle"></i>
                                        <span i18n>The time is past 10am, devlivery to the Advenire Hotel is no longer available</span>
                                    </span>

                                    <span class="text-danger" *ngIf="flagUserItems.length > 0">
                                        <i class="fas fa-exclamation-circle"></i>
                                        <span i18n>Please remove the unavailable products to continue with checkout</span>
                                        <span *ngFor="let item of flagUserItems">
                                            <br>{{item}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-container>
