import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface OnNavigationLeave {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class OnNavigationLeaveService {
  canDeactivate(component: OnNavigationLeave): Observable<boolean> | Promise<boolean> | boolean {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
  constructor() { }
}
