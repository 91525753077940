import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { Subscription, take } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DateTime } from 'luxon';
import { CartService } from 'src/app/services/cart.service';
import { v4 as uuid } from 'uuid';
import { WidgetService } from 'src/app/services/widget.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { PricingService } from 'src/app/services/pricing.service';
import { InventoryPageService } from 'src/app/services/inventory-page.service';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { AvailabilityService } from 'src/app/services/availability.service';
import {
  AvailabilityInterface,
  OverlappingTimesItem,
  ProductsOrganized,
  RentalLengthType,
} from 'src/app/models/availability.model';
import { TimeslotType } from 'src/app/models/availability-timeslot.model';
import { Product } from 'src/app/models/storage/product.model';
import { ProductGroup } from 'src/app/models/storage/product-group.model'
import { InventoryPage } from 'src/app/models/storage/inventory-page.model';
import { ProductElementInterface, WidgetInterface, WidgetType } from 'src/app/models/widget.model';
import { CartItem } from 'src/app/models/cart.model';
import { ProductLocation } from 'src/app/models/storage/product-location.model';
import { FirestoreService } from 'src/app/v2/services/firestore.service';
import { Collection } from 'src/app/v2/models/collection-reference.model';
import { Cart } from 'src/app/models/storage/cart.model';
import { ProductSizeType } from 'src/app/models/storage/product-size-type.model';
import { ProductSize } from 'src/app/v2/models/product-size.model';

@Component({
  selector: 'app-inventory-page-wrapper',
  templateUrl: './inventory-page-wrapper.component.html',
  styleUrls: ['./inventory-page-wrapper.component.scss'],
  providers: []
})

export class InventoryPageWrapperComponent {

  // Inputs
  @Input() availability: AvailabilityInterface;
  @Input() cartObj: Cart;
  @Input() companyID: string;
  @Input() inventoryPageMap: { [key: string]: InventoryPage };
  @Input() isLoading: boolean;
  @Input() locationsArray: ProductLocation[];
  @Input() locationsMap: { [key: string]: ProductLocation };
  @Input() numberOfDaysChosen: number;
  @Input() productGroups: ProductGroup[];
  @Input() productGroupsMap: { [key: string]: ProductGroup };
  @Input() productsArray: Product[];
  @Input() productSizesMap: { [key: string]: ProductSize };
  @Input() productSizeTypesMap: { [key: string]: ProductSizeType };
  @Input() productsMap: { [key: string]: Product };
  @Input() templateID: string;
  @Input() widgetMap: { [key: string]: WidgetInterface };
  @Input() widgetsArray: WidgetInterface[] = [];

  // Outputs
  @Output() algoResUpdated = new EventEmitter<any>();
  @Output() cartItemEdit = new EventEmitter<any>();
  @Output() continueShopping = new EventEmitter<any>()
  @Output() itemsAddedToNewCart = new EventEmitter<any>(); // added items to a new cart

  // DatTime
  protected customerStartDate: DateTime = null;
  protected customerEndDate: DateTime = null;

  // Strings
  private cartItemID: string = null;
  private contentID: string;
  private selectedProductSizeId: string;
  protected customerLocation: string = null;
  protected errorMessage: string | null = null; // Message to display if there is an error
  protected outtaHereEbikesCustomerMessage: string | null = null;
  protected parent: string;

  // Booleans
  private addToCartClicked: boolean = false;
  private algoResAlreadyUpdated: boolean = false;
  private getAllDataComplete: boolean;
  private groupLoaded: boolean = false;
  private inventoryPageLoaded: boolean = false;
  protected editingCartItem: boolean = false;
  protected productsDropdownDisabled: boolean = true;
  protected productWidgetsMeetRequirements: boolean = true;
  protected requiredWidgetCheck: boolean = true; // Will not let the user check out if any required widgets inputs arent filled
  protected showAddons: boolean = false; // Decides if I should show addon page
  protected showDateRange: boolean = false;
  protected showProductDropdown: boolean = false; // Will show product drop down after availability is searched for
  protected showRentalPrice: boolean = false;

  // Numbers
  private minProductAvail: number;
  private oldSelectedQuantity: number = 0;
  protected addonPrice: number = 0;
  protected currentRentalPrice: number = 0; // Price of just the rental items
  protected selectedQuantity: number = 0;
  protected totalPrice: number = 0;// Price of addons and anything extra combined

  // Objects
  private cartData;
  private cartItem: CartItem | null = null;
  private cartItemInventoryPage: InventoryPage; // When editing a cart item this invenentory page will be a copy of the group inventory page but it will have some widget input values pre-popuolated
  private locationObj: ProductLocation;
  private unavailItems: { productGroupID: string, sizeID: string, quantity: number }[] = [];
  protected groupInventoryPage: InventoryPage;
  protected newAvailability: AvailabilityInterface;
  protected productGroup: ProductGroup; // the group that is being viewed on page
  protected selectedAvailableTime: OverlappingTimesItem | null = null;
  protected selectedHours = null;
  protected selectedProduct: ProductsOrganized | null = null; // Product chosen in dropdown
  protected tourMinProductsRequirementsMet: { bool: boolean, count: number };
  public newCartItem = {};
  public productsInGroupMap = {};

  // Arrays
  private productsInGroup: Product[];
  protected availabilityOverlap: OverlappingTimesItem[] = []; //gets the count for overlapping times
  protected dropdownQuantity: number[] = [];
  protected invPageArray: InventoryPage[] = [];
  protected productsOrganized: ProductsOrganized[] = []; //includes size, qty, and products that belong to size organized by objects in array
  protected timeLengthOptions: (number | string)[]  = [];

  // Subs
  private activatedRouteSub;
  private cartSubscription: Subscription;
  private subs = new Subscription(); // group of subscriptions


  constructor(private productsService: ProductsService,
              private activatedRoute: ActivatedRoute,
              private cartService: CartService,
              private widgetService: WidgetService,
              private pricingService: PricingService,
              private inventoryPageService: InventoryPageService,
              private currentUserService: CurrentUserService,
              private availabilityService: AvailabilityService,
              private router: Router,
              private firestoreService: FirestoreService
              ) { }


  async ngOnChanges(changes: SimpleChanges): Promise<void>  {
    this.errorMessage = "";

    if (changes.availability && !this.addToCartClicked) {
      if(!this.algoResAlreadyUpdated){
        if (this.getAllDataComplete) {
          this.isLoading = true;
          await this.getProductsInGroup();
          this.searchForProductAvailability();
        }
      }
      else{
        this.algoResAlreadyUpdated = false;
      }
    }

    // If there is a change to the product group you are looking at
    else if (changes.productGroups) {
      if (!_.isEqual(changes.productGroupsMap.currentValue[this.parent], changes.productGroupsMap.previousValue[this.parent])) {
        this.productGroup = changes.productGroupsMap.currentValue[this.parent];
        this.getInventoryPage();
      }
    }

    // If there are changes made to the inventory page layout the product group is attached to then get new inventory page updates
    else if(changes.inventoryPageMap){
      if (!_.isEqual(changes.inventoryPageMap.currentValue[this.productGroup['inventoryPageId']], changes.inventoryPageMap.previousValue[this.productGroup['inventoryPageId']]) && this.showAddons) {
        this.getInventoryPage();
      }
    }
  }


  ngAfterViewInit(): void {
    this.parent = this.activatedRoute.firstChild.snapshot.params.productGroupID;

    this.activatedRouteSub = this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.contentID = this.activatedRoute.snapshot.queryParamMap.get("content");
      this.customerLocation = this.activatedRoute.snapshot.queryParamMap.get("location");
      if (this.activatedRoute.snapshot.queryParamMap.get("startDate") !== null && this.activatedRoute.snapshot.queryParamMap.get("endDate") !== null) {
        this.customerStartDate = DateTime.fromISO(this.activatedRoute.snapshot.queryParamMap.get("startDate")).setZone(this.locationsMap[this.customerLocation].timezone, {keepLocalTime: true})
        this.customerEndDate = DateTime.fromISO(this.activatedRoute.snapshot.queryParamMap.get("endDate")).setZone(this.locationsMap[this.customerLocation].timezone, {keepLocalTime: true})
      }
      else {
        this.customerStartDate = null;
        this.customerEndDate = null;
      }

      if (this.activatedRoute.snapshot.queryParamMap.get("cartItemID") !== null) {
        this.cartItemID = this.activatedRoute.snapshot.queryParamMap.get("cartItemID");
        this.editingCartItem = true;
        this.cartObj.items.forEach(item => {
          if (item.cartItemID == this.cartItemID) {
            this.cartItem = item;
          }
        })
      }
    })

    console.log("cartItem editing", this.editingCartItem)
    this.handleSubs()
    return
  }


  private handleSubs(): void {
    // Gets all products that belong to a group
    this.productsService.getProductByGroupIDWithParamCompanyID(this.parent, this.companyID).then(products => {
      this.productsInGroup = products;

      //------ TRY CATCH ------//
      // Catches if the promise did not return anything, means the parent ID is most likely incorrect
      if (this.productsInGroup.length < 1) {
        this.router.navigate(['404'])
      }
      else {
        //------ TRY CATCH ------//
        // makign sure the parent ID exists in the active product group map
        if (this.productGroupsMap[this.parent]) {
          this.productGroup = this.productGroupsMap[this.parent];
          this.groupLoaded = true;
          this.getAllData();
          this.getInventoryPage();
        }
        else {
          this.router.navigate(['404'])
        }

      }
    });

  } // End of subs

  private getProductsInGroup(): void {
    // Gets all products that belong to a group
    this.productsService.getProductByGroupIDWithParamCompanyID(this.parent, this.companyID).then(products => {
      this.productsInGroup = products;

      //------ TRY CATCH ------//
      // Catches if the promise did not return anything, means the parent ID is most likely incorrect
      if (this.productsInGroup.length < 1) {
        this.router.navigate(['404'])
      }
      return
    })
  }

  private getInventoryPage(): void {
    //------ TRY CATCH ------//
    if (this.inventoryPageMap[this.productGroup['inventoryPageId']]) {
      this.groupInventoryPage = JSON.parse(JSON.stringify(this.inventoryPageMap[this.productGroup['inventoryPageId']]));
      this.inventoryPageLoaded = true;
      this.getAllData();
    }
    else {
      this.router.navigate(['404'])
    }
  }

  private async getAllData(): Promise<void>  {

    if (this.inventoryPageLoaded && this.groupLoaded) {
      const user = await this.currentUserService.getCurrentUserOnce();
      // If the user is not logged in and they are viewing an outta here ebikes booking flow
      // Or if it is a partner from another company viewing the booking suite and they are not a developer
      if (this.companyID == '9QojDFeCywSHHy4A3HNV' && !user && this.productGroup.isTour|| this.companyID == '050qsRhEKaHRBK3E7Ajc' && !user && this.productGroup.isTour
          || user && user?.levelType == 'Partner' && user.companyId != '9QojDFeCywSHHy4A3HNV' && !user.isDeveloper && this.companyID == '9QojDFeCywSHHy4A3HNV' && this.productGroup.isTour
          || user && user?.levelType == 'Partner' && user.companyId != '050qsRhEKaHRBK3E7Ajc' && !user.isDeveloper && this.companyID == '050qsRhEKaHRBK3E7Ajc' && this.productGroup.isTour) {
        this.outtaHereEbikesCustomerMessage = $localize`Please call 435-922-5151 to find out available times for this product`;
        this.showDateRange = true;
      }
      else {
        this.groupInventoryPage = await this.widgetService.getSavedWidgetsInfo(this.groupInventoryPage, this.widgetsArray);
        this.groupInventoryPage = await this.widgetService.getProductWidgetData(this.groupInventoryPage, this.productGroupsMap);
        this.groupInventoryPage = await this.widgetService.getProductOptionSizes(this.groupInventoryPage, this.productSizesMap, this.productSizeTypesMap, this.companyID)

        // Get regular widgets
        let regularWidgets = await this.widgetService.filterWidgetCartList(this.groupInventoryPage.widgetList, false)
        this.groupInventoryPage.widgetList = regularWidgets;

      this.searchForProductAvailability();

      this.getAllDataComplete = true;
      this.showDateRange = true;
      }
    }
    return
  }

  protected ngOnDestroy(): void {
    this.subs.unsubscribe(); // unsubscribe from all subscriptions in this group
    this.activatedRouteSub.unsubscribe();
  }

  private resetAllValues(): void {
    this.selectedQuantity = 0;
    this.selectedHours = null;
    this.dropdownQuantity = [];
    this.timeLengthOptions = [];
    this.availabilityOverlap = [];
    this.selectedProduct = null;
    this.selectedAvailableTime = null;
    this.currentRentalPrice = 0;
    this.showAddons = false;
    this.showRentalPrice = false;
    this.showProductDropdown = false;
    this.errorMessage = null;
    this.addToCartClicked = false;
    this.calculateTotalPrice();
    return
  }

  private async searchForProductAvailability(): Promise<void>  {

    this.errorMessage = null;
    await this.resetAllValues();
    this.isLoading = true;

    // Search in the already filtered locationsArray (only shows locationsArray with at least 1 product) and from the selected location ID, provide the location object
    this.locationsArray.forEach((location) => {
      if (location.id === this.customerLocation) {
        this.locationObj = location;
        return;
      }
    })

    if (!this.customerStartDate || !this.customerEndDate || !this.customerLocation || !this.availability) {
      this.errorMessage = $localize`Please select a location and date range above to find product's availability.`
      this.isLoading = false;
      return
    }

    // If editing a cart item the pre-populate its widget inputs
    // Change input to value customer had submitted
    if (this.editingCartItem) {
      this.cartItemInventoryPage = await this.updateWidgetInputsForCartEdit(this.groupInventoryPage, this.cartItem);
      // Reset cart item cart quantity
      this.availability.increaseCurrentAvailDecreaseCartQuantity(this.cartItem['parentId'], this.cartItem['productSizeID']);
      this.oldSelectedQuantity = 1;
    }

    // Check for errors in the availability response
    const error = this.availability.checkForErrorsInAvailabilityResponse(this.productGroup.id);

    if (error) {
      this.handleError(error);
    }
    else if (this.numberOfDaysChosen <= 0) {
      this.handleError($localize`This item is not available for the chosen time`);
    }

    this.getAvailableProductSizes();
  }

  private handleError(message: string, logMessage?: string, error?: any): void {
    if (logMessage && error) {
      console.error(logMessage, error);
    }
    else if(logMessage) {
      console.error(logMessage)
    }

    this.errorMessage = message;
    this.isLoading = false;
  }

  // Creates the products organized array, where products are separated by sizes
  // and the quantity of each size is counted
  private async getAvailableProductSizes(): Promise<void>  {
    this.showProductDropdown = true;
    this.productsOrganized = [];

    try {
      this.productsOrganized = this.availability.productsOrganizedFunction(this.parent, this.productSizeTypesMap);
    }
    catch (error) {
      this.handleError($localize`There are no products available for the selected date`, "Error in products organized function", error);
      return;
    }

    if (this.productsOrganized.length < 1) {
      this.handleError($localize`There are no products available for the selected date`, "Nothing in products organized array");
      return;
    }

    // If the url is pasted in the search bar and there are no products available, show error message instead of dropdown being greyed out
    if (!this.productsOrganized.some(product => product.isAvailable)) {
      this.handleError($localize`There are no products available for the selected date`);
      return;
    }

    // If editing from cart then make sure there is only one size in the products organized array and then auto fill
    if (this.editingCartItem) {
      this.productsOrganized = this.productsOrganized.filter(product =>
        product.sizeID === this.cartItem.productSizeID);

      // There should only be one size in the products organized array if you are editing a cart item
      if (this.productsOrganized.length > 1) {
        console.error("There are multiple sizes in the product organized array", this.productsOrganized);
      }
    }

    // Automatic dropdown fill
    if (this.productsOrganized.length == 1) {
      this.selectedProduct = this.productsOrganized[0];
      this.setSelectedSize()
    }

    console.log("PRODUCTS ORGANIZED")
    console.log(this.productsOrganized)
    this.isLoading = false;
    this.productsDropdownDisabled = false;
  }


  protected async setSelectedSize(): Promise<void>  {
    this.resetAllAddons();

    this.selectedProductSizeId = this.selectedProduct.sizeID;
    console.log("SELECTED PRODUCT")
    console.log(this.selectedProduct, this.selectedProductSizeId)

    await this.getTimeLengthOptions();
  }


  // Wil figure out the possible options of # of hours there is to choose from
  // Variables are reset everytime the function is called because it means there was a change in a
  // previous dropdown choice
  private getTimeLengthOptions(): void {
    this.resetAllAddons();
    this.selectedAvailableTime = null;
    this.timeLengthOptions = [];
    this.showAddons = false;
    this.selectedHours = null;

    try {
      this.timeLengthOptions = this.availability.getGroupSizeTimeLengths(this.parent, this.selectedProductSizeId)
      this.timeLengthOptions = this.sortTimeLengthOptions(this.timeLengthOptions);
    }
    catch (error) {
      this.handleError($localize`There are no times available for the selected product`, "Error in time lengths function", error);
      return
    }

    if (this.editingCartItem || this.timeLengthOptions.length == 1) {
      this.selectedHours = this.timeLengthOptions[0];
      this.checkCartItemSelectedHours();
      this.findOverlapTimes();
      this.updateProductWidgetPrices();
    }


    console.log("SELETCED QUANTTIYT");
    console.log(this.selectedQuantity)

    console.log("DROP DOWN HOURS")
    console.log(this.timeLengthOptions)
    return
  }

  private sortTimeLengthOptions(timeOptions: (number | string)[]): (number | string)[] {
    // Separate numbers and strings into different arrays
    const numbers: number[] = timeOptions.filter(item => typeof item === 'number') as number[];
    const strings: string[] = timeOptions.filter(item => typeof item === 'string') as string[];

    // Sort numbers in ascending order
    numbers.sort((a, b) => a - b);

    // Sort strings in alphabetical order
    strings.sort();

    // Concatenate numbers and strings arrays
    return [...numbers, ...strings];
}


  // Checks if the selected hours in the dropdown match the selected hours on the cart item
  private checkCartItemSelectedHours(): void {
    if (this.editingCartItem) {
      if (this.cartItem.selectedHours != this.selectedHours) {
        this.selectedHours = this.cartItem.selectedHours;
        console.error("The selected hours on the cart item do not match the selected hours in the dropdown")
      }
    }
  }


  // Gets all possible times for a rental of a specific product and size for all quantities
  // The count variable will represent the quantity of vehicles available for a certain time
  // Adds the 'count' variable to object and includes the product Ids that are tied to the count
  protected async findOverlapTimes(): Promise<void> {
    this.resetAllAddons();
    this.selectedQuantity = 0;
    this.selectedAvailableTime = null;

    let timeslotType: TimeslotType = this.timeslotTypeBasedOnselectedHours(this.selectedHours);

    try {
      // Helps avoid issues where selectedHours is a string sometimes, the availability class is expecting a number input
      if (typeof this.selectedHours === 'number') {
        this.availabilityOverlap = await Object.values(this.availability.getGroupSizeOverlappingTimes(this.parent, this.selectedProductSizeId, null, timeslotType, this.selectedHours));
      }
      else {
        this.availabilityOverlap = await Object.values(this.availability.getGroupSizeOverlappingTimes(this.parent, this.selectedProductSizeId, null, timeslotType));
      }
    }
    catch (error) {
      this.handleError($localize`There are no times available for the selected product`, "Error in overlapping times function", error);
      return;
    }

    if (this.availabilityOverlap.length < 1) {
      this.handleError($localize`There are no times available for the selected product`, "No overlapping times found");
      return
    }

    console.log("AVAILABILTY Overlap");
    console.log(this.availabilityOverlap)

    console.log('SELECTED HOURS');
    console.log(this.selectedHours)


    // If there is only one time available, If there are already items in the cart then there should only ever be one time available
    if (this.editingCartItem || this.cartObj?.items && this.cartObj.items.length > 0 || this.availabilityOverlap.length == 1) {
      this.selectedAvailableTime = this.availabilityOverlap[0];
      this.setQuantityDropdown();
    }
  }


  private timeslotTypeBasedOnselectedHours(selectedHours): TimeslotType {
    switch (selectedHours) {
      case RentalLengthType.AllDay:
          return TimeslotType.ShopDay;

      case RentalLengthType.TwentyFourHour:
          return TimeslotType.TwentyFourHour;

      default:
          return TimeslotType.Hourly;
    }
  }


  private sortAvailabilities(availabilityOverlap): DateTime {
    return availabilityOverlap.sort((a, b) => {
      return a.dayStart - b.dayStart;
    })
  }


  protected displaySimpleDayStartEnd(type: TimeslotType, dayStart: DateTime, dayEnd: DateTime): string {
    if (type === '24hr' || this.numberOfDaysChosen > 1) {
      return dayStart.setZone(this.locationObj.timezone).toFormat('LLL dd, t') + ' - ' + dayEnd.setZone(this.locationObj.timezone).toFormat('LLL dd, t');
    } else {
      return dayStart.setZone(this.locationObj.timezone).toLocaleString(DateTime.TIME_SIMPLE) + ' - ' + dayEnd.setZone(this.locationObj.timezone).toLocaleString(DateTime.TIME_SIMPLE);
    }
  }


  // Figures out quantity available for a certain product size
  // If function is called again the variables are reset because it
  // means there was a change made to the product drop down
  async setQuantityDropdown(): Promise<void>  {
    this.selectedQuantity = 0;
    this.resetAllAddons();

    await this.productWidgetOptionsLogic();

    console.log("SELECTED AVAIL TIMES")
    console.log(JSON.parse(JSON.stringify(this.selectedAvailableTime)))
    this.dropdownQuantity = [];


    // If there is a required product widget the minProductsAvail will be set
    if (this.minProductAvail) {
      if (this.selectedAvailableTime?.count && this.selectedAvailableTime.count <= this.minProductAvail) {
        for (let i = 1; i <= this.selectedAvailableTime.count; i ++) {
          this.dropdownQuantity.push(i)
        }

      }
      else if (this.selectedAvailableTime?.count && this.selectedAvailableTime.count > this.minProductAvail) {
        for (let i = 1; i <= this.minProductAvail; i ++) {
          this.dropdownQuantity.push(i)
        }
      }
    }
    else {
      if (this.selectedAvailableTime && this.selectedAvailableTime?.count) {
        for (let i = 1; i <= this.selectedAvailableTime.count; i ++) {
          this.dropdownQuantity.push(i)
        }
      }
    }

    this.selectedQuantity = 0;

    if(this.editingCartItem){
      this.selectedQuantity = 1;
      this.calculateIndividualRentalPrice();
      this.showWidgets();
    }

    else if(this.dropdownQuantity.length == 1){
      this.selectedQuantity = this.dropdownQuantity[0];
      this.calculateIndividualRentalPrice();
      this.showWidgets();
    }

    console.log("dropdown quant")
    console.log(this.dropdownQuantity)
  }


  //// -------------- Widget and Cart Logic --------------- ////

  resetAllAddons(): void {
    this.showAddons = false;
    this.oldSelectedQuantity = 0;
    this.invPageArray = [];
    this.clearGroupInvPage();
    this.calculateTotalPrice();
  }

  public async productWidgetOptionsLogic(): Promise<void> {
    this.productWidgetsMeetRequirements = false;
    await this.updateProductWidgetPrices();
    await this.getProductWidgetMaxes();
    await this.setProductWidgetOptionShow();
    this.productWidgetsMeetRequirements = await this.areRequiredProdWidgetsAvail();
    return
  }

  async showWidgets(): Promise<void>  {
    if (this.selectedQuantity && this.selectedProduct && this.selectedHours
      && this.selectedAvailableTime) {
      this.showAddons = true;

      // If the widgets do not meet rquirements then do not construct invPageArray, it messes with pricing
      if(this.productWidgetsMeetRequirements){
        await this.setInvPageArray();
      }
    }
    return
  }

  async setInvPageArray(): Promise<void> {
    if(this.showAddons){
      // Make sure there is a quantity selected
      if(this.selectedQuantity){
        let amountChanged = this.selectedQuantity - this.oldSelectedQuantity

        if(amountChanged > 0){ // If quantity increased

          // If editing the cart add the cartInventoryPage to the inv page array as the first value
          if(this.editingCartItem && this.invPageArray.length < 1 && this.cartItemInventoryPage){
            // adding widgetLists based off of quantity selected
            let newGroup = JSON.parse(JSON.stringify(this.cartItemInventoryPage))
            // Add a unique identifier to each page
            newGroup['displayID'] = uuid();
            this.invPageArray.push(newGroup);
          }
          else{
            for (let i = 0; i < amountChanged; i++) {
              this.groupInventoryPage['totalAddonPrice'] = 0;
              // adding widgetLists based off of quantity selected
              let newGroup = JSON.parse(JSON.stringify(this.groupInventoryPage))
              // Add a unique identifier to each page
              newGroup['displayID'] = uuid();

              this.invPageArray.push(newGroup);
            }
          }
        }
        else{ // if quantity decreased
          amountChanged = Math.abs(amountChanged);
          // Splice off end of array for each quantity removed
          for (let i = 0; i < amountChanged; i++) {
            this.invPageArray.splice(this.invPageArray.length - 1, 1);
          }
        }
        this.oldSelectedQuantity = this.selectedQuantity;

        this.invPageArray.forEach(page => {
          this.calculateAddonPrice(page.displayID);
        })
      }
      console.log("GROUP INV PAGE ARRAY In SET INV")
      console.log(this.groupInventoryPage)

      // Making sure to take into account added or deleted input values if invPage array changes, do not do this if you are editing the cart
      if(this.invPageArray.length > 0){
        await this.recalculateProductWidgetMax()
      }
    }
    return
  }

  // Removes any maxes or extra variables added during filtering
  public clearGroupInvPage(): void {
    this.groupInventoryPage.widgetList.forEach(widget => {
      if (widget.widgetType == WidgetType.product && 'options' in widget.element) {
        // If the product widget has a max then delete it
        widget.element.options.forEach(option => {
          if (option?.max) {
            delete option.max;
            delete option.productsAvail;
          }
        })
        delete widget['notEnoughProducts'];
      }
    })
  }

  async onWidgetInputChange(e) {
    await this.calculateAddonPrice(e);
  }

  public recalculateProductWidgetMax(): void {
    this.invPageArray[0]['widgetList'].forEach(widget => {
      if (widget.widgetType == WidgetType.product && 'options' in widget.element) {
        widget.element.options.forEach(option => {
          if (option.show) {
            this.productWidgetInputChangeMaxAdjustment({option: option, widgetID: widget.id, ID: this.invPageArray[0]['displayID'], widget: widget})
          }
        })
      }
    })
    return
  }


  async productWidgetInputChangeMaxAdjustment(e): Promise<void> {
    // Mark exactly where the option is in the page
    let widgetInd: number;
    let optionInd: number;
    let combinedInput: number = 0;
    let maxInput: number;

    // If it is a dropdown then just toggle the disabled
    if(e.widget.element?.isDropdown){
      this.groupInventoryPage.widgetList.forEach((widget, ind) =>{
        if(widget.widgetType == WidgetType.product){
          if(widget['element']['isDropdown']){
            widget['element']['options'].forEach((option, i) => {
              let totalCount = 0;
              this.invPageArray.forEach(page =>{
                if(page.widgetList[ind]['element']['options'][i]['is_selected']){
                  totalCount += 1
                }
              })
                this.invPageArray.forEach(page => {
                  if(totalCount >= option['max']){
                    page.widgetList[ind]['element']['options'][i]['isDisabled'] = true;
                  }
                  else{
                    page.widgetList[ind]['element']['options'][i]['isDisabled'] = false;
                  }
                })
            })
          }
        }
      })
    }
    else{
        // Find the option that changed
        this.invPageArray.forEach(page => {
          page['widgetList'].forEach((widget, ind) => {
            if(widget.id == e.widgetID){
              widgetInd = ind;
              widget['element']['options'].forEach((option, i) => {
                if(option.id == e.option.id){
                  optionInd = i;
                    if(option.inputValue){
                      combinedInput += Number(option.inputValue);
                    }
                }
              })
            }
          })
        })
        // Getting overall max
        maxInput = this.groupInventoryPage['widgetList'][widgetInd]['element']['options'][optionInd]['max'];
        // Finding amount leftover
        let inputLeftover = maxInput - combinedInput;

        // Setting new maxes for each option based on number left before it reaches the max input
        this.invPageArray.forEach(page => {
          page['widgetList'][widgetInd]['element']['options'][optionInd]['max'] = page['widgetList'][widgetInd]['element']['options'][optionInd]['inputValue'] + inputLeftover;

          if(page['widgetList'][widgetInd]['element']['options'][optionInd]['max'] < 0){
            page['widgetList'][widgetInd]['element']['options'][optionInd]['max'] = 0;
          }
        })
    }

    this.calculateAddonPrice(e.ID);
  }

  // ------------------------- Product widget filtering ----------------------------- //

  // Gets the max for each option and sets a productsAvail list for each option
  async getProductWidgetMaxes(): Promise<void> {

    // If editing the cart use the cart list or if there are items in the cart
    // It will use the cart quantities to calculate
    if(this.editingCartItem){
      this.cartItemInventoryPage['widgetList'] = await this.inventoryPageService.setProductWidgetMaxesWithProductWidgetQuantitiesParsing(this.cartItemInventoryPage.widgetList, this.availability);

      // Group Inventory page needs to have the same max but no input values
      this.groupInventoryPage['widgetList'] = JSON.parse(JSON.stringify(this.cartItemInventoryPage['widgetList']))

      this.groupInventoryPage.widgetList.forEach(widget => {
        if(widget.widgetType == WidgetType.product){
          widget['element']['options'].forEach(option => {
            option['inputValue'] = 0;
          })
        }
      })
    }
    else if(this.cartObj['items'] && this.cartObj['items'].length > 0){
      this.groupInventoryPage['widgetList'] = await this.inventoryPageService.setProductWidgetMaxesWithProductWidgetQuantitiesParsing(this.groupInventoryPage.widgetList, this.availability)
    }
    // If not editing the cart then dont pass in the algo, it will not have the correct cartQuantities
    else{
      this.groupInventoryPage['widgetList'] = await this.inventoryPageService.setProductWidgetMaxes(this.selectedAvailableTime['productWidgetIDs'], this.productsMap, this.groupInventoryPage.widgetList)
    }
    return
  }

  // Set options to not show if there is not enough of a size available for a quantity selected
  setProductWidgetOptionShow(): void {
    this.groupInventoryPage['widgetList'].forEach(widget => {
        if (widget['widgetType'] == WidgetType.product) {
          widget['element']['options'].forEach(option => {
            if(option['max'] < 1 && option['inputValue'] == 0){
              // Means there was no product widgets in the availability for the options selected
              option['isDisabled'] = true;
            }
            else{
              option['isDisabled'] = false;
            }
          })
        }
    })

    if(this.editingCartItem){
      this.cartItemInventoryPage['widgetList'].forEach(widget => {
        if (widget['widgetType'] == WidgetType.product) {
          widget['element']['options'].forEach(option => {
            if(option['max'] < 1 && option['inputValue'] == 0){
              // Means there was no product widgets in the availability for the options selected
              option['isDisabled'] = true;
            }
            else{
              option['isDisabled'] = false;
            }
          })
        }
    })
    }

    return
  }

  // Check to see if there are required product widgets and if there is any size being displayed,
  // If not then tell the user there are not enough product widgets for that quantity at this time
  areRequiredProdWidgetsAvail(): boolean{
    let requiredAreAvail: boolean = true;
    let maxNumb: number | null = null;
    let realMaxNumb: number| null = null;
    this.groupInventoryPage['widgetList'].forEach(widget => {

        // If the max is 0 then disregaurd, it does not matter if there are or arent any products available
        if (widget['widgetType'] == WidgetType.product && widget['element']['is_required']) {
          let count = 0;
          let showOne = false;

          widget['element']['options'].forEach(option => {
            // If the option is shown and not disabled
            if(option['show'] && !option['isDisabled']){
              showOne = true;
              count += option?.max;
            }
            // else if(option['show'] && option['isDisabled'])
          })
          // Get the smallest combined number of products available for the product widget
          if(!maxNumb || count < maxNumb){
            maxNumb = count;
            // If there is a minimum amount divide the maxNumb to see how many products can actuall be rented
            if(widget['element']['min'] && widget['element']['min'] != 0){
              realMaxNumb = Math.floor(maxNumb / widget['element']['min']);
            }
          }
          // If there is nothing being shown then there are not enough
          if(!showOne){
            widget['notEnoughProducts'] = true;
            if(widget['element']['is_required']){
              requiredAreAvail = false;
            }
          }
          // If the widget has a min and the overall count is lower than the min then the product is not available
          else if(widget['element']['min']){
            if(widget['element']['min'] > maxNumb){
              requiredAreAvail = false;
            }
          }
        }
    })
    if(realMaxNumb){
      this.minProductAvail = realMaxNumb; // If there are only 3 product widgets available then only allow a 3 product rental
    }
    else{
      this.minProductAvail = maxNumb
    }
    return requiredAreAvail
  }

  // --------------------------- Pricing -------------------------------- //

  // Will display correct price for product widget based on day/hour and increment price set
  updateProductWidgetPrices(): void {
    this.groupInventoryPage['widgetList'].forEach(widget => {
      if (widget['widgetType'] == WidgetType.product) {
        widget['element']['price'] = this.widgetService.calculateProductWidgetPrice(widget['element'], this.numberOfDaysChosen, this.selectedHours, this.productGroup['is24hrsPrice']);

      }
    })

    // If editing cart item, update the product widget prices
    if(this.editingCartItem){
      this.cartItemInventoryPage['widgetList'].forEach(widget => {
        if (widget['widgetType'] == WidgetType.product) {
          // widget['element']['price'] = this.calculateProductWidgetPrice(widget['element']);
          widget['element']['price'] = this.widgetService.calculateProductWidgetPrice(widget['element'], this.numberOfDaysChosen, this.selectedHours, this.productGroup['is24hrsPrice']);
        }
      })
    }
    return
  }


  calculateProductWidgetPrice(productWidget): number {
    let newPrice = 0;
    // If it is a multiple day rental
    if (this.numberOfDaysChosen > 1) {
      let numberOfDaysCharged;
      // --------- If it is a 24 hour rental --------- //
      numberOfDaysCharged = JSON.parse(JSON.stringify(this.numberOfDaysChosen));
      let alreadyFound = false;

      if (this.productGroup['is24hrsPrice']) {
        numberOfDaysCharged = numberOfDaysCharged - 1;
      }

      for (let i = productWidget['priceByDay'].length - 1; i >= 0; i--) {

        if (productWidget['priceByDay'][i]['day'] <= numberOfDaysCharged && !alreadyFound) {
          alreadyFound = true;
          // Add inital price
          newPrice = Number(productWidget['priceByDay'][i]['price'])
          // Calculate amount to add for extra incremented days, get number of increments
          let incrementAmountAdded = numberOfDaysCharged - productWidget['priceByDay'][i]['day'];
          // Multiply number of increments by increment price and add it to total
          newPrice += incrementAmountAdded * Number(productWidget['priceByDay'][i]['increment'])
        }
      }
    }

    // If it is a single day rental
    else {
      if (this.selectedAvailableTime?.type == "shopDay" || this.selectedHours == "All Day Rental" || this.selectedAvailableTime?.type == "24hr" || this.selectedHours == "24 Hour Rental") {
        if (productWidget['priceByDay'][0]['price']) {
          newPrice = productWidget['priceByDay'][0]['price']
        }
        else {
          newPrice = 0;
          console.error("No price by day set for product widget")
        }
      }
      else {
        if (productWidget['priceByHour'][0]['increment']) {
          newPrice = Number(productWidget['priceByHour'][0]['increment']) * (this.selectedHours - 1)
        }
        newPrice += Number(productWidget['priceByHour'][0]['price'])
      }
    }
    return Math.round(newPrice)
  }



  protected calculateIndividualRentalPrice(): void {
    let newPrice: number = 0;
    newPrice = this.pricingService.calculateIndividualRentalPrice(this.numberOfDaysChosen, this.selectedHours, this.productGroup).rentalPrice * this.selectedQuantity
    let priceDifference: number = newPrice - this.currentRentalPrice;
    this.totalPrice += priceDifference;
    this.currentRentalPrice = newPrice;

    //If current rental price is a number
    if (this.currentRentalPrice || this.currentRentalPrice == 0) {
      this.showRentalPrice = true;
      this.calculateTotalPrice();
    }
    return
  }


  protected calculateAddonPrice(changedID: string): void {
    let currentInventoryPage;
    this.invPageArray.forEach(page => {
      if(page.displayID == changedID){
        currentInventoryPage = page;
      }
    })
    currentInventoryPage['totalAddonPrice'] = 0;

    currentInventoryPage['totalAddonPrice'] = this.pricingService.calculateAddonPrice(currentInventoryPage['widgetList'], this.numberOfDaysChosen, this.selectedHours, this.productGroup, false);
    this.calculateTotalPrice();
  }


  calculateTotalPrice(): void {
    let invPagePrice = 0;

    this.invPageArray.forEach(page => {
      if(page["totalAddonPrice"]){
        invPagePrice += page["totalAddonPrice"];
      }
    })

    this.addonPrice = invPagePrice;
    this.totalPrice = 0;
    this.totalPrice = invPagePrice + this.currentRentalPrice;
    return
  }


  // ---------------------- Add to cart -------------------------------- //

  // Added items to cart
  async addToCart(): Promise<void>  {
    if (!this.cartService.hasCustomCartPermission(this.cartObj))return;

    if (!this.addToCartClicked) {
      this.addToCartClicked = true;

    // Creates array to pass to cart
    if (this.selectedProduct && this.selectedQuantity != 0 &&
      this.selectedHours && this.selectedAvailableTime) {
      this.unavailItems = [];

      // Check all required widgets
      let check = await this.requiredWidgetsCheck();
      if (!check) {
        this.requiredWidgetCheck = false;
        this.addToCartClicked = false;
      }
      else {
        this.requiredWidgetCheck = true;
      }
      // If the product is a tour then amke sure the minimum products required is met
      if (this.productGroup?.productType == "tour") {
        this.tourMinProductsRequirementsMet = await this.checkIfMinProductsForTourSelected();
        if (!this.tourMinProductsRequirementsMet.bool) {
          this.addToCartClicked = false;
          return
        }
      }

      if (this.requiredWidgetCheck) {

        if (!this.editingCartItem) {
          Swal.fire({
            title: $localize`Adding to cart...`,
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          Swal.showLoading();
        }
        else {
          Swal.fire({
            title: $localize`Updating cart...`,
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          Swal.showLoading();
        }
        // Get an updated availability response
        // Make sure to run it after the required widget check, so that can be a quick response if the user doesn't pass

        let cartItems = await this.psuedoCreateCartItem();
        let psuedoCartObj = {items: cartItems}

        if (this.cartObj['cartWidgetList']) {
          psuedoCartObj['cartWidgetList'] = this.cartObj['cartWidgetList']
        }
        else {
          psuedoCartObj['cartWidgetList'] = [];
        }

        this.newAvailability = await this.availabilityService.runAvailabilityAlgorithm(
          this.selectedAvailableTime['dayStart'], // search start date
          this.selectedAvailableTime['dayEnd'], // search end date
          this.customerLocation, // search / selected location
          this.companyID,
          this.locationsArray,
          this.productsArray,
          this.productGroupsMap,
          this.inventoryPageMap,
          this.widgetMap,
          this.productsMap,
          psuedoCartObj)

          console.log("NEW ALGO RES")
          console.log(this.newAvailability)

          let productGroupStillAvail = await this.inventoryPageService.checkAlgoCartQuantitiesParsing(this.parent, this.selectedProductSizeId, this.newAvailability).passCheck;
          console.log("still avail response", productGroupStillAvail)
          if (!productGroupStillAvail) {

            let cartWidgetRequirementsMet = await this.checkIfProductIsUnavailBecauseOfRequiredCartProductWidgets(); //Edge case

            if (!cartWidgetRequirementsMet) {
              this.addToCartClicked = false;
              Swal.fire({
                title: $localize`Items Unavailable`,
                html: $localize`There are not enough cart addons to fulfill this products requirements at this time. Please try a different product.`,
                icon: 'error',
                allowEnterKey: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
              })
            }
            else {
              // this.unavailItems.push({productGroupID: this.parent, sizeID: this.selectedProductSizeId, quantity: Math.abs(this.selectedQuantity)})
              this.addToCartClicked = false;
              let flagUserItems = '';
              flagUserItems += '<br>' + '&#x2022 ' + this.productGroupsMap[this.parent]['groupName'] + ', ' + this.productSizesMap[this.selectedProductSizeId]['size'] + ' x' + Math.abs(this.selectedQuantity);
              Swal.fire({
                title: $localize`Item Unavailable`,
                html: $localize`Please try a different time or remove these items ${flagUserItems}`,
                icon: 'error',
                allowEnterKey: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
              })
            }
          }
          else {
            this.createCartItem();
          }
      }
    }
    }
  }

  // Create a fake cart obj to pass into alrogithm so that the cart quanitites will be correct for a specific time
  psuedoCreateCartItem() {
    let cartList = [];

    this.invPageArray.forEach((page, ind) => {

      cartList.push({
        parentId: this.productGroup['id'],
        addonPrice: page['totalAddonPrice'],
        locationTimeZone: this.locationObj.timezone,
        locationID: this.customerLocation,
        dateAdded: new Date(),
        dayStart: this.selectedAvailableTime['dayStart'],
        dayEnd: this.selectedAvailableTime['dayEnd'],
        dayStartString: this.selectedAvailableTime['dayStart'].setZone(this.locationObj.timezone).toLocaleString(DateTime.DATETIME_FULL),
        dayEndString: this.selectedAvailableTime['dayEnd'].setZone(this.locationObj.timezone).toLocaleString(DateTime.DATETIME_FULL),
        dayStartISO: this.selectedAvailableTime['dayStart'].setZone(this.locationObj.timezone).toISO(),
        dayEndISO: this.selectedAvailableTime['dayEnd'].setZone(this.locationObj.timezone).toISO(),
        customerLocalTimezone: DateTime.now().zoneName,
        widgetList: page['widgetList'],
        cartItemID: uuid(),
        daySpan: this.numberOfDaysChosen,
        priceByDay: this.productGroup['priceByDay'],
        priceByHour: this.productGroup['priceByHour'],
        is24Hrs: this.productGroup['is24hrsPrice'],
        selectedHours: this.selectedHours,
        productSize: this.selectedProduct.sizeName,
        productName: this.productGroup.groupName,
        downtime: this.productGroup.downtime,
        productSizeID: this.selectedProductSizeId,
        contentID: this.contentID,
        timeslotType: this.selectedAvailableTime.type
      })

    })

    // If the cart already has items in it
    if (this.cartObj && this.cartObj['items'] && this.cartObj['items'].length > 0) {
      this.cartObj['items'].forEach(item => {
        // If you are editing the cart then dont duplicate the item you are editing
        if (this.editingCartItem && this.cartItemID == item['cartItemID']) {
        }
        else{
          cartList.push(item)
        }
      })
    }

    return cartList;

  }

  async createCartItem(): Promise<void> {
    let cartList = [];

    // For each loop converted by AI to await checkProductWidgetAvailability()
    for (let [ind, page] of this.invPageArray.entries()) {

      await this.checkProductWidgetAvailability(page, this.newAvailability);

      cartList.push({
        parentId: this.productGroup['id'],
        addonPrice: page['totalAddonPrice'],
        locationTimeZone: this.locationObj.timezone,
        locationID: this.customerLocation,
        dateAdded: new Date(),
        dayStart: (this.selectedAvailableTime['dayStart'].setZone(this.locationObj.timezone)).toJSDate(),
        dayEnd: (this.selectedAvailableTime['dayEnd'].setZone(this.locationObj.timezone)).toJSDate(),
        dayStartString: this.selectedAvailableTime['dayStart'].setZone(this.locationObj.timezone).toLocaleString(DateTime.DATETIME_FULL),
        dayEndString: this.selectedAvailableTime['dayEnd'].setZone(this.locationObj.timezone).toLocaleString(DateTime.DATETIME_FULL),
        dayStartISO: this.selectedAvailableTime['dayStart'].setZone(this.locationObj.timezone).toISO(),
        dayEndISO: this.selectedAvailableTime['dayEnd'].setZone(this.locationObj.timezone).toISO(),
        customerLocalTimezone: DateTime.now().zoneName,
        widgetList: page['widgetList'],
        cartItemID: uuid(),
        daySpan: this.numberOfDaysChosen,
        priceByDay: this.productGroup['priceByDay'],
        priceByHour: this.productGroup['priceByHour'],
        is24Hrs: this.productGroup['is24hrsPrice'],
        selectedHours: this.selectedHours,
        productSize: this.selectedProduct.sizeName,
        productName: this.productGroup.groupName,
        downtime: this.productGroup.downtime,
        productSizeID: this.selectedProductSizeId,
        contentID: this.contentID,
        timeslotType: this.selectedAvailableTime.type
      });

      // If you are editing an item and have a cartItemID then make sure to update the cartItemId to be accurate
      // The first item in the inv page array will represent the item you are editing
      if (this.editingCartItem && ind == 0) {
        cartList[ind].cartItemID == this.cartItemID;
      }
    }

    let timeslotCheck = this.inventoryPageService.checkCartItemsTimeslotType(cartList); // If there are differing timeslot types then there is an issue
    if (!timeslotCheck) {
      console.error("Error with cart timeslot types")
      this.addToCartClicked = false;
      Swal.fire({
        title: $localize`Error processing item`,
        html: $localize`Please try again or try a different item`,
        icon: 'error',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      })
      return
    }
    // If there are unavailable items then flag the user and tell them what and how many are unavailable

    await this.updateCart(cartList)


    return
  }


  async updateCart(cartList) {
    if (cartList.length > 0) {
      let cartID;
      try {
        cartID = localStorage.getItem(`FM-cart-uuid-${this.templateID}`);
      } catch (error) {
        // Handle error from localStorage.getItem
        console.error("Error retrieving cart ID from localStorage:", error);
      }

      if (cartID) { // if cart found in localStorage
        try {
          this.cartSubscription = this.cartService.getCart(cartID).pipe(take(1)).subscribe(async (data) => {
            try {
              // If no document was found remove id and create a new one
              if (!data.payload.exists) {
                await this.handleInvalidCartID(cartList);
              } else { // doc was found
                let dataRes = data.payload.data();
                let cartData = dataRes;
                await this.addingItemToExistingCart(cartList, cartData, cartID);
              }
            } catch (error) {
              // Handle error from handleInvalidCartID or handleUpdatingExistingCart
              console.error("Error handling cart data:", error);
            }
          });
        } catch (error) {
          // Handle error from cartService.getCart subscription
          console.error("Error subscribing to cartService:", error);
        }
      }
      // If cart does not yet exist, create a new cart and add items
      else {
        try {
          this.cartData = {};
          await this.addingItemToNewCart(cartList, cartID);
        } catch (error) {
          // Handle error from handleCreatingNewCart
          console.error("Error creating new cart:", error);
        }
      }
    }

  }


  async handleInvalidCartID(cartList): Promise<void> {
    this.cartData = {} // set cartData to items just added
    // delete localStorage cart (since it doesn't exist in db)
    localStorage.removeItem(`FM-cart-uuid-${this.templateID}`);

    let newCartWidgetList = await this.getUpdatedCartWidgetList(cartList, []);
    // Create new cart and add items
    this.cartService.addItemsToNewCartAndReturnId({ items: cartList, cartWidgetList: newCartWidgetList }).then((async data => {
      let cartID = data;
      localStorage.setItem(`FM-cart-uuid-${this.templateID}`, cartID);
      this.displayAddToCartSwalMessage();
    }))

    return
  }

  async addingItemToExistingCart(cartList, cartData, cartID): Promise<void> {
    // If editing an item then remove that item from the cart before adding edited item back in
    // edited item will be treated as a new item
    if (this.editingCartItem) {
      cartData['items'].forEach((item, ind) => {
        if (item.cartItemID == this.cartItemID) {
          // replace item with the cart changes item
          cartData['items'][ind] = cartList[0];
          cartList.splice(0, 1);
        }
      })
    }

    // Iterate through cartList with a for loop
    // Cart list holds all of the items to be added to the cart, cart data holds all of the current item in the cart
    for (const item of cartList) {
      cartData['items'].push(item);
    }

    let newCartWidgetList = await this.getUpdatedCartWidgetList(cartList, this.cartObj['cartWidgetList'] || []);
    cartData.cartWidgetList = newCartWidgetList;
    this.addItemToCart(cartData, cartID)
    return
  }

  async addingItemToNewCart(cartList, cartID): Promise<void> {
    let newCartObject = {
      items: cartList,
      promocode: "",
      promoApplied: false,
      cartCheckedOut: false,
      dateCheckedOut: null,
      additionalComments: "",
      dateCreated: new Date(),
      companyId: this.productGroup['companyID'],
      cartWidgetList: [],
    }

    newCartObject['cartWidgetList'] = await this.getUpdatedCartWidgetList(cartList, []);
    this.addItemToCart(newCartObject)
    return
  }

  private async addItemToCart(cartObj: Cart, cartID?: string){
    if(cartID){ // If cartID supplied, updateDoc
      const updatedDoc = await this.firestoreService.updateDocument(Collection.Cart, cartID, cartObj, true);
      console.log(updatedDoc);
      this.itemsAddedToNewCart.emit(updatedDoc);
    }
    else{ // Create a cart doc
      const createdDoc = await this.firestoreService.createDocument(Collection.Cart, cartObj);
      localStorage.setItem(`FM-cart-uuid-${this.templateID}`, createdDoc.id);
      console.log(createdDoc);
      this.itemsAddedToNewCart.emit(createdDoc);
    }
    this.displayAddToCartSwalMessage();
  }

  displayAddToCartSwalMessage(): void {
    this.addToCartClicked = false;
    this.algoResAlreadyUpdated = true;
    // this.algoResUpdated.emit(this.availability);
    this.algoResUpdated.emit(this.newAvailability);

    if (!this.editingCartItem) {
      // this.resetAllValues();
      Swal.fire({
        title: $localize`Successfully added to cart`,
        icon: 'success',
        showCancelButton: true,
        cancelButtonColor: '#6e7881',
        cancelButtonText: $localize`Go to cart`,
        showConfirmButton: true,
        confirmButtonColor: '#7066e0',
        confirmButtonText: $localize`Continue shopping`,
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then((result) => {
        this.addToCartClicked = false;
        Swal.close();

        // Go to cart
        if (result.isDismissed) {
          this.continueShopping.emit(false)
        }
        // Continue Shopping
        else {
          this.continueShopping.emit(true)
        }
      });
    }
    else {
          // this.resetAllValues();
    Swal.fire({
      title: $localize`Successfully saved changes`,
      icon: 'success',
      confirmButtonColor: '#7066e0',
      confirmButtonText: $localize`Go to cart`,
      showConfirmButton: true,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      this.addToCartClicked = false;
      Swal.close();
      if (!result.isDismissed) {
        this.continueShopping.emit(false);
      }
    });
    }
  }


  async getUpdatedCartWidgetList(cartList, existingCartWidgetList) {
    // Updates cart widget list
    let newCartWidgetList = await this.inventoryPageService.getCartWidgetListData(cartList, existingCartWidgetList, this.inventoryPageMap, this.productGroupsMap, this.companyID);

    if(newCartWidgetList.length > 0){

      let newGroupInvPage = {};
      newGroupInvPage['widgetList'] = newCartWidgetList;
          // Get the saved widget info and product info
      newGroupInvPage = await this.widgetService.getSavedWidgetsInfo(newGroupInvPage, this.widgetsArray, true);
      newGroupInvPage = await this.widgetService.getProductWidgetData(newGroupInvPage, this.productGroupsMap);
      newGroupInvPage = await this.widgetService.getProductOptionSizes(newGroupInvPage, this.productSizesMap, this.productSizeTypesMap, this.companyID);

      await newGroupInvPage['widgetList'].forEach(async widget => {
        if (widget['widgetType'] == WidgetType.product) {
          widget['element']['price'] = await this.widgetService.calculateProductWidgetPrice(widget['element'], this.numberOfDaysChosen, this.selectedHours, this.productGroup['is24hrsPrice']);
        }
      })

      newCartWidgetList = newGroupInvPage['widgetList'];
    }

    return newCartWidgetList
  }

  // If the productGroup is a tour find the minimun products attached requirement x the quanitty of tours being checked out
  // Then get the total amount of product widgets that couunt towards tour minimun to see if there are enough product widgets being checked out to meet requirements
  checkIfMinProductsForTourSelected(): { bool: boolean, count: number } {
    let totalCount = 0;
    let totalNeeded = this.productGroup.minProductsAttached * this.selectedQuantity;
    // Get the total count of product widgets added for the productGroup
    this.invPageArray.forEach(page => {
      page.widgetList.forEach(widget => {
        if (widget['element']['appliesToTourProductCount']) {
          if (widget['element']['isDropdown']) {
            if (widget['element']['inputValue']) {
              totalCount += 1;
            }
          }
          else{
            widget['element']['options'].forEach(option => {
              if(option['inputValue']){
                totalCount += option['inputValue']
              }
            })
          }
        }
      })
    })

    if (totalCount < totalNeeded) {
      return { bool: false, count: totalNeeded - totalCount }
    }
    // See if the total count is enought to satisfy the minProducts needed for the tour quantity chosen
    else {
      return { bool: true, count: 0 }
    }
  }

  requiredWidgetsCheck(): boolean {
    let pass: boolean = true;
    this.invPageArray.forEach(page => {
      let valueReturned = this.widgetService.requiredWidgetsCheck(page.widgetList);
      // If value returned is false then set pass equal to false
      // Do not set pass equal to each returned value otherwise a false pass could be written over
      if (!valueReturned) {
        pass = valueReturned;
      }
    })
    return pass
  }

  // Theres two different kinds of algo res you pass in, one before a specific selected time and one for after
  async checkProductWidgetAvailability(widgetList, availabilityParsing): Promise<void> {
    // Map the widgetList to an array of Promises representing the asynchronous operations
    const promises = widgetList.widgetList.map(async (widget) => {
      if (widget.widgetType === WidgetType.product) {
        let element = widget.element;
        // Use Promise.all to wait for all inner asynchronous operations to complete
        await Promise.all(element.options.map(async (option) => {
          if (option.inputValue > 0) {
            let availableResult = await this.inventoryPageService.checkAlgoProductQuantitiesParsing(element.groupId, option.sizeID, availabilityParsing);
            if (!availableResult.passCheck) {
              this.unavailItems.push({ productGroupID: element.groupId, sizeID: option.sizeID, quantity: availableResult.amountUnavail });
            }
          }
        }));
      }
    });

    // Wait for all promises to resolve
    await Promise.all(promises);
    return
  }

  checkIfProductIsUnavailBecauseOfRequiredCartProductWidgets(): boolean {
    let passCheck: boolean = true;

    for (const widget of this.inventoryPageMap[this.productGroupsMap[this.parent]['inventoryPageId']].widgetList) {
      if (widget?.isCartWidget) {
        if (widget.widgetType == WidgetType.product && this.widgetMap[widget.savedWidgetId].element &&
          (this.widgetMap[widget.savedWidgetId].element as ProductElementInterface).is_required && 'groupId' in this.widgetMap[widget.savedWidgetId].element) {
          const element = this.widgetMap[widget.savedWidgetId].element as ProductElementInterface;

          let totalAvail = 0;
          try {
            totalAvail = this.availability.getGroupProductWidgetCurrentAvailTotal(element.groupId);
          } catch (error) {
            console.error("Error getting total avail for product widget", error)
            return false;
          }

          if (totalAvail < element.min) {
            passCheck = false;
          }
        }
      }
    }

    return passCheck;
  }


  //-------- Edit Cart Item functions --------//

  updateWidgetInputsForCartEdit(groupInventoryPage, cartItem) {
    let cartItemInventoryPage = JSON.parse(JSON.stringify(groupInventoryPage))
    cartItemInventoryPage.widgetList.forEach((widget, ind) => {
      cartItem.widgetList.forEach(item => {
        if (item.id == widget.id) {
          // Height input doesnt use the inputValue variable, it only has feetInput and heightInput
          if (widget.widgetType == WidgetType.heightInput) {
            widget.element.feetInput = item.element.feetInput;
            widget.element.inchInput = item.element.inchInput;
          }
          else if (widget.widgetType == WidgetType.dropdown || widget.widgetType == WidgetType.radios) {
            widget.element.inputValue = item.element.inputValue;
            widget.element.options.forEach((option, i) => {
              option.is_selected = item.element.options[i].is_selected;
            })
          }
          // Set each product widget size option input value, this works for quantity or dropdown product widgets
          else if (widget.widgetType == WidgetType.product) {
            widget.element.options.forEach((option, i) => {
              item.element.options.forEach(opt => {
                if (option.sizeID == opt.sizeID) {
                  if (widget.element.isDropdown) {
                    cartItemInventoryPage.widgetList[ind]['element']['options'][i].is_selected = opt.is_selected;
                  }
                  else {
                    cartItemInventoryPage.widgetList[ind]['element']['options'][i].inputValue = opt.inputValue;
                  }
                }
              })
            })
          }
          else if (widget.widgetType == WidgetType.quantity || widget.widgetType == WidgetType.checkbox || widget.widgetType == WidgetType.weightInput) {
            widget.element.inputValue = item.element.inputValue;
          }
        }
      });
    })

    return cartItemInventoryPage
  }


} //End of class
