import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators, FormBuilder } from '@angular/forms';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { RentalService } from 'src/app/services/rental.service';
import { ProductLocationService } from 'src/app/services/product-location.service';
import { Sizes } from 'src/app/models/product.model';
import { OrderProcessingService } from 'src/app/services/order-processing.service';
import { MatStepper } from '@angular/material/stepper';
import { lastValueFrom, Observable, Subscription } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { ImageLibraryService } from 'src/app/services/image-library.service';
import { ColorTheme } from 'src/app/services/colorTheme.service';
import { LogService } from 'src/app/services/log.service';
import { WidgetService } from 'src/app/services/widget.service';
import { BookingService } from 'src/app/services/booking.service';
import { AvailabilityService } from 'src/app/services/availability.service';
import { environment } from 'src/environments/environment';
import { ProductGroup } from 'src/app/models/storage/product-group.model';
@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})

export class InventoryComponent implements OnInit {
  @ViewChild('stepper') private myStepper: MatStepper;
  @ViewChild('stepperEdit') private myStepperEdit: MatStepper;

  subs = new Subscription(); // group of subscriptions
  projectId: string = environment.firebase.projectId;

  //Forms
  productEditForm: UntypedFormGroup;

  informationFormAddGroup: FormGroup;
  quantityFormAddGroup: FormGroup;
  rulesFormAddGroup: FormGroup;

  informationFormEditGroup: FormGroup;
  quantityFormEditGroup: FormGroup;
  rulesFormEditGroup: FormGroup;
  isOptional = false;
  currentUserService: any;

  constructor(
    private bookingService: BookingService,
    private _productService: ProductsService,
    private _rentalService: RentalService,
    private fb: UntypedFormBuilder,
    public _currentUser: CurrentUserService,
    private imageCompress: NgxImageCompressService,
    private _locationService: ProductLocationService,
    private _orderService: OrderProcessingService,
    private _formBuilder: FormBuilder,
    private _imageLib: ImageLibraryService,
    public _theme_color_public: ColorTheme,
    private _logService: LogService,
    private router: Router,
    private _widgetService: WidgetService,
    private route: ActivatedRoute,
    private availabilityService: AvailabilityService
  ) {
    this.buildAddFormGroup();
    this.buildEditFormGroup();
    this.productEditForm = this.fb.group({
      productName: [{ value: '' }, [Validators.required]],
      productNumber: [0],
      id: [0],
      //price: [0, [Validators.required, Validators.min(1)]],
      minimumTime: [30, ''],
      productDescription: [{ value: '' }],
      productNotes: [''],
      productLocationID: ['', [Validators.required]],
      productLocationName: [''],
      productSizeTypeID: ['', [Validators.required]],
      productSizeType: ['', [Validators.required]],
      productSizeID: ['', [Validators.required]],
      productSize: ['', [Validators.required]],
      downtime: [0, [Validators.min(0), Validators.pattern('^[0-9]+$')]],
      //leadtime: [0, [Validators.required, Validators.min(1), Validators.pattern('^[0-9]+$')]],
      imageUrl: [''],
      thumbnail: ['']
    });
  }
  public imgGallery: string = "";
  public thumbnailGallery: string = "";
  public locationID: string = "";
  public trackProducts: boolean = true;
  public selectedTrack: boolean = false;
  public productType: string = "rental";
  public minimumStartPrice: boolean = true;
  public defaultTime: boolean = false;
  public startTimeInvalid: boolean = false;
  public categoryInvalid: boolean = true;
  public hourInvalid: boolean = false;
  public dayInvalid: boolean = false;
  public isTour: boolean = false;
  public isStartTime: boolean = false;
  public startTime: any[] = [{ hour: "12:00" }];
  public products: any
  public productGroup: any[] = [];
  public productEditCopy: any
  public productEditPosition: any
  public groupedProducts: any[]
  public productSizeTypeArray: any[]
  public arraySizeTEdit: any[]
  public arraySizeEdit: any[]
  public productSizeArray: any[] = []
  public productSizeType: string
  public productSize: string
  public selectedSizeType: any
  public selectedSize: any
  public imgResultBeforeCompress: string = '';
  public qtyproducts: any[] = []
  public waivers: any[] = []
  public waiversedit: any[] = []
  public locations: any[] = [];
  public company: any
  public selectedWaiver: any[] = []
  public selectedEditWaiver: any[] = []
  public selectedLocations: any[] = [];
  public validateRequired: boolean = false
  public validateEditRequired: boolean = false
  public hours: any[] = [{ hours: 1, price: 0 }]
  public accordion: boolean = false;
  public productInformation: boolean = true;
  public productQuantity: boolean = false;
  public productRules: boolean = false;
  public productPrice: boolean = false;
  public productMainObject: any
  public quantitytype: boolean = false;
  public quantityLocSize: any[] = [];
  public loadingLocSize: boolean = false;
  public sizeRow: any
  public arraysizesqty: any[] = [];
  public arraysizeqtycopy: any[] = [];
  public productQty: number = 0;
  public productNumbers: boolean = false;
  public isLeadtime: boolean = false;
  public autoNumber: boolean = false;
  public searchProductInput: string = '';
  public inputSearch: string = '';
  public isPriceByDay: boolean = true;
  public isPriceByHour: boolean = true;
  public isPrice24hrs: boolean = false;
  public arrayPricesDayBase: any = [{ day: 1, price: 0, increment: 0 }];
  public arrayPricesHourBase: any = [{ hour: 1, price: 0 }];
  public productRulesList: any[] = [{ type: "Checkout", isActive: false }, { type: "Checkin", isActive: false }, { type: "Maintenance", isActive: false }];
  public selectedProducts: boolean = false;
  public productGroupEdit: any
  public submittedInfo: boolean = false;
  public submittedQty: boolean = false;
  public maintenanceRuleAdd: boolean = false;
  public checkoutRuleAdd: boolean = false;
  public checkineRuleAdd: boolean = false;
  public maintenanceRuleEdit: boolean = false;
  public checkoutRuleEdit: boolean = false;
  public checkineRuleEdit: boolean = false;
  public typeaction: string = ""
  public editImage = ""
  public tempCroppedImage: any;
  private subscription: Subscription;
  public repeatedNumber: boolean = false;
  public existingNumber: boolean = false;
  public hourInvalidInfo: boolean;
  public inventoryPages = [];
  public inventoryPagesIdList = [];
  public defaultInvID;
  public groupsToUpdate = [];
  public ColorBackground: string;
  public TextColor: string;
  public minProductsAttached: number = 0;
  public maxProductsAttached: number = 0;
  public validateGroupRan = false;
  public selectedCategory: string
  public categoryName: string
  public categories: any[] = [];
  public tabCategory: string = "";
  public filteredGroups: any[] = [];
  private productSubscription: Subscription = new Subscription();
  get info() {
    return this.informationFormAddGroup.controls;
  }
  get qty() {
    return this.quantityFormAddGroup.controls;
  }
  get rule() {
    return this.rulesFormAddGroup.controls;
  }
  get infoedit() {
    return this.informationFormEditGroup.controls;
  }
  get qtyedit() {
    return this.quantityFormEditGroup.controls;
  }
  get ruleedit() {
    return this.rulesFormEditGroup.controls;
  }
  get editform() {
    return this.productEditForm.controls;
  }

  private openedProductModalViaRoute: boolean = false;

  public async ngOnInit(): Promise<void> {
    this.initalSubscriptions();
    this.getCategories();
    this.getProducts();
    this.getSizes();
    await this.getCompany();
    this.getDefaultLayoutID();
  }

  private processProductRouteParam(): void{ // Attempt to open up a productGroup modal if a productGroup was found in the URL
    const productID: string | undefined = this.route.snapshot.paramMap.get('productID');
    if (productID) {
      const productgroupedit: ProductGroup | undefined = this.productGroup.find(productG => productG.id === productID);
      this.editProduct(productgroupedit, 'edit');
    }
  }

  initalSubscriptions() {
    // Handles getting imgData from the image-library component using the imageLib service (when the user selects an img)
    this.subs.add(
      this._imageLib.selectedImg.subscribe((imgInfo) => {
        // subscription is used for unsubscribing hot observable
        this.imgGallery = imgInfo.url; // set croppedImg to selected img
        this.thumbnailGallery = imgInfo.thumbUrl;
        this.closeImgGallery(); // close imgGallery bootstrap modal
        this.updateProductsInfo()
      })
    );

    // Subscribes to changes in image library collection
    this.subs.add(
      this._imageLib
        .getImages(this._currentUser.currentUser.companyId)
        .subscribe((res) => {
          this.getImages(res);
        })
    );
  }
  createLog(collection, id, description) {
    if (collection == "product") {
      this._logService.addProductLog(id, description);
    } else {
      this._logService.addProductGroupLog(id, description);
    }
  }
  publicolorTheme() {
    this._theme_color_public
      .getcolorPeruser(this.currentUserService.currentUser.id)
      .then((Selectheme) => {
        let ObjecTheme = {
          Theme: Selectheme.color.value,
          Text: Selectheme.color.fontcolor,
        };
        this.ColorBackground = ObjecTheme.Theme;
        this.TextColor = ObjecTheme.Text;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getImages(images) { }
  private buildAddFormGroup(): void {
    this.informationFormAddGroup = this._formBuilder.group({
      productName: ['', Validators.required],
      minimumTime: [30, ''],
      //leadtime:  [0, [Validators.required, Validators.min(1), Validators.pattern('^[0-9]+$')]],
      downtime: [0, [Validators.min(0), Validators.pattern('^[0-9]+$')]],
      productDescription: [''],
      productNotes: [''],
      imageUrl: [''],
      thumbnail: ['']
    });
    this.quantityFormAddGroup = this._formBuilder.group({
      productLocationID: ['', Validators.required],
      productLocationName: [''],
      validateRequired: [false, Validators.requiredTrue],
    });
    this.rulesFormAddGroup = this._formBuilder.group({});
  }
  private buildEditFormGroup(): void {
    this.informationFormEditGroup = this._formBuilder.group({
      productName: ['', Validators.required],
      minimumTime: [30, ''],
      //leadtime:  [0, [Validators.required, Validators.min(1), Validators.pattern('^[0-9]+$')]],
      downtime: [0, [Validators.min(0), Validators.pattern('^[0-9]+$')]],
      productDescription: [''],
      productNotes: [''],
      imageUrl: [''],
      thumbnail: ['']
    });
    this.quantityFormEditGroup = this._formBuilder.group({
      productLocationID: ['', Validators.required],
      productLocationName: [''],
      validateRequired: [false, Validators.requiredTrue]
    });
    this.rulesFormEditGroup = this._formBuilder.group({});

  }
  getAndFix() {
    console.log("Fixing automaticNumbers")
    this._productService.getProductsPromise().then(data => {
      data.sort((a, b) => (a.automaticNumber === undefined) ? 1 : (b.automaticNumber === undefined) ? -1 : 0);
      for (let i = 0; i < data.length; i++) {
        if (data[i].automaticNumber === undefined) {
          data[i].automaticNumber = i + 1; // Asumiendo que quieres números empezando desde 1
        }
      }
      data.sort((a, b) => {
        if (a.automaticNumber < b.automaticNumber) {
          return -1;
        } else if (a.automaticNumber > b.automaticNumber) {
          return 1;
        } else {
          return 0;
        }
      });
      //QUERY TO FIX PRODUCTS NUMBERS
      data.forEach(async (product, index) => {
        let newNumber = index + 1;
        console.log("Old: ", product.automaticNumber, " - New: ", newNumber)
        product.automaticNumber = newNumber;
        //await this._productService.updateProduct(product.id, product);
      })
      let counts = {};
      data.forEach(item => {
        if (item.automaticNumber !== undefined) {
          if (counts[item.automaticNumber] === undefined) {
            counts[item.automaticNumber] = 1;
          } else {
            counts[item.automaticNumber]++;
          }
        }
      });

      for (let automaticNumber in counts) {
        if (counts[automaticNumber] > 1) {
          console.log(`El valor ${automaticNumber} se repite ${counts[automaticNumber]} veces.`);
        }
      }
    })
  }
  changeCategory(categoryID?) {
    categoryID != undefined ? this.tabCategory = categoryID : this.tabCategory = ""
    if (this.tabCategory != "") {
      const filteredArray = this.productGroup.filter(item => item.categoryID === this.tabCategory);
      this.filteredGroups = filteredArray;
    } else {
      this.filteredGroups = Object.assign(this.productGroup);
    }
  }
  private getProducts(setProducts?: boolean): void {
    if(this.productSubscription){ // If a productSubscription is currently active, unsubscribe to prevent memory leaks (occurs because this method is called more than once)
      this.productSubscription.unsubscribe()
    }

    this.productSubscription = this._productService.getProducts() // method must remain as observable because other processes (such as deleting product group) depend on this functionality
      .pipe(
        switchMap(products => {
          if (this.products == undefined || setProducts) {
            this.products = products;
          }
          return this._productService.getProductGroups();
        }),
        tap(productGroups => {
          this.productGroup = productGroups.map(group => {
            const productsQty = this.products.filter(product => product.productGroupID === group.id);
            group.quantity = productsQty.length;
            return group;
          });
          // Ordenar productGroup por categoryName
          let order = this.categories.map(category => category.id);
          this.productGroup.sort((a, b) => {
            let orderIndexA = order.indexOf(a.categoryID);
            let orderIndexB = order.indexOf(b.categoryID);

            if (orderIndexA !== orderIndexB) {
              return orderIndexA - orderIndexB;
            } else {
              return a.groupName.localeCompare(b.groupName);
            }
          });
          this.tabCategory == "" ? this.changeCategory() : this.changeCategory(this.tabCategory)
        }),
      ).subscribe(()=>{
        this.validateGroupInventoryId();
        if(!this.openedProductModalViaRoute){
          this.openedProductModalViaRoute = true; // prevents the modal from opening again when this method is called
          this.processProductRouteParam();
        }
      })
  }
  FixIncrementPerProduct() {
    console.log(this.productGroup)
    const onlyPriceDay = this.productGroup.filter(g => g.allowRentalByDay == true);
    onlyPriceDay.forEach(async group => {
      group['priceByDay'][0].increment = group['priceByDay'][0].price;
      console.log(group)
      await this._productService.editProductGroup(group);
    })
  }
  FixAvailableLocationsProducts() {
    this._productService.getProductsPromise().then(data => {
      let filteredData = data.filter(item => {
        // Verifica si el campo availableLocations no existe o si es un arreglo vacío
        return !item['availableLocations'] || item['availableLocations'].length === 0;
      });
      console.log(filteredData)
      filteredData.forEach(async product => {
        product['availableLocations'] = [product.productLocationID];
        //await this._productService.updateProduct(product.id,product);
      })
    })
  }
  FixAvailableGroupsProducts() {
    this._productService.getProductGroups().subscribe(data => {
      let filteredData = data.filter(group => {
        // Verifica si el campo availableLocations no existe o si es un arreglo vacío
        return !group['availableLocations'] || group['availableLocations'].length === 0;
      });
      console.log(filteredData)
      filteredData.forEach(async group => {
        group['availableLocations'] = [group.productLocationID];
        //await this._productService.updateProduct(product.id,product);
      })
    })
  }
  async getCompany() {
    try {
      this.company = await this._rentalService.getCompanyByID(this._currentUser.currentUser.companyId);
      this.getWaivers();
      this.getLocations();
    } catch (error) {
      console.error('Error getting the company:', error);
    }
  }
  async getCategories() { //these are the Categories w/o Locations
    await this._productService.getCompanyCategoriesByPromise().then(categories => {
      this.categories = categories;
      this.categories.sort((a, b) => {
        if (a.sortOrder > b.sortOrder) {
          return 1;
        } else {
          return -1;
        }
      });
      console.log(categories)
    })
  }
  async getSizes() {
    try {
      const sizes = await this._productService.getCustomSizeTypesPromise(this._currentUser.currentUser.companyId);
      const defaultSize = (await this._productService.getCustomSizeTypesPromiseDefault())[0];

      if (!sizes.find(typesize => typesize.isDefault === defaultSize.isDefault)) {
        sizes.push(defaultSize);
      }
      sizes.push({ id: "redirect", isSelected: false, productType: "Add new Size..." })
      sizes.forEach(element => {
        element.isSelected = element.isDefault;
      });
      this.productSizeTypeArray = sizes;
      this.arraySizeTEdit = sizes;
      this.getProductSize(defaultSize.id);
    } catch (error) {
      console.error('Error getting sizes:', error);
    }
  }
  getProductSize(sizeTypeID) {
    this.productSizeArray = [];

    this._productService.getCustomSizesObservableDefault(sizeTypeID).subscribe(sizes => {
      const selectedSizeType = this.productSizeTypeArray.find(size => size.id === sizeTypeID);
      const ordersize = selectedSizeType.sortOrder.map(idorder => {
        const positionsize = sizes.find(size => size.id === idorder);
        positionsize['isSelected'] = idorder === selectedSizeType.sortOrder[0];
        return positionsize;
      });

      this.sizeRow = {
        sizeType: JSON.parse(JSON.stringify(this.productSizeTypeArray)),
        sizes: JSON.parse(JSON.stringify(ordersize)),
        qty: 0
      };

      this.arraysizesqty = [JSON.parse(JSON.stringify(this.sizeRow))];
      this.arraysizeqtycopy = [JSON.parse(JSON.stringify(this.sizeRow))]
    });
  }

  setStartTime(i, event) {
    this.startTime[i].hour = event.target.value;
    this.validation();
  }
  validateStartTime() {
    let seenHours = {};
    this.startTimeInvalid = false;
    for (let i = 0; i < this.startTime.length; i++) {
      let hour = this.startTime[i].hour;
      if (seenHours[hour]) {
        this.startTime[i].isInvalid = true;
        this.startTimeInvalid = true;
      } else {
        seenHours[hour] = true;
        if (this.startTime[i].hasOwnProperty('isInvalid')) {
          delete this.startTime[i].isInvalid;
        }
      }
    }
    if (this.startTime.length == 0) {
      this.startTimeInvalid = true;
    }
  }
  addNewStartTime() {
    const newTime = { hour: "12:00" };
    this.startTime.push(newTime);
    this.validation();
  }
  deleteStartTime(i) {
    this.startTime.splice(i, 1)
    this.validation();
  }
  nextStep(type, action) {
    if (action == "edit") {
      type === "info" ? this.submittedInfo = true : ""
      type === "qty" ? this.submittedQty = true : ""
      type === "qty" ? this.submitEditProduct() : ""
    } else {
      type === "info" ? this.submittedInfo = true : ""
      type === "qty" ? this.submittedQty = true : ""
      type === "qty" ? this.submitProduct() : ""
    }

  }

  changeProductType() {
    if (this.qtyproducts.length > 0) {
      Swal.fire({
        title: "Warning!",
        html: "You have previously created products in the Quantity tab, if you change the type of product, the products you have created will be completely deleted, are you sure?",
        icon: "info",
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCancelButton: true
      }).then(result => {
        if (result.isConfirmed) {
          this.qtyproducts.length = 0;
          this.arraysizesqty = JSON.parse(JSON.stringify(this.arraysizeqtycopy));
          this.validation();
          this.checkRequiredFields();
        } else {
          this.productType === "rental" ? this.productType = "tour" : this.productType = "rental";
        }
      })
    } else {
      this.arraysizesqty = JSON.parse(JSON.stringify(this.arraysizeqtycopy));
      this.validation();
    }
  }
  trackProduct(type) {
    let html = ""
    let title = ""
    if (type) {
      title = "Track this product"
      html = "Are you sure you want to track this product? You won't be able to change the option later."
    } else {
      title = "Do not Track this product"
      html = "Are you sure you don't want to track this product? You won't be able to change the option later."
    }
    Swal.fire({
      title: title,
      html: html,
      icon: "info",
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCancelButton: true
    }).then(result => {
      if (result.isConfirmed) {
        this.selectedTrack = true;
        this.trackProducts = type;
      }
    })
  }
  changeProductRental() {
    if (this.minimumStartPrice == true) {
      this.minimumStartPrice = false;
      this.defaultTime = true;
    } else {
      this.minimumStartPrice = true;
      this.defaultTime = false;
    }
    if (this.typeaction == "edit") {
      if (this.minimumStartPrice) {
        this.validation();
        this.informationFormEditGroup.get('minimumTime').disable();
      } else {
        this.startTimeInvalid = false;
        this.informationFormAddGroup.get('minimumTime').enable();
      }
    } else {
      if (this.minimumStartPrice) {
        this.validation();
        this.informationFormAddGroup.get('minimumTime').disable();
      } else {
        this.startTimeInvalid = false;
        this.informationFormAddGroup.get('minimumTime').enable();
      }
    }
  }
  updateProductInfo() {
    //Another idea, discuss it on meeting
  }
  createProductGroups() {
    Swal.fire({
      title: "Creating Products Groups and updating Products..."
    })
    Swal.showLoading();
    this._productService.getFullProducts().then(async products => {
      console.log(products)
      let productswithoutGroupID = products.filter(p => p.productGroupID === undefined);
      console.log(productswithoutGroupID)
      let groupByName = productswithoutGroupID.reduce((accumulator, product) => {
        let key = product.productName;
        if (!accumulator[key]) {
          accumulator[key] = { productName: key, products: [] };
        }
        accumulator[key].products.push(product);
        return accumulator;
      }, {});
      let arrayProductsByName: any = Object.values(groupByName);
      console.log(arrayProductsByName)
      for (let product of arrayProductsByName) {
        //Product Group Creation
        //Product Group Creation
        let groupObj = {
          groupName: product.products[0].productName ? product.products[0].productName : "",
          groupDescription: product.products[0].description ? product.products[0].description : "",
          groupNotes: product.products[0].notes ? product.products[0].notes : "",
          downtime: 30,
          minimumTime: 30,
          groupImage: product.products[0].imageUrl ? product.products[0].imageUrl : "",
          thumbnail: product.products[0].thumbnail ? product.products[0].thumbnail : "",
          isActive: true,
          isRetired: false,
          created: new Date(),
          categoryID: product.products[0].categoryID ? product.products[0].categoryID : "",
          categoryName: product.products[0].productCategory ? product.products[0].productCategory : "",
          companyID: this._currentUser.currentUser.companyId,
          isTracked: true,
          isTour: false,
          inventoryPageId: this.defaultInvID,
          isStartTime: false,
          //isStartTime: false,
          allowRentalByDay: product.products[0].allowRentalByDay,
          allowRentalByHour: product.products[0].allowRentalByHour,
          is24hrsPrice: false, //can change for some companies
          isAvailable: true,
          priceByDay: [],
          priceByHour: [],
          productType: 'rental',
          productLocationID: product.products[0].productLocationID ? product.products[0].productLocationID : "",
          productLocationName: product.products[0].productLocationName ? product.products[0].productLocationName : "",
          availableLocations: [product.products[0].productLocationID ? product.products[0].productLocationID : ""],
          rules: [
            { isActive: true, type: "Checkout" },
            { isActive: true, type: "Checkin" },
            { isActive: true, type: "Maintenance" }
          ],
          waivers: product.products[0].waivers ? product.products[0].waivers : [],
          startTime: [],
        }
        if (product.products[0].allowRentalByDay) {
          let priceByDay = [{
            day: 1,
            increment: 0,
            price: product.products[0].price
          }]
          groupObj['priceByDay'] = priceByDay;
        }
        if (product.products[0].allorRentalByHour) {
          let priceByHour = Object.entries(product.products[0].hours).map(([hour, value]: [string, any]) => {
            return { hour: hour, price: value.price };
          });
          groupObj['priceByHour'] = priceByHour;
        }

        console.log(groupObj)
        let productGroupID = "";
        //Saving Product Group and getting the ID
        await this._productService.saveNewGroup(groupObj).then(async result => {
          productGroupID = result.id;
          this.createLog("productGroup", productGroupID, "Product Group created with " + product.products.length + " products");
          for (let prod of product.products) {
            prod['productGroupID'] = productGroupID;
            prod['downtime'] = 30;
            prod['isTour'] = false;
            prod['isTracked'] = true;
            prod['isStartTime'] = false;
            prod['minimumTime'] = 30;
            prod['isTour'] = false;
            prod['isTour'] = false;
            await this._productService.updateProduct(prod.id, prod);
          }
          this.getProducts(true);
        })
      }
      Swal.close();
      Swal.fire({
        title: "Completed!",
        icon: "success"
      })
    })
  }
  addSizeRow() {
    if (this.trackProducts && this.arraysizesqty.length > 0) {
    const clonedSizeFirst = JSON.parse(JSON.stringify(this.arraysizesqty[0]));
    clonedSizeFirst['qty'] = 0;
    delete clonedSizeFirst['fromdb']
    if (this.arraysizesqty.length > 0) {
      this.arraysizesqty.push(clonedSizeFirst);
    } else {
      this.arraysizesqty = [clonedSizeFirst];
    }

    } else {
      const clonedSizeRow = JSON.parse(JSON.stringify(this.sizeRow));
      clonedSizeRow['newSize'] = true;
      if (this.arraysizesqty.length > 0) {
        this.arraysizesqty.push(clonedSizeRow);
      } else {
        this.arraysizesqty = [clonedSizeRow];
      }
    }
    this.checkRequiredFields();
  }
  editSizeRow(i, type, event, action?) {
    let isize = 0
    if (type == "qty") {
      this.detectProductQty(i, Number(event.target.value), action)
    } else if (type == "type") {
      if (event.target.value == "redirect") {
        this.sizesView();
      } else {
        const sizetype = this.arraysizesqty[i]
        sizetype.sizeType.forEach((size, is) => {
          if (size.id == event.target.value) {
            isize = is
            this.arraysizesqty[i].sizeType[is]['isSelected'] = true
          } else {
            this.arraysizesqty[i].sizeType[is]['isSelected'] = false
          }
        });
        this.updateSizeTypeRow(event.target.value, i, isize);
      }

    } else if (type == "size") {
      let productSize = ""
      let productSizeID = ""
      const size = this.arraysizesqty[i]
      size.sizes.forEach((size, is) => {
        if (size.id == event.target.value) {
          isize = is
          this.arraysizesqty[i].sizes[is]['isSelected'] = true
          productSizeID = this.arraysizesqty[i].sizes[is].id;
          productSize = this.arraysizesqty[i].sizes[is].size;
        } else {
          this.arraysizesqty[i].sizes[is]['isSelected'] = false
        }
      });
      const arraySizeProduct = this.qtyproducts.filter(p => p.position == i)
      arraySizeProduct.forEach((product, index) => {
        arraySizeProduct[index].product["productSizeID"] = productSizeID;
        arraySizeProduct[index].product["productSize"] = productSize;
      })
      this.deleteAllProductsType(i);
      this.addNewProductsType(this.arraysizesqty[i], i, arraySizeProduct.length, 'add', arraySizeProduct);
      this.sortQtyArray();
      this.checkQtyfields();
    }
  }
  updateSizeTypeRow(sizeTypeId, i, isize) {
    this._productService.getCustomSizesObservableDefault(sizeTypeId).subscribe(sizes => {
      let ordersize = []
      this.arraysizesqty[i].sizeType[isize].sortOrder.forEach((idorder, indexorder) => {
        const positionsize = sizes.find(size => size.id === idorder)
        indexorder == 0 ? positionsize['isSelected'] = true : positionsize['isSelected'] = false
        ordersize.push(JSON.parse(JSON.stringify(positionsize)))
      });
      this.arraysizesqty[i]['sizes'] = JSON.parse(JSON.stringify(ordersize));
      const selectedType = this.arraysizesqty[i].sizeType.find(type => type.isSelected == true)
      const selectedSize = this.arraysizesqty[i].sizes.find(size => size.isSelected == true)
      //if (i == 0 && this.trackProducts) {
      this.arraysizesqty.forEach((arraysize, index) => {
        if (index != 0) {
          this.arraysizesqty[index]['sizeType'] = JSON.parse(JSON.stringify(this.arraysizesqty[i].sizeType));
          this.arraysizesqty[index]['sizes'] = JSON.parse(JSON.stringify(ordersize));
        }
      })
      this.qtyproducts.forEach((product, index) => {
        this.qtyproducts[index].product["productSizeTypeID"] = selectedType.id;
        this.qtyproducts[index].product["productSizeType"] = selectedType.productType;
        this.qtyproducts[index].product["productSizeID"] = selectedSize.id;
        this.qtyproducts[index].product["productSize"] = selectedSize.size;
      })
      /*} else {
        const arrayTypeProduct = this.qtyproducts.filter(p => p.position == i)
        arrayTypeProduct.forEach((product, index) => {
          arrayTypeProduct[index].product["productSizeTypeID"] = selectedType.id;
          arrayTypeProduct[index].product["productSizeType"] = selectedType.productType;
          arrayTypeProduct[index].product["productSizeID"] = selectedSize.id;
          arrayTypeProduct[index].product["productSize"] = selectedSize.size;
        })
      }*/
    });
  }
  deleteSize(i, action?) {
    let deleteProducts = true
    if (action == "edit") {
      if (this.trackProducts) {
        if (this.arraysizesqty[i].fromdb) {
          Swal.fire({
            title: "Oops!",
            html: "These products have traceability activated, you cannot delete a size group. If you want to delete products you must do it manually by selecting the products.",
            icon: "error"
          })
          deleteProducts = false
        }
      }
    }
    if (deleteProducts) {
      Swal.fire({
        title: "Are you sure?",
        html: "If you remove this option, all products created in this row will be deleted.",
        icon: "question",
        allowEnterKey: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: true
      }).then(result => {
        if (result.isConfirmed) {
          this.deleteAllProductsType(i);
          this.arraysizesqty.splice(i, 1)
          this.changePositionArraySizes(i);
          this.checkRequiredFields()
        }
      })
    }
  }
  changePositionArraySizes(i: number): void {
    this.qtyproducts = this.qtyproducts.map(product => {
      if (product.position > i) {
        return { ...product, position: product.position - 1 };
      }
      return product;
    });
  }
  public startLocation: any
  getLocations() {
    this.subscription = this._locationService.getAllProductsLocationsRebuild(this.company.id).subscribe(locations => {
      this.locations = locations;
      this.locations.forEach((location, index) => {
        this.locations[index]['notShow'] = true;
      });
      const find = locations.find(location => location.isDefault === true);
      if (find) {
        if (this.typeaction == "add") {
          this.startLocation = find;
          this.selectAvailableLocations(find);
          this.quantityFormAddGroup.get('productLocationID').setValue(find.id);
          this.quantityFormAddGroup.get('productLocationName').setValue(find.name);
          if (this.productMainObject != undefined) {
            this.productMainObject.productLocationID = find.id
            this.productMainObject.productLocationName = find.name
          }

        }
        this.checkShareLocation(find);
      }
    })
  }

  getWaivers() {
    this.waivers = [];
    this.waiversedit = [];
    this._rentalService.getWaiversByCompany(this._currentUser.currentUser.companyId).then((waivers) => {
      this.waivers = waivers;
      this.waiversedit = JSON.parse(JSON.stringify(waivers));
    });
  }
  selectWaiver(waiver, type) {
    if (type === 'add') {
      const find = this.selectedWaiver.find(w => w === waiver)
      if (find) {
        this.selectedWaiver = this.selectedWaiver.filter(function (e) { return e !== waiver; });;
      } else {
        this.selectedWaiver.push(waiver)
      }
    } else {
      const find = this.selectedEditWaiver.find(w => w === waiver)
      if (find) {
        this.selectedEditWaiver = this.selectedEditWaiver.filter(function (e) { return e !== waiver; });;
      } else {
        this.selectedEditWaiver.push(waiver)
      }
    }
    this.updateProductsInfo()
  }
  selectAvailableLocations(location, change?) {
    const index = this.selectedLocations.findIndex((loc) => loc.id === location.id);
    if (index > -1) {
      if (this.typeaction == "edit" && location.id != this.quantityFormEditGroup.value.productLocationID) {
        this.selectedLocations.splice(index, 1);
      } else if (this.typeaction == "add" && location.id != this.quantityFormAddGroup.value.productLocationID) {
        this.selectedLocations.splice(index, 1);
      }
    } else {
      this.selectedLocations.push(location);
    }
    this.qtyproducts.forEach((product: any) => {
      product.availableLocations = this.selectedLocations.map((loc) => loc.id);
    });
    const locIndex = this.locations.findIndex((loc) => loc.id === location.id);
    if (locIndex > -1) {
      const locationid = this.typeaction == "edit" ? this.quantityFormEditGroup.value.productLocationID : this.quantityFormAddGroup.value.productLocationID
      if (location.id == locationid && change != undefined) {
        if (this.typeaction == "edit" && location.id === this.quantityFormEditGroup.value.productLocationID) {
          this.locations[locIndex].isSelected = true;
        } else if (this.typeaction == "add" && location.id === this.quantityFormAddGroup.value.productLocationID) {
          this.locations[locIndex].isSelected = true;
        } else {
          this.locations[locIndex].isSelected = !this.locations[locIndex].isSelected;
        }
      } else {
        this.locations[locIndex].isSelected = !this.locations[locIndex].isSelected;
      }
    }
  }
  changeQuantityType() {
    this.quantitytype ? this.quantitytype = false : this.quantitytype = true
    this.loadingLocSize = true;
    this.quantitytype ? this.getLocationSizes() : ""
  }
  async getLocationSizes() {
    let sizes: Sizes[] = []
    this.quantityLocSize = []
    let promises = [];
    this.productSizeTypeArray.forEach(async sizeType => {
      let promise = this._productService.getCustomSizesPromise(sizeType.id).then((size) => {
        sizes.push({ ...sizeType, sizesgroup: JSON.parse(JSON.stringify(size)) })
      });

      promises.push(promise);
    })
    Promise.all(promises).then((_) => {
      setTimeout(() => {
        this.locations.forEach(location => {
          this.quantityLocSize.push(
            {
              location: location,
              sizeType: sizes,
            }
          )
        })
        this.loadingLocSize = false;
      }, 1000);
    });

  }

  createProductObject(sizelocation, actiontype?) {
    let maphour = {}
    this.hours.forEach((element) => {
      maphour[element.hours] = { price: element.price };
    });
    let waiver = []
    this.selectedWaiver.forEach((element) => {
      waiver.push(element.id);
    });
    let availableLocations = []
    this.selectedLocations.forEach((element) => {
      availableLocations.push(element.id);
    })
    if (this.typeaction == "add") {
      this.productMainObject = {
        isSelected: false,
        companyID: this.company.id,
        companyName: this.company.companyName,
        description: this.informationFormAddGroup.value.productDescription,
        downtime: this.informationFormAddGroup.value.downtime,
        //leadtime: this.informationFormAddGroup.value.leadtime,
        //hours: maphour,
        imageUrl: this.imgGallery,
        isActive: true,
        isAvailable: true,
        isTracked: this.trackProducts,
        isTour: this.isTour,
        isStartTime: this.isStartTime,
        //isStartTime: false,
        isCleaning: false,
        isDamaged: false,
        isMaintenance: false,
        isRetired: false,
        isTuneUp: false,
        maintenanceReason: "",
        //minimumTime: !this.isTour ? Number(this.informationFormAddGroup.value.minimumTime) : 0,
        minimumTime: 30,
        notes: this.informationFormAddGroup.value.productNotes,
        productName: this.informationFormAddGroup.value.productName,
        productNumber: 0,
        productLocationID: sizelocation.productLocationID,
        productLocationName: sizelocation.productLocationName,
        productSizeID: sizelocation.productSizeID,
        productSize: sizelocation.productSize,
        productSizeTypeID: sizelocation.productSizeTypeID,
        productSizeType: sizelocation.productSizeType,
        status: "",
        thumbnail: this.thumbnailGallery,
        waivers: waiver,
      }
    } else if (this.typeaction == "edit") {
      if (actiontype == "editqty") {
        this.productMainObject = {
          isSelected: false,
          companyID: this.company.id,
          companyName: this.company.companyName,
          description: this.informationFormEditGroup.value.productDescription,
          downtime: this.informationFormEditGroup.value.downtime,
          //leadtime: this.informationFormEditGroup.value.leadtime,
          //hours: maphour,
          imageUrl: this.informationFormEditGroup.value.imageUrl,
          isActive: true,
          isAvailable: true,
          isTracked: this.trackProducts,
          isTour: this.isTour,
          isStartTime: this.isStartTime,
          //isStartTime: false,
          isCleaning: false,
          isDamaged: false,
          isMaintenance: false,
          isRetired: false,
          isTuneUp: false,
          maintenanceReason: "",
          productNumber: this.productNumbers ? 0 : "",
          //minimumTime: !this.isTour ? Number(this.informationFormEditGroup.value.minimumTime) : 0,
          minimumTime: 30,
          notes: this.informationFormEditGroup.value.productNotes,
          productName: this.informationFormEditGroup.value.productName,
          productLocationID: sizelocation.productLocationID,
          productLocationName: sizelocation.productLocationName,
          productSizeID: sizelocation.productSizeID,
          productSize: sizelocation.productSize,
          productSizeTypeID: sizelocation.productSizeTypeID,
          productSizeType: sizelocation.productSizeType,
          status: "",
          thumbnail: this.informationFormEditGroup.value.thumbnail,
          waivers: waiver,
        }
      } else {
        this.productMainObject = {
          isSelected: false,
          companyID: this.company.id,
          companyName: this.company.companyName,
          description: this.informationFormEditGroup.value.productDescription,
          downtime: this.informationFormEditGroup.value.downtime,
          //leadtime: this.informationFormEditGroup.value.leadtime,
          //hours: maphour,
          imageUrl: this.informationFormEditGroup.value.imageUrl,
          isActive: true,
          isAvailable: true,
          isTracked: this.trackProducts,
          isTour: this.isTour,
          isStartTime: this.isStartTime,
          //isStartTime: false,
          isCleaning: false,
          isDamaged: false,
          isMaintenance: false,
          isRetired: false,
          isTuneUp: false,
          maintenanceReason: "",
          //minimumTime: !this.isTour ? Number(this.informationFormEditGroup.value.minimumTime) : 0,
          minimumTime: 30,
          notes: this.informationFormEditGroup.value.productNotes,
          productName: this.informationFormEditGroup.value.productName,
          productLocationID: sizelocation.productLocationID,
          productLocationName: sizelocation.productLocationName,
          status: "",
          thumbnail: this.informationFormEditGroup.value.thumbnail,
          waivers: waiver,
        }
      }
      this.productMainObject['isStartTime'] == true ? this.productMainObject['startTime'] = this.startTime : ""
    }
  }

  detectProductQty(i, qtynumber: number, action?) {
    let row = this.arraysizesqty[i]
    if (row.qty == 0 && qtynumber > 0) { //that means that new products are going to be created
      this.addNewProductsType(row, i, qtynumber, 'new', qtynumber);
      this.sortQtyArray();
      if (this.productNumbers) {
        this.validateProductNumber();
        this.validateProductNumberArray();
      }
    } else if (qtynumber == 0 || qtynumber == undefined) {
      if (action == "edit" && this.trackProducts) {
        Swal.fire({
          title: "Oops!",
          html: "This product has traceability activated, you cannot change the quantity to a lower one. If you want to delete products you must do it manually by selecting the products.",
          icon: "error"
        })
        let id = "qtysize" + i.toString();
        const input = document.getElementById(id) as HTMLInputElement | null
        input.value = this.arraysizesqty[i].qty;

      } else {
        this.deleteAllProductsType(i);
        this.arraysizesqty[i].qty = 0;
        this.sortQtyArray();
        this.checkRequiredFields();
      }
    } else {
      const createdproducts = this.qtyproducts.filter(p => p.position == i)
      const editedproducts = this.qtyproducts.filter(p => p.position == i && p.product.isEdited == true)
      const savedproducts = this.qtyproducts.filter(p => p.position == i && p.product.id)
      let qtycopy = row.fromdb ? this.arraysizesqty[i].copyqty : createdproducts.length;
      let newProductRow = row.fromdb ? true : false;
      if (qtynumber > createdproducts.length) {
        let newproductsqty = qtynumber - createdproducts.length;
        this.addNewProductsType(row, i, qtynumber, 'new', newproductsqty);
        this.sortQtyArray();
        if (this.productNumbers) {
          this.validateProductNumber();
          this.validateProductNumberArray();
        }
      } else if (qtynumber < qtycopy) {
        if (action == "edit" && this.trackProducts && newProductRow) {
          Swal.fire({
            title: "Oops!",
            html: "This product has traceability activated, you cannot change the quantity to a lower one. If you want to delete products you must do it manually by selecting the products.",
            icon: "error"
          })
          let id = "qtysize" + i.toString();
          const input = document.getElementById(id) as HTMLInputElement | null
          input.value = this.arraysizesqty[i].qty;
        } else {
          if (qtynumber >= editedproducts.length) {
            const qtydeleteProducts = createdproducts.length - qtynumber;
            const noEditedproducts = this.qtyproducts.filter(p => p.position == i && (p.product.isEdited == false || p.product.isEdited == undefined))
            noEditedproducts.length = noEditedproducts.length - qtydeleteProducts;
            var newProducts
            editedproducts.length > 0 ? newProducts = [...noEditedproducts, ...editedproducts] : newProducts = [...noEditedproducts]
            this.deleteAllProductsType(i);
            this.addNewProductsType(row, i, qtynumber, 'add', newProducts);
            this.sortQtyArray();
          } else {
            Swal.fire({
              title: "Changes on Products",
              html: "There are some products that have some changes, if you change the quantity, this changes gonna be deleted, are you sure?",
              icon: "question",
              showCancelButton: true,
              allowEnterKey: false,
              allowEscapeKey: false,
              allowOutsideClick: false
            }).then(result => {
              if (result.isConfirmed) {
                let qtydeleteProducts = createdproducts.length - qtynumber;
                const noEditedproducts = this.qtyproducts.filter(p => p.position == i && (p.product.isEdited == false || p.product.isEdited == undefined))
                qtydeleteProducts = qtydeleteProducts - noEditedproducts.length
                editedproducts.length = editedproducts.length - qtydeleteProducts;
                var newProducts
                newProducts = [...editedproducts]
                this.deleteAllProductsType(i);
                this.addNewProductsType(row, i, qtynumber, 'add', newProducts);
                this.sortQtyArray();
                this.checkRequiredFields(); //Validate if there is an empty field
              } else {
                const totalproducts = this.qtyproducts.filter(p => p.position == i);
                this.arraysizesqty[i].qty = totalproducts.length;
                this.arraysizesqty[i].copyqty = totalproducts.length;
              }
            })
          }
          if (this.productNumbers) {
            this.validateProductNumber();
            this.validateProductNumberArray();
          }
        }
      } else if (qtynumber >= qtycopy) {
        if (action == "edit" && this.trackProducts) {
          let deletedProducts = 0;
          const qtydeleteProducts = createdproducts.length - qtynumber;
          for (let j = 0; j < this.qtyproducts.length; j++) {
            if (deletedProducts >= qtydeleteProducts) {
              break;
            }
            if (!this.qtyproducts[j].product.id && this.qtyproducts[j].position === i) {
              this.qtyproducts.splice(j, 1);
              j--;
              deletedProducts++;
            }
          }
          this.sortQtyArray();
        }
      }
    }
  }
  defineCategory(event) {
    if (this.selectedCategory != undefined) {
      this.categoryInvalid = false;
      const categoryObj = this.categories.find(c => c.id === event);
      this.categoryName = categoryObj.title;
    } else {
      this.categoryInvalid = true;
    }
  }
  productQtyChange(i, qtytnumber: number, action?) {

  }
  deleteAllProductsType(i) {
    const deleteProducts = this.qtyproducts.filter(p => p.position == i)
    deleteProducts.forEach(product => {
      this.qtyproducts.splice(this.qtyproducts.indexOf(product), 1)
    })
  }
  sortQtyArray() {
    this.qtyproducts.sort(function (a, b) {
      if (a.position < b.position) {
        return -1;
      }
      else if (a.position > b.position) {
        return 1;
      }
      else {
        if (a.product.productSizeType < b.product.productSizeType) {
          return -1;
        }
        else if (a.product.productSizeType > b.product.productSizeType) {
          return 1;
        }
        return 0;
      }
    });
  }
  addNewProductsType(row, i, qtynumber, type, newProducts?) {
    if (type == "add") { //add is when there was some products and the user edit the quantity
      newProducts.forEach(product => {
        this.qtyproducts.push({
          isSelected: false,
          position: i, //Meaning to the position of the array size qty
          product: product.product,
        })
      });
    } else {
      const size = row.sizes.find(size => size.isSelected == true);
      const sizeType = row.sizeType.find(size => size.isSelected == true);
      this.productQty = qtynumber;
      this.arraysizesqty[i].qty = qtynumber;
      let sizelocation = {
        productLocationID: this.typeaction == "edit" ? this.quantityFormEditGroup.value.productLocationID : this.quantityFormAddGroup.value.productLocationID,
        productLocationName: this.typeaction == "edit" ? this.quantityFormEditGroup.value.productLocationName : this.quantityFormAddGroup.value.productLocationName,
        productSizeID: size.id,
        productSize: size.size,
        productSizeTypeID: sizeType.id,
        productSizeType: sizeType.productType,
      }
      this.createProductObject(sizelocation, 'editqty');
      for (let j = 0; j < newProducts; j++) {
        this.qtyproducts.push({
          isSelected: false,
          position: i, //Meaning to the position of the array size qty
          product: { ...this.productMainObject }
        })
      }
    }
    this.checkRequiredFields(); //Validate if there is an empty field
  }
  checkEditFields(type?) {
    this.productEditForm.valid ? this.validateEditRequired = true : this.validateEditRequired = false;
  }

  checkRequiredFields(type?) {
    if (this.typeaction == "add") {
      if (this.informationFormAddGroup.valid) {
        this.validateRequired = true;
        this.quantityFormAddGroup.get('validateRequired').setValue(true);
      } else {
        this.validateRequired = false;
        this.quantityFormAddGroup.get('validateRequired').setValue(false);
      }
      this.arraysizesqty.forEach(row => {
        if (row.qty <= 0) {
          this.validateRequired = false;
          this.quantityFormAddGroup.get('validateRequired').setValue(false);
        }
      });
      if (type == "location") {
        const find = this.locations.find(location => location.id === this.quantityFormAddGroup.value.productLocationID);
        if (find) {
          //this.removeIsSelected();
          this.selectAvailableLocations(find, true);
          this.checkShareLocation(find);
          if (!this.isLeadtime) {
            this.locations.forEach((location, index) => {
              if (find.id == location.id) {
                this.locations[index]['notShow'] = true;
              } else {
                this.locations[index]['notShow'] = false;
              }
            })
          } else {
            this.locations.forEach((location, index) => {
              this.locations[index]['notShow'] = true;
            })
          }
          this.quantityFormAddGroup.get('productLocationID').setValue(find.id)
          this.quantityFormAddGroup.get('productLocationName').setValue(find.name)
          this.qtyproducts.forEach((product, index) => {
            this.qtyproducts[index].product.productLocationID = find.id;
            this.qtyproducts[index].product.productLocationName = find.name;
          })
          this.productMainObject['productLocationID'] = find.id;
          this.productMainObject['productLocationName'] = find.name;
        }
      }
    } else if (this.typeaction == "edit") {
      if (this.informationFormEditGroup.valid) {
        this.validateRequired = true;
        this.quantityFormEditGroup.get('validateRequired').setValue(true);
      } else {
        this.validateRequired = false;
        this.quantityFormEditGroup.get('validateRequired').setValue(false);
      }
      this.arraysizesqty.forEach(row => {
        if (row.qty <= 0) {
          this.validateRequired = false;
          this.quantityFormEditGroup.get('validateRequired').setValue(false);
        }
      });
      if (type == "location") {
        const find = this.locations.find(location => location.id === this.quantityFormEditGroup.value.productLocationID);
        if (find) {
          //this.removeIsSelected();
          this.selectAvailableLocations(find, true);
          this.checkShareLocation(find);
          if (!this.isLeadtime) {
            this.locations.forEach((location, index) => {
              if (find.id == location.id) {
                this.locations[index]['notShow'] = true;
              } else {
                this.locations[index]['notShow'] = false;
              }
            })
          } else {
            this.locations.forEach((location, index) => {
              this.locations[index]['notShow'] = true;
            })
          }
          this.quantityFormEditGroup.get('productLocationID').setValue(find.id)
          this.quantityFormEditGroup.get('productLocationName').setValue(find.name)
          this.qtyproducts.forEach((product, index) => {
            this.qtyproducts[index].product.productLocationID = find.id;
            this.qtyproducts[index].product.productLocationName = find.name;
          })
          this.productMainObject['productLocationID'] = find.id;
          this.productMainObject['productLocationName'] = find.name;
        }
      }
    }

  }
  removeIsSelected() {
    this.selectedLocations = [];
    this.locations.forEach((location, index) => {
      delete this.locations[index].isSelected
    });
  }
  checkShareLocation(location) {
    location.isLeadtime ? this.isLeadtime = true : this.isLeadtime = false;

  }
  checkQtyfields(i?) {
    if (this.typeaction == "add") {
      if (this.informationFormAddGroup.valid) {
        this.validateRequired = true;
        this.quantityFormAddGroup.get('validateRequired').setValue(true);
      } else {
        this.validateRequired = false;
        this.quantityFormAddGroup.get('validateRequired').setValue(false);
      }

      this.arraysizesqty.forEach(row => {
        if (row.qty <= 0) {
          this.validateRequired = false;
          this.quantityFormAddGroup.get('validateRequired').setValue(false);
        }
      });
    } else if (this.typeaction == "edit") {
      if (this.informationFormEditGroup.valid) {
        this.validateRequired = true;
        this.quantityFormEditGroup.get('validateRequired').setValue(true);
      } else {
        this.validateRequired = false;
        this.quantityFormEditGroup.get('validateRequired').setValue(false);
      }

      this.arraysizesqty.forEach(row => {
        if (row.qty <= 0) {
          this.validateRequired = false;
          this.quantityFormEditGroup.get('validateRequired').setValue(false);
        }
      });
    }

  }

  hideAccordion(type) {
    if (type == "info") {
      this.productInformation ? this.productInformation = false : this.productInformation = true;
      this.productQuantity = false;
      this.productRules = false;
      this.productPrice = false;
    } else if (type == "qty") {
      this.productQuantity ? this.productQuantity = false : this.productQuantity = true;
      this.productInformation = false;
      this.productRules = false;
      this.productPrice = false;
    } else if (type == "rules") {
      this.productRules ? this.productRules = false : this.productRules = true;
      this.productInformation = false;
      this.productQuantity = false;
      this.productPrice = false;
    } else {
      this.productPrice ? this.productPrice = false : this.productPrice = true;
      this.productRules = false;
      this.productInformation = false;
      this.productQuantity = false;
    }

  }

  changeProductNumber(event, i) {
    this.qtyproducts[i].product.productNumber = event.target.value;
    this.validateProductNumber();
    this.validateProductNumberArray();
  }
  changeIfProductNumber() {
    if (this.productNumbers) {
      this.validateProductNumber()
      this.validateProductNumberArray();
    } else {
      this.repeatedNumber = false;
      this.existingNumber = false;
    }
  }
  validateProductNumber() {
    let hasRepeated = false;
    this.qtyproducts.forEach((product) => {
      const existing = this.qtyproducts.filter(find => find.product.productNumber.toString() === product.product.productNumber.toString());
      if (existing.length > 1) {
        product.product.isRepeated = true;
        hasRepeated = true;
      } else {
        delete product.product.isRepeated;
      }
    });
    this.repeatedNumber = hasRepeated;
  }
  validateProductNumberArray() {
    let hasRepeated = false;
    this.qtyproducts.forEach((product) => {
      const existing = this.products.find(find => find.productNumber.toString() === product.product.productNumber.toString());
      if (existing && (existing.id != product.product.id)) {
        product.product.isExisted = true;
        hasRepeated = true;
      } else {
        delete product.product.isExisted;
      }
    });
    this.existingNumber = hasRepeated;
  }
  deleteHour(hour, i) {
    this.hours.splice(i, 1);
  }

  async editProductList(product, position, action) {
    this.productEditCopy = JSON.parse(JSON.stringify(product));
    this.productEditPosition = position;
    this.productEditForm.get("productName").setValue(product.product.productName);
    this.productEditForm.get("downtime").setValue(product.product.downtime);
    this.productEditForm.get("id").setValue(product.product.id);
    //this.productEditForm.get("leadtime").setValue(product.product.leadtime);
    this.productEditForm.get("imageUrl").setValue(product.product.imageUrl);
    this.productEditForm.get("minimumTime").setValue(product.product.minimumTime);
    this.editImage = product.product.imageUrl;
    this.productEditForm.get("thumbnail").setValue(product.product.thumbnail);
    this.productEditForm.get("productNumber").setValue(product.product.productNumber);
    this.productEditForm.get("productSizeTypeID").setValue(product.product.productSizeTypeID);
    this.productEditForm.get("productSizeType").setValue(product.product.productSizeType);
    this.productEditForm.get("productLocationID").setValue(product.product.productLocationID);
    this.productEditForm.get("productLocationName").setValue(product.product.productLocationName);
    const sizeType = this.arraySizeTEdit.find(type => type.id === product.product.productSizeTypeID)
    let ordersize = []
    await this._productService.getCustomSizesPromise(this.productEditForm.value.productSizeTypeID).then(sizes => {
      sizeType.sortOrder.forEach((idorder, indexorder) => {
        const positionsize = sizes.find(size => size.id === idorder)
        positionsize.id == product.product.productSizeID ? positionsize['isSelected'] = true : positionsize['isSelected'] = false
        ordersize.push(JSON.parse(JSON.stringify(positionsize)))
      });
      this.arraySizeEdit = ordersize;
      this.productEditForm.get("productSizeID").setValue(product.product.productSizeID);
      this.productEditForm.get("productSize").setValue(product.product.productSize);
    })
    this.productEditForm.get("productDescription").setValue(product.product.description);
    this.productEditForm.get("productNotes").setValue(product.product.notes);
    this.selectedEditWaiver = JSON.parse(JSON.stringify(this.selectedWaiver));
    product.product.waivers.forEach((waiver) => {
      let waiverselected = this.waiversedit.find(w => w.id === waiver)
      this.waiversedit[this.waiversedit.indexOf(waiverselected)].isSelected = true;
    });
    this.checkEditFields();
  }
  selectSize(type) {
    if (type == "type") {
      this._productService.getCustomSizesObservableDefault(this.productEditForm.value.productSizeTypeID).subscribe(sizes => {
        this.arraySizeEdit = sizes;
        const selectedSizeType = this.arraySizeTEdit.find(sizetype => sizetype.id === this.productEditForm.value.productSizeTypeID);
        this.productEditForm.get("productSizeTypeID").setValue(selectedSizeType.id);
        this.productEditForm.get("productSizeType").setValue(selectedSizeType.productType);
      })
    } else if (type == "size") {
      const selectedSize = this.arraySizeEdit.find(sizetype => sizetype.id === this.productEditForm.value.productSizeID);
      this.productEditForm.get("productSizeID").setValue(selectedSize.id);
      this.productEditForm.get("productSize").setValue(selectedSize.size);
    }
  }
  backAddModal() {
    if (this.typeaction == "edit") {
      $('#modalProductEdit').modal('hide')
      $('#modalProductEditGroup').modal('show')
    } else {
      $('#modalProductEdit').modal('hide')
      $('#modalProductAdd').modal('show')
    }

  }
  cancelEditChanges() {
    this.backAddModal();
  }
  setProductRules(type, event) {
    const find = this.productRulesList.find(rule => rule.type === type)
    let position = this.productRulesList.indexOf(find);
    this.productRulesList[position].isActive = event.target.checked;
  }
  submitEditChanges() {
    const objectwaivers = this.selectedEditWaiver
    this.selectedEditWaiver = []
    objectwaivers.forEach(waiver => {
      this.selectedEditWaiver.push(waiver.id)
    })
    if (this.validateEditRequired) {
      this.productEditCopy.product = {
        companyID: this.company.id,
        companyName: this.company.companyName,
        description: this.productEditForm.value.productDescription,
        downtime: this.productEditForm.value.downtime,
        //leadtime: this.productEditForm.value.leadtime,
        //hours: maphour,
        id: this.productEditForm.value.id,
        imageUrl: this.productEditForm.value.imageUrl,
        isActive: true,
        isAvailable: true,
        isCleaning: false,
        isDamaged: false,
        isMaintenance: false,
        isRetired: false,
        isTuneUp: false,
        maintenanceReason: "",
        notes: this.productEditForm.value.productNotes,
        //price: this.productEditForm.value.price,
        productName: this.productEditForm.value.productName,
        productNumber: this.productEditForm.value.productNumber,
        productLocationID: this.productEditForm.value.productLocationID,
        productLocationName: this.productEditForm.value.productLocationName,
        productSizeID: this.productEditForm.value.productSizeID,
        productSize: this.productEditForm.value.productSize,
        productSizeTypeID: this.productEditForm.value.productSizeTypeID,
        productSizeType: this.productEditForm.value.productSizeType,
        status: "",
        thumbnail: this.productEditForm.value.thumbnail,
        waivers: this.selectedEditWaiver
      }
      if (JSON.stringify(this.productEditCopy.product) != JSON.stringify(this.qtyproducts[this.productEditPosition].product)) {
        this.qtyproducts[this.productEditPosition].product = this.productEditCopy.product;
        this.qtyproducts[this.productEditPosition].product.isEdited = true;
      }
      this.validateEditRequired = false;
      this.selectedEditWaiver = [];
      this.waiversedit.forEach((waiver, index) => {
        this.waiversedit[index].isSelected = false;
      });
      this.backAddModal();
    }
  }
  selectProduct(product, i) {
    this.qtyproducts[i].isSelected = !this.qtyproducts[i].isSelected;
    this.verifySelectedProduct();
  }
  verifySelectedProduct() {
    let selectedProduct = false;
    this.qtyproducts.forEach(product => {
      if (product.isSelected) {
        selectedProduct = true
        return false;
      }
      return true;
    })
    this.selectedProducts = selectedProduct;
  }
  UnselectProduct() {
    this.qtyproducts.forEach((product, index) => {
      this.qtyproducts[index]['isSelected'] = false;
    })
    this.verifySelectedProduct();
  }
  deleteSelectedProduct() {
    Swal.fire({
      title: "Are you sure?",
      html: "If you delete the selected products, the quantity for each size line will be updated and you will not be able to recover the information for the deleted products.",
      icon: "info",
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCancelButton: true
    }).then(result => {
      if (result.isConfirmed) {
        const newArray = this.qtyproducts.filter(product => !product.isSelected);
        this.qtyproducts = newArray;
        this.arraysizesqty.forEach((sizerow, index) => {
          this.arraysizesqty[index].qty = newArray.filter(product => product.position == index).length
        })
        this.verifySelectedProduct();
      }
    })

  }
  openImgGallery() {
    $('#imgGallery').modal('show');
  }
  closeImgGallery() {
    // closes imgGallery bootstrap modal
    $('#imgGallery').modal('hide');
  }
  deleteSingleProduct(i) {
    Swal.fire({
      title: "Are you sure?",
      html: "If you delete this product, you will not be able to recover it.",
      icon: "info",
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCancelButton: true
    }).then(result => {
      if (result.isConfirmed) {
        this.arraysizesqty[this.qtyproducts[i].position].qty -= 1;
        this.qtyproducts.splice(i, 1);
      }
    })
  }
  selectAllProducts(type) {
    this.qtyproducts.forEach((product, index) => {
      this.qtyproducts[index]['isSelected'] = type === "select" ? true : false;
    })
    type === "select" ? this.selectedProducts = false : this.selectedProducts = true;
  }
  addPriceProducts() {
    Swal.fire({
      title: "Pricing selected products"
    })
    Swal.showLoading();
    this.qtyproducts.forEach((product, index) => {
      if (product.isSelected) {
        if (this.isPriceByDay) {
          this.qtyproducts[index].product['is24hrsPrice'] = this.isPrice24hrs;
          this.qtyproducts[index].product['allowRentalByDay'] = this.isPriceByDay;
          this.qtyproducts[index].product['priceByDay'] = this.arrayPricesDayBase;
          this.qtyproducts[index]['priceDaySet'] = true;
        } else {
          this.qtyproducts[index].product['is24hrsPrice'] = false;
          this.qtyproducts[index].product['allowRentalByDay'] = false;
          delete this.qtyproducts[index].product['priceByDay']
          this.qtyproducts[index]['priceDaySet'] = false;
        }
        if (this.isPriceByHour) {
          this.qtyproducts[index].product['allowRentalByHour'] = this.isPriceByHour;
          this.qtyproducts[index].product['priceByHour'] = this.arrayPricesHourBase;
          this.qtyproducts[index]['priceHourSet'] = true;
        } else {
          this.qtyproducts[index].product['allowRentalByHour'] = false;
          delete this.qtyproducts[index].product['priceByHour'];
          this.qtyproducts[index]['priceHourSet'] = false;

        }
      }
    });
    Swal.close();
    Swal.fire({
      title: "Updated price on products",
      html: "The price has been updated on the products, the form data and selections will remain the same.",
      icon: "success"
    })
    this.resetPriceForm();
  }
  resetPriceForm() {
    this.isPrice24hrs = false;
    this.isPriceByDay = true;
    this.isPriceByHour = true;
    this.qtyproducts.forEach((product, index) => {
      this.qtyproducts[index]['isSelected'] = false;
    })
    this.arrayPricesDayBase = [{
      day: 1,
      price: 0,
      increment: 0,
      isZero: true
    }]
    this.arrayPricesHourBase = [{
      hour: 1,
      price: 0,
      isZero: true
    }]
    this.validatePriceDay();
  }
  usePrice(product, i) {
    this.resetPriceForm();
    this.isPrice24hrs = product.product.is24hrsPrice;
    if (product.product.priceByDay) {
      this.isPriceByDay = true;
      this.arrayPricesDayBase = JSON.parse(JSON.stringify(product.product.priceByDay));
    }
    if (product.product.priceByHour) {
      this.isPriceByHour = true;
      this.arrayPricesHourBase = JSON.parse(JSON.stringify(product.product.priceByHour));
    }
  }
  async submitProduct() {
    if (this.informationFormAddGroup.valid && this.quantityFormAddGroup.valid && this.rulesFormAddGroup.valid) {
      let availableLocations = []
      if (!this.isLeadtime) {
        const location = this.selectedLocations.find(element => element.id == this.quantityFormAddGroup.value.productLocationID)
        availableLocations = [location.id];
      } else {
        this.selectedLocations.forEach((element) => {
          availableLocations.push(element.id);
        })
      }
      this.productMainObject['rules'] = this.productRulesList;
      if (this.isPriceByDay && this.productType === 'rental') {
        this.productMainObject['is24hrsPrice'] = this.isPrice24hrs;
        this.productMainObject['allowRentalByDay'] = this.isPriceByDay;
        this.productMainObject['priceByDay'] = this.arrayPricesDayBase;
      } else {
        this.productMainObject['is24hrsPrice'] = false;
        this.productMainObject['allowRentalByDay'] = false;
        this.productMainObject['priceByDay'] = []
      }
      if (this.isPriceByHour) {
        this.productMainObject['allowRentalByHour'] = this.isPriceByHour;
        this.productMainObject['priceByHour'] = this.arrayPricesHourBase;
      } else {
        this.productMainObject['allowRentalByHour'] = false;
        this.productMainObject['priceByHour'] = []
      }
      let productGroup = {
        groupName: this.informationFormAddGroup.value.productName,
        groupDescription: this.informationFormAddGroup.value.productDescription,
        groupNotes: this.informationFormAddGroup.value.productNotes,
        downtime: this.informationFormAddGroup.value.downtime,
        minimumTime: (this.productType === "rental") ? 30 : 0,
        groupImage: this.imgGallery,
        thumbnail: this.thumbnailGallery,
        isActive: true,
        isRetired: false,
        created: new Date(),
        categoryID: this.selectedCategory ? this.selectedCategory : null,
        categoryName: this.categoryName ? this.categoryName : null,
        companyID: this._currentUser.currentUser.companyId,
        isTracked: this.trackProducts,
        isTour: this.productType === "tour",
        inventoryPageId: this.defaultInvID,
        isStartTime: (this.productType === "rental") ? false : true,
        //isStartTime: false,
        allowRentalByDay: this.productMainObject.allowRentalByDay,
        allowRentalByHour: this.productType === "tour" ? true : this.productMainObject.allowRentalByHour,
        is24hrsPrice: this.productMainObject.is24hrsPrice,
        isAvailable: this.productMainObject.isAvailable,
        priceByDay: this.productMainObject.priceByDay,
        priceByHour: this.productMainObject.priceByHour,
        productType: this.productType,
        productLocationID: this.productMainObject.productLocationID,
        productLocationName: this.productMainObject.productLocationName,
        availableLocations: availableLocations,
        rules: this.productMainObject.rules,
        waivers: this.productMainObject.waivers,
        startTime: this.productType === "tour" ? this.startTime : [],
      }
      if (this.productType === "tour") {
        productGroup['minProductsAttached'] = this.minProductsAttached;
        productGroup['maxProductsAttached'] = this.maxProductsAttached;
      }
      Swal.fire({
        title: "Saving new Product Group"
      })
      Swal.showLoading();
      let productGroupID = ""
      await this._productService.saveNewGroup(productGroup).then(result => {
        productGroupID = result.id;
        this.createLog("productGroup", productGroupID, "Product Group created with " + this.qtyproducts.length + " products");
        Swal.close();
        Swal.fire({
          title: "Saving new products"
        })
        Swal.showLoading();
        let totalProducts = this.company.totalProducts != undefined ? this.company?.totalProducts : 0;
        this.qtyproducts.forEach(async product => {
          totalProducts += 1;
          delete product.product['isSelected']
          delete product.product['isStartTime']
          delete product.product['isTour']
          delete product.product['minimumTime']
          product.product['productGroupID'] = productGroupID;
          product.product['automaticNumber'] = totalProducts;
          product.product['created'] = new Date();
          product.product['categoryID'] = this.selectedCategory ? this.selectedCategory : null;
          product.product['categoryName'] = this.categoryName ? this.categoryName : null;
          await this._productService.saveNewProduct(product.product).then(result => {
            this.createLog("product", result.id, "Product created");
          })
        })
        this.company['totalProducts'] = totalProducts;
        this._rentalService.updateAllCompany(this.company);
        this.informationFormAddGroup.reset();
        this.quantityFormAddGroup.reset();
        this.rulesFormAddGroup.reset();
        this.productEditForm.reset();
        $('#modalProductAdd').modal('hide')
        Swal.close();
        this.getProducts(true);
        Swal.fire({
          title: "Completed!",
          icon: "success"
        })
      })
    }
  }
  showAlertDescription(type) {
    let html = ""
    let title = ""
    if (type === "type") {
      title = "Product Type Definition"
      html = "This option allows you to choose between two types of product, Product Tour (with different options) or the Product Rental (with default options). The Product Tour does not allow you to choose sizes or define a price per day, while the Product rental does.";
    } else if (type === "minimum") {
      title = "Minimum Time Definition"
      html = "It refers to the time difference between each start time, for example, if the user defines a minimum time of 30, the intervals will be the following: 10:00 AM - 10:30 AM - 11:00 AM, etc."
    } else if (type === "start") {
      title = "Start Time Definition"
      html = "Set specific times when this product can be rented."
    } else if (type === "hour") {
      title = "Price By Hour"
      html = "This option allows you to define a price per hour where you must specify the total hours and the assigned price."
    } else if (type === "default") {
      title = "30 Minutes Increments",
        html = "This option adds an increment of 30 minutes between available hours, allowing hours to be displayed according to store hours as shown below."
    }
    Swal.fire({
      title: title,
      html: html,
      icon: "info"
    })
  }
  async submitEditProduct() {
    if (this.informationFormEditGroup.valid && this.quantityFormEditGroup.valid && this.rulesFormEditGroup.valid) {
      let availableLocations = []
      if (!this.isLeadtime) {
        const location = this.selectedLocations.find(element => element.id == this.quantityFormEditGroup.value.productLocationID)
        availableLocations = [location.id];
      } else {
        this.selectedLocations.forEach((element) => {
          availableLocations.push(element.id);
        })
      }
      this.productMainObject['rules'] = this.productRulesList;
      if (this.isPriceByDay && this.productType === "rental") {
        this.productMainObject['is24hrsPrice'] = this.isPrice24hrs;
        this.productMainObject['allowRentalByDay'] = this.isPriceByDay;
        this.productMainObject['priceByDay'] = this.arrayPricesDayBase;
      } else {
        this.productMainObject['is24hrsPrice'] = false;
        this.productMainObject['allowRentalByDay'] = false;
        this.productMainObject['priceByDay'] = []
      }
      if (this.isPriceByHour) {
        this.productMainObject['allowRentalByHour'] = this.isPriceByHour;
        this.productMainObject['priceByHour'] = this.arrayPricesHourBase;
      } else {
        this.productMainObject['allowRentalByHour'] = false;
        this.productMainObject['priceByHour'] = []
      }
      let waiversSelected = []
      this.selectedWaiver.forEach((waiver) => {
        waiversSelected.push(waiver.id);
      })
      let productGroup = {
        id: this.productGroupEdit.id,
        groupName: this.informationFormEditGroup.value.productName,
        groupDescription: this.informationFormEditGroup.value.productDescription,
        groupNotes: this.informationFormEditGroup.value.productNotes,
        downtime: this.informationFormEditGroup.value.downtime,
        minimumTime: (this.productType === "rental") ? ((this.defaultTime) ? 30 : 0) : 0,
        groupImage: this.imgGallery,
        thumbnail: this.thumbnailGallery,
        isActive: true,
        isRetired: false,
        lastModified: new Date(),
        categoryID: this.selectedCategory ? this.selectedCategory : null,
        categoryName: this.categoryName ? this.categoryName : null,
        companyID: this._currentUser.currentUser.companyId,
        isTracked: this.trackProducts,
        isTour: this.productType === "tour",
        isStartTime: (this.productType === "rental") ? this.minimumStartPrice : true,
        //isStartTime: false,
        allowRentalByDay: this.productMainObject.allowRentalByDay,
        allowRentalByHour: this.productType === "tour" ? true : this.productMainObject.allowRentalByHour,
        is24hrsPrice: this.productMainObject.is24hrsPrice,
        isAvailable: this.productMainObject.isAvailable,
        priceByDay: this.productMainObject.priceByDay,
        priceByHour: this.productMainObject.priceByHour,
        productType: this.productType,
        productLocationID: this.productMainObject.productLocationID,
        productLocationName: this.productMainObject.productLocationName,
        availableLocations: availableLocations,
        rules: this.productMainObject.rules,
        waivers: waiversSelected,
        startTime: this.productType === "tour" ? this.startTime : (this.minimumStartPrice ? this.startTime : [])
      }
      if (this.productType === "tour") {
        productGroup['minProductsAttached'] = this.minProductsAttached;
        productGroup['maxProductsAttached'] = this.maxProductsAttached;
      }
      Swal.fire({
        title: "Editing Product Group"
      })
      Swal.showLoading();
      let productGroupID = ""
      await this._productService.editProductGroup(productGroup).then(result => {
        productGroupID = productGroup.id;
        this.createLog("productGroup", productGroupID, "Product Group updated with " + this.qtyproducts.length + " products");
        Swal.close();
        Swal.fire({
          title: "Editing new products",
          html: "It may take a little time depending on whether the product was removed or updated."
        })
        Swal.showLoading();
        let totalProducts = this.company.totalProducts != undefined ? this.company?.totalProducts : 0;
        const productsGroup = this.products.filter(product => product.productGroupID === productGroupID && product.isActive == true);
        productsGroup.forEach(async productsaved => {
          productsaved['lastModified'] = new Date();
          const findProduct = this.qtyproducts.find(product => product.product.id === productsaved.id)
          productsaved.waivers = waiversSelected;
          if (findProduct) { //Means that the product keeps on the product group and its not deleted
            productsaved['categoryID'] = this.selectedCategory ? this.selectedCategory : null;
            productsaved['categoryName'] = this.categoryName ? this.categoryName : null;
            await this._productService.updateProduct(productsaved.id, productsaved)
            this.createLog("product", productsaved.id, "Product updated");
          } else {
            //Update to delete product
            productsaved['isActive'] = false;
            productsaved['isRetired'] = true;
            productsaved['categoryID'] = this.selectedCategory ? this.selectedCategory : null;
            productsaved['categoryName'] = this.categoryName ? this.categoryName : null;
            await this._productService.updateProduct(productsaved.id, productsaved)
            this.createLog("product", productsaved.id, "Product Deleted");
          }
        })
        const productsNotAdded = this.qtyproducts.filter(product => product.product.id === undefined)
        if (productsNotAdded) {
          productsNotAdded.forEach(async product => {
            totalProducts += 1;
            product.product['productGroupID'] = productGroupID;
            product.product['automaticNumber'] = totalProducts;
            product.product['lastModified'] = new Date();
            product.product.waivers = waiversSelected;
            product.product['categoryID'] = this.selectedCategory ? this.selectedCategory : null;
            product.product['categoryName'] = this.categoryName ? this.categoryName : null;
            await this._productService.saveNewProduct(product.product).then(result => {
              this.createLog("product", result.id, "Product Created");
            });
          })
        }
        this.company['totalProducts'] = totalProducts;
        this._rentalService.updateAllCompany(this.company);
        this.informationFormEditGroup.reset();
        this.quantityFormEditGroup.reset();
        this.rulesFormEditGroup.reset();
        this.productEditForm.reset();
        this.resetForm();
        $('#modalProductEditGroup').modal('hide')
        Swal.close();
        this.getProducts(true);
        Swal.fire({
          title: "Completed!",
          icon: "success"
        })
      })
    }
  }
  deleteAllProductByID() {
    Swal.fire({
      title: "Delete this product",
      html: "Are you sure you want to remove this product? The main group and the products that correspond to the group will be deleted",
      icon: "question",
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCancelButton: true
    }).then(async result => {
      if (result.isConfirmed) {

        let templates: Array<any> = await this.bookingService.getTemplatesForCompany() // Query for all templates and pass to update companyLogo NOTE: doesn't capture templates that have been "deleted"

        let productGroupWasFoundOnTemplate: boolean = false; // mark as true if the productGroup attempting to be deleted was found on any of the templates

        let html = `<span>In order to delete <b>${this.informationFormEditGroup.value.productName}</b>, it's necessary to first remove it from the following booking flows:</span>`

        if (templates.length > 0) {
          html += "<ul style='list-style: none; padding: 0px;'>"
          templates.forEach((t) => { // loop through all templates currently being utilized on company
            t['content'].forEach((cont) => { // loop through each templates contentArr
              if (cont.isItem) {
                if (cont.productGroupID === this.productGroupEdit.id) { // if the productGroup being deleted is found on the template (flag it)
                  productGroupWasFoundOnTemplate = true;
                  html += ` <li><a target="_blank" rel="noopener noreferrer" href="/partner/booking-suite/${t['id']}">${t['title']}</a></li>`
                }
              }
            })
          })
          html += "</ul>"
        }

        if (productGroupWasFoundOnTemplate) { // If productGroup was found - fire err msg and end function early
          Swal.fire({
            icon: "error",
            titleText: `Error | Product group found on active booking flows`,
            html: html,
          })
          return
        }

        Swal.fire({
          title: "Loading..."
        })
        Swal.showLoading();
        this.productGroupEdit['isActive'] = false;
        this.productGroupEdit['isRetired'] = true;
        await this._productService.editProductGroup(this.productGroupEdit).then(result => {
          const productsGroup = this.products.filter(product => product.productGroupID === this.productGroupEdit.id && product.isActive == true);
          this.createLog("productgroup", this.productGroupEdit.id, "Product Group Deleted with " + productsGroup.length + " products");
          productsGroup.forEach(async productsaved => {
            //Update to delete product
            productsaved['isActive'] = false;
            productsaved['isRetired'] = true;
            await this._productService.updateProduct(productsaved.id, productsaved)
            this.createLog("product", productsaved.id, "Product Deleted");
          })
          this.informationFormEditGroup.reset();
          this.quantityFormEditGroup.reset();
          this.rulesFormEditGroup.reset();
          this.productEditForm.reset();
          this.resetForm();
          $('#modalProductEditGroup').modal('hide')
          Swal.close();
          Swal.fire({
            title: "Completed!",
            html: "Products successfully removed",
            icon: "success"
          })
        })
      }
    })
  }
  editBasePriceDay(position, type, event): void {
    if (type === 'day') {
      this.arrayPricesDayBase[position].day = Number(event.target.value);
    } else if (type === 'price') {
      this.arrayPricesDayBase[position].price = Number(event.target.value);
    } else if (type === 'increment') {
      this.arrayPricesDayBase[position].increment = Number(event.target.value);
    }
    this.validation();
  }
  validatePriceDay() {
    let seenDays = {};
    this.dayInvalid = false;
    for (let i = 0; i < this.arrayPricesDayBase.length; i++) {
      let day = this.arrayPricesDayBase[i].day;
      let price = this.arrayPricesDayBase[i].price;
      if (seenDays[day] || day == 0 || day == undefined) {
        this.arrayPricesDayBase[i].isInvalid = true;
        this.dayInvalid = true;
      } else {
        seenDays[day] = true;
        if (this.arrayPricesDayBase[i].hasOwnProperty('isInvalid')) {
          delete this.arrayPricesDayBase[i].isInvalid;
        }
      }
      if (!price || price <= 0) {
        this.arrayPricesDayBase[i].isZero = true;
        this.dayInvalid = true;
      } else {
        if (this.arrayPricesDayBase[i].hasOwnProperty('isZero')) {
          delete this.arrayPricesDayBase[i].isZero;
        }
      }
    }
    if (this.arrayPricesDayBase.length == 0) {
      this.dayInvalid = true;
    }
  }
  editBasePriceHour(position, type, event): void {
    if (type === 'hour') {
      this.arrayPricesHourBase[position].hour = Number(event.target.value);
    } else if (type === 'price') {
      this.arrayPricesHourBase[position].price = Number(event.target.value);
    }
    this.validation();
  }
  public numberToursInvalid: boolean = false;
  validation() {
    if (this.productType === "tour") {
      this.numberToursInvalid = this.minProductsAttached < 0 || this.minProductsAttached == undefined || this.maxProductsAttached < 0 || this.maxProductsAttached == undefined;
      this.isPriceByHour = true;
      this.validatePriceHour();
      this.validateStartTime();
      this.dayInvalid = false;
    } else {
      this.numberToursInvalid = false;
      this.minimumStartPrice ? this.validateStartTime() : ""
      this.isPriceByHour ? this.validatePriceHour() : ""
      this.isPriceByDay ? this.validatePriceDay() : ""
    }
  }
  validatePriceHour() {
    let seenHours = {};
    this.hourInvalidInfo = false;
    for (let i = 0; i < this.arrayPricesHourBase.length; i++) {
      let hour = this.arrayPricesHourBase[i].hour;
      let price = this.arrayPricesHourBase[i].price;
      if (seenHours[hour] || hour == 0 || hour == undefined) {
        this.arrayPricesHourBase[i].isInvalid = true;
        this.hourInvalidInfo = true;
        this.hourInvalid = true;
      } else {
        seenHours[hour] = true;
        if (this.arrayPricesHourBase[i].hasOwnProperty('isInvalid')) {
          delete this.arrayPricesHourBase[i].isInvalid;
        }
      }
      if (!price || price <= 0) {
        this.arrayPricesHourBase[i].isZero = true;
        this.hourInvalidInfo = true;
        this.hourInvalid = true;
      } else {
        if (this.arrayPricesHourBase[i].hasOwnProperty('isZero')) {
          delete this.arrayPricesHourBase[i].isZero;
        }
      }
    }
    if (this.arrayPricesHourBase.length == 0) {
      this.hourInvalidInfo = true;
    }
  }
  createNewBasePriceDay() {
    const newTime = { day: this.arrayPricesDayBase.length + 1, price: 0, increment: 0 };
    this.arrayPricesDayBase.push(newTime);
    this.validation();
  }
  deleteBasePriceDay(position) {
    this.arrayPricesDayBase.splice(position, 1)
    if (this.arrayPricesDayBase.length == 0) {
      this.dayInvalid = true;
    } else {
      this.validation();
    }
  }
  createNewBasePriceHour() {
    const newTime = { hour: this.arrayPricesHourBase.length + 1, price: 0 };
    this.arrayPricesHourBase.push(newTime);
    this.validation();
  }

  deleteBasePriceHour(position) {
    this.arrayPricesHourBase.splice(position, 1)
    if (this.arrayPricesHourBase.length == 0) {
      this.hourInvalidInfo = true;
    } else {
      this.validation();
    }
  }
  changePriceByHourOption() {
    if (!this.isPriceByHour) {
      this.hourInvalidInfo = false;
      this.hourInvalid = false;
    } else {
      this.validatePriceHour();
    }
  }
  validateNotEmptyPrice() {
    if (!this.isPriceByHour && !this.isPriceByDay) {
      Swal.fire({
        title: "Disable Option",
        html: "Both options cannot be disabled, you must have at least one active."
      })
    }
    return this.isPriceByHour || this.isPriceByDay;
  }
  changePriceByDayOption() {
    if (!this.isPriceByDay) {
      this.dayInvalid = false;
    } else {
      this.validation();
    }
  }
  searchProductQty() {
    //Function to search in the table
    var input, filter, found, table, tr, td, i, j;
    input = this.searchProductInput; //Save the input search
    filter = input.toUpperCase(); //Uppercase the input search
    table = document.getElementById('productsTableQty'); //Get the product table
    tr = table.getElementsByTagName('tr'); //Get the tr tags
    for (i = 0; i < tr.length; i++) {
      //For each tr
      td = tr[i].getElementsByTagName('td'); //Get all td in the tr
      for (j = 0; j < td.length; j++) {
        //For each td
        if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
          //Search the value on the td
          found = true;
        }
      }
      if (found) {
        //If there is the same value
        tr[i].style.display = ''; //Remove display propertie
        found = false; //Set found as false
      } else if (tr[i].id != 'tableHeader') {
        //if the tr is different from the tableHeader
        tr[i].style.display = 'none'; //hide tr
      }
    }
  }
  searchProductQtyEdit() {
    //Function to search in the table
    var input, filter, found, table, tr, td, i, j;
    input = this.searchProductInput; //Save the input search
    filter = input.toUpperCase(); //Uppercase the input search
    table = document.getElementById('productsTableQtyEdit'); //Get the product table
    tr = table.getElementsByTagName('tr'); //Get the tr tags
    for (i = 0; i < tr.length; i++) {
      //For each tr
      td = tr[i].getElementsByTagName('td'); //Get all td in the tr
      for (j = 0; j < td.length; j++) {
        //For each td
        if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
          //Search the value on the td
          found = true;
        }
      }
      if (found) {
        //If there is the same value
        tr[i].style.display = ''; //Remove display propertie
        found = false; //Set found as false
      } else if (tr[i].id != 'tableHeader') {
        //if the tr is different from the tableHeader
        tr[i].style.display = 'none'; //hide tr
      }
    }
  }
  searchProductAdd() {
    //Function to search in the table
    var input, filter, found, table, tr, td, i, j;
    input = this.searchProductInput; //Save the input search
    filter = input.toUpperCase(); //Uppercase the input search
    table = document.getElementById('productsTable'); //Get the product table
    tr = table.getElementsByTagName('tr'); //Get the tr tags
    for (i = 0; i < tr.length; i++) {
      //For each tr
      td = tr[i].getElementsByTagName('td'); //Get all td in the tr
      for (j = 0; j < td.length; j++) {
        //For each td
        if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
          //Search the value on the td
          found = true;
        }
      }
      if (found) {
        //If there is the same value
        tr[i].style.display = ''; //Remove display propertie
        found = false; //Set found as false
      } else if (tr[i].id != 'tableHeader') {
        //if the tr is different from the tableHeader
        tr[i].style.display = 'none'; //hide tr
      }
    }
  }
  searchProductEdit() {
    //Function to search in the table
    var input, filter, found, table, tr, td, i, j;
    input = this.searchProductInput; //Save the input search
    filter = input.toUpperCase(); //Uppercase the input search
    table = document.getElementById('productsTableEdit'); //Get the product table
    tr = table.getElementsByTagName('tr'); //Get the tr tags
    for (i = 0; i < tr.length; i++) {
      //For each tr
      td = tr[i].getElementsByTagName('td'); //Get all td in the tr
      for (j = 0; j < td.length; j++) {
        //For each td
        if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
          //Search the value on the td
          found = true;
        }
      }
      if (found) {
        //If there is the same value
        tr[i].style.display = ''; //Remove display propertie
        found = false; //Set found as false
      } else if (tr[i].id != 'tableHeader') {
        //if the tr is different from the tableHeader
        tr[i].style.display = 'none'; //hide tr
      }
    }
  }
  searchProductGroup() {
    //Function to search in the table
    var input, filter, found, table, tr, td, i, j;
    input = this.searchProductInput; //Save the input search
    filter = input.toUpperCase(); //Uppercase the input search
    table = document.getElementById('productsTableGroup'); //Get the product table
    tr = table.getElementsByTagName('tr'); //Get the tr tags
    for (i = 0; i < tr.length; i++) {
      //For each tr
      td = tr[i].getElementsByTagName('td'); //Get all td in the tr
      for (j = 0; j < td.length; j++) {
        //For each td
        if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
          //Search the value on the td
          found = true;
        }
      }
      if (found) {
        //If there is the same value
        tr[i].style.display = ''; //Remove display propertie
        found = false; //Set found as false
      } else if (tr[i].id != 'tableHeader') {
        //if the tr is different from the tableHeader
        tr[i].style.display = 'none'; //hide tr
      }
    }
  }
  locationView() {
    window.open('/partner/shop', '_blank');
  }
  sizesView() {
    Swal.fire({
      title: "New Size",
      html: "if you continue, the current product information will be lost and you will  need to re-add the product after creating the new size",
      icon: "warning",
      showConfirmButton: true,
      showCancelButton: true,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: "Continue",
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        window.open('/partner/product-sizes', '_blank');
      }
    })

  }
  updateProductsInfo() {
    if (this.qtyproducts.length > 0) {
      this.qtyproducts.forEach((product, index) => {
        if (!product.product.isEdited) {
          if (this.typeaction == "edit") {
            this.qtyproducts[index].product.productName = this.informationFormEditGroup.value.productName;
            this.qtyproducts[index].product.downtime = this.informationFormEditGroup.value.downtime;
            this.qtyproducts[index].product.imageUrl = this.imgGallery;
            this.qtyproducts[index].product.thumbnailUrl = this.thumbnailGallery;
            this.qtyproducts[index].product.description = this.informationFormEditGroup.value.productDescription;
            this.qtyproducts[index].product.notes = this.informationFormEditGroup.value.productNotes;
          } else {
            this.qtyproducts[index].product.productName = this.informationFormAddGroup.value.productName;
            this.qtyproducts[index].product.downtime = this.informationFormAddGroup.value.downtime;
            this.qtyproducts[index].product.imageUrl = this.imgGallery;
            this.qtyproducts[index].product.thumbnailUrl = this.thumbnailGallery;
            this.qtyproducts[index].product.description = this.informationFormAddGroup.value.productDescription;
            this.qtyproducts[index].product.notes = this.informationFormAddGroup.value.productNotes;
          }
          let waiversEdit = []
          this.selectedWaiver.forEach((waiver) => {
            waiversEdit.push(waiver.id);
          })
          this.qtyproducts[index].product.waivers = JSON.parse(JSON.stringify(waiversEdit));
        } else {
          if (this.typeaction == "edit") {
            this.qtyproducts[index].product.productName = this.informationFormEditGroup.value.productName;
            this.qtyproducts[index].product.imageUrl = this.imgGallery;
            this.qtyproducts[index].product.thumbnailUrl = this.thumbnailGallery;
            this.qtyproducts[index].product.description = this.informationFormEditGroup.value.productDescription;
          } else {
            this.qtyproducts[index].product.productName = this.informationFormAddGroup.value.productName;
            this.qtyproducts[index].product.imageUrl = this.imgGallery;
            this.qtyproducts[index].product.thumbnailUrl = this.thumbnailGallery;
            this.qtyproducts[index].product.description = this.informationFormAddGroup.value.productDescription;
          }
        }
      })
    }
  }
  async editProduct(productgroupedit: ProductGroup, edit?: string) {
    const productgroup = JSON.parse(JSON.stringify(productgroupedit));
    Swal.fire({
      title: "Loading Product data...",
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false
    })
    Swal.showLoading();
    await this.resetForm('edit');
    this.myStepperEdit.selectedIndex = 0;
    this.productGroupEdit = JSON.parse(JSON.stringify(productgroup));
    this.typeaction = "edit"
    this.arraysizesqty = []
    this.qtyproducts = []
    //Information Form
    if (productgroup.categoryID != undefined) {
      this.selectedCategory = productgroup.categoryID;
      this.categoryName = productgroup.categoryName;
      this.categoryInvalid = false;
    } else {
      this.selectedCategory = undefined;
      this.categoryName = "";
      this.categoryInvalid = true;
    }
    this.informationFormEditGroup.get("productName").setValue(productgroup.groupName);
    this.informationFormEditGroup.get("productDescription").setValue(productgroup.groupDescription);
    this.informationFormEditGroup.get("productNotes").setValue(productgroup.groupNotes);
    this.informationFormEditGroup.get("downtime").setValue(productgroup.downtime);
    this.productType = productgroup.productType;
    this.trackProducts = productgroup.isTracked;
    if (this.productType === "tour") {
      this.startTime = JSON.parse(JSON.stringify(productgroup.startTime));
      this.arrayPricesHourBase = JSON.parse(JSON.stringify(productgroup.priceByHour));
    } else {
      this.informationFormEditGroup.get("minimumTime").setValue(productgroup.minimumTime);
      this.arrayPricesHourBase = JSON.parse(JSON.stringify(productgroup.priceByHour));
    }
    this.informationFormEditGroup.get("imageUrl").setValue(productgroup.groupImage);
    this.imgGallery = productgroup.groupImage;
    this.informationFormEditGroup.get("thumbnail").setValue(productgroup.thumbnail);
    this.waivers.forEach((element, index) => {
      const waiverselected = productgroup.waivers.find(w => w === element.id);
      if (waiverselected) {
        this.waivers[index].isSelected = true
        this.selectedWaiver.push(element)
      }
    });
    this.locations.forEach((element, index) => {
      const locationselected = productgroup.availableLocations.find(l => l === element.id);
      if (locationselected) {
        this.locations[index].isSelected = true
        this.selectedLocations.push(element)
      } else {
        delete this.locations[index]['isSelected']
      }
    })
    this.isTour = productgroup.isTour;
    if (productgroup.isTour) {
      this.minProductsAttached = productgroup.minProductsAttached;
      this.maxProductsAttached = productgroup.maxProductsAttached;

    }
    this.isStartTime = productgroup.isStartTime;
    this.startTime = productgroup.startTime;
    //Quantity Form
    this.arraysizesqty.length = 0;
    const products = await this._productService.getProductByGroupID(productgroup.id);
    const sizeTypes = await this._productService.getCustomSizeTypesPromise(this._currentUser.currentUser.companyId);
    const defaultSize = (await this._productService.getCustomSizeTypesPromiseDefault())[0];
    const finddefault = sizeTypes.find(size => size.id === defaultSize.id);
    if (!finddefault) {
      sizeTypes.push(defaultSize);
    }
    sizeTypes.push({ id: "redirect", isSelected: false, productType: "Add new Size..." })
    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      const sizes = await this._productService.getCustomSizesPromise(product.productSizeTypeID);
      this.getProductsQtyEdit(product, products, productgroup, sizeTypes, sizes, i);
    }
    this.sortQtyArray();
    productgroup.availableLocations.forEach((locationid, index) => {
      const found = this.locations.find(loc => loc.id === locationid)
      found ? this.locations[this.locations.indexOf(found)]['isSelected'] = true : ""
    })
    if (this.typeaction == "add") {
      this.validateRequired = true;
      this.quantityFormAddGroup.get('validateRequired').setValue(true);
    } else if (this.typeaction == "edit") {
      this.validateRequired = true;
      this.quantityFormEditGroup.get('validateRequired').setValue(true);
    }

    //RulesForm
    this.productRulesList = productgroup.rules;
    productgroup.rules.forEach((rule) => {
      rule.type == "Maintenance" ? this.maintenanceRuleEdit = rule.isActive : ""
      rule.type == "Checkout" ? this.checkoutRuleEdit = rule.isActive : ""
      rule.type == "Checkin" ? this.checkineRuleEdit = rule.isActive : ""
    })
    //Price form
    this.isPriceByDay = productgroup.allowRentalByDay;
    this.isPrice24hrs = productgroup.is24hrsPrice;
    this.isPriceByHour = productgroup.allowRentalByHour;
    this.arrayPricesDayBase = JSON.parse(JSON.stringify(productgroup.priceByDay));
    this.arrayPricesHourBase = JSON.parse(JSON.stringify(productgroup.priceByHour));
    if (productgroup.isStartTime) {
      this.defaultTime = false;
      this.minimumStartPrice = true;
    } else {
      this.defaultTime = true;
      this.minimumStartPrice = false;
    }
    this.createProductObject(productgroup);
    this.validation();
    this.startTimeInvalid = false;
    this.hourInvalidInfo = false;

    setTimeout(() => {
      this.quantityFormEditGroup.get("productLocationID").setValue(productgroup.productLocationID)
      this.quantityFormEditGroup.get("productLocationName").setValue(productgroup.productLocationName)
    }, 1000);
    if (edit) {
      $('#modalProductEditGroup').modal('show');
    }
    Swal.close();
  }
  getProductsQtyEdit(product, products, productgroup, sizeTypes, sizes, i) {
    product.productNumber != 0 ? this.productNumbers = true : ""
    let newarraysize: boolean = false
    let position: number = 0

    if (this.arraysizesqty.length > 0) {
      this.arraysizesqty.forEach((arraysize, indexarray) => {
        let samearray: boolean = false
        for (let j = 0; j < arraysize.sizeType.length; j++) {
          var sizetype = arraysize.sizeType[j]
          if (sizetype.id == product.productSizeTypeID && sizetype.isSelected == true) {
            samearray = true;
            break;
          }
        }
        if (samearray) {
          for (let j = 0; j < arraysize.sizes.length; j++) {
            var size = arraysize.sizes[j]
            if (size.id == product.productSizeID && size.isSelected == true) {
              newarraysize = true;
              position = indexarray
              this.arraysizesqty[indexarray].qty += 1;
              this.arraysizesqty[indexarray].copyqty += 1;
              break;
            }
          }
        }
      })
    }
    if (!newarraysize) {
      const selectedsizetype = sizeTypes.find((sizeType) => sizeType.id == product.productSizeTypeID);
      sizeTypes.forEach((t, indextype) => {
        if (t.id == selectedsizetype.id) {
          sizeTypes[indextype]['isSelected'] = true
        } else {
          sizeTypes[indextype]['isSelected'] = false;
        }
      })
      let ordersize = []
      selectedsizetype.sortOrder.forEach((idorder, indexorder) => {
        const positionsize = sizes.find(size => size.id === idorder)
        if (positionsize.id == product.productSizeID) {
          positionsize['isSelected'] = true
        } else {
          positionsize['isSelected'] = false
        }
        ordersize.push(positionsize)
      });
      let sizeRow = {
        sizeType: JSON.parse(JSON.stringify(sizeTypes)),
        sizes: JSON.parse(JSON.stringify(ordersize)),
        qty: 1,
        copyqty: 1,
        fromdb: true
      };
      this.arraysizesqty.push(JSON.parse(JSON.stringify(sizeRow)))
      position = this.arraysizesqty.length - 1;
    }
    this.qtyproducts.push({ isSelected: false, position: position, product: product })
  }
  fixAllProducts() {
    Swal.fire({
      title: "Loading..."
    })
    Swal.showLoading();
    this._productService.getAllProductsGroup().then(groups => {
      const isTourGroups = groups.filter(g => g.productType == "rental" && g.isStartTime == true);
      isTourGroups.forEach(async group => {
        group['isStartTime'] = false;
        group['startTime'] = [];
        //await this._productService.editProductGroup(group);
      });
      Swal.close();
    });
  }
  deleteAllProductsGroups() {
    const copyproducts = JSON.parse(JSON.stringify(this.products));
    Swal.fire({
      title: "Delete All Products",
      html: "This function will delete all products groups with products for this company",
      icon: "info",
      showCancelButton: true,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false
    }).then(result => {
      if (result.isConfirmed) {
        this.productGroup.forEach(async group => {
          const productsToDelete = copyproducts.filter(product => product.productGroupID == group.id);
          productsToDelete.forEach(async product => {
            await this._productService.deleteProduct(product.id);
          });
          await this._productService.deleteProductGroup(group.id);
        })
      }
    })
  }
  openAddProduct() {
    this.resetForm();
    this.myStepper.reset()
    this.validation();
  }
  restrictNumbersDay(i, type, event) {
    var value = Number(event.target.value);
    if (type == "day") {
      if (value > 99) {
        event.target.value = 99;
      }
    } else if (type == "price") {
      if (value > 9999) {
        event.target.value = 9999;
      }
    } else if (type == "increment") {
      if (value > 9999) {
        event.target.value = 9999;
      }
    }
    this.editBasePriceDay(i, type, event);
  }
  restrictNumbersHour(i, type, event) {
    var value = Number(event.target.value);
    if (type == "hour") {
      if (value > 9) {
        event.target.value = 9;
      }
    } else if (type == "price") {
      if (value > 9999) {
        event.target.value = 9999;
      }
    }
    this.editBasePriceHour(i, type, event);
  }
  restrictTourValue(event) {
    var value = Number(event.target.value);
    if (value > 99) {
      event.target.value = 99;
    }
    this.numberToursInvalid = this.minProductsAttached < 0 || this.minProductsAttached == undefined || this.maxProductsAttached < 0 || this.maxProductsAttached == undefined;
  }
  restrictNumbersQty(i, type, event, action?) {
    var value = Number(event.target.value);
    if (type == "qty") {
      if (value > 99) {
        event.target.value = 99;
      }
    }
    action ? this.editSizeRow(i, type, event, action) : this.editSizeRow(i, type, event)


  }
  resetForm(type?) {
    this.trackProducts = true;
    this.selectedTrack = false;
    this.typeaction = "add";
    this.productType = "rental";
    this.myStepper.selectedIndex = 0;
    this.submittedInfo = false;
    this.submittedQty = false;
    this.startTimeInvalid = false;
    this.hourInvalidInfo = false;
    this.hourInvalid = false;
    this.repeatedNumber = false;
    this.existingNumber = false;
    this.numberToursInvalid = false;
    this.minProductsAttached = 0;
    this.maxProductsAttached = 0;
    this.resetPriceSettings();
    this.resetInformationForm();
    this.resetSizeData();
    this.resetWaivers();
    this.resetProductRules();
    this.resetLocations();
    this.checkRequiredFields();
    this.getLocations();
    this.removeIsSelected();
    this.validation();
    type == "edit" ? "" : this.getSizes();
    //this.resetSearchProductInput();
  }

  resetInformationForm() {
    this.informationFormAddGroup.reset();
    this.informationFormAddGroup.get('minimumTime').setValue(30);
    this.informationFormAddGroup.get('downtime').setValue(0);
    this.isTour = false;
    this.isStartTime = false;
    this.trackProducts = false;
    this.startTime = [{ hour: "12:00" }];
    this.imgGallery = "";
  }

  resetSizeData() {
    if (this.sizeRow) {
      this.arraysizesqty = [JSON.parse(JSON.stringify(this.sizeRow))];
      this.qtyproducts = [];
    }
  }

  resetWaivers() {
    this.waivers.forEach(waiver => waiver.isSelected = false);
    this.productNumbers = false;
    this.selectedWaiver = [];
  }

  resetProductRules() {
    this.productRulesList.forEach((rule, position) => {
      this.productRulesList[position].isActive = false;
    });
    this.maintenanceRuleAdd = false;
    this.checkoutRuleAdd = false;
    this.checkineRuleAdd = false;
  }

  resetLocations() {
    this.locations.forEach((location, index) => {
      const id = "location" + index.toString();
      const checkbox = document.getElementById(id) as HTMLInputElement | null;
      if (checkbox !== null) {
        checkbox.checked = false;
      }
    });
    this.selectedLocations = [];
  }

  resetPriceSettings() {
    this.isPriceByDay = true;
    this.isPrice24hrs = false;
    this.isPriceByHour = true;
    this.arrayPricesDayBase = [{ day: 1, price: 0, increment: 0, isZero: true }];
    this.arrayPricesHourBase = [{ hour: 1, price: 0, isZero: true }];
  }
  resetSearchProductInput() {
    const input = document.getElementById('clean0') as HTMLInputElement;
    input.value = "0";
    this.searchProductInput = "";
  }
  // Added by Morgan
  // To check if there is a default inventory layout for a company
  // If there isnt then create one
  getDefaultLayoutID() {
    // Called everytime there is a change to inventory pages
    this.subs.add(this._widgetService.getInventoryPages().subscribe((data: any) => {
      this.inventoryPages = [];
      this.inventoryPagesIdList = [];
      this.inventoryPages = data;
      let tempInvInd;

      // Setting defaultId variable and setting
      this.inventoryPages.forEach((page, ind) => {
        if (page.isDefault == true) {
          this.defaultInvID = page.id;
          tempInvInd = ind;
        }

        this.inventoryPagesIdList.push(page.id);
      })
      // Check if there is a default layout, if not create and set one
      if (this.defaultInvID) {
        // Moves the defualt to the front
        this.inventoryPages.splice(0, 0, this.inventoryPages[tempInvInd]);
        this.inventoryPages.splice(tempInvInd + 1, 1);
      }
      else {
        this.createDefaultTemplate();
      }
    }))
  }
  //Adds a default template to database
  createDefaultTemplate() {
    let check = false;
    // Double checks there is not Default layout
    this.inventoryPages.forEach(page => {
      if (page.isDefault == true) {
        check = true;
      }
    })
    if (!check) {
      this.defaultInvID = this._productService.addInventoryPage('Default', true);
    }
  }

  // Checks to make sure all groups have an inventoryId that exists and if not it sets them to default
  validateGroupInventoryId() {

    this.productGroup.forEach(group => {
      // If product doesnt have an inventoryPageId or has a non-existing inventoryPageId
      // set it to the default inventory page
      if (!group['inventoryPageId'] || !this.inventoryPagesIdList.includes(group['inventoryPageId'])) {
        this._productService.updateProductGroupInventoryPageId(group.id, group['inventoryPageId']);
      }
    })
    // this.validateGroupRan = true;


  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
