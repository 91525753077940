import { Component, OnInit } from '@angular/core';

import { ReportService } from 'src/app/services/report.service';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { RentalService } from 'src/app/services/rental.service';
import { BookingService } from 'src/app/services/booking.service';
import { ColorTheme } from '../../services/colorTheme.service';

import * as moment from 'moment';
import Swal from 'sweetalert2';

import { DomSanitizer } from '@angular/platform-browser';
import { log } from 'console';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-calendar-product-rebuild',
  templateUrl: './calendar-product-rebuild.component.html',
  styleUrls: ['./calendar-product-rebuild.component.scss']
})
export class CalendarProductRebuildComponent {
  isIFrameLoaded = false;
  isIFrameLoadedProduct = false;
  public urlIframe: any;
  public urlIframeProduct: any;
  public products: any = [];
  public rentals: any = [];
  public categories: any = [];
  public dataCalendar: any = [];
  public dataCalendarResp: any = [];
  public date = new Date(); //Obtain the actual date
  public startMonth: any = new Date(new Date().setHours(0, 0, 0, 0));
  public endMonth: any = new Date(new Date().setDate(this.date.getDate() + 30)); //Variable to save the last date from the actual month
  public startDate: string;
  public endDate: string;
  public headersDate: any[] = [];
  public days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  public search = '';
  public productID: string = '';
  public productName: string = '';
  public dateCalendar: string = '';
  public categoryID: string = '';
  IdperUser: string;
  public ColorBackground: string;
  public ColorText: string;
  constructor(
    private _reportService: ReportService,
    public currentUserService: CurrentUserService,
    private rentalService: RentalService,
    private sanitizer: DomSanitizer,
    public _Theme_Select: ColorTheme,
    private _bookingService: BookingService
  ) { }

  ngOnInit(): void {
    const moment = require('moment');
    moment.suppressDeprecationWarnings = true;
    this.getColumnsHeaders(this.startMonth, this.endMonth);
    this.getRentals(
      new Date(moment(this.startMonth).format('YYYY-MM-DD 00:00:00')),
      new Date(moment(this.endMonth).format('YYYY-MM-DD 23:59:59'))
    );
    this.startDate = moment(this.startMonth).format('YYYY-MM-DD');
    this.endDate = moment(this.endMonth).format('YYYY-MM-DD');
    this.SelectColorT();
  }

  checkLocalhost(company: any) {
    let publicUrl = '';

    if (window.location.host.includes('localhost')) {
      publicUrl =`http://${window.location.host}/${company.companyPortal}/${company.companyURL}`;
    } else {
      publicUrl =`https://${environment.app.appDomainName}/${company.companyPortal}/${company.companyURL}`;
    }

    this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl(publicUrl);
  }

  checkLocalhostProduct(company: any) {
    let publicUrlProduct = '';

    if (window.location.host.includes('localhost')) {
      publicUrlProduct = `http://${window.location.host}/${company.companyPortal}/${company.companyURL}/?pr=${this.productID}&pn=${this.productName}&co=${company.id}&ca=${this.dateCalendar}`;
    } else {
      publicUrlProduct = `https://${environment.app.appDomainName}/${company.companyPortal}/${company.companyURL}/?pr=${this.productID}&pn=${this.productName}&co=${company.id}&ca=${this.dateCalendar}&ci=${this.categoryID}`;
    }

    this.urlIframeProduct =
      this.sanitizer.bypassSecurityTrustResourceUrl(publicUrlProduct);
  }

  getColumnsHeaders(startMonth, endMonth) {
    this.headersDate = [];
    let start = moment(startMonth);
    let end = moment(endMonth);
    this.headersDate.push(start.format('YYYY/M/DD'));
    do {
      start.add(1, 'days');
      this.headersDate.push(start.format('YYYY/M/DD'));
    } while (start < end);
  }
  async getRentals(startMonth, endMonth) {
    Swal.fire({
      title: 'Loading',
      text: 'Loading Data',
    });
    Swal.showLoading();
    let dataCalendar = [];
    const rentals = await this._reportService.getRentalsMonth(
      startMonth,
      endMonth
    );
    this.rentals = rentals;
    const products = await this._reportService.getAllProducts(
      this.startMonth,
      this.endMonth
    );
    this.products = products;
    // const categories = await this._reportService.getAllCategories();
    const categories = await this._reportService.getAllGroups();
    categories.forEach((category) => {
      this.products.forEach((product) => {
        if (product.productGroupID == category.id) {
          const found = dataCalendar.findIndex(
            (element) => element.productGroupID == category.id
          );

          // Encuentra todos los productos que pertenecen a esta categoría
          let productsInCategory = this.products.filter(product => product.productGroupID == category.id);
          if (found != -1) {
            dataCalendar[found].products.push({
              id: product.id,
              nameProduct: product.productName,
              isAvailable: product.isAvailable,
              isMaintenance: product.isMaintenance,
              name: product.productName,
              productName:
                product.productName +
                ' #' +
                product.productNumber +
                ' - ' +
                product.productSize,
              productNumber: product.productNumber,
              productSize: product.productSize,
              days: [],
            });
          } else {
            // Si hay productos en esta categoría, añade la categoría y sus productos a dataCalendar
            if (productsInCategory.length > 0) {
              // Solo agrega la categoría si aún no está en dataCalendar
              if (!dataCalendar.find(dc => dc.categoryID === category.id)) {
                dataCalendar.push({
                  categoryID: category.id,
                  categoryName: category.groupName,
                  is24hrsPrice: category.is24hrsPrice,
                  products: [],
                  productsInCategory: productsInCategory.length,
                });

                // Encuentra el índice de la categoría que acabamos de agregar
                const categoryIndex = dataCalendar.findIndex(dc => dc.categoryID === category.id);

                // Agrega los productos a la categoría, verificando si ya existe un producto con el mismo productSize
                productsInCategory.forEach(productInCategory => {
                  const productExists = dataCalendar[categoryIndex].products.some(
                    existingProduct => existingProduct.productSize === productInCategory.productSize
                  );

                  const productSizeCount = productsInCategory.reduce((acc, product) => {
                    if (!acc[product.productSize]) {
                      acc[product.productSize] = 0;
                    }
                    acc[product.productSize]++;
                    return acc;
                  }, {});

                  if (!productExists) {
                    const product = {
                      id: productInCategory.id,
                      nameProduct: productInCategory.productName,
                      isAvailable: productInCategory.isAvailable,
                      isMaintenance: productInCategory.isMaintenance,
                      name: productInCategory.productName,
                      productName: productInCategory.productName + ' - ' + productInCategory.productSize,
                      productNumber: productInCategory.productNumber,
                      productSize: productInCategory.productSize,
                      productLocationID: productInCategory.productLocationID,
                      productGroupID: productInCategory.productGroupID,
                      days: [],
                      productsInCategory: productSizeCount[productInCategory.productSize],
                    };
                    dataCalendar[categoryIndex].products.push(product);
                  }
                });
              }
            }
          }
        }
      });
    });
    console.log('dataCalendar -->', dataCalendar);
    // dataCalendar.sort((c1, c2) =>
    //   c1.sortOrder > c2.sortOrder ? 1 : c1.sortOrder < c2.sortOrder ? -1 : 0
    // );

    this.createArrayJsonProduct(rentals, dataCalendar);
  }

  handleEditDate() {
    if (
      new Date(moment(this.startDate).format('YYYY-MM-DD')) >=
      new Date(moment(this.endDate).format('YYYY-MM-DD'))
    ) {
      Swal.fire({
        title: 'Bad Data Range',
        text: "You can't define a start date less than or equal to the end date.",
        icon: 'error',
      });
    } else {
      this.getRentals(
        new Date(moment(this.startDate).format('YYYY-MM-DD 00:00:00')),
        new Date(moment(this.endDate).format('YYYY-MM-DD 23:59:59'))
      );
      this.getColumnsHeaders(this.startDate, this.endDate);
    }
  }
  setStartDate(e) {
    this.startDate = e.target.value;
  }
  setEndDate(e) {
    this.endDate = e.target.value;
  }
  async createArrayJsonProduct(rentals, dataCalendars) {
    let availableDays = [];
    const company = await this.rentalService.getCompanyByID(
      this.currentUserService.currentUser.companyId
    );
    const pLocation = await this.rentalService.getDefaultLocation(company.defaultLocation)
    console.log(pLocation)
    this.checkLocalhost(company);
    this.checkLocalhostProduct(company);
    let add = false;
    if (pLocation === undefined) {
      Swal.close()
      Swal.fire({
        title: "No Schedule",
        html: "No company shopping hours have been found, therefore this module is not displayed correctly.",
        icon: "info"
      })
    } else {
      this.days.forEach(async (dayWeek) => {
        const findelement = pLocation.scheduleWeek.find(
          (schedule) => schedule.day === dayWeek
        );
        if (findelement.available) {
          add = true;
          availableDays.push(dayWeek);
        } else {
          add = false;
        }
      });
    }
    if (rentals.length == 0) {
      dataCalendars.forEach((data, index2) => {
        data.products.forEach((product, index) => {
          this.headersDate.forEach((header, iheader) => {
            const found = availableDays.find(
              (element) => element == moment(header).format('dddd')
            );
            if (found) {
              dataCalendars[index2].products[index].days[iheader] = 1;
              //This else is to not show the 0 in closed days
            } else {
              dataCalendars[index2].products[index].days[iheader] = undefined;
            }
          });
        });
      });
      if (pLocation.unavailableDays) {
        pLocation.unavailableDays.forEach((unavailable) => {
          var headerdate;
          var type;
          if (unavailable.periodicity == 'Every month') {
            headerdate = moment(
              new Date(unavailable.date.seconds * 1000)
            ).format('DD');
            type = 1;
          } else if (unavailable.periodicity == 'Every year') {
            headerdate = moment(
              new Date(unavailable.date.seconds * 1000)
            ).format('M/DD');
            type = 2;
          } else if (unavailable.periodicity == 'One Time') {
            headerdate = moment(
              new Date(unavailable.date.seconds * 1000)
            ).format('YYYY/M/DD');
            type = 3;
          } else if (unavailable.periodicity == 'Every week') {
            headerdate = moment(
              new Date(unavailable.date.seconds * 1000)
            ).format('dddd');
            type = 4;
          }
          // if (new Date(unavailable.date.seconds * 1000) >= new Date(this.startDate) && new Date(unavailable.date.seconds * 1000) <= new Date(this.endDate)) {
          dataCalendars.forEach((data, index2) => {
            data.products.forEach((product, index) => {
              this.headersDate.forEach((header, iheader) => {
                if (type == 1 && moment(header).format('DD') == headerdate) {
                  dataCalendars[index2].products[index].days[iheader] =
                    undefined;
                }

                if (type == 2 && moment(header).format('M/DD') == headerdate) {
                  dataCalendars[index2].products[index].days[iheader] =
                    undefined;
                }

                if (
                  type == 3 &&
                  moment(header).format('YYYY/M/DD') == headerdate
                ) {
                  dataCalendars[index2].products[index].days[iheader] =
                    undefined;
                }

                if (type == 4 && moment(header).format('dddd') == headerdate) {
                  dataCalendars[index2].products[index].days[iheader] =
                    undefined;
                }
              });
            });
          });
          // }
        });
      }
    } else {
      this.headersDate.forEach((date, header) => {
        rentals.forEach((rental) => {
          const found = availableDays.find(
            (element) => element == moment(date).format('dddd')
          );
          if (
            moment(rental.dayStart.seconds * 1000) >=
            moment(moment(date).format('YYYY-MM-DD 00:00:00')) &&
            moment(rental.dayStart.seconds * 1000) <=
            moment(moment(date).format('YYYY-MM-DD 23:59:59'))
          ) {
            rental.productsID.forEach((product) => {
              dataCalendars.forEach((calendar, index) => {
                calendar.products.forEach((cal, index2) => {
                  if (product == cal.id) {
                    dataCalendars[index].products[index2].days[header] = rental.id;
                  } else {
                    dataCalendars[index].products[index2].days[header] != 1
                      ? ''
                      : (dataCalendars[index].products[index2].days[
                        header
                      ] = 1);
                  }
                });
              });
            });
          }
          if (
            moment(rental.dayStart.seconds * 1000) <
            moment(moment(date).format('YYYY-MM-DD 00:00:00')) &&
            moment(rental.dayEnd.seconds * 1000) >
            moment(moment(date).format('YYYY-MM-DD 00:00:00'))
          ) {
            rental.productsID.forEach((product) => {
              dataCalendars.forEach((calendar, index) => {
                calendar.products.forEach((cal, index2) => {
                  if (product == cal.id) {
                    dataCalendars[index].products[index2].days[header] = rental.id;
                  } else {
                    dataCalendars[index].products[index2].days[header] != 1
                      ? ''
                      : '';
                  }
                });
              });
            });
          }
          if (found) {
            rental.productsID.forEach((product) => {
              dataCalendars.forEach((calendar, index) => {
                calendar.products.forEach((cal, index2) => {
                  if (
                    dataCalendars[index].products[index2].days[header] ==
                    undefined
                  ) {
                    dataCalendars[index].products[index2].days[header] = 1;
                  }
                });
              });
            });
            //This else is to not show the 0 in closed days
          } else {
            rental.productsID.forEach((product) => {
              dataCalendars.forEach((calendar, index) => {
                calendar.products.forEach((cal, index2) => {
                  dataCalendars[index].products[index2].days[header] =
                    undefined;
                });
              });
            });
          }
        });
      });
      if (pLocation.unavailableDays) {
        pLocation.unavailableDays.forEach((unavailable) => {
          var headerdate;
          var type;
          if (unavailable.periodicity == 'Every month') {
            headerdate = moment(
              new Date(unavailable.date.seconds * 1000)
            ).format('DD');
            type = 1;
          } else if (unavailable.periodicity == 'Every year') {
            headerdate = moment(
              new Date(unavailable.date.seconds * 1000)
            ).format('M/DD');
            type = 2;
          } else if (unavailable.periodicity == 'One Time') {
            headerdate = moment(
              new Date(unavailable.date.seconds * 1000)
            ).format('YYYY/M/DD');
            type = 3;
          } else if (unavailable.periodicity == 'Every week') {
            headerdate = moment(
              new Date(unavailable.date.seconds * 1000)
            ).format('dddd');
            type = 4;
          }

          // if (new Date(unavailable.date.seconds * 1000) >= new Date(this.startDate) && new Date(unavailable.date.seconds * 1000) <= new Date(this.endDate)) {
          dataCalendars.forEach((data, index2) => {
            data.products.forEach((product, index) => {
              this.headersDate.forEach((header, iheader) => {
                if (type == 1 && moment(header).format('DD') == headerdate) {
                  dataCalendars[index2].products[index].days[iheader] =
                    undefined;
                }

                if (type == 2 && moment(header).format('M/DD') == headerdate) {
                  dataCalendars[index2].products[index].days[iheader] =
                    undefined;
                }

                if (
                  type == 3 &&
                  moment(header).format('YYYY/M/DD') == headerdate
                ) {
                  dataCalendars[index2].products[index].days[iheader] =
                    undefined;
                }

                if (type == 4 && moment(header).format('dddd') == headerdate) {
                  dataCalendars[index2].products[index].days[iheader] =
                    undefined;
                }
              });
            });
          });
          // }
        });
      }
    }
    dataCalendars.forEach((element) => {
      element.products.sort((c1, c2) =>
        c1.productName.toUpperCase() > c2.productName.toUpperCase()
          ? 1
          : c1.productName.toUpperCase() < c2.productName.toUpperCase()
            ? -1
            : 0
      );
    });
    this.dataCalendar = dataCalendars;
    this.dataCalendarResp = dataCalendars;
    Swal.close();
  }

  searchByName() {
    this.dataCalendar = this.dataCalendarResp;
    if (this.search == '') {
    } else {
      this.dataCalendar = [];
      this.dataCalendarResp.forEach((calendar, index) => {
        var filtered = calendar.products.filter((data) =>
          String(data.productName)
            .toUpperCase()
            .includes(this.search.toUpperCase())
        );
        if (filtered.length > 0) {
          this.dataCalendar.push(this.dataCalendarResp[index]);
        }
      });
    }
  }
  hideAccordion(i) {
    let rows = 'accordion' + i.toString();
    let arrowdown = 'arrowdown' + i.toString();
    let arrowright = 'arrowright' + i.toString();
    const rowscategory = Array.from(
      document.getElementsByClassName(rows) as HTMLCollectionOf<HTMLElement>
    );
    const ad = document.getElementById(arrowdown) as HTMLElement;
    const ar = document.getElementById(arrowright) as HTMLElement;
    rowscategory.forEach((box) => {
      if (box.style.display == 'none') {
        box.style.display = '';
        ad.style.display = 'initial';
        ar.style.display = 'none';
      } else {
        box.style.display = 'none';
        ad.style.display = 'none';
        ar.style.display = 'initial';
      }
    });
  }

  loadIFrame(status) {
    this.isIFrameLoaded = status;
  }

  loadIFrameNew(status) {
    this.isIFrameLoadedNew = status;
  }

  async loadIFrameProductId(
    status: boolean,
    productID: string,
    productName: string,
    date: any,
    categoryID?: string
  ) {
    this.productID = productID;
    this.productName = productName;
    date === "undefined" ? this.dateCalendar = moment().toISOString() : this.dateCalendar = moment(date).toISOString();
    console.log(this.dateCalendar);
    this.categoryID = categoryID;
    this.isIFrameLoadedProduct = status;
    const company = await this.rentalService.getCompanyByID(
      this.currentUserService.currentUser.companyId
    );
    this.checkLocalhostProduct(company);
  }
  loadBooking(rentalID) {
    location.href = "partner/bookings/" + rentalID;
  }
  loadProduct(productID) {
    location.href = "partner/inventory/" + productID;
  }
  async loadIFrameProduct(status: boolean) {
    this.isIFrameLoadedProduct = status;
    const company = await this.rentalService.getCompanyByID(
      this.currentUserService.currentUser.companyId
    );
    this.checkLocalhostProduct(company);
  }

  SelectColorT() {
    this.IdperUser = this.currentUserService.currentUser.id;

    this._Theme_Select
      .getcolorPeruser(this.IdperUser)
      .then((IdCol) => {
        let Selectheme = {
          Bck: IdCol.color.value,
          Tck: IdCol.color.fontcolor,
        };
        this.ColorBackground = Selectheme.Bck;
        this.ColorText = Selectheme.Tck;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  urlIframeNew: any;
  isIFrameLoadedNew: boolean = false;
  templates: any = [];
  selectedOption: string = '';
  invalid: boolean = false;
  bookingChoices: any = {};
  productGroupID: string = '';
  productLocationID: string = '';
  formattedDate: string = '';
  is24hrsPrice: boolean = false;
  formattedEndDate: string = '';
  async showSelector(productGroupID?:string, productLocationID?:string, i?:number, is24hrsPrice?:boolean) {
    console.log('dateCalendar -->', this.headersDate[i]);

    this.productGroupID = productGroupID;
    this.productLocationID = productLocationID;
    let date = this.headersDate[i];
    this.formattedDate = moment(date, "YYYY/M/D").format("YYYY-MM-DD");
    this.is24hrsPrice = is24hrsPrice;
    if (this.is24hrsPrice) {
      this.formattedEndDate = moment(date, "YYYY/M/D").add(1, 'days').format("YYYY-MM-DD");
    }


    this.selectedOption = '';
    this.bookingChoices = {};
    this.templates = await this._bookingService.getTemplatesForCompany();
    // Get the updated bookingChoice
    const choice = await this.currentUserService.getBookingChoice();
    console.log('bookingChoise -->', choice);

    this.bookingChoices = choice;
    let value = this.bookingChoices.get(this.currentUserService.currentUser.companyId);
    this.selectedOption = value ? value : '';

    $('#popupModal').modal('show');
  }

  closeShowSelector() {
    $('#popupModal').modal('hide');
  }

  openNewBookingIframe() {
    if (this.selectedOption === '') {
      this.invalid = true;
      return;
    }

    this.isIFrameLoadedNew = true;
    let publicUrl = '';
    this.invalid = false;

    if (window.location.host.includes('localhost')) {
      publicUrl =`http://${window.location.host}/book/${this.selectedOption}/search/${this.productGroupID}?location=${this.productLocationID}&startDate=${this.formattedDate}&endDate=${this.is24hrsPrice ? this.formattedEndDate : this.formattedDate}`;
    } else {
      publicUrl = `https://${environment.app.appDomainName}/book/${this.selectedOption}/search/${this.productGroupID}?location=${this.productLocationID}&startDate=${this.formattedDate}&endDate=${this.is24hrsPrice ? this.formattedEndDate : this.formattedDate}`;
    }

    this.urlIframeNew = this.sanitizer.bypassSecurityTrustResourceUrl(publicUrl);

    const bookingChoice = {
      [this.currentUserService.currentUser.companyId]: this.selectedOption
    };

    this.updateUserBookingChoice(bookingChoice);

    $('#popupModal').modal('hide');
  }

  openNewBookingNewTab() {
    if (this.selectedOption === '') {
      this.invalid = true;
      return;
    }

    this.invalid = false;

    if (window.location.host.includes('localhost')) {
      window.open(`http://${window.location.host}/book/${this.selectedOption}/search/${this.productGroupID}?location=${this.productLocationID}&startDate=${this.formattedDate}&endDate=${this.is24hrsPrice ? this.formattedEndDate : this.formattedDate}`, '_blank');
    } else {
      window.open(`https://${environment.app.appDomainName}/book/${this.selectedOption}/search/${this.productGroupID}?location=${this.productLocationID}&startDate=${this.formattedDate}&endDate=${this.is24hrsPrice ? this.formattedEndDate : this.formattedDate}`, '_blank');
    }

    const bookingChoice = {
      [this.currentUserService.currentUser.companyId]: this.selectedOption
    };

    this.updateUserBookingChoice(bookingChoice);

    $('#popupModal').modal('hide');

   }

  updateUserBookingChoice(newBookingChoices: any) {
    let keys = Object.keys(newBookingChoices);
    if (keys[0] === this.currentUserService.currentUser.companyId) {
      this.bookingChoices.set(keys[0], this.selectedOption);
      this.currentUserService.updateUserBookingChoice(this.currentUserService.currentUser.id, Object.fromEntries(this.bookingChoices));
    }

  }


}
