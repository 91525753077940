import { Injectable } from '@angular/core';
import { CurrentUserService } from './current-user.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Log } from 'src/app/models/storage/log.model';
import { RentalService } from './rental.service';
import { TimeService } from './time.service';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(
    private _currentUser: CurrentUserService,
    private _afs: AngularFirestore,
    private rentalService: RentalService,
    private timeService: TimeService
  ) { }

  public updateRentalWithLog(rentalID, newRental, logDetailsArr) {

    const batch = this._afs.firestore.batch();

    // needs to convert the rentals dayStart, dayEnd, and isReserved date from moment / luxon to a javascript date so it doesn't save strangely
    newRental.dayStart = this.timeService.convertToJavascriptDate(newRental.dayStart);
    newRental.dayEnd = this.timeService.convertToJavascriptDate(newRental.dayEnd);

    batch.update(this._afs.collection('rentals').doc(rentalID).ref, newRental); // update the rental with the newly edited version

    for (let logDetails of logDetailsArr) {
      // If successful, add a log
      const logInfo = {
        timestamp: new Date(),
        firstName: this._currentUser.currentUser ? this._currentUser.currentUser.firstName : 'public',
        lastName: this._currentUser.currentUser ? this._currentUser.currentUser.lastName : 'public',
        userID: this._currentUser.currentUser ? this._currentUser.currentUser.id : 'public',
        details: logDetails
      }
      const ref = this._afs.firestore.collection('rentals').doc(rentalID).collection('log').doc();
      batch.set(ref, logInfo)
    }

    return batch.commit()
  }

  public addRentalLog(rentalID, details: string, location?: string) {
    const user = this._currentUser.currentUser || {}
    return this._afs
      .collection('rentals')
      .doc(rentalID)
      .collection('log')
      .add({
        timestamp: new Date(),
        firstName: user.firstName || 'public',
        lastName: user.lastName || 'public',
        userID: user.id || 'public',
        details: details,
        location: location || ""
      });
  }
  public addRentalPDFLog(rentalID, details: any) {
    this._afs
      .collection('rentals')
      .doc(rentalID)
      .collection('log')
      .add(details);
  }
  public addCategoryLog(categoryID, details: string) {
    console.log(categoryID);
    this._afs
      .collection('rentalCategories')
      .doc(categoryID)
      .collection('log')
      .add({
        timestamp: new Date(),
        firstName: this._currentUser.currentUser.firstName ?? '',
        lastName: this._currentUser.currentUser.lastName ?? '',
        userID: this._currentUser.currentUser.id,
        details: details,
      });
  }
  public addPublicRentalLog(
    rentalID,
    details: string,
    firstName: string,
    lastName: string
  ) {
    this._afs
      .collection('rentals')
      .doc(rentalID)
      .collection('log')
      .add({
        firstName: firstName,
        lastName: lastName,
        timestamp: new Date(),
        details: details,
        userID: this._currentUser.currentUser
          ? this._currentUser.currentUser.id
          : 'public',
      });
  }
  public addRentalWaiverLog(
    rentalID,
    details: string,
    firstName: string,
    lastName: string,
    sign: string
  ) {
    this._afs
      .collection('rentals')
      .doc(rentalID)
      .collection('log')
      .add({
        firstName: firstName,
        lastName: lastName,
        sign: sign,
        timestamp: new Date(),
        details: details,
        userID: this._currentUser.currentUser
          ? this._currentUser.currentUser.id
          : 'public',
      });
  }
  public addCompanyLog(details: string) {
    this._afs
      .collection('companies')
      .doc(this._currentUser.currentUser.companyId)
      .collection('log')
      .add({
        timestamp: new Date(),
        firstName: this._currentUser.currentUser
          ? this._currentUser.currentUser.firstName
          : 'public',
        lastName: this._currentUser.currentUser
          ? this._currentUser.currentUser.lastName
          : 'public',
        userID: this._currentUser.currentUser
          ? this._currentUser.currentUser.id
          : 'public',
        details: details,
      });
  }
  public addProductLog(productID: string, details: string, location?: string) {
    this._afs
      .collection('products')
      .doc(productID)
      .collection('log')
      .add({
        userID: this._currentUser.currentUser
          ? this._currentUser.currentUser.id
          : 'public',
        firstName: this._currentUser.currentUser
          ? this._currentUser.currentUser.firstName
          : 'public',
        lastName: this._currentUser.currentUser
          ? this._currentUser.currentUser.lastName
          : 'public',
        timestamp: new Date(),
        details: details,
        location: location ? location : ""
      });
  }
  public addProductGroupLog(groupID: string, details: string) {
    this._afs
      .collection('productGroup')
      .doc(groupID)
      .collection('log')
      .add({
        userID: this._currentUser.currentUser
          ? this._currentUser.currentUser.id
          : 'public',
        firstName: this._currentUser.currentUser
          ? this._currentUser.currentUser.firstName
          : 'public',
        lastName: this._currentUser.currentUser
          ? this._currentUser.currentUser.lastName
          : 'public',
        timestamp: new Date(),
        details: details,
      });
  }

  lastProductLogLoaded: any;

  public getLogsWithCollection(collection, id, reset = false): Promise<Log[]> {
    // var log: firebase.firestore.Query
    var log = this._afs
      .collection(collection)
      .doc(id)
      .collection('log')
      .ref.orderBy('timestamp', 'desc');
    log = log.limit(20);
    if (reset) {
      this.lastProductLogLoaded = undefined;
    }

    if (this.lastProductLogLoaded) {
      log = log.startAfter(this.lastProductLogLoaded);
    }
    return log.get().then((data) => {
      if (data.docs.length > 0) {
        this.lastProductLogLoaded = data.docs[data.docs.length - 1];
      }
      return data.docs.map((doc) => {
        let data = doc.data();
        let log = new Log(data);
        return log;
      });
    });
  }
  public getLogsChangedWithCollection(collection, id): Promise<any[]> {
    // var log: firebase.firestore.Query
    var log = this._afs
      .collection(collection)
      .doc(id)
      .collection('log')
      .ref.where('key', '==', 'costChanged')
      .orderBy('timestamp', 'desc');
    return log.get().then((data) => {
      return data.docs.map((doc) => {
        let data = doc.data();
        return data;
      });
    });
  }
  public searchFirstLogCollection(id, reset = false): Promise<Log[]> {
    // var log: firebase.firestore.Query
    var log = this._afs
      .collection('rentals')
      .doc(id)
      .collection('log')
      .ref.where('change', '==', 'first')
      .orderBy('timestamp', 'desc');
    log = log.limit(20);
    if (reset) {
      this.lastProductLogLoaded = undefined;
    }

    if (this.lastProductLogLoaded) {
      log = log.startAfter(this.lastProductLogLoaded);
    }
    return log.get().then((data) => {
      if (data.docs.length > 0) {
        this.lastProductLogLoaded = data.docs[data.docs.length - 1];
      }
      return data.docs.map((doc) => {
        let data = doc.data();
        let log = new Log(data);
        return log;
      });
    });
  }

  public addMaintenanceLog(id: string, details: string) {
    this._afs
      .collection('products')
      .doc(id)
      .collection('log')
      .add({
        userID: this._currentUser.currentUser
          ? this._currentUser.currentUser.id
          : 'public',
        firstName: this._currentUser.currentUser
          ? this._currentUser.currentUser.firstName
          : 'public',
        lastName: this._currentUser.currentUser
          ? this._currentUser.currentUser.lastName
          : 'public',
        timestamp: new Date(),
        details: details,
        type: 'maintenance',
      });
  }

  public addLocationLog(id: string, details: string) {
    this._afs
      .collection('productLocations')
      .doc(id)
      .collection('log')
      .add({
        timestamp: new Date(),
        firstName: this._currentUser.currentUser
          ? this._currentUser.currentUser.firstName
          : 'public',
        lastName: this._currentUser.currentUser
          ? this._currentUser.currentUser.lastName
          : 'public',
        userID: this._currentUser.currentUser
          ? this._currentUser.currentUser.id
          : 'public',
        details: details,
      });
  }
}
