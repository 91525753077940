import { Injectable } from '@angular/core';

/* Services */
import { CurrentUserService } from 'src/app/services/current-user.service';

/* Models */
import { Collection } from 'src/app/v2/models/collection-reference.model';
import { User } from 'src/app/models/storage/user.model';
import { RouteConfig, QueryParamConfig } from 'src/app/v2/models/route-query-guard-config.model';
import { ComputedRouteValues } from 'src/app/v2/models/route-query-guard-config.model';

@Injectable({
  providedIn: 'root'
})
export class RouteQueryGuardConfigService {
  constructor(private currentUserService: CurrentUserService) { }

  public async getConfig(route: string): Promise<QueryParamConfig[] | null> {
    const config: RouteConfig = this.getRouteConfig();

    // Map to store resolved keyword values
    const resolvedValues = new Map<ComputedRouteValues, any>();

    if (config[route]) { // If the route was found
      for (let paramConfig of config[route]) { // Loop through that specific route's params
        for (let condition of paramConfig.conditions) { // Loop through params conditions
          if (Object.values(ComputedRouteValues).includes(condition.value as ComputedRouteValues)) { // If a condition value has a Keyword, resolve it's value
            const keyword = condition.value as ComputedRouteValues;

            // Check if the value has already been resolved
            if (!resolvedValues.has(keyword)) {
              const resolvedValue = await this.resolveValue(keyword);
              resolvedValues.set(keyword, resolvedValue);
            }

            // Use the cached value
            condition.value = resolvedValues.get(keyword);
          }
        }
      }
    }
    return config[route] || null;
  }

  // Define the route configuration
  public getRouteConfig(): RouteConfig {
    return {
      'booking-suite/:templateID': [
        {
          paramName: 'templateID',
          type: 'route',
          collection: Collection.BookingTemplates,
          conditions: [
            {
              field: 'companyID',
              operator: '==',
              value: ComputedRouteValues.CurrentUser // Enum used here
            }
          ]
        }
      ],
      'book/:templateID': [
        {
          paramName: 'templateID',
          type: 'route',
          collection: Collection.BookingTemplates,
          conditions: []
        }
      ]
    };
  }

  // Method to resolve enum to dynamic value
  private async resolveValue(keyword: ComputedRouteValues): Promise<any> {
    switch (keyword) {
      case ComputedRouteValues.CurrentUser:
        const currentUser: User = await this.currentUserService.getCurrentUserOnce();
        return currentUser ? currentUser.companyId : null;
      // Add more cases if needed
      default:
        return null;
    }
  }
}
