import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-product-history',
  templateUrl: './product-history.component.html',
  styleUrls: ['./product-history.component.scss'],
})
export class ProductHistoryComponent implements OnInit {
  @Input() public productID: string = '';
  public history: any[] = [];
  public loading = true;

  /**
   * Constructs a new instance of the class.
   * @param $afFun The AngularFireFunctions instance used for Firebase Cloud Functions.
   */
  constructor(private $afFun: AngularFireFunctions) {}

  ngOnInit(): void {
    this.getProductHistory();
  }

  /**
   * Fetches the product history using a Firebase Cloud Function.
   */
  getProductHistory(): void {
    this.$afFun
      .httpsCallable('getProductHistory')({ productID: this.productID })
      .subscribe(
        (res: any) => {
          Swal.close();
          if (!res) {
            this.showErrorMessage('Oops...', 'Something went wrong!');
            return;
          }
          console.log('Array', res);
          this.history = res;
          this.loading = false;
        },
        (error: any) => {
          this.showErrorMessage('Oops...', 'Something went wrong!');
          console.error(error);
        }
      );
  }

  /**
   * Opens the image in a modal dialog.
   * @param img - The URL of the image to be displayed.
   */
  openModalImg(img: string): void {
    Swal.fire({
      title: 'Image',
      html: `<div style="width:100%;"><img src="${img}" style="width:100%;"></div>`,
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText:
        '<i class="far fa-image"></i> Open the Image in another Tab',
      cancelButtonText: 'Close',
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(img, '_blank');
      }
    });
  }

  /**
   * Shows an error message using Swal (SweetAlert2) library.
   * @param title - The title of the error message.
   * @param text - The content text of the error message.
   */
  private showErrorMessage(title: string, text: string): void {
    Swal.fire({
      icon: 'error',
      title: title,
      text: text,
    });
  }
}
