import { Injectable } from '@angular/core';
import { ProductLocation } from 'src/app/models/storage/product-location.model';
import { ProductGroup } from 'src/app/models/storage/product-group.model';

@Injectable({
  providedIn: 'root'
})
export class ProductLocationService {

  constructor() { }

  public getDefaultLocation(locationMap: { [key: string]: ProductLocation }): ProductLocation {

    // Attempt to find a default location in locationMap and use it
    const locationsArr = Object.values(locationMap);
    const defaultLocation = locationsArr.find((loc) => loc.isDefault);

    if (defaultLocation) { // If default found, assign and stop further processing
      return defaultLocation;
    }

    if (locationsArr.length >= 1) { // No default found, choose 1st available location
      return locationsArr[0];
    }

    throw new Error('No available location found')
  }

  // If needed, in the future we could loop through products instead of product groups to capture if all items are unavailable at location etc
  public filterLocationsByActiveProducts(productGroupArr: ProductGroup[], locationMap: { [key: string]: ProductLocation } = {}): string[] | ProductLocation[] {
    const uniqueLocations = new Set<string>();

    productGroupArr.forEach((pg: ProductGroup) => {
      pg.availableLocations.forEach((locationID: string) => {
        if (locationMap && !locationMap[locationID]) {
          console.warn(`${pg.groupName} - ${pg.id} has a location that is either inactive or no longer exists: ${locationID}`);
          return
        }
        uniqueLocations.add(locationID);
      })
    })

    const arrayOfUniqueLocationIDs = Array.from(uniqueLocations);
    if (!locationMap) {
      return arrayOfUniqueLocationIDs; // Returns as string[]
    }

    let arrOfLocationObjects: ProductLocation[] = [];
    arrayOfUniqueLocationIDs.forEach((locationID: string) => {
      arrOfLocationObjects.push(locationMap[locationID]);
    });

    return arrOfLocationObjects; // Returns as object[]
  }
}
