import {Component, Input, OnInit} from '@angular/core';
import {PartnerService} from "../../services/partner.service";
import {CurrentUserService} from "../../services/current-user.service";
import { User } from 'src/app/models/storage/user.model';
import {AngularFireFunctions} from "@angular/fire/compat/functions";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {StorageService} from "../../services/storage.service";
import {SelectItem} from 'primeng/api';
import {SelectItemGroup} from 'primeng/api';
import Swal from 'sweetalert2';
import {UserService} from "../../services/user.service";
import {Subject} from "rxjs";
import {data} from "jquery";
@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
  @Input() currentUser: User;
  isAccount: boolean = true;
  isEditing = true;
  userInfo: UntypedFormGroup;
  icons: any[] = [];


  constructor(private _partnerService: PartnerService,
              public _currentUserService: CurrentUserService,
              private _storageService: StorageService,
              private _afFun: AngularFireFunctions,
              private _userService: UserService) {
        this.userInfo = new UntypedFormGroup({
      firstName: new UntypedFormControl('', Validators.required),
      lastName: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl(''),
      phone: new UntypedFormControl('', Validators.required),
      levelType: new UntypedFormControl('', Validators.required),
      icon: new UntypedFormControl(''),
    })

  }

  ngOnInit(): void {
  }


}
