import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

/* Services */
import { FirestoreService } from 'src/app/v2/services/firestore.service';

/* Models */
import { Collection } from 'src/app/v2/models/collection-reference.model';
import { Condition } from 'src/app/v2/models/firestore-interaction.model';

@Injectable({
  providedIn: 'root'
})
export class RouteQueryGuardService {

  constructor(private router: Router, private firestoreService: FirestoreService) {}

  public async resolveDocument(collection: Collection, documentID: string, conditions?: Condition[]): Promise<any> {
    try {
      const document = await this.firestoreService.getDocument(collection, documentID);
      if (!document) {
        console.error(`Document not found: ${documentID} in collection ${collection}`);
        this.navigateTo404();
        return null;
      }

      if (conditions) {
        const isValid = conditions.every((condition: Condition) => {
          const docValue = document[condition.field as string];
          switch (condition.operator) {
            case '==':
              return docValue === condition.value;
            case '<':
              return docValue < condition.value;
            case '<=':
              return docValue <= condition.value;
            case '>':
              return docValue > condition.value;
            case '>=':
              return docValue >= condition.value;
            default:
              return false;
          }
        });

        if (!isValid) {
          console.error(`Condition failed for document ${documentID} in collection ${collection}`);
          this.navigateTo404();
          return null;
        }
      }

      return document;
    } catch (err) {
      console.error(`Error retrieving document ${documentID} in collection ${collection}`, err);
      this.navigateTo404();
      return null;
    }
  }

  public navigateTo404(): void {
    this.router.navigate(['404']);
  }
}
