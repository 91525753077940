<ng-container *ngIf="isGridUpdated">
  <gridstack [style.height.px]="gridHeight" style="margin-left: 10px; margin-right: 10px;" [options]="gridstackConfig"
    (changeCB)="onGridstackItemChange($event)">
    <gridstack-item *ngFor="let gridItem of gridContent;" [options]="gridItem">
      <!-- Creator/Editor Btns -->
      <ng-container *ngIf="isCreator">
        <!-- Delete Btn -->
        <button type="button" aria-label="close"
          (click)="processCreatorCardAction({type: BookingFlowContentAction.Remove, contentID: gridItem.contentData.contentID})" id="deleteBtn"
          mat-icon-button><i class="fa fa-close"></i>
        </button>
        <!-- Edit Button -->
        <button *ngIf="gridItem.contentData.isPage" (click)="processCreatorCardAction({type: BookingFlowContentAction.EditPage, pageID: gridItem.contentData.id})"
          class="btn actionBtn" mat-icon-button
          style="background-color: #198754cc; z-index: 1; position: absolute; right: 55px; top: 4px;">
          <i class="fa fa-pencil"></i>
        </button>
      </ng-container>

      <!-- Content -->
      <app-content-card (click)="navigateByContentCard(gridItem.contentData)" class="content-container"
        style="z-index: 22;" [contentInfo]="gridItem.contentData">
      </app-content-card>
    </gridstack-item>
  </gridstack>
</ng-container>