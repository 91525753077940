<!-- <p>logo-transition works!</p> -->


<!-- <button type="button" class="py-2 px-8 first-letter:mb-2 text-white bg-lime-500"
    (click)="toggleEvents()">Toggle</button> -->

<!-- <ng-container *ngIf="showEvents">
  <div style="height: 50vh">
    <span @fade (@fade.start)="toggleFlag = true" (@fade.done)="toggleFlag = false">Fleetmaid</span>
    <span @fadeSlide (@fadeSlide.start)="toggleFlag = true" (@fadeSlide.done)="toggleFlag = false"> asdf</span>
  </div>
</ng-container> -->

<section>
  <button (click)="toogle=!toogle">click</button>
  <div style="height: 50vh" [@flyInOut] *ngIf="toogle">
    FM
    <!-- <button (click)="toogle=false">x</button> -->
    <span @fade (@fade.start)="toggleFlag = true" (@fade.done)="toggleFlag = false">Fleetmaid</span>
  </div>
</section>
