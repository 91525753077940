import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpClient: HttpClient, private router: Router) { }

  post(url: string, body: {}): Observable<any> {
    return this.httpClient.post(url, body).pipe(
      catchError(this.handleError)
    );;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    // Handle specific status codes
    switch (error.status) {
      case 404:
        return throwError(() => ({ message: 'Resource not found', status: 404, error: error.error }));
      case 500:
        return throwError(() => ({ message: 'Internal server error', status: 500, error: error.error }));
      default:
        return throwError(() => ({ message: 'An error occurred; please try again later.', status: error.status, error: error.error }));
    }
  }
}
