<div *ngIf="!dataIsAvailable" class="d-flex text-center justify-content-center align-items-center" style="margin-top: 50px;">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div
  class="container"
  style="overflow-x: auto !important"
  *ngIf="dataIsAvailable"
>
  <mat-tab-group animationDuration="0ms">
    <!--------- Layouts ---------->

    <mat-tab label="Layouts">
      <div class="container-fluid mt-3">
        <div>
          <h2 class="align-middle text-center">Layouts</h2>
          <div style="margin-bottom: 10px">
            <div style="margin: 10px" class="align-right text-right">
              <button
                class="btn btn-success"
                (click)="openCreateTemplateModal()"
              >
                Create New Layout
              </button>
            </div>
          </div>
        </div>
        <table class="table table-striped hover">
          <thead>
            <tr id="tableHeader">
              <th scope="col">Title</th>
              <th scope="col">Edit</th>
              <th scope="col">Assign</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              *ngFor="let inventoryPage of inventoryPages; let i = index"
              style="cursor: pointer"
            >
              <tr style="padding: 25px">
                <td style="width: 80%">

                  <div *ngIf="!edit[i]" (click)="inventoryPageTitleInteraction(i, 'open')" [class.edit]="edit[i]">
                    <i id="pencilactive" class="fa fa-solid fa-pencil mx-1" [class.pencilactive]="edit[i]"></i>
                    <span>{{ inventoryPage.title }}</span>
                  </div>
    
                    <input
                      *ngIf="edit[i]"
                      [(ngModel)]="inventoryPageTitlesWhileEditing[inventoryPages[i].id]"
                    />

                  <i
                    *ngIf="edit[i]"
                    tooltip="Save"
                    class="fa fa-check-circle mx-1 text-success"
                    (click)="inventoryPageTitleInteraction(i, 'save')"
                  ></i>

                  <i
                    *ngIf="edit[i]"
                    tooltip="Cancel"
                    class="fa fa-times-circle text-danger"
                    (click)="inventoryPageTitleInteraction(i, 'cancel')"
                  ></i>
                </td>
                <td>
                  <button
                    class="btn btn-sm btn-success"
                    [routerLink]="[
                      '/partner/inventoryPageBuilder/',
                      inventoryPage['id']
                    ]"
                    routerlinkActive="active"
                  >
                    <i class="fa fa-pencil"></i>
                  </button>
                </td>
                <td>
                  <button
                    class="btn btn-sm btn-warning"
                    data-bs-toggle="modal"
                    data-bs-target="#productModal"
                    (click)="getLayoutIdModal(inventoryPage.id)"
                    style="padding: 5px"
                  >
                    <i class="fas fa-bezier-curve"></i>
                  </button>
                </td>
                <!-- <td style="width:100%"></td> -->
                <td>
                  <span *ngIf="!inventoryPage.isDefault">
                    <button
                      class="btn btn-sm btn-danger"
                      (click)="
                        deleteLayout(inventoryPage.id)
                      "
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </span>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </mat-tab>

    <!--------- Inventory Pages ---------->

    <mat-tab label="Inventory Pages">
      <div class="container-fluid mt-3">
        <h2 class="align-middle text-center">Inventory Pages</h2>

        <div *ngIf="productGroups; else noInvPages">
          <table class="table table-striped hover">
            <thead class="tableHeader">
              <tr>
                <th scope="col">Product Group</th>
                <!-- <th scope="col"></th> -->
                <th scope="col">Layout</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let group of productGroups; let i = index">
                <td>
                  {{ group["groupName"] }}
                </td>
                <!-- <td [routerLink]="['/partner/inventoryPageBuilder/', product.inventoryPageId]" routerlinkActive="active">
                                        <i class="fas fa-eye"></i>
                                    </td> -->
                <td>
                  <div
                    class="badge badge-success"
                    style="
                      padding: 7px;
                      cursor: pointer;
                      font-weight: 500;
                      font-size: 12px;
                      letter-spacing: 0.5px;
                    "
                  >
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#productLayoutModal"
                      (click)="openProductLayoutModal(group)"
                      >{{ getInventoryPageName(group.inventoryPageId) }}</a
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <ng-template #noInvPages>
          <div
            class="align-middle text-center"
            style="background-color: #e5e5e5"
          >
            <span>No Products</span>
          </div>
        </ng-template>
      </div>
    </mat-tab>

    <!--------- Parent Pages ---------->

    <!-- <mat-tab label="Parent Pages">
            <div class="container-fluid mt-3">
                <table class="table table-striped hover">
                <div>
                    <h2 class="align-middle text-center">Group Pages</h2>
                </div>
                <ng-container *ngIf="parentLoaded; else noParents">
                    <tbody>
                        <tr *ngFor="let page of productGroups" style="cursor:pointer">
                            <div style="display:flex;"  >
                                <td>
                                    <button class="btn btn-sm btn-success"
                                        [routerLink]="['/partner/inventoryPageParent', page.id]" routerlinkActive="active">
                                        <i class="fas fa-eye"></i>
                                    </button>
                                </td>
                                <td>
                                    {{page.groupName}}
                                </td>
                            </div>
                        </tr>
                    </tbody>
                </ng-container>
                <ng-template #noParents>
                    <div class="align-middle text-center" style="background-color: #E5E5E5;">
                        <span>No Groups</span>
                    </div>
                </ng-template>
                </table>
            </div>
        </mat-tab> -->

    <!--------- Saved Widgets ---------->

    <mat-tab label="Saved Widgets">
      <div class="container-fluid mt-3">
        <h2 class="align-middle text-center">Saved Widgets</h2>
        <div *ngIf="widgetList.length > 0; else noSavedWidgets">
          <table class="table table-striped hover">
            <thead class="tableHeader">
              <tr>
                <th scope="col">Widget Name</th>
                <th scope="col">Type</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let widget of widgetList; let i = index"
                style="cursor: pointer">
                <td style="width: 40%">

                  <div *ngIf="widgetEdit != i" (click)="activateEditWidgetTitle(i, widget)">
                    <i [class.pencilactive]="widgetEdit == i" id="pencilactive" class="fa fa-solid fa-pencil"></i>
                    <span>
                      {{ widget["widgetName"] }}
                    </span>
                  </div>

                    <input
                    [(ngModel)]="newWidgetName"
                    *ngIf="widgetEdit == i"
                    />

                  <i
                    *ngIf="widgetEdit == i"
                    tooltip="Save"
                    class="fa fa-check-circle mx-1 text-success"
                    (click)="saveNewWidgetName(widget);"
                  ></i>

                  <i
                    *ngIf="widgetEdit == i"
                    tooltip="Cancel"
                    class="fa fa-times-circle text-danger"
                    (click)="closeEditWidgetTitle(i, widget)"
                  ></i>
                </td>
                <td style="width: 40%">
                  {{ widget["widgetType"] }}
                </td>
                <td>
                  <div>
                    <button
                      class="btn btn-sm btn-success"
                      [routerLink]="[
                        '/partner/inventoryPageBuilder/savedWidgetEditor/',
                        widget['id']
                      ]"
                      routerlinkActive="active"
                    >
                      <i class="fa fa-pencil"></i>
                    </button>
                  </div>
                </td>
                <td>
                  <span>
                    <button
                      class="btn btn-sm btn-danger"
                      (click)="
                        deleteWidget(widget.id)
                      "
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <ng-template #noSavedWidgets>
          <div
            class="align-middle text-center"
            style="background-color: #e5e5e5"
          >
            <span>No Saved Widgets</span>
          </div>
        </ng-template>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- Create Template Modal -->
<div
  class="modal fade"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  id="createTemplateModal"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Create Layout</h4>
        <button
          type="button"
          class="btn btn-danger"
          (click)="closeTemplateModal()"
          aria-label="Close"
        >
          <i class="fa fa-close"></i>
        </button>
      </div>

      <div class="modal-body">
        <form
          class="needs-validations"
          #groupf="ngForm"
          [formGroup]="layoutForm"
          (ngSubmit)="createNewTemplate()"
        >
          <!-- title -->
          <div class="row">
            <div class="form-group-sm mb-2 input-group-sm">
              <label class="form-check-label" for="layoutTitle">Title</label>
              <span class="text-danger">*</span>
              <!-- Required Symbl -->
              <input
                class="form-control"
                type="text"
                name="layoutTitle"
                id="templateTitle"
                formControlName="title"
              />
            </div>
            <span
              class="text-danger mb-2"
              *ngIf="title.errors?.['required'] && title.touched"
            >
              A layout must have a title.
            </span>
          </div>

          <!-- Buttons -->
          <div class="container-xxl">
            <div class="row justify-content-center mt-2">
              <div class="text-center">
                <button
                  type="submit"
                  class="btn btn-success"
                  [disabled]="layoutForm.invalid"
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Connect Layout Modal -->
<div
  class="modal fade"
  id="productModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="productModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title fs-5" id="productModalLabel">
          Assign products to the layout
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="unselectLayoutId()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="container-fluid mb-3">
          <div class="mb-3">
            <div class="row">
              <div class="col-6 text-left"></div>
              <div class="col-6 text-right"></div>
            </div>

            <div class="form-text">Select a product to assign the Layout</div>
          </div>
        </div>
        <div class="container-fluid tableheight">
          <table
            id="productsTable"
            class="table text-center"
            *ngIf="productGroups"
          >
            <thead class="bgtable">
              <tr id="tableHeader">
                <th scope="col">Product Name</th>
                <th scope="col">Category</th>
                <th scope="col">Layout</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let group of productGroups; let i = index">
                <tr>
                  <td>{{ group.groupName }}</td>
                  <td>{{ getInventoryPageName(group.inventoryPageId) }}</td>
                  <td class="text-center">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        [checked]="group.inventoryPageId == selectedLayoutId"
                        id="flexCheckDefault"
                        (click)="selectProductLayout(group, i)"
                      />
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="saveLayoutItems()"
        >
          Save Changes
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Change a products layout -->
<div
  class="modal fade"
  id="productLayoutModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="productModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title fs-5" id="productModalLabel">Choose a layout</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="closeProductLayoutModal()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="container-fluid mb-3">
          <div class="form-text">
            Select a layout to assign the product group
          </div>
        </div>
        <div class="container-fluid tableheight">
          <table
            id="productsTable"
            class="table text-center"
            *ngIf="productGroups"
          >
            <thead class="bgtable">
              <tr id="tableHeader">
                <th scope="col">Group Name</th>
                <th scope="col">Select</th>
              </tr>
            </thead>
            <tbody class="text-center">
              <ng-container *ngIf="productLayoutChange">
                <tr>
                  <td>{{ productLayoutChange.groupName }}</td>
                  <td class="text-center">
                    <div>
                      <select
                        class="form-select"
                        [(ngModel)]="chosenLayout"
                        selected="chosenLayout"
                      >
                        <option
                          [ngValue]="choice"
                          *ngFor="let choice of inventoryPages"
                        >
                          {{ choice["title"] }}
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="saveProductLayout()"
        >
          Save Changes
        </button>
      </div>
    </div>
  </div>
</div>
