import { Component, Input, Output, EventEmitter} from '@angular/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-booking-timeslot-list',
  templateUrl: './booking-timeslot-list.component.html',
  styleUrls: ['./booking-timeslot-list.component.scss']
})


export class BookingTimeslotListComponent {
  @Input() timezone: string; // Timezone of given location
  @Input() timeslotArr: any = { }
  @Input() productsMap: any = { }
  @Input() itemsInCartObj: boolean; 
  @Input() rentalTypeOptions: any = []; 
  @Input() hoursArray: any = []; 
  @Input() bookingTimeSlotSelected: any; 

  @Output() getTimeslotSelection = new EventEmitter(); // Submit timeslot selection
  @Output() cancelTimeslotSelection = new EventEmitter(); // Cancel timeslot selection / close modal

  currentSelection: {dayStart: DateTime, dayEnd: DateTime, hourLength: {hours: number}, productIDs: string[], swappableItems: string[]} | null = null; // Passed back after submission
  selectedSwappableIndex: number = 0; // Used to display the unavailable items for a given timeslot

  /* Hourly Filter Variable */
  selectedHourlyFilter: number | null = null;

  /* All | Available Filter Variables */
  showAll: boolean = true;
  showAvailable: boolean = false;
  rentalTypeFilter: string = "All";

  ngOnInit() {
    if (this.bookingTimeSlotSelected && Object.keys(this.bookingTimeSlotSelected).length > 0) {
      this.timeslotArr.forEach((item:any) => {
        if (item?.dayStart.hasSame(this.bookingTimeSlotSelected?.dayStart,'minute') && item?.dayEnd.hasSame(this.bookingTimeSlotSelected?.dayEnd, 'minute')
            && item?.type == this.bookingTimeSlotSelected?.type) {
          this.currentSelection = item;
        }
      })
    }
  }


  updateRentalType(type) {
    this.rentalTypeOptions.forEach((option:any) => {
      if (option.type === type) {
        option.isSelected = true;
      }
      else {
        option.isSelected = false;
      }
    })
    this.rentalTypeFilter = type;

    if (this.rentalTypeFilter != 'Hourly') {
      this.selectedHourlyFilter = null;
    }
  }

  updateIsSelected(index) {
    this.timeslotArr.forEach(item => {
      if (item?.isSelected) {
        item.isSelected = false;
      }
    })
    this.timeslotArr[index].isSelected = true;
    this.currentSelection = this.timeslotArr[index];
  }

} // End of class

