import {Injectable} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class SharedDataService {
    constructor(){}

    public data: any = []

    public subject = new Subject<any>()
    private dataSource = new BehaviorSubject(this.data)

    currentData = this.dataSource.asObservable()
    
    updateData(data: any){
        this.dataSource.next(data)
    }

}