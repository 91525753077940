import { Timestamp } from "firebase/firestore";

export interface WidgetInterface {
  id?: string;
  name: string;
  widgetType: WidgetType,
  iconClass?: string;
  savedWidgetId?: string;
  sectionContentId?: string;
  sectionPosition?: [];
  addedWidgets?: WidgetInterface[];
  accordianItems?: [];
  isSelected?: boolean;
  componentSettings?: {};
  isCartWidget?: boolean;
  lastModified?: Timestamp;
  element?: 
  | CheckboxElementInterface
  | QuantityElementInterface
  | PriceElementInterface
  | TextAreaElementInterface
  | ImageElementInterface
  | RadiosElementInterface
  | DropdownElementInterface
  | ProductElementInterface
  | HeightInputElementInterface
  | WeightInputElementInterface
  | TipElementInterface;
}

export enum WidgetType {
  checkbox = 'checkbox',
  quantity = 'quantity',
  price = 'price',
  textarea = 'textarea',
  image = 'image',
  radios = 'radios',
  dropdown = 'dropdown',
  product = 'product',
  heightInput = 'heightInput',
  weightInput = 'weightInput',
  tip = 'tip'
}

// Minimum required properties for most widgets (Except for textarea and image widgets)
export interface BaseElementInterface {
  label: string;
  description: string;
}

export interface CheckboxElementInterface extends BaseElementInterface {
  price: number;
  inputValue: boolean;
  is_required: boolean;
  recurringPrice?: boolean;
}

export interface QuantityElementInterface extends BaseElementInterface {
  price: number;
  inputValue: number;
  is_required: boolean;
  recurringPrice?: boolean;
  min: number;
  max: number;
}

export interface RadiosElementInterface extends BaseElementInterface {
  inputValue: any;
  is_required: boolean;
  options: RadiosOptionsInterface[];
  show_label: boolean;
  recurringPrice?: boolean;
}

export interface DropdownElementInterface extends BaseElementInterface {
  inputValue: any;
  is_required: boolean;
  options: DropdownOptionsInterface[];
  show_label: boolean;
  recurringPrice?: boolean;
}

export interface PriceElementInterface extends BaseElementInterface {
  price: number;
  recurringPrice?: boolean;
}

export interface HeightInputElementInterface extends BaseElementInterface {
  is_required: boolean;
  feetInput: number,
  inchInput: number
}

export interface WeightInputElementInterface extends BaseElementInterface {
  is_required: boolean;
  inputValue: number;
}

export interface TipElementInterface extends  BaseElementInterface {
  price: number;
  inputValue: number;
  is_required: boolean;
}

export interface ProductElementInterface extends BaseElementInterface {
  groupId: string;
  name: string;
  size: string;
  appliesToTourProductCount?: boolean
  is_required: boolean;
  isDropdown: boolean;
  min: number;
  max: number;
  priceByDay:  [{day: number, increment: number, price: number}];
  priceByHour: [{hour: number, price: number}];
  is24hrsPrice: boolean;
  price: number;
  show_label: boolean;
  show_description: boolean;
  show_price: boolean;
  show_price_label: boolean;
  show_name: boolean;
  inputValue: number;
  options: ProductOptionsInterface[] | ProductOptionsDropdownInterface[];
}


// -- Unique Widgets -- //
export interface TextAreaElementInterface {
  label: string;
  is_required: boolean;
  show_label: boolean;
  content: string;
}

export interface ImageElementInterface {
  image_link: string;
  alignment: string;
  alt_text: string;
  width: number;
}




// --- Widget options interfaces --- //
export interface RadiosOptionsInterface {
  id: string;
  text: string;
  description: string;
  is_selected: boolean;
  price: number;
}

export interface DropdownOptionsInterface {
  id: string;
  text: string;
  is_selected: boolean;
  price: number;
}

export interface ProductOptionsInterface {
  id: string;
  size?: string;
  sizeID?: string;
  inputValue?: number;
  show?: boolean;
  isDisabled?: boolean;
  price?: number;
  productsCheckedout?: [];
  productsAvail?: [];
  max?: number;
  is_selected?: boolean;
}

export interface ProductOptionsDropdownInterface {
  id: string;
  size?: string;
  sizeID?: boolean;
  is_selected: boolean;
  show: boolean;
  isDisabled: boolean;
  price?: number;
  productsCheckedout?: any[];
  productsAvail?: any[];
}

export const advenireSavedWidgetId: string = 'advenireCheckbox1';
export const reservationSavedWidgetId: string = 'reservationCheckbox1';
