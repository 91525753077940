import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productNameFilter'
})
export class ProductNameFilterPipe implements PipeTransform {

  transform(products, term){
    // Create an empty array
    let filteredArray = []

    if(term && term.length > 0){
    
    // Filter the array if theres a match to the term. (Lowercase to make comparing accurate)

    // this.products = this.productscopy.filter((product) => {
    //   return Object.keys(product).some((key) => {
    //     if(key === "productName" || key === "productNumber" || key === "productSize" || key === "productCategory" || key === "price" || key === "description"){
    //       return product[key].toString().toLowerCase().includes(this.inputSearch.toLowerCase())
    //     }
    //   })
    // })


        filteredArray = products.filter((product) => {
      return Object.keys(product).some((key) => {
        if(key === "productName" || key === "productNumber" || key === "productSize" || key === "productCategory" || key === "price" || key === "description" || key === "groupName"){
          return product[key].toString().toLowerCase().includes(term.toLowerCase())
        }
      })
    })


    // filteredArray = products.filter(
    //   (product) => 
    //     product.productName.toLowerCase().includes(term.toLowerCase())
    //   );
    }

    // return filteredArray.length > 0 ? filteredArray : products;
    return term.length > 0 ? filteredArray : products;
  }

}
