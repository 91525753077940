import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { AuthService } from './services/auth.service';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],

})
export class LoginComponent implements OnInit {

  isProgressVisible: boolean;
  loginForm: UntypedFormGroup;
  email: string;
  password: string;
  firebaseErrorMessage: string;
  confirm: boolean;
  checkEmail: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private afAuth: AngularFireAuth,
  ) {

    this.isProgressVisible = false;

    this.loginForm = new UntypedFormGroup({
      'email': new UntypedFormControl('', [Validators.required, Validators.email]),
      'password': new UntypedFormControl('', Validators.required)
    });

  }

  ngOnInit(): void {
    this.authService.getAuth().subscribe(auth => {
      if (auth) {
        this.router.navigate(['/partner']);
      }
    })
    this.confirm = false;
    this.checkEmail = false;
  }

  login() {
    this.isProgressVisible = true;

    if (this.loginForm.invalid) {
      this.isProgressVisible = false;

      return;
    }
    this.authService.login(this.loginForm.value.email, this.loginForm.value.password)
      .then(result => {

        this.isProgressVisible = false;
        this.router.navigate(['/partner']);  // when the user is logged in, navigate them to dashboard
      }).catch(err => {
      this.isProgressVisible = false;
      this.firebaseErrorMessage = err.message;
    });

  }

  resetPassword() {
    this.confirm = true;
    this.checkEmail = false;
  }

  confirmReset() {
    this.authService.resetPassword(this.email).then(data => {
      this.checkEmail = true;
      this.confirm = false;
    })
  }

  return() {
    this.confirm = false;
    this.checkEmail = false;
    this.firebaseErrorMessage = '';
  }


}