<!-- Card with a little image profile on the left side -->
<div class="row" (click)="launchInfo()">
  <div class='col-2'>
    <img class="card-img-top img-rounded" *ngIf="user.icon.code" src="{{user.icon.code }}" alt="#"/>
    <img class="card-img-top img-rounded" *ngIf="!user.icon.code" src="assets/user/noFace.png" alt="#"/>
    <div *ngIf="presence$ && (presence$.status == 'Online')" class='status-circle online'>
    </div>
    <div *ngIf="!presence$ || (presence$.status == 'Offline')" class='status-circle offline'></div>
    <div *ngIf="presence$ && (presence$.status == 'Away')" class='status-circle away'></div>

  </div>


  <div class="col-8">
    <div class="card-body">
      <span class="card-subtitle"><strong>{{user.firstName}} {{user.lastName}}</strong></span><br/>
      <span class="card-text">{{user.email}}</span><br/>
      <span class="card-text"><small class="text-muted">{{user.phone}}</small></span>
    </div>
  </div>
<!--  <div class="col-4" *ngIf="user.isActive && !isAccount">-->
<!--    &lt;!&ndash; Last Connection Info and login status &ndash;&gt;-->
<!--    <div class="card-body">-->
<!--      <h5 class="card-title"><strong>Last Connection<br></strong></h5>-->
<!--      <span class="card-text"><i class="fas fa-clock-o"></i>{{user.lastConnection | date}}</span>-->

<!--    </div>-->


<!--  </div>-->
<!--  <div class="col-4" *ngIf="!user.isActive ">-->
<!--    &lt;!&ndash; invactive badge &ndash;&gt;-->
<!--    <div class="card-body">-->
<!--      <h5 class="card-title"><strong>Inactive</strong></h5>-->
<!--      <span class="card-text"><i class="fas fa-clock-o"></i>{{user.lastConnection | date}}</span>-->
<!--    </div>-->

<!--  </div>-->
  <div class="col-1" *ngIf="!user.isActive">
    <!-- Reactivate icon -->
    <div class="mt-2">
      <i class="fas fa-undo" style="color: blue" (click)="enableUser()"></i>
    </div>
  </div>
  <div class="col-1" *ngIf="user.isActive && !isAccount">
    <!-- red trash icon -->
    <div class="mt-2">
      <i class="fas fa-trash-alt" style="color: red" (click)="deleteUser()"></i>
    </div>
  </div>
  <!-- Delete icon  on top right corner -->
</div>
