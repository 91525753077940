import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductTableComponent } from './product-table/product-table.component';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../components/components.module';
import { MaintenanceComponent } from './maintenance.component';
import { RouterModule, Routes } from '@angular/router';

import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [ProductTableComponent, MaintenanceComponent],
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    RouterModule,
    NgSelectModule,
    MatIconModule,
  ],
})
export class MaintenanceModule {}
