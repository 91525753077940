
    <div style="position: absolute; top: 25%; left: 50%; transform: translateX(-50%); z-index: 1">
        <div>
            <div class="ml-3">
                <h1 i18n>Page not found</h1>
                <p i18n>If you think this was a mistake, please contact FleetMaid support.</p>
            </div>
            <img src="../../assets/images/logos/theme2.png" style="max-height: 4em; -webkit-filter: invert(100%); filter: invert(100%);">
        </div>
    </div>
