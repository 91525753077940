<ng-container *ngIf="isLoaded">
  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">

    <!-- Template Editor - Contains the save btn, template options, etc, (only accessable as a creator) -->
    <app-template-editor style="width: 100%;" *ngIf="true" [pages]="bookingPagesCollectionArray"
      [productGroups]="productGroupCollectionArray" (saveClicked)="onSave()"
      (addContentModalSubmitted)="creatorAddContentToGrid($event)" (showCreateNewPageForm)="switchToNewPageForm()"
      [catalogTitle]="templateObj?.title">
    </app-template-editor>

    <!-- Add / Edit modal - Only called on Creator view -->
    <div #pageModal class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="pageModal" tabindex="-1"
      role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              <span *ngIf="!creatorIsEditing">Create Page</span>
              <span *ngIf="creatorIsEditing">Edit Page</span>
            </h4>
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close">
              <i class="fa fa-close"></i>
            </button>
          </div>

          <div class="modal-body">
            <app-page-form (outputNewlyCreatedPageDoc)="onNewPageFormSubmission($event)"
              (outputUpdatedPageDoc)="onPageEditFormSubmission($event)" [isEditing]="creatorIsEditing"
              [companyID]="templateObj?.companyID"></app-page-form>
          </div>
        </div>
      </div>
    </div>

    <!-- Iframe -->
    <div id="customFlowCont">
      <app-booking-header [templateObj]="templateObj" [isScreenLarge]="true"></app-booking-header>
      <app-booking-catalog-breadcrumbs [templateObj]="templateObj" [currentContentID]="customerContentID"
        [isCreator]="true" [productGroupCollectionMap]="productGroupCollectionMap"
        [bookingPagesCollectionMap]="bookingPagesCollectionMap"
        (sendCreatorContentID)="setCreatorContentID($event)">
      </app-booking-catalog-breadcrumbs>
      <app-booking-catalog-view [templateObj]="templateObj" [bookingPagesCollectionMap]="bookingPagesCollectionMap"
        [productGroupCollectionMap]="productGroupCollectionMap" [productsCollectionMap]="productsCollectionMap"
        [currentContentID]="customerContentID" [isCreator]="true"
        (sendCreatorContentID)="setCreatorContentID($event)" (sendCreatorCardAction)="processCreatorCardAction($event)">
      </app-booking-catalog-view>
      
      <!-- Footer -->
      <app-booking-footer></app-booking-footer>
    </div>

    <div style="margin-bottom: 20em; margin-top: 20em;">
      <span style="visibility: hidden;">spacer</span>
    </div>
  </div>
</ng-container>

<!-- Loader -->
<ng-container *ngIf="!isLoaded">
  <div style="position: relative; height: 600px;">
    <div style="position: absolute; left: 50%; bottom:40%; z-index: 3;">
      <div class="spinner-border" role="status" style="width: 3em !important; height: 3em !important">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</ng-container>