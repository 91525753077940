import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { ImageLibraryService } from 'src/app/services/image-library.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
  AccessibilityHelp,
  Alignment,
  Bold,
  InlineEditor,
  EditorConfig,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  HorizontalLine,
  Italic,
  Link,
  List,
  Underline,
  Undo,
 } from 'ckeditor5';

@Component({
  selector: 'app-inventory-widget-display',
  templateUrl: './inventory-widget-display.component.html',
  styleUrls: ['./inventory-widget-display.component.scss']
})
export class InventoryWidgetDisplayComponent {
  @Input() widget: any;
  @Input() editMode: boolean;
  @Input() displayID: any;
  @Input() isRentalEdit: boolean; // Editing a rental from the mini cart view
  @Input() savedWidgetEditor: boolean;

  @Output() widgetInputChange = new EventEmitter<any>();
  @Output() productInputChange = new EventEmitter<any>();
  subs = new Subscription(); // group of subscriptions

  public Editor: any = InlineEditor
  editorData;
  temporaryProductWidgetDropdown;
  productDropdownIsAvailable = true;
  showPage = false;
  // panelOpenState = false;
  // public Editor =  ClassicEditor;

  public editorConfig: EditorConfig = {
    toolbar: {
			items: [
				'heading',
				'|',
				'bold',
				'italic',
				'underline',
				'|',
        'fontSize',
				'fontFamily',
        'fontColor',
				'fontBackgroundColor',
				'|',
				'horizontalLine',
				'link',
				'bulletedList',
				'numberedList',
				'alignment',
				'|',
				'undo',
				'redo'
			]
		},
    placeholder: "Type something here!",
    plugins: [
      AccessibilityHelp,
      Alignment,
      Essentials,
      Bold,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      Heading,
      HorizontalLine,
      Italic,
      Link,
      List,
      Underline,
      Undo
    ]
  };

  productRequirementMet:boolean = false;


  constructor(private productsService: ProductsService, private http: HttpClient,
    private imageLibraryService: ImageLibraryService, private activatedRoute: ActivatedRoute,
    private router: Router){};


  async ngOnInit() {
      if(this.editMode){
        this.showPage = true;
      }
      // If the product widget is required and the min input is 0 then it will resolve the is required warning
      else if (this.widget.widgetType == 'product' && !this.widget['element']['isDropdown']) {
        if (this.widget.element.is_required && this.widget.element.min == 0) {
          this.productRequirementMet = true;
          this.showPage = true;
        }
        else if (this.widget.element.is_required && this.widget.element.min > 0) {
          this.productRequirementMet = await this.checkProductWidgetQuantityRequirements();
          this.showPage = true;
        }
        else{
          this.showPage = true;
        }
      }
      // If the widget is a product dropdown and there is nothing available show that there is nothing available
      else if (this.widget.widgetType == 'product' && this.widget['element']['isDropdown']) {
        let optionAvail = false;
        this.widget.element.options.forEach(option => {
          if (!option['isDisabled'] && option['max'] > 0) {
            optionAvail = true;
          }
        })
        this.productDropdownIsAvailable = optionAvail;
        this.showPage = true;
      }
      else {
        this.showPage = true;
      }
    }

  inputChange(ID){
    console.log("Input change")
    this.widgetInputChange.emit(ID);
  }

  quantityInputChange(ID, element){
    if(element.inputValue == null || element.inputValue == undefined){
      element.inputValue = 0;
    }
    this.widgetInputChange.emit(ID);
  }

  productWidgetChange(optionChange, widgetID, ID){
    this.validateProductRequirements();
    // Set the input to the max if the input is more than the max
    if(optionChange.inputValue > optionChange.max){
      optionChange.inputValue = optionChange.max
    }
    // // // Set the input to the max if the input is more than the max
    // else if(optionChange.inputValue == null){
    //   optionChange.inputValue = 0;
    // }
    console.log("OPTION CHNAGE INPUT")
    console.log(optionChange)
    console.log(JSON.parse(JSON.stringify(this.widget)))
    // Dont emit change is requirement arent passed, the price calculations will be triggered
    this.productInputChange.emit({option: optionChange, widgetID: this.widget.id, ID:ID, widget: this.widget});

  }


  // When checkbox input is clicked
  onClickedCheck(ID) {
    this.widget.element.inputValue = !this.widget.element.inputValue;
    console.log("INPUT VALUE")
    console.log(this.widget.element.inputValue)
    this.widgetInputChange.emit(ID);

  }


  // When radio or dropdown input is changed
  updateSelectOrRadios(widgetType, list, $event, ID) {
      // resetting the input value
      this.widget['element']['inputValue'] = "";
      //Updates is-selected and input value for radios and dropdowns
      list.forEach((choice, index) => {
        if (choice.id === $event.target.value) {
          choice.is_selected = true;
          // Sets price of element if there is a price
          this.widget['element']['inputValue'] = choice['id'];
          if(this.widget['widgetType'] === 'product'){
            choice.inputValue = 1;
            choice.price = this.widget['element']['price'];
          }

        }
        else {
          choice.is_selected = false;
          if(this.widget['widgetType'] === 'product'){
            choice.inputValue = 0;
            delete choice.price
          }
          // console.log('false')
        }
      })
      if(this.widget['widgetType'] === 'product'){
        console.log("IT IS A PRODUCT WIDGET")
        let foundChoice = false;
        list.forEach((choice, index) => {

          if (choice.id === $event.target.value) {
            foundChoice = true;
            console.log("IS EQUAL")
            this.productWidgetChange(choice, this.widget.id, ID)
          }
        })
        // If the value is set back to normal the addons price need to be recalculated
        if(!foundChoice){
          this.productInputChange.emit({option: null, widgetID: this.widget.id, ID:ID, widget: this.widget});
        }

        // console.log($event.target.value)
        // console.log(this.widget.element.label)
        // // If dropdown was revertd back to nothing
        // if($event.target.value == this.widget.element.label){
        // }
      }
      else{
        this.inputChange(ID);
      }
  }

  async validateProductRequirements(){
    if(this.widget.element.isDropdown){
      this.productRequirementMet = true;
    }
    else{
      this.productRequirementMet = await this.checkProductWidgetQuantityRequirements();
    }
  }

  checkProductWidgetQuantityRequirements(){
    let totalCount = 0;
    let requireMet: boolean
    this.widget.element.options.forEach(option => {
      totalCount += option?.inputValue;
    })

    if(totalCount && totalCount >= this.widget.element.min || totalCount == 0 && totalCount >= this.widget.element.min){
      requireMet = true;
    }
    else{
      requireMet = false;
    }
    return requireMet
  }

  inputWidgetChange(ID){
    this.widgetInputChange.emit(ID);
  }

} // End of class
