import { Component, Pipe } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { RentalsReport } from 'src/app/models/report-rentals.model';
import { CurrentUserService } from 'src/app/services/current-user.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { saveAs } from 'file-saver';
import dayjs, { Dayjs } from 'dayjs/esm';

interface Column {
  field: string;
  header: string;
  customExportHeader?: string;
  pipe?: Pipe;
}

interface ExportColumn {
  title: string;
  dataKey: string;
}
@Component({
  selector: 'app-tax-report',
  templateUrl: './tax-report.component.html',
  styleUrls: ['./tax-report.component.scss'],
})
export class TaxReportComponent {
  // Rentals
  public rentals!: RentalsReport[];
  public selectedRentals!: RentalsReport[];

  // User
  private user = null;

  // Dates Variables
  public selectedDates: any;
  public ranges: any = {
    'Quarter to Date': [moment().startOf('quarter'), moment().endOf('day')],
    'Last Quarter': [
      moment().subtract(1, 'quarter').startOf('quarter'),
      moment().subtract(1, 'quarter').endOf('quarter'),
    ],
  };
  public locale: LocaleConfig = {
    format: 'MM-DD-YYYY',
    displayFormat: 'MM-DD-YYYY',
    separator: ' - ',
    cancelLabel: 'Cancel',
    applyLabel: 'Okay',
  };
  public maxDate: Dayjs;
  public minDate: Dayjs;

  // Totals Variables
  public subTotal: number = 0;
  public taxesAndFees: number = 0;
  public taxes: number = 0;
  protected dataQuery
  // Utils
  public loading: boolean = false;

  constructor(
    private $afFun: AngularFireFunctions,
    private _currentUserS: CurrentUserService
  ) {
      this.selectedDates = {
      startDate: moment().subtract(1, 'quarter').startOf('quarter'),
      endDate: moment().subtract(1, 'quarter').endOf('quarter'),
    };
  }

  cols!: Column[];

  exportColumns!: ExportColumn[];

  ngOnInit(): void {
    this.initializeUserAndDates().then(() => {
      this.maxDate = dayjs()
    });
  }

  public async getReports(range: {startDate?: Dayjs, endDate?: Dayjs}): Promise<void> {
    const baseRequestFormat = '%Y-%m-%d';
    const format = 'YYYY-MM-DD HH:MM:ss'
    const start: Dayjs = range?.startDate || dayjs().startOf('year')
    const end: Dayjs = range?.endDate || dayjs().endOf('day')

    const data = {
      companyId: this.user.companyId,
      fleetTax: this._currentUserS.currentUser.currentCompany.fleetTax,
      dateStart: start
        ? start.format(format)
        : start.startOf('year').format(format),
      dateEnd: end
        ? end.format(format)
        : end.endOf('day').format(format),
      format: baseRequestFormat,
    };
    this.dataQuery = data;
    await Promise.all([this.getRentalsByTime(data)]);
  }


  public exportPdf() {
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then((x) => {
        const doc = new jsPDF.default('p', 'px', 'a4');
        (doc as any).autoTable(this.exportColumns, this.rentals);
        doc.save('products.pdf');
      });
    });
  }

  public formatValueForCurrency(value: number): string {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  }

  // This function starts a counter animation for updating total rental counts, total rentals, or total customers.
  private startCounterAnimation(
    startValue: number,
    newValue: number,
    option: number
  ): void {
    const increment = 1;
    const duration = 100;
    const steps = duration / increment;
    let step = 0;

    const interval = setInterval(() => {
      step++;
      const progress = step / steps;
      switch (option) {
        case 0:
          this.subTotal = Math.round(
            startValue + (newValue - startValue) * progress
          );
          if (step >= steps) {
            this.subTotal = newValue;
            clearInterval(interval); // Stops animation
          }
          break;
        case 1:
          this.taxesAndFees = Math.round(
            startValue + (newValue - startValue) * progress
          );
          if (step >= steps) {
            this.taxesAndFees = newValue;
            clearInterval(interval);
          }
          break;
        case 2:
          this.taxes = Math.round(
            startValue + (newValue - startValue) * progress
          );
          if (step >= steps) {
            this.taxes = newValue;
            clearInterval(interval);
          }
      }
    }, increment);
  }
  protected async generateRentalDetails() {
    Swal.fire({
      title: "Generating report",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      const res = await this.$afFun
        .httpsCallable('getRentalDetails')(this.dataQuery)
        .toPromise();

      if (!res) {
        throw new Error('Something went wrong!');
      }
      this.exportToCsv(`rental-details-from-${this.dataQuery.dateStart}-to-${this.dataQuery.dateEnd}.csv`, res);
      Swal.close();
    } catch (error) {
      Swal.close();
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }


  protected exportToCsv(filename: string, rows: object[]) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    const csvContent = keys.join(separator) + '\n' +
      rows.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date ? cell.toLocaleString() : cell;
          cell = cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, filename);
  }

  private async getRentalsByTime(data): Promise<void> {
    try {
      this.subTotal = 0;
      this.taxesAndFees = 0;
      this.taxes = 0;
      this.loading = true;
      const res = await this.$afFun
        .httpsCallable('getRentalsReport')(data)
        .toPromise();

      if (!res) {
        throw new Error('Something went wrong!');
      }

      this.rentals = res;

      let subTotal = 0;
      let taxesAndFees = 0;
      let taxes = 0;

      res.map((r) => {
        subTotal += r.subTotal;
        taxesAndFees += r.taxesAndFees;
        taxes += r.taxes;
        return r;
      });

      this.startCounterAnimation(this.subTotal, subTotal, 0);
      this.startCounterAnimation(this.taxesAndFees, taxesAndFees, 1);
      this.startCounterAnimation(this.taxes, taxes, 2);


      this.cols = [
        { field: 'rentalNumber', header: 'Rental Number' },
        { field: 'name', header: 'Name' },
        {
          field: 'reservation',
          header: 'Reservation',
        },
        { field: 'subTotal', header: 'Subtotal' },
        { field: 'taxesAndFees', header: 'Taxes And Fees' },
        { field: 'taxes', header: 'Taxes' },
      ];

      this.exportColumns = this.cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
      }));
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }

  // Initialize the user and quarters based on the user's data and current date
  private async initializeUserAndDates(): Promise<void> {
    this.user = this._currentUserS.currentUser;
  }

  // This function handles errors and displays an error message.
  private handleError(error) {
    this.showErrorMessage('Oops...', error.message || 'Something went wrong!');
    console.error(error);
  }

  // Show an error message using Swal (SweetAlert2) library
  private showErrorMessage(title: string, text: string): void {
    Swal.fire({
      icon: 'error',
      title: title,
      text: text,
    });
  }
}
