import { Timestamp } from "firebase/firestore";
import { CustomerInfo } from "src/app/models/customer-info.model";
import { CartItem } from "src/app/models/cart.model";
import { CustomCartSettings } from "src/app/models/cart.model";
import { TimeslotType } from 'src/app/models/availability-timeslot.model'


export interface Cart {
  additionalComments?: string;
  amountPending?: number;
  cartCheckedOut?: boolean;
  cartWidgetList?: any[]
  companyId?: string;
  customCartSettings?: CustomCartSettings;
  customerInfo?: CustomerInfo;
  dateCheckedOut?: null | Timestamp;
  dateCreated?: null | Date | Timestamp; // Should be Timestamp when interacting directly from db. Posting to db allows javascript dates
  id?: string;
  isCustomCart?: boolean;
  isSkipPayment?: boolean;
  isTestStripe?: boolean;
  items: CartItem[] | []; // always required (can be empty)
  paymentID?: string;
  promoApplied?: boolean;
  promocode?: string;
  rentalID?: any;
  statusDate?: any;
  timeslotType?: TimeslotType | null;
}

const defaultCart: Partial<Cart> = {
  additionalComments: '',
  cartCheckedOut: false,
  cartWidgetList: [],
  companyId: '',
  dateCheckedOut: null,
  dateCreated: null,
  id: '',
  items: [],
  promoApplied: false,
  promocode: '',
  isTestStripe: false,
  isSkipPayment: false,
  isCustomCart: false,
  customCartSettings: {
      isModificationsAllowed: true
  },
}

export function makeNewCart(cart?: Cart) {
  return { ...JSON.parse(JSON.stringify(defaultCart)), ...cart } as Cart
}
