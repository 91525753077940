<div>
    <!-- Warning unique to the Booking Suite component - deleting a page attached to a template -->
    <ng-container *ngIf="extras?.bookingSuite">
        <div class="alert alert-danger" role="alert" *ngIf="extras?.bookingSuite?.affectedTemplates">
            <h4>Warning!</h4>
            <span>Deleting this page will remove the page and it's nested content from the following templates:</span>
            <ul>
                <li *ngFor="let template of extras?.bookingSuite?.affectedTemplates">{{template.title}}</li>
            </ul>
        </div>
    </ng-container>

    <form (ngSubmit)="delete()" #deleteForm="ngForm">
        <span>This action <strong>cannot</strong> be undone. To permanently delete, type <span style="color: red">{{title}}</span> to confirm.</span>
        <input class="form-control" type="text" name="inputValue" id="" [(ngModel)]="inputValue" [placeholder]="title">
        <button type="submit" class="btn btn-danger mt-2" [disabled]="inputValue != title">Delete</button>
    </form>
</div>