import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import { User } from 'src/app/models/storage/user.model';
import { Company } from 'src/app/models/storage/company.model';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,

  ) {
  }

  createAccount(email: string, password: string): Promise<any> {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
  }

  updateUserInfo(userID: string, obj: any) {
    return this.afs.collection('users').doc(userID).update(obj);
  }

  addNewUser(user: any, id?: string) {
    console.log(user, id);
    return this.afs.collection('users').doc(id).set(user);

  }



  addNewCompany(company: Company) {
    //add new company to the database and return the id
    return this.afs.collection('companies').add(company).then(doc => {
      return doc.id;
    })
  }

  updateCompany(id,company: Company){
    return this.afs.collection('companies').doc(id).update(company);
  }



  updateUser(user: any, id: string) {
    return this.afs.collection('users').doc(id).update(user);
  }

  deactivateUser(userID: string): Promise<boolean> {

    return this.afs.collection('users').doc(userID).update({isActive: false, isBlocked: true}).then(() => {
      return true;
    })

  }

  reactivateUser(userID: string): Promise<boolean> {
    return this.afs.collection('users').doc(userID).update({isActive: true, isBlocked: false}).then(() => {
      return true;
    })
  }

  getUser(userID: string): Promise<User> {
    return this.afs.collection('users').doc(userID).ref.get().then(doc => {
      const data = doc.data() as User;
      data.id = doc.id
      return data;
    })
  }

  getStripeID(companyID: string) {
    return this.afs.collection('companies').doc(companyID).ref.get().then(doc => {
      const data = doc.data() as Company;
      data.id = doc.id
      return data;
    })
  }

}
