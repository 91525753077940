<div class="container" *ngIf="history.length === 0 && loading">
    <app-spinner></app-spinner>
</div>
<div class="container container80" *ngIf="history.length > 0">
    <!-- Mostrar los detalles del alquiler aquí -->

    <table class="table  table-bordered ">
        <thead style="background-color:#114463; color:white;">
            <tr>
                <th>Type</th>
                <th>Date</th>
                <th>Description</th>
                <th>Check In Photos</th>
                <th>Check Out Photos</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let r of history; let i = index">
                <td>{{r.recordType | uppercase}}</td>
                <td>{{r.recordDate | date: 'MMM dd, YYYY hh:mm a' }}</td>
                <td >{{r.description}}</td>
                <td class="photoHolder">
                    <ng-container *ngIf="r.rentalCheckInPhotos">
                        <ng-container *ngIf="r.rentalCheckInPhotos.length > 0">
                        <div class="container">
                            <div class="styleImgThumb justify-content-center align-items-center" *ngFor="let p of r.rentalCheckInPhotos" >
                                <img class="checkPhotos" src="{{p.url}}" (click)="openModalImg(p.url)" alt="Card image cap">
                            </div>
                        </div>

                    </ng-container>
                </ng-container>
                </td>
                <td class="photoHolder">
                    <ng-container *ngIf="r.rentalCheckOutPhotos">
                        <ng-container *ngIf="r.rentalCheckOutPhotos.length > 0">
                        <div class="container">
                            <div  class="styleImgThumb justify-content-center align-items-center" *ngFor="let p of r.rentalCheckOutPhotos">
                                <img class="checkPhotos" src="{{p.url}}" (click)="openModalImg(p.url)" alt="Card image cap">
                            </div>

                        </div>

                    </ng-container>
                </ng-container>
                </td>
            </tr>
        </tbody>
    </table>

</div>

<div class="container" *ngIf='history.length == 0 && !loading'>
    <!-- No history to show with as 404 error-->
    <app-not-found></app-not-found>
</div>
