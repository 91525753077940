import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { last, lastValueFrom, Observable, Subscription, take } from 'rxjs';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';

/* Services */
import { CartService } from 'src/app/services/cart.service';
import { TimeService } from 'src/app/services/time.service';
import { AvailabilityService } from 'src/app/services/availability.service';
import { WidgetService } from 'src/app/services/widget.service';
import { PricingService } from 'src/app/services/pricing.service';
import { InventoryPageService } from 'src/app/services/inventory-page.service';
import { CookieInteractionService } from 'src/app/v2/services/cookie-interaction.service';
import { RentalService } from 'src/app/services/rental.service';
import { PaymentsService } from 'src/app/services/payments.service';
import { ReservationService } from 'src/app/v2/services/reservation.service';

/* Models */
import { WidgetInterface, WidgetType } from 'src/app/models/widget.model';

/* Libraries */
import { AvailabilityInterface } from 'src/app/models/availability.model';
import { Cart } from 'src/app/models/storage/cart.model';
import { CartItem } from 'src/app/models/cart.model';
import { DateTime } from 'luxon';
import { Rental } from 'src/app/models/storage/rental.model';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-cart2',
  templateUrl: './cart2.component.html',
  styleUrls: ['./cart2.component.scss']
})
export class Cart2Component {


  @Input() cartQuantities;
  @Input() templateID: string;

  // Necessary for Availability Query
  @Input() productsArray: Array<any> // array of products returned from a firebase collection
  @Input() locationsArray: Array<any> // array of all locations for a given company
  @Input() productGroupsCollection: Array<any>
  @Input() companyID: string

  // Cart Specific
  @Input() cartObj; // Also used in availability query
  @Input() productGroupMap;
  @Input() productMap;
  @Input() widgetMap;
  @Input() widgetArray;
  @Input() inventoryPageMap;
  @Input() discountCodeMap;
  @Input() companyObj;
  @Input() filteredLocations;
  @Input() locationMap;
  @Input() productSizeTypeMap;
  @Input() productSizesMap;
  @Input() companyName: string;
  @Input() cartWidgetList;
  @Input() pmtLinkRentalDoc: Rental | null = null;
  @Input() isReserved: boolean = false;


  @Output() private updateAvailabilityFromCart = new EventEmitter<AvailabilityInterface>();
  @Output() cartToCustomerInfo = new EventEmitter<any>();
  @Output() cartItemEdit = new EventEmitter<any>();
  @Output() getCollectionUpdates = new EventEmitter<any>();
  @Output() updateCart = new EventEmitter<any>();

  protected isLoading: boolean = true;
  protected isReservationPayment: boolean = false;
  protected discountCodeInput: string = "";
  protected updatingCart: boolean = false;
  protected hasOutdatedItems: boolean = false;
  protected hasUnavailableItems: boolean = false;

  private subs = new Subscription(); // group of subscriptions

  protected cost: number;
  protected pendingCost: number;
  protected taxes: number;
  protected promoCodeDiscountTotal: number;

  private timer;
  protected deleteHasResponded: boolean = true;

  protected showInvalidPromoCodeMsg: boolean = false;

  protected availabilityDoneProcessing: boolean;
  protected groupInventoryPage = {};
  protected cartWidgetsLoading: boolean = false;
  protected total: number = 0;

  protected cartWidgetsValid: boolean = true;
  protected flagUserItems: Array<any> = [];
  protected advenireWidgetValid: boolean = true;
  private availability: AvailabilityInterface;
  protected checkout: boolean = false;

  @ViewChild('cartForm') cartForm: NgForm // Form

  constructor(private cartService: CartService,
    private timeService: TimeService,
    private pricingService: PricingService,
    private availabilityService: AvailabilityService,
    private widgetService: WidgetService,
    private inventoryPageService: InventoryPageService,
    private cookieInteractionService: CookieInteractionService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private rentalService: RentalService,
    private _paymentsService: PaymentsService,
    private _reservationService: ReservationService) { };

  async ngAfterViewInit() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    Swal.close();
    if (this.cartObj.items.length > 0) {
      this.checkIfItemsStillBad();
    }
    else {
      this.isLoading = false; // loading is done because cart is empty
      this.cdr.detectChanges();
    }
  }


  async ngOnChanges(changes: SimpleChanges) {

    if (this.checkout) { // checkOutProcessing() is called form wrapper when all collection promises are complete
      return
    }

    if (this.cartObj.items && this.cartObj.items.length <= 0) {
      this.clearCode(); // no items exist in cart -> clear code
      return
    }
    else {
      this.setWidgetProductAndAddonTotalCountOnCartItems();
    }

    if (changes.cartObj && this.pmtLinkRentalDoc) { // If there has been changes in the cart object we can do re
        this.initCartPaymentLink();
    }

    if (this.productGroupMap && this.productMap && this.widgetMap && this.widgetArray && this.inventoryPageMap && this.discountCodeMap && this.locationMap &&
      this.productSizeTypeMap && this.productSizesMap && this.cartObj) {
        this.initOperations();
      }

  }

  private async checkIfItemsStillBad(): Promise<void> {
    let foundOutdated = false;
    let foundUnavailable = false;

    await this.cartObj.items.forEach((item) => {
      if (item['unavailable']) {
        foundUnavailable = true;
      }

      if (item['cartItemOutdated']) {
        foundOutdated = true;
      }
    });

    if (foundOutdated) {
      this.hasOutdatedItems = true;
    }

    if (foundUnavailable) {
      this.hasUnavailableItems = true;
    }
  }

  private async initOperations(): Promise<void> {

    await this.getCartWidgetListData();
    this.validifyThatDiscountCodeIsStillValid();
    await this.runAvailabilityAlgo();
    this.getCartTotalPrice();
    await this.availabilityChecks();
    await this.compareAgainstDateModifiedTimestamps();
    this.isLoading = false;
    this.cdr.detectChanges();
    return
  }

  private async initCartPaymentLink(): Promise<void> {
    this.groupInventoryPage['widgetList'] = this.cartObj['cartWidgetList'];
    this.isLoading = false;
    this.availabilityDoneProcessing = true;
    this.cartObj.paymentID = this.pmtLinkRentalDoc.paymentID;
    this.cartObj.amountPending = this.pmtLinkRentalDoc.amountPending;
    this.getCartTotalPrice();
    this.cdr.detectChanges();
    this.isLoading = false;
    this.availabilityDoneProcessing = true;
    return
  }

  protected async clearCode(): Promise<void> {
    if (!this.cartService.hasCustomCartPermission(this.cartObj, Boolean(this.pmtLinkRentalDoc))) return; // If user doesn't have permission to modify cart - return
      this.updatingCart = true;
      this.cartObj.promoApplied = false;
      this.cartObj.promocode = "";
      this.promoCodeDiscountTotal = 0;
      this.debounceTimer();
      await this.getCartTotalPrice();
      this.updatingCart = false
  }


  protected debounceTimer(): void {
    if (this.timer != null) { // Reset timer to max length if timer is currently still running
      clearInterval(this.timer)
    }


    this.timer = setTimeout(() => {
      this.updateCart.emit(this.cartObj);
      if(!this.pmtLinkRentalDoc) {
        this.cartService.updateCart(this.cartObj.id, { items: this.cartObj.items, additionalComments: this.cartObj.additionalComments, promoApplied: this.cartObj.promoApplied, promocode: this.cartObj.promocode, cartWidgetList: this.cartObj.cartWidgetList })
      }
    }, 1000 * 2)

  }

  private setWidgetProductAndAddonTotalCountOnCartItems(): void {
    this.cartObj.items.forEach((item) => {
        // Initialize totals for each item
        item.addonTotalwoProduct = item.addonPrice;
        item.totalCountAddons = 0;
        item.totalCountProducts = 0;
        item.addonTotalWProduct = 0;

        const widgetProducts = item.widgetList.filter(w => w.widgetType === WidgetType.product);

        item.widgetList.forEach((widget, indexWidget) => {
            switch (widget.widgetType) {
                case WidgetType.dropdown:
                case WidgetType.radios:
                    const selectedAddon = widget.element.options.find(o => o.is_selected);
                    if (selectedAddon) {
                        item.widgetList[indexWidget].selectedAddon = selectedAddon;
                    }
                    break;

                case WidgetType.product:
                    if (!widget.element.isDropdown) {
                        widget.element.options.forEach(product => {
                            if (product.inputValue > 0) {
                                item.addonTotalWProduct += (widget.element.price * product.inputValue);
                                item.totalCountProducts += product.inputValue;
                                item.addonTotalwoProduct -= (widget.element.price * product.inputValue);
                            }
                        });
                    } else {
                        const selectedAddon = widget.element.options.find(o => o.is_selected);
                        if (selectedAddon) {
                            item.widgetList[indexWidget].selectedAddon = selectedAddon;
                            item.addonTotalWProduct += selectedAddon.price;
                            item.totalCountProducts += 1;
                            item.addonTotalwoProduct -= selectedAddon.price;
                        }
                    }
                    break;

                default:
                  item.totalCountAddons += 1;
                  break;
            }
        });

        // Combine widget products
        const widgetDropProducts = widgetProducts.filter(product => product.selectedAddon !== undefined);
        const widgetQtyProducts = widgetProducts.filter(product => !product.element.isDropdown);
        item.widgetProduct = [...widgetDropProducts, ...widgetQtyProducts];
    });
  }

  private async getCartWidgetListData(): Promise<void> {
    this.groupInventoryPage['widgetList'] = this.cartObj['cartWidgetList'];
    // Make sure cart has all cart widget needed // Mainly used for development, to correct errors
    if (this.cartObj.items.length > 0 && this.cartObj['cartWidgetList'] && this.cartObj['cartWidgetList'].length == 0) {
      this.groupInventoryPage['widgetList'] = await this.inventoryPageService.getCartWidgetListData(this.cartObj['items'], [], this.inventoryPageMap, this.productGroupMap, this.cartObj.companyId);

      // If there are new widgets then make sure to get the correct data
      if (this.groupInventoryPage['widgetList'].length > 0) {
        // Get the saved widget info and product info
        this.groupInventoryPage = await this.widgetService.getSavedWidgetsInfo(this.groupInventoryPage, this.widgetArray);
        this.groupInventoryPage = await this.widgetService.getProductWidgetData(this.groupInventoryPage, this.productGroupMap);
        this.groupInventoryPage = await this.widgetService.getProductOptionSizes(this.groupInventoryPage, this.productSizesMap, this.productSizeTypeMap, this.companyID, true); // Dont update databse otherwise it will make the item seem "outdated" when it is not

        await this.groupInventoryPage['widgetList'].forEach(async widget => {
          if (widget['widgetType'] == WidgetType.product) {
            widget['element']['price'] = await this.widgetService.calculateProductWidgetPrice(widget['element'], this.cartObj['items'][0]['daySpan'], this.cartObj['items'][0]['selectedHours'], this.cartObj['items'][0]['is24Hrs']);
          }
        })
      }
    }
    return
  }


  private async compareAgainstDateModifiedTimestamps(): Promise<void> {
    if (this.pmtLinkRentalDoc) { // Already a rental so it should not be compared against recent changes
      return
    }

      let itemsOutdated = false;
      const promises = this.cartObj?.items.map(async (cartItem) => {
          cartItem['cartItemOutdated'] = false;

          if (this.timeService.convertTimestampToLuxon(cartItem.dayStart) < DateTime.now()) {
              cartItem['cartItemOutdated'] = true;
              this.hasOutdatedItems = true;
              itemsOutdated = true;
          }

          try {
              const dateAdded = new Date(cartItem.dateAdded.seconds * 1000);

              const validators = [
                  this.validateProductGroup(cartItem.parentId, cartItem, dateAdded),
                  this.validateProductSizeType(this.productMap[cartItem.productId]?.productSizeTypeID, cartItem, dateAdded),
                  this.validateInventoryPage(cartItem, dateAdded),
                  this.validateSavedWidgetsAndProductWidgets(cartItem, dateAdded)
              ];


              // Execute all validators and track if any return a truthy value
              const results = await Promise.all(validators);
              if (results.some(result => result)) {
                  itemsOutdated = true;
              }

          } catch (error) {
              console.error("Error occurred while processing cart item:", error);
          }
      });

      // Wait for all promises to resolve
      await Promise.all(promises);

      this.hasOutdatedItems = itemsOutdated;
  }


  private validateProductSizeType(productSizeType, cartItem, dateAdded): boolean {
    /* Check productSizeType lastModified (no need to check size collection) */
    if (productSizeType && this.productSizeTypeMap[productSizeType]?.lastModified) { // if no lastModified variable found - then size was established before variable added. Therefore - most likely older than cart item
      const lastModified = new Date(this.productSizeTypeMap[productSizeType].lastModified.seconds * 1000);

      // If the product group was updated since adding this item to the cart - mark as item having updates -> remove from cart
      if (lastModified > dateAdded) {
        // mark item as being incorrect / old - needs to be removed from cart in order to continue checkout
        cartItem.cartItemOutdated = true;
        return true;
      }
    }

    return false;
  }

  private validateProductGroup(productGroupID, cartItem, dateAdded): boolean {
    /* Check Product Groups */
    if (this.productGroupMap[productGroupID]?.lastModified) {
      // Carts are assumed to always have an addedDate
      const lastModified = new Date(this.productGroupMap[productGroupID].lastModified.seconds * 1000);

      // If the product group was updated since adding this item to the cart - mark as item having updates -> remove from cart
      if (lastModified > dateAdded) {
        // mark item as being incorrect / old - needs to be removed from cart in order to continue checkout
        cartItem.cartItemOutdated = true;
        return true;
      }
    }

    return false;
  }

  private validateInventoryPage(cartItem, dateAdded): boolean {
    const inventoryPageId = this.productGroupMap[cartItem.parentId]?.inventoryPageId;
    const inventoryPage = this.inventoryPageMap[inventoryPageId];
    if (inventoryPage?.lastModified) {
      const lastModified = new Date(inventoryPage.lastModified.seconds * 1000)
      if (lastModified > dateAdded) {
        cartItem.cartItemOutdated = true;
        return true;
      }
    }

    return false;
  }

  private validateSavedWidgetsAndProductWidgets(cartItem, dateAdded): boolean {
    let itemsOutdated = false;

    cartItem.widgetList.forEach((widget) => {
      if (widget.savedWidgetId !== "") { // if saved widget
        const lastModified = new Date(this.widgetMap[widget.savedWidgetId]?.lastModified.seconds * 1000)

        // look in savedWidget collection to see if an item has been updated
        if (lastModified > dateAdded) {
          cartItem.cartItemOutdated = true;
          itemsOutdated = true;
        }
      }

      // validate product widget
      if (widget.widgetType === WidgetType.product) {
        const groupId = widget.element.groupId;
        if (this.validateProductGroup(groupId, cartItem, dateAdded)) {
          itemsOutdated = true;
        }
        if (this.productGroupMap[groupId]?.productSizeTypeID && this.validateProductSizeType(this.productGroupMap[groupId].productSizeTypeID, cartItem, dateAdded)) {
          itemsOutdated = true;
        }
      }
    });

    return itemsOutdated;
  }


  protected async clearCart(): Promise<void> {
    // this.cartObj
    const prompt = await Swal.fire({
      title: $localize`Are you sure you want to remove all the items in your cart?`,
      text: $localize`All items and attached options will be removed from your cart.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: $localize`Yes, remove it`,
      cancelButtonText: $localize`Cancel`,
      reverseButtons: true,
      didClose: async () => {
        if (!prompt.isConfirmed) {
          return
        }

        try {
          const cartID = localStorage.getItem(`FM-cart-uuid-${this.templateID}`);
          this.cartObj.items = []; // Empty all content from the carts item arr
          // Update the cart widgets
          this.cartObj.cartWidgetList = await this.processUpdateToCartWidgets();
          // Cant use cartObj.id because it can be the id of the cart used to check out the rental (if visiting via payment link)... not necessarily cartID in localstorage
          this.cartService.updateCart(cartID, { items: this.cartObj.items, additionalComments: this.cartObj.additionalComments, promoApplied: this.cartObj.promoApplied, promocode: this.cartObj.promocode, cartWidgetList: this.cartObj.cartWidgetList });
          this.updateCart.emit(this.cartObj);

        }
        catch (err) {
          localStorage.removeItem(`FM-cart-uuid-${this.templateID}`);
        }

        // Check for Rental Cookie, if so, remove it
        const cookieName = this.cookieInteractionService.getPaymentLinkCookie(this.templateID);
        if (this.cookieInteractionService.getCookie(cookieName)) {
          this.cookieInteractionService.removeCookie(cookieName);

          const parts = this.router.url.split('/cart')[0].split('/');
          const bookId = parts[2]; // Assuming the bookId is always in this position
          window.location.href = `/book/${bookId}`; // Reloads to re-trigger onInit & to prevent dealing with confusing router structure in place within these components
        }
        else {
          await this.runAvailabilityAndCheckAgainstIt();
        }

        this.pmtLinkRentalDoc = null;
        this.deleteHasResponded = true;
        this.cartWidgetsLoading = false;
      }
    })
  }

  protected removeFromCart(prod, index) {
    if (!this.cartService.hasCustomCartPermission(this.cartObj, Boolean(this.pmtLinkRentalDoc))) return; // If user doesn't have permission to modify cart - return

    /* Prompt */
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary ml-2',
        cancelButton: 'btn btn-secondary',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: $localize`Are you sure you want to remove this item from your cart?`,
        text: $localize`All options attached to this product will also be removed.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: $localize`Yes, remove it`,
        cancelButtonText: $localize`Cancel`,
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          Swal.close();

          this.deleteHasResponded = false;

          // using cartID rather than index - safer
          this.cartObj.items.forEach((cartItem, index) => {
            if (cartItem.cartItemID === prod.cartItemID) {
              this.cartObj.items.splice(index, 1);
            }
          })

          // Update the cart widgets
          this.cartObj.cartWidgetList = await this.processUpdateToCartWidgets();

          // Update db
          const cartID = localStorage.getItem(`FM-cart-uuid-${this.templateID}`);
          await this.cartService.updateCart(cartID, { items: this.cartObj.items, additionalComments: this.cartObj.additionalComments, promoApplied: this.cartObj.promoApplied, promocode: this.cartObj.promocode, cartWidgetList: this.cartObj.cartWidgetList });
          this.updateCart.emit(this.cartObj);

          this.getCartTotalPrice();
          await this.runAvailabilityAndCheckAgainstIt();

          this.hasOutdatedItems = false;
          this.hasUnavailableItems = false;
          this.deleteHasResponded = true;
          this.cartWidgetsLoading = false;
          this.checkIfItemsStillBad();
        }
      });
  }

  private async runAvailabilityAndCheckAgainstIt(): Promise<void>{

    if(this.cartObj.items.length <= 0){
      this.updateAvailabilityFromCart.emit();
    }
    else { // has items in cart
      await this.runAvailabilityAlgo();
      await this.availabilityChecks();
      this.updateAvailabilityFromCart.emit(this.availability);
    }
  }

  private async processUpdateToCartWidgets(): Promise<WidgetInterface[]> {
    this.cartWidgetsLoading = true;
    let newWidgetList = [];
    newWidgetList = await this.inventoryPageService.getCartWidgetListData(this.cartObj['items'], [], this.inventoryPageMap, this.productGroupMap, this.cartObj.companyId);
    let newWidget = this.inventoryPageService.removeCartWidgetListData(newWidgetList, this.groupInventoryPage['widgetList']);
    this.groupInventoryPage['widgetList'] = newWidget;
    return newWidget;
  }

  /**
   * Check if discount code is valid
   */
  protected async validifyDiscountCode(onBtnClick?): Promise<void> {

    if (onBtnClick) {
      if (!this.cartService.hasCustomCartPermission(this.cartObj, Boolean(this.pmtLinkRentalDoc))) return; // If user doesn't have permission to modify cart - return
    }

    // If code found in map -> it's active and can be applied
    if (this.discountCodeMap[this.cartObj.promocode.toLowerCase()]) {
      // Set promo code info in cart
      this.updatingCart = true;
      this.cartObj.promoApplied = true; // originally false
      this.cartObj.promocode = this.discountCodeMap[this.cartObj.promocode.toLowerCase()].discountTitle; // originally null

      // Swal code applied toaster
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        customClass: {
          popup: 'colored-toast'
        },
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: 'success',
        title: $localize`Promo Code Applied!`,
      })
      // Update cart with new price
      this.cartObj.promoApplied = true;
      // this.debounceTimer();
      await this.getCartTotalPrice();
      this.updatingCart = false;
    }
    else {
      this.showInvalidPromoCodeMsg = true;
    }
  }

  private validifyThatDiscountCodeIsStillValid(): void {
    if (this.cartObj.promoApplied) {
      if (this.discountCodeMap[this.cartObj.promocode.toLowerCase()] === undefined) {
        this.cartObj.promoApplied = false
        this.cartObj.promocode = null;
        this.cartService.updateCart(this.cartObj.id, { promoApplied: this.cartObj.promoApplied, promocode: this.cartObj.promocode })
        this.updateCart.emit(this.cartObj);
      }
    }
  }

  // Calls pricing service to calculate total
  private async getCartTotalPrice(): Promise<void> {
    this.isReservationPayment = false;
    let discountObj = null;
    this.total = null;

    if (this.cartObj.promoApplied) { // If promo code found on cart -> get that codes object information
      discountObj = this.discountCodeMap[this.cartObj.promocode.toLowerCase()];
    }

    let {
      subTotal,
      promoCodeDiscountTotal,
      cartRentalTax,
      cartRentalFees,
      cartRentalPrice,
      widgetsSubTotal,
      widgetsTaxes,
      widgetsFees,
      cartWidgetPrice
    } = await this.pricingService.calculateCartTotal(this.cartObj, this.productGroupMap, discountObj)
    //Subtotal contains the total of the cart rental and the widgets, but we need it separated since we need to calculate widget taxes and fees separately
    //Some widgets are not taxable, so we need to separate them
    this.total = cartRentalPrice + widgetsSubTotal
    this.promoCodeDiscountTotal = promoCodeDiscountTotal; // Promo Code Discount Total
    let subTotalWithDiscounts = cartRentalPrice + widgetsSubTotal - promoCodeDiscountTotal
    this.taxes = widgetsTaxes + widgetsFees + cartRentalFees + cartRentalTax// Tax Total
    this.cost = subTotalWithDiscounts + this.taxes;
    const {
      rentalHasReservation,
      reservationPaid,
      reservationTotalToPay,
      amountPending
    } = await this._reservationService.getIfCartIsReservedAndAmountToPay(this.cartObj, this.productGroupMap, this.discountCodeMap);
    if (this.pmtLinkRentalDoc) {
      await this.handleWithPaymentLink(rentalHasReservation, reservationPaid, reservationTotalToPay);
    } else {
      await this.handleWithoutPaymentLink(rentalHasReservation, reservationTotalToPay, amountPending, subTotalWithDiscounts);
    }
    this.updatingCart = false;
    this.cdr.detectChanges();
  }
  async handleWithPaymentLink(rentalHasReservation: boolean, reservationPaid: boolean, reservationTotalToPay: number) {
    if (rentalHasReservation) { // If the rental has a reservation
      if (reservationPaid) { // If the reservation is paid
        this.pendingCost = (await this.pricingService.addTaxAndFeesToAmount(this.cartObj, this.pmtLinkRentalDoc.amountPending)).finalAmount;
        return;
      }
      this.isReservationPayment = rentalHasReservation;
      this.pendingCost = reservationTotalToPay;
      return;
    }
    this.pendingCost = (await this.pricingService.addTaxAndFeesToAmount(this.cartObj, this.pmtLinkRentalDoc.amountPending)).finalAmount;
  }

  async handleWithoutPaymentLink(rentalHasReservation: boolean, reservationTotalToPay: number, amountPending: number, subTotalWithDiscounts: number) {
    if (rentalHasReservation) {
      this.isReservationPayment = rentalHasReservation;
      this.pendingCost = reservationTotalToPay;
      this.cartObj.amountPending = amountPending;
      return;
    }
    this.pendingCost = subTotalWithDiscounts + this.taxes;
  }
  private async runAvailabilityAlgo(): Promise<void> {
    // reset values
    this.availabilityDoneProcessing = false;

    this.availability = await this.availabilityService.runAvailabilityAlgorithm(
      this.timeService.convertTimestampToLuxon(this.cartObj.items[0].dayStart).setZone(this.cartObj.items[0].locationTimeZone), // timezone needed here - otherwise, items break in diff timezones in cart
      this.timeService.convertTimestampToLuxon(this.cartObj.items[0].dayEnd).setZone(this.cartObj.items[0].locationTimeZone), // timezone needed here - otherwise, items break in diff timezones in cart
      this.cartObj.items[0].locationID,
      this.companyID,
      this.locationsArray,
      this.productsArray,
      this.productGroupMap,
      this.inventoryPageMap,
      this.widgetMap,
      this.productMap,
      this.cartObj,
      )

    return
  }

  private async availabilityChecks(): Promise<void> {
    if (this.pmtLinkRentalDoc) { // If a rental is already created, no need to check availabilities
      this.availabilityDoneProcessing = true;
      return
    }

    await this.checkCartItemsAvailabilities();
    this.groupInventoryPage['widgetList'] = await this.inventoryPageService.getCartProductWidgetMaxesParsing(this.groupInventoryPage['widgetList'], this.availability);
    this.availabilityDoneProcessing = true;
    return
  }

  private checkCartItemsAvailabilities(): void {
    this.hasUnavailableItems = false;
    const currentCartItemIDs: string[] = this.cartObj.items.map(item => item?.productId);
    // Loop through each item in the cart
    for (let item of this.cartObj.items) {
      if (item.productId) {
        if (!this.checkProductIDAvailability(item['productId'], item['parentId'])) {
          this.findIDForCartItem(item, currentCartItemIDs);
        }
      }
      else {
        this.findIDForCartItem(item, currentCartItemIDs);
      }
    }
    return
  }

  // ID swapping
  private findIDForCartItem(item: CartItem, currentCartItemIDs: string[]): void {
    let availIDs: string[] = [];
    try {
      availIDs = this.availability.getGroupSizeIDsAvailable(item['parentId'], item['productSizeID']);
    }
    catch (error) {
      throw new Error(`Error occurred while checking availability for product group ID ${item['parentId']} and product size ID ${item['productSizeID']}: ${error}`);
    }

    let found = false;
    if (availIDs.length < 1) {
      this.markItemUnavailable(item);
    }
    else {
      // Loop through each available ID
      availIDs.forEach((ID) => {
        if (!found) { // Dont continue loop if an ID has been found
          if (!currentCartItemIDs.includes(ID)) {
            item.productId = ID;
            delete item.unavailable
            currentCartItemIDs.push(ID);
            found = true;
            return
          }
        }
      })
      // If no available ID was found, mark the item as unavailable
      if (!found) {
        this.markItemUnavailable(item);
      }
    }
  }

  private markItemUnavailable(item: any): void {
    item['unavailable'] = true;
    this.hasUnavailableItems = true;
  }

  private checkProductIDAvailability(productID: string, productGroupID: string): boolean {
    try {
      const isAvailable = this.availability.checkProductAvailability(productGroupID, productID);
      return isAvailable;
    } catch (error) {
      console.error(`Error occurred while checking availability for product ID ${productID} in product group ID ${productGroupID}:`, error);
      return false;
    }
  }

  private async checkProductWidgetAvailability(cartObj: Cart) {
    // Check if there are enough IDs
    let itemsAvailable = true;
    await cartObj.items.forEach((item) => {
      if(!item.unavailable && !item.cartItemOutdated){
        item.widgetList.forEach((widget, ind) => {
          if (widget.widgetType === WidgetType.product) {
            let element = widget.element;
            element.options.forEach(async (option) => {

              if (option.inputValue > 0) {
                let isAvailableResult = await this.inventoryPageService.checkAlgoProductQuantitiesParsing(widget['element']['groupId'], option['sizeID'], this.availability);
                if (!isAvailableResult.passCheck) {
                  item['unavailableWidget'] = true;
                  item.widgetList[ind]['unavailable'] = true;
                  itemsAvailable = false;
                  this.flagUserItems.push('- ' + widget['element']['name'] + ', ' + option['size'] + ' x' + Math.abs(isAvailableResult.amountUnavail));
                }
              }
            })
          }
        })
      }
    })

    return itemsAvailable;
  }


  protected async attemptToCheckout(): Promise<void> {
    this.updatingCart = true;
    this.debounceTimer(); // Guarentees the databse cart is fully updated wiht local cart

    if (this.pmtLinkRentalDoc) {
      this.cartToCustomerInfo.emit();
      this.updatingCart = false;
      return;
    }

    this.checkout = true;
    this.getCollectionUpdates.emit();

    // verify that item is above stripe minimum
    if (this.cost < 0.50) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-primary ml-2',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons.fire({
        icon: 'error',
        title: $localize`Oh no! | The Minimum amount to checkout was not met`,
        text: $localize`Fleetmaid doesn't allow processing for totals lower than $0.50. To continue with checkout, please fill the cart to at least $0.50 and try again.`,
      })
      return
    }
  }

  public async checkOutProcessing(): Promise<void> {

    if (this.pmtLinkRentalDoc) { // Dont do any cart processing becuas this is an already created rental
      this.cartToCustomerInfo.emit();
      this.updatingCart = false;
      this.isLoading = false;
      return;
    }

    // Get collection updates
    await this.compareAgainstDateModifiedTimestamps();
    this.validifyThatDiscountCodeIsStillValid();

    if (this.hasOutdatedItems) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-primary ml-2',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons.fire({
        icon: 'error',
        title: $localize`Outdated items were found in the cart`,
        text: $localize`Please remove them to continue with check out`,
      })
      return
    }

    // verify that items are still available for the selected dates
    await this.runAvailabilityAlgo();
    await this.availabilityChecks();
    this.cartWidgetsValid = await this.widgetService.requiredWidgetsCheck(this.groupInventoryPage['widgetList']);
    this.advenireWidgetValid = this.inventoryPageService.validateAdvenireWidgetInCart(this.cartObj.items[0].dayStart, this.cartObj.items[0].locationTimeZone, this.groupInventoryPage['widgetList'])

    if (this.cartWidgetsValid) { // If cart widgets arent valid then save the proscessing
      await this.checkWidgetAvailabilityForCheckout();
    }

    // if called via checkoutBtn click and this.hasUnavailableItems == false then emit checkout if item is still available
    if (!this.hasUnavailableItems && !this.hasOutdatedItems && this.cartWidgetsValid && this.flagUserItems.length === 0 && this.advenireWidgetValid) {
      // checkout
      this.cartObj.items = this.cartObj.items.map(item => {
        if (item.productId === undefined) {
          // If item doesnt have productId, we set unavailable true
          return { ...item, unavailable: true };
        } else {
          // If item have productId, delete unavailable
          const { unavailable, ...itemWithoutUnavailable } = item;
          return itemWithoutUnavailable;
        }
      });

      if (!this.cartObj.items.some(item => item.unavailable || item.cartItemOutdated)) {
        // if all products have productId, we update the cart and emit the cartCustomerInfo
        this.cartService.updateCart(this.cartObj.id, this.cartObj);
        this.updateCart.emit(this.cartObj);
        this.cartToCustomerInfo.emit();
      }
    }
    this.updatingCart = false;
    this.isLoading = false;
  }



  private async checkWidgetAvailabilityForCheckout(): Promise<void> {
    let productWidgetsAvailable = await this.checkProductWidgetAvailability(this.cartObj);

    if (productWidgetsAvailable) {
      // Get all Ids currently being used except for product widget Ids because we are going to re-assign all product widget IDs to avoid ID swapping
      let IDsUsed = [];
      IDsUsed = this.inventoryPageService.getAllCurrentRentalIDs(this.cartObj, true, false, true);

      // Assign Ids to product widgets
      await this.cartObj.items.forEach(async (item, ind) => {
        let result = await this.widgetService.assignProductWidgetIDsParsing(item['widgetList'], this.availability, IDsUsed);
        this.cartObj.items[ind]['widgetList'] = result.widgetList;
        IDsUsed = result.IDsUsed;
      })

      // Assign Id's to the cart widgets if there are product widgets
      this.groupInventoryPage['widgetList'] = await this.widgetService.assignProductWidgetIDsParsing(this.groupInventoryPage['widgetList'], this.availability, IDsUsed).widgetList;
      this.cartObj['cartWidgetList'] = this.groupInventoryPage['widgetList'];

    }
  }


  protected async editCartItem(ind: number): Promise<void> {
    if (!this.cartService.hasCustomCartPermission(this.cartObj, Boolean(this.pmtLinkRentalDoc))) return; // If user doesn't have permission to modify cart - return

    let dayStart = this.timeService.convertTimestampToLuxon(this.cartObj.items[ind].dayStart).setZone(this.locationMap[this.cartObj.items[0]?.locationID]?.timezone);
    let dayEnd = this.timeService.convertTimestampToLuxon(this.cartObj.items[ind].dayEnd).setZone(this.locationMap[this.cartObj.items[0]?.locationID]?.timezone);
    this.cartItemEdit.emit({
      productGroupID: this.cartObj.items[ind].parentId, location: this.cartObj.items[ind].locationID, startDate: dayStart,
      endDate: dayEnd, cartItemID: this.cartObj.items[ind].cartItemID
    })
  }

  protected async onCartWidgetInputChange(e, productInputChange): Promise<void> {
    this.updatingCart = true;
    this.debounceTimer();
    await this.getCartTotalPrice();

    if (productInputChange) {
      await this.runAvailabilityAlgo();
      await this.availabilityChecks();
    }
    this.updatingCart = false;


  }

  protected customCartBtn(): void {
    this.cartService.customCartBtn(this.cartObj);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe(); // unsubscribe from all subscriptions in this group
  }


} // End of class
