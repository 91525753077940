import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {updateDoc, serverTimestamp} from "firebase/firestore";
import * as firebase from '@firebase/app';
import {tap, map, switchMap, first} from 'rxjs/operators';
// import {TouchSequence} from 'selenium-webdriver';
import {of} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthService {


  userLoggedIn: boolean;

  // other navigation can check on this variable for the login status of the user

  constructor(private router: Router, private afAuth: AngularFireAuth, private afs: AngularFirestore,
              private db: AngularFireDatabase) {


    this.afAuth.onAuthStateChanged((user) => {
      if (user) {
        this.userLoggedIn = true;
      } else {
        this.userLoggedIn = false;
      }
    });
  }


  login(email: string, password: string) {
    try {
      return new Promise((resolve, reject) => {
        this.afAuth.signInWithEmailAndPassword(email, password)
          .then(userData => resolve(userData), err => reject(err))
      });
    } catch (error: any) {
      return error;
    }

  }

  getAuth() {
    return this.afAuth.authState.pipe(map(auth => auth));
  }

  logout(): Promise<void> {

    return this.afAuth.signOut()
      .then(() => {
        this.router.navigate(['/']);
        window.location.reload()
      })
      .catch(error => {
        if (error.code)
        {
          console.log(error.code);
        return error;
        }
      });


  }


  resetPassword(email: string) {
    return new Promise((resolve, reject) => {
      this.afAuth.sendPasswordResetEmail(email)
        .then(passwordData => resolve(passwordData), err => reject(err))
    });
  }


}
