import { Collection } from "src/app/v2/models/collection-reference.model";
import { Condition } from "src/app/v2/models/firestore-interaction.model";

export interface RouteConfig {
    [route: string]: QueryParamConfig[];
}

export interface QueryParamConfig {
    paramName: string;
    type: 'route' | 'query';
    collection: Collection;
    conditions?: Condition[];
}

export enum ComputedRouteValues {
    CurrentUser = "CurrentUser"
}

