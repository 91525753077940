<!---calendar  bookings  ---->
<div class="container-fluit mt-2" style="width: 100%; display: inline-block">
  <full-calendar
    #fullcalendar
    [options]="calendarOptions"
    [events]="bookings"
  ></full-calendar>
</div>

<!---Modal information booking---->

<ng-template #modalContent let-close="close">
  <div class="container-fluid">
    <div class="row">
      <div class="modal-body">
        <div class="card data-user" style="width: auto">
          <div class="card-header" [style.background]="colors">
            <h3
              [style.color]="font"
              style="margin: 0; float: left; width: 80%"
              class="user-rental"
            >
              <i class="far fa-id-card" [style.color]="font"></i> Rental
              information
            </h3>
            <button
              type="button"
              class="close mx-1 btn"
              style="float: right"
              (click)="close()"
            >
              <i class="fa fa-close"></i>
            </button>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12 col-lg-8 col-sm-6 col-md-7 mt-2">
                <p class="pInfo">
                  <b>Name : </b
                  >{{
                    modalData?.clickInfo.event.extendedProps.obj.userInfo.name
                  }}
                  {{
                    modalData?.clickInfo.event.extendedProps.obj.userInfo
                      .lastName
                  }}
                </p>
                <p class="pInfo text-primary">
                  <b>Email : </b
                  >{{
                    modalData?.clickInfo.event.extendedProps.obj.userInfo.email
                  }}
                </p>
                <p class="pInfo">
                  <b>Phone : </b
                  >{{
                    modalData?.clickInfo.event.extendedProps.obj.userInfo.phone
                  }}
                </p>
                <p class="pInfo">
                  <b>Address : </b
                  >{{
                    modalData?.clickInfo.event.extendedProps.obj.userInfo
                      .address
                  }}
                </p>
              </div>

              <div
                class="col-12 col-lg-4 col-sm-6 col-md-5 mt-2 justify-content-end"
              >
                <p class="pInfo">
                  <b>Date : </b
                  >{{
                    modalData?.clickInfo.event.extendedProps.obj.rentalType ==
                    "byHour"
                      ? (modalData?.clickInfo.event.extendedProps.obj.dayStart.toDate()
                        | date : "h:mm a")
                      : (modalData?.clickInfo.event.extendedProps.obj.dayStart.toDate()
                        | date : "MMM d")
                  }}
                  -
                  {{
                    modalData?.clickInfo.event.extendedProps.obj.dayEnd.toDate()
                      | date : "MMM d,y"
                  }}
                </p>
                <p class="pInfo">
                  <b>Rental number : </b> #
                  <a href="/partner/bookings/{{ modalData?.clickInfo.event.extendedProps.obj.id }}">
                    {{
                      modalData?.clickInfo.event.extendedProps.obj.rentalNumber
                    }}
                  </a>
                </p>
                <p
                  class="pInfo"
                  *ngIf="
                    modalData?.clickInfo.event.extendedProps.obj.rentalType ==
                      'byDay';
                    else ByHour
                  "
                >
                  <b>Rental type : </b> By day
                </p>
                <ng-template #ByHour>
                  <p class="pInfo"><b>Rental type : </b> By hour</p>
                </ng-template>
                <p class="pInfo">
                  <b>Product Qty : </b
                  >{{
                    modalData?.clickInfo.event.extendedProps.obj.productsID
                      .length
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="card products mt-4">
          <div class="card-header" [style.background]="colors">
            <h3 [style.color]="font" style="margin: 0; font-weight: 500">
              <i class="fa fa-shopping-cart" [style.color]="font"></i> Product
              Information
            </h3>
          </div>
          <table class="table table-bordered">
            <thead [style.background]="colors">
              <tr [style.color]="font">
                <th>Product Name</th>
                <th>Size</th>
                <th>Description</th>
                <th>Cost</th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngFor="
                  let product of modalData?.clickInfo.event.extendedProps.obj
                    .products
                "
              >
                <tr>
                  <td>{{ product.productName }}</td>
                  <td>{{ product.productSize }}</td>
                  <td>{{ product.description }}</td>
                  <td>{{ product.cost | currency }}</td>
                </tr>

                <ng-container *ngFor="let addon of product.addons">
                  <ng-container *ngFor="let selection of addon.optionsSelect">
                    <ng-template [ngIf]="selection.isSelected == true">
                      <tr>
                        <td class="empty-1"></td>
                        <td class="empty-2"></td>
                        <td>{{ addon.title }}</td>
                        <td>{{ selection.price | currency }}</td>
                      </tr>
                    </ng-template>
                  </ng-container>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
          <div class="row">
            <div class="col-12">
              <p class="text-end p-1">
                <b> Total </b>
                {{
                  (+modalData?.clickInfo.event.extendedProps.total | currency)
                    ? (modalData?.clickInfo.event.extendedProps.total
                      | currency)
                    : (0 | currency)
                }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-outline-secondary"
          (click)="close()"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</ng-template>
