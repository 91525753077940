import { Injectable } from '@angular/core';

//Services
import { InventoryPageService } from 'src/app/services/inventory-page.service';
import { PaymentsService } from 'src/app/services/payments.service';
import { PricingService } from 'src/app/services/pricing.service';

//Models
import { Cart } from 'src/app/models/storage/cart.model';
import { DiscountCode } from 'src/app/v2/models/storage/discount-code.model';
import { ProductGroup } from 'src/app/models/storage/product-group.model';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  constructor(
    private _paymentsService: PaymentsService,
    private _pricingService: PricingService,
    private _inventoryPageService: InventoryPageService
  ) { }

  async getIfCartIsReservedAndAmountToPay(Cart: Cart, productGroupMap: { [key: string]: ProductGroup }, discountCodeMap: { [key: string]: DiscountCode }) {
    try {
      const rentalHasReservation = this.checkIfHasReservation(Cart); //Get if the Cart has the reservation widget and is checked
      const reservationPaid = this._paymentsService.reservationIsPaid(Cart); //Get if the reservation was paid

      if (!rentalHasReservation) {
        return this.buildResponse(false, reservationPaid);
      }

      const discountObj = this.getDiscountObj(Cart, discountCodeMap); //Get discount Obj
      const subTotalWithDiscounts = await this.getSubtotalWithDiscounts(Cart, productGroupMap, discountObj); //Get subtotalWDiscounts
      const taxes = await this.calculateTaxes(Cart); //Get all taxes & fees
      const amountPending = this.calculateAmountPending(subTotalWithDiscounts, taxes.reservationSubtotal); //Get amountPending to pay

      return this.buildResponse(true, reservationPaid, taxes.totalToPay, taxes.fleetTax, taxes.companyTax, amountPending); //Return all the values

    } catch (error) {
      console.error('Error calculating reservation:', error);
      throw new Error('Error calculating reservation.');
    }
  }

  async calculateAmount(rentalHasReservation: boolean, reservationPaid: boolean, reservationTotalToPay: number, Cart: any, amountPending: number): Promise<number> {
    if (rentalHasReservation) {
      return reservationPaid
        ? (await this._pricingService.addTaxAndFeesToAmount(Cart, amountPending)).finalAmount
        : reservationTotalToPay;
    } else {
      return (await this._pricingService.addTaxAndFeesToAmount(Cart, amountPending)).finalAmount;
    }
  }

  private checkIfHasReservation(Cart: Cart): boolean {
    return this._inventoryPageService.hasReservationWidget(Cart.cartWidgetList, true).selected;
  }

  private getDiscountObj(Cart: Cart, discountCodeMap: { [key: string]: DiscountCode }): DiscountCode | null {
    if (Cart.promoApplied && Cart.promocode) {
      return discountCodeMap[Cart.promocode.toLowerCase()] || null;
    }
    return null;
  }

  private async getSubtotalWithDiscounts(Cart: Cart, productGroupMap: { [key: string]: ProductGroup }, discountObj: DiscountCode | null) {
    const { promoCodeDiscountTotal, cartRentalPrice, widgetsSubTotal } = await this._pricingService.calculateCartTotal(Cart, productGroupMap, discountObj);
    const subTotalWithDiscounts = cartRentalPrice + widgetsSubTotal - promoCodeDiscountTotal;

    return subTotalWithDiscounts;
  }

  private async calculateTaxes(Cart: Cart) {
    const { reservationSubtotal, fleetTax, companyTax } = await this._pricingService.calculateReservationTotal(Cart);
    const totalToPay = reservationSubtotal + fleetTax + companyTax;

    return { reservationSubtotal, fleetTax, companyTax, totalToPay };
  }

  private calculateAmountPending(subTotalWithDiscounts: number, reservationSubtotal: number): number {
    return subTotalWithDiscounts - reservationSubtotal;
  }

  private buildResponse(
    rentalHasReservation: boolean,
    reservationPaid: boolean,
    reservationTotalToPay: number = 0,
    fleetTax: number = 0,
    companyTax: number = 0,
    amountPending: number = 0
  ) {
    return {
      rentalHasReservation,
      reservationPaid,
      reservationTotalToPay,
      fleetTax,
      companyTax,
      amountPending
    };
  }
}
