import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from "@angular/router";
import { CurrencyPipe } from '@angular/common';


//Firebase Imports
import {environment} from '../environments/environment';

//Forms
import {FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import {ReactiveFormsModule} from '@angular/forms';

import {HttpClientModule} from '@angular/common/http';

//Angular Material
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatNativeDateModule} from '@angular/material/core';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatRippleModule} from '@angular/material/core';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatLegacySliderModule as MatSliderModule} from '@angular/material/legacy-slider';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatTreeModule} from '@angular/material/tree';


//Pages
import {momentAdapterFactory, PartnerModule} from "./partner/partner.module";
import {CalendarModule, DateAdapter} from "angular-calendar";
import {adapterFactory} from "angular-calendar/date-adapters/date-fns";
import {ToastrModule} from "ngx-toastr";

import { getApp, initializeApp, provideFirebaseApp, FirebaseOptions } from "@angular/fire/app";
import { initializeFirestore, provideFirestore } from "@angular/fire/firestore";
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireFunctionsModule, REGION } from "@angular/fire/compat/functions";
import { AngularFireDatabaseModule } from "@angular/fire/compat/database";
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { ImageCropperModule } from 'ngx-image-cropper';
import { WaiverComponent } from './waiver/waiver.component';
import { DataTablesModule } from 'angular-datatables';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { LoginComponent } from './login.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
    declarations: [
        AppComponent,
        WaiverComponent,
        LoginComponent,
        ],
    imports: [
        NgbModule,
        DataTablesModule,
        CKEditorModule,
        BrowserModule,
        AppRoutingModule,
        NgbModalModule,
        BrowserAnimationsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireFunctionsModule,
        AngularFireAnalyticsModule,
        AngularFireDatabaseModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        PartnerModule,
        RouterModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        NgSelectModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        ImageCropperModule, CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
        FontAwesomeModule
    ],
    providers: [
      CurrencyPipe,
      { provide: REGION, useValue: environment.google.region },
      provideFirebaseApp(() => getApp()),
      provideFirestore(() => {
        return initializeFirestore(getApp(), {ignoreUndefinedProperties: true})
      }),
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
