export interface BookingTemplate {

    // declare enum MyEnum{
    //     qwer,
    // }

    // export type ReadingTypes = 'some'|'variants'|'of'|'strings';

    title: string,
    isActive: boolean,
    companyID: string,
    content: Array<any>,
    breadcrumbs: Array<any>[],
    gridHeight: number,
    gridRows: number,
    viewTypePreference: viewTypePreference,
    editState?: { isEditing: false, user: '' },
    dateCreated?: Date,
    datesInactivated: [],
    companyLogo: string,
    companyName: string,
    id?: string, // Sometimes included from collection return
}

export enum viewTypePreference {
    catalog = "catalog",
    date = "date"
}

export const defaultValues = {
    title: '',
    isActive: true,
    companyID: '',
    content: [],
    breadcrumbs: [],
    gridHeight: 0,
    gridRows: 0,
    viewTypePreference: 'catalog',
    dateCreated: new Date(),
    datesInactivated: [],
    companyLogo: '',
    companyName: '',
}
