import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {PartnerService} from "../../../services/partner.service";
import Swal from 'sweetalert2';
import {AngularFireFunctions} from "@angular/fire/compat/functions";
import {UserService} from "../../../services/user.service";

export interface presence {
  state: string;
  lastChanged: any;
}

@Component({
  selector: 'app-user-status',
  templateUrl: './user-status.component.html',
  styleUrls: ['./user-status.component.scss']
})


export class UserStatusComponent implements OnInit {

  @Input() user;
  //emite un event que manda a la pagina de info del usuario
  @Input()
  isAccount: boolean;
  @Output()
  currentUser: EventEmitter<any> = new EventEmitter<any>()

  presence$
  @Output()
  dUser: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output()
  eUser: EventEmitter<boolean> = new EventEmitter<boolean>()

  constructor(private _partnerService: PartnerService,
              private _afFun: AngularFireFunctions,
              private _userService: UserService) {
  }

  ngOnInit() {
    this._partnerService.getPresence(this.user.id).subscribe(presence => {
      if (presence) {
        this.presence$ = presence
      }
    })
  }


  launchInfo() {
    this.currentUser.emit(this.user)
  }

  deleteUser() {
    this.dUser.emit(true)
  }


  enableUser() {
    this.eUser.emit(true)
  }
}
