// A service created to hold logc and functions for the inventory page based off of availability algorithm responses
// Mainly for filtering data, restructuring data, and holding reusable methods

import { Injectable } from '@angular/core';
import { PricingService } from './pricing.service';
import { RentalService } from './rental.service';
import { DateTime } from 'luxon';
import { newAddons, ProductRental, makeNewAddonsObject, makeNewProductObject } from 'src/app/models/rental.model';
import { Rental } from 'src/app/models/storage/rental.model';
import { Cart } from 'src/app/models/storage/cart.model';
import { TimeService } from './time.service';
import { environment } from 'src/environments/environment';
import { advenireSavedWidgetId, reservationSavedWidgetId, ProductOptionsInterface, WidgetType } from '../models/widget.model';
import { AvailabilityInterface, RentalLengthType } from '../models/availability.model';
import { WidgetInterface, ProductElementInterface } from '../models/widget.model';
import { Product } from 'src/app/models/storage/product.model';

@Injectable({
  providedIn: 'root'
})
export class InventoryPageService {

  public newAddonsObj: newAddons = makeNewAddonsObject();
  public newProductRentalObj: ProductRental = makeNewProductObject();

  constructor(
    private pricingService: PricingService,
    private rentalService: RentalService,
    private timeService: TimeService
  ) { }


  // Creates an easy to read productsOrgnized array which is just information about the product sizes from the availability algo
  filterAvailabilityResultsPerProductGroup(productGroupID, algoRes, sizeTypesMap) {
    // Resets variables for each time availability query is called
    let sortOrderList = null;
    let productsOrganized = [];
    /* If items are in cart, we can use cartQuantities */
    if (Object.keys(algoRes['cartQuantities']).length > 0) {
      // Get all the keys that contain the prent group ID
      let newCart = Object.keys(algoRes['cartQuantities']).filter(e => e.indexOf(productGroupID) >= 0)

      // Get the data from the object for the product group sizes to create productsOrganized array
      newCart.forEach(ID => {
        algoRes['cartQuantities'][ID]['sizeID'] = ID.split('_')[1]
        productsOrganized.push(algoRes['cartQuantities'][ID])
        if (sizeTypesMap[algoRes['cartQuantities'][ID]['sizeTypeID']]?.sortOrder) {
          sortOrderList = sizeTypesMap[algoRes['cartQuantities'][ID]['sizeTypeID']]['sortOrder'];
        }
      })
    }
    /* If no items exist in the cart, we must process this info differently as we cannot rely on cartQuantities */
    else {
      Object.keys(algoRes['resultSet'][productGroupID]['listOfSizes']).forEach((size) => {
        productsOrganized.push(algoRes['resultSet'][productGroupID]['listOfSizes'][size])
      });

      if(sizeTypesMap[Object.values(algoRes['resultSet'][productGroupID]['listOfSizes'])[0]['sizeTypeID']]?.sortOrder){
        sortOrderList = sizeTypesMap[Object.values(algoRes['resultSet'][productGroupID]['listOfSizes'])[0]['sizeTypeID']]['sortOrder'];
      }
    }

    // Catches if sort order does not exist, avoids errors & sorts by sort order list defined on size type
    if (sortOrderList && sortOrderList.length > 0) {
      productsOrganized.sort((a, b) => sortOrderList.indexOf(a.sizeID) - sortOrderList.indexOf(b.sizeID));
    }
    return productsOrganized // returns Arr of objects containing size info
  }

  // Takes the results from the availability for a product group
  // Determines amount and sorts the sizes available into an object with the size, quantity and products tied to the specific size
  filterProductSizeAvailability(availableProducts, parentID) {
    let productsOrganized = [];
    let tempSizes = []; //Keeps track of sizes added to productsOrganized array


    availableProducts.forEach(product => {
      // adds first object to array if empty
      if (!tempSizes.includes(product['productSize'])) {
        productsOrganized.push({ product: [product['id']], sizeName: product['productSize'], quantity: 1, sizeID: product['productSizeID'] });
        tempSizes.push(product['productSize']);
      }
      // if size exists already, add the product id and increase quantity of object
      else {
        productsOrganized.forEach(object => {
          if (object['size'] == product['productSize']) {
            object['product'].push(product['id']);
            object['quantity'] += 1;
          }
        })
      }
    })

    // Sorting groups by groupName in alphabetical order
    function compare(a, b) {
      if (a.size > b.size) {
        return -1;
      }
      if (a.size < b.size) {
        return 1;
      }
      return 0;
    }

    productsOrganized = productsOrganized.sort(compare);

    return productsOrganized
  }


  getWidgetCssSettings(item) {
    let settings;
    if (item.widgetType == "checkbox") {
      settings =
      {
        background_color: '',
        border: true,
        rounded_corners: 10,
        padding_top: 10,
        padding_right: 24,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      }
    }
    else if (item.widgetType == "quantity") {
      settings =
      {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 15,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      }
    }
    else if (item.widgetType == "price") {
      settings =
      {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 15,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      }
    }
    else if (item.widgetType == "textarea") {
      settings =
      {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 0,
        padding_bottom: 10,
        padding_left: 0,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      };

    }
    else if (item.widgetType == "image") {

      settings =
      {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 0,
        padding_bottom: 10,
        padding_left: 0,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      };

    }
    else if (item.widgetType == "radios") {

      settings =
      {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 15,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,

      }
    }
    else if (item.widgetType == "dropdown") {

      settings =
      {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 15,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
        width: 100,
      }
    }
    else if (item.widgetType == "product") {
      settings =
      {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 0,
        padding_bottom: 10,
        padding_left: 0,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      }
    }
    else if (item.widgetType == "heightInput") {

      settings =
      {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 15,
        padding_bottom: 10,
        padding_left: 0,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      }
    }
    else if (item.widgetType == "weightInput") {
      settings =
      {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 5,
        padding_right: 15,
        padding_bottom: 0,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      }
    }
    else if (item.widgetType == "tip") {
      settings =
      {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 15,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      }
    }

    return settings
  }




  //------------ Widget Filtering ------------------//

  // Gets the max for each option and sets a productsAvail list for each option
  public setProductWidgetMaxes(productWidgetIDs: string[], productMap: { [key:string] : Product }, widgetList: WidgetInterface[]): WidgetInterface[] {

    // reset all max values
    widgetList.forEach((widget) => {
      if (widget.widgetType == 'product') {
        // If you are looking at the right product group then find the size and add 1 to the max
        widget['element']['options'].forEach(option => {
          option['max'] = 0;
        })
      }
    })

    if (productWidgetIDs) {
      // this.allInvPageProducts
      productWidgetIDs.forEach(widgetAvail => {
        let product = productMap[widgetAvail];

        //loop though the entire widgetList to find the right product widget
        widgetList.forEach((widget, i) => {
          if (widget.widgetType == WidgetType.product) {
            this.setProductWidgetMaxesHelper(widget.element as ProductElementInterface, product)
          }
        })
      })
    }

    return widgetList
  }

  public setProductWidgetMaxesHelper(element: ProductElementInterface, product: Product): void {
    // If you are looking at the right product group then find the size and add 1 to the max
    if (element.groupId == product.productGroupID) {
      element.options.forEach((option) => {
        option.productsAvail = []; // reset products avail
        if (option.sizeID == product.productSizeID) {
          // If size is found in group then increase max by 1
          if (option.max) {
            option.max += 1;
            option.productsAvail.push(product.id)
          }
          else {
            option.max = 1;
            option.productsAvail = [product.id];
          }

        }
      })
    }
  }

  // Gets the max for each option and sets a productsAvail list for each option using the 'quantites' object from the avaiiability algo
  public setProductWidgetMaxesWithProductWidgetQuantitiesParsing(widgetList: WidgetInterface[], availabilityParsing: AvailabilityInterface): WidgetInterface[] {
    this.resetOptionMaxValues(widgetList);

    widgetList.forEach(widget => {
      if (widget.widgetType !== WidgetType.product){
        return
      }

      if (widget.element && 'options' in widget.element) {
        widget.element.options.forEach(option => {
          this.updateOptionMaxValues(widget, option, availabilityParsing);
        });
      }
    });

    return widgetList;
  }

  private resetOptionMaxValues(widgetList: WidgetInterface[]): void {
    widgetList.forEach(widget => {
      if (widget.widgetType === WidgetType.product) {
        if (widget.element && 'options' in widget.element) {

          widget.element.options.forEach(option => {
            option.max = 0;
          });
        }
      }
    });
  }


  private updateOptionMaxValues(widget: WidgetInterface, option: ProductOptionsInterface, availabilityParsing: AvailabilityInterface): void {
    try {
      if (widget.element && 'groupId' in widget.element) {
        const productElement = widget.element as ProductElementInterface;
        const currentAvail = availabilityParsing.getProductWidgetCurrentAvail(productElement.groupId, option.sizeID);
        option.productsAvail = [];
        option.max = currentAvail + option.inputValue;
        if (option.max < 0) {
          option.max = 0;
        }
      }
      else {
        throw new Error('Invalid widget element type');
      }
    }
    catch (error) {
      console.error('Error updating option max values:', error);
      option.max = 0;
    }
  }


  public setProductWidgetMaxesWithProductWidgetQuantitiesMiniCartParsing(widgetList: WidgetInterface[], availabilityParsing: AvailabilityInterface): WidgetInterface[] {
    // Reset all max values
    widgetList.forEach((widget) => {
        if (widget.widgetType === WidgetType.product) {
          if (widget.element && 'options' in widget.element) {
              widget.element.options.forEach((option) => {
                  option.max = 0;
              });
            }
        }
    });

    // Loop through the entire widgetList to find the right product widget
    widgetList.forEach((widget: WidgetInterface) => {
        if (widget.widgetType === WidgetType.product) {
          if (widget.element && 'options' in widget.element && ('groupId' in widget.element)) {
            const productElement = widget.element as ProductElementInterface;

            productElement.options.forEach((option) => {
                option.productsAvail = []; // Reset products avail

                try {
                  // Adjust the max to include the product widgets already in the cart for the product you are editing
                  option.max = availabilityParsing.getProductWidgetCurrentAvail(productElement.groupId, option.sizeID);
                }
                catch (error) {
                  console.error('Error getting product widget current availability:', error);
                  option.max = 0;
                }

                // Fixes a bug in the mini cart
                if (option?.productsCheckedOut) {
                    option.max += option.productsCheckedOut.length;
                }
            });
          }
        }
    });

    return widgetList;
  }

    // loop through widgets and find products
    // set the product maxes equal to cart qty avail, easy peasy
    getCartProductWidgetMaxes(widgetList, algoRes) {
      widgetList.forEach((widget, ind) => {
        if (widget.widgetType == WidgetType.product) {
          widget['element']['options'].forEach((opt, i) => {
            if(algoRes['productWidgetTotals'][widget.element.groupId + "_" + opt['sizeID']] && algoRes['cartQuantities'][widget.element.groupId + "_" + opt['sizeID']]){
              widgetList[ind]['element']['options'][i]['max'] = algoRes['productWidgetTotals'][widget.element.groupId + "_" + opt['sizeID']]['totalAvail'] - algoRes['cartQuantities'][widget.element.groupId + "_" + opt['sizeID']]['cartQty'] + opt['inputValue'];
            }
          })
        }
      })
      return widgetList
    }


  // loop through widgets and find products
  // set the product maxes equal to cart qty avail
  public getCartProductWidgetMaxesParsing(widgetList: WidgetInterface[], availabilityParsing: AvailabilityInterface): WidgetInterface[] {
    widgetList.forEach((widget) => {
        if (widget.widgetType === WidgetType.product) {
          if (widget.element && 'options' in widget.element && ('groupId' in widget.element)) {
            const productElement = widget.element as ProductElementInterface;

            productElement.options.forEach((opt) => {
            try {
              opt.max = availabilityParsing.getProductWidgetCurrentAvail(productElement.groupId, opt.sizeID) + opt.inputValue;
            }
            catch (error) {
              console.error('Error getting product widget current availability:', error);
              opt.max = 0;
            }
            });
          }
        }
    });
    return widgetList;
  }


  // -------- Rental Conversion ------- //

  // Converting cart obj to rental
  convertWidgetsToAddons(widgetList, companyID, daySpan, selectedHours?, productGroup?, isCartWidget?, cartRentalPrice?) {

    let addonList = [];
    let allProductWidgetIDs = [];

    if (widgetList.length == 0) {
      return {addonList, allProductWidgetIDs};
    }

    if (selectedHours == '24 Hour Rental') {
      daySpan = daySpan - 1;
    }
    const widgetTips = widgetList.filter(widget => widget.widgetType === "tip");
    const otherWidgets = widgetList.filter(widget => widget.widgetType !== "tip");
    // Set addons
    otherWidgets.forEach((widget) => {
      let newAddons = JSON.parse(JSON.stringify(this.newAddonsObj));
      newAddons.id = widget.id;
      newAddons.companyID = companyID;

      if (widget?.element) {
        widget.element.description ? (newAddons.description = widget.element.description) : (newAddons.description = '');
        widget.element?.is_required ? (newAddons.isRequired = widget.element.is_required) : (newAddons.isRequired = false);
      }
      newAddons.sortOrder = widget?.sortOrder || 0;
      // widget.element ? (newAddons.isRequired = widget.element.is_required) : '';
      newAddons.addonType = widget?.widgetType || "";

      if (widget.widgetType == 'dropdown') {
        newAddons.addonType = 'select';
      }

      // Set addon options if widget is a dropdown or radio
      if (widget.widgetType == 'dropdown' || widget.widgetType == 'radios') {
        newAddons.title = widget.element.label;
        widget.element.options.forEach((option) => {
          newAddons.optionsSelect.push({
            isSelected: option?.is_selected || null,
            description: option?.text || "",
          });

          // If price doesnt exists, is null or undefined then set it equal to 0
          if(option?.price && option.price != null && option.price != undefined){
              let price = option.price;
              if (widget.element.recurringPrice) {
                price = price * daySpan;
              }
              newAddons.optionsSelect[newAddons.optionsSelect.length - 1]['price'] = price;
          }
          else{
            newAddons.optionsSelect[newAddons.optionsSelect.length - 1]['price'] = 0;
          }
        });
      }

      // Set addon option if widget is quantity
      else if (widget.widgetType == 'quantity') {
        newAddons.title = widget.element.label;
        newAddons.optionsSelect = [
          {
            qty: widget.element?.inputValue,
          },
        ];

        // If price doesnt exists, is null or undefined then set it equal to 0
        if(widget.element?.price && widget.element.price != null && widget.element.price != undefined){
          let price = widget.element.price;
          if(widget.element?.recurringPrice){
            price = price * daySpan;
          }
          newAddons.optionsSelect[0]['price'] = price;
        }
        else{
          newAddons.optionsSelect[0]['price'] = 0;
        }
      }

      // Set addon option if widget is height
      else if (widget.widgetType == 'heightInput') {
        newAddons.title = widget.element.label;

        // Make sure these values are set to null and not undefined
        if (!widget.element?.feetInput) {
          widget.element.feetInput = null;
        }
        if (!widget.element?.inchInput) {
          widget.element.inchInput = null;
        }

        newAddons.optionsSelect = [
          {
            feetInput: widget.element?.feetInput || null,
            inchInput: widget.element?.inchInput || null,
          },
        ];
      }

      // Set addon option if widget is height
      else if (widget.widgetType == 'weightInput') {
        newAddons.title = widget.element.label;

        if (!widget.element?.inputValue) {
          widget.element.inputValue = null;
        }

        newAddons.optionsSelect = [
          {
            input: widget.element?.inputValue,
          },
        ];
      }

      // Set addon option if widget is product
      // Price is kept on the overall element not on the individual options
      else if (widget.widgetType === 'product') {
        newAddons.title = widget.element?.name || "";

        if (widget['element']['isDropdown']) {
          newAddons.addonType = 'select';
          widget.element.options.forEach((option) => {
            if (option?.show) {
              newAddons.optionsSelect.push({
                isSelected: option?.is_selected || null,
                description: widget.element?.description || "",
                size: option.size,
              });

              // If price doesnt exists, is null or undefined then set it equal to 0
              if(widget.element?.price && widget.element.price != null && widget.element.price != undefined){
                newAddons.optionsSelect[newAddons.optionsSelect.length - 1]['price'] = widget.element.price;
              }
              else{
                newAddons.optionsSelect[newAddons.optionsSelect.length - 1]['price'] = 0;
              }

              // If there are productId's attached then add them to allProducts list
              // Necessary for availability algorithm
              if (option.productsCheckedOut) {
                option.productsCheckedOut.forEach((productID) => {
                  allProductWidgetIDs.push(productID);
                });
              }
            }
          });
        } else {
          widget.element.options.forEach((option) => {
            if (option.show) {
              newAddons.optionsSelect.push({
                qty: option?.inputValue,
                size: option.size,
              });

              // If price doesnt exists, is null or undefined then set it equal to 0
              if(widget.element?.price && widget.element.price != null && widget.element.price != undefined){
                newAddons.optionsSelect[newAddons.optionsSelect.length - 1]['price'] = widget.element.price;
              }
              else{
                newAddons.optionsSelect[newAddons.optionsSelect.length - 1]['price'] = 0;
              }

              // If there are productId's attached then add them to allProducts list
              // Necessary for availability algorithm
              if (option.productsCheckedOut) {
                option.productsCheckedOut.forEach((productID) => {
                  allProductWidgetIDs.push(productID);
                });
              }
            }
          });
        }
      }

      // Set addon option if widget is checkbox
      else if (widget.widgetType == 'checkbox') {
        newAddons.title = widget.element.label;
        if (widget.element?.price && widget.element.price != null && widget.element.price != undefined) {
          let price = widget.element?.price;
          if (widget.element?.recurringPrice) {
            price = price * daySpan;
          }
          newAddons.optionsSelect = [{ price: price }];
        }
        else{
          newAddons.optionsSelect = [{price: 0}];
        }
        // Qty is 1 if true and 0 if false
        if (widget.element.inputValue) {
          newAddons.optionsSelect[0].qty = 1;
        } else {
          newAddons.optionsSelect[0].qty = 0;
        }
      }

      else if (widget.widgetType == 'price') {
        newAddons.title = widget.element.label;
        if (widget.element.price != null && widget.element.price != undefined) {
          let price = widget.element.price;
          if (widget.element.recurringPrice) {
            price = price * daySpan;
          }
          newAddons.optionsSelect = [{ price: price }];
        } else {
          newAddons.optionsSelect = [{price: 0}];
        }

      }
      addonList.push(newAddons);
    });
    if(!isCartWidget){
      const priceitemWOTip = this.pricingService.calculateAddonPrice(widgetList, daySpan, selectedHours, productGroup, false, cartRentalPrice, true);
      let productwotip = priceitemWOTip + cartRentalPrice;
      widgetTips.forEach((widget) => {
        let newAddons = JSON.parse(JSON.stringify(this.newAddonsObj));
        newAddons.id = widget.id;
        newAddons.companyID = companyID;
        if (widget?.element) {
          widget.element.description ? (newAddons.description = widget.element.description) : (newAddons.description = '');
          widget.element?.is_required ? (newAddons.isRequired = widget.element.is_required) : (newAddons.isRequired = false);
        }
        newAddons.sortOrder = widget?.sortOrder || 0;
        newAddons.addonType = widget.widgetType;
        newAddons.title = widget.element.label;
        newAddons.description = widget.element.description + " (" + widget.element.price.toString() + "%" + ")"
        newAddons.optionsSelect = [{
          price: productwotip * (widget.element.price / 100)
        }]
        addonList.push(newAddons);
      });
    }else{
      widgetTips.forEach((widget) => {
        let newAddons = JSON.parse(JSON.stringify(this.newAddonsObj));
        newAddons.id = widget.id;
        newAddons.companyID = companyID;

        if (widget?.element) {
          widget.element.description ? (newAddons.description = widget.element.description) : (newAddons.description = '');
          widget.element?.is_required ? (newAddons.isRequired = widget.element.is_required) : (newAddons.isRequired = false);
        }
        newAddons.sortOrder = widget?.sortOrder || 0;
        // widget.element ? (newAddons.isRequired = widget.element.is_required) : '';
        newAddons.addonType = widget.widgetType;
        newAddons.title = widget.element.label;
        newAddons.description = widget.element.description + " (" + widget.element.price.toString() + "%" + ")"
        newAddons.optionsSelect = [{
          price: cartRentalPrice * (widget.element.price / 100)
        }]

        addonList.push(newAddons);
      });
    }
    return {addonList, allProductWidgetIDs};
  }


  async convertCartObjToRental(cartObj: Cart, productGroupMap, productMap, discountCodeMap, oldRental, isSkipPayment?) {

    let newRental;
    newRental = JSON.parse(JSON.stringify(oldRental)); // So that no info is left out (isReserved, etc.)

    if (cartObj.items.length == 0) {
      throw new Error('No items in cart');
    }

    let timeslotCheck = this.checkCartItemsTimeslotType(cartObj?.items); // If here are differing timeslot types then there is an issue
    if (!timeslotCheck) {
      console.error('Timeslot types do not match');
    }


    newRental = await this.convertCartItemsToRentalProducts(cartObj, cartObj.companyId, productMap, productGroupMap, newRental);


    //----- New Rental Model ------ //
    newRental.companyID = cartObj['companyId'] || "";
    newRental.rentalLocationID = cartObj['items'][0].locationID || "";
    newRental.timeZone = cartObj['items'][0]?.locationTimeZone;
    newRental.dayStart = cartObj['items'][0]?.dayStart;
    newRental.dayEnd = cartObj['items'][0]?.dayEnd;
    newRental.cartObj = cartObj;
    newRental.created = oldRental.id ? oldRental.created : new Date();
    newRental.statusDate = oldRental.statusDate || {isReserved: new Date()};
    if ((isSkipPayment === undefined || isSkipPayment === null) && this.hasReservationWidget(cartObj.cartWidgetList, true).selected) {
      newRental.statusDate.reservationPayment = new Date();
    }
    newRental.statusDate.isReserved = oldRental.statusDate ? oldRental.statusDate.isReserved : new Date();
    newRental.lastModified = new Date();
    newRental.lastcost = JSON.parse(JSON.stringify(oldRental.cost));
    newRental.availabilityOverrideConfig = oldRental?.availabilityOverrideConfig ? oldRental.availabilityOverrideConfig : null;
    newRental.timeslotType = cartObj['items'][0]?.timeslotType || "";

    newRental = await this.convertRentalPricingUpdatesAndConvertCartWidgets(cartObj, newRental, cartObj.companyId, productGroupMap, discountCodeMap, cartObj?.items[0].daySpan, cartObj?.items[0].selectedHours);
    // let productResult = this.convertCartItemsToRentalProducts(cartObj, productGroupMap, productMap);

    const amountPending = oldRental.amountPending;
    if(oldRental?.id){
      const totalPaid = await this.getCartTotalPaid(oldRental.id);
      newRental.amountPaid = totalPaid;
      newRental.amountPending = amountPending;
      const costDifference = Number((newRental.subtotal - oldRental.subtotal).toFixed(2));
      if(costDifference >= 0){
        newRental.amountPending += costDifference;
        newRental.amountPendingToRefund = 0
      } else {
        newRental.amountPending -= Math.abs(costDifference);
        if(newRental.amountPending < 0) {
          newRental.amountPending = 0
          newRental.amountPendingToRefund = Math.abs(newRental.amountPending)
        } else {
          newRental.amountPendingToRefund = 0;
        }
      }
    }

    const { isDeliver, deliverInformation } = this.toggleRentalDevliverVariable(cartObj?.cartWidgetList);
    newRental.isDeliver = isDeliver;
    newRental.deliverInformation = deliverInformation;

    // Waivers
    // if (newRental?.products && newRental?.products.length > 0) {
      newRental?.products.forEach(newProductRental => {
        if(newProductRental.waivers.length > 0){
          newProductRental.waivers.forEach((waiver) => {
            if(!newRental.waiver.includes(waiver)){
              newRental.waiver.push(waiver);
            }
          })
        }
      })
    // }

    // newRental.isActive = true; //probably change this to false
    newRental.isConfirmed = true;

    // If cart is shop day or 24 hour rental then rental type is byDay
    if (
      cartObj['items'][0]?.selectedHours == RentalLengthType.AllDay ||
      cartObj['items'][0]?.selectedHours == RentalLengthType.TwentyFourHour
      ) {
        newRental.rentalType = 'byDay';
      }
      // Otherwise it is an hourly rental
      else {
        newRental.rentalType = 'byHour';
      }

      //Add userInfo
      newRental.userInfo = cartObj?.customerInfo || {};
      newRental.id = oldRental.id;

      console.log("new rental made", newRental);

      return newRental as Rental
    }


  async convertRentalPricingUpdatesAndConvertCartWidgets(cartObj: Cart, newRental: Rental, companyID, productGroupMap, discountCodeMap, daySpan, selectedHours): Promise<Rental> {
    let discountObj = null
    if (cartObj.promoApplied) {
      // If promo code found on cart -> get that codes object information
      discountObj = discountCodeMap[cartObj.promocode.toLowerCase()];
    }
    // newRental.is24Hours = cartObj.items[0].is24Hrs || false; // Should change with diferents dates i think
    let {subTotal, promoCodeDiscountTotal, cartRentalTax, cartRentalFees, cartRentalPrice, widgetsSubTotal, widgetsTaxes,
      widgetsFees, cartWidgetPrice } = await this.pricingService.calculateCartTotal(cartObj, productGroupMap, discountObj);
    let cartTotalWOTip = this.pricingService.calculateAddonPrice(cartObj['cartWidgetList'], daySpan,
      selectedHours, null, true, cartWidgetPrice, true);
    // Cart Widgets
    if (cartObj?.cartWidgetList && cartObj?.cartWidgetList.length > 0) {
      let rentalPriceWithNoCartWidgets =  (cartRentalPrice + widgetsSubTotal - cartWidgetPrice) + cartTotalWOTip;
      let result = this.convertWidgetsToAddons(cartObj['cartWidgetList'], companyID, daySpan,
                                              selectedHours, null, true, rentalPriceWithNoCartWidgets);

      newRental['cartWidgetList'] = result.addonList;
      newRental['allProductsID'].push(...result.allProductWidgetIDs);
    }
    else {
      newRental['cartWidgetList'] = [];
    }

    newRental.subtotal = cartRentalPrice + widgetsSubTotal;

    // Discount Section
    if (cartObj.promoApplied) {
      // If promo code found on cart -> get that codes object information
      if (discountObj !== null) {
        newRental.discountInfo = discountObj;
        newRental.discount = promoCodeDiscountTotal
      }
    } else {
      newRental.discount = 0;
      newRental.discountInfo = {};
    }
    let subTotalWithDiscounts = cartRentalPrice + widgetsSubTotal - promoCodeDiscountTotal

    newRental.taxes = widgetsTaxes + widgetsFees + cartRentalFees + cartRentalTax
    newRental.cost = subTotalWithDiscounts +  newRental.taxes;

    // this.newRental.govermentTax =
    newRental.companyTax = cartRentalTax + widgetsTaxes;
    newRental.fleetTax = cartRentalFees + widgetsFees;

    newRental.amountPaid = 0;
    newRental.amountPending = 0;

    return newRental as Rental
  }

  convertCartItemsToRentalProducts(cartObj: Cart, companyID, productMap, productGroupMap, rental: Rental): Rental {
      let allProductsID = [];
      let products = [];
      let productsID = [];
      let productsNames = [];
      let productsNamesMonths = [];

      // loop through products in cart
      cartObj['items'].forEach((item) => {
        let newProductRental = JSON.parse(JSON.stringify(this.newProductRentalObj));
        const { rentalPrice, singlePrice } =
          this.pricingService.calculateIndividualRentalPrice(
            item['daySpan'],
            item['selectedHours'],
            productGroupMap[item['parentId']], item
          );
        // Set product rental and add it to product in rental object
        let result = this.convertWidgetsToAddons(item['widgetList'], companyID, item['daySpan'], item['selectedHours'], productGroupMap[item['parentId']], false, singlePrice);
        newProductRental['addons'] = result.addonList;
        allProductsID.push(...result.allProductWidgetIDs);

        //let hours = moment(item.dayEnd.seconds * 1000).diff((item.dayStart.seconds * 1000),'hours')

        newProductRental.productID = item.productId;
        newProductRental.productName = item.productName;
        newProductRental.productSize = item.productSize;
        newProductRental.description = productGroupMap[item.parentId]['groupDescription'];
        newProductRental.days = item.daySpan;
        if (productMap[item['productId']]) {
          newProductRental.productNumber =
          productMap[item['productId']]['productNumber'] === undefined
          ? 0
          : productMap[item['productId']]['productNumber'];
        } else {
          newProductRental.productNumber = 0;
        }
        newProductRental.automaticNumber = productMap[item['productId']]?.automaticNumber || null;
        newProductRental.waivers = productGroupMap[item['parentId']]['waivers'];


        // if (item['adjustedPrice']) {
          //   newProductRental.price = item['adjustedPrice'];
          //   newProductRental.subtotal = item['adjustedPrice'];
          //   newProductRental.rentalCost = item['adjustedPrice'];
        //   newProductRental.cost = item['adjustedPrice'];
        // }
        // else {
          // Get the rental product price

          newProductRental.price = singlePrice;
          newProductRental.subtotal = rentalPrice;
          newProductRental.rentalCost = rentalPrice;
          newProductRental.cost = rentalPrice;
        // }

        // Add product to the rental model
        products.push(newProductRental);
        productsID.push(item.productId);
        productsNames.push(item.productName);
        productsNamesMonths.push(
          item.productName.concat(item.productSize)
        );

        allProductsID.push(item.productId);
      });


      rental.products = products;
      rental.productsID = productsID;
      rental.productsNames = productsNames;
      rental.productsNamesMonths = productsNamesMonths;
      rental.allProductsID = allProductsID;

      return rental as Rental
    }

    toggleRentalDevliverVariable(cartWidgetList) {
      let isDeliver = false;
      let deliverInformation = {name: '', address: ''}

      if (!cartWidgetList || cartWidgetList.length == 0) {
        return { isDeliver, deliverInformation }
      }

      let hasAdvenireWidgetAndIsDeliver = this.checkForAdvenireWidget(cartWidgetList).isDeliver;
      if (hasAdvenireWidgetAndIsDeliver) {
        isDeliver = true;
        deliverInformation = {
          name: 'The Advenire Hotel',
          address: '25 W St George Blvd, St. George, UT 84770, USA',
        };
      }

      return { isDeliver, deliverInformation }
    }

  async quickBookUpdateCostAndProductsOnRental(rental: Rental, cartObj: Cart, productMap, productGroupMap, discountCodeMap, daySpan, selectedHours): Promise<Rental> {
    let newRental = JSON.parse(JSON.stringify(rental))
    newRental = this.convertCartItemsToRentalProducts(cartObj, cartObj.companyId, productMap, productGroupMap, newRental);
    newRental = await this.convertRentalPricingUpdatesAndConvertCartWidgets(cartObj, newRental, newRental.companyID, productGroupMap, discountCodeMap, daySpan, selectedHours);
    const oldRental = await this.rentalService.getRentalByIDPromise(newRental.id)

    const totalPaid = await this.getCartTotalPaid(newRental.id);
    newRental.amountPaid = totalPaid;
    const costDifference = Number((newRental.subtotal - oldRental.subtotal).toFixed(2));

      if(costDifference >= 0){
        newRental.amountPending += costDifference;
        newRental.amountPendingToRefund = 0
      } else {
        newRental.amountPending -= costDifference;
        if(newRental.amountPending < 0) {
          newRental.amountPending = 0
          newRental.amountPendingToRefund = Math.abs(newRental.amountPending)
        } else {
          newRental.amountPendingToRefund = 0;
        }
      }

    const { isDeliver, deliverInformation } = this.toggleRentalDevliverVariable(cartObj?.cartWidgetList);
    newRental.isDeliver = isDeliver;
    newRental.deliverInformation = deliverInformation;

    return newRental as Rental
  }

  // Calls pricing service to calculate total
  async getCartTotalPaid(rentalID) {
    let rentalTotalPaid = 0
    // return this.pricingService.calculateCartTotal(this.cartObj, this.productGroupMap, this.discountGroup);
    await this.rentalService.getMainPayment(rentalID).then((charges) => {
      charges.forEach((charge) => {
        rentalTotalPaid = Number(
          (rentalTotalPaid + charge.amount / 100).toFixed(2)
          );
        });
      });
      await this.rentalService.getCharges(rentalID).then((charges) => {
        charges.forEach((charge) => {
        //this.rentalTotalCost = Number((this.rentalTotalCost + (charge.amount / 100)).toFixed(2))
        rentalTotalPaid = Number(
          (rentalTotalPaid + charge.amount / 100).toFixed(2)
        );
      });
    });
    await this.rentalService.getRefunds(rentalID).then((charges) => {
      charges.forEach((charge) => {
        //this.rentalTotalCost = Number((this.rentalTotalCost - (charge.amount / 100)).toFixed(2))
        rentalTotalPaid = Number(
          (rentalTotalPaid - charge.amount / 100).toFixed(2)
        );
      });
    });
    return rentalTotalPaid
  }

  async getPendingAfterRentalTotalAdjustment(rental){
    let totalPaid = await this.getCartTotalPaid(rental.id);

    if (!totalPaid && totalPaid != 0) {
      console.error('Total paid not a number');
      totalPaid = 0;
    }

    rental.amountPaid = totalPaid;
    const costDifference = Number((rental.cost - totalPaid).toFixed(3));

    const differenceWOTax = this.pricingService.getAmountWithoutTaxes(costDifference)

    if(differenceWOTax >= 0){
      rental.amountPending = differenceWOTax;
      rental.amountPendingToRefund = 0
    } else {
      rental.amountPendingToRefund = Math.abs(costDifference)
      rental.amountPending = 0
    }

    return rental
  }

  // ---------- Mini cart/ Bakend booking methods ----------- //
  createAddonsDisplayArrayByProduct(rental: Rental) {
    rental.products.forEach((product, i) => {
        rental.products[i]['addonDisplay'] = this.createAddonsDisplayArray(product.addons);
    })
    return rental
  }

  // Creates an array on the rental products called addonDisplay which will show the user which addons are selected and the quantity
  createAddonsDisplayArray(addons) {
    const displayArr = [];

    if (!addons || !Object.prototype.isPrototypeOf.call(Array.prototype, addons)) {
      return displayArr
    }

    addons.forEach(addon => {
      // Set addon options if widget is a dropdown or radio
      if (addon.addonType == 'select' || addon.addonType == 'radios') {
        addon.optionsSelect.forEach(opt => {
          if (opt?.isSelected) {
            displayArr.push({ title: opt?.description || addon?.title, price: '$' + opt.price, addonID: addon.id})
          }
        })
      }
      else if (addon.addonType == 'quantity') {
        if (addon.optionsSelect[0]?.qty > 0) {
          displayArr.push({ title: addon.title + 'x ' + addon.optionsSelect[0].qty, price: '$' + addon.optionsSelect[0].price, addonID: addon.id})
        }
      }
      else if (addon.addonType == 'heightInput') {
        if (addon.optionsSelect[0]?.feetInput) {
          if (addon.optionsSelect[0]?.inchInput){
            displayArr.push({ title: 'height: ' + addon.optionsSelect[0].feetInput + 'ft ' + addon.optionsSelect[0].inchInput + 'in', addonID: addon.id});
          }
          else {
            displayArr.push({ title: 'height: ' + addon.optionsSelect[0].feetInput + 'ft ', addonID: addon.id});
          }
        }
      }
      else if (addon.addonType == 'weightInput') {
        if (addon.optionsSelect[0]?.input) {
          displayArr.push({ title: 'weight: ' + addon.optionsSelect[0].input + 'lbs', addonID: addon.id});
        }
      }
      else if (addon.addonType == 'product') {
        addon.optionsSelect.forEach(opt => {
          if (opt.qty > 0) {
            displayArr.push({ title: addon.title + ' ' + opt.size + ' x' + opt.qty, price: '$' + opt.price, addonID: addon.id})
          }
        })
      }
      else if (addon.addonType == 'checkbox') {
        if (addon.optionsSelect[0]?.qty == 1) {
          displayArr.push({ title: addon.title, price: '$' + addon.optionsSelect[0].price, addonID: addon.id})
        }
      }
      else if (addon.addonType == 'price') {
        displayArr.push({ title: addon.title, price: '$' + addon.optionsSelect[0].price, addonID: addon.id})
      }
      else if (addon.addonType == 'tip') {
        displayArr.push({ title: addon.title, price: '$' + addon.optionsSelect[0]?.price || 0, addonID: addon.id})
      }
    })
    return displayArr
  }


  turnAddonDisplayIntoLogString(rental: Rental) {
    let logString = '';
    rental.products.forEach((product, ind) => {
      logString += product.productName + ' ' + product.productSize;

      if (product?.addonDisplay.length > 0) {
        logString += ' - ';

        product.addonDisplay.forEach((addon, i) => {
          if (addon?.price) {
            logString += addon.title + ' ' + addon.price;
          }
          else {
            logString += addon.title;
          }

          if (product.addonDisplay.length - 1 != i) {
            logString += ', ';
          }

        })
        logString += ' | ';
      }

    })

    if (rental.cartWidgetList && rental.cartWidgetList.length > 0) {
      let cartAddons = this.createAddonsDisplayArray(rental.cartWidgetList);

      if (cartAddons.length > 0) {
        logString += 'Cart Widgets: ';
        cartAddons.forEach((addon, i) => {
          if (addon?.price) {
            logString += addon.title + ' ' + addon.price;
          }
          else {
            logString += addon.title;
          }

          if (cartAddons.length - 1 != i) {
            logString += ', ';
          }
        })
      }
    }

    return logString
  }


  swapRentalProductWidgetID(oldID, newID, cartObj: Cart){
    let found = false;
    cartObj.items.forEach((item, i) => {
      if (Object.prototype.hasOwnProperty.call(item, 'widgetList')) {
        item.widgetList.forEach((widget, index) => {
          if (widget.widgetType === 'product') {
            let element = widget.element;
            element.options.forEach((option, ind) => {
              if(option['productsCheckedOut'].includes(oldID)){
                let foundInd = option['productsCheckedOut'].findIndex(id => id === oldID);
                cartObj.items[i].widgetList[index].element.options[ind]['productsCheckedOut'][foundInd] = newID;
                found = true;
              }
            })
          }
        })
      }
    })

    if(!found && Object.prototype.hasOwnProperty.call(cartObj, 'cartWidgetList')) {
      cartObj.cartWidgetList.forEach((widget, index) => {
        if (widget.widgetType === 'product') {
          let element = widget.element;
          element.options.forEach((option, ind) => {
            if(option['productsCheckedOut'].includes(oldID)){
              let foundInd = option['productsCheckedOut'].findIndex(id => id === oldID);
              cartObj.cartWidgetList[index].element.options[ind]['productsCheckedOut'][foundInd] = newID;
            }
          })
        }
      })
    }

    return cartObj
  }

  swapRentalProductID(oldID, newID, cartObj){
    cartObj.items.forEach((item, ind) => {
      if(item.productId == oldID){
        cartObj.items[ind]['productId'] == newID
      }
    })
    return cartObj
  }


  // ------- Cart Widgets ------------- //

  getCartWidgetListData(cartItems, cartWidgetList, inventoryPageMap, productGroupMap, companyID) {

    if (!cartItems || cartItems.length == 0) {
      return cartWidgetList
    }

    let groupIDList = [];
    let invPageIDList = [];
    let allWidgetsList = [];
    let newCartWidgetList = [];
    let savedWidgetIDList = [];

    // Get the prexisting widgets in the cart widget list
    if (cartWidgetList && cartWidgetList.length > 0) {
      cartWidgetList.forEach(widget => {
        savedWidgetIDList.push(widget.savedWidgetId);
      })
    }

    // Get all the parent group ID's in the cart
    cartItems.forEach(item => {
      if (!groupIDList.includes(item.parentId)) {
        groupIDList.push(item.parentId)
      }
    })

    // Get the inventory pages attached and then get the widgets
    groupIDList.forEach(ID => {
      invPageIDList.push()
      allWidgetsList.push(...inventoryPageMap[productGroupMap[ID]['inventoryPageId']]['widgetList'])
    })

    // Filter out duplicate widgets
    allWidgetsList.forEach(widget => {
      if (widget.isCartWidget) {
        if (!savedWidgetIDList.includes(widget.savedWidgetId)) {
          savedWidgetIDList.push(widget.savedWidgetId)
          newCartWidgetList.push(widget);
        }
      }
    })

    ///// Hardcode for rapid cycling and UT PT /////
    if (this.checkConditionsForReservationWidget(cartWidgetList, companyID)) {
      let reservationWidget = this.getReservationWidget();
      reservationWidget.companyID = companyID;
      cartWidgetList.push(reservationWidget);
    }
    if (cartItems.length > 0) {
      if (this.checkConditionsForAdvenireWidget(cartWidgetList, cartItems[0].dayStart, cartItems[0].dayEnd, cartItems[0]?.timeslotType || "", companyID, cartItems[0].locationTimeZone)) {
        let advenireWidget = this.getAdvenireWidget();
        advenireWidget.companyID = companyID;
        cartWidgetList.push(advenireWidget);
      }
    }

    cartWidgetList.push(...newCartWidgetList)
    return cartWidgetList
  }



  checkConditionsForAdvenireWidget(cartWidgetList, dayStart, dayEnd, timeslotType, companyID, timeZone) {
    // check company ID
    if (!environment.hardCodedFeatures.advenireCompanyIDs.includes(companyID)) {
      return false
    }
    // Check if the cart widget list already has the advenire widget
    let hasAdvenireWidget = this.checkForAdvenireWidget(cartWidgetList).hasAdvenireWidget;

    if (hasAdvenireWidget) {
      return false
    }

    // Make sure it is before 10am if looking at the current day
    let currentDate = new Date();
    let rentalStartDateLux;
    let rentalEndDateLux;
    let currentDateLux;
    try {
      rentalStartDateLux = this.timeService.convertToJavascriptDate(dayStart);
      rentalStartDateLux = DateTime.fromJSDate(rentalStartDateLux).setZone(timeZone);
      rentalEndDateLux = this.timeService.convertToJavascriptDate(dayEnd);
      rentalEndDateLux = DateTime.fromJSDate(rentalEndDateLux).setZone(timeZone);
      currentDateLux = DateTime.fromJSDate(currentDate).setZone(timeZone);
    }
    catch(err) {
      console.error(err)
      return false
    }
    // If it is the current day and it is before 10 and ther rental doesnt end before 10 or if it is not the current day
    if ((currentDateLux.hasSame(rentalStartDateLux, 'day') && currentDateLux.hour < 10) && (timeslotType == 'hourly' && rentalEndDateLux.hour > 10 || !timeslotType || timeslotType != 'hourly') || currentDateLux.startOf('day') < rentalStartDateLux.startOf('day')) {
      return true
    }

    return false
  }

  checkConditionsForReservationWidget(cartWidgetList: WidgetInterface[], companyID: string): boolean {
    // Check if the cart widget list already has the reservation widget
    if (this.hasReservationWidget(cartWidgetList, false).isReservationWidgetPresent){
      return false
    }
    if (environment.hardCodedFeatures.reservationCompanyIDs.includes(companyID)) {
      return true
    }
    return false
  }

  validateAdvenireWidgetInCart(dayStart, timeZone, cartWidgetList: WidgetInterface[]): boolean {

    if (!this.checkForAdvenireWidget(cartWidgetList).hasAdvenireWidget) {
      return true
    }
    // Make sure it is before 10am if looking at the current day
    const currentDate = new Date();
    let rentalStartDateLux;
    let currentDateLux;
    try {
      rentalStartDateLux = this.timeService.convertToJavascriptDate(dayStart);
      rentalStartDateLux = DateTime.fromJSDate(rentalStartDateLux).setZone(timeZone);
      currentDateLux = DateTime.fromJSDate(currentDate).setZone(timeZone);
    }
    catch(err) {
      console.error(err)
    }
    // If it is the current day and it is after 10am
    if ((currentDateLux.hasSame(rentalStartDateLux, 'day') && currentDateLux.hour > 10)) {
      return false
    }

    return true
  }

  getAdvenireWidget() {
    const advenireWidget = {
      widgetType: 'checkbox',
      isCartWidget: true,
      companyID: "",
      widgetName: 'Advenire Checkbox',
      savedWidgetId: advenireSavedWidgetId,
      sectionContentId: '',
      sectionPosition: [],
      isSelected: false,
      sortOrder: 0,
      element: {
        label: 'Delivery to The Advenire Hotel',
        is_required: false,
        price: null,
        description: 'Bikes will be delivered the hotel lobby at 25 W St. George Blvd, St. George, UT 84770. Must be guests of The Advenire Hotel to take advantage of this complimentary service. Bike will be available for pickup at 10am and must be returned to the hotel by 6pm.',
        inputValue: false,
        recurringPrice: false,
      },
      componentSettings: {
        background_color: '',
        border: true,
        rounded_corners: 10,
        padding_top: 10,
        padding_right: 24,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      },
    }

    return advenireWidget
  }

  getReservationWidget(){
    const reservationWidget = {
      widgetType: 'checkbox',
      isCartWidget: true,
      companyID: "",
      widgetName: 'Reservation Checkbox',
      savedWidgetId: reservationSavedWidgetId,
      sectionContentId: '',
      sectionPosition: [],
      isSelected: false,
      sortOrder: 0,
      element: {
        label: 'Chekout with Reservation Fee',
        is_required: false,
        price: null,
        description: 'A reservation fee allows you to pay a small, NON-REFUNDABLE, amount now with the remaining balance due prior to pick-up.',
        inputValue: false,
        recurringPrice: false,
      },
      componentSettings: {
        background_color: '',
        border: true,
        rounded_corners: 10,
        padding_top: 10,
        padding_right: 24,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      },
    }

    return reservationWidget
  }

  checkForAdvenireWidget(cartWidgetList) {
    let hasAdvenireWidget = false;
    let isDeliver = false;

    if (!cartWidgetList || cartWidgetList.length == 0) {
      return { hasAdvenireWidget, isDeliver }
    }

    cartWidgetList.forEach(widget => {
      if (widget.savedWidgetId == advenireSavedWidgetId) {
        hasAdvenireWidget = true;
        if (widget.element?.inputValue) {
          isDeliver = true;
        }
      }
    })

    return { hasAdvenireWidget, isDeliver }
  }

  hasReservationWidget(cartWidgetList: WidgetInterface[], validateSelection: boolean): {
    isReservationWidgetPresent: boolean,
    reservationCartWidget?: WidgetInterface,
    selected: boolean
  } {
    //Variables to return
    let isReservationWidgetPresent: boolean = false;
    let reservationCartWidget: WidgetInterface | undefined = undefined;
    let selected: boolean = false;

    //If theres not cartWidget
    if (!cartWidgetList || cartWidgetList.length === 0) {
      return {
        isReservationWidgetPresent,
        reservationCartWidget,
        selected
      }
    }
    //Get the reservation cart widget
    const foundReservationWidget = cartWidgetList.find(widget => widget.savedWidgetId === reservationSavedWidgetId);
    if (foundReservationWidget) {
      isReservationWidgetPresent = true;
      reservationCartWidget = foundReservationWidget;
    }

    if (validateSelection) { //Return checkbox selection
      if (reservationCartWidget && reservationCartWidget.element) { //Verify widget structure
        if ('inputValue' in reservationCartWidget.element) { //Verify if inputValue exists due to model
          selected = reservationCartWidget.element.inputValue; //Return checkbox value - true or false
        }
      }
    }

    return {
      isReservationWidgetPresent,
      reservationCartWidget,
      selected
    };
  }

  // To maintain data integrity, use the old list but remove anye xcess values from it, the new list will
  // contain all the wwidgets still necessary
  removeCartWidgetListData(newList, oldList) {
    let savedWidgetIDs = [];
    let updatedList = [];

    newList.forEach(widget => {
      savedWidgetIDs.push(widget.savedWidgetId)
    })

    oldList.forEach(widget => {
      if (savedWidgetIDs.includes(widget.savedWidgetId)) {
        updatedList.push(widget)
      }
    })

    return updatedList
  }

  getAllCurrentRentalIDs(cartObj: Cart, getItems: boolean, getProductWidgets: boolean, getCartProductWidgets: boolean): string[]{
    let allIDs: string[] = [];

    if (getItems || getProductWidgets ) { // dont waste time looping through cartObj if only cart widgets are needed
      cartObj.items.forEach(item => {

        if (getItems) {
          allIDs.push(item.productId);
        }

        if (getProductWidgets && Object.prototype.hasOwnProperty.call(item, 'widgetList')) {
          item.widgetList.forEach((widget) => {
            if (widget.widgetType === 'product') {
              let element = widget.element;
              element.options.forEach((option) => { // no need to look for savedWidget (cart widget data always has element cuz snapshot of data)
                if (option.inputValue > 0 && option['productsCheckedOut']) {
                  allIDs.push(...option.productsCheckedOut)
                }
                else if (option.inputValue === 0) {
                  option['productsCheckedOut'] = [];
                }
              })
            }
          })
        }
      })
    }

    if (getCartProductWidgets && Object.prototype.hasOwnProperty.call(cartObj, 'cartWidgetList')) {
      cartObj.cartWidgetList.forEach((widget) => {
        if (widget.widgetType === 'product') {
          let element = widget.element;
          element.options.forEach((option) => { // no need to look for savedWidget (cart widget data always has element cuz snapshot of data)
            if (option.inputValue > 0 && option['productsCheckedOut']) {
              allIDs.push(...option.productsCheckedOut)
            }
            else if (option.inputValue === 0) {
              option['productsCheckedOut'] = [];
            }
          })
        }
      })
    }
    return allIDs
  }

  public resetAlgoCartQuantitesParsing(originalIDs: string[], currentIDs: string[], productsMap: { [key:string] : Product }, availabilityParsing: AvailabilityInterface): void {

    const removeIDList = originalIDs.filter(oldID => !currentIDs.includes(oldID));
    const addIDList = currentIDs.filter(newID => !originalIDs.includes(newID));

    removeIDList.forEach(id => {
      const product = productsMap[id];
      availabilityParsing.increaseCurrentAvailDecreaseCartQuantity(product.productGroupID, product.productSizeID);
    });

    addIDList.forEach(id => {
      const product = productsMap[id];
      availabilityParsing.increaseCurrentAvailDecreaseCartQuantity(product.productGroupID, product.productSizeID);
    });

    return
  }


  public updateAlgoCartQuantitiesParsing(parentID: string, sizeID: string, reverse: boolean, availabilityParsing: AvailabilityInterface): void {
    availabilityParsing.increaseCurrentAvailDecreaseCartQuantity(parentID, sizeID, reverse);
  }


  public checkAlgoCartQuantitiesParsing(productGroupID: string, sizeID: string, availabilityParsing: AvailabilityInterface): { passCheck: boolean; amountUnavail: number } {
    let passCheck = true;
    let amountUnavail = 0;
    let currentAvail: number;

    try {
        currentAvail = availabilityParsing.getGroupSizeCartQuantitiesCurrentAvail(productGroupID, sizeID);
    }
    catch (error) {
        console.error('Error getting group size cart quantities current availability:', error);
        currentAvail = 0;
    }

    // There are not enough items available
    if (currentAvail < 0) {
        passCheck = false;
        amountUnavail = Math.abs(currentAvail);
    }

    return { passCheck, amountUnavail };
  }

  checkAlgoProductQuanitites(productGroupID, sizeID, algoRes){
    let passCheck = true;
    let amountMissing = 0;
    // There is not enough items available
    if (algoRes['productWidgetTotals'][productGroupID + '_' + sizeID]['totalAvail'] - algoRes['cartQuantities'][productGroupID + '_' + sizeID]['cartQty'] < 0) {
      passCheck = false;
      amountMissing = algoRes['productWidgetTotals'][productGroupID + '_' + sizeID]['totalAvail'] - algoRes['cartQuantities'][productGroupID + '_' + sizeID]['cartQty']
    }
    return {passCheck, amountMissing}
  }


  checkAlgoProductQuantitiesParsing(productGroupID: string, sizeID: string, availabilityParsing: AvailabilityInterface): { passCheck: boolean; amountUnavail: number } {
    let passCheck = true;
    let amountUnavail = 0;
    let currentAvail: number;

    try {
        currentAvail = availabilityParsing.getProductWidgetCurrentAvail(productGroupID, sizeID);
    }
    catch (error) {
        console.error('Error getting product widget current availability:', error);
        currentAvail = 0;
    }

    if (currentAvail < 0) {
        passCheck = false;
        amountUnavail = Math.abs(currentAvail);
    }

    return { passCheck, amountUnavail };
  }

  checkCartItemsTimeslotType(items) {
    let timeslotType = items[0].timeslotType;
    let passCheck = true;

    items.forEach(item => {
      if(item.timeslotType != timeslotType){
        passCheck = false;
      }
    })

    return passCheck
  }


} // End of service

