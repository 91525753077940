import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieInteractionService {

constructor() { }

public getPaymentLinkCookie(templateID): string{
  return `fm.R.${templateID}`;
}

// Attempt to find a cookie
  public getCookie(cookieName: string): string | null {
    const cookieArray = document.cookie.split(';');
    let cookieValue = null;

    // Attempt to find an affiliate cookie that matches the name provided
    cookieLoop: for (let i = 0; i < cookieArray.length; i++) {
      let cookiePair = cookieArray[i].split('=');

      // If a cookie is found
      if (cookieName === cookiePair[0].trim()) {
        cookieValue = decodeURIComponent(cookiePair[1]) // Decode the cookie value
        break cookieLoop;
      }
    }

    if (!cookieValue) {
      return null
    }
    return cookieValue;
  }

  // Attempt to find a cookie
  public removeCookie(cookieName: string): boolean {
    try{
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      return true;
    }
    catch (err) {
      console.error('Unable to remove cookie', err);
      return false;
    }
  }
}
