import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, map } from 'rxjs';

/* Libraries */
import { DateTime } from 'luxon';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Collection } from 'src/app/v2/models/collection-reference.model';
@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(private afs: AngularFirestore, private router: Router) {}

  getCart(cartID: string): Observable<any> {
    return this.afs.collection<any>('cart').doc(cartID).snapshotChanges();
  }

  // replaceCart(cartObj, cartID){
  //   return this.afs.collection('cart').doc(cartID).set(cartObj);
  // }

  addItemsToCart(item) {
    return this.afs.collection('cart').add(item);
  }

  replaceItemsInCart(item, cartId): Promise<any> {
    return this.afs.collection('cart').doc(cartId).update({ items: item });
  }
  replaceCustomerInfoInCart(customerInfo, cartId) {
    return this.afs
      .collection('cart')
      .doc(cartId)
      .update({ customerInfo: customerInfo });
  }
  async replaceCustomerInfoInRental(customerInfo, rentalId) {
    console.log(customerInfo)
    console.log(rentalId)
    try {
      // Create object to update data
      const updateData = {
        userInfo: customerInfo,
        'cartObj.customerInfo': customerInfo
      };

      // Update rental document (only updateData obj)
      await this.afs
        .collection('rentals')
        .doc(rentalId)
        .update(updateData);

      console.log("Customer info updated successfully in userInfo and cartObj.customerInfo.");
    } catch (error) {
      console.error("Error updating customer info:", error);
      throw error; //Throwing error when is not possible to update Customer
    }
  }

  replaceCustomerInfoTestInCart(customerInfoTest, cartId) {
    return this.afs
      .collection('cart')
      .doc(cartId)
      .update({ customerInfoTest: customerInfoTest });
  }
  replacePaymentIntentInCart(paymentIntent, cartId) {
    return this.afs.collection('cart').doc(cartId).update({
      paymentIntent: paymentIntent,
    });
  }
  replacePaymentIntentTestInCart(paymentIntentTest, cartId){
    return this.afs.collection('cart').doc(cartId).update({
      paymentIntentTest: paymentIntentTest,
    });
  }



  updateCart(cartID: string, obj) {
    return this.afs.collection('cart').doc(cartID).update(obj);
  }
  addRentalIDToCart(rentalID, cartID) {
    return this.afs.collection('cart').doc(cartID).update({ rentalID: rentalID });
  }
  deleteCartAfterRental(cartID){
    return this.afs.collection('cart').doc(cartID).delete()
  }

  public updateCartItemsAndWidgets(docID: string, cartObjItems: [], cartWidgetList): Promise<void> {
    return this.afs.collection('cart').doc(docID).update({ items: cartObjItems, cartWidgetList: cartWidgetList });
  }

  updateCartWidgetList(docID, obj) {
    return this.afs.collection('cart').doc(docID).update({ cartWidgetList: obj });
  }

  addStripeTransaction(paymentID, details){
    return this.afs.collection('stripeTransactions').doc(paymentID).update(details)
  }

  async addItemsToNewCartAndReturnId(item) {
    const newItem = await this.afs.collection('cart').add(item);
    return newItem.id;
    // console.log("the new city:", newCityAdded)
    // console.log("it's id:", newCityAdded.id)
    // return this.afs.collection('cart').add(item).then(function(docRef) {
    //   console.log("Document written with ID: ", docRef.id);
    // });
  }

  async addCustomerInfoToCartAndReturnId(customerInfo) {
    const newItem = await this.afs.collection('cart').add(customerInfo);
    return newItem.id;
  }

  //Normal cart flow
  async addStripeTestToCart(cartID,isTestStripe){
    return this.afs.collection('cart').doc(cartID).update(isTestStripe)
  }
  async setSkipPayment(col: Collection.Cart | Collection.Rentals, docID: string, isTestStripe: boolean, isSkipPayment: boolean = false): Promise<void> {
    const update = {
      isSkipPayment: isSkipPayment,
      isTestStripe: isTestStripe
    };
    return this.afs.collection(col).doc(docID).update(update)
  }
  async addRentalObjToCart(cartID, rentalObj){
    console.log("que mando", rentalObj)
    return this.afs.collection('cart').doc(cartID).update(rentalObj)
  }
  //Rental update
  async addStripeTestToRental(rentalID,isTestStripe){
    return this.afs.collection('rentals').doc(rentalID).update(isTestStripe)
  }
  async addSkipPaymentToRental(rentalID,isSkipPayment){
    return this.afs.collection('rentals').doc(rentalID).update(isSkipPayment)
  }


  /* Custom Cart Specific Methods - Start */

  hasCustomCartPermission(cartObj, paymentLink: boolean = false){
    if(cartObj?.isCustomCart && !cartObj?.customCartSettings?.isModificationsAllowed || paymentLink) {
      Swal.fire({
        title: 'Cannot Modify Cart',
        text: "This cart has been locked to it's current state and cannot be modified.",
        icon: 'error',
        confirmButtonText: 'Ok'
      })
      return false
    }
    return true
  }


  cannotModifyCartSwalWarning(){
    Swal.fire({
      title: 'Cannot Modify Cart',
      text: "This cart has been locked to it's current state and cannot be modified.",
      icon: 'error',
      confirmButtonText: 'Ok'
    })
  }

  async removeCustomCartPermissions(cartObj){
    let customCartObj = cartObj;
    customCartObj.isCustomCart = false;
    Object.keys(cartObj.customCartSettings).forEach(key => { // delete all perms
      delete customCartObj.customCartSettings[key];
    })
    await this.updateCart(cartObj.id, { isCustomCart: false, customCartSettings: customCartObj.customCartSettings }) // listing keyname as a string prevents overriding entire object
  }

  customCartBtn(cartObj) {
    let SwalParams = Swal.mixin({
      position: 'center',
      showCloseButton: true,
      icon: 'info',
      title: 'Custom Cart Options',
      showCancelButton: true,
      reverseButtons: true,
    })

    let route = this.router.url
    let cartLink = environment.app.appDomainName + route + `?cart=${cartObj.id}`;

    // If cart is already a custom cart
    if(cartObj.isCustomCart){
      SwalParams.fire({
        confirmButtonText: 'Modify Custom Cart',
        showDenyButton: true,
        showCancelButton: false,
        denyButtonText: 'Convert To Normal Cart',
        footer: `<span id="myFooterButton" style="color: #6c6ccd;">Click to Copy Cart Link</span>`,
        didOpen: () => {
          const footerButton = document.getElementById('myFooterButton');
          footerButton.addEventListener('click', () => {
            this.copyTextToClipboard(cartLink);
          });
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.chooseCustomCartOptions(cartObj);
        }
        if(result.isDenied){ // Revert to normal cart
          this.removeCustomCartPermissions(cartObj)
        }
      })
    }
    // If cart is a standard cart
    else{
      SwalParams.fire({
        confirmButtonText: 'Create a Custom Cart',
        text: 'Custom carts allow logged in users the ability to create carts with additional settings and configurations. Would you like to create a custom cart?',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.chooseCustomCartOptions(cartObj);
        }
      })
    }
  }

  async chooseCustomCartOptions(cartObj){

    const {value: formValues} = await Swal.fire({
      title: 'Custom Cart Configurations',
      html: `
        <input type="checkbox" id="checkbox1" class="swal2-checkbox" checked=true>
        <label for="checkbox1">Disable Rental Modifications</label><br>
      `,
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById('checkbox1')['checked'],
        ];
      }
    })

    if(!formValues)return;

    this.createAndModifyCustomCart(cartObj, formValues);
  }
  async createAndModifyCustomCart(cartObj, customCartSettings) {
    // Update cart on server
    await this.updateCart(cartObj.id, { isCustomCart: true, 'customCartSettings.isModificationsAllowed': !customCartSettings[0] }) // listing keyname as a string prevents overriding entire object

    let route = this.router.url
    let cartLink = environment.app.appDomainName + route + `?cart=${cartObj.id}`;

    Swal.fire({
      icon: 'success',
      title: 'Custom Cart Settings Succesfully Applied!',
      html: `<span>Click the button below to copy the link to your custom cart!</span>`,
      showCloseButton: true,
      confirmButtonText: 'Copy Link',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.copyTextToClipboard(cartLink);
      }
    })
  }

  copyTextToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      Swal.fire({ toast: true, title: 'Link Copied!', icon: 'success', position: 'bottom-end', timer: 1500, timerProgressBar: true, showCloseButton: true, showConfirmButton: false, showLoaderOnConfirm: true, allowOutsideClick: () => !Swal.isLoading() })
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  }

  /* Custom Cart Specific Methods - End */

  /* Discounts */
  getStatusActiveDiscountsParamCompanyID(companyID): Observable<any> {
    return this.afs
      .collection(
        'discountCodes',
        (ref) =>
          ref
            .where('companyID', '==', companyID)
            .where('isActive', '==', true) // Active is a manual setting on the coupon code
            // .where('expiredDate', '>', Date.now()) // Expiration date must also be valid
            .where('expiredDate', '>', DateTime.now().toJSDate()) // Expiration date must also be valid
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((action) => {
            let data = action.payload.doc.data();
            data['id'] = action.payload.doc.id;
            return data;
          });
        })
      );
  }
}
