<div class="container-fluid">
  <!-- TITLE -->
  <div class="row justify-content-center mt-2">
    <div class="col-md-6 text-left">
      <h1 class="mt-2 mb-2" style="font-size: 32px">Sales Report</h1>
    </div>
    <!-- DATE RANGE PICKER -->
    <div class="col-md-6 ">
      <div class="d-flex flex-column flex-sm-row align-items-center">
        <div class="col-8">

          <span class="p-float-label">
            <input pInputText ngxDaterangepickerMd #datePicker class="py-1" inputId="float-label" type="text"
              id="dPicker" placeholder="Select a Period" [showCustomRangeLabel]="true" [showRangeLabelOnInput]="true"
              [keepCalendarOpeningWithRange]="true" [alwaysShowCalendars]="true" [locale]="locale" [autoApply]="true"
              (datesUpdated)="getReports($event)" [closeOnAutoApply]="true" [ranges]="ranges"
              [maxDate]="maxDate" (rangeClicked)="rangeClicked($event)" [(ngModel)]="selectedDates" readonly
              class="form-control form-control-sm " />
            <label for="float-label">Select a Period</label>
          </span>
        </div>


      </div>
    </div>
  </div>

  <!-- TOTALS BAR -->
  <div class="row text-center text-lg-left">
    <div class="col-xs-12 col-md-4 mb-4 d-flex flex-column flex-lg-row align-items-center justify-content-center">
      <div class="d-none d-md-flex align-items-center justify-content-center bg-blue-100 border-rounded" style="width: 6rem; height: 6rem;">
        <i class="pi pi-shopping-cart text-blue-500 icon-totals"></i>
      </div>
      <div class="d-flex flex-column align-items-center align-items-lg-start justify-content-center ml-lg-3 mt-3 mt-lg-0">
        <span class="block text-500 font-medium text-card-title mb-2 mb-lg-0">Rentals</span>
        <div *ngIf="!loading" class="text-900 font-medium text-card-totals">{{totalRentalCount}}</div>
        <span *ngIf="loading" class="loader loader-sm text-900 font-medium text-card-totals"></span>
      </div>
    </div>
    <div class="col-xs-12 col-md-4 mb-4 d-flex flex-column flex-lg-row align-items-center justify-content-center">
      <div class="d-none d-md-flex align-items-center justify-content-center bg-orange-100 border-rounded" style="width: 6rem; height: 6rem;">
        <i class="pi pi-dollar text-orange-500 icon-totals"></i>
      </div>
      <div class="d-flex flex-column align-items-center align-items-lg-start justify-content-center ml-lg-3 mt-3 mt-lg-0">
        <span class="block text-500 font-medium text-card-title mb-2 mb-lg-0">Revenue</span>
        <div *ngIf="!loading" class="text-900 font-medium text-card-totals">{{totalRentals | currency: 'USD'}}</div>
        <span *ngIf="loading" class="loader loader-sm text-900 font-medium text-card-totals"></span>
      </div>
    </div>
    <div class="col-xs-12 col-md-4 mb-4 d-flex flex-column flex-lg-row align-items-center justify-content-center">
      <div class="d-none d-md-flex align-items-center justify-content-center bg-cyan-100 border-rounded" style="width: 6rem; height: 6rem;">
        <i class="pi pi-users text-cyan-500 icon-totals"></i>
      </div>
      <div class="d-flex flex-column align-items-center align-items-lg-start justify-content-center ml-lg-3 mt-3 mt-lg-0">
        <span class="block text-500 font-medium text-card-title mb-2 mb-lg-0">Customers</span>
        <div *ngIf="!loading" class="text-900 font-medium text-card-totals">{{totalCustomers}}</div>
        <span *ngIf="loading" class="loader loader-sm text-900 font-medium text-card-totals"></span>
      </div>
    </div>
  </div>



  <div class="row">
    <!-- BEST SELLERS -->
    <div class="col-xs-12 col-lg-4 mb-5">
      <div class="card">
        <div class="flex justify-content-center align-items-center">
          <h5>Best Selling Products</h5>

        </div>
        <table class="table table-borderless" style="font-size: small">
          <tbody>
            <tr *ngFor="let bsp of bestSProducts.slice(0, 10); let i = index" class="product-text" (click)="op.toggle($event)">
              <td>
                <span class="text-900  mr-2 mb-1 md:mb-0 ">{{bsp.product}}</span>
                <div class="mt-1 text-600">{{bsp.totalValue | currency: 'USD'}}<small> ({{bsp.rentalCount}})</small>
                </div>
              </td>
              <td>
                <p-overlayPanel #op>
                  <span class="text-900 font-medium  mr-2 mb-5 md:mb-0 ">{{bsp.product}}</span>
                  <table class="table table-borderless">
                    <tbody>
                      <tr *ngFor="let size of bsp.size; let j = index">
                        <td>
                          <span class="text-900 font-medium text-right" (mouseenter)="op.toggle($event)"
                            (mouseleave)="op.toggle($event)">
                            {{size.size}}
                          </span>
                        </td>
                        <td>
                          <span class="text-600 text-left">{{size.qty}}</span>
                        </td>
                        <td class="align-middle">
                          <div class="surface-300 border-round overflow-hidden w-10rem lg-w-6rem"
                            [ngStyle]="{height: '8px'}">
                            <div class="h-full" [ngStyle]="{width: ((100 * size.qty) / bsp.rentalCount) + '%'}"
                              [ngClass]="barColors[j].bg"></div>
                          </div>
                        </td>
                        <td class="align-middle">
                          <span class="font-medium" [ngClass]="barColors[j].text">
                            {{((100 * size.qty) / bsp.rentalCount) | number : '1.2-2'}} %
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </p-overlayPanel>
              </td>
              <td class="align-middle">
                <div class="surface-300 border-round overflow-hidden w-5rem lg-w-5rem" [ngStyle]="{height: '8px'}">
                  <div class="h-full" [ngStyle]="{width: ((100 * bsp.rentalCount) / totalPRentalCount) + '%'}"
                    [ngClass]="barColors[i].bg"></div>
                </div>
              </td>
              <td class="align-middle">
                <span class="font-medium" [ngClass]="barColors[i].text">{{((100 * bsp.rentalCount) /
                  totalPRentalCount) | number : '1.2-2' }} % </span>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="align-items-center justify-content-center">
          <span *ngIf="loading" class="loader loader-md text-900 font-medium text-card-totals mb-"></span>
        </div>
      </div>
    </div>
    <!-- GRAPH -->
    <div class="col-xs-12 col-lg-8 mb-5">
      <div class="card flex justify-content-center">
        <h5>Sales Information</h5>
        <span *ngIf="loading" class="loader loader-lg text-900 font-medium text-card-totals mb-"></span>
        <p-chart *ngIf="!loading" [type]="chartType" [data]="chartLineData" [options]="chartLineOptions"></p-chart>
      </div>

    </div>
  </div>
  <!-- TABLES -->
  <div class="row" *ngIf="!loading">
    <div class="col-xs-12 col-lg-4 mb-5" *ngFor="let r of records; let i = index">
      <div class="card">
        <h5><strong>{{r.monthName}}</strong> </h5>
        <p-table [value]="r.records" [styleClass]="'p-datatable-sm'" styleClass="p-datatable-gridlines"
          [scrollable]="true" scrollHeight="400px" [globalFilterFields]="['rentalCount']" [sortField]="'rentalCount'"
          [sortOrder]="-1" (sortFunction)="customSort($event)" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th>Image</th>
              <th>Product </th>
              <th pSortableColumn="rentalCount">Rental Count <p-sortIcon field="rentalCount"></p-sortIcon></th>
              <th pSortableColumn="totalValue">Total <p-sortIcon field="totalValue"></p-sortIcon></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product let-index>
            <tr>
              <td>
                <img src="{{product.imageUrl}}" class="shadow-4" alt="{{product.product}}" width="50">
              </td>
              <td>{{product.product}}</td>
              <td>{{product.rentalCount}}</td>
              <td>{{product.totalValue | currency:'USD'}}</td>

            </tr>
          </ng-template>

        </p-table>
      </div>
    </div>

  </div>
</div>
