
<div class="container-fluid">
  <!-- TITLE -->
  <div class="row justify-content-center mt-2">
    <div class="col-md-6 text-left">
      <h1 class="mt-2 mb-2" style="font-size: 32px">Tax Report</h1>
    </div>
    <!-- DATE RANGE PICKER -->
    <div class="col-md-6 ">
      <div class="d-flex flex-column flex-sm-row align-items-center">
        <div class="col-12">

          <span class="p-float-label">
            <input pInputText ngxDaterangepickerMd #datePicker class="py-1" inputId="float-label" type="text"
              id="dPicker" placeholder="Select a Period" [showCustomRangeLabel]="true" [showRangeLabelOnInput]="true"
              [singleDatePicker]="false" [keepCalendarOpeningWithRange]="true" [alwaysShowCalendars]="true"
              [locale]="locale" [autoApply]="true" (datesUpdated)="getReports($event)" [closeOnAutoApply]="true"
              [ranges]="ranges" [minDate]="minDate" [maxDate]="maxDate" [(ngModel)]="selectedDates" readonly
              class="form-control form-control-sm " />
            <label for="float-label">Select a Period</label>
          </span>
        </div>
      </div>
    </div>
  </div>

  <!-- TOTALS BAR -->
  <div class="row text-center text-lg-left">
    <div class="col-xs-12 col-md-4 mb-4 d-flex flex-column flex-lg-row align-items-center justify-content-center">
      <div class="d-none d-md-flex align-items-center justify-content-center bg-blue-100 border-rounded" style="width: 6rem; height: 6rem;">
        <i class="pi pi-dollar text-blue-500 icon-totals"></i>
      </div>
      <div class="d-flex flex-column align-items-center align-items-lg-start justify-content-center ml-lg-3 mt-3 mt-lg-0">
        <span class="block text-500 font-medium text-card-title mb-2 mb-lg-0">Subtotal</span>
        <div *ngIf="!loading" class="text-900 font-medium text-card-totals">{{subTotal | currency: 'USD'}}</div>
        <span *ngIf="loading" class="loader loader-sm text-900 font-medium text-card-totals"></span>
      </div>
    </div>
    <div class="col-xs-12 col-md-4 mb-4 d-flex flex-column flex-lg-row align-items-center justify-content-center">
      <div class="d-none d-md-flex align-items-center justify-content-center bg-orange-100 border-rounded" style="width: 6rem; height: 6rem;">
        <i class="pi pi-dollar text-orange-500 icon-totals"></i>
      </div>
      <div class="d-flex flex-column align-items-center align-items-lg-start justify-content-center ml-lg-3 mt-3 mt-lg-0">
        <span class="block text-500 font-medium text-card-title mb-2 mb-lg-0">Taxes &amp; Fees</span>
        <div *ngIf="!loading" class="text-900 font-medium text-card-totals">{{taxesAndFees | currency: 'USD'}}</div>
        <span *ngIf="loading" class="loader loader-sm text-900 font-medium text-card-totals"></span>
      </div>
    </div>
    <div class="col-xs-12 col-md-4 mb-4 d-flex flex-column flex-lg-row align-items-center justify-content-center">
      <div class="d-none d-md-flex align-items-center justify-content-center bg-cyan-100 border-rounded" style="width: 6rem; height: 6rem;">
        <i class="pi pi-dollar text-cyan-500 icon-totals"></i>
      </div>
      <div class="d-flex flex-column align-items-center align-items-lg-start justify-content-center ml-lg-3 mt-3 mt-lg-0">
        <span class="block text-500 font-medium text-card-title mb-2 mb-lg-0">Taxes</span>
        <div *ngIf="!loading" class="text-900 font-medium text-card-totals">{{taxes | currency: 'USD'}}</div>
        <span *ngIf="loading" class="loader loader-sm text-900 font-medium text-card-totals"></span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="card">
      <span *ngIf="loading" class="loader loader-lg text-900 font-medium text-card-totals mb-"></span>

      <p-table *ngIf="!loading" [value]="rentals" #dt [columns]="cols" [styleClass]="'p-datatable-sm'"
        styleClass="p-datatable-gridlines" [scrollable]="true" scrollHeight="70vh" responsiveLayout="scroll"
        [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="caption">
          <div class="flex">
            <!--
            <button type="p-button-info" pButton pRipple icon="pi pi-file" (click)="dt.exportCSV()" class="mr-2"
              pTooltip="CSV" tooltipPosition="bottom"></button>
            <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()"
              class="p-button-danger mr-2" pTooltip="PDF" tooltipPosition="bottom"></button> -->
            <button type="button" pButton pRipple icon="pi pi-file" (click)="generateRentalDetails()"
              class="p-button-success ml-auto download-btn" pTooltip="CSV" tooltipPosition="bottom">&nbsp; Download CSV</button>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">
              {{ col.header }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-r let-index>
          <tr>
            <td><a href="partner/bookings/{{ r.rentalID }}">{{r.rentalNumber}}</a></td>
            <td>{{r.name}}</td>
            <td>{{r.reservation}}</td>
            <td>{{r.subTotal | currency: 'USD'}}</td>
            <td>{{r.taxesAndFees | currency: 'USD'}}</td>
            <td>{{r.taxes | currency: 'USD'}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
