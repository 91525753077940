import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import dayjs, { Dayjs } from 'dayjs/esm';
import * as moment from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { SortEvent } from 'primeng/api';
import { DatesSelected } from 'src/app/models/rental.model';
import {
  ProductMonthR,
  ProductToday,
  RentalsByTime,
  SalesByMonthProduct,
} from 'src/app/models/report-sales.model';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { ProductsService } from 'src/app/services/products.service';
import { RentalService } from 'src/app/services/rental.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-big-sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.scss'],
})
export class SalesReportComponent implements OnInit {
  // Chart Variables
  public chartLineData: any;
  public chartLineOptions: any;
  public chartPolarData: any;
  public chartPolarOptions: any;
  public isAllYearChart: boolean = true;
  public isTodayChart: boolean = false;
  public chartType: string = 'line';
  public isWeekFormat: boolean = false;

  // Rentals Variables
  public rentals: RentalsByTime[] = [];
  public totalRentalCount: number = 0;
  public totalRentals: number = 0;
  public totalCustomers: number = 0;
  public rentalCountChanged: boolean = false;

  // Products Variables
  public records: SalesByMonthProduct[] = [];
  public totalPRentalCount: number = 0;
  public totalPRentals: number = 0;
  public bestSProducts: ProductMonthR[] = [];

  // User and year-related variables
  private user = null;
  private firstYear: string = '';
  private firstMonth: number = 0;
  private firstDate: Dayjs;

  // Dates Variables
  public selectedDates: any;
  public ranges: any = {
    'Year to Date': [moment().startOf('year'), moment().endOf('day')],
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
    'Last Year': [
      moment().subtract(1, 'year').startOf('year'),
      moment().subtract(1, 'year').endOf('year'),
    ],
  };
  public locale: LocaleConfig = {
    format: 'MM-DD-YYYY',
    displayFormat: 'MM-DD-YYYY',
    separator: ' - ',
    cancelLabel: 'Cancel',
    applyLabel: 'Okay',
  };
  public maxDate: Dayjs;
  public minDate: Dayjs;

  // Utils
  public loading: boolean = false;
  public barColors: any[] = [
    { bg: 'bg-orange-500', text: 'text-orange-500' },
    { bg: 'bg-cyan-500', text: 'text-cyan-500' },
    { bg: 'bg-pink-500', text: 'text-pink-500' },
    { bg: 'bg-green-500', text: 'text-green-500' },
    { bg: 'bg-purple-500', text: 'text-purple-500' },
    { bg: 'bg-teal-500', text: 'text-teal-500' },
    { bg: 'bg-blue-500', text: 'text-blue-500' },
    { bg: 'bg-indigo-500', text: 'text-indigo-500' },
    { bg: 'bg-yellow-500', text: 'text-yellow-500' },
    { bg: 'bg-red-500', text: 'text-red-500' },
  ];

  constructor(
    private $afFun: AngularFireFunctions,
    private _productS: ProductsService,
    private _currentUserS: CurrentUserService,
    private _rentalS: RentalService
  ) {
    // Define the template for quarters with command functions

    this.selectedDates = {
      startDate: moment().startOf('year'),
      endDate: moment().endOf('day'),
    };
  }

  ngOnInit(): void {
    this.initializeUserAndDates().then(() => {
      this.maxDate = dayjs();
      this.minDate = this.firstDate;
    });
  }

  // This function retrieves reports based on user input (selected year and quarter).
  public async getReports(range: {startDate?: Dayjs, endDate?: Dayjs}): Promise<void> {
    const baseRequestFormat = '%Y-%m';
    const format = 'YYYY-MM-DD HH:MM:ss'
    const start: Dayjs = range?.startDate || dayjs().startOf('year')
    const end: Dayjs = range?.endDate || dayjs().endOf('day')

    if (
      start == dayjs().startOf('year') ||
      start == dayjs().subtract(1, 'year').startOf('year')
    ) {
      this.isAllYearChart = true;
    } else {
      this.isAllYearChart = false;
    }

    const data = {
      companyId: this.user.companyId,
      dateStart: start
        ? start.format(format)
        : start.startOf('year').format(format),
      dateEnd: end
        ? end.format(format)
        : end.endOf('day').format(format),
      format: start.isSame(end.toDate(), 'day')
        ? `${baseRequestFormat}-%d %H:%M:%S` // If the start and end date is the same day, add day, hour, and minute format
        : end.diff(start, 'months') < 3
        ? `${baseRequestFormat}-%d` // If the start and end date is less than a month, use the base format with days
        : end.diff(start, 'months') >= 3
        ? `${baseRequestFormat}` // If the range is greater than 1 month, use the base format
        : baseRequestFormat, // By default, it uses the month format
    };
    const diff = end.diff(start, 'months');

    console.log('getReports', {
      range,
      data,
      diff,
    });

    this.isWeekFormat = diff <= 3 && diff >= 1;

    await Promise.all([
      this.getProductsByMonth(data),
      this.getSalesByMonth(data),
    ]);
  }

  // Custom sorting logic for tables
  public customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

  public rangeClicked(range): void {
    if (
      (range.label && range.label == 'Year to Date') ||
      range.label == 'Last Year'
    ) {
      this.isAllYearChart = true;
      this.isTodayChart = false;
    } else if (range.label && range.label == 'Today') {
      this.isAllYearChart = false;
      this.isTodayChart = true;
    } else {
      this.isAllYearChart = false;
      this.isTodayChart = false;
    }
  }

  public returnWeekString(
    numberWeek,
    year = this.selectedDates.startDate.year()
  ) {
    if (!this.isWeekFormat) {
      return numberWeek;
    }
    return (
      moment()
        .year(year)
        .isoWeek(numberWeek)
        .startOf('isoWeek')
        .format('YYYY-MM-DD') +
      ' - ' +
      moment()
        .year(year)
        .isoWeek(numberWeek)
        .endOf('isoWeek')
        .format('YYYY-MM-DD')
    );
  }

  // This function creates data for the chart displaying rentals and customers.
  private createChartData(
    rentals: any[],
    documentStyle: CSSStyleDeclaration
  ): any {
    const times = rentals.map((r) => r.time);

    const {
      filledTimes,
      filledRentalsCount,
      filledRentalsCustomers,
      filledRentalsTotal,
    } = this.fillTimeGaps(rentals, times);

    return {
      labels: filledTimes,
      datasets: [
        {
          label: 'Rentals',
          data: filledRentalsCount,
          fill: false,
          backgroundColor: documentStyle.getPropertyValue('--blue-500'),
          borderColor: documentStyle.getPropertyValue('--blue-500'),
          tension: 0.4,
          hidden: true,
        },
        {
          label: 'Customers',
          data: filledRentalsCustomers,
          fill: false,
          backgroundColor: documentStyle.getPropertyValue('--cyan-500'),
          borderColor: documentStyle.getPropertyValue('--cyan-500'),
          tension: 0.4,
          hidden: true,
        },
        {
          label: 'Revenue',
          data: filledRentalsTotal,
          fill: false,
          backgroundColor: documentStyle.getPropertyValue('--orange-500'),
          borderColor: documentStyle.getPropertyValue('--orange-500'),
          tension: 0.4,
          hidden: false,
        },
      ],
    };
  }

  private createChartOptions(documentStyle: CSSStyleDeclaration): any {
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary'
    );
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    return {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };
  }

  private fillTimeGaps(rentals, times) {
    const filledTimes = [];
    const filledRentalsCount = [];
    const filledRentalsCustomers = [];
    const filledRentalsTotal = [];

    for (let i = 0; i < times.length - 1; i++) {
      filledTimes.push(this.returnWeekString(times[i]));

      const currentRentals = rentals.find((r) => r.time === times[i]);
      if (currentRentals) {
        filledRentalsCount.push(currentRentals.rentals);
        filledRentalsCustomers.push(currentRentals.customers);
        filledRentalsTotal.push(currentRentals.total);
      } else {
        filledRentalsCount.push(0);
        filledRentalsCustomers.push(0);
        filledRentalsTotal.push(0);
      }

      const isDayFormat = times[i].match(/^\d{2}:\d{2}$/);
      const currentYear = this.selectedDates.startDate.year() + '-';

      const currentMoment = moment(
        isDayFormat
          ? this.selectedDates.startDate.format('YYYY-MM-DD ') + times[i]
          : this.isWeekFormat
          ? this.convertWeekNumberToDate(times[i], Number(currentYear))
              .startDate
          : currentYear + times[i]
      );
      const nextMoment = moment(
        isDayFormat
          ? this.selectedDates.startDate.format('YYYY-MM-DD ') + times[i + 1]
          : this.isWeekFormat
          ? this.convertWeekNumberToDate(times[i + 1], Number(currentYear))
              .startDate
          : currentYear + times[i + 1]
      );

      const duration = moment.duration(nextMoment.diff(currentMoment));
      const timeUnit = this.getLargestTimeUnit(duration);

      // Check the largest time unit difference and add accordingly
      for (let j = 1; j < duration.as(timeUnit); j++) {
        const newTime = currentMoment
          .clone()
          .add(j, timeUnit)
          .format(this.getFormatString(timeUnit));

        const isNewTimeReady = times.find((t) => t == newTime);

        if (!isNewTimeReady) {
          filledTimes.push(this.returnWeekString(newTime));
          filledRentalsCount.push(0);
          filledRentalsCustomers.push(0);
          filledRentalsTotal.push(0);
        }
      }
    }

    filledTimes.push(this.returnWeekString(times[times.length - 1]));
    const lastRentals = rentals.find((r) => r.time === times[times.length - 1]);
    if (lastRentals) {
      filledRentalsCount.push(lastRentals.rentals);
      filledRentalsCustomers.push(lastRentals.customers);
      filledRentalsTotal.push(lastRentals.total);
    } else {
      filledRentalsCount.push(0);
      filledRentalsCustomers.push(0);
      filledRentalsTotal.push(0);
    }

    return {
      filledTimes,
      filledRentalsCount,
      filledRentalsCustomers,
      filledRentalsTotal,
    };
  }

  private getLargestTimeUnit(duration) {
    console.log(
      'durations',
      this.isWeekFormat,
      duration.asWeeks(),
      duration.asMonths()
    );
    if (this.isWeekFormat) {
      return 'weeks';
    } else if (duration.asWeeks() >= 4) {
      return 'months';
    } else if (duration.asDays() >= 1) {
      return 'days';
    } else {
      return 'hours';
    }
  }

  private getFormatString(timeUnit) {
    if (timeUnit === 'months') {
      return 'MMMM';
    } else if (timeUnit === 'days') {
      return 'MM-DD';
    } else if (timeUnit === 'weeks') {
      return 'WW';
    } else {
      return 'HH:mm';
    }
  }
  // This function initializes the chart based on user selections.
  private initChart(): void {
    const documentStyle = getComputedStyle(document.documentElement);
    this.chartLineData = this.createChartData(this.rentals, documentStyle);
    this.chartLineOptions = this.createChartOptions(documentStyle);

    this.rentals.length <= 1
      ? (this.chartType = 'bar')
      : (this.chartType = 'line');
  }

  // Fetch product report for a specific year and date range
  private async getProductsByMonth(data): Promise<void> {
    try {
      this.loading = true;
      console.log('Loading started...'); // Registro de inicio de carga

      const res = await this.$afFun
        .httpsCallable('getSalesByMonthProduct')(data)
        .toPromise();
      console.log('Received response from getSalesByMonthProduct:', res); // Registro de la respuesta recibida

      if (!res) {
        throw new Error('Something went wrong!');
      }

      this.fetchAndGroupProductSalesData(res);

      console.log('Records after fetching and grouping:', this.records); // Registro de registros después del procesamiento
    } catch (error) {
      console.error('Error:', error); // Registro de errores
      this.handleError(error);
    } finally {
      this.loading = false;
      console.log('Loading completed.'); // Registro de finalización de carga
    }
  }

  // Fetch sales report for a specific year and date range
  private async getSalesByMonth(data): Promise<void> {
    try {
      this.loading = true;
      const res = await this.$afFun
        .httpsCallable('getSalesByMonth')(data)
        .toPromise();

      if (!res) {
        throw new Error('Something went wrong!');
      }

      this.rentals = await this.fetchAndGroupSalesData(res);
      this.initChart();
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
      Swal.close();
    }
  }

  // This function starts a counter animation for updating total rental counts, total rentals, or total customers.
  private startCounterAnimation(
    startValue: number,
    newValue: number,
    option: number
  ): void {
    const increment = 1;
    const duration = 300;
    const steps = duration / increment;
    let step = 0;

    const interval = setInterval(() => {
      step++;
      const progress = step / steps;
      switch (option) {
        case 0:
          this.totalRentalCount = Math.round(
            startValue + (newValue - startValue) * progress
          );
          if (step >= steps) {
            this.totalRentalCount = newValue;
            clearInterval(interval); // Stops animation
          }
          break;
        case 1:
          this.totalRentals = Math.round(
            startValue + (newValue - startValue) * progress
          );
          if (step >= steps) {
            this.totalRentals = newValue;
            clearInterval(interval);
          }
          break;
        case 2:
          this.totalCustomers = Math.round(
            startValue + (newValue - startValue) * progress
          );
          if (step >= steps) {
            this.totalCustomers = newValue;
            clearInterval(interval);
          }
      }
    }, increment);
  }

  // Get the date of the first sale for a given company ID
  private async getFirstSale(companyID: string): Promise<Date> {
    let date = new Date();
    await this._rentalS.getFirstSale(companyID).then((rental: any) => {
      date = new Date(rental[0].dayStart.seconds * 1000);
    });
    return date;
  }

  private async getTodayStats(): Promise<RentalsByTime[]> {
    const dates: DatesSelected = {
      dateStart: moment().startOf('day').toDate(),
      dateEnd: moment().endOf('day').toDate(),
    };

    return this._rentalS
      .getTodayRentals(dates, this.user.companyId)
      .then((rentals) => {
        if (rentals) {
          const timeMap: Record<string, RentalsByTime> = {};

          rentals.forEach((rental) => {
            const rentalDate = moment(rental.dayStart.seconds * 1000);

            if (rentalDate.isValid()) {
              const timeKey = rentalDate.format('HH:mm');

              if (!timeMap[timeKey]) {
                timeMap[timeKey] = {
                  time: timeKey,
                  rentals: 0,
                  total: 0,
                  customers: 0,
                };
              }

              timeMap[timeKey].rentals += 1; // Increment rental count
              timeMap[timeKey].total += rental.cost; // Add to total sales
              timeMap[timeKey].customers += 1; // Increment customer count
            } else {
              throw new Error('Unsupported date format.');
            }
          });

          const timeStats = Object.values(timeMap) as RentalsByTime[];
          return timeStats;
        }

        return null;
      });
  }

  private async getTodayProducts(): Promise<SalesByMonthProduct[]> {
    const dates: DatesSelected = {
      dateStart: moment().startOf('day').toDate(),
      dateEnd: moment().endOf('day').toDate(),
    };

    const productMonthRs: ProductToday[] =
      (await this._rentalS.getTodayProducts(dates, this.user.companyId)) || [];

    // Use a map to group the products by month
    const monthMap = new Map<string, SalesByMonthProduct>();

    for (const productMonthR of productMonthRs) {
      const productName = productMonthR.product;
      const month = productMonthR.month;

      if (!monthMap.has(month)) {
        // Initialize a new SalesByMonthProduct object for the month
        monthMap.set(month, {
          month: moment(month, 'YYYY-MM').month(),
          monthName: moment(month, 'YYYY-MM').format('MMMM'),
          monthSortName: moment(month, 'YYYY-MM').format('MMM'),
          records: [],
        });
      }

      const rentalCount = productMonthR.rentalCount;

      // Calculate the totalValue and rentalCount for the month
      const totalValue = productMonthR.totalValue;
      const prods = await this._productS.getProductByName(productName);

      // Check if the product already exists in the records for the same month
      const existingRecordIndex = monthMap
        .get(month)
        .records.findIndex((record) => record.product === productName);

      if (existingRecordIndex !== -1) {
        // If the product already exists, update rentalCount and totalValue
        monthMap.get(month).records[existingRecordIndex].rentalCount +=
          rentalCount;
        monthMap.get(month).records[existingRecordIndex].totalValue +=
          totalValue;
      } else {
        // If the product does not exist, add it to the records
        monthMap.get(month).records.push({
          imageUrl: prods[0].imageUrl,
          product: productName,
          rentalCount,
          totalValue,
          month,
          size: [],
        });
      }
      // Calculate the totalPRentalCount and totalPRentals for all months
      this.totalPRentalCount += rentalCount;
      this.totalPRentals += totalValue;
    }
    // Convert the map values to an array of SalesByMonthProduct
    const sales: SalesByMonthProduct[] = Array.from(monthMap.values());

    // Calculate the best SProducts based on the aggregated data
    this.bestSProducts = this.calculateBestSProducts(productMonthRs);

    return sales;
  }

  // Initialize the user and quarters based on the user's data and current date
  private async initializeUserAndDates(): Promise<void> {
    this.user = this._currentUserS.currentUser;
    await this.getFirstSale(this.user.companyId).then((fs) => {
      const first = dayjs(fs)
      this.firstDate = first;
      this.firstYear = first.format('YYYY');
      this.firstMonth = first.month();
    });
  }

  // This function fetches and groups product sales data by month.
  private async fetchAndGroupProductSalesData(prodSalesData) {
    let monthMap = {};
    this.totalPRentalCount = 0;
    this.totalPRentals = 0;
    this.bestSProducts = [];

    if (!this.isTodayChart) {
      const data = await Promise.all(
        prodSalesData.map(async (r) => {
          // Clonar el objeto para evitar modificar el original
          const clonedRecord = { ...r };

          const prod = await this._productS.getProductByName(
            clonedRecord.product
          );
          if (prod[0]) {
            clonedRecord.imageUrl = prod[0].imageUrl;
          }
          this.totalPRentalCount += clonedRecord.rentalCount;
          this.totalPRentals += clonedRecord.totalValue;
          const date = moment(clonedRecord.month, 'YYYY-MM');
          const month = date.month();
          if (!monthMap.hasOwnProperty(month)) {
            monthMap[month] = {
              month,
              monthName: date.format('MMMM'),
              monthSortName: date.format('MMM'),
              records: [],
            };
          } else {
            const existingRecordIndex = monthMap[month].records.findIndex(
              (record) => record.product === clonedRecord.product
            );
            if (existingRecordIndex === -1) {
              monthMap[month].records.push(clonedRecord);
            } else {
              monthMap[month].records[existingRecordIndex].rentalCount +=
                clonedRecord.rentalCount;
              monthMap[month].records[existingRecordIndex].totalValue +=
                clonedRecord.totalValue;
            }
          }
          return clonedRecord;
        })
      );
      this.bestSProducts = this.calculateBestSProducts(prodSalesData);
    } else {
      monthMap = await this.getTodayProducts();
    }
    this.records = Object.values(monthMap) as SalesByMonthProduct[];
  }
  // This function calculates the best selling products based on sales data.
  private calculateBestSProducts(salesData): ProductMonthR[] {
    const bestSProducts: ProductMonthR[] = [];

    salesData.forEach((r) => {
      let prodIndex = bestSProducts.findIndex(
        (bsp) => bsp.product === r.product
      );
      if (prodIndex < 0) {
        bestSProducts.push({
          product: r.product,
          rentalCount: r.rentalCount,
          totalValue: r.totalValue,
          size: [{ size: r.size, qty: r.rentalCount }],
        });
      } else {
        bestSProducts[prodIndex].rentalCount += r.rentalCount;
        bestSProducts[prodIndex].totalValue += r.totalValue;

        // Verificar si el tamaño ya existe en el objeto "size"
        const sizeIndex = bestSProducts[prodIndex].size.findIndex(
          (sizeObj) => sizeObj.size === r.size
        );

        if (sizeIndex < 0) {
          bestSProducts[prodIndex].size.push({
            size: r.size,
            qty: r.rentalCount,
          });
        } else {
          bestSProducts[prodIndex].size[sizeIndex].qty += r.rentalCount;
        }

        // Ordenar la propiedad "size" por cantidad (qty) de mayor a menor
        bestSProducts[prodIndex].size = bestSProducts[prodIndex].size.sort(
          (a, b) => b.qty - a.qty
        );
      }
    });

    // Sort the array
    bestSProducts.sort((a, b) => b.rentalCount - a.rentalCount);
    return bestSProducts.slice(0, 10);
  }

  // This function fetches and groups sales data by month.
  private async fetchAndGroupSalesData(salesData): Promise<RentalsByTime[]> {
    let timeMap = {};

    let totalRentalCount = 0;
    let totalRentals = 0;
    let totalCustomers = 0;

    if (!this.isTodayChart) {
      await Promise.all(
        salesData.map(async (r) => {
          totalRentalCount += r.rentals;
          totalRentals += r.total;
          totalCustomers += r.customers;

          let timeKey = null;
          // Detect the format of the date field and group accordingly
          if (r.date.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)) {
            // If the date format is YYYY-MM-DD HH:mm:ss, group by hour
            const date = moment(r.date, 'YYYY-MM-DD HH:mm');
            timeKey = date.format('HH:mm');
          } else if (r.date.match(/^\d{4}-\d{2}-\d{2}$/)) {
            // If the date format is YYYY-MM-DD, group by day
            const date = moment(r.date, 'YYYY-MM-DD');
            timeKey = this.isWeekFormat
              ? date.format('WW')
              : date.format('MM-DD');
          } else if (r.date.match(/^\d{4}-\d{2}$/)) {
            // If the date format is YYYY-MM, group by month
            const date = moment(r.date, 'YYYY-MM');
            timeKey = date.format('MMMM');
          } else {
            // Handle unsupported date format
            throw new Error('Unsupported date format: ' + r.date);
          }

          if (!timeMap[timeKey]) {
            timeMap[timeKey] = {
              time: timeKey,
              rentals: 0,
              total: 0,
              customers: 0,
              isWeekFormat: this.isWeekFormat,
            };
          }

          timeMap[timeKey].rentals += r.rentals;
          timeMap[timeKey].total += r.total;
          timeMap[timeKey].customers += r.customers;

          return r;
        })
      );
    } else {
      timeMap = await this.getTodayStats();
      if (!timeMap) {
        this.startCounterAnimation(this.totalRentalCount, totalRentalCount, 0);
        this.startCounterAnimation(this.totalRentals, totalRentals, 1);
        this.startCounterAnimation(this.totalCustomers, totalCustomers, 2);
        return [];
      }
      totalRentalCount = timeMap[0].rentals || 0;
      totalCustomers = timeMap[0].customers || 0;
      totalRentals = timeMap[0].total || 0;
    }

    const timeObjects = Object.values(timeMap) as RentalsByTime[];

    this.startCounterAnimation(this.totalRentalCount, totalRentalCount, 0);
    this.startCounterAnimation(this.totalRentals, totalRentals, 1);
    this.startCounterAnimation(this.totalCustomers, totalCustomers, 2);

    return timeObjects;
  }
  private convertWeekNumberToDate(weekNumber: number, year: number) {
    const dates = {
      startDate: moment()
        .year(year)
        .isoWeek(weekNumber)
        .startOf('isoWeek')
        .format('YYYY-MM-DD'),
      endDate: moment()
        .year(year)
        .isoWeek(weekNumber)
        .endOf('isoWeek')
        .format('YYYY-MM-DD'),
    };
    return dates;
  }

  // This function handles errors and displays an error message.
  private handleError(error) {
    this.showErrorMessage('Oops...', error.message || 'Something went wrong!');
    console.error(error);
  }

  // Show an error message using Swal (SweetAlert2) library
  private showErrorMessage(title: string, text: string): void {
    Swal.fire({
      icon: 'error',
      title: title,
      text: text,
    });
  }
}
