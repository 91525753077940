// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Components
import { SalesReportComponent } from './sales-report/sales-report.component';


// Prime NG
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ChartModule } from 'primeng/chart';
import { MenuModule } from 'primeng/menu';
import { StyleClassModule } from 'primeng/styleclass';
import { PanelMenuModule } from 'primeng/panelmenu';
import { OverlayPanelModule } from 'primeng/overlaypanel';

//Utils
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { TaxReportComponent } from './tax-report/tax-report.component';


@NgModule({
  declarations: [
    SalesReportComponent,
    TaxReportComponent
  ],
  imports: [
    CommonModule,
    // PrimeNG
    TableModule,
    InputTextModule,
    ButtonModule,
    SplitButtonModule,
    ChartModule,
    MenuModule,
    StyleClassModule,
    PanelMenuModule,
    OverlayPanelModule,
    // Utils
    NgxDaterangepickerMd,
    FormsModule,



  ],
})
export class BigqReportsModule {}
