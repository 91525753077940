  /*** @description - The purpose of this service is to take care of most complex logic and shared methods
   * pertaining to the backend bookings (mini cart, quick booking rental creation, and date changes).
   * Components that use this service include the bookings component, bookings-upload2 component, and the
   * bookings-products-table2 component.
   */

import { Injectable } from '@angular/core';
import { PricingService } from './pricing.service';
import { RentalService } from './rental.service';
import { TimeService } from './time.service';
import { AvailabilityService } from './availability.service';
import { DateTime } from 'luxon';


@Injectable({
  providedIn: 'root'
})
export class BackendBookingsService {

  constructor(
    private pricingService: PricingService,
    private rentalService: RentalService,
    private availabilityService: AvailabilityService,
    private timeService: TimeService
  ) { }

  //--------------- Bookings Date Range Filtering ----------------//

  getUniqueCartItemsCount(cartObj){
    let cartQuantities = {};
    cartObj.items.forEach((item) => {
        // Add cart quantity for cartItems
        if (cartQuantities[item.parentId + '_' + item.productSizeID]) { // if has product size ID on item (means it's a newer cart)
          cartQuantities[item.parentId + '_' + item.productSizeID];
          cartQuantities[item.parentId + '_' + item.productSizeID].originalProductIDs.push(item.productId)
        }
        else {
          cartQuantities[item.parentId + '_' + item.productSizeID] = {originalProductIDs: [item.productId], originalProductWidgetIDs: [], productGroupID: item.parentId, productSizeID: item.productSizeID, timeslotOverlap:{}};
        }

        // Add cart quantity for selected product widgets
        item.widgetList.forEach((widget) => {
          if (widget.widgetType === 'product') {
            let element = widget.element;
            element.options.forEach((option) => { // no need to look for savedWidget (cart widget data always has element cuz snapshot of data)
              if (option.inputValue > 0 && option['productsCheckedOut']){
                cartQuantities[item.parentId + '_' + item.productSizeID].originalProductWidgetIDs.push(...option['productsCheckedOut'])
              }
            })
          }
        })
    })
    return cartQuantities
  }


  getOverlapTimeslotsForProductGroupSize(sortedList, algoRes){
    let arraySortedList = []
    let timesAreAvail = true;

    Object.values(sortedList).forEach(listItem => {

      // loop through all the product groups and sizes and get the available timeslots
      // Gets all the available time slot and overlap timeslot for a product group and size
      Object.keys(algoRes.resultSet).forEach(groupID => {
        if(groupID == listItem['productGroupID']){
          Object.values(algoRes.resultSet[groupID]['products']).forEach(product => {
            if(product['productSizeID'] == listItem['productSizeID']){
              product['availTimeslots'].forEach(slot => {
                if(!slot['unavailable']){

                  let newKey = (slot['dayStart']).toISO() + '_' + (slot['dayEnd']).toISO();
                  if(sortedList[groupID + '_' + product['productSizeID']]['timeslotOverlap'][newKey]){
                    sortedList[groupID + '_' + product['productSizeID']]['timeslotOverlap'][newKey].availProductIDs.push(product['productID']);

                  }
                  else{
                    let newItem = {slot, availProductIDs: [product['productID']], unavailableIDs: [], originalProductIDs: sortedList[groupID + '_' + product['productSizeID']]['originalProductIDs'], originalProductWidgetIDs: sortedList[groupID + '_' + product['productSizeID']]['originalProductWidgetIDs']};
                    sortedList[groupID + '_' + product['productSizeID']]['timeslotOverlap'][newKey] = slot;
                    sortedList[groupID + '_' + product['productSizeID']]['timeslotOverlap'][newKey].availProductIDs = [];
                    sortedList[groupID + '_' + product['productSizeID']]['timeslotOverlap'][newKey].availProductIDs = [product['productID']];
                    sortedList[groupID + '_' + product['productSizeID']]['timeslotOverlap'][newKey]['unavailableIDs'] = [];
                    sortedList[groupID + '_' + product['productSizeID']]['timeslotOverlap'][newKey]['originalProductIDs'] = sortedList[groupID + '_' + product['productSizeID']]['originalProductIDs'];
                    sortedList[groupID + '_' + product['productSizeID']]['timeslotOverlap'][newKey]['originalProductWidgetIDs'] = sortedList[groupID + '_' + product['productSizeID']]['originalProductWidgetIDs'];
                    sortedList[groupID + '_' + product['productSizeID']]['timeslotOverlap'][newKey]['hourLength'] = slot['dayEnd'].diff(slot['dayStart'], 'hours').toObject();
                    sortedList[groupID + '_' + product['productSizeID']]['timeslotOverlap'][newKey]['swappableProductWidgets'] = [];
                    sortedList[groupID + '_' + product['productSizeID']]['timeslotOverlap'][newKey]['swappableProducts'] = [];
                      if(!sortedList[groupID + '_' + product['productSizeID']]['timeslotOverlap'][newKey]?.prodWidgets){
                        sortedList[groupID + '_' + product['productSizeID']]['timeslotOverlap'][newKey]['prodWidgets'] = [];
                      }

                  }
                }
              })
            }
          })
        }
      })
    });

    Object.values(sortedList).forEach(listItem => {
      if(Object.keys(listItem['timeslotOverlap']).length === 0){
        timesAreAvail = false;
      }
    })

    return {sortedList, timesAreAvail}
  }


  // Make sure timeslot has enough quantity to suport amount of product type in cart
  filterOverlappingTimeSlotForProducts(sortedList){
    let IDsUsedForSwap = {};
    let timesAreAvail = true;
    Object.values(sortedList).forEach(listItem => {
      Object.keys(listItem['timeslotOverlap']).forEach(slot => {

        if (listItem['timeslotOverlap'][slot]['availProductIDs'].length < listItem['originalProductIDs'].length) {
          delete listItem['timeslotOverlap'][slot];
          if (Object.keys(listItem['timeslotOverlap']).length === 0) {
            timesAreAvail = false;
          }
        }
        else{
          outerOriginalProductIDsLoop: for (const originalID of listItem['originalProductIDs']) {
            if (!listItem['timeslotOverlap'][slot]['availProductIDs'].includes(originalID)) {
              let found = false;

              for (const newID of listItem['timeslotOverlap'][slot]['availProductIDs']) {
                // let objKey = (slot['dayStart']).toISO() + '_' + (slot['dayEnd']).toISO()
                if ((!IDsUsedForSwap[slot] || !IDsUsedForSwap[slot]['IDs'].includes(newID)) && !listItem['originalProductIDs'].includes(newID) && !found) {
                  listItem['timeslotOverlap'][slot]['swappableProducts'].push({ originalID, newID });
                  // IDsUsedForSwap.push(newID);
                  IDsUsedForSwap = this.addIDToIDsUsedForSwap(newID, IDsUsedForSwap, slot);
                  found = true;
                }
              }
              if (!found) {
                delete listItem['timeslotOverlap'][slot];
                if (Object.keys(listItem['timeslotOverlap']).length === 0) {
                  timesAreAvail = false;
                }
                break outerOriginalProductIDsLoop;
              }
            }
          }

        }
      })
    })

    return {sortedList, timesAreAvail, IDsUsedForSwap}
  }

  // Filters out any times that dont have enouh product widgets for cart item
  async filterOverlappingTimeSlotForProductsWidgets(sortedList, algoRes, productsMap, allOriginalRentalIDs, IDsUsedForSwap) {
    let timesAreAvail = true;

    for (const listItem of Object.values(sortedList)) {

      for (const slot of Object.keys(listItem['timeslotOverlap'])) {
        if (listItem['originalProductWidgetIDs'].length > 0) {
          await Promise.all(listItem['originalProductWidgetIDs'].map(async id => {
            if (!listItem['timeslotOverlap'][slot]?.prodWidgets || !listItem['timeslotOverlap'][slot].prodWidgets.includes(id)) {
              let rentalAvailResult = await this.checkAlgoRentalAvail(id, algoRes['resultSet'][productsMap[id]['productGroupID']]['products'][id],
                listItem['timeslotOverlap'][slot]['dayStart'],
                listItem['timeslotOverlap'][slot]['dayEnd'],
                listItem['timeslotOverlap'][slot]
              );
              if (rentalAvailResult) {
                listItem['timeslotOverlap'][slot]['prodWidgets'].push(id);
              }
            }
          }));

          if (!listItem['timeslotOverlap'][slot]?.prodWidgets || listItem['timeslotOverlap'][slot].prodWidgets.length < listItem['originalProductWidgetIDs'].length) {
            delete listItem['timeslotOverlap'][slot];
            if (Object.keys(listItem['timeslotOverlap']).length === 0) {
              timesAreAvail = false;
            }
          } else {
            let prodWidgQuantities = {};
            let originalProdWidgQuantities = {};

            listItem['timeslotOverlap'][slot].prodWidgets.forEach(id => {
              if (prodWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']]) {
                prodWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']]['count'] += 1;
                prodWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']]['availIDs'].push(id);
              } else {
                prodWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']] = {};
                prodWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']]['count'] = 1;
                prodWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']]['availIDs'] = [id];
              }
            });

            listItem['originalProductWidgetIDs'].forEach(id => {
              if (originalProdWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']]) {
                originalProdWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']]['count'] += 1;
                originalProdWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']]['IDs'].push(id);
              } else {
                originalProdWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']] = {}
                originalProdWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']]['count'] = 1;
                originalProdWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']]['IDs'] = [id];
              }
            });

            let slotRemoved = false;

            for (const key of Object.keys(originalProdWidgQuantities)) {
              if (!slotRemoved) {
                if (prodWidgQuantities[key]) {
                  if (prodWidgQuantities[key]['count'] < originalProdWidgQuantities[key]['count']) {
                    slotRemoved = true;
                    delete listItem['timeslotOverlap'][slot];
                    if (Object.keys(listItem['timeslotOverlap']).length === 0) {
                      timesAreAvail = false;
                    }
                  } else {
                    for (const originalID of originalProdWidgQuantities[key]['IDs']) {
                      if (!prodWidgQuantities[key]['availIDs'].includes(originalID)) {
                        let found = false;
                        for (const proWidgID of prodWidgQuantities[key]['availIDs']) {
                          if ((!IDsUsedForSwap[slot] || !IDsUsedForSwap[slot]['IDs'].includes(proWidgID)) && !originalProdWidgQuantities[key]['IDs'].includes(proWidgID) && !found && !allOriginalRentalIDs.includes(proWidgID)) {
                            listItem['timeslotOverlap'][slot]['swappableProductWidgets'].push({ originalID, newID: proWidgID });
                            IDsUsedForSwap = this.addIDToIDsUsedForSwap(proWidgID, IDsUsedForSwap, listItem['timeslotOverlap'][slot]);
                            found = true;
                          }
                        }
                        if (!found) {
                          delete listItem['timeslotOverlap'][slot];
                          if (Object.keys(listItem['timeslotOverlap']).length === 0) {
                            timesAreAvail = false;
                          }
                          break;
                        }
                      }
                    }
                  }
                } else {
                  delete listItem['timeslotOverlap'][slot];
                  slotRemoved = true;
                  if (Object.keys(listItem['timeslotOverlap']).length === 0) {
                    timesAreAvail = false;
                  }
                }
              }
            }
          }
        }
      }
    }
    return { sortedList, timesAreAvail, IDsUsedForSwap };
  }


  // Filters out any times that dont have enouh product widgets for cart item
  async filterOverlappingTimeSlotForCartWidget(sortedList, algoRes, productsMap, cartProductWidgetIDs, allOriginalIDs, IDsUsedForSwap) {
    let timesAreAvail = true;
    // Dont loop through both list otherwise the cart swappable items will be ran twice and will fail
    //this needs to be moved after the overlpapping time are comimed
      for (const slot of Object.keys(Object.values(sortedList)[0]['timeslotOverlap'])) {
        cartWidgetIDLoop: for (const id of cartProductWidgetIDs) {
          let rentalAvailResult = await this.checkAlgoRentalAvail(id, algoRes['resultSet'][productsMap[id]['productGroupID']]['products'][id], Object.values(sortedList)[0]['timeslotOverlap'][slot]['dayStart'], Object.values(sortedList)[0]['timeslotOverlap'][slot]['dayEnd'], Object.values(sortedList)[0]['timeslotOverlap'][slot]);

          // If cart product widget is not in the rental avail then look for a replacement
          if (rentalAvailResult) {

          } else {
            let foundReplacement = false;
            // Look for a replacement ID
            const promises = Object.values(algoRes['resultSet'][productsMap[id]['productGroupID']]['products']).map(async newProduct => {
              if(!foundReplacement){
                // if it is the same size, not the same ID, has rental availability and is not an ID being used
                if (productsMap[id]['productSizeID'] == newProduct['productSizeID']) {
                  // let newRentalAvail = algoRes['resultSet'][productsMap[newProduct['productID']]['productGroupID']]['products'][newProduct['productID']]['main']['rentalAvail'][0];
                  let rentalAvailReplaceResult = await this.checkAlgoRentalAvail(id, algoRes['resultSet'][productsMap[newProduct['productID']]['productGroupID']]['products'][newProduct['productID']], Object.values(sortedList)[0]['timeslotOverlap'][slot]['dayStart'], Object.values(sortedList)[0]['timeslotOverlap'][slot]['dayEnd'], Object.values(sortedList)[0]['timeslotOverlap'][slot]);
                  if(rentalAvailReplaceResult){
                    // Make sure its not part of the original IDs
                    if (newProduct['productID'] != id && !allOriginalIDs.includes(newProduct['productID']) && (!IDsUsedForSwap[slot] || !IDsUsedForSwap[slot]['IDs'].includes(newProduct['productID']))) {
                      Object.values(sortedList)[0]['timeslotOverlap'][slot]['swappableProductWidgets'].push({ originalID: id, newID: newProduct['productID'] });
                      IDsUsedForSwap = this.addIDToIDsUsedForSwap(newProduct['productID'], IDsUsedForSwap, slot);
                      foundReplacement = true;
                    }
                  }
                }
              }
            });

            // Wait for all promises to resolve
            await Promise.all(promises);

            if (!foundReplacement) {
              delete Object.values(sortedList)[0]['timeslotOverlap'][slot];
              if (Object.keys(sortedList[Object.keys(sortedList)[0]]['timeslotOverlap']).length === 0) {
                  timesAreAvail = false;
              }
              break cartWidgetIDLoop;
            }
          }
        }
      }

    return {sortedList, timesAreAvail}
  }

  addIDToIDsUsedForSwap(newID, IDsUsedForSwap, slot){
    if(IDsUsedForSwap[slot]){
      IDsUsedForSwap[slot]['IDs'].push(newID);
    }
    else{
      IDsUsedForSwap[slot] = {IDs : []}
      IDsUsedForSwap[slot]['IDs'] = [newID];
    }
    return IDsUsedForSwap
  }

  combieAllOverlappingTimeslotsAndRestructure(sortedList){
    let listItemCount = 0;
    let allSlots = {};
    let allUniqueHours = [];
    let backendBookingData = {};
    Object.keys(sortedList).forEach(listItemID => {
      listItemCount += 1;
      Object.keys(sortedList[listItemID]['timeslotOverlap']).forEach(slotOverlapID => {

        // We only care about the count
        if(allSlots[slotOverlapID]){
          allSlots[slotOverlapID]['combineCount'] += 1;
          allSlots[slotOverlapID]['productGroupSizes'][listItemID] = sortedList[listItemID]['timeslotOverlap'][slotOverlapID];
        }
        else{
          allSlots[slotOverlapID] = {};
          allSlots[slotOverlapID]['combineCount'] = 1;
          allSlots[slotOverlapID]['hourLength'] = sortedList[listItemID]['timeslotOverlap'][slotOverlapID]['hourLength'].hours;
          allSlots[slotOverlapID]['availDaySpan'] = sortedList[listItemID]['timeslotOverlap'][slotOverlapID]['availDaySpan'];
          allSlots[slotOverlapID]['dayStart'] = sortedList[listItemID]['timeslotOverlap'][slotOverlapID]['dayStart'];
          allSlots[slotOverlapID]['dayEnd'] = sortedList[listItemID]['timeslotOverlap'][slotOverlapID]['dayEnd'];
          allSlots[slotOverlapID]['type'] = sortedList[listItemID]['timeslotOverlap'][slotOverlapID]['type'];
          allSlots[slotOverlapID]['productGroupSizes'] = {};
          allSlots[slotOverlapID]['productGroupSizes'][listItemID] = sortedList[listItemID]['timeslotOverlap'][slotOverlapID];
          allSlots[slotOverlapID]['swappableItems'] = [];
          allSlots[slotOverlapID]['originalProductIDs'] = [...sortedList[listItemID]['timeslotOverlap'][slotOverlapID]['originalProductIDs']];
          allSlots[slotOverlapID]['originalProductWidgetIDs'] = [...sortedList[listItemID]['timeslotOverlap'][slotOverlapID]['originalProductWidgetIDs']];
          if(!allUniqueHours.includes(sortedList[listItemID]['timeslotOverlap'][slotOverlapID]['hourLength'].hours)){
            allUniqueHours.push(sortedList[listItemID]['timeslotOverlap'][slotOverlapID]['hourLength'].hours)
          }
        }
      })
    })

    // Remove any slots that do not support all cart items
    Object.keys(allSlots).forEach(slotID => {
      if(allSlots[slotID]['combineCount'] < listItemCount){
        delete allSlots[slotID]
      }
      else{
        delete allSlots[slotID]['combineCount'];
      }
    })
    return {allSlots, allUniqueHours}
  }

  getSwappableItems(sortedList){
    // let allAvailableIDs = []

    Object.keys(sortedList).forEach(slotID => {
      Object.keys(sortedList[slotID]['productGroupSizes']).forEach(productGroupSizeID => {

          sortedList[slotID]['swappableItems'].push(...sortedList[slotID]['productGroupSizes'][productGroupSizeID]['swappableProductWidgets']);
          sortedList[slotID]['swappableItems'].push(...sortedList[slotID]['productGroupSizes'][productGroupSizeID]['swappableItems']);

      })
    })

    return sortedList
  }

  checkAlgoRentalAvail(prodID, algoProductInfo, dayStart, dayEnd, slot){
    let passTimeCheck = false;
    let rentalAvailArray = [];

    if(slot.type == '24hr' && algoProductInfo['secondary']['24hrOnSingleSearchDay']['rentalAvail'].length > 0) {
      rentalAvailArray = algoProductInfo['secondary']['24hrOnSingleSearchDay']['rentalAvail'];
    }
    else {
      rentalAvailArray = algoProductInfo['main']['rentalAvail'];
    }

    rentalAvailArray.forEach(rentalAvailItem => {
      // If cart product widget is not in the rental avail then look for a replacement
      if (rentalAvailItem && rentalAvailItem['windowStart'] <= dayStart && dayEnd <= rentalAvailItem['windowEnd']) {
        passTimeCheck = true;
      }
    })

    return passTimeCheck
  }



  //----------------- Quick Booking Methods -----------------//
  getManualTimeSlots(info) {
    let results24hr = [];
    let resultsHourly = [];
    let resultsShopDay = [];

    if (Object.keys(info).length === 0) {
      return { results24hr, resultsShopDay, resultsHourly }
    }

    let rentalAvail24Hr = [{ windowStart: info.sdsOpeningDateTime, windowEnd: info.xtraDateInfo.singleDay24ClosingDateTime }]
    let rentalAvail = [{ windowStart: info.sdsOpeningDateTime, windowEnd: info.sdeClosingDateTime }]
    let isSingleCalendarDay;

    if (info.daySpan === 1) {
      isSingleCalendarDay = true;
    }
    else {
      isSingleCalendarDay = false;
    }

    //--- 24 hour times ---//
    if (isSingleCalendarDay) {
      results24hr = this.availabilityService.getRangeAvailability(info.sdsOpeningDateTime, info.xtraDateInfo['singleDay24ClosingDateTime'], info.sdsClosingDateTime,
                      info.xtraDateInfo['singleDay24OpeningDateTime'], info.sdsUnavailableTimes, info.xtraDateInfo['singleDay24_SDEUnavailableTimes'], 30, rentalAvail24Hr,
                      0, info.xtraDateInfo['singleDay24_daySpan'], info);
    }
    else {
      results24hr = this.availabilityService.getRangeAvailability(info.sdsOpeningDateTime, info.sdeClosingDateTime, info.sdsClosingDateTime, info.sdeOpeningDateTime,
                      info.sdsUnavailableTimes, info.sdeUnavailableTimes, 30, rentalAvail, 0, info.daySpan, info);
    }

    //--- Daily times ---//

    resultsShopDay = this.availabilityService.getShopDayTimeSlot(rentalAvail, info.sdsOpeningDateTime, info.sdeClosingDateTime, info.sdsUnavailableTimes,
                          info.sdeUnavailableTimes, info.daySpan, info, isSingleCalendarDay);

    // --- Hourly times ---//
    if (isSingleCalendarDay) {
      let hoursArray = this.getHoursArray(info);
      let DS = info.sdsOpeningDateTime;

      if (info.searchIncludesToday) {
        DS = info.currentRentalWindow;
      }

      while (DS <= info.sdeClosingDateTime) {
        hoursArray.forEach(async (i) => {
          let DE = DS.plus({ hours: i })

          let timeIsUnavailable = false;

          // Check unavailables first
          // Can't await here, if awaiting then times will be inaccurate in differing timezones from the shop
          info.sdsUnavailableTimes.forEach((unavail) => { // only need to check sds because sds and sde should be the same date for hourly | single date
            if ((unavail.dayStart <= DS && DS <= unavail.dayEnd) || (unavail.dayStart <= DE && DE <= unavail.dayEnd)) {
              // time slot is unavailable
              timeIsUnavailable = true;
            }
          })

          if (!timeIsUnavailable) {
              let rentalAvailDS = rentalAvail[0].windowStart
              let rentalAvailDE = rentalAvail[0].windowEnd

              if ((rentalAvailDS <= DS) && ((DE.hasSame(info.sdsClosingDateTime, 'minute') ? DE : DE.plus({ minutes: 0 })) <= rentalAvailDE)) {
                resultsHourly.push({ dayStart: DS, dayEnd: DE, dayStartString: DS.toLocaleString(DateTime.DATETIME_FULL), dayEndString: DE.toLocaleString(DateTime.DATETIME_FULL),
                                    availDaySpan: info.daySpan, type: "hourly", hourLength: DE.diff(DS, 'hours').toObject().hours})
              }
          }
        })
        DS = DS.plus({ minutes: 30 })
      }
    }
    return { results24hr, resultsShopDay, resultsHourly }
}

  getHoursArray(info) {
    if (Object.keys(info).length === 0) {
      return []
    }

    let maxHoursInShopDay = info.sdeClosingDateTime.diff(info.sdsOpeningDateTime, 'hours').hours;
    let hours = [];
    for (let i = 1; i <= maxHoursInShopDay; i++) {
      hours.push(i);
    }
    return hours
  }


  getSelectedHoursBasedOnRentalType(type, rental){
    let dayStart = this.timeService.convertTimestampToLuxon(rental['dayStart'])
    let dayEnd = this.timeService.convertTimestampToLuxon(rental['dayEnd'])
    let hourAmount = dayEnd.diff(dayStart, 'hours').toObject().hours


    if (type == "byDay") {
      let dayspan = this.availabilityService.getDayspan(dayEnd, dayStart);
      if (hourAmount % 24 == 0 && hourAmount / 24 == dayspan - 1) {
        return '24 Hour Rental';
      }
      return 'All Day Rental';
    }
    else if (type == "byHour") {
      return hourAmount
    }
    else {
      throw new Error('Invalid rental type')
    }
  }

  getTimeslotTypeBasedOnRentalType(type, rental){
    let dayStart = this.timeService.convertTimestampToLuxon(rental['dayStart'])
    let dayEnd = this.timeService.convertTimestampToLuxon(rental['dayEnd'])
    let hourAmount = dayEnd.diff(dayStart, 'hours').toObject().hours


    if (type == "byDay") {
      let dayspan = this.availabilityService.getDayspan(dayStart, dayEnd);
      if (hourAmount % 24 == 0 && hourAmount / 24 == dayspan) {
        return '24hr';
      }
      return 'shopDay';
    }
    else if (type == "byHour") {
      return "hourly"
    }
    else {
      throw new Error('Invalid rental type')
    }
  }

  async convertAllRentalObjectTimesToJSDate(rental) {
    // It is necessary to call the luxon function first to take care of deep clone strings
    rental.dayStart = this.timeService.convertTimestampToLuxon(rental.dayStart);
    rental.dayStart = this.timeService.convertToJavascriptDate(rental.dayStart);

    rental.dayEnd = this.timeService.convertTimestampToLuxon(rental.dayEnd);
    rental.dayEnd = this.timeService.convertToJavascriptDate(rental.dayEnd);

    rental.created = this.timeService.convertTimestampToLuxon(rental.created);
    rental.created = this.timeService.convertToJavascriptDate(rental.created);

    if (rental?.statusDate && rental?.statusDate?.isReserved) {
      rental.statusDate.isReserved = this.timeService.convertTimestampToLuxon(rental.statusDate.isReserved);
      rental.statusDate.isReserved = this.timeService.convertToJavascriptDate(rental.statusDate.isReserved);
    }

    rental.lastModified = this.timeService.convertTimestampToLuxon(rental.lastModified);
    rental.lastModified = this.timeService.convertToJavascriptDate(rental.lastModified);

    if (rental?.cartObj) {
      rental.cartObj.dateCreated = this.timeService.convertTimestampToLuxon(rental.cartObj.dateCreated);
      rental.cartObj.dateCreated = this.timeService.convertToJavascriptDate(rental.cartObj.dateCreated);

      if (rental?.cartObj?.items && rental?.cartObj?.items.length > 0) {
        await Promise.all(rental.cartObj.items.map(async (item) => {
          item.dayStart = this.timeService.convertTimestampToLuxon(item.dayStart);
          item.dayStart = this.timeService.convertToJavascriptDate(item.dayStart);

          item.dayEnd = this.timeService.convertTimestampToLuxon(item.dayEnd);
          item.dayEnd = this.timeService.convertToJavascriptDate(item.dayEnd);

          item.dateAdded = this.timeService.convertTimestampToLuxon(item.dateAdded);
          item.dateAdded = this.timeService.convertToJavascriptDate(item.dateAdded);
        }));
      }
    }
    return rental
  }

  createAvailProductGroupsList(algoRes) {
    let availList = [];
    Object.keys(algoRes.cartQuantities).forEach(group => {
      if(algoRes.cartQuantities[group]['currentAvail'] > 0){
        let productGroupIDString = group.split('_');
        algoRes.cartQuantities[group]['productGroupID'] = productGroupIDString[0];
        availList.push(algoRes.cartQuantities[group]);
      }
    })
    return availList
  }


  // The only time this function should be called is in the bookings upload when the date is adjusted, that is the ONLY time the items timeslot type should be manually changed
  updateCartItemsToHaveMatchingTimeslotTypes(items, timeslotType) {
    console.log("time slot type passed", timeslotType)
    // Check if all cart items have the same timeslot type
    // If not, then change all cart items to have the same timeslot type
    if (items.length > 0) {
      items.forEach(item => {
        console.log("item loop", item)
        if (item?.timeslotType !== timeslotType) {
          item.timeslotType = timeslotType;
        }
      })
    }

    return items
  }

} // End of service
