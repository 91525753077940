import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent implements OnInit {

  constructor  (  public dialogRef: MatDialogRef <DialogConfirmComponent>,
  @ Inject ( MAT_DIALOG_DATA ) public data: any ){}

  ngOnInit(): void {

  }
  close(){
    this.dialogRef.close()
  }

}
