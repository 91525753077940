import { Timestamp } from "firebase/firestore";
import { PriceByDay, PriceByHour } from "./pricing";
import { TimeslotType } from 'src/app/models/availability-timeslot.model'
import { uuidv4 as uuid } from "@firebase/util";
import { DateTime } from "luxon";


export interface CustomCartSettings {
    isModificationsAllowed: boolean;
}

export enum CartOperation {
    Init = 'Init',
    Checkout = 'Checkout',
    ValidateDiscount = 'ValidateDiscount',
    ClearDiscount = 'ClearDiscount',
    CommentInput = 'CommentInput',
    CartWidgetInput = 'CartWidgetInput',
    CartProductWidgetInput = 'ProdCartProductWidgetInputuctWidgetInput',
    ClearCart = 'ClearCart',
    RemoveCartItem = 'RemoveCartItem'
}

export interface CartItem {
    addonTotalWProduct?: number;
    addonTotalwoProduct?: number;
    addonPrice: number;
    cartItemID: string;
    customerLocalTimezone: string;
    companyId: string;
    dateAdded: Timestamp | Date;
    dayEnd: Timestamp | DateTime | Date; // DateTime used on client ONLY
    dayEndISO: string;
    dayEndString: string;
    daySpan: number;
    dayStart: Timestamp | DateTime | Date; // DateTime used on client ONLY
    dayStartISO: string;
    dayStartString: string;
    downtime: null | number;
    is24Hrs: boolean;
    isCheckedIn: boolean;
    isCheckedOut: boolean;
    isPrepared: boolean;
    locationID: string;
    locationTimeZone: string;
    parentId: string;
    priceByDay: PriceByDay[];
    priceByHour: PriceByHour[];
    productId: string;
    productName: string;
    productSize: string;
    productSizeID: string;
    selectedHours: string | number;
    widgetList?: any[];
    timeslotType?: TimeslotType | null;
    unavailable?: boolean;
    cartItemOutdated?: boolean;
    totalCountAddons?: number;
    totalCountProducts?: number;
    widgetProduct?: any[];
    unavailableWidget?: boolean;
}
export type RentalItem = CartItem

export interface PsuedoCartItem { // Currently used to query the availability for cart quantities without having any legitimate items (creates a psuedo item) so the availability still returns the cartQuantities
    dayStart: DateTime,
    dayEnd: DateTime,
    timeslotType: TimeslotType;
}

const defaultCartItem: Partial<CartItem> = {
  downtime: 30
}

export function makeNewCartItem(item?: Partial<CartItem>) {
  const added = item?.dateAdded || new Date()
  const itemID = item?.cartItemID || uuid()

  return { ...defaultCartItem, ...item, cartItemID: itemID, dateAdded: added } as CartItem
}

export const makeNewRentalItem = makeNewCartItem
