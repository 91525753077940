import { Injectable } from '@angular/core';
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { StorageService } from "./storage.service";
import { CurrentUserService } from "./current-user.service";
import { ImageLibrary } from "src/app/models/image-library.model";
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageLibraryService {

  public selectedImg = new Subject<ImageLibrary>();

  constructor(private _afs: AngularFirestore,
    private _storageService: StorageService,
    private _currentUserService: CurrentUserService) {
  }

  getImages(companyID: string): Observable<ImageLibrary[]> {
    //get id from doc
    const query = this._afs.collection<ImageLibrary>('imageLibrary', ref => ref.where('companyID', '==', companyID))

    return query.valueChanges({ idField: 'id' });

  }

  saveImage(image: ImageLibrary) {
    return this._afs.collection('imageLibrary').add(image);
  }

  updateImage(image) {
    return this._afs.collection('imageLibrary').doc(image.id).update(image);
  }

  getCompanyLogo(companyID?: string) {

    let call;

    console.log(companyID)

    if (typeof companyID !== 'undefined') {
      call = this._afs.collection('imageLibrary').ref.where('isCompanyLogo', '==', true).where('companyID', '==', companyID).get();
    }
    else {
      call = this._afs.collection('imageLibrary').ref.where('isCompanyLogo', '==', true).where('companyID', '==', this._currentUserService.currentUser.companyId).get();
    }

    return call.then(docs => {
      try {
        let doc = docs.docs[0].data()
        doc['id'] = docs.docs[0].id
        return doc
      }
      catch { // if no prior logo set (fresh company with no logo set up yet)
        return { id: undefined };
      }
    })
  }

  //   return this._afs.collection('imageLibrary').ref.where('isCompanyLogo', '==', true).where('companyID', '==', this._currentUserService.currentUser.companyId).get().then(docs => {
  //     try {
  //       let doc = docs.docs[0].data()
  //       doc['id'] = docs.docs[0].id
  //       return doc
  //     }
  //     catch { // if no prior logo set (fresh company with no logo set up yet)
  //       return { id: undefined };
  //     }
  //   })
  // }

  updateCompanyLogo(oldCompanyLogo, newCompanyLogo, templates) {
    let batch = this._afs.firestore.batch();

    // Switch out companyLogo bools
    oldCompanyLogo.isCompanyLogo = false;
    newCompanyLogo.isCompanyLogo = true;

    if (oldCompanyLogo['id'] === undefined) { // if oldCompanyLogo is undefined / empty - (company never had a logo set up)
      batch.update(this._afs.collection('imageLibrary').doc(newCompanyLogo.id).ref, newCompanyLogo); // set the new logo, no old logo needs to be reset
    }
    else { // reset old logo bool to false, new logo bool set to true
      batch.update(this._afs.collection('imageLibrary').doc(oldCompanyLogo.id).ref, oldCompanyLogo);
      batch.update(this._afs.collection('imageLibrary').doc(newCompanyLogo.id).ref, newCompanyLogo);
    }

    console.log(templates)

    // Make edits to all templates
    templates.forEach((template) => {
      batch.update(this._afs.collection('bookingTemplates').doc(template.id).ref, { companyLogo: newCompanyLogo.url })
    })

    batch.commit()
  }

  dropImage(image: ImageLibrary) {
    console.log(image)
    return this._afs.collection('imageLibrary').doc(image.id).delete();
  }

  // When selecting an img, we push the selected imgInfo to all subscribers listening
  selectImage(image: ImageLibrary) {
    this.selectedImg.next(image);
  }

  ifExists(name: string, type: string) {
    console.log(name, type)
    let ref = this._afs.collection('imageLibrary').ref.where('companyID', '==', this._currentUserService.currentUser.companyId)
      .where('name', '==', name)
      .where('type', '==', type)

    return ref.get().then(res => {
      return !res.empty ? res.docs[0].id : null;

    })
  }

}

