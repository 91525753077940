import { Component, Input, OnInit } from '@angular/core';

// Libraries
import Swal from 'sweetalert2';

@Component({
  selector: 'app-copyToClipboard',
  templateUrl: './copyToClipboard.component.html',
  styleUrls: ['./copyToClipboard.component.css']
})
export class CopyToClipboardComponent implements OnInit {
  @Input() textToCopy: string;
  @Input() showToast: boolean = true;

  constructor() { }

  ngOnInit() {
  }

  copyToClipboard(): void {
    navigator.clipboard.writeText(this.textToCopy).then(() => {
      // toast
      Swal.fire({ toast: true, title: 'Successfully copied!', icon: 'success', position: 'bottom-end', timer: 1500, timerProgressBar: true, showCloseButton: true, showConfirmButton: false, showLoaderOnConfirm: true, allowOutsideClick: () => !Swal.isLoading() })
    }).catch(err => {
      console.error('Could not copy text: ', err);
    })
  }

}
