import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { tap, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

import imageSize from '@coderosh/image-size'
import { ToastrService } from 'ngx-toastr';

// import { request } from 'http';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  @Input() storagePath: string;
  @Output() urlEvent = new EventEmitter<{}>();
  @Output() startUploadEvent = new EventEmitter<{}>();
  @Input() existingFileName: string;
  @Input() mode: string;
  @Input() btnTitle: string = "Upload";
  @Input() isButtonAlert: boolean;
  @Input() isEditing: boolean;
  @Input() showLink: boolean;
  @Input() public fileName = ""
  @Input() public receiptUrl: any
  @Input() uploadBtnFileName: boolean = true

  @Input() imageRequest: string

  // Main Task.  Gives us access to observable data and ability to pause, cancel and resume upload.
  task: AngularFireUploadTask;

  // Progress Monitoring
  percentage: Observable<number>;
  snapshot: Observable<any>;
  uploading: boolean = false;
  // uploadBtnFileName: boolean = true

  // Download URL
  downloadURL: Observable<string> = new Observable<string>();

  // State for dropzone CSS toggling
  isHovering: boolean;

  constructor(
    private storage: AngularFireStorage,
    private db: AngularFirestore,
    private afs: AngularFirestore,
    private _cd: ChangeDetectorRef,
    public toastr: ToastrService,

  ) {
    if (this.mode === undefined) this.mode = 'dropzone'
    if (this.isButtonAlert === undefined) this.isButtonAlert = false
    if (this.isEditing === undefined) this.isEditing = false

  }

  mouseEnter(){
    this.isHovering = true
    this._cd.detectChanges()
  }
  mouseLeave(){
    this.isHovering = false
    this._cd.detectChanges()
  }
  clickDropzone(){
    $('#dropzoneFileInput').trigger('click');
  }


  // Kicks off upload process.  Is an event handler
  async startUpload(target: any) {

    let event = target.files as FileList
    var tmppath = URL.createObjectURL(event[0]);
    let segments = event[0].name.split(".")
    let fileType = segments[segments.length - 1].toUpperCase()

    var size
    if (fileType == "JPG" || fileType == "JPEG" || fileType == "PNG"){
      size = await imageSize(tmppath)
    } else{

    }
    switch (size && this.imageRequest) {
      case 'Products&Categories':
        if (size.width < 960 || size.height < 540) {
          this.isButtonAlert = true
          this.toastr.error('The minimum size for the width and height settings is: "960 x 540"  ', 'Empty Field:', { positionClass: 'toast-top-right' });
        } else {
          this.isButtonAlert = false
        }
        break;
      case 'Options':
        if (size.width < 1280 || size.height < 720) {
          this.isButtonAlert = true
          this.toastr.error('The minimum size for the width and height settings is: "1280 x 720"  ', 'Empty Field:', { positionClass: 'toast-top-right' });
        } else {
          this.isButtonAlert = false
        }
        break;

    }
    if (this.isButtonAlert === false) {


      // The File object
      this.startUploadEvent.emit({});

      for (var i = 0; i < event.length; i++) {
        const file = event.item(i);
        if (this.uploadBtnFileName) {
          this.fileName = file.name
        }

        let path = '';

        path = this.storagePath + `${file.name}`;

        // The storage path
        const fileRef = this.storage.ref(path);

        // The Main Task
        this.task = this.storage.upload(path, file)
        this.uploading = true;
        this.task.then(() => {
          fileRef.getDownloadURL().subscribe(url => {
            this.receiptUrl = url
            this.urlEvent.emit({ url: url, name: file.name })
            setTimeout(() => {
              this.uploading = false
              this._cd.detectChanges()
            }, 1500);
          })
        }).catch(error => {
          console.log("error with upload - ", error)
        })

        // Progress monitoring
        this.percentage = this.task.percentageChanges();
      }
    }
  }




  // Determines if the upload task is active
  isActive(snapshot: any) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes
  }

  ngOnInit() {

  }

}
