import { Injectable, Inject, Optional } from '@angular/core';

/* Libraries */
import { DateTime } from 'luxon';
import { LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';

@Injectable()
export class FleetmaidDateAdapter extends LuxonDateAdapter {

    private timezone: string = 'America/Denver'; // Change dynamically based off selected location

    constructor(
        @Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string,
        @Optional() @Inject(MAT_LUXON_DATE_ADAPTER_OPTIONS) private options?: any
    ) {
        super(matDateLocale, options);
    }

    // Method to update the timezone dynamically
    public setTimezone(timezone: string): void {
        this.timezone = timezone;
    }

    // Ensure that when 'now()' is called, it's also in the correct timezone
    public now(): DateTime {
        return DateTime.local().setZone(this.timezone);
    }

    override createDate(year: number, month: number, date: number): DateTime {
        // Create the date in the system's local timezone
        const localDate = DateTime.fromObject({ year, month: month + 1, day: date });

        // Then, convert the local date to the desired timezone
        return localDate.setZone(this.timezone, { keepLocalTime: true });
    }

    // Ensure deserialized dates are set to the correct timezone
    override deserialize(value: any): DateTime | null {
        if (value) {
            return DateTime.fromISO(value, { zone: this.timezone });
        }
        return null;
    }

    // Make sure the formatted date is in the correct timezone
    override format(date: DateTime, displayFormat: string): string {
        return date.setZone(this.timezone).toFormat(displayFormat);
    }

    // Ensure that parsed dates use the correct timezone
    override parse(value: any, parseFormat: string | string[]): DateTime | null {
        if (value && typeof value === 'string') {
            return DateTime.fromFormat(value, parseFormat as string, { zone: this.timezone });
        }
        return null;
    }

    // Override ISO serialization to ensure the correct timezone is used
    override toIso8601(date: DateTime): string {
        return date.setZone(this.timezone).toISO();
    }
}
