import {
  CollectionViewer,
  DataSource,
} from "@angular/cdk/collections";

import {
  BehaviorSubject,
  catchError,
  finalize,
  Observable,
  of,
  take,
} from "rxjs";

// Models
import { Collection } from "src/app/v2/models/collection-reference.model";
import { DataSourceIface, OrderBy, PaginateBy, QueryConfig } from "src/app/v2/models/firestore-interaction.model";

export class ServerSideDataSource<T> implements DataSource<T> {

    private dataSubject = new BehaviorSubject<T[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private firstAndLast = new BehaviorSubject<{ readonly first: T, readonly last: T }>(null)

    public loading$ = this.loadingSubject.asObservable();

    constructor(private _collection: Collection, private dataService: DataSourceIface) {
      if (!this._collection) {
        throw new Error('missing required collection type')
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public connect(collectionViewer: CollectionViewer): Observable<T[]> {
      return this.dataSubject.asObservable();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public disconnect(collectionViewer: CollectionViewer): void {
      this.dataSubject.complete();
      this.loadingSubject.complete();
      this.firstAndLast.complete();
    }

    public total(configs: QueryConfig[]) {
      return this.dataService.count(this._collection, configs)
    }

    public load(configs: QueryConfig[], sort?: OrderBy[], paginate?: PaginateBy) {

        this.loadingSubject.next(true);

        let orderBy: OrderBy[]
        if (sort?.length > 0) {
          orderBy = sort
        }

        console.debug(
          `load(conf..${configs.length}, sort?..${sort?.length || 0}, page?..${paginate && 'present' || undefined})`,
          {configs, sort, paginate},
        )
        this.dataService.find(this._collection, configs, orderBy, paginate).pipe(
          take(1),
          catchError((err) => {
            console.error(
              `load(conf..${configs.length}, ` +
              `sort?..${sort?.length || 0}, ` +
              `page?..${paginate && 'present' || undefined})`,
              {configs, sort, paginate},
              err
            )
            // return an empty respone
            return of([])
          }),
          finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(objects => {
          this.dataSubject.next(objects)
          if (objects?.length) {
            const _first = objects[0]
            const _last = objects[objects.length-1]
            this.firstAndLast.next({first: _first, last: _last})
          }
        });
    }

    public watchPageEnds(): Observable<{ readonly first: T, readonly last: T }> {
      return this.firstAndLast.asObservable();
    }
}
