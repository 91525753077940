import { Injectable } from '@angular/core';

// Models
import { Rental } from 'src/app/models/storage/rental.model';
import { RentalStatus } from 'src/app/models/rental.model';
import { Condition } from '../models/firestore-interaction.model';

// Services
import { FirestoreService } from 'src/app/v2/services/firestore.service'
import { AvailabilityService } from 'src/app/services/availability.service';
import { TimeService } from 'src/app/services/time.service';

@Injectable({
  providedIn: 'root'
})

export class RentalServiceV2 {
  constructor(
    private availabilityService: AvailabilityService,
    private firestoreService: FirestoreService,
    private timeService: TimeService,
  ) {}

  protected mapRentalStatusToWhereClause(status: RentalStatus): Condition[] {
    const conditions: Condition[] = []
    const today = new Date().setHours(0, 0, 0, 0)

    switch (status) {
      case RentalStatus.Archived:
        conditions.push({field: 'isActive', operator: '==', value: false})
        break

      case RentalStatus.Cancelled:
        conditions.push(
          {field: 'isCancelled', operator: '==', value: true},
          {field: 'isConfirmed', operator: '==', value: true},
        )
        break

      case RentalStatus.CheckedIn:
        // overlaps with Completed
        console.warn(`Rental status '${RentalStatus.CheckedIn}' is DEPRECATED and will be REMOVED, do not use it!`)
        conditions.push(
          {field: 'isCheckedIn', operator: '==', value: true},
          {field: 'isCancelled', operator: '==', value: false},
          {field: 'isConfirmed', operator: '==', value: true},
        )
        break

      case RentalStatus.CheckedOut:
        conditions.push(
          {field: 'isCheckedOut', operator: '==', value: true},
          {field: 'isComplete', operator: '==', value: false},
          {field: 'isCancelled', operator: '==', value: false},
          {field: 'isConfirmed', operator: '==', value: true},
        )
        break

      case RentalStatus.Completed:
        conditions.push(
          {field: 'isComplete', operator: '==', value: true},
          {field: 'isConfirmed', operator: '==', value: true},
        )
        break

      case RentalStatus.Current:
        conditions.push(
          {field: 'isCheckedIn', operator: '==', value: false},
          {field: 'isCancelled', operator: '==', value: false},
          {field: 'isConfirmed', operator: '==', value: true},
        )
        break

      case RentalStatus.Preparing:
        // overlaps with Upcoming
        console.warn(`Rental status '${RentalStatus.Preparing}' is DEPRECATED and will be REMOVED do not use it!`)
        conditions.push(
          {field: 'isCheckedOut', operator: '==', value: false},
          {field: 'isCancelled', operator: '==', value: false},
          {field: 'isConfirmed', operator: '==', value: true},
        )
        break

      case RentalStatus.Upcoming:
        conditions.push(
          {field: 'dayStart', operator: '>', value: new Date(today)},
          {field: 'isCheckedIn', operator: '==', value: false},
          {field: 'isCancelled', operator: '==', value: false},
          {field: 'isCheckedOut', operator: '==', value: false},
          {field: 'isConfirmed', operator: '==', value: true},
        )
        break

      case RentalStatus.All:
      default:
        // no filters, return all
        break;
    }
    return conditions
  }

  makeConditionsForFind(companyID: string, status: RentalStatus): Condition[] {
    const conditions: Condition[] = [{field: 'companyID', operator: '==', value: companyID}]
    conditions.push(...this.mapRentalStatusToWhereClause(status))
    return conditions
  }

  getSelectedHoursBasedOnRentalType(type: string, rental: Rental) {
    const dayStart = this.timeService.convertTimestampToLuxon(rental['dayStart'])
    const dayEnd = this.timeService.convertTimestampToLuxon(rental['dayEnd'])
    const hourAmount = dayEnd.diff(dayStart, 'hours').toObject().hours

    if (type === "byDay") {
      const dayspan = this.availabilityService.getDayspan(dayEnd, dayStart);
      if (hourAmount % 24 == 0 && hourAmount / 24 == dayspan - 1) {
        return '24 Hour Rental';
      }
      return 'All Day Rental';
    }
    else if (type === "byHour") {
      return hourAmount
    }
    else {
      throw new Error('Invalid rental type')
    }
  }
}
