import {Injectable} from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AngularFireStorage} from "@angular/fire/compat/storage";

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private afs: AngularFirestore,
              private storage: AngularFireStorage) {
  }


  // upload a file to firebase storage
  uploadFile(file: File, path: string) {
    return this.storage.upload(path, file);
  }

  getIcons(path: string): Promise<string[]> {

    return new Promise<string[]>((resolve, reject) => {
      this.storage.ref(path).listAll().subscribe((res) => {

        let icons: any[] = [];
        res.items.forEach((item) => {
          item.getDownloadURL().then((url) => {
            // quitar .png y poner primera letra en mayuscula
            let name = item.name.replace('.png', '');
            name = name.charAt(0).toUpperCase() + name.slice(1);

            let index = icons.findIndex((icon) => icon.name > name);
            if (index === -1) {
              icons.push({name: name, code: url});
            } else {
              icons.splice(index, 0, {name: name, code: url});

            }

          })
        })
        // sort icons by name


        resolve(icons);
      })
    })

  }

  // get a specific url file from firebase storage


  // delete a file from firebase storage
  deleteFile(path: string) {
    return this.storage.ref(path).delete();
  }
}
