<div class="container-fluid pt-5 titleheight" *ngIf="smartWaiver == false">
    <div class="container-fluid align-items-center justify-content-center d-flex"><span class="fs-3">Waiver and
            Release</span></div>
    <div class="container-fluid align-items-center justify-content-center d-flex mt-1 text-primary">
        <span>{{actualdate}}</span>
    </div>
</div>
<div class="container-fluid fullheight pt-5" *ngIf="smartWaiver == false">
    <div class="container-fluid mb-3 text-center fs-5 titlescroll text-secondary" *ngIf="multiplewaivers">
        Please select a waiver below then scroll down to read and sign.
    </div>
    <div class="row" *ngIf="multiplewaivers">
        <div class="col-md-2 offset-md-1 pt-2 cardsheight" *ngIf="waivers.length > 0">
            <div class="container shadow mb-2 rounded hover"
                [ngClass]="{'bg-light':!waiver.isSigned, 'lightgreen':waiver.isSigned}"
                *ngFor="let waiver of waivers; let i = index" (click)="putToSign(waiver, i)">
                <div class="row text-center d-flex align-items-center justify-content-center" style="height: auto;">
                    <span class="fs-5 mt-3 mb-1">{{waiver.title}}</span>
                </div>
                <div class="row">
                    <div class="d-grid gap-2 pt-2 pb-3">
                        <ng-container *ngIf="waiver.isSigned; else nosigned">
                            <button class="btn btn-sm btn-success ms-1" (click)="putToSign(waiver, i)">
                                <i class="fas fa-check-circle"></i>
                                Signed
                            </button>
                        </ng-container>
                        <ng-template #nosigned>
                            <button class="btn btn-sm btn-primary" (click)="putToSign(waiver, i)">
                                Sign
                            </button>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8 bg-light p-5 shadow waiverheight" *ngIf="waivers.length > 0">
            <div id="waivercontent" class="textheight text-secondary text-center pt-3">
                <span class="fs-3" *ngIf="waivers.length > 0">Select a Waiver to Sign...</span>
            </div>
            <div *ngIf="waiver?.waiverPDF" class="textheight text-secondary text-center pt-3">
                <iframe class="container-fluid" *ngIf="waiver?.pdfContent; else noPDF" [src]="pdfEditor" height="460"
                    style="overflow:hidden" frameborder="0"></iframe>
                <ng-template #noPDF>
                    <div style="height: 465px"
                        class="textheight container-fluid text-center fs-4 bg-light d-flex justify-content-center align-items-center">
                        <span>No waiver preview available</span>
                    </div>
                </ng-template>
            </div>
            <ng-container *ngIf="waiverselected && waiver.multipleSignature">
                <div *ngFor="let product of waiver.products; let i = index"
                    class="container-fluid btnsheight pt-2 pb-2 rounded border mt-2" style="background-color: white;">
                    <form (submit)="signWaiver(product, i, waiver)" class="mb-2" novalidate>
                        <div class="mb-3 mt-1">
                            <span class="fw-bold">Product Name: </span><span>{{product.productName}} -
                                {{product.productSize}}</span>
                            <hr style="color: #cbd0ff">
                        </div>
                        <div class="mb-3">
                            <label for="waiversign" class="form-label">Type full name for signature here <b
                                    style="color:red;">*</b></label>
                            <input type="text" class="form-control" id="waiversign{{i}}"
                                [disabled]="product.signed || companyview" value="{{product?.waiverSignature}}"
                                required>
                            <div class="form-text" *ngIf="product.signed">Signed on {{product.waiverDate}}</div>
                        </div>
                        <div class="d-flex justify-content-end align-items-end">
                            <button type="submit" class="btn btn-primary"
                                [disabled]="product.signed || companyview">Submit</button>
                        </div>
                    </form>
                </div>
            </ng-container>

            <div *ngIf="waiverselected && !waiver.multipleSignature"
                class="container-fluid btnsheight pt-2 pb-2 rounded border mt-2" style="background-color: white;">
                <form (submit)="signWaiver()" novalidate>
                    <div class="mb-3">
                        <label for="waiversign" class="form-label">Type full name for signature here <b
                                style="color:red;">*</b></label>
                        <input type="text" [ngClass]="{'isinvalid':invalid}" class="form-control" id="waiversign"
                            name="waiverSignature" [(ngModel)]="waiverSignature" [disabled]="isSigned || companyview"
                            (keyup)="checkInput()" required>
                        <div class="form-text text-danger" *ngIf="invalid"><i class="fas fa-exclamation-circle"></i>
                            The
                            sign is required</div>
                        <div class="form-text" *ngIf="isSigned">Signed on {{waiverDate}}</div>
                    </div>
                    <div class="d-flex justify-content-end align-items-end">
                        <button type="submit" class="btn btn-primary"
                            [disabled]="isSigned || companyview">Submit</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-md-10 offset-md-1 bg-light p-5 shadow waiverheight" *ngIf="waivers.length == 0">
            <div id="waivercontent" class="textheight text-secondary text-center pt-3">
                <span class="fs-3" *ngIf="waivers.length > 0">Select a Waiver to Sign...</span>
                <span class="fs-3" *ngIf="waivers.length == 0">Waivers for this order could not be found.</span>
            </div>
            <div *ngIf="waiver?.waiverPDF" class="textheight text-secondary text-center pt-3">
                <iframe class="container-fluid" *ngIf="waiver.waiverPDF && waiver.pdfContent" [src]="pdfEditor"
                    height="460" style="overflow:hidden" frameborder="0"></iframe>
            </div>
            <div *ngIf="waiverselected" class="container-fluid btnsheight pt-2 pb-2 rounded border mt-2"
                style="background-color: white;">
                <form (submit)="signWaiver()" novalidate>
                    <div class="mb-3">
                        <label for="waiversign" class="form-label">Type full name for signature here <b
                                style="color:red;">*</b></label>
                        <input type="text" [ngClass]="{'isinvalid':invalid}" class="form-control" id="waiversign"
                            name="waiverSignature" [(ngModel)]="waiverSignature" [disabled]="isSigned || companyview"
                            (keyup)="checkInput()" required>
                        <div class="form-text text-danger" *ngIf="invalid"><i class="fas fa-exclamation-circle"></i> The
                            sign is required</div>
                        <div class="form-text" *ngIf="isSigned">Signed on {{waiverDate}}</div>
                    </div>
                    <div class="d-flex justify-content-end align-items-end">
                        <button type="submit" class="btn btn-primary"
                            [disabled]="isSigned || companyview">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!multiplewaivers">
        <div class="col-md-10 offset-md-1 bg-light p-5 shadow waiverheight">
            <div *ngIf="!company?.waiverPDF" id="waivercontent" class="textheight text-secondary text-center pt-3">
                <span class="fs-3" *ngIf="waivers.length > 0">Select a Waiver to Sign...</span>
                <span class="fs-3" *ngIf="waivers.length == 0">Waivers for this order could not be found.</span>
            </div>
            <div *ngIf="company?.waiverPDF" class="textheight text-secondary text-center pt-3">
                <iframe class="container-fluid" *ngIf="company.waiverPDF && company.pdfContent" [src]="pdfEditor"
                    height="460" style="overflow:hidden" frameborder="0"></iframe>
            </div>
            <ng-container *ngIf="waiverselected">
                <div *ngFor="let product of waivers[0].products; let i = index"
                    class="container-fluid btnsheight pt-2 pb-2 rounded border mt-2" style="background-color: white;">
                    <form (submit)="signWaiver(product, i)" novalidate>
                        <div *ngIf="company.multipleSignature" class="mb-3 mt-1">
                            <span class="fw-bold">Product Name: </span><span>{{product.productName}} -
                                {{product.productSize}}</span>
                            <hr style="color: #cbd0ff">
                        </div>
                        <div class="mb-3">
                            <label for="waiversign" class="form-label">Type full name for signature here <b
                                    style="color:red;">*</b></label>
                            <input type="text" class="form-control" id="waiversign{{i}}"
                                [disabled]="product.signed || companyview" value="{{product?.waiverSignature}}"
                                required>
                            <div class="form-text" *ngIf="product.signed">Signed on {{product.waiverDate}}</div>
                        </div>
                        <div class="d-flex justify-content-end align-items-end">
                            <button type="submit" class="btn btn-primary"
                                [disabled]="product.signed || companyview">Submit</button>
                        </div>
                    </form>
                </div>
            </ng-container>
            <!--<div *ngIf="waiverselected && !company.multipleSignature"
                class="container-fluid btnsheight pt-2 pb-2 rounded border mt-2" style="background-color: white;">
                <form (submit)="signWaiver()" novalidate>
                    <div class="mb-3">
                        <label for="waiversign" class="form-label">Type full name for signature here <b
                                style="color:red;">*</b></label>
                        <input type="text" [ngClass]="{'isinvalid':invalid}" class="form-control" id="waiversign"
                            name="waiverSignature" [(ngModel)]="waiverSignature" [disabled]="isSigned || companyview"
                            (keyup)="checkInput()" required>
                        <div class="form-text text-danger" *ngIf="invalid"><i class="fas fa-exclamation-circle"></i> The
                            sign is required</div>
                        <div class="form-text" *ngIf="isSigned">Signed the day {{waiverDate}}</div>
                    </div>
                    <div class="d-flex justify-content-end align-items-end">
                        <button type="submit" class="btn btn-primary"
                            [disabled]="isSigned || companyview">Submit</button>
                    </div>
                </form>
            </div>-->
        </div>
    </div>
</div>
<div class="fullscreen" *ngIf="smartWaiver">
    <iframe [src]="smartWaiverUrl" style="overflow:hidden" frameborder="0"></iframe>
</div>