import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RentalService } from '../services/rental.service';
import { CurrentUserService } from '../services/current-user.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { LogService } from '../services/log.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-waiver',
  templateUrl: './waiver.component.html',
  styleUrls: ['./waiver.component.scss']
})
export class WaiverComponent implements OnInit {
  public safeSrc: SafeResourceUrl;
  public isSignAlert = false
  public signature = ''
  public rental: any
  public companywaiver = ''
  public success = false
  public isSignExist = false
  public multiplewaivers: boolean = false
  public waiver: any
  public waivers: any
  public company: any
  public waiversarray: any = []
  public actualdate = moment().format('MMMM Do YYYY');
  public waiverselected = false;
  public waiverSignature = "";
  public waiverDate = "";
  public isSigned: boolean = false;
  public invalid: boolean = false;
  public position: number
  public companyview: boolean = false;
  public pdfEditor: any;
  public loadWaiver: boolean = false;
  public smartWaiver: boolean = false;
  public smartWaiverUrl: any
  constructor(
    public toastr: ToastrService,
    private rentalService: RentalService,
    private route: ActivatedRoute,
    private currenUser: CurrentUserService,
    private _logService: LogService,
    private sanitizer: DomSanitizer
  ) { }
  ngOnInit() {
    Swal.fire({
      title: "Loading Waivers..."
    })
    Swal.showLoading();
    this.getWaiver();
    setTimeout(() => {
      Swal.close();
    }, 3000)
  }
  SafeUrl(url, type?: boolean) {
    if (type) {
      this.smartWaiverUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      this.pdfEditor = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }
  async getWaiver() {
    var rentalID = this.route.snapshot.params.id
    this.waivers = []
    if (rentalID.substring(0, 7) == "company") {
      const company = await this.getCompanyWaiver(rentalID.substring(7));
      this.company = company;
      this.companyview = true;
      if (company.isSmartWaiver) {
        this.multiplewaivers = false;
        this.smartWaiver = company.isSmartWaiver;
        let url = company.smartWaiverLink;
        this.SafeUrl(url, true);
        location.href = url;
      } else {
        if (company.multipleWaivers) {
          this.multiplewaivers = true;
          const waivers = await this.rentalService.getWaiversByCompany(company.id);
          waivers.forEach(waiver => {
            this.waivers.push(waiver)
          })
        } else {
          this.multiplewaivers = false;
          if (company.companyWaiver) {
            let waiverobj = {
              title: "Main Waiver",
              content: company.companyWaiver,
              main: true,
              pdfContent: company.pdfContent,
              waiverPDF: company.waiverPDF
            }
            this.waivers = [waiverobj]
            this.putToSign(waiverobj, 0);
          }
        }
      }
      Swal.close();
    } else { //If the view will be for an existing rental
      const rental: any = await this.rentalService.getRentalByIDPromise(rentalID); //get the rental object
      const company = await this.getCompanyWaiver(rental.companyID); //get the company object
      this.rental = rental; //save the rental object in a variable public
      this.company = company; //save the company object in a variable public
      this.loadWaiver = true; //Start the loadView
      if (company.isSmartWaiver) { //Condition to see if the waiver is SmartWaiver
        this.multiplewaivers = false;
        this.smartWaiver = company.isSmartWaiver;
        var url = company.smartWaiverLink + "?auto_tag=" + rental.id;
        location.href = url;
      } else { //This meansthat the waiver could be one or multiple
        if (company.multipleWaivers) { //IF waiver is multi per company
          this.multiplewaivers = true;
          let promises = []
          this.rental.productsID.forEach(async productid => { //Get the prodcutsID
            const product = await this.rentalService.getWaiversByProduct(productid); //get the product object
            var position = 0
            if (product.waivers) { //If the product have waivers assigned
              let promise = product.waivers.forEach(async waiverid => { //for each wavier id
                const waiver = await this.rentalService.getWaiverById(this.rental.companyID, waiverid); //get the waiver object
                const waiversigned = rental.waiversSigned ? rental.waiversSigned.find(element => element.id == waiver.id && element.productId == product.id) : null //Get the waiver signed
                var productobj = {}
                if (waiversigned) {
                  productobj = {
                    ...JSON.parse(JSON.stringify(product)),
                    waiverDate: waiversigned.waiverDate?.seconds ? moment(waiversigned.waiverDate.seconds * 1000).format("LLLL") : moment(waiversigned.waiverDate).format("LLLL"),
                    waiverSignature: waiversigned.waiverSignature,
                    signed: true
                  }
                } else {
                  productobj = JSON.parse(JSON.stringify(product))
                }
                if (this.waivers.length > 0) {//If we have some waiver saved in the public object
                  const found = this.waivers.find(element => element.id == waiver.id) //Search the id
                  position = this.waivers.indexOf(found); //Get the position
                  if (found) { //If there is a waiver with the same id
                    this.waivers[position].products.push( //push new product
                      productobj
                    )
                  } else { //If the waiver is new in the object
                    this.waivers.push({ ...JSON.parse(JSON.stringify(waiver)), products: [productobj] }) //Push waiver with the new product
                  }
                } else { //if the object is empty
                  this.waivers = [{ ...JSON.parse(JSON.stringify(waiver)), products: [productobj] }] //push waiter with the new product
                }
              })
              promises.push(promise)
            }
          })
          Promise.all(promises).then(() => {
            setTimeout(() => {
              //this.getWaiversById(); No more needed
              this.validateWaiverSing();
              Swal.close();
            }, 1000)
          })
        } else {
          this.multiplewaivers = false;
          let products = []
          if (company.multipleSignature) {
            this.rental.productsID.forEach(async productid => {
              var productobj = {}
              const product = await this.rentalService.getWaiversByProduct(productid); //get the product object
              const waiversigned = rental.waiversSigned ? rental.waiversSigned.find(element => element.productId == product.id) : null //Get the waiver signed
              if (waiversigned) {
                productobj = {
                  ...JSON.parse(JSON.stringify(product)),
                  waiverDate: waiversigned.waiverDate?.seconds ? moment(waiversigned.waiverDate.seconds * 1000).format("LLLL") : moment(waiversigned.waiverDate).format("LLLL"),
                  waiverSignature: waiversigned.waiverSignature,
                  signed: true
                }
              } else {
                productobj = JSON.parse(JSON.stringify(product))
              }
              products.push(productobj); //push waiter with the new product

            })
          } else {
            var productobj = {}
            const product = await this.rentalService.getWaiversByProduct(this.rental.productsID[0]); //get the product object
            const waiversigned = rental.waiversSigned ? rental.waiversSigned.find(element => element.productId == product.id) : null //Get the waiver signed
            if (waiversigned) {
              productobj = {
                ...JSON.parse(JSON.stringify(product)),
                waiverDate: waiversigned.waiverDate?.seconds ? moment(waiversigned.waiverDate.seconds * 1000).format("LLLL") : moment(waiversigned.waiverDate).format("LLLL"),
                waiverSignature: waiversigned.waiverSignature,
                signed: true
              }
            } else {
              productobj = JSON.parse(JSON.stringify(product))
            }
            products.push(productobj); //push waiter with the new product
          }
          let waiverobj = {
            title: "Main Waiver",
            content: company.companyWaiver,
            main: true,
            id: "first",
            pdfContent: company.pdfContent,
            waiverPDF: company.waiverPDF,
            products: products
          }
          this.waivers.push(waiverobj)
          this.putToSign(waiverobj, 0);

        }
      }
    }
  }
  async getCompanyWaiver(id) {
    const company = await this.rentalService.getCompanyWaiver(id);
    return company
  }
  async getWaiversById() {
    return this.waiversarray.forEach(async waiverid => {
      if (waiverid != "first") {
        const waiver = await this.rentalService.getWaiverById(this.rental.companyID, waiverid);
        if (waiver.multipleSignature) {
        } else {
          if (this.rental.waiversSigned) {
            const found = this.rental.waiversSigned.find(element => element.id == waiver.id);
            found ? this.waivers.push({ ...waiver, signed: true, ...found }) : this.waivers.push(waiver)
          } else {
            this.waivers.push(waiver)
          }
        }
      }
    })

  }

  async putToSign(waiver, i) {
    await this.SafeUrl(waiver.pdfContent);
    if (this.multiplewaivers) {
      const div = document.getElementById("waivercontent") as HTMLElement
      div.style.display = "none";
    }
    this.invalid = false;
    if (waiver.isSigned) {
      this.waiver = waiver;
      this.isSigned = true;
      try{
        this.waiverSignature = waiver.products[0].waiverSignature;
        if (waiver.waiverDate?.seconds) {
          this.waiverDate = moment(waiver.products[0].waiverDate.seconds * 1000).format("LLLL");
        } else {
          this.waiverDate = moment(waiver.products[0].waiverDate).format("LLLL");
        }
      }
      catch{
        this.waiverSignature = waiver.waiverSignature;
        if (waiver.waiverDate?.seconds) {
          this.waiverDate = moment(waiver.waiverDate.seconds * 1000).format("LLLL");
        } else {
          this.waiverDate = moment(waiver.waiverDate).format("LLLL");
        }
      }
    } else {
      this.waiver = waiver;
      this.isSigned = false;
      this.waiverSignature = "";
      this.waiverDate = "";
      this.position = i;
    }
    this.waiverselected = true;
    if (waiver.waiverPDF) {
    } else {
      const div = document.getElementById("waivercontent") as HTMLElement
      div.style.display = "block";
      this.pdfEditor = "";
      document.getElementById("waivercontent").innerHTML = waiver.content;
    }

  }
  checkInput(i?) {
    if (this.company.multipleWaivers) {
      if (this.waiver.multipleSignature) {
        var input = document.getElementById("waiversign" + i.toString()) as HTMLInputElement;
        if (input.value == "") {
          input.style.border = "1px solid red";
          this.invalid = true;
        } else {
          this.waiverSignature = input.value;
          input.style.border = "1px solid lightgrey";
          this.invalid = false;
        }
      } else {
        if (this.waiverSignature == "") {
          this.invalid = true;
        } else {
          this.invalid = false;
        }
      }
    } else {

      var input = document.getElementById("waiversign" + i.toString()) as HTMLInputElement;
      if (input.value == "") {
        input.style.border = "1px solid red";
        this.invalid = true;
      } else {
        this.waiverSignature = input.value;
        input.style.border = "1px solid lightgrey";
        this.invalid = false;
      }

    }
  }
  validateWaiverSing() {
    this.waivers.forEach((waiver, index) => {
      let statussigned: boolean = waiver.multipleSignature;
      waiver.products.forEach(product => {
        if (waiver.multipleSignature) {
          if (product.signed == undefined) {
            statussigned = false;
          }
        } else {
          product.signed == true ? statussigned = true : ""
        }
      });
      this.waivers[index]['isSigned'] = statussigned;
    })
  }
  signWaiver(product?, i?, waiver?) {
    this.checkInput(i);
    if (!this.invalid) {
      if (this.rental.waiversSigned) {
        this.rental.waiversSigned.push({
          id: this.waiver.main ? "first" : this.waiver.id,
          productId: product?.id ? product.id : this.waiver.products[0].id,
          waiverDate: new Date(),
          waiverSignature: this.waiverSignature
        })
      } else {
        this.rental.waiversSigned = [{
          id: this.waiver.main ? "first" : this.waiver.id,
          productId: this.company.multipleSignature ? product.id : this.waiver.products[0].id,
          waiverDate: new Date(),
          waiverSignature: this.waiverSignature
        }]
      }
      this.rentalService.updateRental(this.rental, this.rental.id);
      if (this.waiver.multipleSignature || (this.company.multipleSignature && this.company.multipleWaivers == false)) {
        //!this.waiver.main ? this.waivers[this.position].id = this.waiver.id : ""
        this.waivers[this.position].products[i].waiverDate = moment(new Date()).format("LLLL")
        this.waivers[this.position].products[i].waiverSignature = this.waiverSignature
        this.waivers[this.position].products[i].signed = true
        //this.putToSign(this.waiver, this.position);
        this._logService.addRentalWaiverLog(this.rental.id, "Waiver " + this.waivers[this.position].title + " signed", this.rental.userInfo.name, this.rental.userInfo.lastName, this.waiverSignature);
      } else {
        !this.waiver.main ? this.waivers[this.position].id = this.waiver.id : ""
        this.waivers[this.position].waiverDate = moment(new Date()).format("LLLL")
        this.waivers[this.position].waiverSignature = this.waiverSignature
        this.waivers[this.position].isSigned = true
        this.waivers[this.position].products[0].waiverDate = new Date()
        this.waivers[this.position].products[0].waiverSignature = this.waiverSignature
        this.waivers[this.position].products[0].signed = true
        this.putToSign(this.waiver, this.position);
        this._logService.addRentalWaiverLog(this.rental.id, "Waiver " + this.waivers[this.position].title + " signed", this.rental.userInfo.name, this.rental.userInfo.lastName, this.waiverSignature);
      }

      this.validateWaiverSing();
      Swal.fire({
        title: "Waiver Signed",
        html: "The waiver has been signed successfully",
        icon: "success"
      })
    }
  }

}
