<div class="container">
  <!-- CUSTOMER INFO FORM-->
  <form [formGroup]="customerInfoForm" (ngSubmit)="handleSubmit($event)" class="needs-validation" id="customerInfoForm"
    novalidate>
    <!-- FORM-->
    <div class="row">
      <!-- FIRST NAME-->
      <div class="col-sm-12 col-12 col-md-6 col-lg-6">
        <label for="name">First Name <b class="text-danger">*</b></label>
        <div class="input-group mb-3">
          <input type="text" id="name" placeholder="First Name" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" formControlName="firstName" required>
          <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
            <div *ngIf="f.firstName.errors.required">First Name is required</div>
          </div>
        </div>
      </div>
      <!-- LAST NAME-->
      <div class="col-sm-12 col-12 col-md-6 col-lg-6">
        <label for="name">Last Name <b class="text-danger">*</b></label>
        <div class="input-group mb-3">
          <input type="text" id="lastName" placeholder="Last Name" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" formControlName="lastName" required>
          <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
            <div *ngIf="f.lastName.errors.required">Last Name is required</div>
          </div>
        </div>
      </div>
      <!-- EMAIL-->
      <div class="col-sm-12 col-12 col-md-6 col-lg-6">
        <div class="form-group">
          <label for="email">Email <b class="text-danger">*</b></label>
          <div class="input-group mb-3">
            <input type="email" #emailInput id="email" placeholder="Email" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email" required>
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">Email is invalid</div>
            </div>
          </div>
        </div>
      </div>
      <!-- PHONE-->
      <div class="col-sm-12 col-12 col-md-6 col-lg-6">
        <div class="form-group">
          <label for="phone">Phone <b class="text-danger">*</b></label>
          <ngx-intl-tel-input [cssClass]="'custom'" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
            [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
            [searchCountryFlag]="true" [searchCountryField]="[searchCountryField.Iso2,searchCountryField.Name]"
            [selectFirstCountry]="false" [selectedCountryISO]="countryISO.UnitedStates" [maxLength]="15"
            [phoneValidation]="true" [separateDialCode]="true" [enableAutoCountrySelect]="true"
            [numberFormat]="phoneNumberFormat.International" formControlName="phone" id="phone">
          </ngx-intl-tel-input>
          <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
            <div *ngIf="f.phone.errors.required">Phone is required</div>
          </div>
        </div>
      </div>
      <!-- ADDRESS-->
      <div class="col-sm-12 col-12 col-md-6 col-lg-12">
        <div class="form-group">
          <label for="address">Address <b class="text-danger" *ngIf="!isPartner">*</b></label>
          <div class="input-group mb-3">
            <input type="text" #addressInput id="address" placeholder="Address" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.address.errors }" formControlName="address" required>
            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
              <div *ngIf="f.address.errors.required">Address is required</div>
            </div>
          </div>
        </div>
      </div>
      <!-- ADDRESS 2-->
      <div class="col-sm-12 col-12 col-md-6 col-lg-12">
        <div class="form-group">
          <label for="address2">Address 2</label>
          <div class="input-group mb-3">
            <input type="text" #addressInput id="address2" placeholder="Address 2" class="form-control"
              formControlName="address2">
            <div class="invalid-feedback">
              Please put a valid address
            </div>
          </div>
        </div>
      </div>
      <!-- CITY-->
      <div class="col-sm-12 col-12"
        [ngClass]="{'col-md-7 col-lg-7': states.length <= 0, 'col-md-4 col-lg-4': states.length > 0}">
        <div class="form-group">
          <label for="city">City <b class="text-danger" *ngIf="!isPartner">*</b></label>
          <div class="input-group mb-3">
            <input type="text" id="city" placeholder="City" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.city.errors }" formControlName="city" required>
            <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
              <div *ngIf="f.city.errors.required">City is required</div>
            </div>
          </div>
        </div>
      </div>
      <!-- CONTRY-->
      <div class="col-sm-12 col-12 col-md-3 col-lg-3">
        <div class="form-group">
          <label for="country">Country <b class="text-danger" *ngIf="!isPartner">*</b></label>
          <div class="input-group mb-3">
            <select id="country" class="form-select" [ngClass]="{ 'is-invalid': submitted && f.country.errors }"
              (change)="setStates($event)" formControlName="country" required>
              <option *ngFor="let country of countries" [selected]=" country.shortName == 'US'"
                [value]=country.shortName>
                {{ country.name }}
              </option>
            </select>
            <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
              <div *ngIf="f.country.errors.required">Country is required</div>
            </div>
          </div>
        </div>
      </div>
      <!-- STATE -->
      <div class="col-sm-12 col-12 col-md-3 col-lg-3" *ngIf="states.length > 0">
        <div class="form-group">
          <label for="state">{{stateTitle}} <b class="text-danger" *ngIf="!isPartner">*</b></label>
          <div class="input-group mb-3">
            <select id="state" class="form-select" [formControl]="state" formControlName="state"
              [ngClass]="{ 'is-invalid': submitted && f.state.errors }" [required]="states.length > 0 && !isPartner">
              <option value="" selected disabled>Select State</option>
              <option *ngFor="let state of states" [value]="state">
                {{ state }}
              </option>
            </select>
            <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
              <div *ngIf="f.state.errors.required">{{stateTitle}} is required</div>
            </div>
          </div>
        </div>
      </div>
      <!-- ZIP CODE -->
      <div class="col-sm-12 col-12 col-md-2 col-lg-2">
        <div class="form-group">
          <label for="zip">{{zipTitle}} <b class="text-danger" *ngIf="!isPartner">*</b></label>
          <div class="input-group mb-3">
            <input type="text" id="zip" placeholder="{{zipTitle == 'Postal Code' ? 'P.C.': zipTitle}}"
              class="form-control" [ngClass]="{ 'is-invalid': submitted && f.zip.errors }" formControlName="zip"
              required>
            <div *ngIf="submitted && f.zip.errors" class="invalid-feedback">
              <div *ngIf="f.zip.errors.required">{{zipTitle}} is required</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BUTTON-->
    <div class="row " >
      <!--
        <div class="col-md-4 mb-2 text-right" *ngIf="isDev">
        <button id="submit" (click)="handleSubmit($event); isTestStripe = true; isSkipPayment = false" type="submit" class="btn btn-secondary float-right">
          <div class="spinner hidden" id="spinner"></div>
          <span id="button-text">Continue to Test Payment</span>
        </button>
      </div>
      -->
      <div class="col-md-4 offset-md-4 mb-2 text-right">
        <button *ngIf="!pmtLinkRentalDoc && ((isDev || isPartner) && userBelongsToCompany)" id="submit" (click)="handleSubmit($event); isSkipPayment = true; isTestStripe  = false;" type="submit" class="btn btn-danger float-right me-4">
          <div class="spinner hidden" id="spinner"></div>
          <span id="button-text">Skip Payment</span>
        </button>
      </div>
      <div class="col-md-4 mb-2 text-end align-items-end">
        <button id="submit" (click)="handleSubmit($event); isTestStripe = false; isSkipPayment = false" type="submit" class="btn btn-primary float-right">
          <div class="spinner hidden" id="spinner"></div>
          <span id="button-text">Continue to Payment</span>
        </button>
      </div>
    </div>
  </form>
</div>
