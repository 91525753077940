import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FirebaseEmulatorsService } from 'src/app/services/firebase-emulators.service';
import { HttpService } from 'src/app/services/http.service';
import { throwError } from 'rxjs';

// Models
import { FirebaseEnabled } from 'src/app/models/firebase-emulators.model'

@Component({
  selector: 'app-affiliateCookieSetter',
  templateUrl: './affiliateCookieSetter.component.html',
  styleUrls: ['./affiliateCookieSetter.component.css']
})
export class AffiliateCookieSetterComponent implements OnInit {

  private fbEnabled: FirebaseEnabled

  constructor(
    private activatedRoute: ActivatedRoute,
    private afFun: AngularFireFunctions,
    private fsEmulatorService: FirebaseEmulatorsService,
    private httpService: HttpService,
    private router: Router,
  ) {

    this.fbEnabled = this.fsEmulatorService.useEmulators({
      afFunctions: this.afFun
    })
  }

  ngOnInit() {
    // get the affiliate id from the query params
    const af: string | undefined = this.activatedRoute.snapshot.paramMap.get("af");
    if (!af) {
      console.error("affiliateCookieSetter::ngOnInit() missing affiliate id")
      this.router.navigate(['/404'])
      return
    }

    let link: string;
    if(this.fbEnabled.functions) {
      // Emulator -> `http://localhost:5001/fleetmaid-dev-1/us-west4/affiliateClicks?af=${af}`
      link = (`http://localhost:5001/${environment.firebase.projectId}/${environment.google.region}/affiliateClicks?af=${af}`);
    } else{
      // https://dev-api.fleetmaid.com/affiliate?af=:af
      link = (`https://${environment.app.apiDomainName}/affiliate/?af=${af}`);
    }

    // redirect to the api affiliate url
    window.location.href = link;
  }
}
