import { Component } from '@angular/core';

@Component({
  selector: 'app-booking-footer',
  templateUrl: './booking-footer.component.html',
  styleUrls: ['./booking-footer.component.css']
})
export class BookingFooterComponent {

  constructor() { }

}
