<div *ngIf="showPage">
  <ng-container [ngSwitch]="widget.widgetType">

    <!---------- Check Box -------------->
    <div *ngSwitchCase="'checkbox'">
      <div class="d-flex align-items-center">

      <label style="cursor:pointer; margin: 5px; margin-left: 10px;" [ngClass]="{
          'border': widget.componentSettings.border
        }" [ngStyle]="{
          'padding-top': widget.componentSettings.padding_top + 'px',
          'padding-right': widget.componentSettings.padding_right + 'px',
          'padding-bottom': widget.componentSettings.padding_bottom + 'px',
          'padding-left': widget.componentSettings.padding_left + 'px',
          'border-radius': widget.componentSettings.rounded_corners + 'px',
          'background-color': widget.componentSettings.background_color || 'white',
          'color' : widget.componentSettings.text_color,
          'text-align' : widget.componentSettings.alignment,
          height:
          widget.componentSettings.height > 0
            ? widget.componentSettings.height + 'px'
            : 'auto',
          'font-size':
          widget.componentSettings.text_size > 0
            ? widget.componentSettings.text_size + 'px'
            : 'auto'
        }">
  
        <input type="checkbox" (click)="onClickedCheck(displayID)" [checked]="widget.element?.inputValue">
        
        <span style="padding-left:7px">
          <span *ngIf="!widget.element.recurringPrice && widget.element.price" class="price-container" [ngStyle]="{
            'font-size':
            widget.componentSettings.text_size > 0
            ? widget.componentSettings.text_size - 1 + 'px'
            : 'auto'}">
            ${{widget.element.price}}
          </span>
          <span *ngIf="widget.element.recurringPrice && widget.element.price" class="price-container" [ngStyle]="{
            'font-size':
            widget.componentSettings.text_size > 0
            ? widget.componentSettings.text_size - 1 + 'px'
            : 'auto'}">
            ${{widget.element.price}} per day
          </span>
  
          <span class="widget-label" [ngStyle]="{
              'font-size':
              widget.componentSettings.text_size > 0
              ? widget.componentSettings.text_size + 'px'
              : 'auto'}">{{ widget.element.label }}
          </span>
          <span *ngIf="widget.element.is_required && widget.element.inputValue == false"><i class="fas fa-asterisk fa-xs"
              style="color:red"></i></span>
          <br>
          <span *ngIf="widget.element.description" style="color: #697886; margin-left: 17px;" [ngStyle]="{
            'font-size':
            widget.componentSettings.text_size > 0
            ? widget.componentSettings.text_size - 2 + 'px'
            : 'auto'}">{{ widget.element.description }}
          </span>
        </span>
  
      </label>
      </div>
    </div>
  
    <!---------- Number Quantity ------------>
    <div *ngSwitchCase="'quantity'" [ngClass]="{
          'border': widget.componentSettings.border
        }" [ngStyle]="{
          'padding-top': widget.componentSettings.padding_top + 'px',
          'padding-right': widget.componentSettings.padding_right + 'px',
          'padding-bottom': widget.componentSettings.padding_bottom + 'px',
          'padding-left': widget.componentSettings.padding_left + 'px',
          'background-color': widget.componentSettings.background_color,
          'border-radius': widget.componentSettings.rounded_corners + 'px',
          'color' : widget.componentSettings.text_color,
          'text-align' : widget.componentSettings.alignment,
          height:
          widget.componentSettings.height > 0
            ? widget.componentSettings.height + 'px'
            : 'auto',
          'font-size':
          widget.componentSettings.text_size > 0
            ? widget.componentSettings.text_size + 'px'
            : 'auto'
          }">
  
      <span *ngIf="widget.element?.max && widget.element.inputValue > widget.element?.max" style="color:red;">
        <i class="fas fa-asterisk fa-xs" style="color:red">
        </i>
        The maximum allowed quantity is {{widget.element?.max}}
      </span>
  
      <div class="d-flex align-items-center">
            <div>
              <input type="number" 
                class="form-check-input"
                [(ngModel)]="widget.element.inputValue" 
                style="width:3em; margin-right: 3px;" 
                class="quantityInput"
                [min]='widget.element.min ? widget.element.min : 0'
                [max]='widget.element.max ? widget.element.max : 999'
                id="quantity_{{displayID}}{{widget.id}}"
                (change)="quantityInputChange(displayID, widget.element)"
                (keyup)="quantityInputChange(displayID, widget.element)"
                oninput="this.value = this.value.replace(/[^0-9]/g, '')"/>
            </div>
  
        <div style="padding-left:7px">
          <span *ngIf="!widget.element.recurringPrice && widget.element.price" class="price-container" [ngStyle]="{
              'font-size':
              widget.componentSettings.text_size > 0
              ? widget.componentSettings.text_size - 1 + 'px'
              : 'auto'}">
            ${{widget.element.price}}
          </span>
          <span *ngIf="widget.element.recurringPrice && widget.element.price" class="price-container" [ngStyle]="{
              'font-size':
              widget.componentSettings.text_size > 0
              ? widget.componentSettings.text_size - 1 + 'px'
              : 'auto'}">
            ${{widget.element.price}} per day
          </span>
  
          <span class="widget-label" [ngStyle]="{
              'font-size':
              widget.componentSettings.text_size > 0
              ? widget.componentSettings.text_size + 'px'
              : 'auto'}">{{ widget.element.label }}
          </span>
          <span *ngIf="widget.element.is_required && widget.element.inputValue == null || widget.element.is_required  && widget.element.inputValue == undefined
                || widget.element?.min && widget.element.inputValue < widget.element?.min">
            <i class="fas fa-asterisk fa-xs" style="color:red"></i>
          </span>
          <br>
          
  
          <span style="color: #697886; margin: 0px;" [ngStyle]="{
              'font-size':
              widget.componentSettings.text_size > 0
              ? widget.componentSettings.text_size - 2 + 'px'
              : 'auto'}">{{ widget.element.description }}
          </span>
        </div>
      </div>
    </div>
  
    <!---------- Price ------------>
    <div *ngSwitchCase="'price'" [ngClass]="{
          'border': widget.componentSettings.border
        }" [ngStyle]="{
          'padding-top': widget.componentSettings.padding_top + 'px',
          'padding-right': widget.componentSettings.padding_right + 'px',
          'padding-bottom': widget.componentSettings.padding_bottom + 'px',
          'padding-left': widget.componentSettings.padding_left + 'px',
          'background-color': widget.componentSettings.background_color,
          'border-radius': widget.componentSettings.rounded_corners + 'px',
          'color' : widget.componentSettings.text_color,
          'text-align' : widget.componentSettings.alignment,
          height:
          widget.componentSettings.height > 0
            ? widget.componentSettings.height + 'px'
            : 'auto',
          'font-size':
          widget.componentSettings.text_size > 0
            ? widget.componentSettings.text_size + 'px'
            : 'auto'
          }">
  
      <span *ngIf="!widget.element.recurringPrice && widget.element.price" class="price-container" [ngStyle]="{
            'font-size':
            widget.componentSettings.text_size > 0
            ? widget.componentSettings.text_size - 1 + 'px'
            : 'auto'}">
        ${{widget.element.price}}
      </span>
      <span *ngIf="widget.element.recurringPrice && widget.element.price" class="price-container" [ngStyle]="{
            'font-size':
            widget.componentSettings.text_size > 0
            ? widget.componentSettings.text_size - 1 + 'px'
            : 'auto'}">
        ${{widget.element.price}} per day
      </span>
  
  
      <span class="widget-label" [ngStyle]="{
            'font-size':
            widget.componentSettings.text_size > 0
            ? widget.componentSettings.text_size + 'px'
            : 'auto'}">
        {{ widget.element.label }}
      </span>
  
      <span style='margin: 0px; color: #697886' [ngStyle]="{
          'font-size':
          widget.componentSettings.text_size > 0
          ? widget.componentSettings.text_size - 2 + 'px'
          : 'auto'}">
        {{ widget.element.description }}
      </span>
    </div>
  
    <!----------- Text Area -------------->
    <div *ngSwitchCase="'textarea'">
      <!-- <div
          id="editor"
          [(ngModel)]="widget.element.content"
          >
        </div> -->
      <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="widget.element.content" [disabled]="!editMode || (widget?.savedWidgetId != '' && !savedWidgetEditor)">
      </ckeditor>
    </div>
  
    <!---------- Image ------------>
    <div *ngSwitchCase="'image'" [ngStyle]="{
        width:
        widget.element.width > 0
          ? widget.element.width + '%'
          : 'auto'
        }">
      <img [src]="widget.element.image_link" class="img-responsive" style="width:100%; height:auto;">
    </div>
  
    <!------------ Radios ------------->
    <div *ngSwitchCase="'radios'" [ngClass]="{
          'border': widget.componentSettings.border
        }" [ngStyle]="{
          'padding-top': widget.componentSettings.padding_top + 'px',
          'padding-right': widget.componentSettings.padding_right + 'px',
          'padding-bottom': widget.componentSettings.padding_bottom + 'px',
          'padding-left': widget.componentSettings.padding_left + 'px',
          'background-color': widget.componentSettings.background_color,
          'border-radius': widget.componentSettings.rounded_corners + 'px',
          'color' : widget.componentSettings.text_color,
          'text-align' : widget.componentSettings.alignment,
          height:
          widget.componentSettings.height > 0
            ? widget.componentSettings.height + 'px'
            : 'auto',
          'font-size':
          widget.componentSettings.text_size > 0
            ? widget.componentSettings.text_size + 'px'
            : 'auto'
          }">
  
      <div style="color: #1F2428;" [ngStyle]="{
            'font-size':
            widget.componentSettings.text_size > 0
            ? widget.componentSettings.text_size + 'px'
            : 'auto'}">
        {{ widget.element.label }}
      </div>
  
      <div>
        <!---Puts radios in a line-->
        <!-- <div class="d-flex flex-wrap" > -->
  
  
        <div class="form-check" style="margin-right: 10px; margin-top: 6px;"
          *ngFor="let choice of widget.element.options">
  
            <input
              style="text-align:left;"
              class="form-check-input"
              type="radio"
              name="{{displayID}}radio[{{ widget.id }}]"
              id="{{displayID}}radio_{{ choice.id }}"
              [value]="choice.id"
              [checked]="choice.is_selected"
              (change)="updateSelectOrRadios(widget?.widgetType, widget?.element?.options, $event, displayID);"
            />
  
          <span *ngIf="!widget.element.recurringPrice && choice.price || !widget.element.recurringPrice && choice.price == 0" class="price-container" [ngStyle]="{
            'font-size':
            widget.componentSettings.text_size > 0
            ? widget.componentSettings.text_size - 1 + 'px'
            : 'auto'}">
            ${{choice.price}}
          </span>
          <span *ngIf="widget.element.recurringPrice && choice.price || widget.element.recurringPrice && choice.price == 0" class="price-container" [ngStyle]="{
            'font-size':
            widget.componentSettings.text_size > 0
            ? widget.componentSettings.text_size - 1 + 'px'
            : 'auto'}">
            ${{choice.price}} per day
          </span>
  
  
          <label class="form-check-label" for="radio_{{ choice.id }}" style="color: #697886; margin: 0px;" [ngStyle]="{
              'font-size':
              widget.componentSettings.text_size > 0
              ? widget.componentSettings.text_size + 'px'
              : 'auto'}">
            {{ choice.text }}
          </label>
  
        </div>
      </div>
    </div>
  
    <!------------ Dropdowns ------------->
    <div *ngSwitchCase="'dropdown'" [ngClass]="{
          'border': widget.componentSettings.border
        }" [ngStyle]="{
          'padding-top': widget.componentSettings.padding_top + 'px',
          'padding-right': widget.componentSettings.padding_right + 'px',
          'padding-bottom': widget.componentSettings.padding_bottom + 'px',
          'padding-left': widget.componentSettings.padding_left + 'px',
          'background-color': widget.componentSettings.background_color,
          'border-radius': widget.componentSettings.rounded_corners + 'px',
          'color' : widget.componentSettings.text_color, 
          'text-align' : widget.componentSettings.alignment,
  
          height:
          widget.componentSettings.height > 0
            ? widget.componentSettings.height + 'px'
            : 'auto',
          'font-size':
          widget.componentSettings.text_size > 0
            ? widget.componentSettings.text_size + 'px'
            : 'auto'
        }">
      <!-- <span *ngIf="widget.element.is_required" class="text-danger">*</span> -->
  
      <div style="color: #1F2428;" [ngStyle]="{
            'font-size':
            widget.componentSettings.text_size > 0
            ? widget.componentSettings.text_size + 'px'
            : 'auto'}">
        {{ widget.element.label }}
      </div>
  
      <div>
  
          <select
            class="form-select"
            name="{{displayID}}dropdown{{ widget.id }}"
            id="{{displayID}}dropdown_{{ widget.id }}"
              (change)="updateSelectOrRadios(widget?.widgetType, widget?.element?.options, $event, displayID); "
            style="max-width: 400px">
  
          <option [value]="choice.id" *ngFor="let choice of widget.element.options" [selected]="choice.is_selected">
            {{ choice.text }}
  
            <span *ngIf="!widget.element.recurringPrice && choice.price || !widget.element.recurringPrice && choice.price == 0" class="price-container" [ngStyle]="{
              'font-size':
              widget.componentSettings.text_size > 0
              ? widget.componentSettings.text_size - 1 + 'px'
              : 'auto'}">
              (${{choice.price}})
            </span>
            <span *ngIf="widget.element.recurringPrice && choice.price || widget.element.recurringPrice && choice.price == 0" class="price-container" [ngStyle]="{
              'font-size':
              widget.componentSettings.text_size > 0
              ? widget.componentSettings.text_size - 1 + 'px'
              : 'auto'}">
              (${{choice.price}} per day)
            </span>
  
          </option>
        </select>
  
      </div>
    </div>
  
    <!---------- Product ------------>
    <div *ngSwitchCase="'product'" [ngClass]="{
          'border': widget.componentSettings.border
        }" [ngStyle]="{
          'padding-top': widget.componentSettings.padding_top + 'px',
          'padding-right': widget.componentSettings.padding_right + 'px',
          'padding-bottom': widget.componentSettings.padding_bottom + 'px',
          'padding-left': widget.componentSettings.padding_left + 'px',
          'background-color': widget.componentSettings.background_color,
          'border-radius': widget.componentSettings.rounded_corners + 'px',
          'color' : widget.componentSettings.text_color,
          height:
          widget.componentSettings.height > 0
            ? widget.componentSettings.height + 'px'
            : 'auto',
          'font-size':
          widget.componentSettings.text_size > 0
            ? widget.componentSettings.text_size + 'px'
            : 'auto'
          }">
      <!-- <h2 *ngIf="widget.element.label" style="margin-bottom: 8px"
          [ngStyle]="{
            'font-size':
            widget.componentSettings.text_size > 0
              ? widget.componentSettings.text_size + 2 + 'px'
              : 'auto'
          }"
          >{{widget.element.label}}</h2> -->
  
      <span *ngIf="widget.element.name; else noneChosen">

        <span *ngIf="widget.element.options.length == 0 && editMode">No Active Products</span>
        <!-- INPUT NUMBERS TEMPLATE -->
        <ng-container *ngIf="!widget.element.isDropdown; else product_select">
          <span *ngIf="widget.element.is_required && !productRequirementMet"><i class="fas fa-asterisk fa-xs"
            style="color:red; padding: 0px 0px 0px 12px"></i>
              <span *ngIf="widget.element.min || widget.element.min == 0" style="color:red;">
                <span *ngIf="widget.element.min == 1"> Requires an input of at least {{widget.element.min}} product addon</span>
                <span *ngIf="widget.element.min > 1"> Requires an input of at least {{widget.element.min}} product addons total</span>
              </span>
            </span>
            <div *ngFor="let option of widget.element.options">
              <span *ngIf="option.inputValue > option?.max" style="color:red;">
                <span *ngIf="option.max === 0"> There are no products available for this size</span>
                <span *ngIf="option.max === 1"> Only {{option.max}} product available for this size</span>
                <span *ngIf="option.max > 1"> Only {{option.max}} products available for this size</span>
              </span>
              <span *ngIf="option.show">
                <div class="d-flex align-items-center" >
                    <div style="padding: 0px 0px 0px 0px">
                      <input 
                        [(ngModel)]="option.inputValue"
                        id="{{displayID}}{{widget.id}}"
                        type="number"
                        min=0
                        max="{{option?.max}}" 
                        style="width: 4em;"
                        (change)="productWidgetChange(option, widget.id, displayID)"
                        (keyup)="productWidgetChange(option, widget.id, displayID)"
                        oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                        [disabled]="option.isDisabled && !editMode || option.max < 1 && !editMode && option.inputValue < 1"
                        />
                    </div>
                    <div style="padding:7px 10px 7px 15px">
                      <span       
                        style="color: #1F2428; "
                        [ngStyle]="{
                        'font-size':
                        widget.componentSettings.text_size > 0
                        ? widget.componentSettings.text_size + 'px'
                        : 'auto'}">{{ widget.element.name }}, {{ option.size }}
                      </span>
                      
                    <br>
                    <span *ngIf="widget.element.show_description" style='margin: 0px 0px 8px 0px; color: #697886' [ngStyle]="{
                          'font-size':
                          widget.componentSettings.text_size > 0
                          ? widget.componentSettings.text_size - 2 + 'px'
                          : 'auto'}">{{ widget.element.description }}
                    </span>
                  </div>
                  <!-- If it is in view mode then display the product price -->
                  <div style="padding: 0px 15px 0px 12px;" *ngIf="!editMode">
                    <span *ngIf="widget.element.price" style="font-weight:bold; color:#0a6ece" [ngStyle]="{
                            'font-size':
                            widget.componentSettings.text_size > 0
                              ? widget.componentSettings.text_size - 1 + 'px'
                              : 'auto'
                          }">  ${{widget.element.price}}
                    </span>
                  </div>
                  <!-- If you are editing just display the price by day -->
                  <div style="padding: 0px 15px 0px 12px;" *ngIf="editMode">
                    <span>
                      <!-- If there is a day price set-->
                      <span *ngIf="widget.element.priceByDay[0]" style="font-weight:bold; color:#0a6ece"
                        [ngStyle]="{
                            'font-size':
                            widget.componentSettings.text_size > 0
                              ? widget.componentSettings.text_size - 1 + 'px'
                              : 'auto'
                          }">
                        <span>${{ widget.element.priceByDay[0]?.price }}</span>
                      </span>
                      
                      <!-- If there is only an hourly price set-->
                      <span *ngIf="!widget.element.priceByDay[0] && widget.element.priceByHour[0]" style="font-weight:bold; color:#0a6ece"
                      [ngStyle]="{
                          'font-size':
                          widget.componentSettings.text_size > 0
                            ? widget.componentSettings.text_size - 1 + 'px'
                            : 'auto'
                        }">
                      <span>${{ widget.element.priceByHour[0]?.price }}</span>
                    </span>

                      <!-- If there is no price set-->
                    <span *ngIf="!widget.element.priceByDay[0] && !widget.element.priceByHour[0]" style="font-weight:bold; color:#0a6ece"
                      [ngStyle]="{
                          'font-size':
                          widget.componentSettings.text_size > 0
                            ? widget.componentSettings.text_size - 1 + 'px'
                            : 'auto'
                        }">
                      <span>$0</span>
                    </span>
                    </span>

                    </div>

                    <div style="max-width: 100px; padding: 2px; margin-left: auto; justify-content: flex-end;">
                      <img src="{{ widget.element?.['groupImage'] }}"
                      class="img-fluid" style="max-width:100%; height:auto;">
                    </div>
                </div>
  
  
              </span>
            </div>
        </ng-container>
        <!-- DROPDOWN TEMPLATE -->
        <ng-template #product_select>

          <span *ngFor="let choice of widget.element.options">
            <span *ngIf="choice.inputValue > choice.max" style="color:red;">
              <span *ngIf="choice.max === 0"> This size has become unavailable, please choose another size</span>
              <span *ngIf="choice.max === 1"> Only {{choice.max}} product available for this size</span>
              <span *ngIf="choice.max > 1"> Only {{choice.max}} products available for this size</span>
            </span>
          </span>

            <div style="color: #1F2428;" [ngStyle]="{
              'font-size':
              widget.componentSettings.text_size > 0
              ? widget.componentSettings.text_size + 'px'
              : 'auto'}">
                {{ widget.element.name }} 
                <span style="font-weight:bold; color:#0a6ece" *ngIf="widget.element.show_price_label && widget.element.priceByDay" > 
                  ${{ !editMode ? widget.element.price: widget.element.priceByDay[0].price }}
                </span>
                <span *ngIf="widget.element.is_required && !productRequirementMet"><i class="fas fa-asterisk fa-xs"
                  style="color:red"></i>
                </span>
            </div>
            <div style="display: flex;">
              <div>
                <select class="form-select" name="dropdown_{{ widget.id }}"
                  (change)="updateSelectOrRadios(widget?.widgetType, widget?.element?.options, $event, displayID); "
                  >
                    <option *ngIf="productDropdownIsAvailable" selected>{{widget.element.label}}</option>
                    <option *ngIf="!productDropdownIsAvailable" value="" hidden selected>No available products</option>
                    <ng-container *ngFor="let choice of widget.element.options">
                      <span *ngIf="choice.inputValue > choice?.max" style="color:red;">
                        <span *ngIf="choice.max === 0"> There are no products available for this size</span>
                        <span *ngIf="choice.max === 1"> Only {{choice.max}} product available for this size</span>
                        <span *ngIf="choice.max > 0"> Only {{choice.max}} products available for this size</span>
                      </span>
                      <span *ngIf="choice['error'] == 'max'">
                      </span>
                      <option 
                        [value]="choice.id"
                        *ngIf="choice.show" 
                        [selected]="choice.is_selected"
                        [disabled]="choice.max < 1 && !editMode && choice.inputValue < 1 || choice.isDisabled && !editMode"> 
                        <span *ngIf="widget.element.show_name">{{ widget.element.name }},</span> {{ choice.size }}
                        <span class="price-container" *ngIf="widget.element.show_price && widget.element.priceByDay" style="font-weight:bold; color:#0a6ece" [ngStyle]="{
                        'font-size':
                        widget.componentSettings.text_size > 0
                        ? widget.componentSettings.text_size - 1 + 'px'
                        : 'auto'}">
                        <!-- If not edit mode the display the price, if edit mode then display the price by day -->
                          (${{ !editMode ? widget.element.price: widget.element.priceByDay[0].price }})
                        </span>
                      </option>
                    </ng-container>
                  <!-- <ng-template #productDropdownIsNotAvail> -->
                  <!-- </ng-template> -->
    
    
                </select>
              </div>
              <div style="max-width: 100px; padding: 2px;">
                <img src="{{ widget.element?.['groupImage'] }}"
                class="img-fluid" style="max-width:100%; height:auto;">
              </div>
            </div>
              <!-- <br> -->
              <div *ngIf="widget.element.show_description" style='margin: 0px; color: #697886' [ngStyle]="{
                  'font-size':
                  widget.componentSettings.text_size > 0
                  ? widget.componentSettings.text_size - 2 + 'px'
                  : 'auto'}">{{ widget.element.description }}
              </div>
              
  
        </ng-template>
      </span>
      <ng-template #noneChosen>
        <div class="align-items-center">
          Choose a product and a size to display
        </div>
      </ng-template>
      <ng-template #noSizeChosen>
        <div class="align-items-center">
          Choose a size to display
        </div>
      </ng-template>
  
  
  
    </div>
  
  
    <!---------- Height Input ------------>
    <div *ngSwitchCase="'heightInput'" [ngClass]="{
        'border': widget.componentSettings.border
      }" [ngStyle]="{
        'padding-top': widget.componentSettings.padding_top + 'px',
        'padding-right': widget.componentSettings.padding_right + 'px',
        'padding-bottom': widget.componentSettings.padding_bottom + 'px',
        'padding-left': widget.componentSettings.padding_left + 'px',
        'background-color': widget.componentSettings.background_color,
        'border-radius': widget.componentSettings.rounded_corners + 'px',
        'color' : widget.componentSettings.text_color,
        'text-align' : widget.componentSettings.alignment,
        height:
        widget.componentSettings.height > 0
          ? widget.componentSettings.height + 'px'
          : 'auto',
        'font-size':
        widget.componentSettings.text_size > 0
          ? widget.componentSettings.text_size + 'px'
          : 'auto'
        }">

      <span class="widget-label" [ngStyle]="{
        'font-size':
        widget.componentSettings.text_size > 0
        ? widget.componentSettings.text_size + 'px'
        : 'auto'}">
      {{ widget.element.label }}
      </span>
      <br>

      <span style='margin: 0px; color: #697886' [ngStyle]="{
        'font-size':
        widget.componentSettings.text_size > 0
        ? widget.componentSettings.text_size - 2 + 'px'
        : 'auto'}">
        {{ widget.element.description }}
      </span>

      <div class="d-flex">
        <div style="padding: 5px 5px 5px 0px;">
          <input id="heightInputFeet" type="number" min="0" max="99"
              style="width: 3em; padding: 1px;"
              [(ngModel)]="widget.element.feetInput" 
              oninput="this.value = this.value.replace(/[^0-9]/g, '')"
              (change)="inputWidgetChange(displayID)"/>
          <label for="heightInputFeet" style="padding: 5px;">ft</label>

        </div>
        <div style="padding: 5px 5px 5px 5px;">
          <input id="heightInputInch" type="number" min="0" max="99"
              style="width: 3em; padding: 1px;"
              [(ngModel)]="widget.element.inchInput" 
              oninput="this.value = this.value.replace(/[^0-9]/g, '')"
              (change)="inputWidgetChange(displayID)"/>
          <label for="heightInputInch" style="padding: 5px;">in</label>
        </div>
        <span *ngIf="widget.element.is_required && !widget.element.feetInput || widget.element.is_required && !widget.element.inchInput && widget.element.inchInput != 0"><i class="fas fa-asterisk fa-xs"
          style="color:red"></i></span>
      </div>

    </div>

    <!---------- Weight Input ------------>
    <div *ngSwitchCase="'weightInput'" [ngClass]="{
      'border': widget.componentSettings.border
    }" [ngStyle]="{
      'padding-top': widget.componentSettings.padding_top + 'px',
      'padding-right': widget.componentSettings.padding_right + 'px',
      'padding-bottom': widget.componentSettings.padding_bottom + 'px',
      'padding-left': widget.componentSettings.padding_left + 'px',
      'background-color': widget.componentSettings.background_color,
      'border-radius': widget.componentSettings.rounded_corners + 'px',
      'color' : widget.componentSettings.text_color,
      'text-align' : widget.componentSettings.alignment,
      height:
      widget.componentSettings.height > 0
        ? widget.componentSettings.height + 'px'
        : 'auto',
      'font-size':
      widget.componentSettings.text_size > 0
        ? widget.componentSettings.text_size + 'px'
        : 'auto'
      }">

    <span class="widget-label" [ngStyle]="{
      'font-size':
      widget.componentSettings.text_size > 0
      ? widget.componentSettings.text_size + 'px'
      : 'auto'}">
    {{ widget.element.label }}
    </span>
    <br>

    <span style='margin: 0px; color: #697886' [ngStyle]="{
      'font-size':
      widget.componentSettings.text_size > 0
      ? widget.componentSettings.text_size - 2 + 'px'
      : 'auto'}">
      {{ widget.element.description }}
    </span>

    <div class="d-flex">
      <div style="padding-right: 5px; padding-top: 5px;">
        <input id="widget?" type="number" min="0" max="999"
            style="width: 3em; padding: 1px;"
            [(ngModel)]="widget.element.inputValue" 
            oninput="this.value = this.value.replace(/[^0-9]/g, '')"
            (change)="inputWidgetChange(displayID)"/>
        <label for="weightInput" style="padding: 5px;">lbs</label>
      </div>
      <span *ngIf="widget.element.is_required && !widget.element.inputValue"><i class="fas fa-asterisk fa-xs"
        style="color:red"></i></span>
    </div>

    </div>

    <!---------- Tip ------------>
    <div *ngSwitchCase="'tip'" [ngClass]="{
          'border': widget.componentSettings.border
        }" [ngStyle]="{
          'padding-top': widget.componentSettings.padding_top + 'px',
          'padding-right': widget.componentSettings.padding_right + 'px',
          'padding-bottom': widget.componentSettings.padding_bottom + 'px',
          'padding-left': widget.componentSettings.padding_left + 'px',
          'background-color': widget.componentSettings.background_color,
          'border-radius': widget.componentSettings.rounded_corners + 'px',
          'color' : widget.componentSettings.text_color,
          'text-align' : widget.componentSettings.alignment,
          height:
          widget.componentSettings.height > 0
            ? widget.componentSettings.height + 'px'
            : 'auto',
          'font-size':
          widget.componentSettings.text_size > 0
            ? widget.componentSettings.text_size + 'px'
            : 'auto'
          }">
  
      <span *ngIf="widget.element.price" class="price-container" [ngStyle]="{
            'font-size':
            widget.componentSettings.text_size > 0
            ? widget.componentSettings.text_size - 1 + 'px'
            : 'auto'}">
        {{ widget.element.price }}%
      </span>
  
      <span class="widget-label" [ngStyle]="{
            'font-size':
            widget.componentSettings.text_size > 0
            ? widget.componentSettings.text_size + 'px'
            : 'auto'}">
        {{ widget.element.label }}
      </span>
  
      <span style='margin: 0px; color: #697886' [ngStyle]="{
          'font-size':
          widget.componentSettings.text_size > 0
          ? widget.componentSettings.text_size - 2 + 'px'
          : 'auto'}">
        {{ widget.element.description }}
      </span>
    </div>
  </ng-container>
</div>

