import {Component, OnInit} from '@angular/core';
import {Routes} from "@angular/router";
import {DashboardComponent} from "../../../dashboard/dashboard.component";


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  routes: Routes = [
    {path: 'dashboard', component: DashboardComponent},
  ];
  status: boolean = false;

  constructor() {
  }

  ngOnInit(): void {/* global bootstrap: false */

  }

}
