import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DeleteConfirmationService } from 'src/app/services/delete-confirmation.service';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss']
})
export class DeleteConfirmationComponent implements OnInit, OnDestroy{
  constructor(private deleteConfirmationService: DeleteConfirmationService){

  }

  @ViewChild('deleteForm') deleteForm: NgForm;

  subs = new Subscription();
  title: string = '';
  inputValue: string;
  id: string = '';
  extras: any = {};
  
  ngOnInit(): void {
    this.subs.add(this.deleteConfirmationService.getInfo.subscribe((res)=>{
      this.inputValue = "";
      this.title = res['title'];
      this.id = res['id'];
      this.extras = res['extras']
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  delete(){
    console.log(this.deleteForm.value)
    this.deleteConfirmationService.validationPassed({id: this.id, title: this.title, extras: this.extras});
  }
}
