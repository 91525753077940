<div class="container">
    <div class="row" style="position: relative;">

        <!-- Hourly Filter -->
        <!-- <div class="col-7">
            <label class="dropdown-label"><b>Filter by Hours:</b></label>
            <select class="form-select" [(ngModel)]="selectedHourlyFilter">
                <ng-container>
                    <option selected [ngValue]="null">All Hours</option>
                    <option *ngFor="let times of backendBookingData['allHours']; index as i" [ngValue]="times">
                        {{times}}
                    </option>
                </ng-container>
            </select>
        </div> -->

        <!-- All | Available filter -->
        <!-- justify-content: end; flex-direction: column; -->
        <div class="col-5" style="display: flex; align-items: end;" *ngIf="itemsInCartObj">
            <div class="btn-group btn-group-sm" role="group">
                <button type="button" style="border-radius: 5px;"
                    [ngClass]="{'btn btn-outline-primary': !showAll, 'btn-primary' : showAll}"
                    (click)="showAll = true">All Times</button>
                <button type="button" style="border-radius: 5px;"
                    [ngClass]="{'btn btn-outline-primary': showAll, 'btn-primary' : !showAll}"
                    (click)="showAll = false">Available</button>
            </div>
        </div>

        <div class="col-5" style="display: flex; align-items: end;" *ngIf="!itemsInCartObj">
            <div *ngIf="this.rentalTypeOptions.length > 0" class="d-flex justify-content-center align-middle">
                <div *ngFor="let option of rentalTypeOptions" style="margin:8px 2px 0px 2px;">
                    <span *ngIf="option.show">
                        <input
                            style="text-align:left;"
                            class="btn-check"
                            type="radio"
                            name="rentalTypeOptions"
                            autocomplete="off"
                            id="{{option.type}}"
                            [value]="option.type"
                            [checked]="option.isSelected"
                            (change)="updateRentalType(option.type)"
                        />
                        <span *ngIf="option.isSelected">
                            <label class="btn btn-primary" style="font-size:14px" for="{{option.type}}">{{ option.type }}</label>
                        </span>
                        <span *ngIf="!option.isSelected">
                            <label class="btn btn-secondary" style="font-size:14px" for="{{option.type}}">{{ option.type }}</label>
                        </span>
                    </span>
                </div>
            </div>
        </div>

        <div *ngIf="rentalTypeFilter == 'Hourly'" class="d-inline-flex">
            <label class="dropdown-label" for="hourlyFilter" style="margin: 2px 3px 2px 0px;"><b>Filter by Hours:</b></label>
            <select
            style="width: 20%"
            name="hourlyFilter"
            [(ngModel)]="selectedHourlyFilter">
            <option *ngIf="selectedHourlyFilter === null" hidden selected [ngValue]="null">None</option>
            <option [value]="hour" *ngFor="let hour of hoursArray" [selected]="selectedHourlyFilter == hour">
                {{ hour }}
            </option>
        </select>
        </div>

        <div class="col-12 mt-2">
            <!-- Reservation Date Header -->
            <div
                style="border: 1px #adcdff solid; border-radius: 5px; background-color: #e0ecff; padding-top: 10px; padding-bottom: 10px;">
                <div style="color: #2a0083;" class="col-md-6 fs-6">
                    Reservation Dates
                    <span class="text-primary">
                        {{timeslotArr[0].dayStart | luxonDisplayPipe : 'DATE_SHORT' : timezone}} -
                        {{timeslotArr[0].dayEnd | luxonDisplayPipe : 'DATE_SHORT' : timezone }}
                    </span>
                </div>
            </div>

            <!-- Main Content -->
            <div class="mb-4" style="border: solid #bdbdbd70 1px; background-color: #f7f7f742; border-radius: 5px; height: 60vh;
            overflow-y: auto;">
                <div class="col-12" style="line-height: 1.6">
                    <div class="row" style="margin-top: 1em">
                        <ng-container
                            *ngFor="let possibleTimeslot of timeslotArr; let index = index">
                            <!-- Apply All | Available filter -->
                            <ng-container *ngIf="showAll && itemsInCartObj || (!showAll && possibleTimeslot?.swappableItems.length <= 0 && itemsInCartObj) || rentalTypeFilter == 'All' && !itemsInCartObj ||
                                        rentalTypeFilter == 'Hourly' && possibleTimeslot.type == 'hourly' || rentalTypeFilter == 'All Day' && possibleTimeslot.type == 'shopDay' ||
                                        rentalTypeFilter == '24 Hour' && possibleTimeslot.type == '24hr'">
                                <!-- Apply Hourly Filter -->
                                <ng-container
                                    *ngIf="!selectedHourlyFilter || (selectedHourlyFilter && possibleTimeslot['hourLength'] == selectedHourlyFilter)">
                                    <div class="col-8" style="margin-bottom: 0.3rem;">
                                        <!-- Radios -->
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="possibleTimeslot"
                                                [(ngModel)]="currentSelection" id="possibleTimeslot_{{index}}"
                                                [value]="possibleTimeslot" (change)="updateIsSelected(index)">
                                            <label class="form-check-label" for="possibleTimeslot_{{index}}">
                                                <ng-container *ngIf="possibleTimeslot['type'] === '24hr'">
                                                    <span style="margin-left: 5px; margin-right: 2px;">
                                                        {{possibleTimeslot.dayStart
                                                        |
                                                        luxonDisplayPipe : 'DATETIME_SHORT' :
                                                        timezone}} - {{possibleTimeslot.dayEnd |
                                                        luxonDisplayPipe
                                                        : 'DATETIME_SHORT' :
                                                        timezone}}
                                                    </span>
                                                </ng-container>

                                                <ng-container *ngIf="possibleTimeslot['type'] !== '24hr'">
                                                    <span style="margin-left: 5px; margin-right: 2px;">
                                                        {{possibleTimeslot.dayStart
                                                        |
                                                        luxonDisplayPipe : 'TIME_SIMPLE' :
                                                        timezone}} - {{possibleTimeslot.dayEnd |
                                                        luxonDisplayPipe
                                                        : 'TIME_SIMPLE' :
                                                        timezone}}
                                                    </span>
                                                </ng-container>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-3" *ngIf="itemsInCartObj">
                                        <!-- Unavailable Label -->
                                        <div *ngIf="possibleTimeslot?.swappableItems.length > 0">
                                            <span class="badge bg-warning badges" style="float: right;"
                                                (click)="this.selectedSwappableIndex = index;"
                                                [ngbPopover]="popoverContent" [autoClose]="'outside'"
                                                #popover="ngbPopover" #p="ngbPopover" popoverClass="my-custom-class">
                                                {{possibleTimeslot?.swappableItems.length}} - Swappable Items
                                            </span>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>

            <!-- Current Selection -->
            <ng-container *ngIf="currentSelection">
                <span><b>Selected Rental Time:</b></span>
                <span>
                    {{currentSelection.dayStart | luxonDisplayPipe : 'DATETIME_SHORT' : timezone}} -
                    {{currentSelection.dayEnd | luxonDisplayPipe : 'DATETIME_SHORT' : timezone}}</span>
            </ng-container>

            <!-- Buttons -->
            <div class="col-12 text-right">
                <button type="button" class="btn btn-secondary"
                    (click)="cancelTimeslotSelection.emit(); this.currentSelection = null;">
                    Cancel
                </button>
                <button [disabled]="!currentSelection" type="button" class="btn btn-primary ml-2"
                    (click)="this.getTimeslotSelection.emit(this.currentSelection); this.currentSelection = null">
                    Confirm
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Popover for removing unavailable items / swapping -->
<ng-template #popoverContent>
    <div>
        <strong class="mr-auto">Unavailable Items</strong>
    </div>
    <div style="display: flex; flex-direction: column">
        <div *ngFor="let item of timeslotArr[selectedSwappableIndex]?.swappableItems">
            <div class="row">
                <div class="col-12">
                    <span>{{productsMap[item.originalID].productName}}, {{productsMap[item.originalID].productSize}} #{{productsMap[item.originalID].productNumber}}</span>
                </div>
            </div>
        </div>
        <!-- <button style=" align-items: center; justify-content: center; " class="btn btn-warning mt-2" >Swap Items
        </button> -->
    </div>
</ng-template>
