<div style="z-index: 9999" class="modal fade" id="add-note-modal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">

    <!-- <div class="modal" id="add-note-modal"> -->
    <div class="modal-dialog">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title mb-0">Add Note</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <!-- <label for="exampleFormControlTextarea1">Note</label> -->
                        <textarea class="form-control" formControlName="note" id="exampleFormControlTextarea1" rows="3"></textarea>
                    </div>
                    <div class="d-flex">
                        <div class="ml-auto">

                            <button type="submit" class="btn btn-primary" data-dismiss="modal">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
            
            <!-- Modal footer -->
            <!-- <div class="modal-footer">
                <button type="submit" class="btn btn-primary" data-dismiss="modal">Submit</button>
            </div> -->
        </div>
    </div>
</div>