import { Injectable } from '@angular/core';
import { firstValueFrom, forkJoin, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParallelPromiseService {

  constructor() { }

  public promisedProccessing<T>(
    initialPromise: Promise<T>, 
    processingFn: (result: T) => void
  ): Promise<void> {
    return initialPromise.then((res: T) => {
      return new Promise<void>((resolve, reject) => {
        try {
          processingFn(res); // Execute the processing function using the resolved data
          resolve(); // Resolve the outer promise
        } catch (error) {
          reject(error); // Reject the outer promise if an error occurs
        }
      });
    });
  }

  public async processMultiplePromisesInParallel(promises: Promise<any>[] = []): Promise<any> {
    let promisesResult: any = {};

    return new Promise((resolve, reject) => {
      // Wait for all promises to complete and processing to finish
      forkJoin(promises).pipe(take(1)).subscribe({
        next: () => {
          resolve(promisesResult);
        },
        error: (error) => {
          console.error(error);
          reject(error);
        }
      });
    })
  }

}
