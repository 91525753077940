import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

/* Libraries */
import { DateTime } from 'luxon';

/* Services */
import { BookingFlowService } from 'src/app/v2/services/booking-flow.service';

/* Models */
import { BookingPage } from 'src/app/models/storage/booking-page.model';
import { BookingTemplate, BookingContent } from 'src/app/v2/models/booking-flow.model';
import { ProductGroup } from 'src/app/models/storage/product-group.model';

@Component({
  selector: 'app-booking-catalog-breadcrumbs',
  templateUrl: './booking-catalog-breadcrumbs.component.html',
  styleUrls: ['./booking-catalog-breadcrumbs.component.css']
})

export class BookingCatalogBreadcrumbsComponent implements OnChanges {
  protected currentPageTitle: string = ""; // Presents the user the current page title by appending the page title to the breadcrumb trail (visually)
  protected breadcrumbList: { title: string, queryParams: { content: string } }[] = [];
  @Input() public isCreator: boolean;
  @Input() public currentContentID: string;

  /* Collections / Docs */
  @Input() public templateObj: BookingTemplate;
  @Input() public bookingPagesCollectionMap: { [id: string]: BookingPage };
  @Input() public productGroupCollectionMap: { [id: string]: ProductGroup };

  /* Creator/Editor Specific */
  @Output() private sendCreatorContentID: EventEmitter<string> = new EventEmitter<string>();

  /* Front-facing Booking Flow Specific */
  @Input() public dayStart: DateTime;
  @Input() public dayEnd: DateTime;
  @Input() public locationID: string;

  constructor(
    private router: Router,
    private bookingFlowService: BookingFlowService,
    private activatedRoute: ActivatedRoute
  ) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentContentID || changes.templateObj) {
      this.loadBreadcrumbsForView();
    }
  }

  private loadBreadcrumbsForView(): void {
    this.breadcrumbList.length = 0; // reset prior breadcrumb array
    this.currentPageTitle = "";

    // If templateObj or breadcrumbs are not initalized, do not continue processing
    if (!this.templateObj || !this.templateObj.breadcrumbs || !this.templateObj.content) {
      return;
    }

    // If no currentContentID is present, prevent further breadcrumb processing
    if (this.currentContentID == "") {
      return
    }

    let breadcrumbs: string[] = [""];
    // Find if this pages breadcrumb trail was stored on the template
    const breadcrumbObj = this.templateObj.breadcrumbs.find((bc) => { return bc.pageID === this.currentContentID });
    if (!breadcrumbObj) {
      throw new Error($localize`Breadcrumbs not found for contentID: ${this.currentContentID}`);
    }
    else {
      breadcrumbs = breadcrumbObj.breadcrumbs;
    }

    let pageNamesMap: { [id: string]: string } = {};
    this.templateObj.content.forEach((cont: BookingContent) => {
      if(cont.isPage){
        pageNamesMap[cont.contentID] = this.bookingPagesCollectionMap[cont.id].title;
      }

      // Assign a page title
      if (this.currentContentID && cont.contentID == this.currentContentID) {
        this.currentPageTitle = this.bookingFlowService.assignContentPageTitle(cont, this.bookingPagesCollectionMap, this.productGroupCollectionMap);
      }
    })

    breadcrumbs.forEach((pageID: string) => {
      if (pageID === "") {
        this.breadcrumbList.push({ title: "Home", queryParams: { content: "" } })
      }
      else {
        this.breadcrumbList.push({ title: pageNamesMap[pageID], queryParams: { content: pageID } })
      }
    })
  }

  protected navigateByBreadcrumbs(contentID: string): void {
    if (this.isCreator) {
      this.sendCreatorContentID.emit(contentID);
    } else {
      this.router.navigate(['catalog'], { relativeTo: this.activatedRoute, queryParams: { content: contentID ? contentID : null, location: this.locationID, startDate: this.dayStart.toISODate(), endDate: this.dayEnd.toISODate() } });
    }
  }
}
