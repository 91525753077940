<form class="needs-validations" #f="ngForm" (ngSubmit)="onSubmit()" novalidate>

    <!-- Header -->
    <h2 class="d-flex"><span>Notifications for {{product?.productName}}</span><span style="font-size: small;
            color: #005dff;" class="ml-2">({{startDateString}} - {{endDateString}})</span></h2>
    <p>Fill out the form below to be notified if availabilities for your product changes between the selected dates.</p>

    <!-- Email -->
    <div class="row">
        <div class="form-group-sm mb-2 input-group-sm">
            <label class="form-check-label" for="email">Email</label>
            <span class="text-danger">*</span> <!-- Required Symbl -->

            <input class="form-control" type="email" name="email" id="email" ngModel required email #email="ngModel">
            <!-- <input class="form-control" type="email" name="email" id="email" [(ngModel)]="email"> -->
        </div>
        <!-- title.errors?.['required'] && title.touched" -->
        <span class="text-danger mb-2" *ngIf="!email.valid && email.touched">
            In order to be notified you must provide a valid email.
        </span>
    </div>

    <!-- Phone -->
    <div class="row">
        <div class="form-group-sm mb-2 input-group-sm">
            <label class="form-check-label" for="phone">Phone</label>
            <span class="text-danger">*</span> <!-- Required Symbl -->

            <input class="form-control" type="text" name="phone" id="phone" ngModel required #phone="ngModel"
                pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.\-]?\d{3}[\s.\-]?\d{4}$">
        </div>
        <span class="text-danger mb-2" *ngIf="!phone.valid && phone.touched">
            In order to be notified you must provide a valid phone number.
        </span>
    </div>

    <!-- Agree to Terms -->
    <div class="row">
        <div class="form-check" style="text-align: center;">
            <input type="checkbox" ngModel required #agreeToTerms="ngModel" name="agreeToTerms"
                [disabled]="!phone.valid || !email.valid" id="agreeToTerms" class="form-check-input" />
            <label for="agreeToTerms" class="form-check-label">I accept the <span>terms and conditions</span></label>
            <div *ngIf="!agreeToTerms.valid && agreeToTerms.touched" class="invalid-feedback">Must accept the terms and
                conditions in order to continue.</div>
        </div>
    </div>

    <!-- Buttons -->
    <div class="container-xxl">
        <div class="row justify-content-center mt-2">
            <div class="text-center">
                <button type="submit" class="btn btn-success" [disabled]="f.invalid">
                    Done
                </button>
            </div>
        </div>
    </div>
</form>