import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { lastValueFrom, Subscription, take } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

/* Model */
import { BookingTemplate } from 'src/app/models/storage/booking-template.model';

/* Services */
import { CurrentUserService } from 'src/app/services/current-user.service';
import { PartnerService } from 'src/app/services/partner.service';
import { ProductsService } from 'src/app/services/products.service';
import { RentalService } from 'src/app/services/rental.service';
import { FirestoreService } from 'src/app/v2/services/firestore.service';
import { ColorTheme } from 'src/app/services/colorTheme.service';
import { Collection } from 'src/app/v2/models/collection-reference.model';

@Component({
  selector: 'app-page-embeds',
  templateUrl: './page-embeds.component.html',
  styleUrls: ['./page-embeds.component.scss'],
})
export class PageEmbedsComponent implements OnInit {
  protected bookingTemplates: BookingTemplate[] = [];
  protected selectedTemplate: BookingTemplate = null;
  protected loaded: boolean = false;

  app = environment.app
  companyID: string = '';
  companyURL: string = '';
  userId: string;
  public getTheme: string;
  public getText: string;
  subs = new Subscription();
  productData = [];
  categories: any = [];
  public typeOfURL: string = 'default';
  public directLinkID: string = '';
  public STOREFRONTURL: string;

  public URL: string = `https://${environment.app.appDomainName}/rental/`;
  public FLEETMAIDSCRIPT = `&lt;!-- Fleetmaid script - do not remove --&gt;&#10;&lt;script src="https://${environment.app.appDomainName}/assets/js/fleetmaid.js"&gt;&lt;/script&gt;`;
  public generatedURL: string = '';
  public generatedQueryString: string = '';
  generatorCtrl = { code: '', codeType: 'HTML_CODE_TYPE', label: '' };
  previewHtml: HTMLElement; //currently unused, may be beneficial to show a preview if we implement custom-styled generated buttons
  showBeforeElement = false;
  public stylesColors: any;
  constructor(
    private _currentUser: CurrentUserService,
    private _partnerService: PartnerService,
    private _productsService: ProductsService,
    private _rentalService: RentalService,
    private _renderer2: Renderer2,
    public sanitizer: DomSanitizer,
    public _Theme_Color: ColorTheme,
    private firestoreService: FirestoreService,
    @Inject(DOCUMENT) private _document
  ) {}

  public async ngOnInit(): Promise<void> {
    this.fncolortheme();
    this.companyID = this._currentUser.currentUser.companyId;
    await this.queryAndProcessBookingTemplates();

    const company = await this._partnerService.getCompanyInfoS(this.companyID)
      this.companyURL = company.companyURL;

      this.STOREFRONTURL =
        `https://${environment.app.appDomainName}/book/${this.selectedTemplate}/`;
      this.generatedURL = this.STOREFRONTURL;
      this.generatorCtrl.label = 'Book Now!';
      this.generatorCtrl.code = `&lt;!-- Fleetmaid rental button --&gt;&#10;&lt;a href="${this.STOREFRONTURL}"&gt;${this.generatorCtrl.label}&lt;/a&gt;`;

    /*
    //Angular normally strips out scripts before compiling for security, using renderer2 as a workaround to inject our iframe script (fleetmaid.com/assets/js/fleetmaid.js) for partner link preview functionality
    //5/22/2023 Turns out we can't use this injection script on our own site, because some other components already use #divRental and #scrollframe elements in their DOM, which break when another element with the same ID is injected
    const iframeScript = this._renderer2.createElement('script');
    iframeScript.type = 'text/javascript';
    iframeScript.src = 'https://fleetmaid.com/assets/js/fleetmaid.js';
    //iframeScript.text = ``;
    this._renderer2.appendChild(this._document.body, iframeScript);
    */

    this.loaded = true;
    // this.initialSubscriptions();
  }

  private async queryAndProcessBookingTemplates(): Promise<void>{
    const companyBookingTemplates = await lastValueFrom(this.firestoreService.getCollection(Collection.BookingTemplates, [{field: 'companyID', operator: '==', value: this.companyID}], []).pipe(take(1)));
    this.bookingTemplates = companyBookingTemplates;

    if(companyBookingTemplates && companyBookingTemplates.length > 0){
      this.selectedTemplate = companyBookingTemplates[0].id;
    }
  }

  protected updateUrl(): void {
    this.STOREFRONTURL = `https://${environment.app.appDomainName}/book/${this.selectedTemplate}/`;
    this.generatedURL = this.STOREFRONTURL;
    this.updateControlCode();
  }

  fncolortheme() {
    this.userId = this._currentUser.currentUser.id;
    this._Theme_Color
      .getcolorPeruser(this.userId)
      .then((rest) => {
        let objs = {
          theme: rest.color.value,
          text: rest.color.fontcolor,
        };

        this.getTheme = objs.theme;
        this.getText = objs.text;
        this.stylesColors = [this.getTheme, this.getText];
        this.showBeforeElement = !this.showBeforeElement;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * @description Receives select input from user and creates a corresponding URL query string which will pass the correct docIDs & field data through to the rapidcycling component
   * @author Dustin Chen
   * @date 05/24/2023
   * @param {*} [event] <select> change event
   */
  generateQueryString(event?: any) {
    let value = event.target.value;
    if (
      value == 'default' ||
      value == 'location' ||
      value == 'category' ||
      value == 'product'
    ) {
      this.generatedURL = this.STOREFRONTURL;
      this.directLinkID = '';
    } else {
      this.directLinkID = event.target.value;
      switch (this.typeOfURL) {
        case 'default':
          this.generatedQueryString = '';
          this.generatedURL = this.STOREFRONTURL;
          break;
        case 'location':
          this.generatedQueryString = `?lo=${encodeURIComponent(
            this.directLinkID
          )}&co=${encodeURIComponent(this.companyID)}`;
          this.generatedURL = `${this.STOREFRONTURL}${this.generatedQueryString}`;
          break;
        case 'category':
          this.generatedQueryString = `?ci=${encodeURIComponent(
            this.directLinkID
          )}&co=${encodeURIComponent(this.companyID)}`;
          this.generatedURL = `${this.STOREFRONTURL}${this.generatedQueryString}`;
          break;
        case 'product':
          this.generatedQueryString = `?pr=${encodeURIComponent(
            this.directLinkID
          )}&pn=${encodeURIComponent(
            this.productData.find((product) => product.id === this.directLinkID)
              .productName
          )}&co=${encodeURIComponent(this.companyID)}`;
          this.generatedURL = `${this.STOREFRONTURL}${this.generatedQueryString}`;
          break;
        default:
          console.log('error generating query string');
      }
    }
    console.log('generate query string: ', event);
  }

  /**
   * @description Display iframe preview of what page the generated direct link will pull up
   * @author Dustin Chen
   * @date 05/24/2023
   */
  testQueryString() {
    //console.log("Query string button clicked", this.generatedURL);
    if (this.checkLocalhost()) {
      this.openFrame(
        true,
        `http://${window.location.host}/rental/${this.companyURL}/${this.generatedQueryString}`
      );
    } else {
      this.openFrame(true, `${this.STOREFRONTURL}${this.generatedQueryString}`);
    }
  }

  checkLocalhost() {
    //for testing query string functionality while in developer mode
    return window.location.host.includes('localhost');
  }

  /**
   * @description Toggle embedded Iframe popup with a target url
   * @author Dustin Chen
   * @date 05/24/2023
   * @param {boolean} status true to open frame, false to hide
   * @param {*} [url] the link to the page to open inside the iframe
   */
  openFrame(status: boolean, url?: any) {
    let divRental = document.getElementById('divRental');
    if (status == true) {
      document.getElementById('scrollframe')['src'] = url;
      divRental.style.display = 'flex';
    }
    if (status == false) {
      divRental.style.display = 'none';
    }
  }

  /**
   * @description Updates the code displayed in the generator textarea
   * @author Dustin Chen
   * @date 05/24/2023
   * @param {string} [codeSelection] passed in from code-type-selection radio button group, current options are 'HTML_CODE_TYPE', 'BUTTON_CODE_TYPE', or 'LINK_CODE_TYPE'
   */
  updateControlCode(codeSelection?: string) {
    if (codeSelection) {
      this.generatorCtrl.codeType = codeSelection;
    }
    if (this.typeOfURL == 'default') {
      this.generatedQueryString = '';
    }
    switch (this.generatorCtrl.codeType) {
      case 'BUTTON_CODE_TYPE':
        this.generatorCtrl.code = `&lt;!-- Fleetmaid rental button --&gt;&#10;&lt;button class="bookButton" onclick="openFrame('${this.STOREFRONTURL}${this.generatedQueryString}')"&gt;${this.generatorCtrl.label}&lt;/button&gt;`;
        // this.previewHtml = this.stringToHtml(this.decodeHtml(this.generatorCtrl.code));
        // document.getElementById('generator-preview').appendChild(this.sampleHtml);
        break;
      case 'HTML_CODE_TYPE':
        this.generatorCtrl.code = `&lt;!-- Fleetmaid rental anchor --&gt;&#10;&lt;a href="${this.STOREFRONTURL}${this.generatedQueryString}"&gt;${this.generatorCtrl.label}&lt;/a&gt;`;
        // this.previewHtml = this.stringToHtml(this.decodeHtml(this.generatorCtrl.code));
        // document.getElementById('generator-preview').appendChild(this.sampleHtml);
        break;
      case 'LINK_CODE_TYPE':
        this.generatorCtrl.code = `${this.STOREFRONTURL}${this.generatedQueryString}`;
        break;
    }
  }

  decodeHtml(html: string) {
    //decodes HTML special character encoding (&gt; => > / &#10; => space, etc.)
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }

  stringToHtml(str: string) {
    //parses a string into a valid HTMLElement
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, 'text/html');
    console.log('parser output', doc.body);
    return doc.body;
  }
}
