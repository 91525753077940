import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// Libraries
import Swal from "sweetalert2";


@Component({
  selector: 'app-notify-me-modal',
  templateUrl: './notify-me-modal.component.html',
  styleUrls: ['./notify-me-modal.component.scss']
})
export class NotifyMeModalComponent implements OnInit {
  @Input() product;
  @Input() startDateString;
  @Input() endDateString;
  @Output() submitNotifyMeForm = new EventEmitter();
  @ViewChild('f') notifyMeForm: NgForm;
  email;
  phone;
  agreeToTerms;

  // notifyMeForm;

  // get email() { return this.notifyMeForm.get('email'); }
  // get phone() { return this.notifyMeForm.get('phone'); }
  // get agreeToTerms() { return this.notifyMeForm.get('agreeToTerms'); }

  ngOnInit(): void {
    // this.notifyMeForm = new FormGroup({
    //   'email': new FormControl("", [Validators.required, Validators.email]),
    //   'phone': new FormControl("", [Validators.required, Validators.pattern(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)]),
    //   'agreeToTerms': new FormControl({value: false, disabled: this.notifyMeForm.get('phone').errors}, Validators.requiredTrue)
    // })
  }

  onSubmit() {
    // Set up notification, etc

    console.log(this.notifyMeForm)
    if(this.notifyMeForm.valid){ // If form is valid, send login information to server
      // this.authService.loginUser( 
      // {
      //   email: (this.signinForm.value.email).toLowerCase(), // lowercase used for unique emails that don't differentiate by capitals.
      //   password: this.signinForm.value.password
      // })
      this.notifyMeForm.form.markAsPristine()
      this.notifyMeForm.form.updateValueAndValidity()
  }





    Swal.fire({
      title: 'Success!',
      text: 'If this item becomes available during the selected date range, you will be notified.',
      icon: 'success',
    });

    this.notifyMeForm.reset(); // reset form
    this.submitNotifyMeForm.emit(); // close modal

  }
}
