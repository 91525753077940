import { DateTime } from 'luxon';
import { Cart } from 'src/app/models/storage/cart.model';
import { AvailabilityOverrides } from 'src/app/models/availability-overrides.model';
import { Timeslot, TimeslotType } from 'src/app/models/availability-timeslot.model';
import { ProductLocation } from 'src/app/models/storage/product-location.model';

export interface AvailabilityObject {
    algoMetadata?: AlgoMetadata;
    productWidgetTotals?: ProductWidgetTotalsKey;
    cartQuantities?: CartQuantitiesKey;
    resultSet?: AvailabilityProductGroup;
}

export interface AlgoMetadata {
    cartObj: Cart | object,
    dayEnd: DateTime,
    dayStart: DateTime,
    info: Info,
    location: string,
    overrides: AvailabilityOverrides | undefined,
    runTime: string,
    runTimeUnit: string,
    selectedDaySpan: number
}

interface Info {
    cartObj: Cart | object;
    firstAvailTimeForToday: null | any;
    daySpan: number;
    invalidDate: boolean;
    isSingleCalendarDay: boolean;
    locationLTMap: {[key: string]: { locationA: string, locationB: string, minutes: number}};
    maxLT: number;
    overrides: undefined | AvailabilityOverrides;
    sdeClosingDateTime: DateTime;
    sdeIsAvailable: boolean;
    sdeNotInPast: boolean;
    sdeOpeningDateTime: DateTime;
    sdePassesDOTWCheck: boolean;
    sdePassesUnavailableDayCheck: boolean;
    sdeUnavailableTimes: any[];
    sdsClosingDateTime: DateTime;
    sdsIsAvailable: boolean;
    sdsNotInPast: boolean;
    sdsOpeningDateTime: DateTime;
    sdsPassesDOTWCheck: boolean;
    sdsPassesUnavailableDayCheck: boolean;
    sdsUnavailableTimes: any[];
    searchIncludesToday: boolean;
    selectedLocationObj: ProductLocation;
    selectedTimezone: string;
    selectedTimezoneName: string;
    xtraDateInfo: XtraDateInfo;
}

interface XtraDateInfo {
    singleDay24ClosingDateTime: DateTime;
    singleDay24OpeningDateTime: DateTime;
    singleDay24_SDEUnavailableTimes: any[]; // Adjust type based on actual data structure
    singleDay24_daySpan: number;
    singleDay24_isAvailable: boolean;
}

export interface AvailabilityProductGroup {
    [key: string]: AvailabilityProductGroupItem;
}

export interface AvailabilityProductGroupItem {
    hasAvailableQuantity: boolean;
    listOfSizes?: { [sizeID:string] : ProductsOrganized };
    pgName?: string;
    productData: object;
    products: AvailabilityProduct;
    sizingAvail?: any[];
}

export interface AvailabilityProduct {
    [key: string]: AvailabilityProductItem;
}

export interface AvailabilityProductItem {
    availTimeslots: Timeslot[];
    unavailTimeslots: Timeslot[];
    allTimeslots?: Timeslot[];
    main?: object;
    secondary?: object;
    productID: string;
    productSizeID: string;
    productSize: string;
}

export enum RentalLengthType {
    AllDay = "All Day Rental",
    TwentyFourHour = "24 Hour Rental"
}

export interface ProductsOrganized {
    isAvailable: boolean;
    pgName: string;
    sizeID: string;
    sizeTypeID: string;
    sizeName: string;
    sizeTypeName: string;
}

export interface OverlappingTimes {
    [key: string]: OverlappingTimesItem;
}

export interface OverlappingTimesItem {
    availDaySpan: number;
    dayStart: DateTime;
    dayStartString: string;
    dayEnd: DateTime;
    dayEndString: string;
    count?: number;
    productIDs: string[];
    productWidgetIDs: string[];
    type: TimeslotType;
    hourLength: number;
    productID?: string;
    productSizeID?: string;
    productSizeName?: string;
    productGroupID?: string;
}

export interface SortedList {
    [key: string]: SortedListItem;
}

export interface SortedListItem  {
    productGroupID?: string;
    productSizeID: string;
    originalProductIDs?: string[]; // for getUniqueCartItemsCount
    originalProductWidgetIDs?: string[]; // for getUniqueCartItemsCount
    timeslotOverlap: OverlapSortedList
}

export interface OverlapSortedList {
    [key: string]: OverlapSortedListItem;
}

export interface OverlapSortedListItem extends OverlappingTimesItem {
    swappableProductWidgets?: SwappableProducts[]; // for getOverlapTimeslotsForProductGroupSize
    swappableItems?: SwappableProducts[]; // for getOverlapTimeslotsForProductGroupSize
    originalProductIDs?: string[]; // for getOverlapTimeslotsForProductGroupSize
    originalProductWidgetIDs?: string[]; // for getOverlapTimeslotsForProductGroupSize
}

export interface ReconstructedOverlappingTimeslots {
    [key: string]: {
        combineCount?: number;
        hourLength: number;
        availDaySpan: number;
        dayStart: DateTime;
        dayEnd: DateTime;
        type: string;
        productGroupSizes: { [key: string]: Timeslot };
        swappableItems: SwappableProducts[];
        originalProductIDs: string[];
        originalProductWidgetIDs: string[];
    }
}

export interface ProductWidgetTotals {
    pgName: string;
    sizeName: string;
    sizeTypeID: string;
    sizeID: string;
    sizeTypeName: string;
    totalAvail: number;
    uniqueIDs: { [key:string]: string };
}

export interface ProductWidgetTotalsKey {
    [key: string]: ProductWidgetTotals;

}

export interface CartQuantitiesKey {
    [key: string]: CartQuantities;
}

export interface CartQuantities {
    cartQty: number;
    currentAvail: number;
    pgName: string;
    productGroupID?: string;
    sizeID: string;
    sizeName: string;
    sizeTypeID: string;
    sizeTypeName: string;
    totalAvail: number;
}

export interface IDsUsedForSwap {
    [key: string]: { IDs: string[] };
}

export interface AvailableProductGroupSizes {
    [productGroupID: string]: AvailableProductGroupSizesDetails;
}

interface AvailableProductGroupSizesDetails {
    sizes: {
        [sizeID: string]: {
            products: string[];
            productCount: number;
            sizeName: string;
            sizeID: string;
        }
    };
    sizeNames: string[];
    productGroupID: string;
}

export interface SwappableProducts {
    originalID: string,
    newID: string
}


export interface AvailabilityInterface {
    get cartQuantities()
    get resultSet()
    get algoResult()
    get algoMetadata()
    get selectedDaySpan()
    get timeZone()
    get productWidgetTotals()
    set resultSet(resultSet)
    set cartQuantities(cartQuantities)
    set productWidgetTotals(productWidgetTotals)
    checkProductAvailability(groupID: string, productID: string): boolean
    checkGroupSizeAvailability(groupID: string, sizeID: string): boolean
    checkGroupAvailability(groupID: string): boolean
    checkForErrorsInAvailabilityResponse(productGroupID: string): string
    getGroupSizesIDsAvailable(groupID: string): string[]
    getGroupSizeIDsAvailable(groupID: string, sizeID: string): string[]
    getGroupsIDsAvailable(): string[]
    getGroupIDsUnavailable(): string[]
    getAllGroupIDsOrganized(): string[]
    getAllGroupIDs(): string[]
    getProductAvailableTimeslots(groupID: string, productID: string): Timeslot[]
    getGroupSizeAvailableTimeslots(groupID: string, sizeID: string): Timeslot[]
    getGroupAvailableTimeslots(groupID: string): Timeslot[]
    filterAvailableTimeslotsByType(timeslots: Timeslot[], type: TimeslotType, hourLength?: number): Timeslot[]
    getProductTimeLengths(groupID: string, productID: string, productTimeslots?: Timeslot[]): (number | string)[]
    getGroupSizeTimeLengths(groupID: string, sizeID: string, groupSizeTimeslots?: Timeslot[]): (number | string)[]
    determineTimeLengths(timeslots: Timeslot[]): (number | string)[]
    getProductsOverlappingTimes(groupID: string, productIDs: string[], productTimeslots?: Timeslot[], timeslotType?: TimeslotType, hourLength?: number): OverlappingTimes
    getGroupSizeOverlappingTimes(groupID: string, sizeID: string, groupSizeTimeslots?: Timeslot[], timeslotType?: TimeslotType, hourlength?: number): OverlappingTimes
    determineOverlappingCount(count: number, productGroupID: string, productGroupSize: string): number
    getOverlappingTimesForSpecifiedHourLength(overlappingTimes: OverlappingTimes, hourLength: number): OverlappingTimes
    getObjectOfAvailableProductsAndSizes(): AvailableProductGroupSizes
    productsOrganizedFunction(groupID: string, sizeTypesMap: object): ProductsOrganized[]
    productsOrganizedFunctionUsingCartQuantities(groupID, sizeTypesMap): {productsOrganized: ProductsOrganized[], sortOrderList: string[]}
    getListOfSizes(groupID: string, sizeTypesMap: object): { [sizeID:string] : ProductsOrganized }
    getOverlappingTimeValueForSpecificTime(overlappingTimes: OverlappingTimes, dayStart: DateTime, dayEnd: DateTime): OverlappingTimesItem
    checkProductAvailabilityByTime(groupID: string, productID: string, dayStart: DateTime, dayEnd: DateTime): boolean
    checkGroupSizeAvailabilityByTime(groupID: string, sizeID: string, dayStart: DateTime, dayEnd: DateTime): boolean
    checkProductGroupAvailabilityByTime(groupID: string, dayStart: DateTime, dayEnd: DateTime): boolean
    checkProductsAvailabilityByTime(groupID: string, productIDs: string[], dayStart: DateTime, dayEnd: DateTime): boolean
    countGroupSizeAvailabilityByTime(groupID: string, sizeID: string, dayStart: DateTime, dayEnd: DateTime, availTimeslots?: Timeslot[]): number
    getProductAvailableTimeslotByTime(groupID: string, productID: string, dayStart: DateTime, dayEnd: DateTime, type?: TimeslotType): Timeslot[]
    getGroupSizeAvailableTimeslotsByTime(groupID: string, sizeID: string, dayStart: DateTime, dayEnd: DateTime, type?: TimeslotType): Timeslot[]
    getGroupSizeIDsAvailableByTime(groupID: string, sizeID: string, dayStart: DateTime, dayEnd: DateTime): string[]
    getObjectOfAvailableProductsAndSizesByTime(dayStart: DateTime, dayEnd: DateTime): AvailableProductGroupSizes
    getGroupSizeCartQuantitiesObject(groupID: string, sizeID: string): CartQuantities
    getGroupSizeCartQuantitiesCurrentAvail(groupID: string, sizeID: string): number
    availCartQuantitiesList(): CartQuantities[]
    increaseCurrentAvailDecreaseCartQuantity(groupID: string, sizeID: string, reverse?: boolean): void
    getGroupSizeProductWidgetTotalsObject(groupID: string, sizeID: string): ProductWidgetTotals
    getGroupProductWidgetTotals(groupID: string): ProductWidgetTotals[]
    getProductWidgetCurrentAvail(groupID: string, sizeID: string): number
    getProductWidgetCurrentAvailUniqueIDs(groupID: string, sizeID: string): string[]
    getGroupProductWidgetCurrentAvailTotal(groupID: string): number
    getUniqueCartItemsCount(cartObj: Cart): SortedList
    getOverlapTimeslotsForProductGroupSize(sortedList: SortedList): { sortedList: SortedList, timesAreAvail: boolean }
    filterOverlappingTimeSlotForProducts(sortedList: SortedList): { sortedList: SortedList, timesAreAvail: boolean, IDsUsedForSwap: IDsUsedForSwap }
    filterOverlappingTimeSlotForProductsWidgets(sortedList: SortedList, productsMap: object, allOriginalRentalIDs: string[], IDsUsedForSwap: IDsUsedForSwap): Promise<{ sortedList: SortedList, timesAreAvail: boolean, IDsUsedForSwap: IDsUsedForSwap }>
    filterOverlappingTimeSlotForCartWidget(sortedList: SortedList, productsMap: object, cartProductWidgetIDs: string[], allOriginalIDs: string[], IDsUsedForSwap: IDsUsedForSwap): Promise<{sortedList: SortedList, timesAreAvail: boolean}>
    combineAllOverlappingTimeslotsAndRestructure(sortedList: SortedList): { allSlots: ReconstructedOverlappingTimeslots, allUniqueHours: number[] }
    getSwappableItems(sortedList: SortedList): SortedList
    checkProductRentalAvailability(groupID: string, productID: string, start: DateTime, end: DateTime, slotType: string | TimeslotType): boolean

}
