<nav id="breadcrumb-nav">
  <ol id="breadcrumb-ol">
    <ng-container *ngFor="let breadcrumb of breadcrumbList; let i = index">
      <!-- Breadcrumb -->
      <li>
        <a class="breadcrumbNavs" (click)="navigateByBreadcrumbs(breadcrumb.queryParams?.content)">{{breadcrumb.title}}</a>
      </li>

      <!-- Divider -->
      <svg xmlns='http://www.w3.org/2000/svg' width='8' height='8'>
        <path d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='#{$breadcrumb-divider-color}' />
      </svg>
    </ng-container>
    
    <!-- Current Page Title -->
    <li style="padding-left: 6px;"><span>{{currentPageTitle}}</span></li>
  </ol>
</nav>