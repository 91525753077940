import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {NgForOf} from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { Subscription } from 'rxjs';
import {GridsterComponent, GridsterItemComponent, CompactType,
  GridsterPush, GridType, GridsterConfig, GridsterItem, DisplayGrid} from 'angular-gridster2';
import { v4 as uuid } from 'uuid';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import * as _ from 'lodash';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { WidgetInterface } from 'src/app/models/widget.model';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DndDropEvent } from 'ngx-drag-drop';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCardModule} from '@angular/material/card';
import {MatNativeDateModule} from '@angular/material/core';
import { lastValueFrom } from 'rxjs';
import { WidgetService } from 'src/app/services/widget.service';

@Component({
  selector: 'app-inventory-page-view',
  templateUrl: './inventory-page-view.component.html',
  styleUrls: ['./inventory-page-view.component.scss']
})
export class InventoryPageViewComponent  {

  @ViewChild('ckEditor') ckEditor: any;
  @ViewChild('popover') popover!: NgbPopover;
  @Input() groupInventoryPage: any;
  @Output() productGroupDataUpdated = new EventEmitter<any>();
  @Output() emitData = new EventEmitter<any>();
  @Output() widgetInputChanged = new EventEmitter<any>();
  @Output() productInputChanged = new EventEmitter<any>();

  @Output() addWidgetToProduct = new EventEmitter<any>();
  @Output() editWidget = new EventEmitter<any>();
  @Output() deleteWidget = new EventEmitter<any>();

  @Input() customHeader: string = null;
  @Input() companyID;
  @Input() numberIndex;
  @Input() selectedProduct;
  @Input() groupName;
  @Input() cartWidgets;

  @Input() isReadOnly: boolean;
  @Input() isRentalEdit: boolean; //Mini cart bookings editor
  @Input() isRentalAdd: boolean;

  subs = new Subscription(); // group of subscriptions

  // Check if subscriptions are loaded
  inventoryPageLoaded = false;
  groupsLoaded = false;
  widgetsLoaded = false;

  companyWidgetList;
  accordianItems;
  productWidgetList = [];

  selectedDate : Date;


  optionChoice;
  checkoutOptions;

  expandedIndex = 0;

  someId = uuid();

  customerView: boolean = false;

  inventoryPageId: string;
  isDataAvailable:boolean = false;

  components;
  selectedComponent;

  productGroups; // The groups that product widgets point to

  addedWidgets!: Array<WidgetInterface>;
  widgets

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private productsService: ProductsService,
              private widgetService: WidgetService){};

  async ngOnInit() {
    this.numberIndex = this.numberIndex + 1;
    this.isDataAvailable = true;
  }


  ngOnChanges(){
    if(this.isDataAvailable){
      this.getAllData();
    }

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe(); // unsubscribe from all subscriptions in this group
    document.body.classList.remove('drag-drop-page');
  }

  async getAllData(){
    if(this.groupInventoryPage){
      this.isDataAvailable = true;
    }

  }

  async areSubsLoaded(){
    if(this.groupsLoaded && this.widgetsLoaded){
      // this.emitData.emit()
      this.getAllData();
    }
  }


  // Navigates back to inventory page builder home page
  onGoBack(){
    this.router.navigate(['/partner/inventoryPageBuilder'])
  }

  onWidgetInputChanged(e){
    console.log("view input change")
    this.widgetInputChanged.emit(e);
  }

  onProductInputChange(e){
    console.log("product input changed")
    this.productInputChanged.emit(e);
  }


} // End of class
