import { firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';

/* Libraries */
import { DateTime } from 'luxon';

/* Models */
import { BookingContent, LegacyGridsterGridPosition } from '../models/booking-flow.model';
import { BookingFlowCollectionPreReqs } from '../models/booking-flow.model';
import { BookingPage } from 'src/app/models/storage/booking-page.model';
import { Collection } from 'src/app/v2/models/collection-reference.model';
import { ProductSizeType } from 'src/app/models/storage/product-size-type.model';
import { DiscountCode } from 'src/app/v2/models/storage/discount-code.model';
import { InventoryPage } from 'src/app/models/storage/inventory-page.model';
import { Product } from 'src/app/models/storage/product.model';
import { ProductGroup } from 'src/app/models/storage/product-group.model';
import { ProductLocation } from 'src/app/models/storage/product-location.model';
import { WidgetInterface } from 'src/app/models/widget.model';

/* Services */
import { FirestoreService } from './firestore.service';
import { ParallelPromiseService } from './parallel-promise-grouping.service';
import { ProductsService } from 'src/app/services/products.service';

@Injectable({
  providedIn: 'root'
})
export class BookingFlowService {

  constructor(private firestoreService: FirestoreService, private productService: ProductsService, private parallelPromiseService: ParallelPromiseService) { }

  public translateGridsterToGridstack(item: LegacyGridsterGridPosition) {
    // These properties directly influence the gridstack component
    return {
      x: item.x,
      y: item.y,
      w: item.cols,
      h: item.rows,
    };
  }

  public assignContentPageTitle(cont: BookingContent, bookingsPageMap: { [id: string]: BookingPage }, productGroupMap: { [id: string]: ProductGroup }): string {
    try {
      let customerPageTitle = "";
      if (cont.isPage) {
        customerPageTitle = bookingsPageMap[cont.id].title;
      }
      if (cont.isItem) {
        customerPageTitle = productGroupMap[cont.id].groupName;
      }
      return customerPageTitle;
    }
    catch {
      console.error("Error - cannot assign content title.");
      return "";
    }
  }

  async validateDocuments(ids: string[], collection: Collection): Promise<{ valid: boolean, missingIDs: string[] }> {
    const missingIDs: string[] = [];

    if (ids.length === 0) {
      return { valid: true, missingIDs: missingIDs };
    }

    await Promise.all(ids.map(async (id) => {
      try {
        const doc = await this.firestoreService.getDocument(collection, id);
        if (!doc.isActive) {
          missingIDs.push(id);
        }
      } catch (error) {
        missingIDs.push(id);
      }
    }));

    const valid = missingIDs.length === 0
    return { valid, missingIDs };
  }

  public async validateBookingFlowContent(content: BookingContent[]): Promise<{ bookingFlowValid: boolean, missingPageIDs: string[], missingProductGroupIDs: string[] }> {
    const bookingPageIDs: string[] = [];
    const productGroupIDs: string[] = [];

    // Assign IDs by content type
    content.forEach((cont: BookingContent) => {
      if (cont.isPage) {
        bookingPageIDs.push(cont.id);
      }
      if (cont.isItem) {
        productGroupIDs.push(cont.id);
      }
    })

    const [pagesValidation, itemsValidation] = await Promise.all([
      this.validateDocuments(bookingPageIDs, Collection.BookingPages),
      this.validateDocuments(productGroupIDs, Collection.ProductGroup)
    ]);

    const { valid: pagesAreValidated, missingIDs: missingPageIDs } = pagesValidation;
    const { valid: productGroupsAreValidated, missingIDs: missingProductGroupIDs } = itemsValidation;
    const bookingFlowValid = pagesAreValidated && productGroupsAreValidated;
    return { bookingFlowValid, missingPageIDs, missingProductGroupIDs };
  }

  public getDefaultDates(timezone: string): { dayStart: DateTime, dayEnd: DateTime } {
    const currentDate = DateTime.now().setZone(timezone);
    return { dayStart: currentDate, dayEnd: currentDate };
  }

  private async queryAndApplyDefaultSizeTypeAndSizes(productSizeTypeMap, productSizesMap): Promise<any> {
    // Default SizeType
    const defaultSizeType = await this.productService.getCustomSizeTypesPromiseDefault(); // get default size type (1 doc for all companies)
    defaultSizeType.forEach(async (sizeType) => {
      productSizeTypeMap[sizeType.id] = sizeType

      // Default Sizes
      const defaultSizes = await this.productService.getCustomSizesPromise(sizeType.id);
      defaultSizes.forEach((size) => {
        productSizesMap[size.id] = size;
      })

      return { productSizeTypeMap, productSizesMap }
    })

    return { productSizeTypeMap, productSizesMap }
  }

  public async getBookingFlowCollections(companyID: string, optsPromise?: Promise<any>[]): Promise<BookingFlowCollectionPreReqs> {
    let promises: Promise<any>[] = []; // A variety of types will be pushed onto this arr (so using any)
    let promisesResult = {} as BookingFlowCollectionPreReqs;

    const
      productGroupCollectionPromise = firstValueFrom(this.firestoreService.getCollection(Collection.ProductGroup, [{ field: 'companyID', operator: '==', value: companyID }, { field: 'isActive', operator: '==', value: true }], undefined, { limit: 0 })),
      productsCollectionPromise = firstValueFrom(this.firestoreService.getCollection(Collection.Products, [{ field: 'companyID', operator: '==', value: companyID }, { field: 'isActive', operator: '==', value: true }], undefined, { limit: 0 })),
      bookingPagesCollectionPromise = firstValueFrom(this.firestoreService.getCollection(Collection.BookingPages, [{ field: 'companyID', operator: '==', value: companyID }, { field: 'isActive', operator: '==', value: true }])),
      productLocationCollectionPromise = firstValueFrom(this.firestoreService.getCollection(Collection.ProductLocations, [{ field: 'companyID', operator: '==', value: companyID }, { field: 'isActive', operator: '==', value: true }])),
      widgetCollectionPromise = firstValueFrom(this.firestoreService.getCollection(Collection.Widgets, [{ field: 'companyID', operator: '==', value: companyID }, { field: 'isActive', operator: '==', value: true }])),
      productSizesCollectionPromise = firstValueFrom(this.firestoreService.getCollection(Collection.ProductSizes, [{ field: 'companyId', operator: '==', value: companyID }, { field: 'isActive', operator: '==', value: true }])),
      productSizeTypesCollectionPromise = firstValueFrom(this.firestoreService.getCollection(Collection.ProductSizeTypes, [{ field: 'companyId', operator: '==', value: companyID }, { field: 'isActive', operator: '==', value: true }])),
      discountCodeCollectionPromise = firstValueFrom(this.firestoreService.getCollection(Collection.DiscountCodes, [{ field: 'companyID', operator: '==', value: companyID }, { field: 'isActive', operator: '==', value: true }, { field: 'expiredDate', operator: '>', value: new Date() }])),
      inventoryPageCollectionPromise = firstValueFrom(this.firestoreService.getCollection(Collection.InventoryPages, [{ field: 'companyID', operator: '==', value: companyID }, { field: 'isActive', operator: '==', value: true }]));

    // Combine productSizeTypesCollection and productSizesCollection into a single promise to be processed together
    const productSizeTypesAndSizesPromise = this.parallelPromiseService.promisedProccessing(productSizeTypesCollectionPromise, async (res: ProductSizeType[]) => {
      promisesResult.productSizeTypeMap = this.firestoreService.createCollectionMap(res);

      const productSizeQueryResponse = await productSizesCollectionPromise;
      promisesResult.productSizesMap = this.firestoreService.createCollectionMap(productSizeQueryResponse);

      // After both collections are fetched and processed, apply default sizes / sizetype
      let productSizeInfoIncludingDefaults = await this.queryAndApplyDefaultSizeTypeAndSizes(
        promisesResult.productSizeTypeMap,
        promisesResult.productSizesMap
      );

      // Update promisesResult with the final values
      promisesResult.productSizeTypeMap = productSizeInfoIncludingDefaults.productSizeTypeMap;
      promisesResult.productSizesMap = productSizeInfoIncludingDefaults.productSizesMap;
    });

    // Push the combined promise into the main promises array
    promises.push(productSizeTypesAndSizesPromise);

    // Booking Pages
    promises.push(this.parallelPromiseService.promisedProccessing(bookingPagesCollectionPromise, (res: BookingPage[]) => {
      promisesResult.customerBookingPagesMap = this.firestoreService.createCollectionMap(res);
    }))

    // Product Group
    promises.push(this.parallelPromiseService.promisedProccessing(productGroupCollectionPromise, (res: ProductGroup[]) => {
      promisesResult.productGroupCollectionArray = res;
      promisesResult.productGroupsMap = this.firestoreService.createCollectionMap(res);
    }))

    // Products
    promises.push(this.parallelPromiseService.promisedProccessing(productsCollectionPromise, (res: Product[]) => {
      promisesResult.productCollectionArray = res;
      promisesResult.allProductsMap = this.firestoreService.createCollectionMap(res);
    }))

    // Product Locations
    promises.push(this.parallelPromiseService.promisedProccessing(productLocationCollectionPromise, (res: ProductLocation[]) => {
      promisesResult.locationMap = this.firestoreService.createCollectionMap(res);
      promisesResult.productLocationCollectionArray = res;
    }))

    // Widgets
    promises.push(this.parallelPromiseService.promisedProccessing(widgetCollectionPromise, (res: WidgetInterface[]) => {
      promisesResult.widgetCollectionMap = this.firestoreService.createCollectionMap(res);
      promisesResult.widgetCollectionArray = res;
    }))

    // Discount Codes
    promises.push(this.parallelPromiseService.promisedProccessing(discountCodeCollectionPromise, (res: DiscountCode[]) => {
      res.forEach((discountCode) => {
        if (!promisesResult.discountCodeMap) {
          promisesResult.discountCodeMap = {};
        }
        promisesResult.discountCodeMap[discountCode.discountTitle.toLowerCase()] = discountCode;
      })
    }))

    // Inventory Page
    promises.push(this.parallelPromiseService.promisedProccessing(inventoryPageCollectionPromise, (res: InventoryPage[]) => {
      promisesResult.inventoryPageMap = this.firestoreService.createCollectionMap(res);
    }))

    // Await the completion of all promises
    await this.parallelPromiseService.processMultiplePromisesInParallel(promises);

    // Return the mapped results
    return promisesResult;
  }
}
