<div *ngIf="showDateRange" class="container" style="padding: 40px; max-width: 100%; background-color: white;">
    <div class="row" style="margin-bottom: 20px;">
        <div class="col-sm-12">
            <h1 class="text-center">
                {{ productGroup?.['groupName'] }}
            </h1>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-6" style="padding:15px">
            <span class="mb-3">
                <img src="{{ productGroup?.['groupImage'] }}" class="img-fluid" style="max-width:100%; height:auto;">
            </span>
            <div style="padding:15px 10px;">
                <p style="text-align: center;">
                    {{ productGroup?.['groupDescription'] }}
                </p>
            </div>
        </div>

        <div class="col-sm-6" style="padding:15px">
            <div class="row">
                <div *ngIf="isLoading && customerStartDate && customerEndDate && customerLocation"  class="d-flex text-center justify-content-center" style="margin-top: 15px;" >
                    <div class="spinner-border" role="status">
                        <span class="sr-only" i18n>Loading...</span>
                    </div>
                </div>
                <div class="mb-2">
                    <div *ngIf="errorMessage && !isLoading" class="text-center justify-content-center" style="margin-top: 15px; color:red;">
                        <b>{{errorMessage}}</b>
                    </div>
                </div>
                <div class="mb-2">
                    <div *ngIf="outtaHereEbikesCustomerMessage" class="text-center justify-content-center" style="margin-top: 15px; color:red;">
                        <b>{{outtaHereEbikesCustomerMessage}}</b>
                    </div>
                </div>
                    <div *ngIf="showProductDropdown && !errorMessage && !isLoading && !outtaHereEbikesCustomerMessage" class="mb-2">
                        <div *ngIf="productsOrganized.length > 0">
                            <label class="dropdown-label"><b i18n>Product:</b></label>

                            <select class="form-select" [(ngModel)]="selectedProduct"
                                (change)="setSelectedSize()"
                                disbaled="productsDropdownDisabled">
                                <!-- <option [ngValue]="null" *ngIf="selectedProduct === null && productsOrganized.length > 1" hidden selected [ngValue]="null">- Choose a product -</option>
                                <option [ngValue]="choice.size" *ngFor="let choice of productsOrganized" [selected]="productsOrganized.length == 1"> -->
                                <ng-container *ngIf="productsOrganized.length > 1; else productsOrganizedSingleValue">
                                    <option *ngIf="selectedProduct === null" hidden selected [ngValue]="null" i18n>- Choose a product -</option>
                                    <option [ngValue]="choice" *ngFor="let choice of productsOrganized" [disabled]="!choice.isAvailable">
                                        {{ choice.pgName }},
                                        {{ choice.sizeName }}
                                    </option>
                                </ng-container>

                                <!-- Automatic fill if there is only one dropdown item -->
                                <ng-template #productsOrganizedSingleValue>
                                    <option selected [ngValue]="productsOrganized[0]">
                                        {{ productGroup.groupName }},
                                        {{ productsOrganized[0]?.sizeName }}
                                    </option>
                                </ng-template>
                            </select>
                        </div>
                    </div>
                <ng-template #cartProductDropdown>

                </ng-template>
            </div>
            <div class="row">
                <div style="justify-content: center;" class="mb-2">
                    <div *ngIf="selectedProduct && timeLengthOptions && !errorMessage">
                        <ng-container *ngIf="timeLengthOptions.length >= 1 && numberOfDaysChosen < 2 || numberOfDaysChosen > 1 || numberOfDaysChosen < 2 && productGroup.is24hrsPrice; else noAvailHours">
                            <label class="dropdown-label"><b i18n>Hours:</b></label>

                            <select class="form-select" [(ngModel)]="selectedHours" (change)="findOverlapTimes(); updateProductWidgetPrices();"
                                [disabled]="numberOfDaysChosen > 1">

                                <ng-container *ngIf="timeLengthOptions.length > 1; else hoursSingleValue">
                                    <option *ngIf="numberOfDaysChosen > 1" selected hidden>{{ selectedHours }}</option>
                                    <option *ngIf="numberOfDaysChosen == 1 && productGroup.is24hrsPrice" selected hidden>{{ selectedHours }}</option>
                                    <option *ngIf="numberOfDaysChosen == 1 && selectedHours === null" selected hidden [ngValue]="null" i18n>- Select hour amount -</option>
                                    <option *ngFor="let hour of timeLengthOptions" [ngValue]="hour">{{hour}}</option>
                                </ng-container>

                                <ng-template #hoursSingleValue>
                                    <option selected [ngValue]="timeLengthOptions[0]">
                                        {{ timeLengthOptions[0] }}
                                    </option>
                                </ng-template>

                            </select>

                        </ng-container>
                        <ng-template #noAvailHours>
                            <div class="text-center justify-content-center" style="margin-top: 15px; color:red;">
                                <b i18n>No hours available for this item</b>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="row">
                <div style="justify-content: center;" class="mb-2">
                    <ng-container *ngIf="selectedHours && selectedProduct && timeLengthOptions.length > 0 && !errorMessage">
                        <label class="dropdown-label"><b i18n>Available Times:</b></label>

                        <select class="form-select" [(ngModel)]="selectedAvailableTime"
                            (change)="setQuantityDropdown();">

                            <ng-container *ngIf="availabilityOverlap.length > 1; else overlapSingleValue">
                                <option *ngIf="selectedAvailableTime === null" selected hidden [ngValue]="null" i18n>- Choose a time -</option>
                                <option *ngFor="let avail of availabilityOverlap; index as i" [ngValue]="avail">
                                    {{ displaySimpleDayStartEnd(avail.type, avail['dayStart'], avail['dayEnd']) }}
                                </option>
                            </ng-container>

                            <ng-template #overlapSingleValue>
                                <option selected [ngValue]="availabilityOverlap[0]">
                                    {{ displaySimpleDayStartEnd(availabilityOverlap[0].type, availabilityOverlap[0]['dayStart'], availabilityOverlap[0]['dayEnd']) }}
                                </option>
                            </ng-template>

                        </select>

                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div style="justify-content: center;" class="mb-2">
                    <div *ngIf="selectedAvailableTime && dropdownQuantity && selectedProduct && !errorMessage">
                        <ng-container *ngIf="dropdownQuantity.length >= 1; else noAvailQuantity">
                            <label class="dropdown-label"><b i18n>Quantity:</b></label>

                            <select class="form-select" [(ngModel)]="selectedQuantity"
                                (change)="calculateIndividualRentalPrice(); showWidgets();">

                                <ng-container *ngIf="dropdownQuantity.length > 1; else quantitySingleValue">
                                    <option selected hidden>0</option>
                                    <option *ngFor="let number of dropdownQuantity" [ngValue]="number">{{number}}</option>
                                </ng-container>

                                <ng-template #quantitySingleValue>
                                    <option selected [ngValue]="dropdownQuantity[0]">
                                        {{ dropdownQuantity[0] }}
                                    </option>
                                </ng-template>

                            </select>

                        </ng-container>
                        <ng-template #noAvailQuantity>
                            <div class="text-center justify-content-center" style="margin-top: 15px; color:red;">
                                <b i18n>No items available for the time chosen</b>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>

            <div class="row d-flex" *ngIf="showRentalPrice && selectedAvailableTime && selectedQuantity != 0">
                <div class="m-2 text-right" >
                    <div>
                      <h2 i18n>Rental Price: {{ currentRentalPrice | currency }}</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="align-items-center" style="margin:auto">
            <div *ngIf="showAddons && groupInventoryPage && groupInventoryPage.widgetList.length > 0">
                <hr>

                <div *ngIf="productWidgetsMeetRequirements; else prodWidgetsDontMeetRequirements">
                    <h2 i18n>Addons:</h2>
                <div class="row" *ngIf="invPageArray.length > 0">
                    <div *ngFor="let page of invPageArray; index as ind" class="box-containers">
                        <div class="col-6-sm">
                            <app-inventory-page-view [groupInventoryPage]="page" [companyID]="companyID"
                                [numberIndex]= "ind" [selectedProduct]="selectedProduct" [groupName]="productGroup.groupName"
                                (widgetInputChanged)="onWidgetInputChange($event)"
                                (productInputChanged)="productWidgetInputChangeMaxAdjustment($event)"
                                >
                            </app-inventory-page-view>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 offset-md-8 mb-2 text-right" i18n>
                        Addons Total: {{ addonPrice | currency }}
                    </div>
                </div>
                </div>
                <ng-template #prodWidgetsDontMeetRequirements>
                    <span i18n>There are not enough products available for the quantity selected at this time</span>
                </ng-template>
            </div>

            <hr style="margin-top: 10px;">

            <div *ngIf="selectedQuantity && selectedProduct && selectedHours && selectedAvailableTime
                && productWidgetsMeetRequirements"
                class="col-md-4 offset-md-8 mb-2 text-right" style="justify-content: center;">
                <div>
                    <h2 i18n>
                        Total: {{ totalPrice | currency }}
                    </h2>
                </div>

                <div *ngIf="!editingCartItem;">
                    <button (click)="addToCart();" class="btn btn-primary" i18n>Add To Cart</button>
                </div>
                <div *ngIf="editingCartItem">
                    <button (click)="addToCart();" class="btn btn-primary" i18n>Save Changes</button>
                </div>

                <div *ngIf="!requiredWidgetCheck" style="color:red; margin-top: 5px;">
                    <b>Please fill out the required addons.</b>
                </div>
                <div *ngIf="this.tourMinProductsRequirementsMet && !this.tourMinProductsRequirementsMet.bool" style="color:red; margin-top: 5px;">
                    <b i18n>At least {{productGroup.minProductsAttached}} product(s) required for each tour.
                        Please select {{tourMinProductsRequirementsMet.count}} more product addon(s)</b>
                </div>
            </div>
        </div>
    </div>
