<div class="container-fluid">
  <div class="row justify-content-center mt-2">
    <div class="col-md-12 text-left">
      <h1 class="mt-2 mb-2" style="font-size: 32px">
        User Management
        <button
          [style.background]="text"
          [style.color]="background"
          type="button"
          (click)="cleanValidation()"
          class="btn btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#addUser"
        >
          + Add New User
        </button>
      </h1>
    </div>
  </div>
</div>

<div class="container-fluid mt-3" *ngIf="users.length">
  <table class="table table-striped hover" id="usersTable">
    <thead [style.background]="background" [style.color]="text">
      <tr>
        <th scope="col" class="sorting">Icon</th>
        <th scope="col">Name</th>
        <th scope="col">Email</th>
        <th scope="col">Phone</th>
        <th scope="col">Level Type</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let u of users" (click)="openEdit(u)" style="cursor: pointer">
        <td *ngIf="u.icon.code && u.isActive">
          <img src="{{ u.icon.code }}" class="flag" />
        </td>
        <td *ngIf="!u.icon.code && u.isActive">
          <img src="assets/user/noFace.png" class="flag" />
        </td>
        <td *ngIf="u.isActive">{{ u.firstName }} {{ u.lastName }}</td>
        <td *ngIf="u.isActive">{{ u.email }}</td>
        <td *ngIf="u.isActive">{{ u.phone }}</td>
        <td *ngIf="u.isActive">{{ u.levelType }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div
  class="modal fade"
  id="addUser"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addUserLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-bs-dismiss="modal"
          aria-bs-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-create-account></app-create-account>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="editUser"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addUserLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- Tittle-->
        <h5
          *ngIf="!_currentUserService.currentUser.isDeveloper"
          class="modal-title"
          id="addUserLabel"
        >
          Edit user
        </h5>
        <h5
          *ngIf="_currentUserService.currentUser.isDeveloper"
          class="modal-title"
          id="addUserLabel"
        >
          <a
            href="https://console.firebase.google.com/u/0/project/{{ projectId }}/firestore/data/~2Fusers/{{
              currentUser?.id
            }}"
            target="_blank"
            style="text-decoration: none"
            >Edit user</a
          >
        </h5>
        <button
          type="button"
          class="close"
          data-bs-dismiss="modal"
          aria-bs-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="row">
            <div class="col" *ngIf="currentUser != undefined">
              <!-- FORM with user information -->
              <form
                class="needs-validation"
                [formGroup]="userInfo"
                (ngSubmit)="validateForm()"
              >
                <div class="row">
                  <div class="form-group col-lg-6 col-md-12">
                    <label for="name">First Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      [(ngModel)]="currentUser.firstName"
                      [readonly]="!isEditing"
                      formControlName="firstName"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div class="invalid-tooltip">
                    Please provide a valid name.
                  </div>
                  <div class="form-group col-lg-6 col-md-12">
                    <label for="lastName">Last Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="lastName"
                      [(ngModel)]="currentUser.lastName"
                      [readonly]="!isEditing"
                      formControlName="lastName"
                      placeholder="Last Name"
                      required
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-lg-6 col-md-12">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      [(ngModel)]="currentUser.email"
                      [readonly]="!isEditing"
                      formControlName="email"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <!-- Password field -->
                  <div class="form-group col-lg-6 col-md-12">
                    <label for="password">Password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="password"
                      [(ngModel)]="currentUser.password"
                      [readonly]="!isEditing"
                      formControlName="password"
                      placeholder="*******"
                    />
                  </div>
                  <div class="form-group col-lg-6 col-md-12">
                    <label for="phone">Phone</label>
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      [(ngModel)]="currentUser.phone"
                      [readonly]="!isEditing"
                      formControlName="phone"
                      placeholder="Phone"
                      required
                    />
                  </div>
                  <div class="form-group col-lg-6 col-md-12">
                    <label for="levelType">Level type</label>
                    <select
                      class="form-select"
                      id="levelType"
                      [(ngModel)]="currentUser.levelType"
                      formControlName="levelType"
                    >
                      <option value="Partner">Partner</option>
                      <option value="Employee">Employee</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <!--Logo-->
                  <div class="form-group col-lg-6 col-md-12" *ngIf="isEditing">
                    <label for="icon">Icon</label>
                    <br />
                    <p-dropdown
                      id="icon"
                      [options]="icons"
                      [(ngModel)]="currentUser.icon"
                      optionLabel="name"
                      formControlName="icon"
                      [panelStyleClass]="'form-control'"
                    >
                      <ng-template let-icon pTemplate="selectedItem">
                        <div class="icon-item icon-item-value" *ngIf="icon">
                          <img
                            src="{{ icon.code }}"
                            alt=""
                            [class]="'flag flag-' + icon.name.toLowerCase()"
                          />
                          <div>{{ icon.name }}</div>
                        </div>
                      </ng-template>
                      <ng-template let-icon pTemplate="item">
                        <div class="icon-item">
                          <img
                            src="{{ icon.code }}"
                            alt=""
                            [class]="'flag flag-' + icon.name.toLowerCase()"
                          />
                          <div>{{ icon.name }}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>

                <!-- submit and cancel buttons align right -->
                <div class="row mt-1" *ngIf="isEditing">
                  <div class="col">
                    <button
                      *ngIf="currentUser.isActive"
                      type="button"
                      class="btn btn-outline-secondary float-start"
                      (click)="deleteUser()"
                    >
                      Delete user
                    </button>
                    <button
                      *ngIf="!currentUser.isActive"
                      type="button"
                      class="btn btn-outline-secondary float-start"
                      (click)="enableUser()"
                    >
                      Reactivate user
                    </button>
                    <button type="submit" class="btn btn-primary float-end">
                      Save
                    </button>
                    <!-- close dialog-->
                    <button
                      type="button"
                      class="btn btn-secondary float-end me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
              <div class="row" *ngIf="!isEditing">
                <div class="col-3">
                  <button
                    type="button"
                    [disabled]="!currentUser.isActive"
                    class="btn btn-primary"
                    (click)="enableEdition()"
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>

            <!-- Button to enable edition -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
