import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import { User } from 'src/app/models/storage/user.model';
import {CurrentUserService} from '../services/current-user.service';
import firebase from 'firebase/compat/app';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private currentUserService: CurrentUserService
  ) {

  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.currentUserService.getCurrentUserOnce().then(user => {
      if (user === null) {
        this.router.navigate(['/']);
        return false;
      } else {
        return this.rules(this.currentUserService.afUser, user, next, state)
      }
    })
  }

  rules(auth: firebase.User, user: User, next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    //CHECK IF FIREBASE AUTHENTICATED
    if (auth) {

      //CHECK USER PERMISSIONS
      if (user) {

        //USER IS BLOCKED: RETURN TO LOGIN PAGE
        if (user.isBlocked !== undefined && user.isBlocked === true) {
          this.router.navigate(['/']);
          return false

          //URL CHECKING: REDIRECT TO DASHBOARD WHERE DENIED URL ACCESS
        } else {
          var url = state.url

          let forbiddenURLMember = [
            '/partner'
          ]

          if (url.includes('/partner/urlwithgetid') === true) {
            url = '/partner/urlwithgetid';
          }

          //PUBLIC LEVEL ACCESS
          if (user.levelType === 'Public') {
            switch (url) {
              case '/member/account':
                break;
              //default: this.router.navigate(['/']);
            }

            //PARTNER LEVEL ACCESS
          } else if (user.level === 'Partner') {
            console.log(this.currentUserService.currentUser)
            //If Rebuild Access Prevention
            if(user.id !='GHtIJGDdnFNMwqfxdsEIIKnwc1M2' && !user.isDeveloper){
              if(this.currentUserService.currentUser.currentCompany.isRebuild === true){
                if(url==='/partner/bookings-v1'){this.router.navigate(['/partner/bookings'])}
                if(url==='/partner/inventoryCalendar'){this.router.navigate(['/partner/inventoryCalendarRebuild'])}
                if(url==='/partner/checkoutOptions'){this.router.navigate(['/partner/inventoryPageBuilder'])}
              } else {
                if(url==='/partner/bookings'){this.router.navigate(['/partner/bookings-v1'])}
                if(url==='/partner/inventoryCalendarRebuild'){this.router.navigate(['/partner/inventoryCalendar'])}
                if(url==='/partner/inventoryPageBuilder'){this.router.navigate(['/partner/checkoutOptions'])}
              }
            }

            //Developer Only
            if(user.isDeveloper!=true){
              //transaction access removed for now
              if(url==='/partner/transaction'){
                this.router.navigate(['/partner'])
              }
            }

            if (user.levelType === 'Employee') {
              switch (url) {
                case '/partner/report':
                  this.router.navigate(['/partner'])
                  break;
                case '/partner/transaction':
                  this.router.navigate(['/partner'])
                  break;
                case '/partner/usersManagement':
                  this.router.navigate(['/partner/userInfo'])
              }

            // 7JQQkZqjwxshPILXaoBf == FleetMaid
            } else  if(user.companyId != "7JQQkZqjwxshPILXaoBf") {
              switch (url) {
                case '/partner/applicationFees':
                  this.router.navigate(['/partner/transaction'])
              }
            } else if(user.companyId == "7JQQkZqjwxshPILXaoBf") {
              switch (url) {
                case '/partner/transaction':
                  this.router.navigate(['/partner/applicationFees'])
              }
            }

            else {
              switch (url) {
                case '/partner/account':
                  break;
                //default: this.router.navigate(['/']);
              }
            }


            //ADMIN LEVEL ACCESS
          } else if (user.level === 'Admin') {

            switch (url) {
              case '/member':
                break;
              //default: this.router.navigate(['/']);
            }
          } else if (user.level === 'Member') {
            switch (url) {
              case '/':
                break;
            }

            if (forbiddenURLMember.includes(url)) {
              this.router.navigate(['/']);

            }
          }

          return true
        }

        //NO USER CREDENTIALS: RETURN TO LOGIN PAGE
      } else {
        this.router.navigate(['/']);
        return false
      }

      //NOT AUTHENTICATED: RETURN TO LOGIN PAGE
    } else {

      this.router.navigate(['/']);
      return false;
    }
  }

}
