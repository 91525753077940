import { BookingPage } from 'src/app/models/storage/booking-page.model';
import { DateTime } from 'luxon';
import { DiscountCode } from 'src/app/v2/models/storage/discount-code.model';
import { GridStackNode } from 'gridstack';
import { GridStackPosition } from 'gridstack';
import { InventoryPage } from 'src/app/models/storage/inventory-page.model';
import { Product } from 'src/app/models/storage/product.model';
import { ProductGroup } from 'src/app/models/storage/product-group.model';
import { ProductLocation } from 'src/app/models/storage/product-location.model';
import { ProductSize } from './product-size.model';
import { ProductSizeType } from 'src/app/models/storage/product-size-type.model';
import { WidgetInterface } from 'src/app/models/widget.model';
import { TimeslotType } from 'src/app/models/availability-timeslot.model';

// Follows bookingTemplate db modal
export interface BookingTemplate { // Use with ContentData if referencing database
    title: string,
    isActive: boolean,
    companyID: string,
    content: BookingContent[],
    breadcrumbs: CatalogBreadcrumbs[],
    gridHeight: number,
    gridRows?: number, // Legacy
    viewTypePreference: ViewTypePreference,
    editState?: { isEditing: false, user: '' }, // Legacy
    dateCreated?: Date,
    datesInactivated: [],
    companyLogo: string,
    companyName: string,
    id?: string, // Sometimes included from collection return
}

export interface BookingContent { // Exists on every content card
    gridRows?: number, // Legacy
    productGroupID?: string, // Legacy
    gridPosition?: LegacyGridsterGridPosition, // Legacy
    contentID: string, // in page content element UUID
    gridHeight: number,
    gridstackGridPosition?: GridStackPosition, // New grid position
    id: string, // firestore document id for given content type
    isExternal: boolean,
    isItem: boolean,
    isPage: boolean,
    onPage: string,
    title: string,
    img?: string, // occurs after processing is done (could come from bookingPage or productGroup collection)
    subHeading?: string, // occurs if working with a page
    itemUnavailable?: boolean, // occurs if working with an item
    sizesAvail?: SizesAvail[]
}
export interface CatalogBreadcrumbs {
    pageID: string,
    breadcrumbs: string[]
}

export interface LegacyGridsterGridPosition { // Legacy
    cols: number,
    rows: number,
    x: number,
    y: number
}

export interface BookingFlowContentActionEvent {
    type: BookingFlowContentAction;
    contentID?: string;
    updatedContArray?: MappedGridstackItem[];
    pageID?: string;
}

// When working with Catalog Content that's been transformed to work with gridstack (grid drag and drop library)
// GridStackPosition
export interface MappedGridstackItem extends GridStackNode{
    originalW?: number;
    originalH?: number;
    contentData: BookingContent
}

export enum BookingFlowContentType {
    Catalog = 'Catalog',
    DateRangeSearch = 'DateRangeSearch',
    Cart = 'Cart',
    CustomerInfo = 'CustomerInfo',
    PaymentIntent = 'PaymentIntent',
    CheckoutSuccess = 'CheckoutSuccess',
    CartItemEdit = 'CartItemEdit'
}

export enum BookingFlowContentAction {
    EditPage = 'edit-page',
    Remove = 'remove',
    GridItemPositionChange = 'gridItemPositionChange'
}

export enum ViewTypePreference {
    catalog = "catalog",
    date = "date"
}

export interface SizesAvail {
    sizeID: string,
    sizeName: string,
    sizeTypeID: string,
    sizeTypeName: string,
    pgName: string,
    isAvailable: boolean,
    currentAvail: number
}

export interface DateRangeContentCard { // This interface can have it's fields reduced / optimized but needs other PRs
    hasAvailableQuantity: boolean,
    listOfSizes: {},
    pgName: string,
    productData: any,
    productGroupID: string,
    products: [],
    sizesAvail: SizesAvail,
    sizingAvail: [{cartQty: number, currentAvail: number, totalAvail: number, sizeName: string, sizeTypeID: string, isAvailable: boolean}]
}


export interface BookingFlowCollectionPreReqs extends AvailabilityPreReqs {
    customerBookingPagesMap: { [key: string]: BookingPage }

}
export interface AvailabilityPreReqs {
    widgetCollectionMap: { [key: string]: WidgetInterface }
    inventoryPageMap: { [key: string]: InventoryPage }
    discountCodeMap: { [key: string]: DiscountCode }
    locationMap: { [key: string]: ProductLocation }
    productSizeTypeMap: { [key: string]: ProductSizeType }
    productSizesMap: { [key: string]: ProductSize }
    allProductsMap: { [key: string]: Product }
    productGroupsMap: { [key: string]: ProductGroup }
    productLocationCollectionArray: ProductLocation[]
    productCollectionArray: Product[]
    productGroupCollectionArray: ProductGroup[]
    widgetCollectionArray: WidgetInterface[]
}

export type TimeslotOption = { dayStart: DateTime, dayEnd: DateTime, type: TimeslotType }; // Based off of db
export type TimeslotTypeLabels = "Hourly" | "Shop Day" | "24 Hrs"; // Presented on the DOM
export type TimeslotGroupedByType = { timeslotTypeLabel: TimeslotTypeLabels, timeslotOption: TimeslotOption[] };

export const defaultValues = {
    title: '',
    isActive: true,
    companyID: '',
    content: [],
    breadcrumbs: [],
    gridHeight: 0,
    gridRows: 0,
    viewTypePreference: 'catalog',
    dateCreated: new Date(),
    datesInactivated: [],
    companyLogo: '',
    companyName: '',
}
