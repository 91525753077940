import { Injectable } from '@angular/core';

/* Libraries */
import { DateTime } from 'luxon';

/* Models */
import { AvailabilityOverrides } from 'src/app/models/availability-overrides.model';
import { DateRangeInputState } from '../models/date-range.model';
import { ErrorCodes } from '../models/errors.model';
import { ProductLocation } from 'src/app/models/storage/product-location.model';
import { TimeslotType } from 'src/app/models/availability-timeslot.model';

/* Services */
import { AvailabilityInterface } from 'src/app/models/availability.model';
import { ErrorHandlingObject } from '../models/errors.model';
import { ErrorService } from './errors.service';
import { TimeService } from 'src/app/services/time.service';
import { Timeslot } from 'src/app/models/availability-timeslot.model';
import { TimeslotGroupedByType, TimeslotOption } from 'src/app/v2/models/booking-flow.model';

@Injectable({
    providedIn: 'root'
})

export class DateRangeService {

    constructor(private timeService: TimeService, private errorService: ErrorService) {
    }

    public validateDatepickerInputState(dateRangeInputOnChange: DateRangeInputState): boolean {
        const inputs = dateRangeInputOnChange;
        let isValid: boolean = true;

        const locationValid = this.validateLocationDatepicker(inputs.selectedLocationID);

        const datePickerValid = this.isDatePickerValid(inputs.selectedStartDate, inputs.selectedEndDate);

        isValid = (locationValid && datePickerValid);

        return isValid
    }

    public validateLocationDatepicker(selectedLocationID: string): boolean {
        if (!selectedLocationID || selectedLocationID === "") {
            return false;
        }
        return true;
    }

    public isDatePickerValid(selectedStartDate: DateTime, selectedEndDate: DateTime, availabilityOverride?: AvailabilityOverrides): boolean {
        // Test that selectedEndDate is a proper DateTime object
        if (!this.timeService.isValidLuxonDateTimeObject(selectedEndDate)) {
            return false;
        }

        // Test that selectedStartDate is a proper DateTime object
        if (!this.timeService.isValidLuxonDateTimeObject(selectedStartDate)) {
            return false;
        }

        // If selected end date (day) is before selected start date (day), invalid input
        if (selectedEndDate < selectedStartDate) {
            return false;
        }

        return true;
    }

    public isDatePickerValidWithAvailability(inputs: DateRangeInputState, locationObj: ProductLocation, availabilityOverride?: AvailabilityOverrides): ErrorHandlingObject {
        let validityObj: ErrorHandlingObject = { isValid: true, errors: [] };

        // A timezone is crucial to a valid location / the availability
        if (!locationObj?.timezone) {
            validityObj.isValid = false;
            validityObj.errors.push(this.errorService.getErrorByCode(ErrorCodes.INVALID_LOCATION));
            return validityObj;
        }

        const selectedTimezone: string = locationObj.timezone;
        let currentDay: DateTime = DateTime.now().setZone(selectedTimezone, { keepLocalTime: true }).startOf('day');

        // Date Range Inputs are prior to the locations current day
        if (inputs.selectedStartDate < currentDay || inputs.selectedEndDate < currentDay) {
            let hasOverride = (availabilityOverride && availabilityOverride.overridePastDatePrevention);
            if (!hasOverride) {
                validityObj.isValid = false;
                validityObj.errors.push(this.errorService.getErrorByCode(ErrorCodes.DATES_PASSED));
                return validityObj;
            }
        }

        return validityObj;
    }

    public generateUniqueAvailableTimeslots(availability: AvailabilityInterface): { timeslotOptions: TimeslotGroupedByType[], firstAvailableTimeslot: TimeslotOption | null } {
        // Use separate Sets to track unique timeslots for each rental timeslot type
        const shopDayTimeslotsSet = new Set(),
            hourlyTimeslotsSet = new Set(),
            twentyFourHourTimeslotsSet = new Set();

        // Store timeslots into different arrays by rental type
        const sortedTimeslots: { [key: string]: TimeslotOption[] } = {
            hourly: [],
            shopDay: [],
            twentyFourHr: []
        };

        // Loop through availabilities products, get their timeslots, and if available -> provide timeslot
        Object.keys(availability.resultSet).forEach((pgid: string) => {
            Object.keys(availability.resultSet[pgid].products).forEach((prod: string) => {
                const prodAvailTimeslots: Timeslot[] = availability.getProductAvailableTimeslots(pgid, prod);
                prodAvailTimeslots.forEach((timeslot) => { // Loop through each products available timeslots
                    const timeslotKey = `${timeslot.dayStart.toISOTime()}_${timeslot.dayEnd.toISOTime()}`;

                    const formattedTimeslotData: TimeslotOption = {
                        dayStart: timeslot.dayStart,
                        dayEnd: timeslot.dayEnd,
                        type: timeslot.type
                    }

                    // Check if the timeslot key already exists, if not, add data to it's respective timeslot array
                    switch (timeslot.type) {
                        /* Shop Day Timeslots */
                        case TimeslotType.ShopDay:
                            if (!shopDayTimeslotsSet.has(timeslotKey)) {
                                shopDayTimeslotsSet.add(timeslotKey);
                                sortedTimeslots.shopDay.push(formattedTimeslotData);
                            }
                            break;

                        /* Hourly Timeslots */
                        case TimeslotType.Hourly:
                            if (!hourlyTimeslotsSet.has(timeslotKey)) {
                                hourlyTimeslotsSet.add(timeslotKey);
                                sortedTimeslots.hourly.push(formattedTimeslotData);
                            }
                            break;

                        /* 24 Hour Timeslots */
                        case TimeslotType.TwentyFourHour:
                            if (!twentyFourHourTimeslotsSet.has(timeslotKey)) {
                                twentyFourHourTimeslotsSet.add(timeslotKey);
                                sortedTimeslots.twentyFourHr.push(formattedTimeslotData);
                            }
                            break;
                    }
                });
            });
        });

        // Sort each timeslot array by it's dayStart property
        Object.values(sortedTimeslots).forEach((arrayTimeslotsByRentalType) => {
            arrayTimeslotsByRentalType.sort((a: TimeslotOption, b: TimeslotOption) => a.dayStart.toMillis() - b.dayStart.toMillis());
        })

        // Display order
        const timeslotOptions: TimeslotGroupedByType[] =
            [
                { timeslotTypeLabel: 'Hourly', timeslotOption: sortedTimeslots.hourly },
                { timeslotTypeLabel: 'Shop Day', timeslotOption: sortedTimeslots.shopDay },
                { timeslotTypeLabel: '24 Hrs', timeslotOption: sortedTimeslots.twentyFourHr },
            ]

        const firstAvailableTimeslot = this.getFirstAvailableTimeslot(timeslotOptions);

        return { timeslotOptions: timeslotOptions, firstAvailableTimeslot: firstAvailableTimeslot };
    }

    private getFirstAvailableTimeslot(timeslotGroupedByType: TimeslotGroupedByType[]): Timeslot {
        let selectedTimeslot = null;
        timeslotTypeLoop: for (let i = 0; i <= timeslotGroupedByType.length - 1; i++) {
            if (timeslotGroupedByType[i].timeslotOption.length > 0) {
                selectedTimeslot = timeslotGroupedByType[i].timeslotOption[0];
                break timeslotTypeLoop
            }
        }
        return selectedTimeslot;
    }
}
