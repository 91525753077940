import { Component } from '@angular/core';
import {
  trigger, state, style, animate, transition, query, group, keyframes
} from '@angular/animations';


//  animate('2s', keyframes([
//           style({ transform: 'scale(1,1) translateY(0)' }),
//           style({ transform: 'scale(1.1, 0.9) translateY(0)' }),
//           style({ transform: 'scale(0.9, 1.1) translateY(-100px)' }),
//           style({ transform: 'scale(1.05, 0.95) translateY(0)' }),
//           style({ transform: 'scale(1,1) translateY(-7px)' }),
//           style({ transform: 'scale(1,1) translateY(0)' }),
//         ]))

// transition: transform 280ms ease-out 50ms; 
// transform: translateY(250%);
//         transition: transform 280ms ease-out 50ms; 

@Component({
  animations: [
    trigger('flyInOut', [
      state('in', style({ opacity:0, transform: 'translateY(20%)'})),
      transition('void => *', [
        animate('1.3s', keyframes([
          style({ opacity: 0.5, transform: 'translateY(-100%)'}),
          style({ opacity: 1, transform: 'translateY(0)',}),
          style({ transform: 'translateY(-10px)',}),
          style({ transform: 'translateY(0)'}),
        ]))
        // animate('1000ms'),
        // style({ transform: 'translateY(20%)'}),
        // animate('1000ms')
      ]),
      // transition('void => *', [
      //   // style({ opacity:0, transform: 'translateY(-100%)'}),
      //   // animate('500ms ease-out'),
      //   style({ transform: 'translateY(50%)'}),
      //   animate('1000ms'),
      //   style({ transform: 'translateY(20%)'}),
      //   animate('1000ms')
      // ])
    ]),
  trigger('fade', [
    transition('void => *', [
      animate('1.3s', keyframes([
        style({opacity: 0}),
        style({opacity: 1}),
        // style({ opacity: 0.5, transform: 'translateY(-100%)'}),
        // style({ opacity: 1, transform: 'translateY(0)',}),
        // style({ transform: 'translateY(-10px)',}),
        // style({ transform: 'translateY(0)'}),
      ]))
      // animate('1000ms'),
      // style({ transform: 'translateY(20%)'}),
      // animate('1000ms')
    ]),
    // transition(':enter', [style({ opacity: 0 }),
    // animate('1.3s', style({ opacity: 1 }))]),
    // transition(':leave', [style({ opacity: 1 }),
    // animate(1000, style({ opacity: 0 }))])
  ])
  ],

  selector: 'app-logo-transition',
  templateUrl: './logo-transition.component.html',
  styleUrls: ['./logo-transition.component.scss']
})
export class LogoTransitionComponent {

  showEvents = true;

  toggleFlag = false

  toogle = false;

  toggleEvents() {
    if (!this.toggleFlag) {
      this.showEvents = !this.showEvents
    }
  }

}
