import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Company } from 'src/app/models/storage/company.model';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  constructor(private $afs: AngularFirestore) {}

  public getStripePKey(isTesting) {
    if (isTesting) {
      return this.$afs
        .collection('companies')
        .doc('7JQQkZqjwxshPILXaoBf')
        .ref.get()
        .then((doc) => {
          return doc.data()['stripeTestPKey'];
        });
    } else {
      return this.$afs
        .collection('companies')
        .doc('7JQQkZqjwxshPILXaoBf')
        .ref.get()
        .then((doc) => {
          const data = doc.data() as any;
          return data.stripePublicKey;
        });
    }
  }

  public getStripeID(companyID) {
    return this.$afs
      .collection('companies')
      .doc(companyID)
      .ref.get()
      .then((doc) => {
        return doc.data()['stripeID'];
      });
  }

  public getCompanyInfo(companyID) {
    return this.$afs
      .collection('companies')
      .doc(companyID)
      .ref.get()
      .then((doc) => {
        return doc.data() as Company;
      });
  }
}
