import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as moment from 'moment';
import { Rental } from 'src/app/models/storage/rental.model';
import { LogService } from 'src/app/services/log.service';

@Component({
  selector: 'app-booking-next-status',
  templateUrl: './booking-next-status.component.html',
  styleUrls: ['./booking-next-status.component.scss']
})
export class BookingNextStatusComponent implements OnInit {

  constructor(
    private _afs: AngularFirestore,
    private _logService: LogService,
    private _cd: ChangeDetectorRef
  ) { }

  rental: Rental

  rentalUpdateData: Rental

  ngOnInit(): void {
    // $('#creatingAccountModal').on('hidden.bs.modal', () => {
    //   this.reset()

    // })
    $('#booking-next-status').on('hidden.bs.modal',  () => {
      // $("#myDiv") .prependTo("body");
      // this.rental = undefined
      // $('#booking-next-status').hide//.remove()
      // console.log("it's hidden")
    })
  }


  didDateChange = false
  dateChange($event){
    this.didDateChange = true
    this.statusDate = moment($event, "YYYY-MM-DD").toDate()// $event
  }

  statusSelected: string;
  statusDate : Date
  openModal(rental: Rental, statusToUpdate: string) {
    this.rental = rental
    this.statusSelected = statusToUpdate
    this.rentalUpdateData = undefined
    this.statusDate = new Date()

    $("#booking-next-status").modal("show")
    //this._cd.detectChanges()


    // setTimeout(()=>{
      // console.log("time", this.rental.id)
      // $("#booking-next-status").modal("hide")
    // }, 2000)
  }



  getFileURL(event) {
    let waiver = this.rental.waiver
    if (waiver) {
      waiver.push(event.url)
    } else {
      waiver = [event.url]
    }
    this._afs.collection("rentals").doc(this.rental.id).update({
      "waiver": waiver
    })
  }

  tableCheckboxUpdated(event) {
    this.rentalUpdateData = event
  }

  openWaiver(waiver) {
    window.open(waiver, "_blank")
  }

  /*postProductCheckboxUpdates() {
    if (this.rentalUpdateData) {
      let rentalStrs = []
      for (let product of this.rentalUpdateData.products) {
        let data = {}
        let str = []
        if (product.isCleaning) {
          data["isCleaning"] = true
          str.push("Marked as In Cleaning")
        }
        if (product.isDamaged){
          data["isDamaged"] = true
          str.push("Marked as Damaged")
        }
        if (product.isTuneUp){
          data["isTuneUp"] = true
          str.push("Marked as Tune-Up")
        }

        if (Object.keys(data).length > 0) {
          this._afs.collection("products").doc(product.productID).update(
            data
          )

          this._logService.addProductLog(product.productID, str)
        }
      }
    }
  }*/

  closeModal(){
    $("#booking-next-status").modal("hide")
  }

  updateStatus() {

    //this.postProductCheckboxUpdates()

    var rentalUpdate = this.rental
    var logDetails = ""
    var dateChange = ""
    if (this.statusSelected == "Reserved"){
      rentalUpdate.isCheckedOut = false
      rentalUpdate.isCheckedIn = false
      rentalUpdate.isComplete = false
      rentalUpdate.statusDate["isReserved"] = this.statusDate// new Date()
      delete rentalUpdate.statusDate["isCheckedIn"]
      delete rentalUpdate.statusDate["isComplete"]
      delete rentalUpdate.statusDate["isCheckedOut"]
      logDetails = "Reserved"
    } else if (this.statusSelected == "Check Out"){
      rentalUpdate.isCheckedOut = true
      rentalUpdate.isCheckedIn = false
      rentalUpdate.isComplete = false
      rentalUpdate.statusDate["isCheckedOut"] = this.statusDate//new Date()
      delete rentalUpdate.statusDate["isCheckedIn"]
      delete rentalUpdate.statusDate["isComplete"]
      logDetails = "Product Check Out"
    } else if (this.statusSelected == "Check In"){
      rentalUpdate.isCheckedOut = true
      rentalUpdate.isCheckedIn = true
      rentalUpdate.isComplete = false
      rentalUpdate.statusDate["isCheckedIn"] = this.statusDate//new Date()
      delete rentalUpdate.statusDate["isComplete"]
      logDetails = "Product Check In"
    } else if (this.statusSelected == "Complete"){
      rentalUpdate.isCheckedOut = true
      rentalUpdate.isCheckedIn = true
      rentalUpdate.isComplete = true
      rentalUpdate.statusDate["isComplete"] = this.statusDate//new Date()
      logDetails = "Booking Complete"
    }
    if (this.didDateChange){
      dateChange = "Status Date Set - " + moment(this.statusDate).format("M/D/YY")
    }
    // if (this.rental.isCheckedOut == false) {
    //   rentalUpdate.isCheckedOut = true
    //   rentalUpdate.statusDate["isCheckedOut"] = new Date()
    //   logDetails = "Product Check Out"
    // } else if (this.rental.isCheckedOut && !this.rental.isCheckedIn) {
    //   rentalUpdate.isCheckedIn = true
    //   rentalUpdate.statusDate["isCheckedIn"] = new Date()
    //   logDetails = "Product Check In"
    // } else if (this.rental.isCheckedIn && !this.rental.isComplete) {
    //   rentalUpdate.isComplete = true
    //   rentalUpdate.statusDate["isComplete"] = new Date()
    //   logDetails = "Booking Complete"
    // }

    this._afs.collection("rentals").doc(this.rental.id).update(
      rentalUpdate
    )

    //this._logService.addRentalLog(this.rental.id, [logDetails, dateChange])

    $("#booking-next-status").modal("hide")

  }




}
