import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReportComponent} from "./report.component";
import {NgChartsModule} from "ng2-charts";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import { DataTablesModule } from 'angular-datatables';



@NgModule({
  declarations: [
    ReportComponent
  ],
  imports: [
    CommonModule,
    NgChartsModule,
    MatButtonModule,
    DataTablesModule
  ]
})
export class ReportModule { }
