import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { TimeService } from 'src/app/services/time.service';
import { DateTime, DateTimeFormatOptions } from 'luxon';

// Models
import { FirestoreDateObject } from 'src/app/models/time.model';

@Pipe({
  name: 'luxonDisplayPipe'
})
@Injectable()
export class LuxonDisplay implements PipeTransform {

  constructor(private timeService: TimeService) {
  }

  transform(date: DateTime | Date | FirestoreDateObject, dateTimeType?: string, locationTimezone?: string | undefined, fallBackTimezone?: string | undefined) {
    if (this.timeService.isValidDateObject(date)) {
      let dateTimeFormat: DateTimeFormatOptions;
      let returnedDate: DateTime = this.timeService.convertTimestampToLuxon(date); // convert inputed date to Luxon

      // DateTime String Formatting
      switch (dateTimeType) {
        case 'DATETIME_SHORT':
          dateTimeFormat = DateTime.DATETIME_SHORT;
          break;
        case 'DATE_SHORT':
          dateTimeFormat = DateTime.DATE_SHORT;
          break;
          case 'DATE_MED':
            dateTimeFormat = DateTime.DATE_MED;
            break;
        case 'TIME_SIMPLE':
          dateTimeFormat = DateTime.TIME_SIMPLE;
          break;
        case 'DATETIME_MED':
          dateTimeFormat = DateTime.DATETIME_MED;
          break;
      }

      /* Timezones */
      // Leave locationTime & fallBackTimezone undefined if you do not want to apply a timezone change
      if (locationTimezone) {
        returnedDate = returnedDate.setZone(locationTimezone)
      }
      else if (fallBackTimezone) { // If the first timezone dynamically set to undefined, etc, then fall back to the second timezone (commonly set to default)
        returnedDate = returnedDate.setZone(fallBackTimezone)
      }

      /* Return Type */
      if(!dateTimeType || !dateTimeFormat){ // @Return: DateTime - If no dateTimeType is passed OR no dateTime format was provided, returning dateTime object instead of display
        return returnedDate;
      }else{ // @Return: String
        return returnedDate.toLocaleString(dateTimeFormat);
      }
    }
    else {
      console.warn(`invalid date passed to LuxonDisplay.transform`, {date, dateTimeType, fallBackTimezone})
      return null;
    }
  }
}
