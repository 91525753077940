import { Component, Input, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

/* Components */
import { NotifyMeModalComponent } from '../notify-me-modal/notify-me-modal.component';

/* Models */
import { DateTime } from 'luxon';
import { DateRangeContentCard } from 'src/app/v2/models/booking-flow.model';

@Component({
  selector: 'app-search-template-by-date',
  templateUrl: './search-template-by-date.component.html',
  styleUrls: ['./search-template-by-date.component.scss']
})

export class SearchTemplateByDateComponent implements OnDestroy {
  constructor() { }
  @Input() public startDate: DateTime;
  @Input() public endDate: DateTime;
  @Input() public badDatesProvided: boolean;
  @Input() public dateRangeContentCards: DateRangeContentCard[];
  @Output() public chosenProductGroupID: EventEmitter<string> = new EventEmitter();
  @ViewChild(NotifyMeModalComponent) public NotifyMeModalComponent: NotifyMeModalComponent;
  public isInvalidDate: boolean = false
  private subs: Subscription = new Subscription();

  protected emitChosenProductGroup(chosenProductGroup: string): void {
    this.chosenProductGroupID.emit(chosenProductGroup)
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
