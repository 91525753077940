import { Component, Input} from '@angular/core';

/* Models */
import { BookingContent } from 'src/app/v2/models/booking-flow.model';

@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss']
})

export class ContentCardComponent {
  @Input() public contentInfo: BookingContent;
}
