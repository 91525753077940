<div class="container">
  <form (ngSubmit)="handleSubmit($event)">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-12 col-sm-12 form__stripe">
        <div class="form-group">
          <div id="payment-request-button">
            <!-- A Stripe Element will be inserted here. -->
          </div>
          <div id="payment-element">
            <!--Stripe.js injects the Payment Element-->
          </div>
          <div class="row justify-content-center">
            <hr *ngIf="!isHidden" class="col-11">
          </div>
          <div class="row mb-3">
            <div class="col-6 col-md-5 offset-md-1 fs-6 text-left text-bold" *ngIf="!isHidden" i18n>
              Payment Total:
            </div>
            <div class="col-6 col-md-2 offset-md-3 fs-6 text-right text-bold" *ngIf="!isHidden">
              {{
              totalAmount | currency
              }}
            </div>
          </div>
          <div class="row justify-content-center">
            <hr *ngIf="!isHidden" class="col-11">
          </div>
          <div class="row justify-content-end">
            <div class="col-4">
              <button id="submit" type="submit" class="btn btn-primary float-right" *ngIf="!isHidden">
                <div class="spinner hidden" id="spinner"></div>
                <span id="button-text" i18n>Pay now</span>
              </button>
            </div>
          </div>
          <div id="payment-message" class="hidden">
            {{message}}
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
