<div class="container-fluid">
  <!-- Partner Component Header -->
  <div class="row justify-content-center mt-2">
    <div class="col-md-12 text-left">
      <h1 class="mt-2 mb-2" style="font-size: 32px">{{componentName}}</h1>
    </div>
  </div>

  <!-- Main Component View -->
  <ng-container *ngIf="timeslotOptionsLoaded">
    <mat-stepper>
      <!-- Search -->
      <mat-step label="Search" state="search">
        <!-- Date Range -->
        <app-date-range [selectedStartDate]="selectedStartDate" [selectedEndDate]="selectedEndDate" [cartObj]="cartObj"
          [showingViaBookingFlow]="true" [locationMap]="locationsMap" [locations]="locationsArr"
          [selectedLocationID]="selectedLocationID" [timeslotsGroupedByType]="timeslotsGroupedByType"
          [selectedTimeslot]="selectedTimeslot" [dateRangeConfig]="dateRangeConfig"
          [isAvailabilityOverride]="isAvailabilityOverride" [availabilityOverrideConfig]="availabilityOverrideConfig"
          (dateRangeInputChanged)="dateRangeInputChanged($event)"
          (toggleAvailabilityOverride)="toggleAvailabilityOverride($event)">
        </app-date-range>

        <!-- Results -->
        <ng-container *ngIf="resultsLoaded && dateRangeFormValid">
          <section style="margin-top: 2em;">

            <div class="d-flex justify-content-end">
              <div *ngIf="!paginationResultLoaded && paginationResultLoadedFirstTime" class="loading-overlay">
                <div class="spinner-container">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>

              <!-- Paginator - Top -->
              <ng-container *ngIf="paginatedProductGroupUIKeys?.length > 0">
                <mat-paginator #paginatorTop [length]="totalProductGroups" [pageSize]="productGroupsPerPage"
                  (page)="onPagination($event)" aria-label="Select page">
                </mat-paginator>
              </ng-container>
            </div>

            <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" id="productAccordion" (panelChange)="onPanelChange($event)">
              <ngb-panel 
                *ngFor="let productGroupKey of paginatedProductGroupUIKeys; let i = index" 
                [id]="'panel-' + productGroupKey"
                [disabled]="productGroupUI[productGroupKey]?.totalAvailableQty === 0">
                
                <ng-template ngbPanelTitle>
                  <div
                    class="d-flex justify-content-between align-items-center"
                    [class.disabled-panel]="productGroupUI[productGroupKey]?.totalAvailableQty === 0"
                    [style.cursor]="productGroupUI[productGroupKey]?.totalAvailableQty === 0 ? 'not-allowed' : 'pointer'"
                    style="width: 100%;"
                  >
                    <!-- Product name with "Unavailable" indicator if quantity is 0 -->
                    <span class="flex-grow-1 text-start" style="font-weight: 500;">
                      {{ productGroupUI[productGroupKey]?.productGroupName }}
                      <span *ngIf="productGroupUI[productGroupKey]?.totalAvailableQty === 0" class="text-muted ms-2">(Unavailable)</span>
                    </span>
                    
                    <!-- Badge with conditional styling based on quantity -->
                    <span 
                      class="badge ms-4 mr-1" 
                      [ngClass]="productGroupUI[productGroupKey]?.totalAvailableQty > 0 ? 'bg-primary' : 'bg-secondary'">
                      {{ productGroupUI[productGroupKey]?.totalAvailableQty }}
                    </span>
                  </div>
                </ng-template>
            
                <ng-template ngbPanelContent>
                  <!-- Accordion panel content as in your original template -->
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Size</th>
                        <th>Desired Quantity</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let size of productGroupUI[productGroupKey]?.sizes; let j = index">
                        <td>{{ size.size }}</td>
                        <td>
                          <input
                            type="number"
                            class="form-control"
                            [(ngModel)]="productGroupUI[productGroupKey]?.sizes[j].inputValue"
                            [min]="0"
                            [max]="size.totalAvailQty"
                            (input)="validateSizeQty(productGroupKey, j, $event)"
                          >
                        </td>
                        <td>
                          <span class="text-danger">/{{ size.totalAvailQty }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
            
                  <!-- Widget Area -->
                  <ng-container *ngIf="selectedProductGroupID === productGroupKey">
                    <app-inventory-page-view
                      [groupInventoryPage]="productGroupUI[productGroupKey].productGroupInventoryPage"
                      [customHeader]="'Addons (Applies to each item)'"
                      (productInputChanged)="pricingUpdate()"
                      (widgetInputChanged)="pricingUpdate()"
                    ></app-inventory-page-view>
                  </ng-container>
            
                  <!-- Summary and Add to Cart Section -->
                  <div class="mt-3">
                    <div class="text-right">
                      <div class="row" style="padding-left: 20px;">
                        <div class="col-md-6 offset-md-6 mb-2" style="padding-right: 20px">
                          <div style="padding-top: 15px; font-size: 14px">
                            Addons Total Price:
                            <span style="margin-left: 5px;">
                              {{ selectedAddonPrice | currency }}
                            </span>
                          </div>
            
                          <div style="padding-top: 5px; font-size: 14px">
                            Product Price:
                            <span style="margin-left: 5px;">
                              {{ selectedProductPrice * selectedProductGroupQtySummed | currency }}
                            </span>
                          </div>
            
                          <hr style="margin: 4px">
            
                          <div style="padding-top: 2px; font-size: 16px">
                            Total Price:
                            <span style="margin-left: 5px;">
                              {{ (selectedProductPrice * selectedProductGroupQtySummed) + selectedAddonPrice | currency }}
                            </span>
                          </div>
                        </div>
                      </div>
            
                      <button class="btn btn-primary" (click)="addProductToCart(productGroupKey)">
                        Add to Cart
                      </button>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            

            <!-- Paginator - Bottom -->
            <div class="d-flex justify-content-end">
              <div *ngIf="!paginationResultLoaded && paginationResultLoadedFirstTime" class="loading-overlay">
                <div class="spinner-container">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="paginatedProductGroupUIKeys?.length > 0">
                <mat-paginator #paginatorBottom [length]="totalProductGroups" [pageSize]="productGroupsPerPage"
                  (page)="onPagination($event)" aria-label="Select page">
                </mat-paginator>
              </ng-container>
            </div>

          </section>

        </ng-container>

        <!-- Results Loader -->
        <ng-container *ngIf="!resultsLoaded">
          <div style="position: relative; height: 600px;">
            <div style="position: absolute; transform: translate(-50%, -50%); left: 50%; bottom:70%; z-index: 3;">
              <app-fleetmaid-spinner></app-fleetmaid-spinner>
              <!-- <div class="spinner-border" role="status" style="width: 3em !important; height: 3em !important">
                <span class="visually-hidden">Loading...</span>
              </div> -->
            </div>
          </div>
        </ng-container>

      </mat-step>

      <!-- Cart -->
      <mat-step label="Cart" state="cart">
        <p>Cart Component Here</p>
      </mat-step>

      <!-- Icon overrides. -->
      <ng-template matStepperIcon="number" let-index="index">
        <ng-container [ngSwitch]="index">
          <!-- Icon for Step 1 -->
          <ng-template [ngSwitchCase]="0">
            <mat-icon>search</mat-icon>
          </ng-template>

          <!-- Icon for Step 2 -->
          <ng-template [ngSwitchCase]="1">
            <mat-icon>shopping_cart</mat-icon>
          </ng-template>
        </ng-container>
      </ng-template>

      <!-- Icon overrides. -->
      <ng-template matStepperIcon="edit" let-index="index">
        <ng-container [ngSwitch]="index">
          <!-- Icon for Step 1 -->
          <ng-template [ngSwitchCase]="0">
            <mat-icon>search</mat-icon>
          </ng-template>

          <!-- Icon for Step 2 -->
          <ng-template [ngSwitchCase]="1">
            <mat-icon>shopping_cart</mat-icon>
          </ng-template>
        </ng-container>
      </ng-template>
    </mat-stepper>
  </ng-container>

  <!-- Main View - Error -->
  <ng-container *ngIf="errorHandlingObj">
    <app-error-message [errorHandlingObject]="errorHandlingObj">
    </app-error-message>
  </ng-container>


  <!-- Timeslot Loader -->
  <ng-container *ngIf="!timeslotOptionsLoaded && !errorHandlingObj">
    <div style="position: relative; height: 600px;">
      <div style="position: absolute; transform: translate(-50%, -50%); left: 50%; bottom:40%; z-index: 3;">
        <div class="spinner-border" role="status" style="width: 3em !important; height: 3em !important">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </ng-container>
</div>