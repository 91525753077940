/* Models */
import { AppError, ErrorLevel, ErrorCodes } from "../models/errors.model";

export const GLOBAL_ERRORS: { [key: string]: AppError } = {
    [ErrorCodes.NETWORK_FAILURE]: {
        code: ErrorCodes.NETWORK_FAILURE,
        message: 'Failed to connect to the server. Please check your internet connection.',
        level: ErrorLevel.CRITICAL,
        override: false
    },
    [ErrorCodes.UNAUTHORIZED_ACCESS]: {
        code: ErrorCodes.UNAUTHORIZED_ACCESS,
        message: 'You do not have permission to perform this action.',
        level: ErrorLevel.ERROR,
        override: false
    },
    [ErrorCodes.SERVER_ERROR]: {
        code: ErrorCodes.SERVER_ERROR,
        message: 'An internal server error occurred. Please try again later.',
        level: ErrorLevel.CRITICAL,
        override: false
    },
    [ErrorCodes.SUCCESS]: {
        code: ErrorCodes.SUCCESS,
        message: 'Operation completed successfully.',
        level: ErrorLevel.INFO,
        override: false
    },
};