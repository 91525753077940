import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Log } from 'src/app/models/storage/log.model';
import { LogService } from 'src/app/services/log.service';

@Component({
  selector: 'app-log-list',
  templateUrl: './log-list.component.html',
  styleUrls: ['./log-list.component.scss']
})
export class LogListComponent implements OnInit {

  constructor(
    private _afs: AngularFirestore,
    private _logService: LogService
  ) { }

  ngOnInit(): void {

  }

  logs: Log[] = []



  hasFinishedLoadingProducts = false
  loadingProducts = false
  openLogList(productID){
    this.loadingProducts = false
    this.hasFinishedLoadingProducts = false
    this.logs = []
    this.getLogs(productID, true)
  }

  getLogs(productID, reset = false){
    if (this.loadingProducts || this.hasFinishedLoadingProducts) return
    this.loadingProducts = true

    this._logService.getLogsWithCollection("products", productID, reset).then(logs =>{
      this.loadingProducts = false
      if (logs.length < 20){
        this.hasFinishedLoadingProducts = true
      }
      this.logs = this.logs.concat(logs)

    })
  }


}
