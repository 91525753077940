import { Component, Input } from '@angular/core';

/* Model */
import { ErrorHandlingObject } from 'src/app/v2/models/errors.model';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.css']
})
export class ErrorMessageComponent {

  @Input() public errorHandlingObject: ErrorHandlingObject;
}
