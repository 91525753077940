import { Pipe, PipeTransform } from '@angular/core';
import { Rental } from 'src/app/models/storage/rental.model';

@Pipe({
  name: 'filterBookings',
})
export class FilterBookingsPipe implements PipeTransform {
  transform(rentals: Rental[], page: number = 0): Rental[] {
    return rentals.slice(page, page + 100);
  }
}
