import { Component, Input, OnInit, Output, EventEmitter, SimpleChange, SimpleChanges, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// Interfaces
import { BookingTemplate, defaultValues } from 'src/app/v2/models/booking-flow.model';

// Services
import { BookingService } from 'src/app/services/booking.service';

// Libraries
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bookingFlowConfigModal',
  templateUrl: './bookingFlowConfigModal.component.html',
  styleUrls: ['./bookingFlowConfigModal.component.css']
})
export class BookingFlowConfigModalComponent implements OnInit, OnChanges {

  @Input() public template: BookingTemplate;
  @Output() public bookingFlowConfigFormSubmission = new EventEmitter<string>();
  public configForm: FormGroup;

  constructor(private bookingService: BookingService) { }

  public ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.configForm = new FormGroup({
      viewTypePreference: new FormControl("", [Validators.required]),
    })
  }

  public ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.template) { // Set the form with the values from the booking template upon being set from parent component
      this.setFormWithValues(simpleChanges.template.currentValue);
    }
  }


  public setFormWithValues(bookingTemplate: BookingTemplate): void {
    if (!this.configForm?.controls) {
      return
    }

    // If the booking template doesn't have a template, set catalog as the default view type viewTypePreference
    if (bookingTemplate?.viewTypePreference) {
      this.configForm.controls['viewTypePreference'].setValue(bookingTemplate.viewTypePreference); // set to db value
    } else {
      this.configForm.controls['viewTypePreference'].setValue('catalog'); // set default
    }
  }

  public onSubmit(): void {
    let updateObj = {}; // holds all the properties that need to be updated

    for (let control in this.configForm.controls) { // Loop through form values and update the booking config fields
      if (!this.template[control]) { // Control not found in original booking template
        if (control in defaultValues) { // Check if control exists in booking template interface, if not, set default value
          updateObj[control] = defaultValues[control];
        }
        else {
          // Control not found in original bookingTemplate, please update interface and ensure keyname matches form control name
          throw Error('Control not found in interface');
        }
      }

      if (this.configForm.get(control)?.value !== this.template[control]) { // If the form value is different from the original bookingTemplate's value, update the object
        updateObj[control] = this.configForm.get(control)?.value;
      }
    }

    this.bookingService.updateTemplateFields(this.template.id, updateObj).then(() => { // Update the booking config in the database
      this.deliverToast('Booking Configurations successfully updated!', 'success');
      this.bookingFlowConfigFormSubmission.emit('success')
    })
  }

  private deliverToast(title: string, icon: any): void {
    Swal.fire({
      toast: true,
      position: 'bottom-right',
      title: title,
      icon: icon,
      showConfirmButton: false,
      timer: 1500
    })
  }
}
