import { Component, OnInit } from '@angular/core';
import {
  Alignment,
  Bold,
  ClassicEditor,
  EditorConfig,
  Essentials,
  FontColor,
  FontSize,
  Heading,
  Italic,
  Undo,
 } from 'ckeditor5';
import { RentalService } from 'src/app/services/rental.service';
import { ProductsService } from 'src/app/services/products.service';
import { CurrentUserService } from 'src/app/services/current-user.service';
import Swal from 'sweetalert2';
import { Company } from 'src/app/models/storage/company.model';
import { LogService } from 'src/app/services/log.service';
import { ReportService } from 'src/app/services/report.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ColorTheme } from 'src/app/services/colorTheme.service';

@Component({
  selector: 'app-waiver-editor',
  templateUrl: './waiver-editor.component.html',
  styleUrls: ['./waiver-editor.component.scss'],
})
export class WaiverEditorComponent implements OnInit {
  public safeSrc: SafeResourceUrl;
  constructor(
    private _rentalService: RentalService,
    private _currentUser: CurrentUserService,
    private _logService: LogService,
    private _reportService: ReportService,
    private sanitizer: DomSanitizer,
    private _productsService: ProductsService,
    public _theme_color: ColorTheme
  ) {}
  public editor: any = ClassicEditor
  public copyWaiver = '';
  public copytitle = '';
  public company: Company = {};
  public editwaiver: boolean = false;
  public multipleWaivers: boolean = false;
  public multipleSignature: boolean = false;
  public multipleSignPerWaiver: boolean = false;
  public companymultiplewaivers: boolean = false;
  public error: boolean = false;
  public waiversArray: any[];
  public waiverTitle: string = '';
  public title: boolean = false;
  public waiver: any;
  public id: string;
  public showEditor: boolean = false;
  public pdfEditor: any;
  public smartWaiver: any;
  public companySmartWaiver: boolean = false;
  public hidebuttons: boolean = false;
  public model = {
    editorData: '<p>Write Someting...</p>',
  };
  public colorTheme: string;
  public colorText: string;
  public savebtn: boolean = true;
  public cancelbtn: boolean = true;
  public editorConfig: EditorConfig =
    {toolbar: {
      items: [
        'heading', '|', 'bold', 'fontSize', 'fontColor',
        'italic', '|', 'undo', 'redo', 'alignment'
      ]},
      plugins: [
        Alignment,
        Essentials,
        Bold,
        FontColor,
        FontSize,
        Heading,
        Italic,
        Undo
      ]
    }

  Iduser: string;
  ngOnInit(): void {
    this.getWaiverElement();
    this.fnchangecolor();
  }
  fnchangecolor() {
    this.Iduser = this._currentUser.currentUser.id;
    this._theme_color
      .getcolorPeruser(this.Iduser)
      .then((res) => {
        let objct = {
          theme: res.color.value,
          text: res.color.fontcolor,
        };

        this.colorTheme = objct.theme;
        this.colorText = objct.text;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  SafeUrl(url, type) {
    if (type == 'pdf') {
      this.pdfEditor = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      this.smartWaiver = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }
  getWaiverElement() {
    this._rentalService
      .getCompanyWaiver(this._currentUser.currentUser.companyId)
      .then(async (company) => {
        this.company = company;
        this.multipleWaivers = company.multipleWaivers;
        this.companymultiplewaivers = company.multipleWaivers;
        this.multipleSignature = company.multipleSignature;
        if (company.isSmartWaiver) {
          this.hidebuttons = true;
        }
        this.companySmartWaiver = company.isSmartWaiver;
        await this.SafeUrl(this.company.pdfContent, 'pdf');
        await this.SafeUrl(this.company.smartWaiverLink, 'smart');
        if (company.multipleWaivers) {
          this.waiversArray = [];
          this.getWaiversByCompany(company);
          this.model.editorData = '<p>Write Someting...</p>';
        } else {
          this.model.editorData = company.companyWaiver;
          this.copyWaiver = company.companyWaiver;
        }
      });
  }
  getWaiversByCompany(company) {
    this._rentalService
      .getWaiversByCompany(this._currentUser.currentUser.companyId)
      .then((waivers) => {
        this.waiversArray = [];
        if (waivers.length > 0) {
          waivers.forEach((element) => {
            this.waiversArray.push(element);
          });
        }
      });
  }
  onChangeWaiver(event) {
    if (
      this.model.editorData != '' ||
      this.copyWaiver != this.model.editorData ||
      this.copytitle != this.waiverTitle
    ) {
      this.savebtn = false;
      this.cancelbtn = false;
    } else {
      this.savebtn = true;
      this.cancelbtn = true;
    }
  }
  onChangeTitle(event) {
    this.waiverTitle != '' ? (this.error = false) : (this.error = true);
    if (this.copytitle != this.waiverTitle) {
      this.savebtn = false;
      this.cancelbtn = false;
    } else {
      this.savebtn = true;
      this.cancelbtn = true;
    }
  }
  restoreWaiver() {
    this.model.editorData = this.copyWaiver;
    this.savebtn = true;
    this.cancelbtn = true;
    this.showEditor = false;
  }
  saveWaiver() {
    this.company.companyWaiver = this.model.editorData;
    this._rentalService.updateCompany(this.company);
    this._logService.addCompanyLog('First Waiver Updated');
    Swal.fire('Waiver Updated', 'The Waiver has been updated', 'success');
    this.savebtn = true;
    this.cancelbtn = true;
  }
  changeWaivers() {
    if (this.company.multipleWaivers) {
      Swal.fire({
        title: 'Turn Off Multiple Waivers ',
        html: 'You are about to turn off multiple waivers. Once off, you can edit a single waiver as a default which does not require assignment to products.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#7066e0',
        cancelButtonText: 'Accept',
        showConfirmButton: true,
        confirmButtonColor: '#6e7881',
        confirmButtonText: 'Cancel',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isDismissed) {
          this.company.multipleWaivers = false;
          this._logService.addCompanyLog('Set Waivers to only one');
          this._rentalService.updateAllCompany(this.company);
          Swal.fire({
            title: 'Multiple Waiver Cancel',
            html: 'You can only manage one waiver now',
            icon: 'success',
          });
          this.getWaiverElement();
        } else {
          this.multipleWaivers = true;
        }
      });
    } else {
      if (
        this.company.companyWaiver != undefined &&
        this.company.companyWaiver != ''
      ) {
        if (this.multipleWaivers) {
          Swal.fire({
            title: 'Turn on Multiple Waivers',
            html: 'If you turn on multiple waivers, you will have to individually assign the waivers to each product. Products w/o assigned waivers will not provide waivers to sign.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7066e0',
            cancelButtonText: 'Accept',
            showConfirmButton: true,
            confirmButtonColor: '#6e7881',
            confirmButtonText: 'Cancel',
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isDismissed) {
              this.company.multipleWaivers = true;
              this._rentalService.updateAllCompany(this.company);
              this._logService.addCompanyLog('Set Waivers to multiple');
              Swal.fire({
                title: 'Multiple Waiver',
                html: 'Multiple waiver has been set',
                icon: 'success',
              });
              this.getWaiverElement();
            } else {
              this.multipleWaivers = false;
            }
          });
        } else {
          Swal.fire({
            title: 'Cancel Multiple Waivers',
            html: 'You are about to change the option of waivers, ff you had multiple waivers before, none will be shown except for the main one. Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7066e0',
            cancelButtonText: 'Accept',
            showConfirmButton: true,
            confirmButtonColor: '#6e7881',
            confirmButtonText: 'Cancel',
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isDismissed) {
              this.company.multipleWaivers = false;
              this._logService.addCompanyLog('Set Waivers to only one');
              this._rentalService.updateAllCompany(this.company);
              Swal.fire({
                title: 'Multiple Waiver Cancel',
                html: 'You can only manage one waiver now',
                icon: 'success',
              });
              this.getWaiverElement();
            } else {
              this.multipleWaivers = true;
            }
          });
        }
      } else {
        Swal.fire({
          title: 'Multiple Waivers',
          html: 'If you use select to use multiple waivers, you will have to individually assign the waivers to each product.  Products w/o assigned waivers will not require a waiver signature.',
          icon: 'question',
          showCancelButton: true,
          cancelButtonColor: '#7066e0',
          cancelButtonText: 'Accept',
          showConfirmButton: true,
          confirmButtonColor: '#6e7881',
          confirmButtonText: 'Cancel',
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isDismissed) {
            this.company.multipleWaivers = true;
            this._rentalService.updateAllCompany(this.company);
            this._logService.addCompanyLog('Set Waivers to multiple');
            Swal.fire({
              title: 'Multiple Waiver',
              html: 'Multiple waiver has been set',
              icon: 'success',
            });
            this.getWaiverElement();
          } else {
            this.multipleWaivers = false;
          }
        });
      }
    }
  }
  changeMultiSignature() {
    if (this.company.multipleSignature) {
      Swal.fire({
        title: 'Turn Off Multiple Signature',
        html: 'By turning off this option, only a single signature will be required for this waiver.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#7066e0',
        cancelButtonText: 'Accept',
        showConfirmButton: true,
        confirmButtonColor: '#6e7881',
        confirmButtonText: 'Cancel',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isDismissed) {
          this.company.multipleSignature = false;
          this._logService.addCompanyLog(
            'Set Waiver to only one Signature on the main waiver'
          );
          this._rentalService.updateAllCompany(this.company);
          Swal.fire({
            title: 'Multiple Signature Cancel',
            html: 'Now only one signature per Waiver will be received',
            icon: 'success',
          });
          this.getWaiverElement();
        } else {
          this.multipleSignature = true;
        }
      });
    } else {
      Swal.fire({
        title: 'Multiple Signatures',
        html: 'If you activate this option, a signature field will be displayed for each product containing this waiver on the same order.',
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#7066e0',
        cancelButtonText: 'Accept',
        showConfirmButton: true,
        confirmButtonColor: '#6e7881',
        confirmButtonText: 'Cancel',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isDismissed) {
          this.company.multipleSignature = true;
          this._rentalService.updateAllCompany(this.company);
          this._logService.addCompanyLog(
            'Set Signature for each product on the main waiver'
          );
          Swal.fire({
            title: 'Multiple Signature',
            html: 'Multiple signature has been set',
            icon: 'success',
          });
          this.getWaiverElement();
        } else {
          this.multipleSignature = false;
        }
      });
    }
  }
  changeMultiSignPerWaiver() {
    if (this.waiver.multipleSignature) {
      Swal.fire({
        title: 'Turn Off Multiple Signature',
        html: 'By turning off this option, only a single signature will be required for this waiver.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#7066e0',
        cancelButtonText: 'Accept',
        showConfirmButton: true,
        confirmButtonColor: '#6e7881',
        confirmButtonText: 'Cancel',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isDismissed) {
          this.waiver.multipleSignature = false;
          this._logService.addCompanyLog(
            'Set Waiver ' + this.waiver.waiverTitle + ' to only one Signature'
          );
          this._rentalService.updateCompanyWaivers(
            this.company,
            this.waiver,
            this.waiver.id
          );
          Swal.fire({
            title: 'Multiple Signature Cancel',
            html: 'Now only one signature per Waiver will be received',
            icon: 'success',
          });
          this.getWaiverElement();
        } else {
          this.multipleSignPerWaiver = true;
        }
      });
    } else {
      Swal.fire({
        title: 'Multiple Signatures',
        html: 'If you activate this option, a signature field will be displayed for each product containing this waiver on the same order.',
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#7066e0',
        cancelButtonText: 'Accept',
        showConfirmButton: true,
        confirmButtonColor: '#6e7881',
        confirmButtonText: 'Cancel',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isDismissed) {
          this.waiver.multipleSignature = true;
          this._logService.addCompanyLog(
            'Set Signature for each product on the ' +
              this.waiver.waiverTitle +
              ' waiver'
          );
          this._rentalService.updateCompanyWaivers(
            this.company,
            this.waiver,
            this.waiver.id
          );
          Swal.fire({
            title: 'Multiple Signature',
            html: 'Multiple signature has been set',
            icon: 'success',
          });
          this.getWaiverElement();
        } else {
          this.multipleSignPerWaiver = false;
        }
      });
    }
  }
  async newWaiver() {
    this.createWaiver();
    this.waiverTitle = '';
    this.model.editorData = '';
    this.editwaiver = false;
  }
  editWaiver(waiver) {
    this.showEditor = true;
    this.savebtn = true;
    this.cancelbtn = true;
    this.editwaiver = true;
    this.waiverTitle = waiver.title;
    this.model.editorData = waiver.content;
    this.copyWaiver = waiver.content;
    this.copytitle = waiver.title;
    this.multipleSignPerWaiver = waiver.multipleSignature;
    this.waiver = waiver;
    this.id = waiver.id;
    waiver.waiverPDF
      ? this.SafeUrl(waiver.pdfContent, 'pdf')
      : (this.pdfEditor = '');
    waiver.main ? (this.title = true) : (this.title = false);
  }
  createWaiver() {
    Swal.fire({
      title: 'Create Waiver',
      html: 'Are you sure you want to create a waiver?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#7066e0',
      cancelButtonText: 'Confirm',
      showConfirmButton: true,
      confirmButtonColor: '#6e7881',
      confirmButtonText: 'Cancel',
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isDismissed) {
        let waiverdata = {
          title: 'New Waiver Title',
          content: '',
          createdDate: new Date(),
        };
        this._rentalService
          .createWaiver(this.company, waiverdata)
          .then((element) => {
            this._rentalService
              .getWaiverById(this.company.id, element)
              .then((waiver) => {
                this.editWaiver(waiver);
                this.getWaiverElement();
              });
          });
        //this.resetWaiverForm();
        this._logService.addCompanyLog(
          'Waiver ' + this.waiverTitle + ' created'
        );
        Swal.fire({
          title: 'Waiver created',
          html: 'The waiver has been created',
          icon: 'success',
        });
      } else {
        this.showEditor = false;
      }
    });
  }
  deleteWaiver(waiver) {
    this._rentalService
      .searchAvailabilityByWaiver(this.company, waiver)
      .then((product) => {
        if (product.length <= 0) {
          Swal.fire({
            title: 'Delete Waiver',
            html: 'Are you sure you want to delete this waiver?',
            icon: 'question',
            showCancelButton: true,
            cancelButtonColor: '#7066e0',
            cancelButtonText: 'Confirm',
            showConfirmButton: true,
            confirmButtonColor: '#6e7881',
            confirmButtonText: 'Cancel',
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isDismissed) {
              this._rentalService.deleteWaiver(this.company, waiver);
              this._logService.addCompanyLog(
                'Waiver ' + waiver.title + ' deleted'
              );
              Swal.fire({
                title: 'Waiver deleted',
                html: 'The waiver has been deleted permanently',
                icon: 'success',
              });
              this.resetWaiverForm();
            }
          });
        } else {
          Swal.fire({
            title: 'Waiver in use',
            html:
              'This Waiver is associated with ' +
              product.length +
              ' product(s), change this before removing this Waiver permanently',
            icon: 'error',
          });
        }
      });
  }
  resetWaiverForm(edit?) {
    if (edit) {
      this.showEditor = true;
    } else {
      this.showEditor = false;
      this.editwaiver = false;
      this.waiverTitle = '';
      this.model.editorData = '<p>Write Someting...</p>';
      this.savebtn = true;
      this.cancelbtn = true;
      this.error = false;
      this.title = false;
    }
    this.getWaiversByCompany(this.company);
  }
  updateWaiver() {
    Swal.fire({
      title: 'Update Waiver',
      html: 'Are you sure you want to edit this waiver?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#7066e0',
      cancelButtonText: 'Confirm',
      showConfirmButton: true,
      confirmButtonColor: '#6e7881',
      confirmButtonText: 'Cancel',
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isDismissed) {
        if (this.waiver.main) {
          this.company.companyWaiver = this.model.editorData;
          this._logService.addCompanyLog('First Waiver Updated');
          this._rentalService.updateCompany(this.company);
          Swal.fire({
            title: 'Waiver Updated',
            html: 'The First Waiver has been updated',
            icon: 'success',
          });
        } else {
          let waiverdata = {
            title: this.waiverTitle,
            content: this.model.editorData,
          };
          this._logService.addCompanyLog(
            'Waiver ' + waiverdata.title + ' updated'
          );
          this._rentalService.updateCompanyWaivers(
            this.company,
            waiverdata,
            this.id
          );
          this.copyWaiver = this.waiverTitle;
          this.copytitle = this.model.editorData;
          this.waiversArray = [];
          this.getWaiversByCompany(this.company);
          Swal.fire({
            title: 'Waiver Updated',
            html: this.waiverTitle + ' has been updated',
            icon: 'success',
          });
          this.resetWaiverForm('edit');
        }
        this.waiversArray = [];
        this.getWaiversByCompany(this.company);
      }
    });
  }
  uploadPDFWaiver() {
    Swal.fire({
      title: 'Change Waiver',
      html: 'If you select this option, you will have to upload a PDF to display, are you sure?',
      icon: 'question',
      showCancelButton: true,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        this.company.waiverPDF = true;
        if (this.company.isSmartWaiver) {
          this.company.isSmartWaiver = false;
        }
        this.companySmartWaiver = false;
        this._rentalService.updateAllCompany(this.company);
        this._logService.addCompanyLog('Waiver set to PDF');
        Swal.fire({
          title: 'Waiver changed to PDF format',
          icon: 'success',
        });
      }
    });
  }
  addPDFWaiver() {
    Swal.fire({
      title: 'Change Waiver',
      html: 'If you select this option, you will have to upload a PDF to display in this waiver, are you sure?',
      icon: 'question',
      showCancelButton: true,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let waiverdata = {
          id: this.id,
          title: this.waiverTitle,
          content: this.model.editorData,
          waiverPDF: true,
        };
        var waiver = {
          ...waiverdata,
          ...this.waiver,
          waiverPDF: true,
        };
        this.waiver = waiver;
        this.SafeUrl(this.waiver.pdfContent, 'pdf');
        this._logService.addCompanyLog(
          'Waiver ' + waiverdata.title + ' updated'
        );
        this._rentalService.updateCompanyWaivers(
          this.company,
          waiverdata,
          this.id
        );
        this.waiversArray = [];
        this.getWaiversByCompany(this.company);
        Swal.fire({
          title: 'Waiver changed to PDF format',
          icon: 'success',
        });
      }
    });
  }
  uploadPDF(id: string, event: any) {
    Swal.fire({
      title: 'Loading...',
      html: 'Saving waiver',
      showConfirmButton: false,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    let pdfFile = event.target.files;
    for (let i = 0; i < pdfFile.length; i++) {
      let reader = new FileReader();
      reader.readAsDataURL(pdfFile[i]);
      reader.onloadend = () => {
        this._reportService
          .uploadPDF(this.company, reader.result, this.company.id + '_waiver')
          .then((pdfFile) => {
            this.company.pdfContent = pdfFile;
            this._rentalService.updateAllCompany(this.company);
            this._logService.addCompanyLog('Waiver Document Uploaded');
            Swal.close();
            Swal.fire({
              title: 'Waiver Document Uploaded',
              icon: 'success',
            });
            this.ngOnInit();
          });
      };
    }
  }
  uploadPDFWaiverSingle(event: any) {
    Swal.fire({
      title: 'Loading...',
      html: 'Saving waiver',
      showConfirmButton: false,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading();
    let pdfFile = event.target.files;
    for (let i = 0; i < pdfFile.length; i++) {
      let reader = new FileReader();
      reader.readAsDataURL(pdfFile[i]);
      reader.onloadend = () => {
        this._reportService
          .uploadPDFWaiver(
            this.company,
            this.id,
            reader.result,
            this.id + '_waiver'
          )
          .then((pdfFile) => {
            let waiverdata = {
              title: this.waiverTitle,
              content: this.model.editorData,
              waiverPDF: true,
              pdfContent: pdfFile,
            };
            this.waiver = waiverdata;
            this.SafeUrl(pdfFile, 'pdf');
            this._logService.addCompanyLog(
              'Waiver ' + waiverdata.title + ' updated'
            );
            this._rentalService.updateCompanyWaivers(
              this.company,
              waiverdata,
              this.id
            );
            Swal.close();
            Swal.fire({
              title: 'Waiver Document Uploaded',
              icon: 'success',
            });
            this.ngOnInit();
          });
      };
    }
  }
  changeWaiver() {
    Swal.fire({
      title: 'Change Waiver',
      html: 'If you select this option, you will have to write a waiver to display, are you sure?',
      icon: 'question',
      showCancelButton: true,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        this.company.waiverPDF = false;

        if (this.company.isSmartWaiver) {
          this.company.isSmartWaiver = false;
        }
        this.companySmartWaiver = false;
        this._rentalService.updateAllCompany(this.company);
        this._logService.addCompanyLog('Waiver set to Write');
        Swal.fire({
          title: 'Wavier changed to Digital format',
          icon: 'success',
        });
      }
    });
  }
  writePDFWaiver() {
    Swal.fire({
      title: 'Change Waiver',
      html: 'If you select this option, you will have to write a waiver to display, are you sure?',
      icon: 'question',
      showCancelButton: true,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let waiverdata = {
          id: this.id,
          title: this.waiverTitle,
          content: this.model.editorData,
          waiverPDF: false,
        };
        var waiver = {
          ...waiverdata,
          ...this.waiver,
          waiverPDF: false,
        };
        this.waiver = waiver;
        this.pdfEditor = '';
        this._logService.addCompanyLog(
          'Waiver ' + waiverdata.title + ' updated'
        );
        this._rentalService.updateCompanyWaivers(
          this.company,
          waiverdata,
          this.id
        );
        this.waiversArray = [];
        this.getWaiversByCompany(this.company);
        Swal.fire({
          title: 'Wavier changed to Digital format',
          icon: 'success',
        });
      }
    });
  }
  smartWaiverChange(type?) {
    var html =
      'If you want to use SmartWaiver, please write the public link of the waiver';
    var url = '';
    if (type) {
      html = 'You can edit the link of the SmartWaiver';
    }
    this.company.smartWaiverLink
      ? (url = this.company.smartWaiverLink)
      : (url = '');
    Swal.fire({
      title: 'SmartWaiver Integration',
      html: html,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      inputValue: url,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      allowEscapeKey: false,
      allowOutsideClick: false,
      icon: 'info',
      inputValidator: (value) => {
        if (!value) {
          return 'You need to write a Link';
        }
        return null;
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.company.isSmartWaiver = true;
        this.company.smartWaiverLink = result.value;
        await this._rentalService.updateAllCompany(this.company);
        this.companySmartWaiver = this.company.isSmartWaiver;
        this.SafeUrl(this.company.smartWaiverLink, 'smart');
        this._logService.addCompanyLog(
          'Smart Waiver Updated, new link' + result.value
        );
        Swal.fire({
          title: 'Waiver Updated',
          html: 'The Waiver has been updated',
          icon: 'success',
        });
      }
    });
  }
  public products: any = [];
  public totalcountproducts: number = 0;
  getProductsWaiver(selectedWaiver) {
    //Function to get all products for the company
    this.searchProductInput = ''; //Set as empty the search input variable
    this.products = []; //Set as empty the products array
    this._productsService.getProducts(this.company).subscribe((result) => {
      //Getting the products for the company
      this.totalcountproducts = 0; //Reset total products counter
      result.forEach((product, index) => {
        //For each product
        const find = product.waivers?.find(
          (waiver) => waiver === selectedWaiver.id
        ); //Search if that product have the waiver selected
        if (find) {
          //If there is a waiver
          result[index]['isSelected'] = true; //That means that the product have that waiver
          this.totalcountproducts += 1; //Increase 1 to the count badge
        }
      });
      this.products = Object.assign(result); //Save the products on the products array
    });
  }
  selectProductWaiver(product, i) {
    //Method to select a waiver with checkbox
    if (product.isSelected != undefined) {
      //If the product doesnt have the waiver selected
      if (product.isSelected) {
        this.products[i].isSelected = false;
        this.totalcountproducts -= 1; //Rest 1 to the count badge
      } else {
        this.products[i].isSelected = true;
        this.totalcountproducts += 1; //Increase 1 to the count badge
      }
    } else {
      this.totalcountproducts += 1; //Increase 1 to the count badge
      this.products[i].isSelected = true; //Add true to the selected
    }
  }
  saveProductWaiver() {
    //Function to save the products changes
    const findSelected = this.products.filter(
      (product) => product.isSelected === true
    ); //Search the selected products for the waiver
    const findNoSelected = this.products.filter(
      (product) => product.isSelected === false
    ); //Find the products that were already selected before but have been unchecked.
    Swal.fire({
      title: 'Assing Waiver to Products',
      html:
        'You are about to assign this Waiver to a total of ' +
        findSelected.length +
        ' product(s). Are you sure?',
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Loading...',
        });
        Swal.showLoading();
        let promises = [];
        findSelected.forEach((product) => {
          //For each selected waiver
          let promise = new Promise(async (resolve, reject) => {
            //Promise
            if (product.waivers) {
              //If the product have the waivers array
              const waiverid = product.waivers.find(
                (waiver) => waiver == this.waiver.id
              ); //It is searched if the waiver id is already saved on the product
              if (!waiverid) {
                //if the waiver id was not found
                product.waivers
                  ? product.waivers.push(this.waiver.id)
                  : (product.waivers = [this.waiver.id]); //save the waiver id on the waivers array
              }
            } else {
              product.waivers = [this.waiver.id]; //save the waiver id on the waivers array
            }
            delete product['isSelected']; //Remove the field to updated
            await this._productsService.updateProduct(product.id, product); //Update the product
            product['isSelected'] = true; //Add the field again to use in the system
            resolve(true);
          });
          promises.push(promise);
        });
        findNoSelected.forEach((product) => {
          //For each product that has been unmarked
          let promise = new Promise(async (resolve, reject) => {
            if (product.waivers) {
              //If the product have the waivers array
              const waiveridtodelete = product.waivers.find(
                (waiver) => waiver == this.waiver.id
              ); //It is searched if the waiver id is on the product
              if (waiveridtodelete) {
                //if the waiver id was found
                product.waivers.forEach((id, index) => {
                  //For each id on the waivers array
                  if (id == waiveridtodelete) {
                    //If its the same ID
                    delete product.waivers[index]; //Delete that id from the array
                  }
                });
              }
            }
            product.waivers = product.waivers.filter(function (e) {
              return e;
            }); //Clean array to delete empty or blank spaces
            product.waivers == undefined ? delete product['waivers'] : ''; //Delete waivers array if theres nothing
            delete product['isSelected']; //Remove the field to updated
            await this._productsService.updateProduct(product.id, product); //Update the product
            resolve(true);
          });
          promises.push(promise);
        });
        Promise.all(promises).then((_) => {
          setTimeout(() => {
            Swal.close();
            this.searchProductInput = ''; //Clean the search input
            Swal.fire({
              title: 'Changes Saved',
              html: 'The Waiver has been added to the products.',
              icon: 'success',
            });
          }, 1000);
        });
      }
    });
  }
  public searchProductInput: string;
  searchProduct() {
    //Function to search in the table
    var input, filter, found, table, tr, td, i, j;
    input = this.searchProductInput; //Save the input search
    filter = input.toUpperCase(); //Uppercase the input search
    table = document.getElementById('productsTable'); //Get the product table
    tr = table.getElementsByTagName('tr'); //Get the tr tags
    for (i = 0; i < tr.length; i++) {
      //For each tr
      td = tr[i].getElementsByTagName('td'); //Get all td in the tr
      for (j = 0; j < td.length; j++) {
        //For each td
        if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
          //Search the value on the td
          found = true;
        }
      }
      if (found) {
        //If there is the same value
        tr[i].style.display = ''; //Remove display propertie
        found = false; //Set found as false
      } else if (tr[i].id != 'tableHeader') {
        //if the tr is different from the tableHeader
        tr[i].style.display = 'none'; //hide tr
      }
    }
  }
}
