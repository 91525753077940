import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Rental } from 'src/app/models/storage/rental.model';
import { LogService } from 'src/app/services/log.service';

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss']
})
export class AddNoteComponent implements OnInit {

  constructor(
    private _afs: AngularFirestore,
    private _logService: LogService
  ) { }

  rental: Rental

  form = new UntypedFormGroup({
    "note": new UntypedFormControl("", Validators.required)
  })

  onSubmit(){
    if (this.form.valid && this.form.value){
      let note = "Note: " + this.form.value["note"]
      this._logService.addRentalLog(this.rental.id, "Note: " + this.form.value["note"])
      $("#add-note-modal").modal("toggle")
    }


  }

  ngOnInit(): void {
  }

  openModal(rental){
    this.rental = rental
    this.form.reset()
    $("#add-note-modal").prependTo("body");
    $("#add-note-modal").modal("toggle")
  }

}
