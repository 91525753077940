import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { ImageLibraryService } from 'src/app/services/image-library.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormArray, FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import * as _ from 'lodash';
import {AutosizeModule} from 'ngx-autosize'
import Swal from 'sweetalert2';

@Component({
  selector: 'app-inventory-widget-editor',
  templateUrl: './inventory-widget-editor.component.html',
  styleUrls: ['./inventory-widget-editor.component.scss']
})
export class InventoryWidgetEditorComponent {

  private _attributes: any;
  public varcharAttributes: Array<any> = [];

  @Output() newSavedWidgetId = new EventEmitter<any>();
  @Output() priceBoolChange = new EventEmitter<any>();

  @Input() component: any;
  @Input() widgetStructureList: any;
  @Input() savedWidgetEditor: boolean;
  @Input() isRentalEdit: boolean; // Editing a rental from the mini cart view

  @Output() onOpenSavedWidgetModal: EventEmitter<any> = new EventEmitter();

  public url = "";
  public croppedImage: any = '';
  public inventoryPageForm: FormGroup;
  isDataAvailable:boolean = false;
  public inventoryPageId;
  public selectedAddon;
  allProductGroups;
  selectedGroup = null; // If a group is selected for product widget dropdown
  productsInGroup; // All products a part of a group
  productGroupSizes = []; // All sizes in a prodct group
  productSizesDisabled = true;
  layoutForm;
  toggleAccordion;
  allProducts;
  checkboxForm;
  productGroupSizesID;
  sizeTypesMap = {};
  productGroupsMap = {};
  sizeTypesLoaded = false;
  productGroupsLoaded = false;
  productsLoaded = false;
  allProductsLoaded = false;
  productSizesLoaded;
  allProductSizesMap = {};
  subs = new Subscription(); // group of subscriptions
  defaultSizeTypesLoaded = false;
  isAlertClosed = false;

  componentSettings = {
    background_color: '',
    border: false,
    rounded_corners: 0,
    padding_top: 10,
    padding_right: 0,
    padding_bottom: 10,
    padding_left: 0,
    height: 0,
    alignment: '',
    text_color: '',
    text_size: 0,
  };

  // public Editor =  DecoupledEditor;

  // public onReady( editor: DecoupledEditor ): void {
  //   const element = editor.ui.getEditableElement()!;
  //   const parent = element.parentElement!;

  //   parent.insertBefore(
  //     editor.ui.view.toolbar.element!,
  //     element
  //   );
  // }

  constructor(private productsService: ProductsService, private http: HttpClient, private fb: FormBuilder,
    private imageLibraryService: ImageLibraryService, private activatedRoute: ActivatedRoute,
    private router: Router){};

  ngOnInit() {

    console.log("selected component edit")
    console.log(this.component)
    this.subs.add(this.imageLibraryService.selectedImg.subscribe((imgInfo) =>{
      this.croppedImage = imgInfo.url;
      this.closeImgGallery()
      this.component.element.image_link = this.croppedImage;
    }));
    if(this.component['widgetType'] == "product"){

      // Get all product groups
      this.subs.add(this.productsService.getProductGroups().subscribe(data => {

        this.allProductGroups = data;
  
        // Sorting groups by groupName in alphabetical order
        function compare( a, b ) {
          if ( a.groupName < b.groupName ){
            return -1;
          }
          if ( a.groupName > b.groupName ){
            return 1;
          }
          return 0;
        }
        
        this.allProductGroups = this.allProductGroups.sort( compare );

        data.forEach((group) => {
          this.productGroupsMap[group.id] = group;
        })

        // if(this.productGroupsLoaded && this.selectedGroup.id){
        //   if(this.selectedGroup.lastModified < this.productGroupsMap[this.selectedGroup.id].lastModified){

        //   }
        // }
        this.productGroupsLoaded = true;
        this.allSubsLoaded();
      }))

      // Gets all the size types 
      this.subs.add(this.productsService.getCustomSizeTypesObservableNoParams().subscribe(data => { 
        data.forEach((sizeType) => {
          this.sizeTypesMap[sizeType.id] = sizeType;
        })

        this.sizeTypesLoaded = true
        this.allSubsLoaded();
      }))

      //Gets all product sizes for a company
      this.subs.add(this.productsService.getProductSizesForCompany().subscribe(data => {
        data.forEach((size) => {
          this.allProductSizesMap[size.id] = size;
        })
  
        this.productSizesLoaded = true;
        this.allSubsLoaded();
      }))


      this.productsService.getCustomSizeTypesPromiseDefault().then((d) => { // get default size type (1 doc for all companies)
        d.forEach((sizeType) => {
          this.sizeTypesMap[sizeType.id] = sizeType;

          // Get default sizes for a company
          this.productsService.getCustomSizesPromise(sizeType.id).then((data) => {
            data.forEach((size) => {
              this.allProductSizesMap[size.id] = size;          
            })
            this.defaultSizeTypesLoaded = true;
            this.allSubsLoaded();
          })
        })


      })

    // Get all products for a company
    this.subs.add(this.productsService.getProducts().subscribe(data => {
      this.allProducts = data;

      // After inital load, if this subscription is called again we will check if there has been any chnages to the product attached tothe product group
      if(this.allProductsLoaded && this.component['element']['groupId']){

      }
      this.allProductsLoaded = true;

      this.allSubsLoaded();
    }))
      
    }
    else{

      this.isDataAvailable = true;
    }
  }

  ngOnChanges(){
    if(this.component['widgetType'] == "product"){
      this.allSubsLoaded();
    }
  }
  async allSubsLoaded(){
    if(this.productGroupsLoaded && this.sizeTypesLoaded && this.productSizesLoaded && this.defaultSizeTypesLoaded && this.allProductsLoaded){
      await this.setProductComponent();
    }
  }


  // If we are viewing a product component make sure to update dropdown values
  setProductComponent(){
    this.selectedGroup = null;
    if(this.component['element']['groupId']){
      this.selectedGroup = this.productGroupsMap[this.component['element']['groupId']];
      console.log("SELECTED GROUP")
      this.getProductGroupSizes(this.selectedGroup.id);
    

      // Set a temporary price to be displayed, this will change in parent component after a date range
      // is searched for and price calculations are made
      // If you are editing a rental then you already have a price set and sill not needa temporary display price
      if(!this.isRentalEdit){
        if(this.component.element.priceByDay[0]?.price){
          this.component.element.price = this.component.element.priceByDay[0].price;
        }
        else if(this.component.element.priceByHour[0]?.price){
          this.component.element.price = this.component.element.priceByHour[0].price;
        }
        else{
          this.component.element.price = 0;
        }
      }
    }
    // It is a new product widget and no product group is selected yet
    else{
      // this.selectedGroup = "- Please select a product group -";
      this.isDataAvailable = true;
    }
    return
  }



  productPriceChanged(ind){
    if(ind == 0){
      this.component.element.price = this.component.element.priceByDay[0].price;
    }
  }

  setAsDefault($event: any) {
    this.component.element.options.forEach((v: any, k: number) => {
      if (v.id == $event.target.value) {
        v.is_selected = true;
      } else {
        v.is_selected = false;
      }
    });
  }

  //Adds options to drop down and radio widgets
  addOption() {
    this.component.element.options.push({
      id: uuid(),
      text: 'Option' + ' ' + (this.component.element.options.length + 1),
      is_selected: false,
      price: null
    });
  }

  //Updates the product component values based on drop down selection
  //Updates the tempProductWidgets if product is changed
  async setProductsValue(){
    console.log("SELECTED GROUP PRINT")
    console.log(this.selectedGroup)
    this.productSizesDisabled = true;
    this.component.element.name = this.selectedGroup.groupName;
    this.component.element.description = this.selectedGroup.groupDescription;
    this.component.element.priceByDay = this.selectedGroup.priceByDay;
    this.component.element.priceByHour = this.selectedGroup.priceByHour;
    this.component.element.groupId = this.selectedGroup.id;
    this.component.element.is24hrsPrice = this.selectedGroup.is24hrsPrice;
    this.component.element.groupImage = this.selectedGroup.groupImage;
    this.component.element.priceByDayOriginal = JSON.parse(JSON.stringify(this.selectedGroup.priceByDay));
    this.component.element.priceByHourOriginal = JSON.parse(JSON.stringify(this.selectedGroup.priceByHour));
    // Set a temporary price to be displayed, this will change in parent component after a date range
    // is searched for and price calculations are made
    if(this.component.element.priceByDay[0]?.price){
      this.component.element.price = this.component.element.priceByDay[0].price;
    }
    if(this.component.element?.priceByHour.length > 0){
      this.component.element.price = this.component.element.priceByHour[0].price;
    }
    else{
      this.component.element.price = 0;
    }

    //// Set the hours and days for the pricing

      // If there are no days set then create the first one
    if(this.component.element.priceByDay.length == 0){
      this.component.element.priceByDay = [{
        day: 1,
        price: 0,
        increment: 0
      }]
    }



    // If there are hours set then grab the first one
    if(this.component.element.priceByHour.length > 0){
      if(this.component.element.priceByHour[0]['hour'] == 1){
        this.component.element.priceByHour = [this.component.element.priceByHour[0]];
        this.component.element.priceByHour[0]['increment'] = 0;
      }
      else{
        // If the first hour in the array isnt 1 then make it 1
        this.component.element.priceByHour = [this.component.element.priceByHour[0]];
        this.component.element.priceByHour[0]['hour'] = 1;
        this.component.element.priceByHour[0]['increment'] = 0;
      }
    }
    // If there arent hours set then create the first one
    else{
      this.component.element.priceByHour = [{
        hour: 1,
        price: 0,
        increment: 0
      }]
    }



    this.component.element.size = '';

    console.log("COMPONENTs")
    console.log(this.component)
    this.component.element.options = [];
    this.getProductGroupSizes(this.selectedGroup.id);

  }



  getProductGroupSizes(id){
    let sizeMap = {}
    let sortOrderList = [];
    

    // Get all the products that belong to this product group
    this.productsService.getProductByGroupID(id).then(data => {
      this.productsInGroup = data;
      
      // console.log("SIZE TYPES MAP")
      // console.log(this.sizeTypesMap)
      // console.log("ALL PRODUCT SIZES")
      // console.log(this.allProductSizesMap)
      this.productsInGroup.forEach(product => {
        // console.log("PRODUCT")
        // console.log(product)
        sizeMap[product['productSizeID']] = this.allProductSizesMap[product['productSizeID']];
        sortOrderList = this.sizeTypesMap[product['productSizeTypeID']]['sortOrder'];

      })
      // console.log("SORT ORDER LSIT")
      // console.log(sortOrderList)
  
    // If there are already options for the product
    if(this.component.element.options){
      this.component.element.options.forEach((option, ind )=> {

        // If size doesnt exist anymore then remove it
        if(!sizeMap.hasOwnProperty(option['sizeID'])){
          delete this.component.element.options[ind];
        }
        // Otherwise make sure the size name is up to date
        // Remove size from map since it is already an option
        else{
          option['size'] = sizeMap[option['sizeID']]['size'];
          delete sizeMap[option['sizeID']];
        }
      })
    }

    // If there are new sizes add them to
    if(sizeMap){
      for(let key in sizeMap){
        this.component.element.options.push(
          {
            id: uuid(),
            size: sizeMap[key]['size'],
            sizeID: sizeMap[key]['id'],
            inputValue: 0,
            show: true
          }
        )
      }
    }
    
    // Sorting
    let optionsList = [];
    let tempOptionsMap = {};

    // Makes a copy of the options sizeID
    this.component.element.options.forEach(option => {
      optionsList.push(option.sizeID);
    })
    
    // Sorts the ID's based on the sort order in the product size types collection
    optionsList.sort((a, b) => sortOrderList.indexOf(a) - sortOrderList.indexOf(b));

    // Copies the options
    let tempOptions = this.component.element.options;
    this.component.element.options = [];

    // Makes the copied option into a map
    tempOptions.forEach((option) => {
      tempOptionsMap[option.sizeID] = option;
    })

    // Pushes the options back onto the real component in the correct order
    optionsList.forEach(option => {
      this.component.element.options.push(tempOptionsMap[option])
    })

    this.productSizesDisabled = false;
    this.isDataAvailable = true;

  })

    return
  }


  removeOption(optionId: any) {
    this.component.element.options = this.component.element.options.filter((v: any) => {
      return v.id != optionId;
    });
  }

  // Resets the values to null if the radio option is changed
  cleanRecurringPriceValues(recurPrice){
    // if(this.component.widgetType == "dropdown" || this.component.widgetType == "radios"){
    //   if(recurPrice){
    //     this.component.element.options.forEach(option => {
    //       option.price = null;
    //     })
    //   }
    //   else{
    //     this.component.element.options.forEach(option => {
    //       option.dayPrice = null;
    //       option.hourPrice = null;
    //     })
    //   }
    // }
    // else{
    //   if(recurPrice){
    //     this.component.element.price = null;
    //   }
    //   else{
    //     this.component.element.dayPrice = null;
    //     this.component.element.hourPrice = null;
    //   }
    // }

    // console.log("CAHNEG")
    // console.log(this.component.element)

  }


  updateSelected(){
    // console.log(this.selectedAddon)
    this.component.element.label = this.selectedAddon.widgetName;
  }

  closeAlert(){
    this.isAlertClosed = true;
  }

  onCancel(){
    this.router.navigate(['../'], { relativeTo: this.activatedRoute})
  }

  openImgGallery(){
    $('#imgGallery').modal('show');
  }

  closeImgGallery(){
    $('#imgGallery').modal('hide');
  }

  // Warn the user that of they dont save their changes the changes will be lost before leaving the page
  onEditSavedWidgetClick(){
    Swal.fire({
      title: 'Any unsaved changes will be lost',
      html: 'Are you sure you want to leave this page?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#7066e0',
      cancelButtonText: 'Confirm',
      showConfirmButton: true,
      confirmButtonColor: '#6e7881',
      confirmButtonText: 'Cancel',
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isDismissed) {
        this.router.navigate(['../savedWidgetEditor/', this.component['savedWidgetId']], { relativeTo: this.activatedRoute })
      }
    });

  }

  //---------- Saving Widget Modal ----------//
  saveAsCompanyWidget() {
    const validate = this.productsService.validateWidgetsValue(this.component);
    if(validate) {
      delete this.component.element['invalid'];
      this.onOpenSavedWidgetModal.emit();
    }else {
      this.component.element['invalid'] = true;
    }
  }

  //add a title variable to savedWidget and updates widgets collection in database
  closeTemplateModal() {
    $('#createTemplateModal').modal('hide')
  }


  // ---------------- Images ----------------------- //
  public handleInputChange(e: any) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();

    if (!file.type.match(pattern)) {
      alert('Invalid format');
      return;
    }

    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e: any) {
    this.component.element.image_url = e.target.result;
  }


  ngOnDestroy(): void {
    this.subs.unsubscribe(); // unsubscribe from all subscriptions in this group
  }


} // End of class
