<div *ngIf="!isDataAvailable" class="d-flex text-center justify-content-center align-items-center" style="margin-top: 50px;">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div *ngIf="isDataAvailable">
    
    <!-------------------Real Display of All Widgets --------------------------------->
        <ng-template #recursiveList let-item>
        
          <div
          style="padding: 2px;"
          >
      
            <div class="align-middle">
              <app-inventory-widget-display [widget]="item" [editMode]="true" [savedWidgetEditor]="true"></app-inventory-widget-display>
            </div>
          </div>
        </ng-template>
  
    
    <div class="container">
  
    <div class="row" style="margin-top: 8px;">
  
  <!------------------------------ Widget Editor ------------------------------------>
  <div class="col-sm-3" style="padding: 4px">
    <div style="display:flex; margin-bottom:5px">
      <button 
        class="btn btn-secondary" 
        style="margin-right: 10px" 
        (click)="onGoBack()">
        <i class="fas fa-arrow-left" aria-hidden="true"></i>
      </button>
      <button class="btn btn-success" (click)="onSaveWidget()">Save</button>
    </div>
    <div class="row widgetDisplay"> 
      <div>
      <div style="display:flex; justify-content: center; 
        align-items: center; margin: 0px;">
        <h3 style="margin:0px; font-weight: bold; letter-spacing: 1px; font-size:16px;">Widget Editor</h3>
      </div>
  
      <hr style="margin-top:5px">
      <div *ngIf="widget['savedWidgetId']" class="text-center">
        <div style="margin: 10px 0px"><b>{{widget['widgetName']}}</b></div>
      </div>
      <app-inventory-widget-editor [component]="widget" [savedWidgetEditor]="true"></app-inventory-widget-editor>
      <span><hr/></span>
    </div>
  </div>
  <div
  class="alert alert-primary"
  style="text-align: left; margin-top: 10px;"
  *ngIf="!isAlertClosed"
    >
  <i
    class="fa fa-window-close pull-right fa-2x"
    aria-hidden="true"
    style="margin-left: 1.3em !important"
    (click)="closeAlert()"
  ></i>
    Any widget changed with this editor will change all instances of the widget.
  </div>
  
  </div>


  <div class="col-sm-9">
    <div class="flex-grow-1">
      <div class="d-flex content-droppable-zone bg-light dndList" >
        <div 
          class="form-container mx-auto" style="margin-top: 3rem; margin-bottom: 2rem">
            <ng-container
            *ngTemplateOutlet="
              recursiveList;
              context: {
                $implicit: widget
              }
            "
            ></ng-container>
          </div>
          </div>
        </div>
  </div>
  </div>
  </div>
  
  
    <!----------- Image Library ------------>
      <div class="modal fade" data-bs-backdrop="false" id="imgGallery">
          <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 90vw; max-height: 90vh;">
              <div class="modal-content">
                  <div class="modal-body">
                      <!-- Button used to close the modal -->
                      <div style="position: relative; width: 100%">
                          <button id="closeImgLibraryBtn" type="button" class="btn btn-danger rowBtn" data-bs-dismiss="modal">
                              <i class="fa fa-close"></i>
                          </button>
                      </div>
                      <!-- The image library component -->
                      <!-- defaultTabSelection determines what tab should be active by default -->
                      <!-- showSelect bool set to true if the select option needs to be active upon clicking on img -->
                      <app-image-library [showSelect]="true" [defaultTabSelection]="'image'"></app-image-library>
                  </div>
              </div>
          </div>
      </div>
    </div>
  
  