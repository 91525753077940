import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booking'
})
export class BookingPipe implements PipeTransform {

  constructor(){

  }

  transform(bookings, term){
    // Create an empty array
    let filteredArray = []
    bookings

    if(term && term.length > 0){
      filteredArray = bookings.filter((booking) => {
      return Object.keys(booking).some((key) => {
        if(key === "title"){
        // if(key === "title" || key === "subHeading" || key === "dateCreated"){
          if(booking[key] == null){
            console.log(key);
          }

          return booking[key].toString().toLowerCase().includes(term.toLowerCase())
        }
      })
    })
    }

    // return filteredArray.length > 0 ? filteredArray : products;
    return term.length > 0 ? filteredArray : bookings;
  }

}
