import { Injectable } from '@angular/core';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, Subscriber, Subscription } from 'rxjs';
import { User } from 'src/app/models/storage/user.model';
import firestore from 'firebase/compat/app';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {

  public currentUser: User
  public stripeID = ""

  private versionCollection: AngularFirestoreCollection<any>;
  version: Observable<any[]>;

  constructor(
    private _afs: AngularFirestore,
    private _afAuth: AngularFireAuth
  ) {
    this.authState()

    this.versionCollection =
      this._afs.collection<any>('version');
  }

  private _afUser: firestore.User
  private _userObservable: Observable<User>

  public get afUser() {
    return this._afUser
  }


  private authState() {
    this._afAuth.authState.subscribe(user => {
      this._afUser = user
      this.setupObservable()
      this.subscribe()
    })
  }

  private setupObservable() {
    if (!this.afUser) {
      this._userObservable = undefined
    } else {
      this._userObservable = this._afs.collection('users').doc(this.afUser.uid).snapshotChanges().pipe(map(user => {
        if (!user.payload.exists) {
          return null
        } else {
          const data = user.payload.data() as User
          data.id = user.payload.id
          return data
        }
      }))

    }
  }

  private _userSubscription: Subscription


  private subscribe() {
    if (this._userObservable === undefined) {
      this.currentUser = undefined
      this._localUserObservable.next(null)
    } else {
      if (this._userSubscription) this._userSubscription.unsubscribe()
      this._userSubscription = this._userObservable.subscribe(user => {
        this.currentUser = user
        this._localUserObservable.next(this.currentUser)
      })
    }
  }

  private _localUserObservable = new BehaviorSubject<User>(null)

  getCurrentUser(): Observable<User> {
    return this._localUserObservable.asObservable()
  }

  getCurrentUserOnce(): Promise<User> {
    if (this.currentUser !== undefined) {
      return new Promise(resolve => resolve(this.currentUser))
    } else {
      return this.queryCurrentUser()
    }
  }

  getCompanies() {
    return this._afs.collection('companies', ref =>
      ref.where("active", "==", true)
        .orderBy("companyName")).snapshotChanges();
  }


  getCompanyInfoByID(companyID): any {
    return this._afs.collection('companies').doc(companyID).ref.get().then((res) => {
      return res.data();
    })
  }

  getCompanyInfoByIDAsObs(companyID: string): Observable<any> {
    return this._afs .collection('companies').doc(companyID)
      .valueChanges()
      .pipe(
        map((data) => {
          return data as any;
        })
      );
  }


  setOptionName(companyID: string, optionName: string, optionSubtitle: string) {
    return this._afs.collection('companies').doc(companyID).update({ optionName: optionName, optionSubtitle: optionSubtitle });

  }

  updateUser(id: any, user: any) {
    console.log(id, user)
    return this._afs.collection('users').doc(id).update(user);
  }

  private queryCurrentUser(): Promise<User> {
    return new Promise(resolve => {
      this._afAuth.authState.pipe(take(1)).subscribe(user => {
        if (!user) {
          return resolve(null)
        } else {
          return this._afs.collection('users').doc(user.uid).ref.get().then(user => {
            if (!user.exists) {
              return resolve(null)
            } else {
              const data = user.data() as User
              data.id = user.id

              // Aquí es donde agregamos la consulta a la colección 'companies'
              this._afs.collection('companies').doc(data.companyId).ref.get().then(company => {
                if (company.exists) {
                  // Si la compañía existe, agregamos sus datos a currentUser
                  data['currentCompany'] = company.data()
                }
                this.currentUser = data
                this._localUserObservable.next(this.currentUser)
                return resolve(data)
              })
            }
          })
        }
      })
    })
  }


  updateCurrentUserPassword(currentPwd: string, newPwd: string): Promise<string> {

    return new Promise((resolve, reject) => {
      this._afAuth.currentUser.then(current => {
        this._afAuth.signInWithEmailAndPassword(current.email, currentPwd).then(login => {

          current.updatePassword(newPwd).then(_ => {
            resolve('éxito')
          }).catch(error => {
            reject(error.message)
          })

        }).catch(error => {
          reject(error.message)
        })

      }).catch(error => {
        reject(error.message)
      })
    })

  }


  checkVersion() {
    var versionCol = this._afs.collection('version').doc("releaseVersion")
    var vers = versionCol.snapshotChanges().pipe(map(changes => {
      const data = changes.payload.data();
      if (data != undefined) {
        return data;
      } else {
        return null
      }
    }))
    return vers as any
  }

  checkVersionTest() {
    var versionCol = this._afs.collection('version').doc("releaseVersion")
    var vers = versionCol.snapshotChanges().pipe(map(changes => {
      const data = changes.payload.data();
      if (data != undefined) {
        return data;
      } else {
        return null
      }
    }))
    return vers as any
  }

  checkVersionReleaseNote() {
    return new Promise<any[]>((resolve, reject) => {
      this.version = this.versionCollection.doc("releaseVersion").valueChanges();
      this.version
        .forEach((data) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  }

  updateUserBookingChoice(userID: string, bookingChoice: any): Promise<void> {
    console.log(userID, bookingChoice);

    return this._afs.collection('users').doc(userID).update({ bookingChoice: bookingChoice });
  }

  getBookingChoice(): Promise<Map<string, any>> {
    return new Promise<Map<string, any>>((resolve, reject) => {
      this._afs.collection('users').ref.where('id', '==', this.currentUser.id).get().then(querySnapshot => {
        const bookingChoices = new Map<string, any>();
        querySnapshot.forEach((doc: any) => {
          const data = doc.data();
          if (data.bookingChoice) {
            for (const key in data.bookingChoice) {
              if (data.bookingChoice.hasOwnProperty(key)) {
                bookingChoices.set(key, data.bookingChoice[key]);
              }
            }
          }
        });
        resolve(bookingChoices);
      });
    });
  }
}
