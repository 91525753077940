import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { User } from 'src/app/models/storage/user.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ColorTheme {
  private colorSubject = new BehaviorSubject<string>('');
  public chooseColor;
  public fontcolor;
  public logoTheme;
  public colors = [
    {
      name: 'Default Theme',
      value: '#114463',
      fontcolor: '#FFFFFF',
      logo: '../../assets/images/logos/theme2.png',
    },
    {
      name: 'Theme 1',
      value: '#E5E1DA',
      //fontcolor: '#9AA8AC',
      fontcolor: '#4A6B77',
      logo: '../../assets/images/logos/theme6.png',
    },
    {
      name: 'Theme 2',
      value: '#C2B59B',
      //fontcolor: '#586775',
      fontcolor: '#4A5967',
      logo: '../../assets/images/logos/theme5.png',
    },
    {
      name: 'Theme 3',
      value: '#586775',
      //fontcolor: '#C2B59B',
      fontcolor: '#D1C19F',
      logo: '../../assets/images/logos/theme4.png',
    },
    {
      name: 'Theme 4',
      value: '#9AA8AC',
      //fontcolor: '#E5E1DA',
      fontcolor: '#F7F7F7',
      logo: '../../assets/images/logos/theme3.png',
    },
    {
      name: 'Theme 5',
      value: '#000000',
      fontcolor: '#FFFFFF',
      logo: '../../assets/images/logos/theme2.png',
    },
    {
      name: 'Theme 6',
      value: '#FFFFFF',
      fontcolor: '#000000',
      logo: '../../assets/images/logos/theme1.png',
    },
  ];

  public color$ = this.colorSubject.asObservable();

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore) {}

  public selectColor(event) {
    this.chooseColor = event.value.value;
    switch (this.chooseColor) {
      case '#E5E1DA':
        this.fontcolor = this.colors[1].fontcolor;
        this.logoTheme = this.colors[1].logo;

        break;
      case '#C2B59B':
        this.fontcolor = this.colors[2].fontcolor;
        this.logoTheme = this.colors[2].logo;

        break;
      case '#586775':
        this.fontcolor = this.colors[3].fontcolor;
        this.logoTheme = this.colors[3].logo;

        break;
      case '#9AA8AC':
        this.fontcolor = this.colors[4].fontcolor;
        this.logoTheme = this.colors[4].logo;
        break;
      case '#000000':
        this.fontcolor = this.colors[5].fontcolor;
        this.logoTheme = this.colors[5].logo;

        break;
      case '#FFFFFF':
        this.fontcolor = this.colors[6].fontcolor;
        this.logoTheme = this.colors[6].logo;

        break;
      default:
        this.fontcolor = '#FFFFFF';
        this.logoTheme = this.colors[0].logo;
    }

    this.colorSubject.next(this.chooseColor);
  }

  setSelectedValue(event) {
    this.chooseColor = event.value.value;
    this.colorSubject.next(this.chooseColor);
  }

  get colores() {
    return this.colors;
  }

  getcolorPeruser(userID: string): Promise<User> {
    return this.afs
      .collection('users')
      .doc(userID)
      .ref.get()
      .then((doc) => {
        const data = doc.data() as User;
        data.id = doc.id;
        return data;
      });
  }
}
