import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { CurrentUserService } from './current-user.service';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {
  public date = new Date();
  public startMonth: any =  new Date(this.date.getFullYear(), this.date.getMonth(), 1);
  public endMonth: any = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0); //Variable to save the last date from the actual month
  
  constructor(private firestore: AngularFirestore, private currentUserService: CurrentUserService) { }
  
  getRentals(): Observable<any> {//Method to obtain the rentals
    return this.firestore.collection('rentals',ref => //return the collection of rentals
        ref
        .where('dayStart', '<=', this.endMonth)//Condition to get the rentals on this month
        .where('dayStart', '>=', this.startMonth)
        .where('isConfirmed', '==', true)
        .where('companyID', '==', this.currentUserService.currentUser.companyId)
        ).snapshotChanges();
  }
  getProductsRepairs(): Observable<any>{//Method to obtain de repairs of products
    return this.firestore.collection('products', ref =>//return the collection of products
      ref.where('isMaintenance', '==', true)//Condition to get the products in imaintenance
      .where('companyID', '==', this.currentUserService.currentUser.companyId)
      ).snapshotChanges();
  }
}
