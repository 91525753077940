import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Component } from '@angular/core';
import { FirebaseEmulatorsService } from 'src/app/services/firebase-emulators.service';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'fleet-adventures';

  constructor (
    private afFun: AngularFireFunctions,
    private afFire: AngularFirestore,
    private afStore: AngularFireStorage,
    private fsEmulatorService: FirebaseEmulatorsService,
    private location: PlatformLocation,
  ) {
    this.fsEmulatorService.useEmulators({
      afFunctions: this.afFun,
      afFirestore: this.afFire,
      afStorage: this.afStore
    })

    location.onPopState(()=>{
      $('.modal').modal('hide');
    })
  }
}
