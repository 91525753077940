import { Component, OnInit, ViewChild } from '@angular/core';
import { Rental } from 'src/app/models/storage/rental.model';
import { ReportService } from 'src/app/services/report.service';
import { ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { ColorTheme } from 'src/app/services/colorTheme.service';
import { ProductsService } from 'src/app/services/products.service';
import { ProductLocationService } from 'src/app/services/product-location.service';
@Component({
  selector: 'app-report-product',
  templateUrl: './report-product.component.html',
  styleUrls: ['./report-product.component.scss'],
})
export class ReportProductComponent implements OnInit {
  Is_id_user: string;
  public ColorTheme;
  public ColorText;

  constructor(
    private reportService: ReportService,
    private _currentUser: CurrentUserService,
    public _Color_theme: ColorTheme,
    private _productService: ProductsService,
    private _locationService: ProductLocationService
  ) { }
  public rentals: Rental[] = [];
  public date = new Date(); //Obtain the actual date
  public datatable: any[] = [];
  public objectsoptions: any = {};
  public objectscategories: any = {};
  public objectsproducts: any = {};
  public objectsproductssize: any = {};
  public arrayoption: any[] = [];
  public arraycategory: any[] = [];
  public arrayproduct: any[] = [];
  public arrayproductsize: any[] = [];
  public isLoadingData: boolean = true;
  public idoption: string = '';
  public idcategory: string = '';
  public productname: string = '';

  //View variables
  public isLocation: boolean = true;
  public isGroup: boolean = false;
  public isSizeType: boolean = false;
  public isSize: boolean = false;
  public isProduct: boolean = false;

  //Breads variables
  public breadlocation: boolean = true;
  public breadGroups: boolean = false;
  public breadSizeType: boolean = false;
  public breadSize: boolean = false;
  public breadProduct: boolean = false;

  //Variable to save auxiliar data
  public locationID: string
  public locationName: string
  public groupID: string
  public groupName: string
  public sizeTypeID: string
  public sizeTypeName: string
  public sizeID: string
  public sizeName: string

  //Arrays of data
  public locationProducts: any[] = []
  public groupsProducts: any[] = []
  public sizeTypesProducts: any[] = []
  public sizesProducts: any[] = []
  public products: any[] = []
  //Array of catalog information
  public locations: any[]
  public productGroups: any[]
  public sizeType: any[]
  public sizes: any[]
  ngOnInit(): void {
    this.getProductGroups();
    this.getSizes();
    this.getLocations();
    this.fnChangecolor();
  }
  fnChangecolor() {
    this.Is_id_user = this._currentUser.currentUser.id;
    this._Color_theme
      .getcolorPeruser(this.Is_id_user)
      .then((fac) => {
        let ObjColor = {
          color: fac.color.value,
          font: fac.color.fontcolor,
        };
        this.ColorText = ObjColor.font;
        this.ColorTheme = ObjColor.color;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getProductGroups() {
    this._productService.getAllProductsGroupByCompanyPromise().then(groups => {
      this.productGroups = groups;
    })
  }
  async getSizes() {
    const sizeType = await this._productService.getCustomSizeTypesPromise(this._currentUser.currentUser.companyId);
    const defaultType = await this._productService.getCustomSizeDefaultPromise(this._currentUser.currentUser.companyId);
    console.log(defaultType)
    this.sizeType = [...sizeType, ...defaultType];
    const sizes = await this._productService.getCustomSizePromise(this._currentUser.currentUser.companyId);
    const defaultSize = await this._productService.getCustomSizesPromise(defaultType[0].id);
    this.sizes = [...sizes, ...defaultSize];
  }
  getLocations() {
    // Reset bread variables
    this.locationID = "";
    this.locationName = "";
    let today = new Date();
    this._locationService.getAllProductsLocations(this._currentUser.currentUser.companyId).subscribe(locations => {
      let groupedLocations = {};
      this.isLoadingData = true;
      this.locations = locations;
      locations.forEach(location => {
        groupedLocations[location.id] = {
          locationID: location.id,
          locationName: location.name,
          last30days: 0,
          daysout1to3: 0,
          daysout4to10: 0,
          daysout11to30: 0,
          daysout31to60: 0,
          daysout61to99: 0,
          daysout100to150: 0,
          visible: true,
          color: this.getRandomColor()
        };
      });
      this.reportService.getRentals150().subscribe(rentals => {
        this.rentals = rentals;
        let filteredRentals = rentals.filter(r => r.cartObj && r.cartObj.items && r.cartObj.items.length > 0);
        // Move through each rental
        filteredRentals.forEach(rental => {
          let daysOut = Math.floor((today.getTime() - new Date(rental.dayStart.seconds * 1000).getTime()) / (1000 * 60 * 60 * 24));
          // Get each item
          rental.cartObj.items.forEach(item => {
            // Search the product
            let groupedLocation = groupedLocations[item.locationID];
            if (groupedLocation) {
              if (daysOut <= 30) {
                groupedLocation.last30days++;
              }
              if (daysOut >= 1 && daysOut <= 3) {
                groupedLocation.daysout1to3++;
              } else if (daysOut >= 4 && daysOut <= 10) {
                groupedLocation.daysout4to10++;
              } else if (daysOut >= 11 && daysOut <= 30) {
                groupedLocation.daysout11to30++;
              } else if (daysOut >= 31 && daysOut <= 60) {
                groupedLocation.daysout31to60++;
              } else if (daysOut >= 61 && daysOut <= 99) {
                groupedLocation.daysout61to99++;
              } else if (daysOut >= 100 && daysOut <= 150) {
                groupedLocation.daysout100to150++;
              }
            }
          });
        });
        const { totalLast30days,
          totalDaysout1to3,
          totalDaysout4to10,
          totalDaysout11to30,
          totalDaysout31to60,
          totalDaysout61to99,
          totalDaysout100to150 } = this.calculateTotals(groupedLocations);
        groupedLocations = this.calculatePercentage(groupedLocations, totalLast30days, totalDaysout1to3, totalDaysout4to10, totalDaysout11to30, totalDaysout31to60, totalDaysout61to99, totalDaysout100to150);
        this.isLoadingData = false;
        this.locationProducts = Object.values(groupedLocations);
        this.getGraphics(this.locationProducts, "location");
        this.chart.update(); //Update chart
      });
    });
  }

  getByGroup(location) {
    // Reset bread variables
    this.groupID = "";
    this.groupName = "";
    if (location) {
      this.locationID = location.locationID;
      this.locationName = location.locationName;
    }
    let today = new Date();
    let groupedProducts = {};
    this.isLoadingData = true;
    let filteredRentals = this.rentals.filter(r => r.cartObj && r.cartObj.items && r.cartObj.items.length > 0);
    // Move through each rental
    filteredRentals.forEach(rental => {
      let daysOut = Math.floor((today.getTime() - new Date(rental.dayStart.seconds * 1000).getTime()) / (1000 * 60 * 60 * 24));
      let filteredByLocation = rental.cartObj.items.filter(item => item.locationID == this.locationID);
      // Get each item
      filteredByLocation.forEach(item => {
        // Search the product on the array
        let groupedProduct = groupedProducts[item.parentId];
        if (!groupedProduct) {
          const group = this.productGroups.find(group => group.id == item.parentId);
          if (group) {
            groupedProduct = {
              productGroupID: item.parentId,
              groupName: group.groupName ? group.groupName : "Product Group",
              last30days: 0,
              daysout1to3: 0,
              daysout4to10: 0,
              daysout11to30: 0,
              daysout31to60: 0,
              daysout61to99: 0,
              daysout100to150: 0,
              visible: true,
              color: this.getRandomColor()
            };
            groupedProducts[item.parentId] = groupedProduct;
          }
        }
        if (groupedProduct) {
          // Here you can add the item to the corresponding group
          // and update the necessary fields
          if (daysOut <= 30) {
            groupedProduct.last30days++;
          }
          if (daysOut >= 1 && daysOut <= 3) {
            groupedProduct.daysout1to3++;
          } else if (daysOut >= 4 && daysOut <= 10) {
            groupedProduct.daysout4to10++;
          } else if (daysOut >= 11 && daysOut <= 30) {
            groupedProduct.daysout11to30++;
          } else if (daysOut >= 31 && daysOut <= 60) {
            groupedProduct.daysout31to60++;
          } else if (daysOut >= 61 && daysOut <= 99) {
            groupedProduct.daysout61to99++;
          } else if (daysOut >= 100 && daysOut <= 150) {
            groupedProduct.daysout100to150++;
          }
        }
      });
    });
    const { totalLast30days,
      totalDaysout1to3,
      totalDaysout4to10,
      totalDaysout11to30,
      totalDaysout31to60,
      totalDaysout61to99,
      totalDaysout100to150 } = this.calculateTotals(groupedProducts);
    groupedProducts = this.calculatePercentage(groupedProducts, totalLast30days, totalDaysout1to3, totalDaysout4to10, totalDaysout11to30, totalDaysout31to60, totalDaysout61to99, totalDaysout100to150);
    this.isLoadingData = false;
    this.groupsProducts = Object.values(groupedProducts);
    this.getGraphics(this.groupsProducts, "group")
    this.chart.update(); //Update chart
  }

  getBySizeType(group) {
    // Reset bread variables
    this.sizeTypeID = "";
    this.sizeTypeName = "";
    if (group) {
      this.groupID = group.productGroupID;
      this.groupName = group.groupName;
    }
    let today = new Date();
    let groupedSizeTypes = {};
    this.isLoadingData = true;
    let filteredRentals = this.rentals.filter(r => r.cartObj && r.cartObj.items && r.cartObj.items.length > 0);
    // Move through each rental
    filteredRentals.forEach(rental => {
      let daysOut = Math.floor((today.getTime() - new Date(rental.dayStart.seconds * 1000).getTime()) / (1000 * 60 * 60 * 24));
      let filteredByLocation = rental.cartObj.items.filter(item => item.locationID == this.locationID && item.parentId == this.groupID);
      // Get each item
      filteredByLocation.forEach(item => {
        const size = this.sizes.find(size => size.id == item.productSizeID)
        if (size) {
          const sizeType = this.sizeType.find(type => type.id == size.productType);
          if (sizeType) {
            let groupedSizeType = groupedSizeTypes[sizeType.id];
            if (!groupedSizeType) {
              groupedSizeType = {
                sizeTypeID: sizeType.id,
                sizeType: sizeType.productType,
                last30days: 0,
                daysout1to3: 0,
                daysout4to10: 0,
                daysout11to30: 0,
                daysout31to60: 0,
                daysout61to99: 0,
                daysout100to150: 0,
                visible: true,
                color: this.getRandomColor()
              };
              groupedSizeTypes[sizeType.id] = groupedSizeType;
            }
            if (daysOut <= 30) {
              groupedSizeType.last30days++;
            }
            if (daysOut >= 1 && daysOut <= 3) {
              groupedSizeType.daysout1to3++;
            } else if (daysOut >= 4 && daysOut <= 10) {
              groupedSizeType.daysout4to10++;
            } else if (daysOut >= 11 && daysOut <= 30) {
              groupedSizeType.daysout11to30++;
            } else if (daysOut >= 31 && daysOut <= 60) {
              groupedSizeType.daysout31to60++;
            } else if (daysOut >= 61 && daysOut <= 99) {
              groupedSizeType.daysout61to99++;
            } else if (daysOut >= 100 && daysOut <= 150) {
              groupedSizeType.daysout100to150++;
            }
          }
        }
      });
    });
    const { totalLast30days,
      totalDaysout1to3,
      totalDaysout4to10,
      totalDaysout11to30,
      totalDaysout31to60,
      totalDaysout61to99,
      totalDaysout100to150 } = this.calculateTotals(groupedSizeTypes);
    groupedSizeTypes = this.calculatePercentage(groupedSizeTypes, totalLast30days, totalDaysout1to3, totalDaysout4to10, totalDaysout11to30, totalDaysout31to60, totalDaysout61to99, totalDaysout100to150);
    this.isLoadingData = false;
    this.sizeTypesProducts = Object.values(groupedSizeTypes);
    this.getGraphics(this.sizeTypesProducts, "sizeType");
    this.chart.update(); //Update chart
  }
  getBySize(sizeType) {
    // Reset bread variables
    this.sizeName = "";
    this.sizeID = "";
    if (sizeType) {
      this.sizeTypeName = sizeType.sizeType;
      this.sizeTypeID = sizeType.sizeTypeID;
    }
    let today = new Date();
    let groupedSizes = {};
    this.isLoadingData = true;
    let filteredRentals = this.rentals.filter(r => r.cartObj && r.cartObj.items && r.cartObj.items.length > 0);
    // Move through each rental
    filteredRentals.forEach(rental => {
      let daysOut = Math.floor((today.getTime() - new Date(rental.dayStart.seconds * 1000).getTime()) / (1000 * 60 * 60 * 24));
      let filteredByLocation = rental.cartObj.items.filter(item => item.locationID == this.locationID && item.parentId == this.groupID);
      // Get each item
      filteredByLocation.forEach(item => {
        const sameType = this.sizes.find(size => size.id == item.productSizeID);
        if (sameType) {
          let groupedSize = groupedSizes[sameType.id];
          if (!groupedSize) {
            groupedSize = {
              sizeID: sameType.id,
              sizeName: sameType.size,
              last30days: 0,
              daysout1to3: 0,
              daysout4to10: 0,
              daysout11to30: 0,
              daysout31to60: 0,
              daysout61to99: 0,
              daysout100to150: 0,
              visible: true,
              color: this.getRandomColor()
            };
            groupedSizes[sameType.id] = groupedSize;
          }
          if (daysOut <= 30) {
            groupedSize.last30days++;
          }
          if (daysOut >= 1 && daysOut <= 3) {
            groupedSize.daysout1to3++;
          } else if (daysOut >= 4 && daysOut <= 10) {
            groupedSize.daysout4to10++;
          } else if (daysOut >= 11 && daysOut <= 30) {
            groupedSize.daysout11to30++;
          } else if (daysOut >= 31 && daysOut <= 60) {
            groupedSize.daysout31to60++;
          } else if (daysOut >= 61 && daysOut <= 99) {
            groupedSize.daysout61to99++;
          } else if (daysOut >= 100 && daysOut <= 150) {
            groupedSize.daysout100to150++;
          }
        }
      });
    });
    const { totalLast30days,
      totalDaysout1to3,
      totalDaysout4to10,
      totalDaysout11to30,
      totalDaysout31to60,
      totalDaysout61to99,
      totalDaysout100to150 } = this.calculateTotals(groupedSizes);
    groupedSizes = this.calculatePercentage(groupedSizes, totalLast30days, totalDaysout1to3, totalDaysout4to10, totalDaysout11to30, totalDaysout31to60, totalDaysout61to99, totalDaysout100to150);
    this.isLoadingData = false;
    this.sizesProducts = Object.values(groupedSizes);
    this.getGraphics(this.sizesProducts, "size");
    this.chart.update(); //Update chart
  }

  getProducts(sizea) {
    if (sizea) {
      this.sizeName = sizea.sizeName;
      this.sizeID = sizea.sizeID;
    }
    let today = new Date();
    let products = {};
    this.isLoadingData = true;
    let filteredRentals = this.rentals.filter(r => r.cartObj && r.cartObj.items && r.cartObj.items.length > 0);
    // Move through each rental
    filteredRentals.forEach(rental => {
      let daysOut = Math.floor((today.getTime() - new Date(rental.dayStart.seconds * 1000).getTime()) / (1000 * 60 * 60 * 24));
      let filteredByLocation = rental.cartObj.items.filter(item => item.locationID == this.locationID && item.parentId == this.groupID);
      // Get each item
      filteredByLocation.forEach(item => {
        const sameType = this.sizes.find(size => size.id == item.productSizeID);
        if (sameType) {
          let product = products[item.productId];
          if (!product) {
            product = {
              productID: item.productId,
              productName: item.productName,
              last30days: 0,
              daysout1to3: 0,
              daysout4to10: 0,
              daysout11to30: 0,
              daysout31to60: 0,
              daysout61to99: 0,
              daysout100to150: 0,
              visible: true,
              color: this.getRandomColor()
            };
            products[item.productId] = product;
          }
          if (daysOut <= 30) {
            product.last30days++;
          }
          if (daysOut >= 1 && daysOut <= 3) {
            product.daysout1to3++;
          } else if (daysOut >= 4 && daysOut <= 10) {
            product.daysout4to10++;
          } else if (daysOut >= 11 && daysOut <= 30) {
            product.daysout11to30++;
          } else if (daysOut >= 31 && daysOut <= 60) {
            product.daysout31to60++;
          } else if (daysOut >= 61 && daysOut <= 99) {
            product.daysout61to99++;
          } else if (daysOut >= 100 && daysOut <= 150) {
            product.daysout100to150++;
          }
        }
      });
    });
    const { totalLast30days,
      totalDaysout1to3,
      totalDaysout4to10,
      totalDaysout11to30,
      totalDaysout31to60,
      totalDaysout61to99,
      totalDaysout100to150 } = this.calculateTotals(products);
    products = this.calculatePercentage(products, totalLast30days, totalDaysout1to3, totalDaysout4to10, totalDaysout11to30, totalDaysout31to60, totalDaysout61to99, totalDaysout100to150);
    this.isLoadingData = false;
    this.products = Object.values(products);
    this.getGraphics(this.products, "product");
    this.chart.update(); //Update chart
  }

  calculatePercentage(array, totalLast30days, totalDaysout1to3, totalDaysout4to10, totalDaysout11to30, totalDaysout31to60, totalDaysout61to99, totalDaysout100to150) {
    Object.values(array).forEach((value: any) => {
      value.last30daysPercentage = totalLast30days > 0 ? Number(((value.last30days / totalLast30days) * 100).toFixed(2)) : 0;
      value.daysout1to3Percentage = totalDaysout1to3 > 0 ? Number(((value.daysout1to3 / totalDaysout1to3) * 100).toFixed(2)) : 0;
      value.daysout4to10Percentage = totalDaysout4to10 > 0 ? Number(((value.daysout4to10 / totalDaysout4to10) * 100).toFixed(2)) : 0;
      value.daysout11to30Percentage = totalDaysout11to30 > 0 ? Number(((value.daysout11to30 / totalDaysout11to30) * 100).toFixed(2)) : 0;
      value.daysout31to60Percentage = totalDaysout31to60 > 0 ? Number(((value.daysout31to60 / totalDaysout31to60) * 100).toFixed(2)) : 0;
      value.daysout61to99Percentage = totalDaysout61to99 > 0 ? Number(((value.daysout61to99 / totalDaysout61to99) * 100).toFixed(2)) : 0;
      value.daysout100to150Percentage = totalDaysout100to150 > 0 ? Number(((value.daysout100to150 / totalDaysout100to150) * 100).toFixed(2)) : 0;
    });
    return array
  }
  calculateTotals(array) {
    console.log(array)
    let totals = {
      totalLast30days: 0,
      totalDaysout1to3: 0,
      totalDaysout4to10: 0,
      totalDaysout11to30: 0,
      totalDaysout31to60: 0,
      totalDaysout61to99: 0,
      totalDaysout100to150: 0
    };

    Object.values(array).forEach((item: any) => {
      totals.totalLast30days += item.last30days;
      totals.totalDaysout1to3 += item.daysout1to3;
      totals.totalDaysout4to10 += item.daysout4to10;
      totals.totalDaysout11to30 += item.daysout11to30;
      totals.totalDaysout31to60 += item.daysout31to60;
      totals.totalDaysout61to99 += item.daysout61to99;
      totals.totalDaysout100to150 += item.daysout100to150;
    });

    return totals;
  }
  changeData(view: string, data?) {
    this.isLoadingData = true;
    if (view == 'Locations') {
      //Set View
      this.isLocation = true;
      this.isGroup = false;
      this.isSizeType = false;
      this.isSize = false;
      this.isProduct = false;

      //Set bread
      this.breadlocation = true;
      this.breadGroups = false;
      this.breadSizeType = false;
      this.breadSize = false;
      this.breadProduct = false;
      this.getLocations();
    } else if (view == "Groups") {
      //Set View
      this.isLocation = false;
      this.isGroup = true;
      this.isSizeType = false;
      this.isSize = false;
      this.isProduct = false;

      //Set bread
      this.breadlocation = true;
      this.breadGroups = true;
      this.breadSizeType = false;
      this.breadSize = false;
      this.breadProduct = false;
      this.getByGroup(data)
    } else if (view == "sizeType") {
      //Set View
      this.isLocation = false;
      this.isGroup = false;
      this.isSizeType = true;
      this.isSize = false;
      this.isProduct = false;

      //Set bread
      this.breadlocation = true;
      this.breadGroups = true;
      this.breadSizeType = true;
      this.breadSize = false;
      this.breadProduct = false;
      this.getBySizeType(data);
    } else if (view == "size") {
      //Set View
      this.isLocation = false;
      this.isGroup = false;
      this.isSizeType = false;
      this.isSize = true;
      this.isProduct = false;

      //Set bread
      this.breadlocation = true;
      this.breadGroups = true;
      this.breadSizeType = true;
      this.breadSize = true;
      this.breadProduct = false;
      this.getBySize(data);
    } else if (view == "product") {
      //Set View
      this.isLocation = false;
      this.isGroup = false;
      this.isSizeType = false;
      this.isSize = false;
      this.isProduct = true;

      //Set bread
      this.breadlocation = true;
      this.breadGroups = true;
      this.breadSizeType = true;
      this.breadSize = true;
      this.breadProduct = true;
      this.getProducts(data);
    }
  }
  getGraphics(data, type) {
    let label = ""

    this.lineSalesData.datasets = [];
    data.forEach((data, index) => {
      if (type == "location") {
        label = data.locationName;
      } else if (type == "group") {
        label = data.groupName;
      } else if (type == "sizeType") {
        label = data.sizeType;
      } else if (type == "size") {
        label = data.sizeName;
      } else {
        label = data.productName;
      }
      let arraydata = [data.last30daysPercentage,
      data.daysout1to3Percentage,
      data.daysout4to10Percentage,
      data.daysout11to30Percentage,
      data.daysout31to60Percentage,
      data.daysout61to99Percentage,
      data.daysout100to150Percentage];
      //Method to put data in chart
      this.lineSalesData.datasets.push({
        type: 'line',
        data: arraydata, //Array with data
        label: label, //Name of the data
        backgroundColor: 'rgba(0, 0, 0,0)', //Background of the graphic line
        borderColor: data.color, //Color of the graphic line
        pointBackgroundColor: data.color, ////Color of the point in the graphic line
        pointBorderColor: '#fff', //Border color of the point
        pointHoverBackgroundColor: '#fff', //Color hover in the point
        pointHoverBorderColor: data.color + "25", //Border color hover of the point
        fill: 'false', //Dont fill the chart
      });
      this.chart.update(); //Update chart
    })

    this.chart.update(); //Update chart
  }
  public lineSalesData: ChartConfiguration['data'] = {
    //data for the lineChart
    datasets: [], //array to obtain the data
    labels: ['Last 30', '1-3', '4-10', '11-30', '31-60', '61-99', '100-150'], //labels with all days
  };
  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.3,
        borderWidth: 4,
      },
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      x: {},
      'y-axis-0': {
        position: 'left',
        max: 100
      },
    },
    plugins: {
      legend: { display: false },
    },
  };
  public lineChartType: ChartType = 'line';
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  // events
  public chartClicked({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: {}[];
  }): void {
    //console.log(event, active);
  }

  public chartHovered({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: {}[];
  }): void {
    //console.log(event, active);
  }

  public hideOne(): void {
    const isHidden = this.chart?.isDatasetHidden(1);
    this.chart?.hideDataset(1, !isHidden);
  }

  public changeColor(): void {
    this.lineSalesData.datasets[2].borderColor = 'red';
    this.lineSalesData.datasets[2].backgroundColor = `rgba(0, 255, 0, 0.3)`;

    this.chart?.update();
  }

  hideOneLine(type: string, index: number, event: Event) {
    event.stopPropagation(); //Helps to only execute this function
    if (type == "group") {
      this.groupsProducts[index].visible = false;
    } else if (type == "location") {
      this.locationProducts[index].visible = false;
    } else if (type == "sizeType") {
      this.sizeTypesProducts[index].visible = false;
    } else if (type == "size") {
      this.sizesProducts[index].visible = false;
    } else {
      this.products[index].visible = false;
    }
    // Hide all datasets except se one selected
    this.lineSalesData.datasets[index].hidden = true;
    // Update Chart
    this.chart.update();
  }

  showOneLine(type: string, index: number, event: Event) {
    event.stopPropagation(); //Helps to only execute this function
    if (type == "group") {
      this.groupsProducts[index].visible = true;
    } else if (type == "location") {
      this.locationProducts[index].visible = true;
    } else if (type == "sizeType") {
      this.sizeTypesProducts[index].visible = true;
    } else if (type == "size") {
      this.sizesProducts[index].visible = true;
    } else {
      this.products[index].visible = true;
    }
    // Show all datasets datasets
    this.lineSalesData.datasets[index].hidden = false;
    // Update Chart
    this.chart.update();
  }


  random(min, max) {
    let possible = max - min;
    let random = Math.random() * possible;
    random = Math.floor(random);
    return parseInt(min) + random;
  }
  getRandomColor() {
    let hexadecimal = new Array("0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F")
    let random_color = "#";
    for (let i = 0; i < 6; i++) {
      let posarray = this.random(0, hexadecimal.length)
      random_color += hexadecimal[posarray]
    }
    return random_color
  }
  public changeLabel(): void {
    if (this.lineSalesData.labels) {
      this.lineSalesData.labels[2] = ['1st Line', '2nd Line'];
    }
    this.chart?.update();
  }
}
