<form class="needs-validations" #groupf="ngForm" [formGroup]="affiliateForm" (ngSubmit)="onSubmit()">

  <!-- name -->
  <div class="row">
    <div class="form-group-sm mb-2 input-group-sm">
      <label class="form-check-label" for="pageTitle">Affiliate Name</label>
      <span class="text-danger">*</span> <!-- Required Symbl -->
      <input class="form-control" type="text" name="name" id="name" formControlName="name">
    </div>
    <span class="text-danger mb-2"
      *ngIf="affiliateForm.get('name').errors?.required && affiliateForm?.get('name').touched">
      All affiliates must have a name.
    </span>
  </div>

  <!-- landingURI -->
  <div class="row">
    <div class="form-group-sm mb-2 input-group-sm">
      <label class="form-check-label" for="pageTitle">Landing URI</label>
      <input class="form-control" type="text" name="landingURI" id="landingURI"
        placeholder="Examples: rentals/bikes, greatOutdoors.html, etc" formControlName="landingURI">
      <div class="form-text">
        The landingURI should be the path or route you would like the user to be directed to upon clicking the affiliate
        link.
        Example: www.fleetmaid.com/<b>rentals</b>
      </div>
    </div>
    <span class="text-danger mb-2" *ngIf="affiliateForm.get('landingURI').errors?.invalidURIFormat">
      Please provide a valid landing URI. Ex: rentals/bikes, greatOutdoors.html, etc.
    </span>
    <span class="text-danger" *ngIf="affiliateForm.get('landingURI').errors?.queryParamsPresent">
      The landing URI can not contain symbols such as '?'.
    </span>
    <span class="text-danger" *ngIf="affiliateForm.get('landingURI').errors?.firstCharAlphanumeric">
      The landing URI must start with an alphanumeric character. Symbols such as "?", "/", spaces, etc, are not allowed.
    </span>
    <span class="text-danger"
      *ngIf="affiliateForm.get('landingURI').errors?.tooManyConsecutiveSlashes && affiliateForm?.get('landingURI')">
      The landingURI must not have consecutive slashes. Please remove any unnecessary slashes.
    </span>
  </div>

  <!-- active -->
  <div class="row">
    <div>
      <span>Status</span>
      <span class="text-danger">*</span> <!-- Required Symbl -->
    </div>
    <div class="form-group-sm mb-2 input-group-sm">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" formControlName="active" [value]="true" id="activeTrue">
        <label class="form-check-label" for="activeTrue">Active</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" formControlName="active" [value]="false" id="activeFalse">
        <label class="form-check-label" for="activeFalse">Disabled</label>
      </div>
    </div>
  </div>

  <!-- Buttons -->
  <div class="container-xxl">
    <div class="row justify-content-center mt-2">
      <div class="text-center">
        <button type="submit" class="btn btn-success" [disabled]="affiliateForm.invalid || awaitingDB">
          <span>{{isEditing ? 'Save' : 'Create'}}</span>
          <span *ngIf="awaitingDB" class="spinner-grow spinner-grow-sm ml-1" role="active" aria-hidden="true"></span>
        </button>
      </div>
    </div>
  </div>
</form>