import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import 'firebase/compat/storage';
import { AngularFireStorage } from '@angular/fire/compat/storage';

// Models
import { Rental } from 'src/app/models/storage/rental.model';
import { ProductV1 } from 'src/app/models/storage/product.model';

// Services
import { CurrentUserService } from './current-user.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  public date = new Date();//Obtain the actual date
  public startYear: any = new Date(this.date.getFullYear(), 1, 1); ////Variable to save the last date of the year
  public endYear: any = new Date(this.date.getFullYear(), 12, 0); //Variable to save the last date of the year
  public ago150: any = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - 150);
  public today: any = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
  public tomorrow: any = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() + 1);
  constructor(private firestore: AngularFirestore,
    private currentUserService: CurrentUserService,
    private storage: AngularFireStorage
  ) { }

  getRentals(): Observable<any> { //Method to obtain the rentals
    return this.firestore.collection('rentals', ref =>
      ref.orderBy('dayStart') //Order by dayStart Asc
        .where('companyID', '==', this.currentUserService.currentUser.companyId)
        .where('dayStart', '<=', this.endYear)//Condition to get the rentals on this year
        .where('dayStart', '>=', this.startYear)
        .where('isConfirmed', '==', true)
    ).snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((data) => {
            let categories = data.payload.doc.data() as any;
            categories.id = data.payload.doc.id;
            return categories;
          });
        })
      );
  }

  getProductsRepairs(): Observable<any> {//Method to obtain the products in repair
    return this.firestore.collection('products', ref =>
      ref.where('isMaintenance', '==', true)//If maintenance is true
        .where('companyID', '==', this.currentUserService.currentUser.companyId)
    ).snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((data) => {
            let categories = data.payload.doc.data() as any;
            categories.id = data.payload.doc.id;
            return categories;
          });
        })
      );
  }

  getSells(): Observable<any> {//Method to obtain all sells
    return this.firestore.collection('pdfPayments', ref =>
      ref.where('companyID', '==', this.currentUserService.currentUser.companyId)
    ).snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((data) => {
            let categories = data.payload.doc.data() as any;
            categories.id = data.payload.doc.id;
            return categories;
          });
        })
      );
  }

  getRentals150(): Observable<any> {
    console.log(this.today)
    return this.firestore.collection('rentals', ref =>
      ref.where('companyID', '==', this.currentUserService.currentUser.companyId)
        .where('dayStart', '>=', this.ago150)
        .where('dayStart', '<=', this.tomorrow)
        .where('isConfirmed', '==', true)
    ).snapshotChanges().pipe(map(changes => {
      return changes.map(action => {
        let data = action.payload.doc.data() as any
        data.id = action.payload.doc.id
        return data
      })
    }));
  }
  getProductsByID(productID: any): Observable<ProductV1[]> {
    return this.firestore.collection('products', ref =>
      ref.where('id', '==', productID)//If maintenance is true
    ).snapshotChanges().pipe(map(changes => {
      return changes.map(action => {
        let data = action.payload.doc.data() as ProductV1
        data.id = action.payload.doc.id
        return data
      })
    }));
  }
  getProductsByIDPromise(productID: any): Promise<any> {

    return this.firestore.collection('products').doc(productID).ref.get().then(doc => {
      const data = doc.data() as any;
      console.log(productID)
      console.log(data)
      data.id = doc.id
      return data;
    })
  }
  getRentalsMonth(startMonth, endMonth): Promise<Rental[]> {
    console.log("EndMonth", endMonth)
    let startMonthLessMonth = new Date(startMonth.getFullYear(), startMonth.getMonth() - 1, startMonth.getDate())
    let query = this.firestore.collection('rentals').ref
      .where('dayStart', '>=', startMonthLessMonth)
      .where('dayStart', '<=', endMonth)
      .where('isConfirmed', '==', true)
      .where('isCancelled', '==', false)
      .where('companyID', '==', this.currentUserService.currentUser.companyId).orderBy("dayStart", "asc")
    return query.get().then(docs => {
      return docs.docs.map(doc => {
        const data = doc.data() as any;
        data.id = doc.id
        return data;
      })
    })
  }
  getAllProducts(startMonth, endMonth): Promise<ProductV1[]> {
    let query = this.firestore.collection('products').ref.where('isActive', '==', true).where('companyID', '==', this.currentUserService.currentUser.companyId)
    return query.get().then(docs => {
      return docs.docs.map(doc => {
        const data = doc.data() as any;
        data.id = doc.id
        return data;
      })
    })
  }
  getAllCategories(): Promise<any[]> {
    let query = this.firestore.collection('rentalCategories').ref.where('companyID', '==', this.currentUserService.currentUser.companyId)
    return query.get().then(docs => {
      return docs.docs.map(doc => {
        const data = doc.data() as any;
        data.id = doc.id
        return data;
      })
    })
  }

  getAllGroups(): Promise<any[]> {
    let query = this.firestore.collection('productGroup').ref.where(
      'companyID',
      '==',
      this.currentUserService.currentUser.companyId
    )
      .where('isActive', '==', true)
      .orderBy('groupName', 'asc')
    return query.get().then(docs => {
      return docs.docs.map(doc => {
        const data = doc.data() as any;
        data.id = doc.id
        return data;
      })
    })
  }

  async uploadPDF(company: any, document: any, documentname) {
    try {
      let response = await this.storage.ref("companies/" + company.id + "/pdfWaiver/" + documentname).putString(document, "data_url");
      return response.ref.getDownloadURL();
    } catch (err) {
      return null;
    }
  }
  async uploadPDFWaiver(company: any, waiverid: any, document: any, documentname) {
    try {
      let response = await this.storage.ref("companies/" + company.id + "/waiver/" + waiverid + "/pdfWaiver/" + documentname).putString(document, "data_url");
      return response.ref.getDownloadURL();
    } catch (err) {
      return null;
    }
  }
}
