import { Injectable } from '@angular/core';

import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProductLocation } from 'src/app/models/storage/product-location.model';

@Injectable({
  providedIn: 'root',
})
export class ProductLocationService {
  private productLocationCollection: AngularFirestoreCollection<ProductLocation>;
  productLocation: Observable<ProductLocation[]>;

  private productsCollection: AngularFirestoreCollection<any>;
  private companiesCollection: AngularFirestoreCollection<any>;

  constructor(private afs: AngularFirestore) {
    this.productLocationCollection =
      this.afs.collection<ProductLocation>('productLocations');

    this.productsCollection = this.afs.collection<any>('products');
    this.companiesCollection = this.afs.collection<any>('companies');
  }

  addProductLocation(productLocation: ProductLocation) {
    return new Promise((resolve, reject) => {
      this.productLocationCollection
        .add(productLocation)
        .then((resp) => {
          resolve({ success: true, id: resp.id });
        })
        .catch((err) => reject(err));
    });
  }

  getAllProductsLocations(companyId: string) {
    return this.afs
      .collection('productLocations', (ref) =>
        ref.where('companyID', '==', companyId).where('isActive', '==', true)
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((action) => {
            let data = action.payload.doc.data() as ProductLocation;
            data.id = action.payload.doc.id;
            return data;
          });
        })
      );
  }

  getAllProductsLocationsRebuild(companyId: string) {
    return this.afs
      .collection('productLocations', ref =>
        ref.where('companyID', '==', companyId).where('isActive', '==', true)
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((action) => {
            let data = action.payload.doc.data() as ProductLocation;
            data.id = action.payload.doc.id;
            return data;
          });
        })
      );
  }

  getCompanyDefaultLocation(companyId: string): Promise<any> {
    return lastValueFrom(this.afs.collection('productLocations', (ref) =>
      ref.where('companyID', '==', companyId).where('isDefault', '==', true)
    ).get()).then((querySnapshot) => {
      if (querySnapshot.empty) {
        return null;
      }
      else {
        const data = querySnapshot.docs[0].data() as any;;
        data.id = querySnapshot.docs[0].id;
        return data;
      }
    });
  }

  getLocationByID(locationID: string): Promise<ProductLocation | null> {
    return this.afs
      .collection('productLocations')
      .doc(locationID)
      .ref.get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data() as ProductLocation;
          data.id = doc.id;
          return data;
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.error(`Error fetching addon with ID ${locationID}: ${error}`);
        return null;
      });
  }

  getCompanyLocationDefaultByID(locationID: string): Promise<any> {
    return this.afs
      .collection('productLocations')
      .doc(locationID)
      .ref.get()
      .then((doc) => {
        const data = doc.data() as any;
        data.id = doc.id;
        return data;
      });
  }

  getAllProducts(companyId): Promise<any[]> {
    let query = this.afs
      .collection('products')
      .ref.where('companyID', '==', companyId);
    return query.get().then((docs) => {
      return docs.docs.map((doc) => {
        const data = doc.data() as any;
        data.id = doc.id;
        return data;
      });
    });
  }

  editProductsLocations(id: string, data: any) {
    return new Promise((resolve, reject) => {
      this.productLocationCollection
        .doc(id)
        .update(data)
        .then((resp) => {
          resolve({ success: true, id: resp });
        })
        .catch((err) => reject(err));
    });
  }

  editProducts(id: string, data: any) {
    return new Promise((resolve, reject) => {
      this.productsCollection
        .doc(id)
        .update(data)
        .then((resp) => {
          resolve({ success: true, id: resp });
        })
        .catch((err) => reject(err));
    });
  }

  editCompanies(id: string, defaultLocation: string) {
    return new Promise((resolve, reject) => {
      this.companiesCollection
        .doc(id)
        .update({ defaultLocation })
        .then((resp) => {
          resolve({ success: true, id: resp });
        })
        .catch((err) => reject(err));
    });
  }

  deleteProductsLocations(id: string) {
    return new Promise((resolve, reject) => {
      this.productLocationCollection
        .doc(id)
        .update({ isActive: false })
        .then(() => {
          resolve({ success: true });
        })
        .catch((err) => reject(err));
    });
  }

  getAllProductLocationsObservable(companyID: string): Observable<any> { // subscribe to bookingPage collection updates - by companyID
    // return this.afs.collection('productLocations', ref => ref
    // .where('companyID', '==', companyID)
    // .where('isActive', '==', true))
    //   .snapshotChanges()
    //   .pipe(map(changes => {
    //     return changes.map(action => {
    //       let data = action.payload.doc.data()
    //       data['id'] = action.payload.doc.id
    //       return data
    //     })
    //   }));

    return this.afs.collection('productLocations', ref => ref
      .where('companyID', '==', companyID)
      .where('isActive', '==', true))
      .snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          let data = action.payload.doc.data()
          data['id'] = action.payload.doc.id
          return data
        })
      }));
  }

  // Subscribes to both active & non active product locations. Used on the booking page (where locations should be displayed regardless of their active / soft delete status)
  getActiveAndNotActiveProductLocationsObservable(companyID: string): Observable<any> {
    return this.afs.collection('productLocations', ref => ref
      .where('companyID', '==', companyID))
      .snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          let data = action.payload.doc.data()
          data['id'] = action.payload.doc.id
          return data
        })
      }));
  }
}
