import { Pipe, PipeTransform } from '@angular/core';
import { PricingService } from 'src/app/services/pricing.service';

@Pipe({
  name: 'getIndividualRentalItemPrice'
})

export class GetIndividualRentalItemPricePipe implements PipeTransform {

  constructor(private pricingService: PricingService){
    //
  }

  transform(daySpan, selectedHours, productGroupInfo): unknown {
    console.log(this.pricingService.calculateIndividualRentalPrice(daySpan, selectedHours, productGroupInfo))
    return this.pricingService.calculateIndividualRentalPrice(daySpan, selectedHours, productGroupInfo).rentalPrice;
  }

}
