<div *ngIf="!isDataAvailable" class="text-center justify-content-center align-items-center page-container" style="margin-top: 50px;">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div
  *ngIf="isDataAvailable && !customerView"
  class="bg-light"
  style="margin-bottom: 10px">
  <!------------------------------- Widget Display ----------------------------->
  <ng-template #recursiveList let-elements>
    <div dndPlaceholderRef class="dndPlaceholder"></div>

    <div
      *ngFor="let item of elements; let i = index"
      class="selected-component align-middle"
      style="margin: 15px 10px"
      [ngClass]="{ 'is-selected': item.isSelected }"
      dndEffectAllowed="move"
      (click)="setSelectedComponent($event, item)"
      [dndDraggable]="item"
      (dndStart)="onDragStart($event)"
      (dndCopied)="onDragged(item, elements, 'copy')"
      (dndLinked)="onDragged(item, elements, 'link')"
      (dndMoved)="onDragged(item, elements, 'move')"
      (dndCanceled)="onDragged(item, elements, 'none')"
      (dndEnd)="onDragEnd($event)">
        <div class="align-middle">

          <!--------Widget Display------->

          <app-inventory-widget-display
            [widget]="item"
            [editMode]="true"
            ></app-inventory-widget-display>
        </div>
        <div class="drag-handler rounded-circle" dndHandle>
          <i class="fa fa-arrows"></i>
        </div>
        <div dndDragImageRef class="my_placeholder"></div>

        <div *ngIf="item.isSelected" class="container-action-wrapper">
          <button
            class="btn btn-sm btn-danger"
            placement="bottom"
            (click)="deleteWidget($event, item.id)">
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
  </ng-template>


  <!--------------------------- Cart Widget Display ---------------------------->
  <ng-template #cartRecursiveList let-elements>
    <div dndPlaceholderRef class="dndPlaceholder"></div>

    <div
      *ngFor="let item of elements; let i = index"
      class="selected-component align-middle"
      style="margin: 15px 10px"
      [ngClass]="{ 'is-selected': item.isSelected }"
      dndEffectAllowed="move"
      (click)="setSelectedComponent($event, item)"
      [dndDraggable]="item"
      (dndStart)="onDragStart($event)"
      (dndCopied)="onDragged(item, elements, 'copy')"
      (dndLinked)="onDragged(item, elements, 'link')"
      (dndMoved)="onDragged(item, elements, 'move')"
      (dndCanceled)="onDragged(item, elements, 'none')"
      (dndEnd)="onDragEnd($event)">

      <div class="align-middle">

        <!--------Widget Display------->

        <app-inventory-widget-display
          [widget]="item"
          [editMode]="true"
          ></app-inventory-widget-display>
      </div>
        <div class="drag-handler rounded-circle" dndHandle>
          <i class="fa fa-arrows"></i>
        </div>
        <div dndDragImageRef class="my_placeholder"></div>

        <div *ngIf="item.isSelected" class="container-action-wrapper">
          <button
            class="btn btn-sm btn-danger"
            placement="bottom"
            (click)="deleteWidget($event, item.id)">
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
  </ng-template>

  <!----- Left side of the screen where the widget editor is displayed ----->



  <section>
    <div class="p-0 rounded" id="form-builder-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-2" style="padding-top: 8px"> 


            <div class="panel-elements sticky-top">


              <div class="email-structure ">
   
                <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab"  class="nav-tabs">
                  <li [ngbNavItem]="1" id="tab-current-element">
                    <a ngbNavLink translate>New</a>
                    <ng-template ngbNavContent>
                      <div 
                      class="component rounded"
                      *ngFor="let component of widgetStructureList"
                      [dndDraggable]="component"
                      (dndStart)="onDragStart($event)"
                      (dndCopied)="onDragged(component, component.addedWidgets, 'copy')"
                      (dndLinked)="onDragged(component, component.addedWidgets, 'link')"
                      (dndMoved)="onDragged(component, component.addedWidgets, 'move')"
                      (dndCanceled)="onDragged(component, component.addedWidgets, 'none')"
                      (dndEnd)="onDragEnd($event)">
                      <p><i [class]="component.iconClass"></i></p>
                      <span>{{ component.name }}</span>
                    </div>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="2" id="tab-form-design">
                    <a ngbNavLink translate>Saved</a>
                    <ng-template ngbNavContent>
                      <div 
                      class="component rounded"
                      *ngFor="let component of saveWidgetStructureList"
                      [dndDraggable]="component"
                      (dndStart)="onDragStart($event)"
                      (dndCopied)="onDragged(component, component.addedWidgets, 'copy')"
                      (dndLinked)="onDragged(component, component.addedWidgets, 'link')"
                      (dndMoved)="onDragged(component, component.addedWidgets, 'move')"
                      (dndCanceled)="onDragged(component, component.addedWidgets, 'none')"
                      (dndEnd)="onDragEnd($event)">
                      <p><i [class]="component.iconClass"></i></p>
                      <span>{{ component.widgetName }}</span>
                    </div>                    </ng-template>
                  </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
  
          </div>
        </div>
        </div>



          <!--- Informational for bttom of widget scren-->
            <!-- <div
            class="alert alert-primary"
            style="text-align: left; margin-top: 10px;"
            *ngIf="!isAlertClosed && !selectedComponent"
              >
            <i
              class="fa fa-window-close pull-right fa-2x"
              aria-hidden="true"
              style="margin-left: 1.3em !important"
              (click)="closeAlert()"
            ></i>
            Click a widget type and then drag a widget by name to the open area to start building.
            </div>
            <div *ngIf="selectedComponent && selectedComponent.widgetType == 'product'">
              <div
              class="alert alert-primary"
              style="text-align: left; margin-top: 10px;"
              *ngIf="!isAlertClosed"
                >
              <i
                class="fa fa-window-close pull-right fa-2x"
                aria-hidden="true"
                style="margin-left: 1.3em !important"
                (click)="closeAlert()"
                ></i>
                This widget is tied to the product selected. It will affect the product's availability based on customer selection 
                and the max quanity allowed for checkout will be affected by the availability of the product.
              </div>
            </div>
            <div *ngIf="selectedComponent && selectedComponent.widgetType !== 'product'">
              <div
              class="alert alert-primary"
              style="text-align: left; margin-top: 10px;"
              *ngIf="!isAlertClosed"
                >
              <i
                class="fa fa-window-close pull-right fa-2x"
                aria-hidden="true"
                style="margin-left: 1.3em !important"
                (click)="closeAlert()"
                ></i>
                This widget is stricly informational, it will not affect availability on any items.
              </div>
            </div> -->
      

        <!------------------------------- Builder View Display ---------------------------------------->
        <div class="col-7">
        <span (click)="changeComponent()">
          
            <div class="form-body" style="padding-top: 8px;">
              
              <div class="sticky-top align-middle">
                <div class="top-bar">
                  <div>
                    <button 
                      class="btn btn-secondary" 
                      style="margin-right: 10px" 
                      (click)="onGoBack()">
                      <i class="fas fa-arrow-left" aria-hidden="true"></i> Back
                    </button>
                  </div>
                    
                    <div>
                      <h2 style="font-weight:400; letter-spacing: 1px;">{{newInventoryPage?.title}}</h2>
                    </div>
                  <div>
                    <button class="btn btn-primary" (click)="onSavePage()">Save Page</button>
                  </div>

                </div>
            
              </div>
              <div style="margin-top: 2rem">
                <div class="email-body content-droppable-zone bg-light dndList align-items-start">
                  <h2 
                  style="font-size: 18px;" 
                  data-toggle="tooltip"
                  title="These widgets will be displayed on the product groups inventory page.
                              They will appear for each product selected."
                  data-placement="bottom"
                  >Inventory Page Widgets</h2>
           
                  <div
                    class="form-container mx-auto" style="margin-bottom: 1rem"
                    dndDropzone
                    (dndDrop)="onDrop($event, inventoryPage.widgetList, false)">

                    <div *ngIf="inventoryPage.widgetList.length < 1" class="text-center">
                      <p>Drop widgets here</p>
                    </div>

                    <ng-container
                      *ngTemplateOutlet="
                        recursiveList;
                        context: {
                          $implicit: filterWidgetList(inventoryPage.widgetList, false)
                        }
                      "></ng-container>
                  </div>
                </div>
              </div>


              <!----- Cart widgets -->
              <div style="margin-top: 3rem; margin-bottom: 3rem;">
                <div class="email-body content-droppable-zone bg-light dndList align-items-start">
                  <h2 style="font-size: 18px;"
                  data-toggle="tooltip"
                  title="These widgets will be displayed once when a customer is checking out."
                  data-placement="bottom"
                  >Cart Widgets</h2>
                  <div
                    class="form-container mx-auto" style="margin-bottom: 1rem"
                    dndDropzone
                    (dndDrop)="onDrop($event, inventoryPage.widgetList, true)">

                    <div *ngIf="inventoryPage.widgetList.length < 1" class="text-center">
                      <p>Drop widgets here</p>
                    </div>

                    <ng-container
                      *ngTemplateOutlet="
                        cartRecursiveList;
                        context: {
                          $implicit: filterWidgetList(inventoryPage.widgetList, true)
                        }
                      "></ng-container>
                  </div>
                </div>
              </div>

            </div>
        </span>
      </div>

        <!---------Widget Editor Right side--------->
        <div class="col-3" style="padding-top: 8px">
          <div class="panel-options property-column sticky-top">
            <div class="property-column-inner">
              <div>
                <div
                  style="
                    
                    justify-content: center;
                    align-items: center;
                    margin: 0px;
                    margin-top: 1rem;
                  "
                  class="text-center">
                  <div *ngIf="!selectedComponent && newInventoryPage.widgetList.length < 1">
                  <h3>Drag a widget and drop it in open area to start building!</h3>
                </div>
                <div *ngIf="!selectedComponent && newInventoryPage.widgetList.length > 0">
                  <h3>No widgets selected for editing</h3>
                </div>
                <div *ngIf="selectedComponent">
                  <h3
                  style="
                    margin: 0px;
                    font-weight: bold;
                    letter-spacing: 1px;
                    font-size: 16px;
                  ">
                  Widget Editor
                </h3>
                </div>
         
                </div>
                <div *ngIf="editWidget">
                  <hr style="margin-top: 5px"/>
                  <div *ngIf="selectedComponent['savedWidgetId']" class="text-center">
                    <div style="margin: 8px 0px">{{selectedComponent['widgetName']}}- {{selectedComponent.widgetType}} widget</div>
                  </div>
                  <!-- <ng-template #notSavedWidget>{{selectedComponent['widgetName']}} {{selectedComponent['widgetType']}}</ng-template> -->
                  <div>
                    <app-inventory-widget-editor
                      [component]="selectedComponent"
                      [widgetStructureList]="widgetStructureList"
                      (onOpenSavedWidgetModal)="onOpenSavedWidgetModal()">
                    </app-inventory-widget-editor>
                  </div>
                </div>

              </div>

            </div>

          </div>
      </div>
      
      </div>
    </div>
  </div>
  </section>
</div>

  <!-- Create Template Modal -->
  <div class="modal" data-bs-keyboard="false" id="createTemplateModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            Save Widget
          </h4>
          <button type="button" class="btn btn-danger" (click)="closeTemplateModal()" aria-label="Close">
            <i class="fa fa-close"></i>
          </button>
        </div>

        <div class="modal-body">
          <!-- title -->
          <div class="row">
            <div class="form-group-sm mb-2 input-group-sm">
              <label class="form-check-label">Widget Name</label>
              <input type="text" [(ngModel)]="formName" class="form-control form-control-sm">
            </div>
          </div>

          <!-- Buttons -->
          <div class="container-xxl">
            <div class="row justify-content-center mt-2">
              <div class="text-center">
                <button type="submit" class="btn btn-success" (click)="onSaveWidget()">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>