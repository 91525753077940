<div class="container-fluid">
  <div class="row justify-content-center mt-2">
    <div class="col-md-8 text-left">
      <h1 class="mt-2 mb-2" style="font-size: 32px">
        Waiver Editor
        <button
          [style.background]="colorText"
          [style.color]="colorTheme"
          class="btn"
          (click)="newWaiver()"
          *ngIf="multipleWaivers"
        >
          <i class="fas fa-plus"></i> Add new Waiver
        </button>
      </h1>

      <div *ngIf="hidebuttons" class="col-12 text-left">
        <span class="text-secondary">Smartwaiver Enabled</span>
      </div>
    </div>
    <div class="col-md-4 justify-content-end align-items-end pb-3 pt-2">
      <a
        [style.background]="colorText"
        [style.color]="colorTheme"
        href="/waiver/company{{ company.id }}"
        target="_blank"
        class="btn"
        style="float: right"
        ><i class="fas fa-file-contract"></i> Preview Waiver</a
      >
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-6">
      <div *ngIf="!hidebuttons" class="form-check form-switch">
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          name="multipleWaivers"
          (change)="changeWaivers()"
          [(ngModel)]="multipleWaivers"
        />
        <label class="form-check-label" for="flexSwitchCheckDefault"
          >Allow multiple waivers</label
        >
      </div>
    </div>
    <div class="col-6 text-right">
      <div
        *ngIf="!hidebuttons && !companymultiplewaivers"
        class="form-check form-switch"
      >
        <label class="form-check-label me-5" for="flexSwitchCheckDefault"
          >Multiple Signature (when more than one signature is required)</label
        >
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          name="multipleSignature"
          (change)="changeMultiSignature()"
          [(ngModel)]="multipleSignature"
        />
      </div>
    </div>
  </div>
</div>
<!--<div class="container-fluid">
    <div class="row  align-items-start mt-2">
        <div class="col-md-6 text-left">
            <h1 class="mt-2 mb-2">Waiver Editor</h1>
        </div>
        <div class="col-md-6 text-right">
            <div style="display: inline-block;" class="form-control-lg mb-3">
                <a href="/waiver/company{{company.id}}"  target="_blank" class="btn btn-primary" style="float:right;"><i class="fas fa-file-contract"></i> Preview Waiver</a>
            </div>
        </div>
    </div>
</div>-->
<div class="container-fluid me-2 mt-2" *ngIf="!companymultiplewaivers">
  <ckeditor
    *ngIf="company.isSmartWaiver != true && !company.waiverPDF"
    [editor]="editor"
    [config]="editorConfig"
    [(ngModel)]="model.editorData"
    (change)="onChangeWaiver($event)"
  ></ckeditor>
  <iframe
    class="container-fluid"
    *ngIf="
      company.isSmartWaiver != true &&
      company.waiverPDF &&
      company.pdfContent &&
      pdfEditor
    "
    [src]="pdfEditor"
    height="460"
    style="overflow: hidden"
    frameborder="0"
  ></iframe>
  <iframe
    class="container-fluid"
    *ngIf="company.isSmartWaiver && company.smartWaiverLink"
    [src]="smartWaiver"
    name="page"
    height="460"
    frameborder="0"
  ></iframe>

  <div
    *ngIf="company.waiverPDF && !company.pdfContent"
    class="text-secondary text-center justify-content-center align-items-center d-flex"
    style="height: 440px"
  >
    <span class="fs-3"
      >You have not uploaded any PDF, upload one right now</span
    >
  </div>
  <div
    *ngIf="
      company.isSmartWaiver &&
      (!company.smartWaiverLink || company.smartWaiverLink == '')
    "
    class="text-secondary text-center justify-content-center align-items-center d-flex"
    style="height: 440px"
  >
    <span class="fs-3"
      >You have not uploaded any SmartWaiver, write one right now</span
    >
  </div>
</div>
<div
  class="row mt-2"
  *ngIf="!companymultiplewaivers && companySmartWaiver != true"
>
  <div class="col-8 text-left align-items-start">
    <button
      *ngIf="!company.waiverPDF"
      class="btn btn-danger me-3 ms-4"
      (click)="uploadPDFWaiver()"
    >
      <i class="fas fa-file-pdf"></i> Add a PDF instead
    </button>
    <button
      *ngIf="company.waiverPDF"
      class="btn btn-warning me-3 ms-4"
      (click)="changeWaiver()"
    >
      <i class="fas fa-file-signature"></i> Write a Waiver instead
    </button>
    <button
      *ngIf="company.isSmartWaiver != undefined"
      [style.background]="colorText"
      [style.color]="colorTheme"
      class="btn me-1 ms-1"
      (click)="smartWaiverChange()"
    >
      <i class="fas fa-signature"></i> Integrate Smart Waiver
    </button>
  </div>
  <div *ngIf="!company.waiverPDF" class="col-4 text-right align-items-end">
    <button
      type="button"
      class="btn ms-2 me-4"
      style="float: right"
      [disabled]="savebtn"
      (click)="saveWaiver()"
      [style.background]="colorText"
      [style.color]="colorTheme"
    >
      Save Changes
    </button>
    <button
      type="button"
      class="btn btn-secondary"
      style="float: right"
      (click)="restoreWaiver()"
      [disabled]="cancelbtn"
    >
      Cancel
    </button>
  </div>
  <div *ngIf="company.waiverPDF" class="col-4 text-right align-items-end">
    <input
      class="form-control"
      type="file"
      id="waiverPDF"
      accept=".pdf"
      (change)="uploadPDF(id, $event)"
    />
    <label class="btn btn-primary ms-2 me-4" for="waiverPDF">
      <i class="fas fa-upload"></i> Upload Waiver
    </label>
  </div>
</div>
<div class="row" *ngIf="companymultiplewaivers && companySmartWaiver != true">
  <div class="col-md-3 p-4">
    <div
      class="height text-center text-secondary"
      *ngIf="waiversArray.length == 0 || waiversArray == undefined"
    >
      There is nothing to show
    </div>
    <div class="height" *ngIf="waiversArray.length > 0">
      <div
        *ngFor="let waiver of waiversArray"
        class="container-fluid rounded bg-light mb-2 shadow hover cards"
        (click)="editWaiver(waiver)"
      >
        <div
          class="row d-flex text-center justify-content-center align-items-center fs-5 pt-3 pb-3"
          style="height: auto"
        >
          {{ waiver.title }}
        </div>
        <div class="row align-items-end text-end">
          <div class="col-12 mb-4">
            <!-- Button trigger modal -->
            <button
              type="button"
              *ngIf="!waiver.main"
              class="btn btn-sm btn-warning me-2"
              data-bs-toggle="modal"
              (click)="getProductsWaiver(waiver)"
              data-bs-target="#productModal"
            >
              <i class="fas fa-bezier-curve"></i>
            </button>
            <button
              class="btn btn-sm btn-primary me-2"
              (click)="editWaiver(waiver)"
            >
              <i class="fas fa-pen"></i>
            </button>
            <button
              *ngIf="!waiver.main"
              class="btn btn-sm btn-danger"
              (click)="deleteWaiver(waiver)"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-9" *ngIf="!showEditor">
    <div
      class="container-fluid text-center mt-5 align-content-center align-items-center fs-2"
    >
      Welcome to the Waiver Editor
    </div>
    <div
      class="container-fluid text-center align-content-center align-items-center fs-5 mt-3"
    >
      Please add a new waiver or select an existing waiver.
    </div>
  </div>
  <div class="col-md-9" *ngIf="showEditor">
    <div class="container-fluid">
      <form>
        <div class="mb-3">
          <label class="form-label"
            >Waiver Title <b style="color: red">*</b></label
          >
          <input
            type="text"
            [ngClass]="{ isempty: error }"
            class="form-control"
            name="waiverTitle"
            [(ngModel)]="waiverTitle"
            placeholder="Waiver Title"
            [disabled]="title"
            (keyup)="onChangeTitle($event)"
          />
          <span *ngIf="error" class="text-danger"
            ><i class="fas fa-exclamation-circle"></i> You must write a title
            for this waiver</span
          >
        </div>
      </form>
    </div>
    <div class="container-fluid">
      <div class="form-check form-switch mt-2">
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          name="multipleSignPerWaiver"
          (change)="changeMultiSignPerWaiver()"
          [(ngModel)]="multipleSignPerWaiver"
        />
        <label class="form-check-label" for="flexSwitchCheckDefault"
          >Enable Multiple Signature (enabling this will require a signature for
          each product "in an order" containing the same wavier)</label
        >
      </div>
    </div>
    <div class="container-fluid mt-1">
      <ckeditor
        *ngIf="!waiver.waiverPDF"
        [editor]="editor"
        [config]="editorConfig"
        [(ngModel)]="model.editorData"
        (change)="onChangeWaiver($event)"
      ></ckeditor>
      <iframe
        class="container-fluid"
        *ngIf="waiver.waiverPDF && waiver.pdfContent && pdfEditor; else noPDF"
        [src]="pdfEditor"
        height="465"
        style="overflow: hidden"
        frameborder="0"
      ></iframe>
      <ng-template #noPDF>
        <div
          *ngIf="waiver.waiverPDF"
          class="container-fluid text-center fs-4 bg-light d-flex justify-content-center align-items-center"
          style="height: 465px"
        >
          <span>You have not uploaded any PDF, upload one right now</span>
        </div>
      </ng-template>

      <div class="row text-end">
        <div
          class="col-md-6 mt-2 text-left align-items-start justify-content-start"
          *ngIf="!editwaiver"
        >
          <button
            *ngIf="!waiver.waiverPDF"
            class="btn btn-danger me-3"
            (click)="addPDFWaiver()"
          >
            <i class="fas fa-file-pdf"></i> Add a PDF instead
          </button>
          <button
            *ngIf="waiver.waiverPDF"
            class="btn btn-danger me-3"
            (click)="writePDFWaiver()"
          >
            <i class="fas fa-file-signature"></i> Write a Waiver instead
          </button>
          <input
            class="form-control"
            type="file"
            id="waiverPDF"
            accept=".pdf"
            (change)="uploadPDFWaiverSingle($event)"
          />
          <label
            *ngIf="waiver.waiverPDF"
            class="btn btn-primary me-3 mt-2"
            for="waiverPDF"
          >
            <i class="fas fa-upload"></i> Upload Waiver
          </label>
        </div>
        <div
          class="col-md-6 mt-2 text-right align-items-end justify-content-end"
          *ngIf="!editwaiver"
        >
          <button
            class="btn btn-secondary me-2"
            (click)="resetWaiverForm()"
            [disabled]="cancelbtn"
          >
            Cancel
          </button>
          <button
            class="btn btn-primary"
            [disabled]="savebtn"
            (click)="createWaiver()"
          >
            Add New Waiver
          </button>
        </div>
        <div
          class="col-md-6 mt-2 text-left align-items-start justify-content-start"
          *ngIf="editwaiver"
        >
          <button
            *ngIf="!waiver.waiverPDF"
            class="btn btn-danger me-3"
            (click)="addPDFWaiver()"
          >
            <i class="fas fa-file-pdf"></i> Add a PDF instead
          </button>
          <button
            *ngIf="waiver.waiverPDF"
            class="btn btn-danger me-3"
            (click)="writePDFWaiver()"
          >
            <i class="fas fa-file-signature"></i> Write a Waiver instead
          </button>
          <input
            class="form-control"
            type="file"
            id="waiverPDF"
            accept=".pdf"
            (change)="uploadPDFWaiverSingle($event)"
          />
          <label
            *ngIf="waiver.waiverPDF"
            class="btn btn-primary me-3 mt-2"
            for="waiverPDF"
          >
            <i class="fas fa-upload"></i> Upload Waiver
          </label>
        </div>

        <div
          class="col-md-6 mt-2 text-right align-items-end justify-content-end"
          *ngIf="editwaiver"
        >
          <div class="row">
            <div class="col-12 text-right align-items-end justify-content-end">
              <button
                class="btn btn-secondary me-2"
                (click)="resetWaiverForm()"
                [disabled]="cancelbtn"
              >
                Cancel
              </button>
              <button
                class="btn btn-warning"
                (click)="updateWaiver()"
                [disabled]="savebtn"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-2" *ngIf="companySmartWaiver">
  <div *ngIf="!hidebuttons" class="col-12 text-left align-items-start">
    <button
      *ngIf="company.isSmartWaiver"
      class="btn btn-danger ms-4"
      (click)="uploadPDFWaiver()"
    >
      <i class="fas fa-file-pdf"></i> Add a PDF instead
    </button>
    <button
      *ngIf="company.isSmartWaiver"
      class="btn btn-warning ms-1"
      (click)="changeWaiver()"
    >
      <i class="fas fa-file-signature"></i> Write a Waiver instead
    </button>
    <button
      *ngIf="company.isSmartWaiver != undefined"
      class="btn btn-primary ms-1"
      (click)="smartWaiverChange('update')"
    >
      <i class="fas fa-signature"></i> Update Smart Waiver
    </button>
  </div>
  <div *ngIf="hidebuttons" class="col-6 text-left">
    <span class="text-secondary ms-4"
      >To switch to Fleetmaid Waivers, please contact Fleetmaid for
      assistance.</span
    >
  </div>
  <div *ngIf="hidebuttons" class="col-6 text-right align-items-end">
    <button
      *ngIf="company.isSmartWaiver != undefined"
      class="btn btn-primary me-3"
      (click)="smartWaiverChange('update')"
    >
      <i class="fas fa-signature"></i> Update Smart Waiver
    </button>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="productModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="productModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title fs-5" id="productModalLabel">
          {{ waiver?.title ? waiver.title : "" }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="container-fluid mb-3">
          <div class="mb-3">
            <div class="row">
              <div class="col-6 text-left">
                <label for="exampleFormControlInput1" class="form-label"
                  >Search product:</label
                >
              </div>
              <div class="col-6 text-right">
                <span class="badge bg-primary"
                  >Total Products with this Waiver:
                  {{ totalcountproducts }}</span
                >
              </div>
            </div>

            <input
              type="text"
              name="searchProductInput"
              [(ngModel)]="searchProductInput"
              class="form-control"
              placeholder="Search..."
              (keyup)="searchProduct()"
            />
            <div class="form-text">Select a product to assign the Waiver</div>
          </div>
        </div>
        <div class="container-fluid tableheight">
          <table
            id="productsTable"
            class="table text-center"
            *ngIf="products.length > 0"
          >
            <thead class="bgtable">
              <tr id="tableHeader">
                <th scope="col">Product Number</th>
                <th scope="col">Product Name</th>
                <th scope="col">Category</th>
                <th scope="col">Select</th>
              </tr>
            </thead>
            <tbody class="text-left">
              <ng-container *ngFor="let product of products; let i = index">
                <tr>
                  <td>#{{ product.productNumber }}</td>
                  <td>{{ product.productName }}</td>
                  <td>{{ product.productCategory }}</td>
                  <td class="text-center">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        [checked]="product.isSelected"
                        id="flexCheckDefault"
                        (click)="selectProductWaiver(product, i)"
                      />
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <button
          type="button"
          (click)="saveProductWaiver()"
          class="btn btn-primary"
        >
          Save Changes
        </button>
      </div>
    </div>
  </div>
</div>
