/* The purpose of this class is to provides methode to process the availability data that is returned from the availability algorithm.
/ This class is meant to be used to provide the necessary data to the front end and is not meant to change or manipulate the data in any way.
*/

import { DateTime } from 'luxon';

// Models
import {
    AlgoMetadata,
    AvailabilityInterface,
    AvailabilityObject,
    AvailabilityProductGroup,
    AvailabilityProductGroupItem,
    AvailabilityProductItem,
    AvailableProductGroupSizes,
    CartQuantities,
    IDsUsedForSwap,
    OverlappingTimes,
    OverlappingTimesItem,
    OverlapSortedListItem,
    ProductsOrganized,
    ProductWidgetTotals,
    ReconstructedOverlappingTimeslots,
    RentalLengthType,
    SortedList,
} from 'src/app/models/availability.model';
import { Timeslot, TimeslotType, timeslotTypeGuard } from 'src/app/models/availability-timeslot.model';
import { Cart } from 'src/app/models/storage/cart.model';


export class AvailabilityParsing implements AvailabilityInterface {
    // Properties
    private  _algoRes: AvailabilityObject;
    private _resultSet: AvailabilityProductGroup;
    private _cartQuantities: CartQuantities | object;
    private _productWidgetTotals: ProductWidgetTotals | object;
    private _algoMetadata: AlgoMetadata;
    private useCartQuantities: boolean = false;


    // Constructor
    constructor(algoRes: AvailabilityObject) {

        this._algoRes = algoRes;
        this._resultSet = algoRes.resultSet;
        this._cartQuantities = algoRes.cartQuantities;
        this._productWidgetTotals = algoRes.productWidgetTotals;
        this._algoMetadata = algoRes.algoMetadata;

        if (this._cartQuantities && Object.keys(this._cartQuantities).length > 0 ) {
            this.useCartQuantities = true;
        }
    }

    public get cartQuantities(): CartQuantities | object {
        return this._cartQuantities;
    }

    public get resultSet(): AvailabilityProductGroup {
        return this._resultSet;
    }

    public get algoResult(): AvailabilityObject {
        return this._algoRes;
    }

    public get algoMetadata(): AlgoMetadata {
        return this._algoRes.algoMetadata;
    }

    public get selectedDaySpan(): number {
        return this._algoMetadata['selectedDaySpan'];
    }

    public get timeZone(): string {
        return this._algoMetadata['info']['selectedTimezone'];
    }

    public get productWidgetTotals(): ProductWidgetTotals | object {
        return this._productWidgetTotals;
    }

    public set resultSet(resultSet) {
        this._resultSet = resultSet;
    }

    public set cartQuantities(cartQuantities) {
        this._cartQuantities = cartQuantities;
    }

    public set productWidgetTotals(productWidgetTotals) {
        this._productWidgetTotals = productWidgetTotals;
    }



    //////// Validation ///////// ( Because Im tired of rewriting them )

    private validateGroupAndSizeIDs(groupID: string, sizeID: string): void {
        if (!groupID || !sizeID) {
            throw new Error('Invalid group ID or size ID');
        }
    }

    private validateGroupAndProductIDs(groupID: string, productID: string): void {
        if (!groupID || !productID) {
            throw new Error('Invalid group ID or product ID');
        }
    }

    private validateGroupID(groupID: string): void {
        if (!groupID) {
            throw new Error('Invalid group ID');
        }
    }

    private validateDayStartAndDayEnd(dayStart: DateTime, dayEnd: DateTime): void {
        if (!dayStart || !dayEnd) {
            throw new Error('Invalid dayStart or dayEnd');
        }
    }

    private validateProductIDArray(productIDs: string[]): void {
        if (!productIDs || !Array.isArray(productIDs)) {
            throw new Error('Invalid product IDs');
        }
    }

    private validateCartQuantities(): void {
        if (!this._cartQuantities) {
            throw new Error('Cart quantities not found.');
        }
    }

    private validateProductWidgetTotals(): void {
        if (!this._productWidgetTotals) {
            throw new Error('Product widget totals not found.');
        }
    }

    ////// Boolean Checks //////

    // Check if an indiviual product is available
    public checkProductAvailability(groupID: string, productID: string): boolean {
        this.validateGroupAndProductIDs(groupID, productID);

        if (!Object.prototype.hasOwnProperty.call(this._resultSet, groupID) || !Object.prototype.hasOwnProperty.call(this._resultSet[groupID].products, productID)) {
            throw new Error('Group ID or product ID not found');
        }

        const item: AvailabilityProductItem = this._resultSet[groupID].products[productID];
        return Boolean(item.availTimeslots) && item.availTimeslots.length > 0;
    }


    // Check if a product group size is available
    public checkGroupSizeAvailability(groupID: string, sizeID: string): boolean {
        this.validateGroupAndSizeIDs(groupID, sizeID);

        if (!Object.prototype.hasOwnProperty.call(this._resultSet, groupID)) {
            throw new Error('Group ID not found');
        }

        if (this.useCartQuantities) {
            return this._cartQuantities[`${groupID}_${sizeID}`].currentAvail > 0;
        }
        else {
            const products: AvailabilityProductItem[] = Object.values(this._resultSet[groupID].products);
            for (const product of products) {
                if (product.productSizeID === sizeID && product.availTimeslots && product.availTimeslots.length > 0) {
                    return true; // Size is available
                }
            }
        }

        return false; // Size is not available
    }


    // Check if a product group is available
    public checkGroupAvailability(groupID: string): boolean {
        this.validateGroupID(groupID);

        if (!Object.prototype.hasOwnProperty.call(this._resultSet, groupID)) {
            throw new Error('Group ID not found');
        }

        if (this.useCartQuantities) {
            return Object.keys(this._cartQuantities).some(key => key.startsWith(`${groupID}_`) && this._cartQuantities[key].currentAvail > 0);
        }
        else {
            const group: AvailabilityProductGroupItem = this._resultSet[groupID];
            return Boolean(group) && group.hasAvailableQuantity;
        }
    }


    public checkForErrorsInAvailabilityResponse(productGroupID: string): string {
        const resultSetItem = this.resultSet[productGroupID];
        // If the product group id is not in the response
        if (!resultSetItem) {
            return 'There are no products available for this time'
        }
        // If I see the locationNotMatchingCartLock on the product group, show an error message
        else if (resultSetItem['locationNotMatchingCartLock']) {
            return 'This product is not available at the chosen location in cart'
        }
        // If they search for a rental on the current day but it is past shop close
        else if (resultSetItem['shopClosedForToday']) {
            return 'The shop has already closed for today, please search for a later date'
        }
        // If algorithm caught that there are no availabilities for any product in the product group
        else if (resultSetItem['unavailable'] && resultSetItem['unavailableDesc']) {
            return resultSetItem['unavailableDesc']
        }
        else if (resultSetItem['unavailable']) {
            return 'There are no products available for this time'
        }

        return '';
    }


    ////// Returns array of IDs //////

    // Returns an array of size IDs that are available in a product group
    public getGroupSizesIDsAvailable(groupID: string): string[] {
        this.validateGroupID(groupID);

        if (!Object.prototype.hasOwnProperty.call(this._resultSet, groupID)) {
            throw new Error('Group ID not found');
        }

        const sizes: string[] = [];
        const products: AvailabilityProductItem[] = Object.values(this._resultSet[groupID].products);
        for (const product of products) {
            if ( this.checkProductAvailability(groupID, product.productID) && !sizes.includes(product.productSizeID)) {
                sizes.push(product.productSizeID);
            }
        }
        return sizes;
    }

    // Returns an array of product IDs that are available in a product group for a specific size
    public getGroupSizeIDsAvailable(groupID: string, sizeID: string): string[] {
        this.validateGroupAndSizeIDs(groupID, sizeID);

        if (!Object.prototype.hasOwnProperty.call(this._resultSet, groupID)) {
            throw new Error('Group ID not found');
        }

        const products: AvailabilityProductItem[] = Object.values(this._resultSet[groupID].products);
        const productIDs: string[] = [];
        for (const product of products) {
            if (product.productSizeID === sizeID && this.checkProductAvailability(groupID, product.productID)) {
                productIDs.push(product.productID);
            }
        }
        return productIDs;
    }


    // Returns an array of product group IDs that are available in a product group
    public getGroupsIDsAvailable(): string[] {
        const groups: string[] = [];
        for (const groupID in this._resultSet) {
            if (this.checkGroupAvailability(groupID)) {
                groups.push(groupID);
            }
        }
        return groups;
    }

    // Returns an array of product group IDs that are unavailable in the result set
    getGroupIDsUnavailable(): string[] {
        const groups: string[] = [];
        for (const groupID in this._resultSet) {
            if (this.checkGroupAvailability(groupID)) {
                groups.push(groupID);
            }
        }
        return groups;
    }

    // Returns an array of all product group IDs in the result set, available first then unavailable
    getAllGroupIDsOrganized(): string[] {
        const availableGroups = this.getGroupsIDsAvailable();
        const unavailableGroups = this.getGroupIDsUnavailable();
        return [...availableGroups, ...unavailableGroups];
    }

    // Returns an array of all product group IDs in the result set
    getAllGroupIDs(): string[] {
        return Object.keys(this._resultSet);
    }

    ////// Available Timeslots //////


    // Get all available time slots for a specific product
    public getProductAvailableTimeslots(groupID: string, productID: string): Timeslot[] {
        this.validateGroupAndProductIDs(groupID, productID);

        if (!Object.prototype.hasOwnProperty.call(this._resultSet, groupID)|| !Object.prototype.hasOwnProperty.call(this._resultSet[groupID].products, productID)) {
            throw new Error('Group ID or product ID not found');
        }

        const availTimeslots: Timeslot[] = this._resultSet[groupID].products[productID].availTimeslots || [];
        const productSizeID = this._resultSet[groupID].products[productID].productSizeID;
        const productSizeName = this._resultSet[groupID].products[productID].productSize;

        // Add the product ID to each timeslot object
        const timeslotsWithProductID: Timeslot[] = availTimeslots.map(timeslot => ({
            ...timeslot,
            productID, // Shorthand syntax to assign productID: productID
            productSizeID,
            productSizeName,
            productGroupID: groupID
        }));

        return timeslotsWithProductID;
    }


    // Get all available time slots for a specific size in a product group
    public getGroupSizeAvailableTimeslots(groupID: string, sizeID: string): Timeslot[] {
        this.validateGroupAndSizeIDs(groupID, sizeID);

        if (!Object.prototype.hasOwnProperty.call(this._resultSet, groupID)) {
            throw new Error('Group ID not found');
        }

        const availTimeslots: Timeslot[] = [];
        const products: AvailabilityProductItem[] = Object.values(this._resultSet[groupID].products);
        for (const product of products) {
            if (product.productSizeID === sizeID) {
                availTimeslots.push(...this.getProductAvailableTimeslots(groupID, product.productID))
            }
        }
        return availTimeslots;
    }


    // Get all available time slots for a product group
    public getGroupAvailableTimeslots(groupID: string): Timeslot[] {
        this.validateGroupID(groupID);

        if (!Object.prototype.hasOwnProperty.call(this._resultSet, groupID)) {
            throw new Error('Group ID not found');
        }

        const availTimeslots: Timeslot[] = [];
        const products: AvailabilityProductItem[] = Object.values(this._resultSet[groupID].products);
        for (const product of products) {
            availTimeslots.push(...this.getProductAvailableTimeslots(groupID, product.productID))
        }

        return availTimeslots;
    }

    // Does exactly what the name says, will filter the available timeslots by the type and hour length if provided
    public filterAvailableTimeslotsByType(timeslots: Timeslot[], type: TimeslotType, hourLength?: number): Timeslot[] {
        if (!Array.isArray(timeslots) || !type) {
            throw new Error('Invalid timeslots or type');
        }
        return timeslots.filter(timeslot => {
            if (timeslot.type === type) {
                if (type === TimeslotType.Hourly && hourLength) {
                    return timeslot.dayEnd.diff(timeslot.dayStart, 'hours').hours === hourLength;
                }
                return true;
            }
            return false;
        });
    }


    ////// Time lengths //////


    // Get the different variations of times a product is available (3 hours, 4 hours, All day etc.)
    public getProductTimeLengths(groupID: string, productID: string, productTimeslots?: Timeslot[]): (number | string)[] {
        this.validateGroupAndProductIDs(groupID, productID);

        if (!productTimeslots) {
            productTimeslots = this.getProductAvailableTimeslots(groupID, productID);
        }

        return this.determineTimeLengths(productTimeslots);
    }


    // Get the different variations of times a specific size in a product group is available (3 hours, 4 hours, All day etc.)
    public getGroupSizeTimeLengths(groupID: string, sizeID: string, groupSizeTimeslots?: Timeslot[]): (number | string)[] {
        this.validateGroupAndSizeIDs(groupID, sizeID);

        if (!groupSizeTimeslots) {
            groupSizeTimeslots = this.getGroupSizeAvailableTimeslots(groupID, sizeID);
        }

        return this.determineTimeLengths(groupSizeTimeslots);
    }


    public determineTimeLengths(timeslots: Timeslot[]): (number | string)[] {
        if (!Array.isArray(timeslots)) {
            throw new Error('Invalid timeslots data');
        }

        const timeLengths: (number | string)[] = [];
        const timeLengthMap: { [key: string]: boolean } = {}; // Track unique time lengths

        for (const timeslot of timeslots) {
            if (!timeslot || !timeslot.type || !timeslot.dayStart || !timeslot.dayEnd) {
                throw new Error('Invalid timeslot data');
            }

            if (timeslot?.type === TimeslotType.ShopDay && !timeLengthMap[RentalLengthType.AllDay]) {
                timeLengths.push(RentalLengthType.AllDay);
                timeLengthMap[RentalLengthType.AllDay] = true;
            }
            else if (timeslot?.type === TimeslotType.TwentyFourHour && !timeLengthMap[RentalLengthType.TwentyFourHour]) {
                timeLengths.push(RentalLengthType.TwentyFourHour);
                timeLengthMap[RentalLengthType.TwentyFourHour] = true;
            }
            else if (timeslot?.type === TimeslotType.Hourly) {
                const hours = timeslot.dayEnd.diff(timeslot.dayStart, 'hours').hours;
                if (!timeLengthMap[hours]) {
                    timeLengths.push(hours);
                    timeLengthMap[hours] = true;
                }
            }
        }
        return timeLengths;
    }



    ////// Overlapping Times //////

    /**
     * Get overlapping times for an array of product IDs passed in
     * @param groupSizeTimeslots Optional. The available timeslots for the products from the availability result set. If not provided, the function will manually get the timeslots.
     * @param timeslotType Optional. Can either be 'hourly', 'shopDay' or '24hr'. Will filter the results based on the type. If type is hourly, then hourLength can be provided to filter by hour length.
     */
    public getProductsOverlappingTimes(groupID: string, productIDs: string[], productTimeslots?: Timeslot[], timeslotType?: TimeslotType, hourLength?: number): OverlappingTimes {

        this.validateGroupID(groupID);
        this.validateProductIDArray(productIDs);

        if (!productTimeslots) {
            productTimeslots = [];
            for (const productID of productIDs) {
                productTimeslots.push(...this.getProductAvailableTimeslots(groupID, productID));
            }

            if (timeslotType) {
                productTimeslots = this.filterAvailableTimeslotsByType(productTimeslots, timeslotType, hourLength);
            }
        }
        return this.determineOverlappingTimes(productTimeslots);
    }


    /**
     * Get overlapping times for a specific product group size.
     * @param groupSizeTimeslots Optional. The available timeslots for the product group size from the availability result set. If not provided, the function will manually get the timeslots.
     * @param timeslotType Optional. Can either be 'hourly', 'shopDay' or '24hr'. Will filter the results based on the type.
    */
    public getGroupSizeOverlappingTimes(groupID: string, sizeID: string, groupSizeTimeslots?: Timeslot[], timeslotType?: TimeslotType, hourlength?: number): OverlappingTimes {
        this.validateGroupAndSizeIDs(groupID, sizeID);

        if (!groupSizeTimeslots) {
            groupSizeTimeslots = this.getGroupSizeAvailableTimeslots(groupID, sizeID);
            if (timeslotType) {
                groupSizeTimeslots = this.filterAvailableTimeslotsByType(groupSizeTimeslots, timeslotType, hourlength);
            }
        }

        return this.determineOverlappingTimes(groupSizeTimeslots);
    }


    // Provides main functionality for figuring out overlapping times ( How many/what products are available at the same time)
    private determineOverlappingTimes(timeslots: Timeslot[]): OverlappingTimes  {
        const overlappingTimes: OverlappingTimes = {}
        for (const timeslot of timeslots) {
            const dateTimeKey = timeslot.dayStart.toISO() + '_' + timeslot.dayEnd.toISO();
                if (overlappingTimes[dateTimeKey] && overlappingTimes[dateTimeKey].type === timeslot.type) {
                    overlappingTimes[dateTimeKey].count = this.determineOverlappingCount(overlappingTimes[dateTimeKey].count, timeslot.productGroupID, timeslot.productSizeID);
                    overlappingTimes[dateTimeKey].productIDs.push(timeslot.productID);
                    overlappingTimes[dateTimeKey].productWidgetIDs = [ ...new Set([...(overlappingTimes[dateTimeKey]?.productWidgetIDs || []), ...(timeslot?.prodWidgets || []) ])] // Gaurentees no dupilcates
                }
                else {
                    overlappingTimes[dateTimeKey] = {
                        availDaySpan: timeslot.availDaySpan,
                        dayStart: timeslot.dayStart,
                        dayStartString: timeslot.dayStartString,
                        dayEnd: timeslot.dayEnd,
                        dayEndString: timeslot.dayEndString,
                        type: timeslot.type,
                        productSizeID: timeslot.productSizeID,
                        productSizeName: timeslot.productSizeName,
                        productGroupID: timeslot.productGroupID,
                        productIDs: [timeslot.productID],
                        productWidgetIDs: timeslot.prodWidgets || [],
                        hourLength: timeslot.dayEnd.diff(timeslot.dayStart, 'hours').hours,
                        count: 1
                    };
                }
        }
        return overlappingTimes;
    }

    // Helper function to figure out whether to use a cart quantity count or manually increase the count
    public determineOverlappingCount(count: number, productGroupID: string, productGroupSize: string): number {
        return this.useCartQuantities ? this._cartQuantities[`${productGroupID}_${productGroupSize}`].currentAvail : count + 1;
    }

    public getOverlappingTimesForSpecifiedHourLength(overlappingTimes: OverlappingTimes, hourLength: number): OverlappingTimes {
        const filteredOverlappingTimes: OverlappingTimes = {};

        for (const time in overlappingTimes) {
            if (overlappingTimes[time].hourLength === hourLength) {
                filteredOverlappingTimes[time] = overlappingTimes[time];
            }
        }

        return filteredOverlappingTimes;
    }

    /////// Reformatting data for date range //////

    // Will create an object that is sorted by productGroupID and will then show the sizes available in that group.
    // It is then sorted by sizeID and you can see what products are available in that size
    public getObjectOfAvailableProductsAndSizes(): AvailableProductGroupSizes {
        const organizedObj: AvailableProductGroupSizes = {};

        for (const productGroupID in this._resultSet) {
            if (Object.prototype.hasOwnProperty.call(this._resultSet, productGroupID)) {
                const productGroup = this._resultSet[productGroupID];

                for (const product in productGroup.products) {
                    if (this.checkProductAvailability(productGroupID, product)) {
                        const sizeID = this._resultSet[productGroupID].products[product].productSizeID;
                        const sizeName = this._resultSet[productGroupID].products[product].productSize;

                        // Initialize the productGroup in result if it doesn't exist
                        if (!organizedObj[productGroupID]) {
                            organizedObj[productGroupID] = {
                                sizes: {},
                                sizeNames: [],
                                productGroupID: productGroupID
                            };
                        }

                        // Initialize the size in result[productGroupID] if it doesn't exist
                        if (!organizedObj[productGroupID].sizes[sizeID]) {
                            organizedObj[productGroupID].sizes[sizeID] = {
                                products: [],
                                productCount: 0,
                                sizeName: sizeName,
                                sizeID: sizeID
                            };
                            organizedObj[productGroupID].sizeNames.push(sizeName);
                        }

                        // Add the product to the products array and increment the count
                        organizedObj[productGroupID].sizes[sizeID].products.push(product);
                        organizedObj[productGroupID].sizes[sizeID].productCount += 1;
                    }
                }
            }
        }

        return organizedObj;
    }

    // Will create and array of object representing what sizes of that product group are available/unavailable, then they are sorted to be in order
    public productsOrganizedFunction(groupID: string, sizeTypesMap: object): ProductsOrganized[] {
        this.validateGroupID(groupID);

        if (!Object.prototype.hasOwnProperty.call(this._resultSet, groupID)) {
            throw new Error('Group ID not found');
        }

        if (!sizeTypesMap || typeof sizeTypesMap !== 'object') {
            throw new Error('Invalid sizeTypesMap: it should be a non-null object.');
        }

        // Resets variables for each time availability query is called
        let sortOrderList: string[] | null = null;
        let productsOrganized: ProductsOrganized[] = [];

        /* If items are in cart, we can use cartQuantities */
        if (this.useCartQuantities) {
            const result = this.productsOrganizedFunctionUsingCartQuantities(groupID, sizeTypesMap);
            productsOrganized = result.productsOrganized;
            sortOrderList = result.sortOrderList;
        }
        else {
            // Process information differently if no items exist in the cart
            productsOrganized = Object.values(this._resultSet[groupID].listOfSizes);

            // If there is an issue with the listOfSizes object, we can manually create it
            if (!productsOrganized) {
                productsOrganized = Object.values(this.getListOfSizes(groupID, sizeTypesMap))
            }

            const firstSizeTypeID = productsOrganized[0]['sizeTypeID'];
            if (firstSizeTypeID) {
                const sizeType = sizeTypesMap[firstSizeTypeID];
                if (sizeType?.sortOrder) {
                    sortOrderList = sizeType.sortOrder;
                }
            }
        }

        // Catches if sort order does not exist, avoids errors & sorts by sort order list defined on size type
        if (sortOrderList && sortOrderList.length > 1) {
            productsOrganized.sort((a, b) => sortOrderList!.indexOf(a.sizeID) - sortOrderList!.indexOf(b.sizeID));
        }

        return productsOrganized; // returns array of objects containing size info
    }


    // Manually creates the availability listOfSizes object, this object is meant to be looked at to see what is and isnt availble. This does not provide counts.
    public getListOfSizes(groupID: string, sizeTypesMap: object): { [sizeID:string] : ProductsOrganized } {
        this.validateGroupID(groupID);

        const sizes: { [sizeID:string] : ProductsOrganized } = {};

        const products: AvailabilityProductItem[] = Object.values(this._resultSet[groupID].products);
        const group = this._resultSet[groupID];
        for (const product of products) {
            if (!sizes[product.productSizeID]) {
                let sizeTypeID: string;
                let sizeTypeName: string;

                // Manually get the sizeTypeID and sizeType name from the sizeTypesMap
                for (const sizeTypeKey in sizeTypesMap) {
                    if (sizeTypesMap[sizeTypeKey]['sortOrder'].includes(product.productSizeID)) {
                        sizeTypeID = sizeTypeKey;
                        sizeTypeName = sizeTypesMap[sizeTypeKey].productType;
                    }
                }

                sizes[product.productSizeID] = {
                    sizeName: product.productSize,
                    sizeID: product.productSizeID,
                    isAvailable: this.checkGroupSizeAvailability(groupID, product.productSizeID),
                    pgName: group.pgName,
                    sizeTypeID: sizeTypeID,
                    sizeTypeName: sizeTypeName
                }

            }
        }
        return sizes;
    }



    ////// Specified  Time Functions //////


    // Used to convert the dayStart and dayEnd to an ISO string and then find the value in the overlappingTimes object
    public getOverlappingTimeValueForSpecificTime(overlappingTimes: OverlappingTimes, dayStart: DateTime, dayEnd: DateTime): OverlappingTimesItem {
        const dateTimeKey: string = dayStart.toISO() + '_' + dayEnd.toISO();
        return overlappingTimes[dateTimeKey];
    }


    // Check if a specific item is available at a specific time
    // DayStart and dayEnd are luxon DateTime objects
    public checkProductAvailabilityByTime(groupID: string, productID: string, dayStart: DateTime, dayEnd: DateTime): boolean {

        this.validateGroupAndProductIDs(groupID, productID);
        this.validateDayStartAndDayEnd(dayStart, dayEnd);

        const availTimeslots: Timeslot[] = this.getProductAvailableTimeslots(groupID, productID);
        return availTimeslots.some(timeslot =>
            timeslot.dayStart.hasSame(dayStart, 'minute') && timeslot.dayEnd.hasSame(dayEnd, 'minute')); // Compares the start and end times of the timeslot with the input times
    }


    // Check if a specific group size is available at a specific time
    public checkGroupSizeAvailabilityByTime(groupID: string, sizeID: string, dayStart: DateTime, dayEnd: DateTime): boolean {

        this.validateGroupAndSizeIDs(groupID, sizeID);
        this.validateDayStartAndDayEnd(dayStart, dayEnd);

        const availTimeslots: Timeslot[] = this.getGroupSizeAvailableTimeslots(groupID, sizeID);
        return availTimeslots.some(timeslot =>
            timeslot.dayStart.hasSame(dayStart, 'minute') && timeslot.dayEnd.hasSame(dayEnd, 'minute')); // Compares the start and end times of the timeslot with the input times
    }


    // Check if a specific group size is available at a specific time
    public checkProductGroupAvailabilityByTime(groupID: string, dayStart: DateTime, dayEnd: DateTime): boolean {

        this.validateGroupID(groupID);
        this.validateDayStartAndDayEnd(dayStart, dayEnd);

        const availTimeslots: Timeslot[] = this.getGroupAvailableTimeslots(groupID);
        return availTimeslots.some(timeslot =>
            timeslot.dayStart.hasSame(dayStart, 'minute') && timeslot.dayEnd.hasSame(dayEnd, 'minute')); // Compares the start and end times of the timeslot with the input times
    }


    // Check a group of random product IDs
    public checkProductsAvailabilityByTime(groupID: string, productIDs: string[], dayStart: DateTime, dayEnd: DateTime): boolean {

        this.validateGroupID(groupID);
        this.validateDayStartAndDayEnd(dayStart, dayEnd);
        this.validateProductIDArray(productIDs);

        const availTimeslots: Timeslot[] = [];
        for (const productID of productIDs) {
            availTimeslots.push(...this.getProductAvailableTimeslots(groupID, productID));
        }

        return availTimeslots.some(timeslot =>
            timeslot.dayStart.hasSame(dayStart, 'minute') && timeslot.dayEnd.hasSame(dayEnd, 'minute')); // Compares the start and end times of the timeslot with the input times
    }


    // Will return a count of how many products are available at a specific time for a specific group size
    public countGroupSizeAvailabilityByTime(groupID: string, sizeID: string, dayStart: DateTime, dayEnd: DateTime, availTimeslots?: Timeslot[]): number {

        this.validateGroupAndSizeIDs(groupID, sizeID);
        this.validateDayStartAndDayEnd(dayStart, dayEnd);

        if (this.useCartQuantities) {
            return this._cartQuantities[`${groupID}_${sizeID}`].currentAvail;
        }
        else {
            if (!availTimeslots) {
                availTimeslots = this.getGroupSizeAvailableTimeslots(groupID, sizeID);
            }

            return availTimeslots.filter(timeslot =>
                timeslot.dayStart.hasSame(dayStart, 'minute') && timeslot.dayEnd.hasSame(dayEnd, 'minute')).length;
        }
    }


    /////// Get available timeslot object //////

    // Will return the timeslot object for a specific product at a specific time
    public getProductAvailableTimeslotByTime(groupID: string, productID: string, dayStart: DateTime, dayEnd: DateTime, type?: TimeslotType): Timeslot[] {

        this.validateGroupAndProductIDs(groupID, productID);
        this.validateDayStartAndDayEnd(dayStart, dayEnd);

        const availTimeslots: Timeslot[] = this.getProductAvailableTimeslots(groupID, productID);
        const matchingTimeslots = availTimeslots.filter(timeslot => {
            const matchesTime = timeslot.dayStart.hasSame(dayStart, 'minute') && timeslot.dayEnd.hasSame(dayEnd, 'minute');
            const matchesType = type ? timeslot.type === type : true;
            return matchesTime && matchesType;
        });

        return matchingTimeslots;
    }


    // Will return an array of timeslot objects for a specific size in a product group at a specific time (one object for each product that is available)
    public getGroupSizeAvailableTimeslotsByTime(groupID: string, sizeID: string, dayStart: DateTime, dayEnd: DateTime, type?: TimeslotType): Timeslot[] {

        this.validateGroupAndSizeIDs(groupID, sizeID);
        this.validateDayStartAndDayEnd(dayStart, dayEnd);

        const availTimeslots: Timeslot[] = this.getGroupSizeAvailableTimeslots(groupID, sizeID);
        const matchingTimeslots = availTimeslots.filter(timeslot => {
            const matchesTime = timeslot.dayStart.hasSame(dayStart, 'minute') && timeslot.dayEnd.hasSame(dayEnd, 'minute');
            const matchesType = type ? timeslot.type === type : true;
            return matchesTime && matchesType;
        });

        return matchingTimeslots;
    }



    /////// Return an array of IDs for specified time //////

    // Returns an array of product IDs that are available in a product group for a specific size
    public getGroupSizeIDsAvailableByTime(groupID: string, sizeID: string, dayStart: DateTime, dayEnd: DateTime): string[] {

        this.validateGroupAndSizeIDs(groupID, sizeID);
        this.validateDayStartAndDayEnd(dayStart, dayEnd);

        if (!Object.prototype.hasOwnProperty.call(this._resultSet, groupID)) {
            throw new Error('Group ID not found');
        }

        const products: AvailabilityProductItem[] = Object.values(this._resultSet[groupID].products);
        const productIDs: string[] = [];
        for (const product of products) {
            if (product.productSizeID === sizeID && this.checkProductAvailabilityByTime(groupID, product.productID, dayStart, dayEnd)) {
                productIDs.push(product.productID);
            }
        }
        return productIDs;
    }


    /////// Reformatting data for specified date //////

    // Will create an object that is sorted by productGroupID and will then show the sizes available in that group.
    // It is then sorted by sizeID and you can see what products are available in that size
    public getObjectOfAvailableProductsAndSizesByTime(dayStart: DateTime, dayEnd: DateTime): AvailableProductGroupSizes {
        const organizedObj: AvailableProductGroupSizes = {};

        for (const productGroupID in this._resultSet) {
            if (Object.prototype.hasOwnProperty.call(this._resultSet, productGroupID)) {
                const productGroup = this._resultSet[productGroupID];

                for (const product in productGroup.products) {
                    if (this.checkProductAvailabilityByTime(productGroupID, product, dayStart, dayEnd)) {
                        const sizeID = this._resultSet[productGroupID].products[product].productSizeID;
                        const sizeName = this._resultSet[productGroupID].products[product].productSize;

                        // Initialize the productGroup in result if it doesn't exist
                        if (!organizedObj[productGroupID]) {
                            organizedObj[productGroupID] = {
                                sizes: {},
                                sizeNames: [],
                                productGroupID: productGroupID
                            };
                        }

                        // Initialize the size in result[productGroupID] if it doesn't exist
                        if (!organizedObj[productGroupID].sizes[sizeID]) {
                            organizedObj[productGroupID].sizes[sizeID] = {
                                products: [],
                                productCount: 0,
                                sizeName: sizeName,
                                sizeID: sizeID
                            };
                            organizedObj[productGroupID].sizeNames.push(sizeName);
                        }

                        // Add the product to the products array and increment the count
                        organizedObj[productGroupID].sizes[sizeID].products.push(product);
                        organizedObj[productGroupID].sizes[sizeID].productCount += 1;
                    }
                }
            }
        }
        return organizedObj;
    }



    ///////////////------------- Cart quantities methods --------------/////////////////

    // Will return the cart quantities object for a specific group size
    public getGroupSizeCartQuantitiesObject(groupID: string, sizeID: string): CartQuantities {

        this.validateGroupAndSizeIDs(groupID, sizeID);
        this.validateCartQuantities();

        if (!Object.prototype.hasOwnProperty.call(this._cartQuantities, `${groupID}_${sizeID}`)) {
            throw new Error('Key not found.');
        }

        return this._cartQuantities[`${groupID}_${sizeID}`];
    }


    // Will return the current avail for a specific group size
    public getGroupSizeCartQuantitiesCurrentAvail(groupID: string, sizeID: string): number {

        this.validateGroupAndSizeIDs(groupID, sizeID);
        this.validateCartQuantities();


        if (!Object.prototype.hasOwnProperty.call(this._cartQuantities, `${groupID}_${sizeID}`)) {
            throw new Error('Key not found.');
        }

        return this._cartQuantities[`${groupID}_${sizeID}`].currentAvail;
    }


    // Cart quantites version of the methods
    public productsOrganizedFunctionUsingCartQuantities(groupID: string, sizeTypesMap: object): {productsOrganized: ProductsOrganized[], sortOrderList: string[]} {

        this.validateGroupID(groupID);

        if (!sizeTypesMap || typeof sizeTypesMap !== 'object') {
            throw new Error('Invalid sizeTypesMap: it should be a non-null object.');
        }

        if (!Object.prototype.hasOwnProperty.call(this._resultSet, groupID)) {
            throw new Error('Group ID not found');
        }

        const productsOrganized: ProductsOrganized[] = [];
        let sortOrderList: string[] | null = null;
        // Get all the keys that contain the parent group ID
        const newCart = Object.keys(this._cartQuantities).filter(key => key.includes(groupID));

      // Get the data from the object for the product group sizes to create productsOrganized array
        newCart.forEach(ID => {
            const cartItem = this._cartQuantities[ID];
            cartItem.sizeID = ID.split('_')[1];
            productsOrganized.push(cartItem);
            const sizeType = sizeTypesMap[cartItem.sizeTypeID];
            cartItem.isAvailable = cartItem.currentAvail > 0;
            if (sizeType?.sortOrder) {
                sortOrderList = sizeType.sortOrder;
            }
        });

        return { productsOrganized, sortOrderList };
    }


    public increaseCurrentAvailDecreaseCartQuantity(groupID: string, sizeID: string, reverse?: boolean): void {
        this.validateGroupAndSizeIDs(groupID, sizeID);
        this.validateCartQuantities();

        if (!Object.prototype.hasOwnProperty.call(this._cartQuantities, `${groupID}_${sizeID}`)) {
            throw new Error('Key not found.');
        }

        if (reverse) {
            this._cartQuantities[`${groupID}_${sizeID}`].currentAvail -= 1;
            this._cartQuantities[`${groupID}_${sizeID}`].cartQty += 1;
        }
        else {
            this._cartQuantities[`${groupID}_${sizeID}`].currentAvail += 1;
            this._cartQuantities[`${groupID}_${sizeID}`].cartQty -= 1;
        }

    }

    public availCartQuantitiesList(): CartQuantities[] { // Built to replace createAvailProductGroupsList
        const availList: CartQuantities[] = [];
        this.validateCartQuantities();
        for (const key in this._cartQuantities) {
            if (this._cartQuantities[key].currentAvail > 0) {
                availList.push(this._cartQuantities[key]);
            }
        }
        return availList;
    }

    ///////////////------------- Product Widget quantities methods --------------/////////////////


    // Will return the ProductWidgetTotals object for a specific group size
    public getGroupSizeProductWidgetTotalsObject(groupID: string, sizeID: string): ProductWidgetTotals {

        this.validateGroupAndSizeIDs(groupID, sizeID);
        this.validateProductWidgetTotals();

        if (!Object.prototype.hasOwnProperty.call(this._productWidgetTotals, `${groupID}_${sizeID}`)) {
            throw new Error('Key not found.');
        }

        return this._productWidgetTotals[`${groupID}_${sizeID}`];
    }


    // Will return any array of ProductWidgetTotals objects for a specific group
    public getGroupProductWidgetTotals(groupID: string): ProductWidgetTotals[] {

        this.validateGroupID(groupID);
        this.validateProductWidgetTotals();

        return Object.keys(this._productWidgetTotals)
            .filter(key => key.includes(groupID))
            .map(key => this._productWidgetTotals[key]);
    }


    // Will return the current avail of a specific product widget, this is calculated by taking the productWidget total and subtracting the cart quantity
    public getProductWidgetCurrentAvail(groupID: string, sizeID: string): number {

        this.validateGroupAndSizeIDs(groupID, sizeID);
        this.validateProductWidgetTotals();
        this.validateCartQuantities();

        if (!Object.prototype.hasOwnProperty.call(this._productWidgetTotals, `${groupID}_${sizeID}`) || !Object.prototype.hasOwnProperty.call(this._cartQuantities, `${groupID}_${sizeID}`)) {
            throw new Error('Key not found.');
        }

        return this._productWidgetTotals[`${groupID}_${sizeID}`].totalAvail - this._cartQuantities[`${groupID}_${sizeID}`].cartQty;
    }


    // Will return the uniqueIDs of a product widget
    public getProductWidgetCurrentAvailUniqueIDs(groupID: string, sizeID: string): string[] {

        this.validateGroupAndSizeIDs(groupID, sizeID);
        this.validateProductWidgetTotals();

        if (!Object.prototype.hasOwnProperty.call(this._productWidgetTotals, `${groupID}_${sizeID}`)) {
            throw new Error('Key not found.');
        }

        return Object.values(this._productWidgetTotals[`${groupID}_${sizeID}`].uniqueIDs);
    }


    public getGroupProductWidgetCurrentAvailTotal(groupID: string): number {
        this.validateGroupID(groupID);
        this.validateProductWidgetTotals();
        this.validateCartQuantities();

        const productWidgetTotalArray: ProductWidgetTotals[]= this.getGroupProductWidgetTotals(groupID);
        let total: number = 0;

        productWidgetTotalArray.forEach((productWidgetTotal) => {
            total += this.getProductWidgetCurrentAvail(groupID, productWidgetTotal.sizeID);
        });

        return total;
    }


    //// --- Processing for the Backend Bookings date-range filtering --- ////



    public getUniqueCartItemsCount(cartObj: Cart): SortedList {
        const manualCartQuantities: SortedList = {};

        cartObj.items.forEach((item) => {
            const key = `${item.parentId}_${item.productSizeID}`;

            if (!manualCartQuantities[key]) {
                manualCartQuantities[key] = {
                    originalProductIDs: [item.productId],
                    originalProductWidgetIDs: [],
                    productGroupID: item.parentId,
                    productSizeID: item.productSizeID,
                    timeslotOverlap: {}
                };
            }
            else {
                manualCartQuantities[key].originalProductIDs.push(item.productId);
            }

            item.widgetList.forEach((widget) => {
                if (widget.widgetType === 'product') {
                    widget.element.options.forEach((option) => {
                        if (option.inputValue > 0 && option.productsCheckedOut) {
                            manualCartQuantities[key].originalProductWidgetIDs.push(...option.productsCheckedOut);
                        }
                    });
                }
            });
        });

        return manualCartQuantities;
    }


    public getOverlapTimeslotsForProductGroupSize(sortedList: SortedList): { sortedList: SortedList, timesAreAvail: boolean } {
        let timesAreAvail = true;

        Object.values(sortedList).forEach(listItem => {
            const { productGroupID, productSizeID } = listItem;

            Object.keys(this._resultSet).forEach(groupID => {
                if (groupID === productGroupID) {
                    Object.values(this.resultSet[groupID].products).forEach(product => {
                        if (product.productSizeID === productSizeID) {
                            product.availTimeslots.forEach(slot => {
                                if (!slot.unavailable) {
                                    const newKey = `${slot.dayStart.toISO()}_${slot.dayEnd.toISO()}`;
                                    const overlapSlot = sortedList[`${groupID}_${productSizeID}`].timeslotOverlap[newKey];

                                    if (overlapSlot) {
                                        overlapSlot.productIDs?.push(product.productID);
                                    }
                                    else {
                                        const newSlot: OverlapSortedListItem = {
                                            ...slot,
                                            productIDs: [product.productID],
                                            originalProductIDs: listItem.originalProductIDs,
                                            originalProductWidgetIDs: listItem.originalProductWidgetIDs,
                                            hourLength: slot.dayEnd.diff(slot.dayStart, 'hours').hours,
                                            swappableProductWidgets: [],
                                            swappableItems: [],
                                            productWidgetIDs: []
                                        };
                                        sortedList[`${groupID}_${productSizeID}`].timeslotOverlap[newKey] = newSlot;
                                    }
                                }
                            });
                        }
                    });
                }
            });
        });

        Object.values(sortedList).forEach(listItem => {
            if (Object.keys(listItem.timeslotOverlap).length === 0) {
                timesAreAvail = false;
            }
        });

        return { sortedList, timesAreAvail };
    }


      // Make sure timeslot has enough quantity to suport amount of product type in cart
    public filterOverlappingTimeSlotForProducts(sortedList: SortedList): { sortedList: SortedList, timesAreAvail: boolean, IDsUsedForSwap: IDsUsedForSwap } {
        let IDsUsedForSwap = {};
        let timesAreAvail = true;
        Object.values(sortedList).forEach(listItem => {
            Object.keys(listItem['timeslotOverlap']).forEach(slot => {
                if (listItem['timeslotOverlap'][slot]['productIDs'].length < listItem['originalProductIDs'].length) {
                    delete listItem['timeslotOverlap'][slot];
                    if (Object.keys(listItem['timeslotOverlap']).length === 0) {
                        timesAreAvail = false;
                    }
                }
                else {
                    outerOriginalProductIDsLoop: for (const originalID of listItem['originalProductIDs']) {
                        if (!listItem['timeslotOverlap'][slot]['productIDs'].includes(originalID)) {
                            let found = false;

                            for (const newID of listItem['timeslotOverlap'][slot]['productIDs']) {
                                // let objKey = (slot['dayStart']).toISO() + '_' + (slot['dayEnd']).toISO()
                                if ((!IDsUsedForSwap[slot] || !IDsUsedForSwap[slot]['IDs'].includes(newID)) && !listItem['originalProductIDs'].includes(newID) && !found) {
                                    listItem['timeslotOverlap'][slot]['swappableItems'].push({ originalID, newID });
                                    // IDsUsedForSwap.push(newID);
                                    IDsUsedForSwap = this.addIDToIDsUsedForSwap(newID, IDsUsedForSwap, slot);
                                    found = true;
                                }
                            }
                            if (!found) {
                                delete listItem['timeslotOverlap'][slot];
                                if (Object.keys(listItem['timeslotOverlap']).length === 0) {
                                    timesAreAvail = false;
                                }
                                break outerOriginalProductIDsLoop;
                            }
                        }
                    }
                }
            })
        })

        return {sortedList, timesAreAvail, IDsUsedForSwap}
    }


      // Filters out any times that dont have enouh product widgets for cart item
    public async filterOverlappingTimeSlotForProductsWidgets(sortedList: SortedList, productsMap: object, allOriginalRentalIDs: string[], IDsUsedForSwap: IDsUsedForSwap): Promise<{ sortedList: SortedList, timesAreAvail: boolean, IDsUsedForSwap: IDsUsedForSwap }> {
        let timesAreAvail = true;

        for (const listItem of Object.values(sortedList)) {

            for (const slot of Object.keys(listItem['timeslotOverlap'])) {
                if (listItem['originalProductWidgetIDs'].length > 0) {
                    await Promise.all(listItem['originalProductWidgetIDs'].map(async id => {
                        if (!listItem['timeslotOverlap'][slot]?.productWidgetIDs || !listItem['timeslotOverlap'][slot].productWidgetIDs.includes(id)) {
                            const rentalAvailResult = await this._checkProductRentalAvail(this.resultSet[productsMap[id]['productGroupID']]['products'][id],
                                listItem['timeslotOverlap'][slot]['dayStart'],
                                listItem['timeslotOverlap'][slot]['dayEnd'],
                                listItem['timeslotOverlap'][slot]['type']
                            );
                            if (rentalAvailResult) {
                                listItem['timeslotOverlap'][slot]['prodWidgets'].push(id);
                            }
                        }
                    }));

                    if (!listItem['timeslotOverlap'][slot]?.productWidgetIDs || listItem['timeslotOverlap'][slot].productWidgetIDs.length < listItem['originalProductWidgetIDs'].length) {
                        delete listItem['timeslotOverlap'][slot];
                        if (Object.keys(listItem['timeslotOverlap']).length === 0) {
                        timesAreAvail = false;
                        }
                    }
                    else {
                        const prodWidgQuantities = {};
                        const originalProdWidgQuantities = {};

                        listItem['timeslotOverlap'][slot].productWidgetIDs.forEach(id => {
                        if (prodWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']]) {
                            prodWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']]['count'] += 1;
                            prodWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']]['availIDs'].push(id);
                        } else {
                            prodWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']] = {};
                            prodWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']]['count'] = 1;
                            prodWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']]['availIDs'] = [id];
                        }
                        });

                        listItem['originalProductWidgetIDs'].forEach(id => {
                        if (originalProdWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']]) {
                            originalProdWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']]['count'] += 1;
                            originalProdWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']]['IDs'].push(id);
                        } else {
                            originalProdWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']] = {}
                            originalProdWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']]['count'] = 1;
                            originalProdWidgQuantities[productsMap[id]['productGroupID'] + '_' + productsMap[id]['productSizeID']]['IDs'] = [id];
                        }
                        });

                        let slotRemoved = false;

                        for (const key of Object.keys(originalProdWidgQuantities)) {
                            if (!slotRemoved) {
                                if (prodWidgQuantities[key]) {
                                    if (prodWidgQuantities[key]['count'] < originalProdWidgQuantities[key]['count']) {
                                        slotRemoved = true;
                                        delete listItem['timeslotOverlap'][slot];
                                        if (Object.keys(listItem['timeslotOverlap']).length === 0) {
                                            timesAreAvail = false;
                                        }
                                    }
                                    else {
                                        for (const originalID of originalProdWidgQuantities[key]['IDs']) {
                                            if (!prodWidgQuantities[key]['availIDs'].includes(originalID)) {
                                                let found = false;
                                                for (const proWidgID of prodWidgQuantities[key]['availIDs']) {
                                                    if ((!IDsUsedForSwap[slot] || !IDsUsedForSwap[slot]['IDs'].includes(proWidgID)) && !originalProdWidgQuantities[key]['IDs'].includes(proWidgID) && !found && !allOriginalRentalIDs.includes(proWidgID)) {
                                                        listItem['timeslotOverlap'][slot]['swappableProductWidgets'].push({ originalID, newID: proWidgID });
                                                        IDsUsedForSwap = this.addIDToIDsUsedForSwap(proWidgID, IDsUsedForSwap, slot);
                                                        found = true;
                                                    }
                                                }
                                                if (!found) {
                                                    delete listItem['timeslotOverlap'][slot];
                                                if (Object.keys(listItem['timeslotOverlap']).length === 0) {
                                                    timesAreAvail = false;
                                                }
                                                break;
                                                }
                                            }
                                        }
                                    }
                                }
                                else {
                                    delete listItem['timeslotOverlap'][slot];
                                    slotRemoved = true;
                                    if (Object.keys(listItem['timeslotOverlap']).length === 0) {
                                        timesAreAvail = false;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return { sortedList, timesAreAvail, IDsUsedForSwap };
    }


    // Filters out any times that dont have enouh product widgets for cart item
    public async filterOverlappingTimeSlotForCartWidget(sortedList: SortedList, productsMap: object, cartProductWidgetIDs: string[], allOriginalIDs: string[], IDsUsedForSwap: IDsUsedForSwap): Promise<{sortedList: SortedList, timesAreAvail: boolean}> {
        let timesAreAvail = true;
        // Dont loop through both list otherwise the cart swappable items will be ran twice and will fail
        //this needs to be moved after the overlpapping time are comimed
        for (const slot of Object.keys(Object.values(sortedList)[0]['timeslotOverlap'])) {
            cartWidgetIDLoop: for (const id of cartProductWidgetIDs) {
                const rentalAvailResult = await this._checkProductRentalAvail(this.resultSet[productsMap[id]['productGroupID']]['products'][id], Object.values(sortedList)[0]['timeslotOverlap'][slot]['dayStart'], Object.values(sortedList)[0]['timeslotOverlap'][slot]['dayEnd'], Object.values(sortedList)[0]['timeslotOverlap'][slot]['type']);

                // If cart product widget is not in the rental avail then look for a replacement
                if (!rentalAvailResult) {
                    let foundReplacement = false;
                    // Look for a replacement ID
                    const promises = Object.values(this.resultSet[productsMap[id]['productGroupID']]['products']).map(async newProduct => {
                        if (!foundReplacement) {
                            // if it is the same size, not the same ID, has rental availability and is not an ID being used
                            if (productsMap[id]['productSizeID'] == newProduct['productSizeID']) {
                                // let newRentalAvail = algoRes['resultSet'][productsMap[newProduct['productID']]['productGroupID']]['products'][newProduct['productID']]['main']['rentalAvail'][0];
                                const rentalAvailReplaceResult = await this._checkProductRentalAvail(this.resultSet[productsMap[newProduct['productID']]['productGroupID']]['products'][newProduct['productID']], Object.values(sortedList)[0]['timeslotOverlap'][slot]['dayStart'], Object.values(sortedList)[0]['timeslotOverlap'][slot]['dayEnd'], Object.values(sortedList)[0]['timeslotOverlap'][slot]['type']);
                                if (rentalAvailReplaceResult) {
                                    // Make sure its not part of the original IDs
                                    if (newProduct['productID'] != id && !allOriginalIDs.includes(newProduct['productID']) && (!IDsUsedForSwap[slot] || !IDsUsedForSwap[slot]['IDs'].includes(newProduct['productID']))) {
                                        Object.values(sortedList)[0]['timeslotOverlap'][slot]['swappableProductWidgets'].push({ originalID: id, newID: newProduct['productID'] });
                                        IDsUsedForSwap = this.addIDToIDsUsedForSwap(newProduct['productID'], IDsUsedForSwap, slot);
                                        foundReplacement = true;
                                    }
                                }
                            }
                        }
                    });

                    // Wait for all promises to resolve
                    await Promise.all(promises);

                    if (!foundReplacement) {
                        delete Object.values(sortedList)[0]['timeslotOverlap'][slot];
                        if (Object.keys(sortedList[Object.keys(sortedList)[0]]['timeslotOverlap']).length === 0) {
                            timesAreAvail = false;
                        }
                        break cartWidgetIDLoop;
                    }
                }
            }
        }

        return { sortedList, timesAreAvail }
    }

    private addIDToIDsUsedForSwap(newID: string, IDsUsedForSwap: IDsUsedForSwap, slot: string): IDsUsedForSwap {
        if (!IDsUsedForSwap[slot]) {
            IDsUsedForSwap[slot] = { IDs: [] };
        }
        IDsUsedForSwap[slot].IDs.push(newID);
        return IDsUsedForSwap;
    }


    public combineAllOverlappingTimeslotsAndRestructure(sortedList: SortedList): { allSlots: ReconstructedOverlappingTimeslots, allUniqueHours: number[] } {
        let listItemCount: number = 0;
        const allSlots: ReconstructedOverlappingTimeslots = {};
        const allUniqueHours: number[] = [];

        Object.keys(sortedList).forEach(listItemID => {
            listItemCount += 1;
            Object.keys(sortedList[listItemID]['timeslotOverlap']).forEach(slotOverlapID => {
                const timeslot = sortedList[listItemID]['timeslotOverlap'][slotOverlapID];

                // We only care about the count
                if (allSlots[slotOverlapID]) {
                    allSlots[slotOverlapID]['combineCount'] += 1;
                    allSlots[slotOverlapID]['productGroupSizes'][listItemID] = sortedList[listItemID]['timeslotOverlap'][slotOverlapID];
                }
                else {
                    allSlots[slotOverlapID] = {
                        combineCount: 1,
                        hourLength: timeslot.hourLength,
                        availDaySpan: timeslot.availDaySpan,
                        dayStart: timeslot.dayStart,
                        dayEnd: timeslot.dayEnd,
                        type: timeslot.type,
                        productGroupSizes: {
                            [listItemID]: timeslot
                        },
                        swappableItems: [],
                        originalProductIDs: [...timeslot.originalProductIDs],
                        originalProductWidgetIDs: [...timeslot.originalProductWidgetIDs]
                    };
                    if (!allUniqueHours.includes(sortedList[listItemID]['timeslotOverlap'][slotOverlapID]['hourLength'])) {
                        allUniqueHours.push(sortedList[listItemID]['timeslotOverlap'][slotOverlapID]['hourLength'])
                    }
                }
            })
        })

        // Remove any slots that do not support all cart items
        Object.keys(allSlots).forEach(slotID => {
            if (allSlots[slotID]['combineCount'] < listItemCount) {
                delete allSlots[slotID]
            }
            else {
                delete allSlots[slotID]['combineCount'];
            }
        })
        return { allSlots, allUniqueHours }
    }


    public getSwappableItems(sortedList: SortedList): SortedList {
        Object.keys(sortedList).forEach(slotID => {
            Object.keys(sortedList[slotID]['productGroupSizes']).forEach(productGroupSizeID => {

                sortedList[slotID]['swappableItems'].push(...sortedList[slotID]['productGroupSizes'][productGroupSizeID]['swappableProductWidgets']);
                sortedList[slotID]['swappableItems'].push(...sortedList[slotID]['productGroupSizes'][productGroupSizeID]['swappableItems']);
            })
        })
        return sortedList
    }


    protected _checkProductRentalAvail(algoProductInfo: object, start: DateTime, end: DateTime, slotType: TimeslotType): boolean {
        let passTimeCheck = false;
        let rentalAvailArray = [];

        if (slotType == '24hr' && algoProductInfo['secondary']['24hrOnSingleSearchDay']['rentalAvail'].length > 0) {
            rentalAvailArray = algoProductInfo['secondary']['24hrOnSingleSearchDay']['rentalAvail'];
        }
        else {
            rentalAvailArray = algoProductInfo['main']['rentalAvail'];
        }

        rentalAvailArray.forEach(rentalAvailItem => {
          // If cart product widget is not in the rental avail then look for a replacement
            if (rentalAvailItem && rentalAvailItem['windowStart'] <= start && end <= rentalAvailItem['windowEnd']) {
                passTimeCheck = true;
            }
        })

        return passTimeCheck
    }

    public checkProductRentalAvailability(groupID: string, productID: string, start: DateTime, end: DateTime, slotType: string): boolean {

      if (!(groupID in this._resultSet)
        || !(productID in this._resultSet[groupID]['products'])
      ) {
        return false
      }

      return timeslotTypeGuard(slotType) && this._checkProductRentalAvail(this.resultSet[groupID]['products'][productID], start, end, slotType)
    }

} // End of class
