<!-- Custom Booking Page -->
<div id="customFlowComp" [ngStyle]="{'margin-top': !isMobile ? '4em' : '0px'}" class="fluid-container d-flex align-items-center justify-content-center flex-column">
    <!--  iframe -->
    <div id="customFlowCont" style="display: flex; flex-direction: column;">
        <!-- Loader | Customer side-->
        <ng-container *ngIf="bookingFlowIsLoading">
            <div style="position: relative; height: 600px;">
                <div style="position: absolute; left: 50%; bottom:40%; z-index: 3;">
                    <div class="spinner-border" role="status" style="width: 3em !important; height: 3em !important">
                        <span class="visually-hidden" i18n>Loading...</span>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="!bookingFlowIsLoading">
            <!-- Header -->
            <app-booking-header [templateObj]="templateObj" [isScreenLarge]="!isMobile" [cartObj]="cartObj"
                (bookingFlowNavigate)="processNavigationEvent($event)"
                [currentBookingFlowNavigationType]="currentBookingFlowNavigationType">
            </app-booking-header>

            <section
                *ngIf="(currentBookingFlowNavigationType !== bookingFlowContentType.Cart) && (currentBookingFlowNavigationType !== bookingFlowContentType.CheckoutSuccess) && (currentBookingFlowNavigationType !== bookingFlowContentType.PaymentIntent) && (currentBookingFlowNavigationType !== bookingFlowContentType.CustomerInfo)"
                style="position: relative; padding-bottom: 1em; border-bottom: solid #ffffff;">
                <div style="margin-left: 12%; margin-right: 12%;">
                    <app-date-range *ngIf="!bookingFlowIsLoading"
                        (dateRangeInputChanged)="dateRangeInputChanged($event)"
                        [isMobile]="isMobile" [selectedStartDate]="selectedStartDate" [selectedEndDate]="selectedEndDate"
                        [locations]="locationsArray"
                        [cartObj]="cartObj" [locationMap]="locationsMap"
                        [isDev]="isDev" [algoMetadata]="algoMetadata" [selectedLocationID]="selectedLocationID" [showingViaBookingFlow]="true" [dateRangeConfig]="dateRangeConfig">
                    </app-date-range>
                </div>

                <!-- Loader | Customer side-->
                <div *ngIf="availabilityLoadingSpinner" style="position: absolute; left: 89%; bottom:34%; z-index: 3;">
                    <div class="spinner-border" role="status" style="width: 2em !important; height: 2em !important">
                        <span class="visually-hidden" i18n>Loading...</span>
                    </div>
                </div>
            </section>

            <!-- Breadcrumbs -->
            <ng-container *ngIf="currentBookingFlowNavigationType === bookingFlowContentType.Catalog">
                <app-booking-catalog-breadcrumbs [templateObj]="templateObj" [currentContentID]="currentContentID"
                    [bookingPagesCollectionMap]="customerBookingPagesMap" [isCreator]="false"
                    [locationID]="selectedLocationID" [dayStart]="selectedStartDate" [dayEnd]="selectedEndDate"
                    [productGroupCollectionMap]="productGroupsMap"></app-booking-catalog-breadcrumbs>
            </ng-container>

            <!-- showTemplate -->
            <ng-container
                *ngIf="currentBookingFlowNavigationType === bookingFlowContentType.Catalog && !this.showingInventoryPage">
                <section id="template">
                    <app-booking-catalog-view [templateObj]="templateObj"
                        [productSizeTypeCollectionMap]="productSizeTypesMap"
                        [bookingPagesCollectionMap]="customerBookingPagesMap"
                        [productGroupCollectionMap]="productGroupsMap" [productsCollectionMap]="productsMap"
                        [dayStart]="selectedStartDate" [dayEnd]="selectedEndDate" [locationID]="selectedLocationID"
                        [currentContentID]="currentContentID" [isMobile]="isMobile">
                    </app-booking-catalog-view>
                </section>
            </ng-container>

            <section
                *ngIf="currentBookingFlowNavigationType === bookingFlowContentType.DateRangeSearch && !this.showingInventoryPage">
                <app-search-template-by-date (chosenProductGroupID)="navigateToInvPage($event)" [startDate]="selectedStartDate" [endDate]="selectedEndDate"
                    [dateRangeContentCards]="dateRangeContentCards">
                </app-search-template-by-date>
            </section>

            <section *ngIf="showingInventoryPage">
                <ng-container *ngIf="currentBookingFlowNavigationType === bookingFlowContentType.DateRangeSearch">
                    <button id="searchByDateOptionBtn" type="button" (click)="showDatedSearchPage()"
                        style="background-color: #33628b; color: white;" class="btn mr-2"><i
                            class="fa fa-calendar bounce"></i>
                        <span class="underline ml-1 customerViewOptionBtns" i18n>Back to date range</span>
                    </button>
                </ng-container>

                <ng-container *ngIf="currentBookingFlowNavigationType === bookingFlowContentType.CartItemEdit">
                    <button id="searchByDateOptionBtn" type="button" (click)="navigateToCart()"
                        style="background-color: #33628b; color: white;" class="btn mr-2"><i id="cartIcon"
                            class="fa fa-shopping-cart" aria-hidden="true"></i>
                        <span class="underline ml-1 customerViewOptionBtns" i18n>Back to cart</span>
                    </button>
                </ng-container>

                <app-inventory-page-wrapper (navigateToCartView)="navigateToCart()"
                    [isLoading]="bookingFlowIsLoading"
                    [locationsArray]="locationsArray"
                    [numberOfDaysChosen]="rentalSearchDaySpan" [companyID]="templateObj?.companyID"
                    [inventoryPageMap]="inventoryPageMap" [widgetMap]="widgetsMap"
                    [productsMap]="productsMap" [productGroupsMap]="productGroupsMap"
                    [productGroups]="productGroupsArray" [widgetsArray]="widgetsArray"
                    [productSizeTypesMap]="productSizeTypesMap" [productSizesMap]="productSizesMap"
                    [templateID]="templateID" [cartObj]="cartObj" [locationsMap]="locationsMap"
                    [productsArray]="productsArray"
                    [availability]="availability" (itemsAddedToNewCart)="handleItemsBeingAddedToNewCart($event)"
                    (continueShopping)="continueShopping($event)" (algoResUpdated)="algoResUpdated($event)">
                </app-inventory-page-wrapper>
            </section>

            <!-- CART -->
            <ng-container *ngIf="currentBookingFlowNavigationType === bookingFlowContentType.Cart">
                <section style="min-height: 30vh;">
                    <app-cart style="height: 100%" [cartObj]="cartObj"
                        (cartToCustomerInfo)="customerInfoLoaded = false; navigateToCustInfo()"
                        [productMap]="productsMap" [productGroupMap]="productGroupsMap"
                        [widgetMap]="widgetsMap" [inventoryPageMap]="inventoryPageMap"
                        [locationsArray]="locationsArray" [discountCodeMap]="discountCodeMap"
                        [locationMap]="locationsMap" [companyObj]="companyObj" [companyID]="templateObj?.companyID"
                        [productsArray]="productsArray" [productGroupsCollection]="productGroupsArray"
                        [productSizeTypesMap]="productSizeTypesMap" [companyName]="templateObj?.companyName"
                        [cartWidgetList]="cartWidgetList" [widgetArray]="widgetsArray"
                        [productSizesMap]="productSizesMap" (updateCart)="this.cartObj = $event"
                        [pmtLinkRentalDoc]="pmtLinkRentalDoc" [templateID]="templateID"
                        (cartItemEdit)="navigateToCartEditView($event)" (updateAvailabilityFromCart)="updateAvailabilityFromCart($event)"
                        (getCollectionUpdates)="processCollectionPromises()">
                    </app-cart>
                </section>
            </ng-container>

            <!-- CUSTOMER INFO -->
            <section *ngIf="currentBookingFlowNavigationType === bookingFlowContentType.CustomerInfo">
                <app-customer-info [cartObj]="cartObj"
                    (customerInfoAddedToCart)="paymentIntentInfoLoaded = false; navigateToPaymentIntent($event)"
                    (customerInfoAddedToCartWOPayment)="paymentIntentInfoLoaded = false; navigateToPaymentIntent(false, true)"
                    (goBackToCart)="navigateToCart()" [isDev]="isDev" [isPartner]="isPartner" (updateCart)="this.cartObj = $event"
                    [userBelongsToCompany]="userBelongsToCompany" [pmtLinkRentalDoc]="pmtLinkRentalDoc"
                    (goToPaymentIntent)="paymentIntentInfoLoaded = false; navigateToPaymentIntent()">
                </app-customer-info>
            </section>

            <!-- PAYMENT INTENT -->
            <section *ngIf="currentBookingFlowNavigationType === bookingFlowContentType.PaymentIntent">
                <app-payment-intent [cartObj]="cartObj" [template]="templateObj"
                    (paymentIDAddedToCart)="checkoutSuccessLoaded = false; navigateToSuccessPayment()"
                    (goBackToCart)="navigateToCart()" [discountCodeMap]="discountCodeMap" [isTestStripe]="isTestStripe"
                    [isSkipPayment]="isSkipPayment" [pmtLinkRentalDoc]="pmtLinkRentalDoc"
                    (goBackToCustomerInfo)=" customerInfoLoaded = false; navigateToCustInfo()"
                    [productMap]="productsMap" [productGroupMap]="productGroupsMap">
                </app-payment-intent>
            </section>

            <!--CHECKOUT SUCCESS -->
            <section *ngIf="currentBookingFlowNavigationType === bookingFlowContentType.CheckoutSuccess">
                <app-checkout-success [cartObj]="cartObj" [templateID]="templateID" [locationMap]="locationsMap"
                    (goBackToCart)="navigateToCart()" [pmtLinkRentalDoc]="pmtLinkRentalDoc"
                    (goBackToCustomerInfo)=" customerInfoLoaded = false; navigateToCustInfo()"
                    (goBackToPaymentIntent)="paymentIntentInfoLoaded = false; navigateToPaymentIntent()"
                    (getCollectionUpdates)="processCollectionPromises()"
                    (provideNewCart)="provideNewCart()">
                </app-checkout-success>
            </section>
        </ng-container>

        <!-- Footer -->
        <app-booking-footer></app-booking-footer>
    </div>
</div>
