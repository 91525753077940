<table id="paymentReceipt" style="display: none">
  <thead>
    <tr>
      <th>Product</th>
      <th>Items</th>
      <th>Description</th>
      <th>Total
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="rentalReady !== undefined && rentalReady.products !== undefined">
      <!--Product with addons-->
      <ng-container *ngFor="let product of rentalReady.products; let i = index">
        <tr>
          <td [rowSpan]="product.rowspan">{{ product.productName }} - {{ product.productSize }}</td>
          <td> Rental -
            <ng-container *ngIf="rentalReady.rentalType === 'byDay'"> By Day</ng-container>
            <ng-container *ngIf="rentalReady.rentalType === 'byHour'"> By Hour</ng-container>
          </td>
          <td>
            {{
              rentalReady.rentalType == "byHour" ?
              (rentalReady.dayStart | luxonDisplayPipe : 'DATETIME_MED' : ((rental?.rentalLocationID) ?
              locationMap[rental.rentalLocationID]['timezone'] : defaultLocation?.timezone))
              :
              (rentalReady.dayStart | luxonDisplayPipe : (rental?.cartObj ? 'DATETIME_MED' : 'DATE_MED') : ((rental?.rentalLocationID) ?
              locationMap[rental.rentalLocationID]['timezone'] : defaultLocation?.timezone))
            }}
              -
            {{
              rentalReady.rentalType == "byHour" ?
              (rentalReady.dayEnd | luxonDisplayPipe : 'TIME_SIMPLE' : ((rental?.rentalLocationID) ?
              locationMap[rental.rentalLocationID]['timezone'] : defaultLocation?.timezone))
              :
              (rentalReady.dayEnd | luxonDisplayPipe : (rental?.cartObj ? 'DATETIME_MED' : 'DATE_MED') : ((rental?.rentalLocationID) ?
              locationMap[rental.rentalLocationID]['timezone'] : defaultLocation?.timezone))
            }}
            
            {{rental?.rentalLocationID ? locationMap[rental.rentalLocationID]?.timezoneName : defaultLocation?.timezoneName}}
          </td>
          <td>
            <ng-container *ngIf="product.cost !== (product.price * product.days)"> {{product.cost |
              currency}}</ng-container>
            <ng-container *ngIf="product.cost == (product.price * product.days)">{{ product.cost |
              currency }}</ng-container>
          </td>

        </tr>

        <tr *ngFor="let addon of product.addons"
          [ngClass]="{'hidden': (addon.addonType === 'select' && (addon.optionsSelect[0] === undefined || addon.optionsSelect[0].description === 'N/S')) || (addon.addonType === 'heightInput' ||addon.addonType === 'weightInput') || (addon.addonType === 'checkbox' && addon.optionsSelect[0].qty === 0) || (addon.addonType === 'textarea') || ((addon.addonType === 'quantity' || addon.addonType === 'product') && addon.optionsSelect[0].qty === 0) }">
          <td>
            <ng-container>
              {{ addon.title }} {{addon.addonType == "product" && addon.optionsSelect[0].size != "NA" ? "- " +
              addon.optionsSelect[0].size : ""}}
            </ng-container>
          </td>
          <td>
            <ng-container
              *ngIf="(addon.addonType === 'select' || addon.addonType === 'radios') && addon.optionsSelect[0] !== undefined && addon.optionsSelect[0].description !== ''">
              {{ addon.optionsSelect[0].description }}
            </ng-container>
            <ng-container
              *ngIf="(addon.addonType === 'checkbox' || addon.addonType === 'quantity' || addon.addonType === 'number')">
              {{ addon.optionsSelect[0].qty ? addon.optionsSelect[0].qty : "---" }}
            </ng-container>
            <ng-container *ngIf="(addon.addonType === 'price')">
              <em>{{addon.description}}</em>
            </ng-container>
            <ng-container *ngIf="(addon.addonType === 'tip')">
              <em>{{addon.description}}</em>
            </ng-container>
            <ng-container *ngIf="(addon.addonType === 'product')">
              {{addon.optionsSelect[0].qty}} @ {{addon.optionsSelect[0].price | currency}}
            </ng-container>
          </td>
          <td>
            <ng-container
              *ngIf="(addon.addonType === 'select' || addon.addonType === 'radios') && addon.optionsSelect[0] !== undefined ">
              {{addon.optionsSelect[0].price * dayDiff | currency}}
            </ng-container>
            <ng-container
              *ngIf="(addon.addonType === 'number' || addon.addonType === 'checkbox' || addon.addonType === 'quantity')  && addon.optionsSelect[0].qty !== null && addon.optionsSelect[0] !== undefined ">
              {{addon.optionsSelect[0].price * addon.optionsSelect[0].qty | currency}}
            </ng-container>
            <ng-container *ngIf="(addon.addonType === 'price' || addon.addonType === 'tip')">
              {{addon.optionsSelect[0].price | currency}}
            </ng-container>
            <ng-container *ngIf="(addon.addonType === 'product')">
              {{addon.optionsSelect[0].price * addon.optionsSelect[0].qty | currency}}
            </ng-container>
          </td>
        </tr>
      </ng-container>
      <!--Cart Widgets-->
      <ng-container *ngIf="rentalReady.cartWidgetRowspan > 0">
        <tr>
          <th [colSpan]="4">
            Cart Addons
          </th>
        </tr>
        <ng-container *ngFor="let addon of rentalReady.cartWidgetList; let i = index">
          <tr>
            <td *ngIf="i == 0" [rowSpan]="rentalReady.cartWidgetRowspan">
              Addons
            </td>
            <td>
              <ng-container>
                {{ addon.title }} {{addon.addonType == "product" && addon.optionsSelect[0].size != "NA" ? "- " +
                addon.optionsSelect[0].size : ""}}
              </ng-container>
            </td>
            <td>
              <ng-container
                *ngIf="(addon.addonType === 'select' || addon.addonType === 'radios') && addon.optionsSelect[0] !== undefined && addon.optionsSelect[0].description !== ''">
                {{ addon.optionsSelect[0].description }}
              </ng-container>
              <ng-container
                *ngIf="(addon.addonType === 'checkbox' || addon.addonType === 'quantity' || addon.addonType === 'number')">
                {{ addon.optionsSelect[0].qty ? addon.optionsSelect[0].qty : "---" }}
              </ng-container>
              <ng-container *ngIf="(addon.addonType === 'price')">
                <em>{{addon.description}}</em>
              </ng-container>
              <ng-container *ngIf="(addon.addonType === 'tip')">
                <em>{{addon.description}}</em>
              </ng-container>
              <ng-container *ngIf="(addon.addonType === 'product')">
                {{addon.optionsSelect[0].qty}} @ {{addon.optionsSelect[0].price | currency}}
              </ng-container>
            </td>
            <td>
              <ng-container
                *ngIf="(addon.addonType === 'select' || addon.addonType === 'radios') && addon.optionsSelect[0] !== undefined ">
                {{addon.optionsSelect[0].price * dayDiff | currency}}
              </ng-container>
              <ng-container
                *ngIf="(addon.addonType === 'number' || addon.addonType === 'checkbox' || addon.addonType === 'quantity')  && addon.optionsSelect[0].qty !== null && addon.optionsSelect[0] !== undefined ">
                {{addon.optionsSelect[0].price * addon.optionsSelect[0].qty | currency}}
              </ng-container>
              <ng-container *ngIf="(addon.addonType === 'price' || addon.addonType === 'tip')">
                {{addon.optionsSelect[0].price | currency}}
              </ng-container>
              <ng-container *ngIf="(addon.addonType === 'product')">
                {{addon.optionsSelect[0].price * addon.optionsSelect[0].qty | currency}}
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="rental.rentalNumber == 14 && rental.companyID == 'f5Ui6uQNI4RnZwM6gaMx'">
        <tr *ngFor="let changes of changeslogs">
          <ng-container *ngIf="changes.difference !== 4507.87">
            <td>ADJUSTMENT</td>
            <td>{{ changes.timestamp | luxonDisplayPipe : 'DATETIME_MED' : (rental?.rentalLocationID ? locationMap[rental.rentalLocationID]['timezone'] : defaultLocation?.timezone) }}</td>
            <td>{{changes.reason ? changes.reason : "Adjustment"}}</td>
            <td>{{changes.negative ? ("-$" + changes.difference) : ("$" + changes.difference)}}</td>
          </ng-container>
        </tr>
      </ng-container>
      <tr>
        <td></td>
        <td></td>
        <td>
          Subtotal <br />
          <ng-container *ngIf="rentalReady.discount > 0">Discounts <br /> </ng-container>
          Taxes & Fees
        </td>
        <td>
          {{ rentalReady.subtotal | currency }}<br />
          <ng-container *ngIf="rentalReady.discount  > 0">
            -{{ rentalReady.discount | currency }}<br /></ng-container>
          {{ rentalReady.taxes | currency }}
        </td>
      </tr>
      <ng-container *ngIf="rental.rentalNumber == 14 && rental.companyID == 'f5Ui6uQNI4RnZwM6gaMx'">
        <tr *ngFor="let changes of changeslogs">
          <ng-container *ngIf="changes.difference === 4507.87">
            <td>ADJUSTMENT</td>
            <td>{{ changes.timestamp | luxonDisplayPipe : 'DATETIME_MED' : (rental?.rentalLocationID ? locationMap[rental.rentalLocationID]['timezone'] : defaultLocation?.timezone) }}</td>
            <td>{{changes.reason ? changes.reason : "Adjustment"}}</td>
            <td>$4628.51</td>
          </ng-container>
        </tr>
      </ng-container>
      <div *ngIf="(rental.rentalNumber !== '14' && rental.companyID === 'f5Ui6uQNI4RnZwM6gaMx')">
        <tr *ngFor="let changes of changeslogs">
          <td>ADJUSTMENT</td>
          <td>{{ changes.timestamp | luxonDisplayPipe : 'DATETIME_MED' : (rental?.rentalLocationID ? locationMap[rental.rentalLocationID]['timezone'] : defaultLocation?.timezone) }}</td>
          <td>{{changes.reason ? changes.reason : "Adjustment"}}</td>
          <td>{{changes.negative ? ("-$" + changes.difference) : ("$" + changes.difference)}}</td>
        </tr>
      </div>

      <tr *ngFor="let c of charges">
        <td>{{"Payment" | uppercase}}</td>
        <td>
          {{c.created | luxonDisplayPipe : 'DATETIME_MED' : (rental?.rentalLocationID ? locationMap[rental.rentalLocationID]['timezone'] : defaultLocation?.timezone)}}
        </td>
        <td>{{c.metadata.title}}</td>
        <td>{{(c.amount / 100).toFixed(2) | currency}}</td>
      </tr>
      <tr *ngFor="let d of deposit">
        <td>{{d.type | uppercase}}</td>
        <td>{{d.created | luxonDisplayPipe : 'DATETIME_MED' : (rental?.rentalLocationID ? locationMap[rental.rentalLocationID]['timezone'] : defaultLocation?.timezone)}}</td>
        <td>{{d.metadata.title}}</td>
        <td>{{(d.amount / 100).toFixed(2) | currency}}</td>
      </tr>
      <tr *ngFor="let r of refunds">
        <td>{{r.type | uppercase}}</td>
        <td>{{r.created | luxonDisplayPipe : 'DATETIME_MED' : (rental?.rentalLocationID ? locationMap[rental.rentalLocationID]['timezone'] : defaultLocation?.timezone)}}</td>
        <td>{{r.metadata.reason}}</td>
        <td>-{{( r.amount / 100).toFixed(2) | currency }}</td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td>
          Booking Total <br>
          <p *ngIf="totalPaid > 0">Total Amount Paid</p>
        </td>
        <td>
          {{ rentalReady.cost | currency }} <br />
          <p *ngIf="totalPaid > 0">{{ totalPaid | currency }}</p>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

<!-- Second Table-->
<table id="refundTable" style="display: none;">
  <thead>
    <tr>
      <th>Type</th>
      <th>Date</th>
      <th>Description</th>
      <th>Amount</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Refund</td>
      <td>Dec 19, 2022</td>
      <td>Refund for Rental</td>
      <td>-$100.00</td>
    </tr>
    <tr>
      <td>Charge</td>
      <td>Dec 19, 2022</td>
      <td>Charge for Rental</td>
      <td>$100.00</td>
    </tr>
  </tbody>

</table>