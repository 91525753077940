/* This service provides methods to help interact between how Date & Time is stored in our database, and the Luxon time library */

import { Injectable } from '@angular/core';
import { DateTime, DateTimeUnit, FixedOffsetZone, Zone } from 'luxon'; // Used for all time related operations
import * as moment from 'moment';
import { Timestamp } from 'firebase/firestore';

// Models
import { FirestoreDateFields, FirestoreDateObject } from 'src/app/models/time.model';

/* IMPORTANT NOTE - Luxon: Luxon DateTime objects are immutable
  Each time you use a method that modifies the DateTime, such as plus, minus, or set,
  Luxon creates a new DateTime object. This ensures that the original DateTime object remains unchanged.
*/

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  /* General Purpose */

  /**
* @description Use this method to combine a time such as 14:00 with a Luxon Date Time Obj
* @IMPORTANT Assigning a time / unit of time to a Datetime object will set it to that time based of your computers timezone if no timezone was provided beforehand.
If timezone matters to you, set the timezone on the Datetime Object prior to using this method. !!*/
  combineDateAndTime(time, dateTimeObj,) {
    let timeArr = time.split(" ")[0].split(":") /* Parse time string by space then colon */
    return dateTimeObj.set({ hour: timeArr[0], minute: timeArr[1], seconds: 0, millisecond: 0 })
  }


  combineDateAndTimeWithTwoObjects(timeObj, dateObj) {
    // let timeArr = time.split(" ")[0].split(":") /* Parse time string by space then colon */
    return dateObj.set({ hour: timeObj['hour'], minute: timeObj['minute'], seconds: 0, millisecond: 0 })
  }

  /**
 * @description Converts a firestore OR Epoch timestamp and returns a luxon DateTime object
 * @notes When pulling data from firestore, more often than not we store Date time as a firestore timestamp or epoch seconds. This method converts that timestamp into a luxon Date Time Object*/
  convertTimestampToLuxon(timestamp) {
    let conversionMethodFound = false;

    // Check to see if obj is already a DateTime
    if (timestamp instanceof DateTime) {
      conversionMethodFound = true;
      return this.clearSeconds(timestamp); // If so, return as is (allows this method to be used as a wrapper just in case)
    }
    else if (timestamp instanceof Date){
      conversionMethodFound = true;
      return this.clearSeconds(DateTime.fromJSDate(timestamp));
    }
    else if (moment.isMoment(timestamp)) {
      conversionMethodFound = true;
      return this.clearSeconds(DateTime.fromJSDate(timestamp.toDate()));
    }


    // Convert firestore timestamp to Luxon DateTime
    if(timestamp
      && FirestoreDateFields.NanoSeconds in timestamp
      && FirestoreDateFields.Seconds in timestamp
    ){
      const stringConcatedSeconds = timestamp.seconds + '.' + timestamp.nanoseconds; // concat seconds and nanoseconds together
      return this.clearSeconds(DateTime.fromSeconds(Number(stringConcatedSeconds))) // convert the string into a number, then create Luxon DateTime obj from seconds
    }

    // Convert epoch seconds to Luxon DateTime
    else if(typeof(timestamp) === 'number' && Number.isFinite(timestamp)){ // handles if epoch seconds are passed in
      conversionMethodFound = true;
      return this.clearSeconds(DateTime.fromSeconds(timestamp));
    }
    else if(typeof(timestamp) === 'string'){
      conversionMethodFound = true;
      return this.clearSeconds(DateTime.fromISO(timestamp));
    }

    if(!conversionMethodFound){
      throw Error('Could not convert to Luxon. Please check your input. ')
    }
  }

  convertToJavascriptDate(dateTime: DateTime | moment.Moment | FirestoreDateObject) {
    // If a new type is needed, please feel free to add it - following the below pattern
    let conversionMethodFound = false;

    // Test if the object is a JS Date
    if(Object.prototype.toString.call(dateTime) === '[object Date]'){
      conversionMethodFound = true;
      return dateTime;
    }

    // Converts from LUXON -> JS Date
    else if (dateTime instanceof DateTime) {
      conversionMethodFound = true;
      return dateTime.toJSDate();
    }

    // Converts from MOMENT -> JS Date
    else if (moment.isMoment(dateTime)) {
      conversionMethodFound = true;
      return dateTime.toDate();
    }

    // Converts from Firestore Timestamp -> JS Date
    else if (dateTime
      && FirestoreDateFields.NanoSeconds in dateTime
      && FirestoreDateFields.Seconds in dateTime
    ) {
      conversionMethodFound = true;
      return this.convertTimestampToLuxon(dateTime).toJSDate();
    }

    if(!conversionMethodFound){

      throw Error('Could not convert to JS Date. Please check the type of object you are passing in.')
    }
  }

  convertToFirestoreTimestamp(dateTime: any) {
    // If a new type is needed, please feel free to add it - following the below pattern

    // Test if the object is a JS Date
    if (Object.prototype.toString.call(dateTime) === '[object Date]') {
      return Timestamp.fromDate(dateTime);
    }

    else if (moment.isMoment(dateTime)) {
      return Timestamp.fromDate(dateTime.toDate());
    }

    // If it is already a firestore time stamp then return it
    else if (dateTime
      && FirestoreDateFields.NanoSeconds in dateTime
      && FirestoreDateFields.Seconds in dateTime
    ) {
      return dateTime
    }

    throw Error('Could not convert to firestore timestamp. Please check the type of object you are passing in.')
  }

  /**
   * @description Sets the seconds and miliseconds in the DateTime object to 0
   * @notes Sometimes when adding by larger units such as days, seconds and miliseconds are added in that calculation.
   * When attempting to compare to a value that's suppossed to be equal such as 8am -> 8am, they will no longer be equal due to seconds and miliseconds existing on the DT object
   * to counter this, we can use this method to clear the seconds and miliseconds - if we don't need them.*/
  clearSeconds(dateTimeObj) {
    return dateTimeObj.set({ seconds: 0, millisecond: 0 })
  }

  /**
 * @description A simple switch case to give you the correct weekDay as a string when supplied with a number.
 * @notes In our database we currently store days differently than in Luxon. (Luxon treats monday as 1 & Sunday as 7)*/
  getDbDayOfWeekFromLuxon(luxonDOTW) {
    let weekDay
    switch (luxonDOTW) {
      case 1:
        weekDay = "Monday"
        // code block
        break;
      case 2:
        weekDay = "Tuesday"
        // code block
        break;
      case 3:
        weekDay = "Wednesday"
        break;
      case 4:
        weekDay = "Thursday"
        break;
      case 5:
        weekDay = "Friday"
        break;
      case 6:
        weekDay = "Saturday"
        break;
      case 7:
        weekDay = "Sunday"
        break;
    }
    return weekDay;
  }

  /* Availability Specific */

  /**
   * @description Using location information, determine the Shop open and close by the SDS and SDE DOTW */
  getDateTimeByShopHour(locationObj, sds, sde, locationTimezone) {
    let sdsWeekday = this.getDbDayOfWeekFromLuxon(sds.weekday) // this method returns a string value for the DOTW ex. "Monday"
    let sdeWeekday = this.getDbDayOfWeekFromLuxon(sde.weekday)

    // let sdsOpeningTime, sdsClosingTime, sdeOpeningTime, sdeClosingTime;
    let sdsOpeningDateTime, sdsClosingDateTime, sdeOpeningDateTime, sdeClosingDateTime;

    // Get shop hour schedule for SDS & SDE
    // Compare the SDS & SDE weekdays to a locations day schedule. Once the correct weekday is found
    locationObj['scheduleWeek'].forEach((day) => {
      if (day.day === sdsWeekday) {
        // sdsOpeningDateTime = this.combineDateAndTime(day.startHour, sds).setZone(locationTimezone)
        // sdsOpeningDateTime = this.combineDateAndTime(day.startHour, sds)
        sdsOpeningDateTime = this.combineDateAndTime(day.startHour, sds).setZone(locationTimezone, { keepLocalTime: true })
        sdsClosingDateTime = this.combineDateAndTime(day.endHour, sds).setZone(locationTimezone, { keepLocalTime: true })
      }
      if (day.day === sdeWeekday) {
        sdeOpeningDateTime = this.combineDateAndTime(day.startHour, sde).setZone(locationTimezone, { keepLocalTime: true })
        sdeClosingDateTime = this.combineDateAndTime(day.endHour, sde).setZone(locationTimezone, { keepLocalTime: true })
      }
    })

    // console.log(sdsOpeningDateTime.toLocaleString(DateTime.DATETIME_FULL))
    // console.log(sdsClosingDateTime.toLocaleString(DateTime.DATETIME_FULL))

    // { keepLocalTime: true }
    // console.log(sdeOpeningDateTime.toLocaleString(DateTime.DATETIME_FULL))
    // console.log(sdeOpeningDateTime.toLocaleString(DateTime.DATETIME_FULL))



    return { sdsOpeningDateTime, sdsClosingDateTime, sdeOpeningDateTime, sdeClosingDateTime } // Returned as Luxon Date Time Objects
  }

  /**
 * @description Using location information, determine the Shop open and close - single date*/
  getShopScheduleForSingleDate(locationObj, date, locationTimezone) {
    let weekDay = this.getDbDayOfWeekFromLuxon(date.weekday) // this method returns a string value for the DOTW ex. "Monday"

    let dateOpen, dateClose;

    // Get shop hour schedule for SDS & SDE
    // Compare the SDS & SDE weekdays to a locations day schedule. Once the correct weekday is found
    locationObj['scheduleWeek'].forEach((day) => {
      if (day.day === weekDay) {
        dateOpen = this.combineDateAndTime(day.startHour, date).setZone(locationTimezone, { keepLocalTime: true })
        dateClose = this.combineDateAndTime(day.endHour, date).setZone(locationTimezone, { keepLocalTime: true })
      }
    })
    return { dateOpen: dateOpen, dateClose: dateClose } // Returned as Luxon Date Time Objects
  }

  /**
   * @description Returns an array of unavailable DateTime objects if the unavailableTimeActivateDate matches the date passed via param
   * @notes Cycle through all the unavailable times in a location & if the unavailableTimeActivateDate date matches the date passed in via param, then get the time stored in the database, concat it with the date to create a luxon object. */
  getDaysUnavailableTimes(locationObj, date) {
    let unavailableTimes = [];
    locationObj['unavailableHours'].forEach((unavailableTimeInfo) => {
      let unavailableTimeActiveDate = this.convertTimestampToLuxon(unavailableTimeInfo.date); // Get the date the unavailable time is active
      if (date.hasSame(unavailableTimeActiveDate, 'day')) { // If the date in param is the same date listed on unavailable time from location ->
        let unavailRange = {};

        // Create DateTime Objects for the following unavailable time slots:
        unavailRange['dayStart'] = this.clearSeconds(this.combineDateAndTime(unavailableTimeInfo.startTime, unavailableTimeActiveDate).setZone(locationObj.timezone, { keepLocalTime: true })) // dayStart

        unavailRange['dayEnd'] = this.clearSeconds(this.combineDateAndTime(unavailableTimeInfo.endTime, unavailableTimeActiveDate).setZone(locationObj.timezone, { keepLocalTime: true })) // dayEnd

        unavailRange['type'] = 'unavailable' // set group type

        // Push the unavailable object to an array
        unavailableTimes.push(unavailRange);
      }
    })

    // Sort the unavailable array by dayStart
    unavailableTimes.sort(function (a, b) {
      return a.dayStart - b.dayStart;
    })

    return unavailableTimes
  }

  public isValidLuxonDateTimeObject(dateTimeObj: DateTime): boolean {
    if(!(dateTimeObj instanceof DateTime)){
      return false;
    }

    if(!dateTimeObj.isValid){ // Property exists on a Luxon DateTime object to verify if the object is valid
      return false;
    }

    return true
  }

  isValidDateObject(date): boolean {
    const tests: boolean[] = [
      date instanceof DateTime,
      date instanceof Date,
      date
        && FirestoreDateFields.NanoSeconds in date
        && FirestoreDateFields.Seconds in date,
    ]

    return tests.some(item => item == true)
  }

  /**
   * equal is a middle ground implementation between
   * Luxon DateTime.hasSame and DateTime.eqauls
   * `hasSame` does not enforce timezone and `equals`
   * requires equality to the millisecond
   * See https://moment.github.io/luxon/api-docs/index.html#datetimehassame
   * and https://moment.github.io/luxon/api-docs/index.html#datetimeequals
   * @param dt1 DateTime from Luxon
   * @param dt2 DateTime from Luxon
   * @param same DateTimeUnit from Luxon
   * @returns boolean
   */
  equal(dt1: DateTime, dt2: DateTime, same: DateTimeUnit = 'minute'): boolean {
    const tz: Zone = dt1.zone || dt2.zone || FixedOffsetZone.utcInstance
    if (tz) {
      return dt1.setZone(tz.name).hasSame(dt2.setZone(tz.name), same)
    }
    return false
  }
}
