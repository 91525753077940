import { AvailabilityOverrides } from "src/app/models/availability-overrides.model";
import { TimeslotType } from 'src/app/models/availability-timeslot.model'
import {
  ProductRental,
  UserInfo,
} from "src/app/models/rental.model";

export interface Rental {
  affiliateID?: string,
  allProductsID?: string[],
  amountPaid?: number,
  amountPending?: number,
  amountPendingAbs?: number,
  amountPendingToRefund?: number;
  availabilityOverrideConfig?: AvailabilityOverrides
  cartObj?: any;
  cartWidgetList?: any[],
  chargesID?: [{
    chargeID?: string,
    cost?: number,
    date?: Date,
    title?: string
  }],
  companyID?: string,
  companyTax?: number;
  cost?: number,
  created?: Date,
  damagedNotes?: string,
  dayEnd?,
  dayStart?,
  deliverInformation?: {
    name?: string,
    address?: string
  },
  discount?: number
  discountID?: string
  discountInfo?,
  domain?: string,
  finalTotal?,
  fleetTax?: number,
  govermentTax?: number,
  hourEnd?: Date,   // DEPRECATED
  hourStart?: Date, // DEPRECATED
  id?: string,
  isActive?: boolean,
  isCancelled?: boolean,
  isCheckedIn?: boolean,
  isCheckedOut?: boolean,
  isCleaning?: boolean,
  isComplete?: boolean,
  isConfirmed?: boolean,
  isDamaged?: boolean,
  isDeliver?: boolean,
  isLate?: boolean,
  isReady?: boolean,
  isReservation?: boolean,
  isReserved?: boolean,
  isTest?: boolean,
  isTuneUp?: boolean,
  isWOPayment?,
  lastcost?: number // this was found in a few components - unsure if this is really necessary to keep
  lastModified?: Date
  notes?: string,
  paymentID?: string,
  products?: ProductRental[],
  productsID?: string[],
  productsNames?: string[],
  productsNamesMonths?: string[],
  refundsID?: [{
    refundID?: string,
    amount?: number,
    date?: Date
  }]
  rentalLocationID?: string;
  rentalNumber?,
  rentalType?: string,
  reservationPaid?: number,
  reservationPending?: boolean,
  sendEmail?: boolean,
  shopComments?: string
  // status: string,
  statusDate?,
  stripeID?: string,
  subtotal?: number,
  taxes?: number,
  timeslotType?: TimeslotType | null;
  timeZone?,
  totalAddons?: number,
  userID?: string,
  userInfo?: UserInfo,
  userName?: string,
  versionNumber?: string,
  waiver?: string[],
  waiverDate?,
  waiverSignature?: string,
  waiversSigned?: [],
}

const defaultUserInfo: Partial<UserInfo> = {
  name: '',
  lastName: '',
  phone: '',
  email: '',
  address: '',
  address2: '',
  city: '',
  country: '',
  state: '',
  zip: ''
}

const defaultRental: Partial<Rental> = {
  allProductsID: [],
  amountPendingToRefund: 0,
  cartObj: {items:[]},
  cartWidgetList: [],
  amountPendingAbs: 0,
  deliverInformation: {
    name: '',
    address: ''
  },
  isDeliver: false,
  finalTotal: null,
  isWOPayment: false,
  fleetTax: null,
  govermentTax: null,
  companyTax: null,
  id: '',
  companyID: '',
  stripeID: '',
  affiliateID: '',
  versionNumber: '',
  rentalType: '',
  products: [],
  domain: '',
  productsID: [],
  productsNames: [],
  productsNamesMonths: [],
  timeZone: '',
  isConfirmed: false,
  isTest: false,
  sendEmail: false,
  userID: '',
  userName: '',
  rentalNumber: '',
  dayStart: '',
  dayEnd: '',
  hourStart: null,
  hourEnd: null,
  userInfo: defaultUserInfo,
  rentalLocationID: '',
  created: null,
  isActive: true,
  isReserved: false,
  isCancelled: false,
  isCheckedIn: false,
  isCheckedOut: false,
  isComplete: false,
  isLate: false,
  isDamaged: false,
  isTuneUp: false,
  isCleaning: false,
  isReady: false,
  statusDate: {},
  damagedNotes: '',
  notes: '',
  subtotal: 0,
  totalAddons: 0,
  taxes: 0,
  cost: 0,
  isReservation: false,
  amountPaid: 0,
  amountPending: 0,
  reservationPaid: null,
  reservationPending: false,
  waiver: [],
  paymentID: '',
  waiverSignature: '',
  waiverDate: '',
  discount: 0,
  discountID: '',
  discountInfo: {},
  chargesID: [{
    chargeID: '',
    date: null,
    cost: null,
    title: ''
  }],
  refundsID: [{
    refundID: '',
    amount: null,
    date: null
  }],
  waiversSigned: [],
  shopComments: '',
  availabilityOverrideConfig: null,
  lastcost: null,
  lastModified: null,
  timeslotType: null,
}

export function makeNewRental(rental?: Rental) {
  return { ...defaultRental, ...rental } as Rental
}
