import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';

import { Timestamp } from '@firebase/firestore';
import { ToastrService } from 'ngx-toastr';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { Moment } from 'moment-timezone';
import { Subscription } from 'rxjs';
import * as moment from 'moment-timezone';
import Swal from 'sweetalert2';

import { ProductAddress } from 'src/app/models/product-location.model';
import { ProductLocation } from 'src/app/models/storage/product-location.model';
import { User } from 'src/app/models/storage/user.model';
import { DatesSelected } from 'src/app/models/rental.model';

import { RentalService } from 'src/app/services/rental.service';
import { ProductLocationService } from 'src/app/services/product-location.service';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { ColorTheme } from 'src/app/services/colorTheme.service';
import { ProductsService } from 'src/app/services/products.service';
import { LogService } from 'src/app/services/log.service';

@Component({
  selector: 'app-product-location',
  templateUrl: './product-location.component.html',
  styleUrls: ['./product-location.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ProductLocationComponent implements OnInit {
  idUser: string;
  public gethemecolor: string;
  public gethemetext: string;
  public states: string[];
  public countries: any[];
  public selectedcountry: string;
  public selectedstate: string;
  public id: string;

  public products: ProductLocation[];
  public product: ProductAddress;
  public productsLocation: any[] = [];
  public locationsLead: any[] = [];

  public currentUser: User;

  public isEdit: boolean = false;
  public isEditUnav: boolean = false;
  public isEditUnavHour: boolean = false;
  public isLinear: boolean = true;
  public isLeadtime: boolean = false;
  public isLoading: boolean = true;

  public timezoneName: any = '';
  public timezone: string = '';
  public notimezone: boolean = true;
  public submit: boolean = false;
  public timezonesresp: any = [];
  public timezones: any;
  public abbreviations: any = [];
  public isSubmitted = false;
  public isHourSubmitted = false;
  public isAddingUHours = false;
  public submitted: boolean = false;
  public isDisabled: boolean = false;
  public indexDay: number;
  public indexHour: number;
  public titleAddEdit: string = 'Add';
  public unavailableDays: any = [];
  public unavailableHours: any = [];
  public comesFrom: boolean = false;
  public selected: { startDate: Moment; endDate: Moment };
  public locale: LocaleConfig = {
    applyLabel: 'Appliquer',
    customRangeLabel: ' - ',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: moment.localeData().firstDayOfWeek(),
    format: 'MM/DD/YYYY',
  };
  public date = new Date();
  public ls = localStorage;
  public minHour: any;
  public maxHour: any;
  public invalidDays = [];
  public weeklyDays = [];
  public monthlyDays = [];
  public yearlyDays = [];
  public datesSelected: DatesSelected = {
    dateStart: moment.tz(this.date, this.timezone).startOf('day').toDate(),
    dateEnd: moment.tz(this.date, this.timezone).endOf('day').toDate(),
  };
  public currentDateTime: Moment = moment();
  public scheduleWeek: any = [
    {
      day: 'Sunday',
      available: false,
      startHour: '08:00',
      endHour: '17:00',
    },
    {
      day: 'Monday',
      available: false,
      startHour: '08:00',
      endHour: '17:00',
    },
    {
      day: 'Tuesday',
      available: false,
      startHour: '08:00',
      endHour: '17:00',
    },
    {
      day: 'Wednesday',
      available: false,
      startHour: '08:00',
      endHour: '17:00',
    },
    {
      day: 'Thursday',
      available: false,
      startHour: '08:00',
      endHour: '17:00',
    },
    {
      day: 'Friday',
      available: false,
      startHour: '08:00',
      endHour: '17:00',
    },
    {
      day: 'Saturday',
      available: false,
      startHour: '08:00',
      endHour: '17:00',
    },
  ];

  displayedColumns: string[];
  columnsToDisplayWithExpand = [];
  columnsToDisplay = [];
  dataSource: MatTableDataSource<ProductLocation>;
  expandedElement: ProductLocation | null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  myForm: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    address1: ['', [Validators.required]],
    address2: [''],
    city: ['', [Validators.required]],
    state: ['', [Validators.required]],
    country: ['', [Validators.required]],
    zip: [
      '',
      [Validators.required, Validators.pattern('^[0-9]+$'), Validators.min(1)],
    ],
    products: this.fb.array([]),
  });

  scheduleForm: FormGroup = this.fb.group({
    timezoneName: ['', Validators.required],
    timezone: ['', Validators.required],
  });

  unavailableDayForm: FormGroup = this.fb.group({
    name: [''],
    date: ['', Validators.required],
    periodicity: ['', Validators.required],
  });

  unavailableHourForm: FormGroup = this.fb.group({
    date: ['', Validators.required],
    startTime: ['', Validators.required],
    endTime: ['', Validators.required, this.endTimeValidator.bind(this)],
  });

  leadtimeForm: FormGroup = this.fb.group({
    leadtimes: this.fb.array([]),
  });

  get hourlyPriceArr() {
    return this.myForm.get('hourlyPrice') as FormArray;
  }

  get productsControl() {
    return this.myForm.get('products') as FormArray;
  }

  get leadtimesControl() {
    return this.leadtimeForm.get('leadtimes') as FormArray;
  }

  get unavailableDaysLS() {
    return JSON.parse(this.ls.getItem('unavailableDays') || '[]');
  }

  get unavailableHoursLS() {
    return JSON.parse(this.ls.getItem('unavailableHours') || '[]');
  }

  get scheduleWeekLS() {
    return JSON.parse(this.ls.getItem('scheduleWeek') || '[]');
  }

  get oldUnavailableDaysLS() {
    return JSON.parse(this.ls.getItem('oldUnavailableDays') || '[]');
  }

  get oldUnavailableHoursLS() {
    return JSON.parse(this.ls.getItem('oldUnavailableHours') || '[]');
  }

  /* Observables variables */
  subs = new Subscription();
  productData: Array<any>;

  protected stepperOrientation: 'horizontal' | 'vertical' = 'horizontal';
  protected repeats: any[] = [];
  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private _rentalService: RentalService,
    private _productLocation: ProductLocationService,
    private _currentUser: CurrentUserService,
    public _theme_color: ColorTheme,
    private _productsService: ProductsService,
    private _logService: LogService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.currentUser = this._currentUser.currentUser;
  }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 767px)']).subscribe(result => {
      this.stepperOrientation = result.matches ? 'vertical' : 'horizontal';
    });
    this.getStatesAndContries();
    this.fngetcolortheme();
    this.getAllProducts(this.currentUser.companyId);
    this.getProducts();
    this.getTimeZones();
  }

  fngetcolortheme() {
    this.idUser = this._currentUser.currentUser.id;
    this._theme_color
      .getcolorPeruser(this.idUser)
      .then((o) => {
        let objc = {
          theme: o.color.value,
          text: o.color.fontcolor,
        };

        this.gethemecolor = objc.theme;
        this.gethemetext = objc.text;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getStatesAndContries() {
    this.countries = await this._rentalService.getCountries();
    this.countries.sort(function (a, b) {
      // * Alphabetical sorting function for countries
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    this.states = await this._rentalService.getStatesByCountry('US');
    const noStates = [
      'AA',
      'AE',
      'AP',
      'AS',
      'FM',
      'GU',
      'MH',
      'MP',
      'PR',
      'PW',
      'VI',
    ];
    this.states = this.states.filter((state) => !noStates.includes(state));
  }

  /**
   * * Change function to get states by country
   */
  async setStates() {
    this.myForm.get('state').reset();
    const selectedcountry = this.myForm.get('country').value;
    if (selectedcountry) {
      this.states = await this._rentalService.getStatesByCountry(selectedcountry);
      const noStates = [
        'AA',
        'AE',
        'AP',
        'AS',
        'FM',
        'GU',
        'MH',
        'MP',
        'PR',
        'PW',
        'VI',
      ];
      this.states = this.states.filter((state) => !noStates.includes(state));
    }
  }

  getAllProducts(companyId: string) {

    this._productLocation
      .getAllProductsLocations(companyId)
      .subscribe((data) => {
        const productDefault = data.find((p) => p.isDefault === true);
        const productFilter = data.filter((p) => p.isDefault !== true);
        if (productDefault) {
          productFilter.unshift(productDefault);
        }
        this.products = productFilter;

        if (this.products.length >= 1) {
          const productIndexOpen = productFilter.findIndex((p) => p.isDefault);
          if (productIndexOpen !== -1 && productFilter[productIndexOpen]) {
            this.expandedElement = productFilter[productIndexOpen];
          }

          this.isLoading = true;
          this.displayedColumns = [
            'Name',
            'Address',
            'Address 2',
            'City',
            'Country',
            'State',
            'Zip',
            'action',
          ];
          this.columnsToDisplay = ['Name'];
          this.columnsToDisplayWithExpand = [
            ...this.columnsToDisplay,
            'expand',
          ];
          this.dataSource = new MatTableDataSource(this.products);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          this.isLoading = false;
        }
      });
  }

  getProducts() {
    // Subscribe to changes in product collection (in order to add checkbox list applying sizes directly to products)
    this.subs.add(
      this._productsService.getProducts().subscribe((data) => {
        this.productData = data;
      })
    );
  }

  //function Time zones  Us,Mx,Ca
  getTimeZones() {
    this.timezonesresp = [];
    const countries = ['US', 'MX', 'CA'];

    countries.forEach((country) => {
      const zones = moment.tz.zonesForCountry(country);

      zones.forEach((zone) => {
        const zoneObject = moment.tz.zone(zone);

        const uniqueAbbrs = zoneObject.abbrs.filter((abbr, index, self) => {
          return index === self.indexOf(abbr);
        });

        uniqueAbbrs.forEach((abbr) => {
          if (!this.abbreviations.includes(abbr)) {
            this.abbreviations.push(abbr);
          }

          this.abbreviations.sort();

          const timezoneData = {
            timezone: zone,
            abbreviation: abbr,
            hour: moment.tz(moment(), zone).format('hh:mm a'),
          };

          this.timezonesresp.push(timezoneData);
        });
      });
    });

    if (this.timezone) {
      this.changetimezone();
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  fieldInvalid(field: string) {
    return this.myForm.get(field)?.invalid && this.myForm.get(field)?.touched;
  }

  fieldInvalidTwo(field: string) {
    return (
      this.scheduleForm.get(field)?.invalid &&
      this.scheduleForm.get(field)?.touched
    );
  }

  fieldInvalidThree(field: string) {
    return (
      this.unavailableDayForm.get(field)?.invalid &&
      this.unavailableDayForm.get(field)?.touched
    );
  }

  //function change time
  changetimezone() {
    this.scheduleForm.get('timezone').reset();
    this.timezoneName != ''
      ? (this.notimezone = false)
      : (this.notimezone = true);
    const filterzones = this.timezonesresp.filter(
      (timezone) => timezone.abbreviation == this.timezoneName
    );
    this.timezones = filterzones;
    this.timezones.sort(function (a, b) {
      if (a.timezone > b.timezone) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  }

  setValueSchedule(position, type, event) {
    if (type == 'startHour') {
      event.target.value == '' ? (event.target.value = '00:00') : '';
      this.scheduleWeek[position].startHour = event.target.value;
      this.scheduleWeek[position].lessth = false;
      this.scheduleWeek[position].equalz = false;
      this.scheduleWeek[position].negative = false;
      // this.validateHour(position, type, event);
    } else if (type == 'endHour') {
      event.target.value == '' ? (event.target.value = '00:00') : '';
      this.scheduleWeek[position].endHour = event.target.value;
      this.scheduleWeek[position].lessth = false;
      this.scheduleWeek[position].equalz = false;
      this.scheduleWeek[position].negative = false;
      // this.validateHour(position, type, event);
    } else {
      if (event.target.checked) {
        this.scheduleWeek[position].available = true;
      } else {
        this.scheduleWeek[position].available = false;
      }
    }
  }

  StartHour(position: number) {
    if (position === 0) {
      this.scheduleWeek.forEach((e: any, i: number) => {
        this.scheduleWeek[i].startHour = this.scheduleWeek[position].startHour;
      });
    }
  }

  EndtHour(position: number) {
    if (position === 0) {
      this.scheduleWeek.forEach((e: any, i: number) => {
        this.scheduleWeek[i].endHour = this.scheduleWeek[position].endHour;
      });
    }
  }

  protected hoursMinutes: { hour: number, minute: number, period: string, display: string }[] = [];
  protected positionsFound: number[] = [];
  protected timeSelected1: string;
  protected timeSelected2: string;


  openModalSetTime(position: number, startHour: string, endHour: string) {
    this.positionsFound.push(position);
    this.createHoursMinutes();

    this.timeSelected1 = startHour;
    this.timeSelected2 = endHour;

    this.loadDaysWeek(this.positionsFound);
    $('#modalSetTime').modal('show');
  }

  private createHoursMinutes() {
    const periods = ['AM', 'PM'];

    this.hoursMinutes = periods.flatMap(period => {
      const periodLabel = period;
      return Array.from({ length: 12 }, (_, hour) => {
        const formattedHour = (hour + 12) % 12 || 12;
        return Array.from({ length: 2 }, (_, index) => {
          const minute = index * 30;
          const display = `${formattedHour}:${minute === 0 ? '00' : minute} ${periodLabel}`;
          return { hour: formattedHour, minute, period: periodLabel, display };
        });
      }).flat();
    });
  }


  daysWeek: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  daySelected: { [key: number]: boolean } = {};

  private loadDaysWeek(positions) {

    const daysSelectedPositions = positions; // For example, select the first 3 days per item

    this.daysWeek.forEach((day, index) => {
      this.daySelected[day] = daysSelectedPositions.includes(index);
    });
  }

  getPositionsSelected() {
    const positionsSelected: number[] = [];

    this.daysWeek.forEach((day, index) => {
      if (this.daySelected[day]) {
        positionsSelected.push(index);
      }
    });

    const selectedIndex1 = this.hoursMinutes.findIndex(item => item.display === this.timeSelected1);
    const selectedIndex2 = this.hoursMinutes.findIndex(item => item.display === this.timeSelected2);

    const startHour = this.hoursMinutes[selectedIndex1];
    const endHour = this.hoursMinutes[selectedIndex2];

    const startHourFo = this.getFormatTime(startHour);
    const endHourFo = this.getFormatTime(endHour);
    const resp = this.compareTimes(startHourFo, endHourFo);

    if (resp.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: resp,
      });
      return;
    }
    const objectModified = this.modifyHoursAvailability(positionsSelected, startHourFo, endHourFo);
    this.scheduleWeek = objectModified;
    $('#modalSetTime').modal('hide');
  }

  modifyHoursAvailability(positions: number[], startHourFo: string, endHourFo: string): any {
    positions.forEach(position => {
      const day = this.scheduleWeek[position];

      if (day) {
        day.startHour = startHourFo;
        day.endHour = endHourFo;
        // day.available = true; // Set available to true
      }
    });

    return this.scheduleWeek; // Return the modified object
  }

  formatTime(TimeControl) {

    const partsTime = TimeControl.split(':');
    const hourNum = +partsTime[0];
    const minutes = partsTime[1];

    let period = 'AM';

    if (hourNum >= 12) {
      period = 'PM';
    }

    // Change from 24-hour to 12-hour format
    const hora12 = (hourNum % 12 === 0) ? 12 : hourNum % 12;

    return `${hora12}:${minutes} ${period}`;

  }

  convertTimeToJSON(time: string): { hour: number, minute: number, period: string } {

    const [timePart, period] = time.split(' ');
    const [hour, minute] = timePart.split(':').map(Number);

    const timeJSON = {
      hour: hour,
      minute: minute,
      period: period
    };

    return timeJSON;
  }

  getFormatTime(Hour): string {
    let hour = Hour.hour % 12 || 12; // Convert time to 12-hour format
    const minute = Hour.minute < 10 ? '0' + Hour.minute : Hour.minute;
    if (Hour.period === 'PM' && hour != 12) {
      hour += 12; // Add 12 hours to get the 24-hour format
    }
    else if (Hour.period === 'AM' && hour == 12) {
      hour -= 12; // Subtract 12 to get the beginning of the day
    }

    return `${hour}:${minute}`;
  }

  compareTimes(startHourFo: string, endHourFo: string) {
    let startdate = moment(startHourFo, 'h:mm');
    let endate = moment(endHourFo, 'h:mm');

    // Performing the comparison
    if (startdate.isSameOrAfter(endate) || endate.isBefore(startdate)) {
      return "The opening time cannot be greater than or equal to the closing time, or the closing time cannot be less than the opening time. Please adjust the times.";
    } else {
      return '';
    }

  }

  emptyingArrangement() {
    this.hoursMinutes = [];
    this.positionsFound = []
    $('#modalSetTime').modal('hide');
  }

  validateHour(position, type?, event?) {
    let startdate = moment()
      .set('hour', this.scheduleWeek[position].startHour.substring(0, 2))
      .set('minute', this.scheduleWeek[position].startHour.slice(-2))
      .set('second', 0);
    let endate = moment()
      .set('hour', this.scheduleWeek[position].endHour.substring(0, 2))
      .set('minute', this.scheduleWeek[position].endHour.slice(-2))
      .set('second', 0);
    var duration = moment.duration(endate.diff(startdate));
    var hours = duration.asHours();
    hours < 1 && hours > 0
      ? (this.scheduleWeek[position].lessth = true)
      : (this.scheduleWeek[position].lessth = false);
    hours == 0
      ? (this.scheduleWeek[position].equalz = true)
      : (this.scheduleWeek[position].equalz = false);
    hours < 0
      ? (this.scheduleWeek[position].negative = true)
      : (this.scheduleWeek[position].negative = false);

      return hours;
  }

  openAddModal() {
    this.isLinear = true;
    this.isLeadtime = false;
    this.location = {};
    this.myForm.get('country').setValue('US');
    this.setStates();
    this.scheduleForm.get('timezoneName').setValue('MDT');
    this.scheduleForm.get('timezone').setValue('');
    const filterzones = this.timezonesresp.filter(
      (timezone) => timezone.abbreviation == 'MDT'
    );
    this.timezones = filterzones;
    this.timezones.sort(function (a, b) {
      if (a.timezone > b.timezone) {
        return 1;
      }
      return 0;
    });
    this.scheduleWeek = [
      {
        day: 'Sunday',
        available: false,
        startHour: '08:00',
        endHour: '17:00',
      },
      {
        day: 'Monday',
        available: false,
        startHour: '08:00',
        endHour: '17:00',
      },
      {
        day: 'Tuesday',
        available: false,
        startHour: '08:00',
        endHour: '17:00',
      },
      {
        day: 'Wednesday',
        available: false,
        startHour: '08:00',
        endHour: '17:00',
      },
      {
        day: 'Thursday',
        available: false,
        startHour: '08:00',
        endHour: '17:00',
      },
      {
        day: 'Friday',
        available: false,
        startHour: '08:00',
        endHour: '17:00',
      },
      {
        day: 'Saturday',
        available: false,
        startHour: '08:00',
        endHour: '17:00',
      },
    ];
    this.locationsLead.length = 0;
    this.locationsLead = this.fiterLocations('');
    this.locationsLead.forEach((p) => {
      this.leadtimesControl.push(
        this.fb.group({
          locationA: [''],
          minutes: ['', []],
          locationB: [p.id],
        })
      );
    });
    this.unavailableDays.length = 0;
    this.unavailableHours.length = 0;
    $('#addNewProductLocation').modal({ backdrop: 'static', keyboard: false });
    $('#addNewProductLocation').modal('show');
  }

  async openEditModal(product: ProductLocation) {
    this.isLinear = false;
    this.isEdit = true;

    this.productsLocation.length = 0;
    this.productData.forEach((elem: any) => {
      if (elem.productLocationID == product.id) {
        this.productsLocation.push(elem);
      }
    });

    if (product.isLeadtime) {
      this.isLeadtime = product.isLeadtime;
      this.locationsLead.length = 0;
      this.locationsLead = this.fiterLocations(product.id);
      this.locationsLead.forEach((p: any, index: number) => {
        this.leadtimesControl.push(
          this.fb.group({
            locationA: [product.id],
            minutes: [
              product?.leadtime?.[index]?.minutes
                ? product?.leadtime?.[index]?.minutes
                : '',
              [
                Validators.required,
                Validators.pattern('^[0-9]+$'),
                Validators.min(1),
              ],
            ],
            locationB: [p.id],
          })
        );
      });
    } else {
      this.isLeadtime = false;
      this.locationsLead.length = 0;
      this.locationsLead = this.fiterLocations(product.id);
      this.locationsLead.forEach((p: any, index: number) => {
        this.leadtimesControl.push(
          this.fb.group({
            locationA: [product.id],
            minutes: [
              product?.leadtime?.[index]?.minutes
                ? product?.leadtime?.[index]?.minutes
                : '',
              [],
            ],
            locationB: [p.id],
          })
        );
      });
    }

    this.location = product;
    this.product = product.address;
    this.id = product.id;
    this.ls.setItem('scheduleWeek', JSON.stringify(product.scheduleWeek));
    if (product.unavailableDays) {
      this.ls.setItem(
        'oldUnavailableDays',
        JSON.stringify(product.unavailableDays)
      );
      this.ls.setItem(
        'unavailableDays',
        JSON.stringify(product.unavailableDays)
      );
      this.unavailableDays = this.unavailableDaysLS;
    }
    if (product.unavailableHours) {
      this.ls.setItem(
        'oldUnavailableHours',
        JSON.stringify(product.unavailableHours)
      );
      this.ls.setItem(
        'unavailableHours',
        JSON.stringify(product.unavailableHours)
      );
      this.unavailableHours = this.unavailableHoursLS;

      this.unavailableHours.forEach((items: any) => {
        items.endTime = items.endTime;
        items.startTime = items.startTime;
        return items;
      });
    }
    this.myForm.get('name').setValue(product.name);
    this.myForm.get('address1').setValue(product.address.address1);
    this.myForm.get('address2').setValue(product.address.address2);
    this.myForm.get('city').setValue(product.address.city);
    this.myForm.get('zip').setValue(product.address.zip);
    this.countries = await this._rentalService.getCountries();
    this.countries.sort((a, b) => a.name.localeCompare(b.name));
    if (product.address?.country) {
      this.states = await this._rentalService.getStatesByCountry(
        product.address.country
      );
      setTimeout(() => {
        this.myForm.get('country').setValue(this.product.country);
      }, 1000);
      this.selectedcountry = product.address.country;
    }
    if (product.address?.state) {
      this.selectedstate = product.address.state;
      setTimeout(() => {
        this.myForm.get('state').setValue(this.selectedstate);
      }, 1000);
    }
    let noStates = [
      'AA',
      'AE',
      'AP',
      'AS',
      'FM',
      'GU',
      'MH',
      'MP',
      'PR',
      'PW',
      'VI',
    ];
    this.states = this.states.filter((state) => !noStates.includes(state));
    this.scheduleForm.get('timezoneName').setValue(product.timezoneName);
    const filterzones = this.timezonesresp.filter(
      (timezone) => timezone.abbreviation == product.timezoneName
    );
    this.timezones = filterzones;
    setTimeout(() => {
      this.scheduleForm.get('timezone').setValue(product.timezone);
      this.scheduleWeek = product.scheduleWeek;
    }, 1000);

    if (this.productsLocation.length > 0) {
      Swal.fire({
        icon: 'info',
        title: 'Edit Shop Location.',
        text: 'There are products linked to this location, the products will be affected by the modifications you make or you can reassign the product to another location.',
        confirmButtonColor: '#114463',
      }).then(() => {
        $('#addNewProductLocation').modal('show');
      });
    } else {
      $('#addNewProductLocation').modal('show');
    }
  }

  fiterLocations(id: string) {
    if (this.products.length > 1) {
      return this.products
        .filter((x) => x.id != id)
        .sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
    } else {
      return [];
    }
  }

  findNameLocation(id: string) {
    const product = this.products.find((p) => p.id === id);
    return product?.name;
  }

  invalidFieldLeadtime(index: number) {
    return (
      this.leadtimesControl.controls[index].get('minutes').errors &&
      this.leadtimesControl.controls[index].get('minutes').touched
    );
  }

  invalidTextLeadtime(index: number) {
    let required =
      this.leadtimesControl.controls[index].get('minutes').errors?.['required'];
    if (required) {
      var textError = 'The field leadtime  is required';
    } else {
      var textError = 'The field must be greater than 0 minutes';
    }
    return textError;
  }

  editDay(index?: number, day?: any, isEdit = false) {
    this.unavailableDayForm.reset();
    this.submitted = false;
    this.repeats = [ { value: 'One Time' }, { value: 'Every year' } ]
    if (isEdit) {
      this.isEditUnav = true;
      this.indexDay = index;
      this.titleAddEdit = 'Update';
      this.unavailableDayForm.get('name').setValue(day.name);
      if (typeof day.date === 'string') {
        this.unavailableDayForm.get('date').setValue({
          startDate: new Date(day.date),
          endDate: new Date(day.date),
        });
      } else {
        this.unavailableDayForm.get('date').setValue({
          startDate: new Date(day.date.seconds * 1000),
          endDate: new Date(day.date.seconds * 1000),
        });
      }
      this.unavailableDayForm.get('periodicity').setValue(day.periodicity);
    } else {
      this.titleAddEdit = 'Add';
      this.unavailableDayForm.get('name').setValue('');
      this.unavailableDayForm
        .get('date')
        .setValue({ startDate: new Date(), endDate: new Date() });
      this.unavailableDayForm.get('periodicity').setValue('');
    }

    // open modal
    $('#UnavailableDays').modal('show');
  }

  editHour(index: number, hour: any) {
    this.createHoursMinutes();

    const startHour = this.formatTime(hour.startTime);
    const endHour = this.formatTime(hour.endTime);


    if (typeof hour.date === 'string') {
      this.unavailableHourForm.get('date').setValue({
        startDate: new Date(hour.date),
        endDate: new Date(hour.date),
      });

      const today = moment(new Date(hour.date)).format('dddd');

      const infoToday = this.scheduleWeek.find(
        (s) => s.day === today && s.available
      );

      if (infoToday) {
        this.minHour = infoToday.startHour;
        this.maxHour = infoToday.endHour;
        this.unavailableHourForm.patchValue({
          startTime: startHour,
          endTime: endHour,
        });
      } else {
        this.minHour = '00:00';
        this.maxHour = '00:00';
        this.unavailableHourForm.patchValue({
          startTime: startHour,
          endTime: endHour,
        });
      }
    } else {
      this.unavailableHourForm.get('date').setValue({
        startDate: new Date(hour.date.seconds * 1000),
        endDate: new Date(hour.date.seconds * 1000),
      });

      const today = moment(new Date(hour.date.seconds * 1000)).format('dddd');

      const infoToday = this.scheduleWeek.find(
        (s) => s.day === today && s.available
      );
      if (infoToday) {
        this.minHour = infoToday.startHour;
        this.maxHour = infoToday.endHour;
        this.unavailableHourForm.patchValue({
          startTime: startHour,
          endTime: endHour,
        });
      } else {
        this.minHour = '00:00';
        this.maxHour = '00:00';
        this.unavailableHourForm.patchValue({
          startTime: startHour,
          endTime: endHour,
        });
      }
    }

    this.isEditUnavHour = true;
    this.isAddingUHours = true;
    this.indexHour = index;
    this.isHourSubmitted = false;
    this.titleAddEdit = 'Update';

    $('#UnavailableHours').modal('show');
  }

  addDayHours() {
    this.unavailableHourForm.reset();
    this.createHoursMinutes();
    this.unavailableHourForm.get('date').setValue({
      startDate: new Date(),
      endDate: new Date(),
    });

    const today = moment().format('dddd');
    const infoToday = this.scheduleWeek.find(
      (s) => s.day === today && s.available
    );

    if (infoToday) {
      this.minHour = infoToday.startHour;
      this.maxHour = infoToday.endHour;
      this.unavailableHourForm.patchValue({
        startTime: '8:00 AM',
        endTime: '10:00 AM',
      });
    } else {
      this.minHour = '8:00 AM';
      this.maxHour = '10:00 AM';
      this.unavailableHourForm.patchValue({
        startTime: '',
        endTime: '',
      });
    }

    this.isHourSubmitted = false;
    this.isAddingUHours = true;
    this.isEditUnavHour = false;
    this.titleAddEdit = 'Add';

    $('#UnavailableHours').modal('show');
  }
  setHours(value) {
    if (!value.startDate) {
      this.unavailableHourForm.controls.date.setErrors({ 'required': true });
    } else if (value.startDate) {
      const day = value.startDate.format('dddd');

      const infoToday = this.scheduleWeek.find(
        (s) => s.day === day && s.available
      );

      this.minHour = (infoToday.startHour);
      this.maxHour = infoToday.endHour;

      this.unavailableHourForm.patchValue({
        startTime:  this.formatTime(infoToday.startHour) || '8:00 AM',
        endTime: this.formatTime(infoToday.endHour) || '5:00 PM',
      });
      this.unavailableHourForm.controls.date.setErrors(null);
    }
  }
  checkHours(isStart: boolean, value) {
    const hour = value.target.value;
    const maxHour = this.maxHour;
    const minHour = this.minHour;

    if (isStart) {
      if (this.compareHours(hour, minHour) >= 0) {
        if (this.compareHours(hour, maxHour) >= 0) {
          value.target.value = maxHour;
        }
      } else {
        value.target.value = minHour;
      }
    } else {
      if (this.compareHours(hour, maxHour) <= 0) {
        if (this.compareHours(hour, minHour) <= 0) {
          value.target.value = minHour;
        }
      } else {
        value.target.value = maxHour;
      }
    }
  }
  compareHours(time1: string, time2: string): number {
    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);

    if (hours1 > hours2) {
      return 1;
    } else if (hours1 < hours2) {
      return -1;
    } else {
      if (minutes1 > minutes2) {
        return 1;
      } else if (minutes1 < minutes2) {
        return -1;
      } else {
        return 0;
      }
    }
  }

  closeModal() {
    this.scheduleForm.get('timezoneName').setValue('');
    this.scheduleForm.get('timezone').setValue('');
    this.myForm.reset();
    this.scheduleForm.reset();
    this.productsControl.clear();
    this.leadtimesControl.clear();
    this.ls.removeItem('unavailableDays');
    this.ls.removeItem('unavailableHours');
    this.ls.removeItem('scheduleWeek');
    this.ls.removeItem('oldUnavailableDays');
    this.ls.removeItem('oldUnavailableHours');
    this.productsLocation.length = 0;
    this.unavailableDays.length = 0;
    this.isEdit = false;
    this.isLinear = true;
    this.scheduleWeek = [
      {
        day: 'Sunday',
        available: false,
        startHour: '08:00',
        endHour: '17:00',
      },
      {
        day: 'Monday',
        available: false,
        startHour: '08:00',
        endHour: '17:00',
      },
      {
        day: 'Tuesday',
        available: false,
        startHour: '08:00',
        endHour: '17:00',
      },
      {
        day: 'Wednesday',
        available: false,
        startHour: '08:00',
        endHour: '17:00',
      },
      {
        day: 'Thursday',
        available: false,
        startHour: '08:00',
        endHour: '17:00',
      },
      {
        day: 'Friday',
        available: false,
        startHour: '08:00',
        endHour: '17:00',
      },
      {
        day: 'Saturday',
        available: false,
        startHour: '08:00',
        endHour: '17:00',
      },
    ];
    $('#addNewProductLocation').modal('hide');
  }

  closeModalUnavailableDays() {
    this.isEditUnav = false;
    $('#UnavailableDays').modal('hide');
    $('#addNewProductLocation').modal('show');
  }
  closeModalUnavailableHours() {
    this.isEditUnav = false;
    this.hoursMinutes = [];
    $('#UnavailableHours').modal('hide');
    $('#addNewProductLocation').modal('show');
  }

  closeModalProduct() {
    const checkboxAll: any = document.getElementById('checkboxAll');
    checkboxAll.checked = false;
    if (this.comesFrom) {
      this.comesFrom = false;
      $('#addNewProductLocation').modal('show');
      $('#productModal').modal('hide');
    }
  }

  nextTimezone(stepper: MatStepper) {
    if (this.myForm.invalid) {
      this.myForm.markAllAsTouched();
      return;
    }
    stepper.next();
  }

  nextUnavailableDays(stepper: MatStepper) {

    if (this.scheduleForm.invalid) {
      this.scheduleForm.markAllAsTouched();
      return;
    }

    const scheduleWeek = [];
    this.scheduleWeek.forEach((e) => {
      if (e.available) {
        scheduleWeek.push(e);
      }
    });

    if (scheduleWeek.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Require at least one day open!',
        confirmButtonColor: '#114463',
      });
      return;
    }

    stepper.next();
  }

  async nextUnavailableHours(stepper: MatStepper) {
    const daysOfTheWeek = [0, 1, 2, 3, 4, 5, 6];
    await this.processScheduleWeek(this.scheduleWeek, daysOfTheWeek);
    this.unavailableDays
      ? this.processUnavailableDays(this.unavailableDays, this.weeklyDays)
      : null;
    stepper.next();
  }

  protected getTimezone(form: FormGroup): string {
    const tz = form.get('timezone').value
    if (typeof tz == 'string') {
      return tz
    } else if ('timezone' in tz) {
      return tz.timezone
    } else {
      console.error(`saveProductLocation failure missing timezone`)
    }
    return ''
  }

  async saveProductLocation(stepper: MatStepper) {
    if (
      this.myForm.invalid ||
      this.scheduleForm.invalid ||
      this.leadtimesControl.invalid
    ) {
      this.myForm.markAllAsTouched();
      this.scheduleForm.markAllAsTouched();
      this.leadtimesControl.markAllAsTouched();
      return;
    }
    this.isDisabled = true;
    const resp = [];
    this.scheduleWeek.forEach((e: any) => {
      if (e.equalz === true || e.lessth === true || e.negative === true) {
        resp.push(e);
      }
    });

    if (resp.length === 0) {
      const editDate = this.unavailableDaysLS.map((d) => {
        if (typeof d.date === 'string') {
          d.date = Timestamp.fromDate(new Date(d.date));
        }
        return d;
      });

      const editDateHours = this.unavailableHoursLS.map((d) => {
        d.date = Timestamp.fromDate(new Date(d.date));
        return d;
      });

      const timezone = this.getTimezone(this.scheduleForm)
      console.log(`saveProductLocation(${this.currentUser.companyId})`, {
         timezone})
      if (!timezone) {
        return
      }

      const newProductLocation = {
        name: this.myForm.get('name').value,
        companyID: this.currentUser.companyId,
        companyName: this.currentUser.company,
        timezone: timezone,
        timezoneName: this.scheduleForm.get('timezoneName').value,
        scheduleWeek: this.scheduleWeek,
        unavailableDays: editDate,
        unavailableHours: editDateHours,
        leadtime: this.leadtimesControl.value,
        isDefault: this.products.length === 0 ? true : false,
        isActive: true,
        isLeadtime: this.isLeadtime,
        address: {
          city: this.myForm.get('city').value,
          state: this.myForm.get('state').value,
          country: this.myForm.get('country').value,
          address1: this.myForm.get('address1').value,
          zip: this.myForm.get('zip').value,
          address2: this.myForm.get('address2').value,
        },
      };

      const addProductLocation: any =
        await this._productLocation.addProductLocation(newProductLocation);

      if (addProductLocation.success) {
        if (this.products.length === 1) {
          this._productLocation.editCompanies(
            this.currentUser.companyId,
            addProductLocation.id
          );
        }

        const LeadTime = this.leadtimesControl.value;
        if (LeadTime.length > 0) {
          LeadTime.forEach((e: any, index: number) => {
            LeadTime[index].locationA = addProductLocation.id;
          });
          let data = { leadtime: LeadTime };
          this._productLocation.editProductsLocations(
            addProductLocation.id,
            data
          );
        }

        this.getAllProducts(this.currentUser.companyId);
        this.ls.removeItem('unavailableDays');
        this.ls.removeItem('unavailableHours');
        this.unavailableDays.length = 0;
        this.myForm.reset();
        this.scheduleForm.reset();
        this.leadtimesControl.clear();
        stepper.reset();
        this.isLinear = true;
        this.isDisabled = false;
        $('#addNewProductLocation').modal('hide');
        Swal.fire({
          icon: 'success',
          iconColor: '#198754',
          text: 'Product location add successfully',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        iconColor: '#bb2d3b',
        title: 'Check your schedules',
        confirmButtonColor: '#114463',
      });
    }
  }

  addUnavailableDay() {
    if (this.unavailableDayForm.invalid) {
      this.unavailableDayForm.markAllAsTouched();
      return;
    }
    let name = this.unavailableDayForm.get('name').value;
    let date = this.unavailableDayForm.get('date').value;
    const periodicity = this.unavailableDayForm.get('periodicity').value;
    const inputDate = document.getElementById('date');
    const inputPeriodicity = document.getElementById('periodicity');

    if (date.startDate) {
      if (periodicity != '') {
        date = date.startDate;

        if (date._d) {
          date = moment(date._d).add(1, 'days').toDate();
        }

        if (name == '') {
          // get the hour and minute
          let day = new Date(date).getDay();
          if (day == 0) {
            name = 'Sunday';
          } else if (day == 1) {
            name = 'Monday';
          } else if (day == 2) {
            name = 'Tuesday';
          } else if (day == 3) {
            name = 'Wednesday';
          } else if (day == 4) {
            name = 'Thursday';
          } else if (day == 5) {
            name = 'Friday';
          } else if (day == 6) {
            name = 'Saturday';
          }
        }

        // encrypt the name, date and periodicity to generate an id
        const unavailableDays = {
          name: name,
          date: date,
          periodicity: periodicity,
        };
        //saveUnavailableDays

        // if (this.unavailableDays.length > 0) {
        //   if (this.isEdit) {
        //     this.unavailableDays[this.indexDay] = unavailableDays;
        //   } else {
        //     this.unavailableDays.push(unavailableDays);
        //   }
        // } else {
        //   this.unavailableDays = [unavailableDays];
        // }
        const getUnavailableDays = this.unavailableDaysLS;
        if (this.isEditUnav) {
          this.unavailableDays[this.indexDay] = unavailableDays;
          this.ls.setItem(
            'unavailableDays',
            JSON.stringify(this.unavailableDays)
          );
          Swal.fire({
            title: 'Unavailable Day updated',
            html: 'Unavailable day updated successfully',
            icon: 'success',
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonColor: '#114463',
          }).then((result) => {
            if (result.isConfirmed) {
              this.unavailableDays = this.unavailableDaysLS;
              this.isEditUnav = false;
              $('#UnavailableDays').modal('hide');
              $('#addNewProductLocation').modal('show');
            }
          });
        } else {
          getUnavailableDays.push(unavailableDays);
          this.ls.setItem(
            'unavailableDays',
            JSON.stringify(getUnavailableDays)
          );
          Swal.fire({
            title: 'Unavailable Day added',
            html: 'Unavailable day added successfully',
            icon: 'success',
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonColor: '#114463',
          }).then((result) => {
            if (result.isConfirmed) {
              this.unavailableDays = this.unavailableDaysLS;
              $('#UnavailableDays').modal('hide');
              $('#addNewProductLocation').modal('show');
            }
          });
        }
      } else {
        inputPeriodicity.classList.add('is-invalid');
      }
    } else {
      inputDate.classList.add('is-invalid');
      this.toastr.error('Please select a date', 'Error', {
        positionClass: 'toast-center-center',
      });
    }
  }

  /**
   * *Start function edit location info
   */
  async editProductLocation(stepper: MatStepper) {
    if (
      this.myForm.invalid ||
      this.scheduleForm.invalid ||
      this.leadtimesControl.invalid
    ) {
      this.myForm.markAllAsTouched();
      this.scheduleForm.markAllAsTouched();
      this.leadtimesControl.markAllAsTouched();
      return;
    }

    const resp = [];
    this.scheduleWeek.forEach((e: any) => {
      if (e.equalz === true || e.lessth === true || e.negative === true) {
        resp.push(e);
      }
    });

    if (resp.length === 0) {
      const unHoursWDate = this.unavailableHours.map((h) => {
        if (typeof h.date === 'string') {
          h.date = Timestamp.fromDate(new Date(h.date));
        } else {
          h.date = Timestamp.fromDate(new Date(h.date.seconds * 1000));
        }
        return h;
      });

      const editDate = this.unavailableDaysLS.map((d) => {
        if (typeof d.date === 'string') {
          d.date = Timestamp.fromDate(new Date(d.date));
        } else {
          d.date = Timestamp.fromDate(new Date(d.date.seconds * 1000));
        }
        return d;
      });

      const timezone = this.getTimezone(this.scheduleForm)
      console.log(`editProductLocation(${this.currentUser.companyId})`, {
         timezone})
      if (!timezone) {
        return
      }

      const editProductLocation = {
        name: this.myForm.get('name').value,
        timezone: timezone,
        timezoneName: this.scheduleForm.get('timezoneName').value,
        scheduleWeek: this.scheduleWeek,
        unavailableDays: editDate,
        unavailableHours: unHoursWDate,
        leadtime: this.leadtimesControl.value,
        address: {
          country: this.myForm.get('country').value,
          zip: this.myForm.get('zip').value,
          state: this.myForm.get('state').value,
          address1: this.myForm.get('address1').value,
          address2: this.myForm.get('address2').value,
          city: this.myForm.get('city').value,
        },
      };

      const addProductLocation: any =
        await this._productLocation.editProductsLocations(
          this.id,
          editProductLocation
        );

      const isEqualAdress = this.objectComparison(
        this.location.address,
        editProductLocation.address
      );

      if (!isEqualAdress) {
        this._logService.addLocationLog(
          this.id,
          'Address location: updated, Old: ' +
            JSON.stringify(this.location.address) +
            ' New: ' +
            JSON.stringify(editProductLocation.address)
        );
      }

      const isEqualSchedule = this.arrayComparison(
        'scheduleWeek',
        this.scheduleWeekLS,
        editProductLocation.scheduleWeek
      );

      if (!isEqualSchedule) {
        this._logService.addLocationLog(
          this.id,
          'Schedule Week: updated, Old: ' +
            JSON.stringify(this.scheduleWeekLS) +
            ' New: ' +
            JSON.stringify(editProductLocation.scheduleWeek)
        );
      }

      const isEqualUnavailableDays = this.arrayComparison(
        'unavailableDays',
        this.oldUnavailableDaysLS,
        editProductLocation.unavailableDays
      );

      if (!isEqualUnavailableDays) {
        const oldUnavailableDaysLS = this.oldUnavailableDaysLS.map((d) => {
          if (typeof d.date === 'string') {
            d.date = moment(new Date(d.date)).format('L');
          } else {
            d.date = moment(new Date(d.date.seconds * 1000)).format('L');
          }
          return d;
        });

        const unavailableDays = editProductLocation.unavailableDays.map((d) => {
          if (typeof d.date === 'string') {
            d.date = moment(new Date(d.date)).format('L');
          } else {
            d.date = moment(new Date(d.date.seconds * 1000)).format('L');
          }
          return d;
        });

        this._logService.addLocationLog(
          this.id,
          'Unavailable Days: updated, Old: ' +
            JSON.stringify(oldUnavailableDaysLS) +
            ' New: ' +
            JSON.stringify(unavailableDays)
        );
      }

      const isEqualunavailableHours = this.arrayComparison(
        'unavailableHours',
        this.oldUnavailableHoursLS,
        editProductLocation.unavailableHours
      );

      if (!isEqualunavailableHours) {
        const oldUnavailableHoursLS = this.oldUnavailableHoursLS.map((d) => {
          if (typeof d.date === 'string') {
            d.date = moment(new Date(d.date)).format('L');
          } else {
            d.date = moment(new Date(d.date.seconds * 1000)).format('L');
          }
          return d;
        });

        const unavailableHours = editProductLocation.unavailableHours.map(
          (d) => {
            if (typeof d.date === 'string') {
              d.date = moment(new Date(d.date)).format('L');
            } else {
              d.date = moment(new Date(d.date.seconds * 1000)).format('L');
            }
            return d;
          }
        );

        this._logService.addLocationLog(
          this.id,
          'unavailable Hours: updated, Old: ' +
            JSON.stringify(oldUnavailableHoursLS) +
            ' New: ' +
            JSON.stringify(unavailableHours)
        );
      }

      if (addProductLocation.success) {
        this.getAllProducts(this.currentUser.companyId);
        this.myForm.reset();
        this.productsControl.clear();
        this.scheduleForm.reset();
        this.leadtimesControl.clear();
        stepper.reset();
        this.isEdit = false;
        this.isLinear = true;
        this.productsLocation.length = 0;
        this.ls.removeItem('unavailableDays');
        this.ls.removeItem('unavailableHours');
        this.ls.removeItem('scheduleWeek');
        this.ls.removeItem('oldUnavailableDays');
        this.ls.removeItem('oldUnavailableHours');
        $('#addNewProductLocation').modal('hide');
        Swal.fire({
          icon: 'success',
          iconColor: '#198754',
          text: 'Product location edit successfully',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        iconColor: '#bb2d3b',
        title: 'Check your schedules',
        confirmButtonColor: '#114463',
      });
    }
  }

  /**
   * *function to perform object comparisons
   */
  objectComparison(obj1, obj2) {
    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
      let val1 = obj1[key];
      let val2 = obj2[key];

      if (val1 !== val2) return false;
    }

    return true;
  }

  /**
   * *function to perform array comparisons
   */
  arrayComparison(compare, array1, array2) {
    if (compare === 'scheduleWeek') {
      array1.sort((x, y) => x.day.localeCompare(y.day));
      array2.sort((x, y) => x.day.localeCompare(y.day));
      var isEqual = JSON.stringify(array1) === JSON.stringify(array2);
    }

    if (compare === 'unavailableDays') {
      array1.sort((x, y) => x.name.localeCompare(y.name));
      array2.sort((x, y) => x.name.localeCompare(y.name));
      var isEqual = JSON.stringify(array1) === JSON.stringify(array2);
    }

    if (compare === 'unavailableHours') {
      array1.sort((x, y) => {
        x.date.toString().localeCompare(y.date);
      });
      array2.sort((x, y) => {
        x.date.toString().localeCompare(y.date);
      });
      var isEqual = JSON.stringify(array1) === JSON.stringify(array2);
    }

    return isEqual;
  }

  deleteDay(index: number) {
    const getUnavailableDays = this.unavailableDaysLS;
    getUnavailableDays.splice(index, 1);
    this.ls.setItem('unavailableDays', JSON.stringify(getUnavailableDays));
    this.unavailableDays = this.unavailableDaysLS;
  }

  deleteHour(index: number) {
    const getUnavailableHours = this.unavailableHoursLS;
    getUnavailableHours.splice(index, 1);
    this.ls.setItem('unavailableHours', JSON.stringify(getUnavailableHours));
    this.unavailableHours = this.unavailableHoursLS;
  }

  deleteProductLocation(stepper: MatStepper) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (this.location.isDefault === true) {
          Swal.fire({
            icon: 'error',
            title: 'This location is set by default',
            text: 'If you want to delete this location, set another location as the default location.',
            confirmButtonColor: '#114463',
          });
          return;
        }
        const products = this.productData;

        const existsProduct = [];

        products.forEach((e: any) => {
          if (e.productLocationID === this.id) {
            existsProduct.push(e);
          }
        });

        if (existsProduct.length === 0) {
          const deleteProductLocation: any =
            await this._productLocation.deleteProductsLocations(this.id);

          this._logService.addLocationLog(
            this.id,
            'Product location: ' + this.location.name + ' deleted'
          );

          if (deleteProductLocation.success) {
            this.getAllProducts(this.currentUser.companyId);
            this.myForm.reset();
            this.productsControl.clear();
            this.scheduleForm.reset();
            this.leadtimesControl.clear();
            stepper.reset();
            this.ls.removeItem('unavailableDays');
            this.ls.removeItem('unavailableHours');
            this.isEdit = false;
            this.isEditUnav = false;
            this.isLinear = true;
            this.productsLocation.length = 0;
            this.unavailableDays.length = 0;
            $('#addNewProductLocation').modal('hide');
            Swal.fire({
              icon: 'success',
              iconColor: '#198754',
              text: 'Your location has been deleted.',
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Delete product location',
            text: 'Remember that to remove the location it is necessary to reassign all products to another location.',
            confirmButtonColor: '#114463',
          });
          this.comesFrom = true;
          this.getProductsLocation(this.location);
          $('#addNewProductLocation').modal('hide');
          $('#productModal').modal('show');
        }
      }
    });
  }

  public productsAll: any = [];
  public location: any = {};
  public totalcountproducts: number = 0;
  public resCount: number = 0;
  async getProductsLocation(selectedLocation: ProductLocation) {
    this.location = selectedLocation;
    //Function to get all products for the company
    this.searchProductInput = ''; //Set as empty the search input variable
    this.productsAll.length = 0; //Set as empty the products array
    const result = await this._productsService.getAllByProducts(
      this.currentUser.companyId
    );
    this.totalcountproducts = 0; //Reset total products counter
    result.forEach((product: any, index: number) => {
      if (product.productLocationID === selectedLocation.id) {
        this.totalcountproducts += 1; //Increase 1 to the count badge
      }
      //Getting the products for the company
    });
    this.productsAll = Object.assign(result); //Save the products on the products array
  }

  selectProductLocation(product, i, location) {
    //Method to select a location with checkbox
    if (product.productLocationID != undefined) {
      //If the product doesnt have the location selected
      if (product.productLocationID != location.id) {
        this.productsAll[i].productLocationID = location.id;
        this.productsAll[i].productLocationName = location.name;
        this.totalcountproducts += 1; //Increase 1 to the count badge
      } else if (product.productLocationID == location.id) {
        const productDefault = this.products.find((p) => p.isDefault === true);
        this.productsAll[i].productLocationID = productDefault.id;
        this.productsAll[i].productLocationName = productDefault.name;
        this.totalcountproducts -= 1; //Rest 1 to the count badge
      } else {
        this.productsAll[i].productLocationID = location.id;
        this.productsAll[i].productLocationName = location.name;
        this.totalcountproducts += 1; //Increase 1 to the count badge
      }
    } else {
      this.totalcountproducts += 1; //Increase 1 to the count badge
      this.productsAll[i].productLocationID = location.id;
      this.productsAll[i].productLocationName = location.name;
    }
  }

  selectAllProductLocation(location) {
    this.totalcountproducts = 0; //Reset total products counter
    const productDefault = this.products.find((p) => p.isDefault === true);

    if (this.showProductsTable.length != 0) {
      const checkboxAll: any = document.getElementById('checkboxAll');
      //If the product doesnt have the location selected
      this.showProductsTable.forEach((p) => {
        const position = p - 1;
        if (checkboxAll.checked == false) {
          this.productsAll[position].productLocationID = '';
          this.productsAll[position].productLocationName = '';
          setTimeout(() => {
            this.totalcountproducts = 0; //Increase 0 to the count badge
          }, 1000);
        }

        if (checkboxAll.checked) {
          this.productsAll[position].productLocationID = location.id;
          this.productsAll[position].productLocationName = location.name;
          setTimeout(() => {
            this.totalcountproducts = this.showProductsTable.length; //Increase 1 to the count badge
          }, 1000);
        }
      });
      return;
    }

    //Method to select a location with checkbox
    const checkboxAll: any = document.getElementById('checkboxAll');
    this.productsAll.forEach((product, i) => {
      if (checkboxAll.checked == false) {
        //If the product doesnt have the location selected
        this.productsAll[i].productLocationID = productDefault.id;
        this.productsAll[i].productLocationName = productDefault.name;
        setTimeout(() => {
          this.totalcountproducts = 0; //Increase 0 to the count badge
        }, 1000);
      }

      if (checkboxAll.checked) {
        this.productsAll[i].productLocationID = location.id;
        this.productsAll[i].productLocationName = location.name;
        setTimeout(() => {
          this.totalcountproducts = this.productsAll.length; //Increase 1 to the count badge
        }, 1000);
      }
    });
  }

  public productCount: number = 0;
  public ProductCountShow: boolean = false;
  saveProducstLocation(locationID: string) {
    this.ProductCountShow = true;
    this.productCount = 0;
    //Function to save the products changes
    const { findSelected, findNoSelected } = this.productsAll.reduce((acc, product) => {
      if (product.productLocationID === locationID) {
      acc.findSelected.push(product);
      } else {
      acc.findNoSelected.push(product);
      }
      return acc;
    }, { findSelected: [], findNoSelected: [] });

    Swal.fire({
      title: 'Assing Location to Products',
      html:
        'You are about to assign this Location to a total of ' +
        findSelected.length +
        ' product(s). Are you sure?',
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#114463',
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Loading...',
          allowOutsideClick: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });
        let promises = [];
        findSelected.forEach((product) => {
          //For each selected waiver
          let promise = new Promise(async (resolve, reject) => {
            await this._productsService.updateProduct(product.id, product); //Update the product
            resolve(true);
          });
          promises.push(promise);
        });
        findNoSelected.forEach((product) => {
          //For each product that has been unmarked
          let promise = new Promise(async (resolve, reject) => {
            await this._productsService.updateProduct(product.id, product); //Update the product
            resolve(true);
          });
          promises.push(promise);
        });

        Promise.all(promises).then((_) => {
          // let projectcountstop: any = setInterval(() => {
          //   this.productCount++;
          // if (
          //   this.productCount === findSelected.length ||
          //   this.productCount === findNoSelected.length
          // ) {
          // clearInterval(projectcountstop);
          setTimeout(() => {
            Swal.close();
            this.searchProductInput = ''; //Clean the search input
            Swal.fire({
              title: 'Changes Saved',
              html: 'The Location has been added to the products.',
              icon: 'success',
              confirmButtonColor: '#114463',
            });
            if (this.comesFrom) {
              const checkboxAll: any = document.getElementById('checkboxAll');
              checkboxAll.checked = false;
              this.ProductCountShow = false;
              this.comesFrom = false;
              const leadtimes = this.leadtimeForm.get('leadtimes') as FormArray;
              leadtimes.clear();
              this.openEditModal(this.location);
              $('#productModal').modal('hide');
            } else {
              const checkboxAll: any = document.getElementById('checkboxAll');
              this.ProductCountShow = false;
              checkboxAll.checked = false;
              $('#productModal').modal('hide');
            }
          }, 1000);
          // }
          // }, 50);
        });
      }
    });
  }

  public searchProductInput: string;
  public showProductsTable: any[] = [];
  searchProduct() {
    //Function to search in the table
    var input, filter, found, table, tr, td, i, j;
    input = this.searchProductInput; //Save the input search
    filter = input.toUpperCase(); //Uppercase the input search
    table = document.getElementById('productsTable'); //Get the product table
    tr = table.getElementsByTagName('tr'); //Get the tr tags
    const showProductsTable = [];
    for (i = 0; i < tr.length; i++) {
      //For each tr
      td = tr[i].getElementsByTagName('td'); //Get all td in the tr
      for (j = 0; j < td.length; j++) {
        //For each td
        if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
          //Search the value on the td
          found = true;
        }
      }
      if (found) {
        showProductsTable.push(i);
        //If there is the same value
        tr[i].style.display = ''; //Remove display propertie
        found = false; //Set found as false
      } else if (tr[i].id != 'tableHeader') {
        //if the tr is different from the tableHeader
        tr[i].style.display = 'none'; //hide tr
      }
    }
    this.showProductsTable = showProductsTable;
  }

  submitHoursForm() {
    this.isHourSubmitted = true;

    if (this.unavailableHourForm.invalid) {
      this.unavailableHourForm.markAllAsTouched();
      return;
    }

    const values = this.unavailableHourForm.value;
    if (this.isEditUnavHour) {
      if (this.validHoursNoOverlap(values, this.indexHour)) {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: 'The hours overlap with other unavailable hours already recorded on the same date.',
          confirmButtonColor: '#114463',
        });
        return;
      }
    } else {
      if (this.validHoursNoOverlap(values)) {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: 'The hours overlap with other unavailable hours already recorded on the same date.',
          confirmButtonColor: '#114463',
        });
        return;
      }
    }

    const startHour = this.convertTimeToJSON(values.startTime);
    const endHour = this.convertTimeToJSON(values.endTime);

    const startHourFo = this.getFormatTime(startHour);
    const endHourFo = this.getFormatTime(endHour);

    let hourObj = {
      date: values.date.startDate,
      startTime: startHourFo,
      endTime: endHourFo,
      lockType: values.lockType,
      startTimeUntilClosing: values.startTimeUntilClosing,
    };

    if (this.isEditUnavHour) {
      this.unavailableHours[this.indexHour] = hourObj;
    } else {
      this.unavailableHours.push(hourObj);
    }

    let updateString = this.isEditUnavHour ? 'updated' : 'added';
    this.ls.setItem('unavailableHours', JSON.stringify(this.unavailableHours));

    Swal.fire({
      title: 'Unavailable Hour ' + updateString,
      html: 'Unavailable hour ' + updateString + ' successfully',
      icon: 'success',
      allowOutsideClick: false,
      showConfirmButton: true,
      confirmButtonColor: '#114463',
      allowEnterKey: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        this.unavailableHours = this.unavailableHoursLS;
        this.unavailableHours.forEach((items: any) => {
          items.endTime = items.endTime;
          items.startTime = items.startTime;
          return items;
        });

        this.hoursMinutes = [];
        $('#UnavailableHours').modal('hide');
        $('#addNewProductLocation').modal('show');
      }
    });
  }

  isInvalidDate(date) {
    const dayOfWeek = date.day();
    if (this.scheduleWeek) {
      const scheduleDay = this.scheduleWeek.find(
        (day) => day.day === this.getWeekdayName(dayOfWeek)
      );

      return !scheduleDay || !scheduleDay.available;
    } else {
      return false;
    }
  }

  validHoursNoOverlap(values: any, indexHour?: number) {
    if (indexHour) {
      var unavailableHoursLS = this.unavailableHoursLS.slice(0, indexHour);
    } else {
      var unavailableHoursLS = this.unavailableHoursLS;
    }

    let findHours = [];
    unavailableHoursLS.find((h: any) => {
      if (typeof h.date === 'string') {
        const date1 = moment(new Date(h.date)).format('L');
        const date2 = moment(new Date(values.date.startDate)).format('L');
        if (date1 === date2) {
          findHours.push(h);
        }
      } else {
        const date1 = moment(new Date(h.date.seconds * 1000)).format('L');
        const date2 = moment(new Date(values.date.startDate)).format('L');
        if (date1 === date2) {
          findHours.push(h);
        }
      }
    });

    if (findHours.length !== 0) {
      const validateHours = findHours.some((fh) => {
        let startTime = moment(values.startTime, 'HH:mm');
        let endTime = moment(values.endTime, 'HH:mm');

        if (fh.startTime.includes('AM') || fh.startTime.includes('PM')) {
          var fhStartTime = fh.startTime.slice(0, -3);
          var fhEndTime = fh.endTime.slice(0, -3);
        } else {
          var fhStartTime = fh.startTime;
          var fhEndTime = fh.endTime;
        }

        let startTime2 = moment(fhStartTime, 'HH:mm');
        let endTime2 = moment(fhEndTime, 'HH:mm');

        let startB = startTime.isBetween(startTime2, endTime2);
        let endB = endTime.isBetween(startTime2, endTime2);

        if (startB === true || endB === true) {
          return true;
        } else if (
          values.startTime === fhStartTime ||
          values.endTime === fhEndTime ||
          // values.startTime === fhEndTime ||
          values.endTime === fhStartTime
        ) {
          return true;
        }
        return false;
      });
      return validateHours;
    }

    return false;
  }

  isCustomDate = (m: moment.Moment): boolean | string => {
    // Retrieve the selected start date and the current date
    const selectedDateStart = moment(
      this._rentalService.datesSelected.dateStart
    ).format('YYYY-MM-DD');
    const currentDate = this.currentDateTime.clone().startOf('day');

    // Convert the input date to the timezone specified in the rental service
    const cTzDate = moment.tz(m, this._rentalService.timezone);

    // Check if the year of the date is a leap year
    const isLeapYear = cTzDate.isLeapYear();
    let dayOfYear = cTzDate.dayOfYear();

    // Adjust the day of the year for a leap year
    if (isLeapYear && cTzDate.month() > 1) {
      dayOfYear--;
    }

    // Check if the date is an invalid day, a weekly day, a monthly day, a yearly day, or before the current date,
    // and the partner is not ignoring unavailable days
    const isInvalidDay = this.invalidDays.includes(
      cTzDate.format('YYYY-MM-DD')
    );
    const isWeeklyDay = this.weeklyDays.includes(cTzDate.weekday());
    const isMonthlyDay = this.monthlyDays.includes(cTzDate.date());
    const isYearlyDay = this.yearlyDays.includes(dayOfYear);
    const isBeforeCurrentDate = cTzDate.isBefore(currentDate);

    if (
      isInvalidDay ||
      isWeeklyDay ||
      isMonthlyDay ||
      isYearlyDay ||
      isBeforeCurrentDate
    ) {
      return 'invalidDays off';
    }

    // Check if the date is the selected start date and update it if it is an invalid day
    if (selectedDateStart === moment(cTzDate).format('YYYY-MM-DD')) {
      let updatedDateStart = currentDate.clone();

      while (this.invalidDays.includes(updatedDateStart.format('YYYY-MM-DD'))) {
        updatedDateStart.add(1, 'day');
      }

      // Update the selected start date and end date in the rental service
      this._rentalService.datesSelected.dateStart = updatedDateStart.toDate();
      this._rentalService.datesSelected.dateEnd = updatedDateStart.toDate();
    }

    return false;
  };

  getWeekdayName(dayOfWeek) {
    const weekdays = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    return weekdays[dayOfWeek];
  }

  async processScheduleWeek(
    scheduleWeek: any[],
    daysOfTheWeek: number[]
  ): Promise<void> {
    const availableDays = scheduleWeek.filter((day) => day.available);

    if (availableDays.length > 0) {
      const firstAvailableDay = availableDays[0];
      const lastAvailableDay = availableDays[availableDays.length - 1];

      this.minHour = moment(firstAvailableDay.startHour, 'hh').format('LT');
      this.maxHour = moment(lastAvailableDay.endHour, 'hh').format('LT');
    }

    for (const day of scheduleWeek) {
      if (!day.available) {
        const dayweek = moment
          .tz(this._rentalService.timezone)
          .day(day.day)
          .subtract(0, 'days')
          .day();
        if (daysOfTheWeek.includes(dayweek)) {
          this.invalidDays.push(day.date);
          this.weeklyDays.push(dayweek);
        }
      }
    }
  }
  processUnavailableDays(unavailableDays: any[], weeklyDays: any[]): void {
    this.invalidDays = [];
    this.weeklyDays = weeklyDays;
    this.monthlyDays = [];
    this.yearlyDays = [];

    unavailableDays.forEach((day) => {
      const date = moment.tz(
        day.date.seconds * 1000,
        this._rentalService.timezone
      );

      if (day.periodicity === 'Every week') {
        const dayOfWeek = date.weekday();
        this.weeklyDays.push(dayOfWeek);
      } else if (day.periodicity === 'Every month') {
        const dayOfMonth = date.date();
        this.monthlyDays.push(dayOfMonth);
      } else if (day.periodicity === 'Every year') {
        const dayOfYear = date.dayOfYear();
        this.yearlyDays.push(dayOfYear);
      } else {
        this.invalidDays.push(date.format('YYYY-MM-DD'));
      }
    });
  }

  updateValidators(lockType: string) {
    const startTimeControl = this.unavailableHourForm.get('startTime');
    const endTimeControl = this.unavailableHourForm.get('endTime');
    const startTimeUntilClosingControl = this.unavailableHourForm.get(
      'startTimeUntilClosing'
    );

    if (lockType === 'hourlyLock') {
      startTimeControl.setValidators([Validators.required]);
      endTimeControl.setValidators([Validators.required]);
      startTimeUntilClosingControl.clearValidators();

      // Custom validation for endTime > startTime
      endTimeControl.setValidators([
        Validators.required,
        this.endTimeValidator.bind(this),
      ]);
    } else if (lockType === 'untilClosing') {
      startTimeControl.clearValidators();
      endTimeControl.clearValidators();
      startTimeUntilClosingControl.setValidators([Validators.required]);

      // Clear custom validation for endTime
      endTimeControl.clearValidators();
    }

    startTimeControl.updateValueAndValidity();
    endTimeControl.updateValueAndValidity();
    startTimeUntilClosingControl.updateValueAndValidity();
  }

  endTimeValidator(control: AbstractControl): Promise<ValidationErrors | null> {
    return new Promise((resolve) => {

      // don't run yet
      if (control.value === null) {
        resolve(null);
        return;
      }

      const startHour = this.convertTimeToJSON(this.unavailableHourForm.get('startTime').value);
      const endHour = this.convertTimeToJSON(control.value);
      const startHourFo = this.getFormatTime(startHour);
      const endHourFo = this.getFormatTime(endHour);

      const startTimeValue = startHourFo;
      const endTimeValue = endHourFo;

      if (this.compareHours(startTimeValue, endTimeValue) >= 0) {
        resolve({ endTimeInvalid: true });
      } else {
        resolve(null);
      }
    });
  }

  getEndTimeErrorMessage() {
    const endTimeControl = this.unavailableHourForm.get('endTime');

    if (endTimeControl?.hasError('required')) {
      return 'End time is required.';
    }

    if (endTimeControl?.hasError('endTimeInvalid')) {
      return 'End time must be greater than the start time.';
    }

    return '';
  }

  /**
   * *Start function assign default locations
   */
  async setDefaultLocation(locationID: string, stepper: MatStepper) {
    Swal.fire({
      title: 'Loading...',
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading();

    const locationDefault = this.products.find((p) => p.isDefault === true);

    const editProductLocationTrue = {
      isDefault: true,
    };

    const editProductLocationFalse = {
      isDefault: false,
    };

    const _editProductLocationTrue: any =
      await this._productLocation.editProductsLocations(
        locationID,
        editProductLocationTrue
      );

    const _editProductLocationFalse: any =
      await this._productLocation.editProductsLocations(
        locationDefault.id,
        editProductLocationFalse
      );

    const editCompany: any = await this._productLocation.editCompanies(
      this.currentUser.companyId,
      locationID
    );

    const promises = [
      _editProductLocationTrue,
      _editProductLocationFalse,
      editCompany,
    ];
    Promise.all(promises)
      .then((resp) => {
        this.getAllProducts(this.currentUser.companyId);
        this.myForm.reset();
        this.productsControl.clear();
        this.scheduleForm.reset();
        stepper.reset();
        this.isEdit = false;
        this.isLinear = true;
        this.productsLocation.length = 0;
        this.ls.removeItem('unavailableDays');
        this.ls.removeItem('unavailableHours');
        $('#addNewProductLocation').modal('hide');
        Swal.fire({
          icon: 'success',
          iconColor: '#198754',
          text: 'Your location has been set as default localization.',
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((reason) => {
        console.log(reason);
      });
  }

  /**
   * *Start function assign default locations
   */
  async setLeadtime(locationID: string) {
    if (locationID !== undefined && this.isLeadtime === true) {
      Swal.fire({
        title: 'Loading...',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      Swal.showLoading();
      this.isLeadtime = false;
      this.locationsLead.length = 0;
      this.locationsLead = this.fiterLocations(locationID);
      this.locationsLead.forEach((p: any, i: number) => {
        this.leadtimesControl.at(i).get('minutes').clearValidators();
        this.leadtimesControl.at(i).get('minutes').updateValueAndValidity();
      });

      const editProductLocationTrue = {
        isLeadtime: false,
      };

      const _editProductLocationTrue: any =
        await this._productLocation.editProductsLocations(
          locationID,
          editProductLocationTrue
        );

      const promises = [_editProductLocationTrue];

      Promise.all(promises)
        .then((resp) => {
          Swal.fire({
            icon: 'success',
            iconColor: '#198754',
            text: 'Leadtime is disabled',
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((reason) => {
          console.log(reason);
        });
    } else if (locationID !== undefined && this.isLeadtime === false) {
      Swal.fire({
        title: 'Loading...',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      Swal.showLoading();
      this.isLeadtime = true;
      this.locationsLead.length = 0;
      this.locationsLead = this.fiterLocations(locationID);
      this.locationsLead.forEach((p: any, i: number) => {
        this.leadtimesControl
          .at(i)
          .get('minutes')
          .setValidators([
            Validators.required,
            Validators.pattern('^[0-9]+$'),
            Validators.min(1),
          ]);
        this.leadtimesControl.at(i).get('minutes').updateValueAndValidity();
      });

      const editProductLocationTrue = {
        isLeadtime: true,
      };

      const _editProductLocationTrue: any =
        await this._productLocation.editProductsLocations(
          locationID,
          editProductLocationTrue
        );

      const promises = [_editProductLocationTrue];

      Promise.all(promises)
        .then((resp) => {
          Swal.fire({
            icon: 'success',
            iconColor: '#198754',
            text: 'Leadtime is enabled',
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((reason) => {
          console.log(reason);
        });
    } else if (locationID === undefined && this.isLeadtime === false) {
      Swal.fire({
        title: 'Loading...',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      Swal.showLoading();
      this.locationsLead.length = 0;
      this.locationsLead = this.fiterLocations('');
      this.locationsLead.forEach((p: any, i: number) => {
        this.leadtimesControl
          .at(i)
          .get('minutes')
          .setValidators([
            Validators.required,
            Validators.pattern('^[0-9]+$'),
            Validators.min(1),
          ]);
        this.leadtimesControl.at(i).get('minutes').updateValueAndValidity();
      });
      this.isLeadtime = true;
      Swal.fire({
        icon: 'success',
        iconColor: '#198754',
        text: 'Leadtime is enabled',
        showConfirmButton: false,
        timer: 1500,
      });
    } else if (locationID === undefined && this.isLeadtime === true) {
      Swal.fire({
        title: 'Loading...',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      Swal.showLoading();
      this.locationsLead.length = 0;
      this.locationsLead = this.fiterLocations('');
      this.locationsLead.forEach((p: any, i: number) => {
        this.leadtimesControl.at(i).get('minutes').clearValidators();
        this.leadtimesControl.at(i).get('minutes').updateValueAndValidity();
      });
      this.isLeadtime = false;
      Swal.fire({
        icon: 'success',
        iconColor: '#198754',
        text: 'Leadtime is disabled',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }
}
