import { Observable } from 'rxjs';
import {
  AggregateSpec,
  DocumentSnapshot,
  FieldPath,
  FieldValue,
  or,
  OrderByDirection,
  WhereFilterOp,
} from 'firebase/firestore';

import { Collection } from 'src/app/v2/models/collection-reference.model';

export interface Aggregate {
  count?: boolean
  spec?: AggregateSpec
}

export interface Condition {
    field: string | FieldPath,
    operator: WhereFilterOp,
    value: FieldValue | any // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const JunctionKey = 'fn'

export interface Junction {
  fn: typeof or, // or, and are of the same type
  conditions: Condition[],
}

export type QueryConfig = Condition | Junction

export interface OrderBy {
    field: string | FieldPath,
    direction: OrderByDirection
}

export enum PageBy {
  DefaultSize = 100,
  MaximumSize = 500,
  NoLimitSize = 0,
}

export interface PaginateBy {
    endBefore?: DocumentSnapshot | FieldValue,
    startAfter?: DocumentSnapshot | FieldValue,
    limit: number
}

export interface DataSourceIface {
  count(collection: Collection, configs: QueryConfig[]): Promise<number>
  find(collection: Collection, configs: QueryConfig[], sort?: OrderBy[], page?: PaginateBy): Observable<any[]>
}
