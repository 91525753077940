import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectPropertyFilterPipe'
})
export class ImgFilterPipe implements PipeTransform {

  transform(array, term){
    // Create an empty array
    let filteredArray = []

    if(term && term.length > 0){

        filteredArray = array.filter((item) => {
      return Object.keys(item).some((key) => {
        console.log(key);
        // if(key === "productName" || key === "productNumber" || key === "productSize" || key === "productCategory" || key === "price" || key === "description" || key === "groupName"){
          return item[key].toString().toLowerCase().includes(term.toLowerCase())
        // }
      })
    })

    }

    return term.length > 0 ? filteredArray : array;
  }

}
