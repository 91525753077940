import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DashboardComponent} from "./dashboard.component";
import {NgChartsModule} from "ng2-charts";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";



@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    CommonModule,
    NgChartsModule,
    MatButtonModule
  ]
})
export class DashboardModule { }
