import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bad-page',
  templateUrl: './bad-page.component.html',
  styleUrls: ['./bad-page.component.scss']
})
export class BadPageComponent implements OnInit{
  
  ngOnInit(): void {
    
  }
}
