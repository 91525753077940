import { Component, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

/* Services */
import { BookingPage } from 'src/app/models/storage/booking-page.model';
import { ProductGroup } from 'src/app/models/storage/product-group.model';

@Component({
  selector: 'app-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss']
})

export class TemplateEditorComponent implements OnDestroy {
  @Output() addContentModalSubmitted = new EventEmitter<any>(); // Event is fired when add btn is clicked - Custom Flow Component listening for this event
  @Output() saveClicked: EventEmitter<any> = new EventEmitter<any>(); // Event is fired when save btn is clicked - Custom Flow Component listening for this event
  @Input() public catalogTitle: string;

  /* Templates Summary Data */
  copyOfTemplateFromDB; // The template data presented as it is in firestore
  template; // the template data being edited on the screen currently
  templateID;
  searchValue: string = "";

  /* Info passed to us via collection -> custom flow -> here */
  @Input() pages;
  @Input() productGroups;

  /* Utility */
  addProduct: boolean = true; // Shows / Hides the different options in the Add Form
  selectionListEmpty: boolean = false;
  showNoResultMsg: boolean = false;

  /* Pages */
  selectedPage: any; // the page selected by the user when trying to add content
  @Output() showCreateNewPageForm = new EventEmitter<any>;
  @Output() closePageModal = new EventEmitter<any>;

  /* Products */
  selectedProduct;
  @ViewChild('productGroupList') productGroupList: ElementRef;

  @ViewChild('pageList') pageList: ElementRef;
  protected pageForm: FormGroup;
  protected productGroupForm: FormGroup;

  subs = new Subscription(); // group of subscriptions

  constructor(private fb: FormBuilder, private router: Router) {
  }

  ngOnInit() {
    // Create controls based of pages
    const pageControls = this.pages.reduce((acc, page) => {
      acc[page.id] = this.fb.control(false);
      return acc;
    }, {});

    // Page Form
    this.pageForm = this.fb.group(pageControls);

    // Create controls based of product groups
    const productGroupControls = this.productGroups.reduce((acc, page) => {
      acc[page.id] = this.fb.control(false);
      return acc;
    }, {});

    // Product group form
    this.productGroupForm = this.fb.group(productGroupControls);
  }

  public addNewPageControl(pageID: string){
    this.pageForm.addControl(pageID, this.fb.control(false));
  }

  ngOnDestroy(): void { this.subs.unsubscribe(); }

  /* Utility */
  showAddModal() { $("#addModal").modal('show') }; // used by custom flow component
  closeAddModal() { $("#addModal").modal('hide'); this.resetForms(); }
  navigateToBookingSuite() { this.router.navigate(["/partner/booking-suite"]) } // Check if unfinished edits
  search(searchValue) { this.searchValue = searchValue; }
  selectionListUpdated() { this.selectionListEmpty = false; } // on change

  /* Pages */
  createNewPage() { this.closeAddModal(); this.showCreateNewPageForm.emit(); }

  addToTemplate() {
    const selectedPages: BookingPage[] = this.pages.filter(page => this.pageForm.get(page.id).value);
    const selectedProductGroups: ProductGroup[] = this.productGroups.filter(productGroup => this.productGroupForm.get(productGroup.id).value);

    this.selectionListEmpty = false;
    let selectionList = [...selectedPages, ...selectedProductGroups];

    // Return if no pages or products have been selected
    if (selectionList.length <= 0) {
      this.selectionListEmpty = true;
      return
    }

    this.addContentModalSubmitted.emit({pages: selectedPages, productGroups: selectedProductGroups})
    this.resetForms();
    this.closeAddModal();
  }

  private resetForms(){
    this.pageForm.reset();
    this.productGroupForm.reset();
  }

  /* Products */
  navigateToInventory() { this.router.navigate(["/partner/inventory"]) };

  /* Template Options */
  save() { this.saveClicked.emit() } // emit save event to custom flow component
}
