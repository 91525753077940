import { Component, Input, Output, EventEmitter } from '@angular/core';

/* Models */
import { BookingTemplate } from 'src/app/v2/models/booking-flow.model';
import { Cart } from 'src/app/models/storage/cart.model';
import { BookingFlowContentType } from 'src/app/v2/models/booking-flow.model';

@Component({
  selector: 'app-booking-header',
  templateUrl: './booking-header.component.html',
  styleUrls: ['./booking-header.component.css']
})
export class BookingHeaderComponent {
  constructor() { }
  protected BookingFlowNavigationType = BookingFlowContentType; // Expose enum to template
  @Output() protected bookingFlowNavigate: EventEmitter<BookingFlowContentType> = new EventEmitter(); // Emits on button click

  @Input() public templateObj: BookingTemplate;
  @Input() public cartObj: Cart;
  @Input() public isScreenLarge: boolean = true; // Assumed to be true on Creator
  @Input() public isCreator: boolean;
  @Input() public currentBookingFlowNavigationType: BookingFlowContentType;
}
