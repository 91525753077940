<div style="z-index: 9999" class="modal fade" id="booking-next-status" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div *ngIf="rental" class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel" style="margin-bottom: 0px">Update Status</h2>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div *ngIf="statusSelected == 'Check Out'" class="mt-2 mb-4">
          <h2>Waiver</h2>

          <div class="d-flex">
            <div style="margin-left: 11px; margin-right:30px; margin-top: 10px; width: 160px;">
              <app-file-upload [storagePath]="'bookings/' + rental?.id + '/'" [mode]="'button'" [fileName]="'waiver'"
                (urlEvent)="getFileURL($event)" [isEditing]="true" [showLink]="true" [uploadBtnFileName]="false"
                [btnTitle]="'Upload Waiver'">
              </app-file-upload>
            </div>

            <span *ngIf="rental.waiverSignature === undefined"
              class="border border-secondary border-2 p-3 ml-auto rounded-3 text-secondary">
              <a href="/waiver/{{rental.id}}" target="_blank" data-toggle="tooltip" title="Sign the Digital Waiver"
                class="link-secondary">Waiver has not been digitally signed</a></span>

            <span *ngIf="rental.waiverSignature !== undefined && rental.waiverDate !== undefined"
              class="border border-secondary border-2 p-3 ml-auto rounded-3 text-secondary">
              {{rental.waiverSignature}} : {{rental.waiverDate}} </span>
          </div>


          <!--
          <div *ngFor="let waiver of rental.waiver" class="mt-2">
            <a href="javascript:void(0)" (click)="openWaiver(waiver)">Waiver</a>
          </div>
-->

        </div>
        <div class="mt-1" *ngIf="statusSelected == 'Check Out' || statusSelected == 'Check In'">
          <h2>Products</h2>
        </div>

        <p class="mr-2">
          Status Date
        </p>
        <input type="date" id="date" [ngModel]="statusDate | date:'yyyy-MM-dd'" (ngModelChange)="dateChange($event)"
          name="date">


        <!-- <h2 *ngIf="rental.isCheckedIn && !rental.isComplete">
            Everything looks good?  Go ahead and mark as complete.
        </h2> -->
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
        <button (click)="updateStatus()" *ngIf="rental" type="button" class="btn btn-warning  btn-lg">
          <span *ngIf="statusSelected == 'Reserved'">Reserved</span>
          <span *ngIf="statusSelected == 'Check Out'">Check Out</span>
          <span *ngIf="statusSelected == 'Check In'">Check In</span>
          <span *ngIf="statusSelected == 'Complete'">Complete</span>

        </button>
      </div>

      <span *ngIf="rental.waiver == undefined" class="opaque54">Waiver has not been uploaded.</span>


    </div>
    <!--
    <div *ngFor="let waiver of rental.waiver" class="mt-2">
      <a href="javascript:void(0)" (click)="openWaiver(waiver)">Waiver</a>
    </div>
-->

  </div>
  <!--Drop the table that doesnt fix on the modal-->
  <!--<div class="mt-1" *ngIf="rental !== undefined && rental.isCheckedIn !== undefined && !rental.isCheckedIn">
    <h2>Products</h2>
    <app-booking-products-table (checkboxChange)="tableCheckboxUpdated($event)" [rental]="rental"
                                [isNextStatusPopup]="true">
    </app-booking-products-table>
  </div>-->

  <!-- <h2 *ngIf="rental.isCheckedIn && !rental.isComplete">
      Everything looks good?  Go ahead and mark as complete.
  </h2> -->
</div>
<!--
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
  <button (click)="updateStatus()" *ngIf="rental" type="button" class="btn btn-warning  btn-lg">
    <span *ngIf="!rental.isCheckedOut">Check Out</span>
    <span *ngIf="rental.isCheckedOut && !rental.isCheckedIn">Check In</span>
    <span *ngIf="rental.isCheckedIn && !rental.isComplete">Complete</span>

  </button>
</div>

-->