import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable, firstValueFrom, forkJoin, take, lastValueFrom } from 'rxjs';
import { v4 as uuid } from 'uuid'; // To later be used for client side generated pages

/* Models */
import { BookingTemplate, BookingContent } from 'src/app/v2/models/booking-flow.model';
import { MappedGridstackItem } from 'src/app/v2/models/booking-flow.model';
import { BookingPage } from 'src/app/models/storage/booking-page.model';
import { Collection } from 'src/app/v2/models/collection-reference.model';
import { BookingFlowContentActionEvent, BookingFlowContentAction } from 'src/app/v2/models/booking-flow.model';

/* Libraries */
import { Modal } from 'bootstrap';

/* Services */
import { FirestoreService } from 'src/app/v2/services/firestore.service';
import { ProductGroup } from 'src/app/models/storage/product-group.model';
import { Product } from 'src/app/models/storage/product.model';
import { ProductLocation } from 'src/app/models/storage/product-location.model';
import { BookingFlowService } from 'src/app/v2/services/booking-flow.service';
import { OnNavigationLeave } from 'src/app/v2/services/on-navigation-leave.service';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { AlertService } from 'src/app/v2/services/alert.service';

/* Components */
import { BookingCatalogViewComponent } from '../../commons/booking-flows/catalog/booking-catalog-view/booking-catalog-view.component';
import { PageFormComponent } from 'src/app/partner/booking-suite/page-form/page-form.component';
import { TemplateEditorComponent } from 'src/app/partner/booking-suite/template-editor/template-editor.component';
import { PageBy } from 'src/app/v2/models/firestore-interaction.model';

@Component({
  selector: 'app-booking-catalog-creator',
  templateUrl: './booking-catalog-creator.component.html',
  styleUrls: ['./booking-catalog-creator.component.css', '../../../styles/booking-flow.scss']
})

export class BookingCatalogCreatorComponent implements OnInit, OnNavigationLeave {
  protected isCreator: boolean = true;
  protected templateID: string; // From QueryURL
  protected templateObj: BookingTemplate
  protected subs = new Subscription();
  protected customerContentID: string = "";
  protected isLoaded: boolean = false;
  protected creatorIsEditing: boolean = false; // Modifies the page form
  protected breadcrumbMap: {[key: string]: string[]} = {};
  protected gridHeight: number = 0; // The height of the grid in the db
  protected showSaveChangesWarningOnNavigationLeave: boolean = false;

  /* Components */
  @ViewChild(BookingCatalogViewComponent) bookingCatalogViewComponent: BookingCatalogViewComponent;
  @ViewChild(PageFormComponent) pageFormComponent: PageFormComponent;
  @ViewChild(TemplateEditorComponent) templateEditorComponent: TemplateEditorComponent;

  /* Element Ref */
  @ViewChild('pageModal') pageModal: ElementRef;

  /* Collection Arrays */
  protected bookingPagesCollectionArray: BookingPage[] = [];
  protected productGroupCollectionArray: ProductGroup[] = [];
  protected productsCollectionArray: Product[] = [];
  protected productLocationArray: ProductLocation[] = [];

  /* Collection Maps */
  protected bookingPagesCollectionMap: { [key: string]: BookingPage } = {};
  protected productGroupCollectionMap: { [key: string]: ProductGroup } = {};
  protected productsCollectionMap: { [key: string]: Product } = {};
  protected productLocationCollectionMap: { [key: string]: ProductLocation } = {};

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private firestoreService: FirestoreService,
    private alertService: AlertService,
    private bookingFlowService: BookingFlowService,
  ) {}

  @HostListener('window:beforeunload', ['$event']) // Catches and optionally displays save alert on new navigation
  private unloadNotification(): boolean {
    if (this.showSaveChangesWarningOnNavigationLeave) {
      return window.confirm('Changes you made may not be saved.');
    }
    return true;
  }

  public canDeactivate(): Observable<boolean> | Promise<boolean> | boolean { // Catches and optionally displays save alert on router navigation
    if (this.showSaveChangesWarningOnNavigationLeave) {
      return window.confirm('Changes you made may not be saved.');
    }
    return true;
  }

  public async ngOnInit(): Promise<void> {
    // 1.) Parse query and route parameters
    this.parseRouteParams();
    // 2.) Query / Verify valid TemplateID
    await this.queryTemplateAndUserAccess();
    // 3.) Prep template related variables
    this.createTemplateRelatedVariables();
    // 4.) Query Other Collections
    this.queryCollections();
  }

  private createTemplateRelatedVariables(): void {
    let breadcrumbMap: {[key: string]: string[]} = {};
    this.templateObj.breadcrumbs.forEach((bc) => {
      breadcrumbMap[bc.pageID] = bc.breadcrumbs;
    })
    this.breadcrumbMap = breadcrumbMap;
  }

  private async queryTemplateAndUserAccess(): Promise<BookingTemplate | void> {
    try {
      const routeQueryGuardResponse = await lastValueFrom(this.activatedRoute.data.pipe(take(1)));
      this.templateObj = routeQueryGuardResponse.templateID;
      return;
    }
    catch (err){
      console.error(err);
      this.router.navigate(['404']);
      return;
    }
  }

  private queryCollections(): void {
    // Define the observables for each collection
    const bookingPagesCollection$ = this.firestoreService.getCollection(Collection.BookingPages, [
      { field: 'companyID', operator: '==', value: this.templateObj.companyID },
      { field: 'isActive', operator: '==', value: true }
    ]);
    const productGroupCollection$ = this.firestoreService.getCollection(Collection.ProductGroup, [
      { field: 'companyID', operator: '==', value: this.templateObj.companyID },
      { field: 'isActive', operator: '==', value: true }],
      [], { limit: PageBy.MaximumSize }
    );
    const productsCollection$ = this.firestoreService.getCollection(Collection.Products, [
      { field: 'companyID', operator: '==', value: this.templateObj.companyID },
      { field: 'isActive', operator: '==', value: true }],
      [], { limit: PageBy.MaximumSize }
    );
    const productLocationCollection$ = this.firestoreService.getCollection(Collection.ProductLocations, [
      { field: 'companyID', operator: '==', value: this.templateObj.companyID },
      { field: 'isActive', operator: '==', value: true }
    ]);

    // Convert observables to promises (allows us process in parallel)
    const bookingPagesPromise = firstValueFrom(bookingPagesCollection$).then((res) => {
      return this.processBookingPages(res);
    });
    const productGroupPromise = firstValueFrom(productGroupCollection$).then((res) => {
      return this.processProductGroup(res);
    });
    const productsPromise = firstValueFrom(productsCollection$).then((res) => {
      return this.processProducts(res);
    });
    const productLocationPromise = firstValueFrom(productLocationCollection$).then((res) => {
      return this.processProductLocation(res);
    });

    // Wait for all promises to complete and processing to finish
    forkJoin([
      bookingPagesPromise,
      productGroupPromise,
      productsPromise,
      productLocationPromise
    ]).pipe(take(1)).subscribe({
      next: () => {
        this.showCreatorView();
      },
      error: (error) => {
        console.error("Error loading collections: ", error);
      }
    });
  }

  private processBookingPages(res: BookingPage[]): Promise<void> {
    return new Promise((resolve) => {
      this.bookingPagesCollectionArray = res;
      this.bookingPagesCollectionMap = this.firestoreService.createCollectionMap<BookingPage>(res);
      resolve();
    })
  }

  private processProductGroup(res: ProductGroup[]): Promise<void> {
    return new Promise((resolve) => {
      this.productGroupCollectionArray = res;
      this.productGroupCollectionMap = this.firestoreService.createCollectionMap<ProductGroup>(res);
      resolve();
    })
  }

  private processProducts(res: Product[]): Promise<void> {
    return new Promise((resolve) => {
      this.productsCollectionMap = this.firestoreService.createCollectionMap<Product>(res);
      resolve();
    })
  }

  private processProductLocation(res: ProductLocation[]): Promise<void> {
    return new Promise((resolve) => {
      this.productLocationCollectionMap = this.firestoreService.createCollectionMap<ProductLocation>(res);
      resolve();
    })
  }

  private showCreatorView(): void {
    this.isLoaded = true;
  }

  protected processCreatorCardAction(action: BookingFlowContentActionEvent): void {
    switch (action.type) {
      case BookingFlowContentAction.GridItemPositionChange:
        this.updateContentGridPosition(action);
        console.log(action)
        this.showSaveChangesWarningOnNavigationLeave = true;
        break;

      case BookingFlowContentAction.EditPage:
        this.creatorIsEditing = true;
        this.pageFormComponent.setFormWithPageValues(this.bookingPagesCollectionMap[action.pageID]);
        this.openPageModal();
        break;

      case BookingFlowContentAction.Remove:
        this.removeContentAndBreadcrumbsFromTemplate(action.contentID);
        this.bookingCatalogViewComponent.loadCurrentContent();
        this.showSaveChangesWarningOnNavigationLeave = true;
        break;
    }
  }

  private removeContentAndBreadcrumbsFromTemplate(contentID: string): void {
    // Remove all breadcrumbs that stem from the contentID being deleted (contentID and it's children)
    for (let i = this.templateObj.breadcrumbs.length - 1; i >= 0; i--) {
      // Delete if the pageID === the contentID being deleted
      if (this.templateObj.breadcrumbs[i].pageID === contentID) {
        this.removeContentFromTemplate(contentID);
        delete this.breadcrumbMap[this.templateObj.breadcrumbs[i].pageID]; // Delete breadcrumb from map
        this.templateObj.breadcrumbs.splice(i, 1);
        continue;
      }

      // Delete contentIDs children
      const positionOfDeletedContentID_inChildrensBreadcrumbs: number = this.customerContentID == "" ? 1 : this.breadcrumbMap[this.customerContentID].length + 1; // If "", we're deleting on the home page, meaning the length of the breadcrumbs [""] will always be 1
      const itteratedContentID: string | undefined = this.templateObj.breadcrumbs[i].breadcrumbs[positionOfDeletedContentID_inChildrensBreadcrumbs]; // If this value == contentID being deleted, it's a child of the contentID being deleted (and should be deleted)
      if (itteratedContentID === contentID) {
        this.removeContentFromTemplate(this.templateObj.breadcrumbs[i].pageID);
        delete this.breadcrumbMap[this.templateObj.breadcrumbs[i].pageID]; // Delete breadcrumb from map
        this.templateObj.breadcrumbs.splice(i, 1); // Delete breadcrumb from template arr
        continue;
      }
    }
  }

  private updateContentGridPosition(action: BookingFlowContentActionEvent): void {
    // Create a map of changed grid content (swap could have two items changing positions, so it's possible to get more than one change at a time)
    let mapOfChangedContent: { [key: string]: MappedGridstackItem } = {};
    action.updatedContArray.forEach((item: MappedGridstackItem) => {
      mapOfChangedContent[item.contentData.contentID] = item;
    });

    this.templateObj.content.forEach((cont: BookingContent) => { // If this is too slow, in the future the template could be mapped so that accessing is faster. This would require updating the template object by the map before saving tho
      // Update each changed content's grid position
      if (mapOfChangedContent[cont.contentID]) {
        if(!cont.gridstackGridPosition){ // If the gridstackGridPosition property doesn't exist on the template yet, create it (this occurs when old booking flows haven't been saved and a change is attempting to be processed such as drag)
          cont.gridstackGridPosition = {};
        }
        cont.gridstackGridPosition.w = mapOfChangedContent[cont.contentID].w;
        cont.gridstackGridPosition.h = mapOfChangedContent[cont.contentID].h;
        cont.gridstackGridPosition.x = mapOfChangedContent[cont.contentID].x;
        cont.gridstackGridPosition.y = mapOfChangedContent[cont.contentID].y;
      }
    })
  }

  private findContentByID(contentID): {reference: BookingContent, index: number} {
    const index = this.templateObj.content.findIndex((i) => { return i.contentID === contentID });
    return { reference: this.templateObj.content[index], index: index };
  }

  private removeContentFromTemplate(contentID: string): void {
    const content = this.findContentByID(contentID);
    this.templateObj.content.splice(content.index, 1);
  }

  private parseRouteParams(): void {
    this.templateID = this.activatedRoute.snapshot.paramMap.get("templateID");
  }

  protected setCreatorContentID($event: string): void {
    this.customerContentID = $event;
  }

  protected onPageEditFormSubmission(bookingPageDoc: BookingPage): void {
    this.updatePageCollection(bookingPageDoc, 'edit');
    this.closePageModal();
    this.bookingCatalogViewComponent.loadCurrentContent();
  }

  protected onNewPageFormSubmission(bookingPageDoc: BookingPage): void {
    this.updatePageCollection(bookingPageDoc, 'add');
    this.templateEditorComponent.addNewPageControl(bookingPageDoc.id);
    this.alertService.fireSweetAlert("Page Created Successfully", "", "success", true);
    this.closePageModal();

  }

  protected closePageModal(): void {
    const modal = Modal.getInstance(this.pageModal.nativeElement);
    modal.hide();
  }

  protected switchToNewPageForm(): void {
    this.creatorIsEditing = false;
    this.pageFormComponent.resetForm();
    this.openPageModal();
  }

  protected openPageModal(): void {
    const modal = new Modal(this.pageModal.nativeElement);
    modal.show();
  }

  protected updatePageCollection(bookingPageDoc: BookingPage, action: 'add' | 'edit'): void {
    switch (action) {
      case 'add':
        this.bookingPagesCollectionArray.push(bookingPageDoc);
        this.bookingPagesCollectionMap[bookingPageDoc.id] = bookingPageDoc;
        break;
      case 'edit':
        const pageIndex = this.bookingPagesCollectionArray.findIndex((i) => { return i.id === bookingPageDoc.id });
        this.bookingPagesCollectionArray[pageIndex] = bookingPageDoc;
        this.bookingPagesCollectionMap[bookingPageDoc.id] = bookingPageDoc;
        this.alertService.fireSweetAlert("Page Updated Successfully", "", "success", true, false);
        break
    }
  }

  protected async onSave(): Promise<void> {
    const validationResult = await this.bookingFlowService.validateBookingFlowContent(this.templateObj.content);

    if (!validationResult.bookingFlowValid) {
      let errorMsg = `The following content is unavailable: `

      // If any pages were found to be missing (deleted or softdeleted)
      validationResult.missingPageIDs.forEach((id: string) => {
        if (this.bookingPagesCollectionMap[id]) {
          errorMsg += `${this.bookingPagesCollectionMap[id].title}, `;
        }
      })

      // If any product groups were found to be missing (deleted or softdeleted)
      validationResult.missingProductGroupIDs.forEach((id: string) => {
        if (this.productGroupCollectionMap[id]) {
          errorMsg += `${this.productGroupCollectionMap[id].groupName}, `;
        }
      });

      // Remove trailing comma
      errorMsg = errorMsg.slice(0, -2);

      this.alertService.fireSweetAlert("Error - Invalid Booking Flow", errorMsg, "error");
      return
    }

    try {
      this.updateLegacyGridPositionToGridstack();
      await this.firestoreService.updateDocument<BookingTemplate>(Collection.BookingTemplates, this.templateID, this.templateObj);
      this.alertService.fireSweetAlert("Booking Flow Saved Successfully", "", "success");
      this.showSaveChangesWarningOnNavigationLeave = false; // Reset save warning
    }
    catch (err) {
      this.alertService.fireSweetAlert("Error - Failed to save Booking Flow", "", "error", false, false, err);
    }
  }

  private updateLegacyGridPositionToGridstack(){
    this.templateObj.content.forEach((cont: BookingContent)=>{
      if(!cont.gridstackGridPosition && cont.gridPosition){ //
        cont.gridstackGridPosition = this.bookingFlowService.translateGridsterToGridstack(cont.gridPosition);
      }
    })
  }

  private generateGridContent(listOfNewlyAddedContent: ProductGroup[] | BookingPage[], type: "page" | "productGroup"): void {
    listOfNewlyAddedContent.forEach(async (selectedContent: ProductGroup | BookingPage) => {
      let cont: BookingContent = { id: selectedContent.id, isPage: false, isItem: false, isExternal: false, title: "", contentID: uuid(), onPage: this.customerContentID, gridHeight: 400, gridstackGridPosition: { h: 2, w: 6, x: 0, y: 0 }}
      let breadcrumbs: string[] = [];
      switch (type) {
        case "productGroup":
          cont.title = this.productGroupCollectionMap[selectedContent.id].groupName;
          cont.isPage = false;
          cont.isItem = true;
          break;
        case "page":
          cont.title = this.bookingPagesCollectionMap[selectedContent.id].title;
          cont.isPage = true;
          cont.isItem = false;
          break;
        default:
          throw new Error('Error - Failed to add because the content type was not recognized');
      }

      this.templateObj.content.push(cont);
      // Breadcrumb generation
      if (this.customerContentID == "") {
        breadcrumbs = [""]; // Home
      }
      else {
        breadcrumbs = JSON.parse(JSON.stringify(this.breadcrumbMap[this.customerContentID])); // Take the content parent's breadcrumb trail
        breadcrumbs.push(this.customerContentID); // Append the content parentID onto it
      }

      this.templateObj.breadcrumbs.push({ pageID: cont.contentID, breadcrumbs }); // push onto breadcrumb arr
      this.breadcrumbMap[cont.contentID] = breadcrumbs; // add to breadcrumb map
    })
    this.bookingCatalogViewComponent.loadCurrentContent();
  }


  protected async creatorAddContentToGrid(listOfNewlyAddedContent: { pages: BookingPage[], productGroups: ProductGroup[] }): Promise<void> {
    this.generateGridContent(listOfNewlyAddedContent.pages, 'page');
    this.generateGridContent(listOfNewlyAddedContent.productGroups, 'productGroup');
  }
}
