import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-fleetmaid-spinner',
  templateUrl: './fleetmaid-spinner.component.html',
  styleUrls: ['./fleetmaid-spinner.component.css']
})
export class FleetmaidSpinnerComponent implements OnInit {

  @Input() public widthPx: number = 130;

  constructor() { }

  ngOnInit() {
  }

}
