import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { CurrentUserService } from './current-user.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ImageLibraryService } from './image-library.service';
import { BookingTemplate, viewTypePreference } from 'src/app/models/storage/booking-template.model';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  /* Defaults */
  template: BookingTemplate = {
    title: 'Header',
    isActive: true,
    companyID: '',
    content: [],
    breadcrumbs: [],
    gridHeight: 400,
    gridRows: 6,
    viewTypePreference: viewTypePreference.catalog,
    editState: { isEditing: false, user: '' },
    dateCreated: new Date(),
    datesInactivated: [],
    companyLogo: '',
    companyName: '',
  };
  pages = {
    companyID: '',
    content: [],
    img: '',
    datesInactivated: [],
    isActive: true,
    subHeading: '',
    title: '',
    dateCreated: Date.now(),
  };
  editsMadeOnCustomFlow = new Subject();

  constructor(
    private afs: AngularFirestore,
    private currentUserService: CurrentUserService,
    private imageLibraryService: ImageLibraryService
  ) { }

  /**
   * @description Changes the isEditing field on the template. This enables / disables another user's ability to edit a template. (1 user at a time)
   * @param {*} isEditing - if isEditing == true then update the isEditing field to true, else false
   * @param {*} templateID - the template doc ID
   */
  async toggleIsEditing(isEditing, templateID, user) {
    if (isEditing) {
      this.afs
        .collection('bookingTemplates')
        .doc(templateID)
        .update({ editState: { isEditing: true, user: user } });
    } else {
      this.afs
        .collection('bookingTemplates')
        .doc(templateID)
        .update({ editState: { isEditing: false, user: '' } });
    }
  }

  /* Page Methods*/
  getMultiplePages(list) {
    return this.afs
      .collection('bookingPages', (ref) =>
        ref.where('__name__', 'in', list).where('isActive', '==', true)
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((action) => {
            let data = action.payload.doc.data();
            data['id'] = action.payload.doc.id;
            console.log(data);
            return data;
          });
        })
      );
  }

  /* Page Methods*/
  getAllPagesForCompany(): Observable<any> {
    // subscribe to bookingPage collection updates - by companyID
    return this.afs
      .collection('bookingPages', (ref) =>
        ref
          .where(
            'companyID',
            '==',
            this.currentUserService.currentUser.companyId
          )
          .where('isActive', '==', true)
          .orderBy('title')
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((action) => {
            let data = action.payload.doc.data();
            data['id'] = action.payload.doc.id;
            return data;
          });
        })
      );
  }

  getAllPagesForCompanyByParam(companyID): Observable<any> {
    // subscribe to bookingPage collection updates - by companyID
    return this.afs
      .collection('bookingPages', (ref) =>
        ref.where('companyID', '==', companyID).where('isActive', '==', true)
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((action) => {
            let data = action.payload.doc.data();
            data['id'] = action.payload.doc.id;
            return data;
          });
        })
      );
  }

  getAllPageChangesForCompany(): Observable<any> {
    // subscribe to bookingPage collection updates - by companyID
    return this.afs
      .collection('bookingPages', (ref) =>
        ref
          .where(
            'companyID',
            '==',
            this.currentUserService.currentUser.companyId
          )
          .where('isActive', '==', true)
          .orderBy('title')
      )
      .stateChanges()
      .pipe(
        map((changes) => {
          return changes.map((action) => {
            // console.log(action)
            let data = action.payload.doc.data();
            data['id'] = action.payload.doc.id;
            data['type'] = action.type;
            return data;
          });
        })
      );
  }

  async getPageByID(id) {
    let page;
    await this.afs
      .collection('bookingPages')
      .doc(id)
      .ref.get()
      .then((res) => {
        page = res.data();
      });
    return page;
  }

  addPageToCustomBookingFlow(templateID, pageID, content, creatorPageID) {
    // creatorPageID tells us what page the creator is currently on (if "" == template)

    if (creatorPageID != '') {
    }

    console.log(content);
    if (pageID) {
      // user is selecting a previously made page
      // this.gridHeight = 400;
      // this.options.minRows = 6;
      let cont = [
        {
          id: pageID,
          isPage: true,
          isExternal: false,
          isItem: false,
          gridPosition: {},
          breadcrumbs: [],
        },
      ]; // breadcrumbs will change on where page is being added to
    }
    // else{ // user is creating a new page
    //   // create a new page and get it's ID
    //   // set below and then add to template, etc
    //   let cont = [{id: pageID, isPage: true, isExternal: false, isItem: false, gridPosition: {}, breadcrumbs: []}] // breadcrumbs will change on where page is being added to
    // }
  }

  /* Template Methods */
  verifyUserAccessToTemplate(id, isCreator) {
    // revise this later (for security perms for multiple users in company)
    return this.afs
      .collection('bookingTemplates')
      .doc(id)
      .ref.get()
      .then(async (res) => {
        let data = res.data();
        console.log(data);
        if (isCreator) {
          if (data === undefined) {
            // Catch - templateID is not found in database
            return {
              userHasAccessToTemplate: false,
              message:
                'This template either no longer exists or is corrupted. Redirecting...',
            };
          }

          // Template exists but has been "deleted" - set to inactive
          if (data['isActive'] === false) {
            return {
              userHasAccessToTemplate: false,
              message:
                'This link has been disabled. If you believe this to be a mistake please contact Fleetmaid support.',
            };
          }

          if (data['editState']['isEditing'] == true) {
            // checks to see if template is currently being edited before catch

            return {
              userHasAccessToTemplate: false,
              message:
                'This template is currently being edited. Only one person may edit a template at a time. Redirecting...',
            };
          }

          let name =
            this.currentUserService.currentUser.firstName +
            ' ' +
            this.currentUserService.currentUser.lastName;

          if (
            this.currentUserService.currentUser.companyId == data['companyID']
          ) {
            // Catch - correct company & logged in user
            return { userHasAccessToTemplate: true, name: name };
          }

          // Catch - User does not have access to this page
          return {
            userHasAccessToTemplate: false,
            message: 'You do not have access to view this page. Redirecting...',
          };
        } else {
          // is customer

          // 404 / no matching template IDs found in db
          if (data === undefined) {
            return {
              userHasAccessToTemplate: false,
              message:
                'Page not found. If this is a mistake, please contact Fleetmaid support.',
            };
          }

          // Template exists but has been "deleted" - set to inactive
          if (data['isActive'] === false) {
            return {
              userHasAccessToTemplate: false,
              message:
                'This link has been disabled. If you believe this to be a mistake please contact Fleetmaid support.',
            };
          }

          return { userHasAccessToTemplate: true };
        }
      });
  }

  getAllTemplatesForCompany(): Observable<any> {
    // subscribe to bookingTemplate collection updates - by companyID
    return this.afs
      .collection('bookingTemplates', (ref) =>
        ref
          .where(
            'companyID',
            '==',
            this.currentUserService.currentUser.companyId
          )
          .where('isActive', '==', true)
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((action) => {
            let data = action.payload.doc.data();
            data['id'] = action.payload.doc.id;
            return data;
          });
        })
      );
  }

  getTemplatesForCompany() {
    return this.afs
      .collection('bookingTemplates', ref =>
        ref
          .where('companyID', '==', this.currentUserService.currentUser.companyId)
          .where('isActive', '==', true)
      )
      .get()
      .toPromise()
      .then(snapshot =>
        snapshot.docs.map(doc => {
          let data = doc.data();
          data['id'] = doc.id;
          return data;
        })
      );
  }

  getAllTemplatesForCompanyOnce() {
    return this.afs
      .collection('bookingTemplates')
      .ref.where(
        'companyID',
        '==',
        this.currentUserService.currentUser.companyId
      )
      .where('isActive', '==', true)
      .get()
      .then((docs) => {
        console.log(docs)
        console.log(docs.docs)
        return docs.docs;
        // let doc = docs.docs[0].data()
        // doc['id'] = docs.docs[0].id
        // return doc
      });
  }

  async createTemplate(templateTitle) {
    // creates a new template document & returns it's ID
    this.template.title = templateTitle; // sets the title

    // Get companyID of logged in user -> get their companyData & companyLogoInfo
    this.template.companyID = await this.currentUserService.currentUser
      .companyId;
    let companyData = await this.currentUserService.getCompanyInfoByID(
      this.currentUserService.currentUser.companyId
    );
    let companyLogoInfo = await this.imageLibraryService.getCompanyLogo();

    // Set
    this.template.companyName = companyData['companyName'];
    this.template.companyLogo = companyLogoInfo['url'];

    this.afs.collection('bookingTemplates').add(this.template);
  }

  async createPage(page) {
    // creates a new page & returns it's ID
    Object.keys(page).forEach((key) => {
      console.log(page[key]);
      this.pages[key] = page[key];
    });
    console.log('test');
    console.log(this.pages);
    this.pages.companyID = await this.currentUserService.currentUser.companyId;
    this.afs.collection('bookingPages').add(this.pages);
  }

  async updatePage(id, page) {
    // update a page
    this.afs.collection('bookingPages').doc(id).update(page); // updates page but also needs to update all affected templates
    // { subHeading: $event.subHeading, title: $event.title, img: $event.img
  }

  async updateTemplatesAffectedPages(templates, listOfPageNameChanges) { }

  async updatePageNamesOnAffectedTemplates(templates, listOfPageNameChanges) {
    console.log(templates);
    console.log(listOfPageNameChanges);

    let batch = this.afs.firestore.batch();

    listOfPageNameChanges.forEach((page) => {
      // for every page that had a name updated
      templates.forEach((template) => {
        // go through every template in db for company
        template.content.forEach((cont) => {
          // check if that page is in their content array
          if (cont.id === page.id) {
            // if a match is found
            cont.title = page.title; // replace old title with new title
            batch.update(
              this.afs.collection('bookingTemplates').doc(template.id).ref,
              { content: template.content }
            ); // replace old content arr with new
          }
        });
      });
    });

    await batch.commit();
    return;
  }

  async deleteBreadcrumbsFromTemplate(template, contentID) {
    // extra variables keeping track for removing from content
    let info = [];

    let bcIndexes = [];
    let numIndexes = 0; // this value represents the index in every pages breadcrumb that should == to the selected pageID to be deleted
    // if doesn't match, than that page shouldn't be deleted.

    // Example: If a template has a total of 3 pages. 1 page on the template & two children pages.
    // The pageID of the parent page (the one being deleted) should be found in the next pages breadcrumb no matter how many children they have

    template.breadcrumbs.forEach((breadcrumb, index) => {
      // loop through all breadcrumbs from template db
      if (breadcrumb.pageID == contentID) {
        // find where pageID is == to the pageID we're deleting
        bcIndexes.push(index); // push the index to thebcIndexes array to splice / remove later
        numIndexes = breadcrumb.breadcrumbs.length; // Tells us where the pageID of the item being deleted will appear in all other breadcrumb lists
        return;
      }
    });

    template.breadcrumbs.forEach((breadcrumb, index) => {
      // loop through all breadcrumbs
      if (breadcrumb.breadcrumbs[numIndexes] == contentID) {
        // go into each breadcrumbs bc list & see if the index at @Var numIndexes matches the pageID being deleted
        info.push(breadcrumb.pageID); // for deleting from template content
        bcIndexes.push(index);
      }
    });

    bcIndexes.reverse(); // reverse the already min -> max list of indexes
    console.log(bcIndexes);

    bcIndexes.forEach((index) => {
      // reversing the list allows us to go through the whole list & delete multiple items without index shifting
      template.breadcrumbs.splice(index, 1);
    });

    return { template, info };
  }

  async delete(id, datesInactivated, collection) {
    // delete a page
    // as of right now only deleting from collections "bookingTemplates" & "bookingPages"

    if (collection === 'bookingPages') {
      // double check if pages are connected to other templates before deleting
      // return
    }

    datesInactivated.push(Date.now());
    // this.afs.collection(collection).doc(id).delete();
    this.afs
      .collection(collection)
      .doc(id)
      .update({ isActive: false, datesInactivated: datesInactivated });
  }

  // get all updates to this template ID
  getTemplateByID(id): Observable<any> {
    // subscribe to bookingTemplate collection updates - by companyID
    console.log(id);

    this.afs
      .collection('bookingTemplates')
      .doc(id)
      .ref.get()
      .then((res) => {
        console.log(res.data());
      });

    return this.afs
      .collection<any>('bookingTemplates')
      .doc(id)
      .snapshotChanges();
  }

  updateTemplate(id, update) {
    this.afs.collection('bookingTemplates').doc(id).update(update);
    // this.afs.collection('bookingTemplates').doc("id").update(update)
  }

  async saveBookingFlow(
    templateID,
    template,
    listOfPagesToUpdate,
    listOfTempPagesToUpdate
  ) {
    // limit of 500 writes...

    let batch = this.afs.firestore.batch();

    // Update bookingTemplate once
    console.log(template);
    console.log(templateID);
    batch.update(
      this.afs.collection('bookingTemplates').doc(templateID).ref,
      template
    );
    console.log('bookingTemplates r fine');

    // Update pre-existing pages
    listOfPagesToUpdate.forEach((page) => {
      batch.update(
        this.afs.collection('bookingPages').doc(page.id).ref,
        page.data
      );
    });
    console.log('bookingPages r fine');

    console.log(listOfPagesToUpdate);

    // Update pages that are new / need to be created
    // return

    await batch.commit();

    return;

    // Save template

    // Save any pages that may have been changed

    // Add all newly created pages to db
  }

  // Booking Suite specific

  updateTitletemp(id: string, title: any) {
    if (title !== undefined) {
      return this.afs
        .collection('bookingTemplates')
        .doc(id)
        .update({ title: title });
    } else {
      return Promise.reject('error');
    }
  }

  updateTemplateFields(templateID: string, templateFieldUpdates: {}): Promise<any>{
    return this.afs.collection('bookingTemplates').doc(templateID).update(templateFieldUpdates);
  }

}
