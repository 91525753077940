import { EventEmitter, Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import Swal from 'sweetalert2';
import { Company } from 'src/app/models/storage/company.model';
import { Rental } from 'src/app/models/storage/rental.model';
import { RentalService } from './rental.service';
import { CurrentUserService } from './current-user.service';
import { Cart } from 'src/app/models/storage/cart.model';
@Injectable({
    providedIn: 'root'
})
export class PaymentsService {
    //Events Emitters
    public onCompletePayment: EventEmitter<any> = new EventEmitter();
    public onSecurityPayment: EventEmitter<any> = new EventEmitter();
    public onNormalCharge: EventEmitter<any> = new EventEmitter();

    //Objects variables
    private company: Company
    private rental: Rental

    //dataPI
    private dataPI: any
    //Taxes
    private fleetTax: number
    private companyTax: number
    constructor(
        private afFun: AngularFireFunctions,
        private _rentalService: RentalService,
        private _currentUser: CurrentUserService
    ) {

    }
    getDataPI(rental) {
        this._rentalService.getCompanyInfo().then(async (data) => {
            this.company = data;
            this.fleetTax = data.fleetTax;
            this.companyTax = data.companyTax;
            this.dataPI = {
                stripeID: data.isTesting ? data.stripeIDTest : data.stripeID,
                paymentID: rental.paymentID,
                isTesting: this._rentalService.isTesting,
            };
        });
    }

    reservationIsPaid(rental: Rental): boolean {
        if (rental.statusDate) {
            if (rental.statusDate.reservationPayment != undefined && rental.statusDate.reservationPayment != null) {
                return true;
            }
        }
        return false
    }

    //Function to Complete Payment
    completePayment(company?, rental?) {
        this.company = company;
        this.rental = rental;

        this.fleetTax = company.fleetTax;
        this.companyTax = company.companyTax;
        //Get taxes & fees
        const totalFees = this.fleetTax + this.companyTax;
        const total = + (rental.amountPending * (1 + totalFees / 100)).toFixed(2);
        const fleetTaxes = + Number(rental.amountPending * (this.fleetTax / 100)).toFixed(2);
        const companyTaxes =+ Number(rental.amountPending * (this.companyTax / 100)).toFixed(2);
        Swal.fire({
            title: 'Complete Payment',
            html: `The pending amount is: <b>${rental.amountPending.toFixed(2)}</b><br>
                   The total with fees is: <b>${total.toFixed(2)}</b><br>
                   Do you want to create a charge with this amount?`,
            icon: 'question',
            showCancelButton: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    Swal.showLoading();
                    //Get the main payment
                    const payment = await this.retrievePayment();
                    //Build an obj to charge
                    const chargeData = this.buildChargeData(payment, total, fleetTaxes, companyTaxes);
                    //Function to create the charge
                    const chargeResponse = await this.createChargeRequest(chargeData);
                    //Function to show the errors or save information on firebase
                    this.handleChargeResponse(chargeResponse, 0);
                } catch (error) {
                    //Call the function to show errors
                    this.handleError(error);
                }
            }
        });
    }
    //Get Payment from Stripe
    async retrievePayment() {
        const dataPI = {
            stripeID: this.company.stripeID,
            isTesting: this.company.isTesting,
            paymentID: this.rental.paymentID
        };
        return await this.afFun.httpsCallable('retrievePayment')(dataPI).toPromise();
    }
    //Create Obj for the charge
    buildChargeData(payment, total: number, fleetTaxes: number, companyTaxes: number) {
        return {
            title: 'Payment Pending Complete',
            customer: payment.customer,
            id: payment.id,
            cost: total.toFixed(2),
            amount: fleetTaxes.toFixed(2),
            companyTaxes: companyTaxes || 0,
            fleetTaxes: fleetTaxes || 0,
            fleetTax: (this.fleetTax / 100),
            companyTax: (this.companyTax / 100),
            paymentMethod: payment.payment_method,
            destination: this.company.stripeID,
            companyID: this.rental.companyID,
            amountDestination: this.rental.amountPending,
            transactiondata: payment.metadata,
            isDeposit: false
        };
    }
    //Creating charge on Stripe
    async createChargeRequest(chargeData) {
        return await this.afFun.httpsCallable('createCharge')({
            data: chargeData,
            isTesting: this._rentalService.isTesting,
        }).toPromise();
    }

    //Function to create Security Charge
    securityCharge(total: number, fleetTaxes: number, companyTaxes: number) {
        Swal.fire({
            title: 'Creating Charge',
            html: 'This alert will close when the charge has been created.',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
            },
            willClose: () => { },
        });

        this.afFun.httpsCallable('retrievePayment')(this.dataPI).subscribe(async (payment) => {
            const data = {
                title: 'Security Deposit',
                customer: payment.customer,
                id: payment.id,
                cost: total.toFixed(2),
                amount: fleetTaxes.toFixed(2),
                companyTaxes: companyTaxes || 0,
                fleetTaxes: fleetTaxes || 0,
                fleetTax: (this.fleetTax / 100),
                companyTax: (this.companyTax / 100),
                paymentMethod: payment.payment_method,
                destination: this.dataPI.stripeID,
                companyID: this.rental.companyID,
                amountDestination: total,
                transactiondata: payment.metadata,
                isDeposit: true,
            };

            try {
                const depositChargeResponse = await this.createDepositCharge(data);
                this.handleDepositChargeResponse(depositChargeResponse);
            } catch (error) {
                this.handleError(error);
            }
        });
    }
    //Create Deposit on Stripe
    async createDepositCharge(data) {
        return await this.afFun.httpsCallable('createCharge')({
            data: data,
            isTesting: this._rentalService.isTesting,
            type: 'deposit',
        }).toPromise();
    }

    //Function to create Normal Charge
    normalCharge(description, totalfees, totalfeesfleet, fleetTaxNumber, total) {
        console.log("description: ", description)
        console.log("totalfees: ", totalfees)
        console.log("totalfeesfleet: ", totalfeesfleet)
        console.log("fleetTaxNumber: ", fleetTaxNumber)
        console.log("total: ", total)
        Swal.fire({
            title: 'Creating Charge',
            html: 'This alert will close when the charge has been created.',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
            },
            willClose: () => { },
        });

        this.afFun.httpsCallable('retrievePayment')(this.dataPI).subscribe(async (payment) => {
            const title = description;
            const data = {
                title: title,
                customer: payment.customer,
                id: payment.id,
                cost: totalfees.toFixed(2),
                amount: totalfeesfleet.toFixed(2),
                fleetTax: this.fleetTax,
                fleetTaxNumber: fleetTaxNumber,
                paymentMethod: payment.payment_method,
                destination: this.dataPI.stripeID,
                companyID: this.rental.companyID,
                amountDestination: total,
                transactiondata: payment.metadata,
                isDeposit: false,
            };
            console.log(data)
            /*try {
                const chargeResponse = await this.createNormalCharge(data);
                this.handleNormalChargeResponse(chargeResponse, data);
            } catch (error) {
                this.handleError(error);
            }*/
        });
    }

    //Function to add charge by popup

    //Create Charge on Stripe
    async createNormalCharge(data) {
        return await this.afFun.httpsCallable('createCharge')({
            data: data,
            isTesting: this._rentalService.isTesting,
        }).toPromise();
    }

    /*Handle Events and Erros Functions*/

    /*Update Rental by Emitter*/
    updateRentalSecurityPayment(depositChargeResponse) {
        this.onSecurityPayment.emit({
            rental: this.rental,
            depositChargeResponse: depositChargeResponse,
            message: `Deposit created, amount: $${Number((depositChargeResponse.amount/100).toFixed(2))}`
        })
    }
    updateRentalAndNotifyUser(chargeResponse, chargeamount) {
        console.log(chargeResponse)
        this.onCompletePayment.emit({
            rental: this.rental,
            chargeamount: chargeamount,
            chargeResponse: chargeResponse,
            message: `Charge created, amount: $${Number((chargeResponse.amount/100).toFixed(2))}`
        });
    }
    updateRentalNormalCharge(chargeResponse, data) {
        console.log(chargeResponse)
        this.onNormalCharge.emit({
            rental: this.rental,
            chargeResponse: chargeResponse,
            data: data,
            message: `Charge created, amount: $${Number((chargeResponse.amount/100).toFixed(2))}`
        })
    }
    //Handle Deposits Errors
    handleDepositChargeResponse(depositChargeResponse) {
        if (depositChargeResponse.code) {
            let error = '';
            depositChargeResponse.code == 'parameter_invalid_empty'
                ? (error = 'There is an invalid parameter in the generated payment.')
                : (error = depositChargeResponse.raw.message);
            Swal.fire({
                title: 'Error',
                html: `There was a problem processing the selected payment. <br> Reason: <b>"${error}"</b>`,
                icon: 'error',
            });
        } else {
            this.updateRentalSecurityPayment(depositChargeResponse);
        }
    }
    handleNormalChargeResponse(chargeResponse, data) {
        if (chargeResponse.code) {
            let error = '';
            chargeResponse.code == 'parameter_invalid_empty'
                ? (error = 'There is an invalid parameter in the generated payment.')
                : (error = chargeResponse.raw.message);
            Swal.fire({
                title: 'Error',
                html: `There was a problem processing the selected payment. <br> Reason: <b>"${error}"</b>`,
                icon: 'error',
            });
        } else {
            this.updateRentalNormalCharge(chargeResponse, data);
        }
    }
    //Handle Charge Errors
    handleChargeResponse(chargeResponse, chargeamount) {
        if (chargeResponse.code) {
            let error = '';
            chargeResponse.code == 'parameter_invalid_empty' ? (error = 'There is an invalid parameter in the generated payment.') : (error = chargeResponse.raw.message);
            Swal.fire({
                title: 'Error',
                html: `There was a problem processing the selected payment. <br> Reason: <b>"${error}"</b>`,
                icon: 'error',
            });
        } else {
            //Add information to firebase
            this.updateRentalAndNotifyUser(chargeResponse, chargeamount);
        }
    }
    handleError(error) {
        Swal.fire({
            title: 'Error',
            text: `An error occurred: ${error.message}`,
            icon: 'error'
        });
    }
}
