import { Component, Input, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

/* Models */
import { BookingPage } from 'src/app/models/storage/booking-page.model';
import { Collection } from 'src/app/v2/models/collection-reference.model';

/* Services */
import { ImageLibraryService } from 'src/app/services/image-library.service';
import { FirestoreService } from 'src/app/v2/services/firestore.service';
import { AlertService } from 'src/app/v2/services/alert.service';

@Component({
  selector: 'app-page-form',
  templateUrl: './page-form.component.html',
  styleUrls: ['./page-form.component.scss']
})

export class PageFormComponent implements OnInit, OnDestroy {
  protected pageForm: FormGroup;
  protected croppedImage: string = "";
  protected hasImgGalleryBtnBeenClicked: boolean = false;
  private subs: Subscription = new Subscription(); // group of subscriptions
  private pageID: string = "";
  @Input() public isEditing: boolean = false;
  @Input() public companyID: string | null = null;
  @Output() private outputUpdatedPageDoc: EventEmitter<BookingPage> = new EventEmitter<BookingPage>;
  @Output() private outputNewlyCreatedPageDoc: EventEmitter<BookingPage> = new EventEmitter<BookingPage>;

  constructor(private imageLibraryService: ImageLibraryService, private firestoreService: FirestoreService, private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.initForm();

    // Handles getting imgData from the image-library component using the imageLib service (when the user selects an img)
    this.subs.add(this.imageLibraryService.selectedImg.subscribe((imgInfo) => { // subscription is used for unsubscribing hot observable
      this.croppedImage = imgInfo.url; // set croppedImg to selected img
      this.closeImgGallery() // close imgGallery bootstrap modal
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async onSubmit() {
    const title = this.pageForm.get('title')?.value,
      subHeading = this.pageForm.get('subHeading')?.value,
      img = this.croppedImage;

    try {
      if (this.isEditing) {
        const updatedDoc = await this.firestoreService.updateDocument<BookingPage>(Collection.BookingPages, this.pageID, { title: title, subHeading: subHeading, img: img }, true);
        this.outputUpdatedPageDoc.emit(updatedDoc);
        this.isEditing = false; // reset
      }
      // Creating a new page
      else {
        if (!this.companyID) {
          throw new Error("No companyID was found.");
        }

        const newlyMadeDoc = await this.firestoreService.createDocument<BookingPage>(Collection.BookingPages, { title: title, subHeading: subHeading, img: img, isActive: true, dateCreated: Date.now(), content: [], companyID: this.companyID });
        console.log(newlyMadeDoc)
        this.outputNewlyCreatedPageDoc.emit(newlyMadeDoc);
      }
    }
    catch (error) {
      console.log(error)
      this.alertService.fireSweetAlert("An error occurred. Please try again later.", "", "error", false, false, error);
    }

  }

  setFormWithPageValues(page) { // used by custom flow - creator & bookingsuite editor
    this.isEditing = true;
    this.croppedImage = page.img;
    this.pageID = page.id;
    this.pageForm.controls['title'].setValue(page.title);
    this.pageForm.controls['subHeading'].setValue(page.subHeading);
  }

  resetForm() {
    this.pageForm.reset();
    this.hasImgGalleryBtnBeenClicked = false;
    this.croppedImage = ""; // reset croppedImg to blank
  }

  /* Adding new content that is not required must be checked to not be a key in the booking pipe to prevent it from breaking if null */
  private initForm(): void {
    this.croppedImage = ""; // reset croppedImg to blank
    this.pageForm = new FormGroup({
      'title': new FormControl("", [Validators.required]),
      'subHeading': new FormControl("", []),
    })
  }

  get title() { return this.pageForm.get('title'); }
  get subHeading() { return this.pageForm.get('subHeading'); }

  openImgGallery() {
    $('#imgGallery').modal('show');
    setTimeout(() => { // prevents error message from showing before modal is opened
      this.hasImgGalleryBtnBeenClicked = true;
    }, 1000)
  }

  closeImgGallery() {
    $('#imgGallery').modal('hide');
  }
}
