export interface ErrorHandlingObject {
    data?: any; // Any extra information you might need for debugging or action
    errors: AppError[]; // Supports multiple errors
    isValid: boolean; // Indicates if the operation is valid or not
}

export interface AppError {
    code: string;
    level: ErrorLevel;
    message: string;
    override: boolean;
    timestamp?: Date;
}

export enum ErrorLevel {
    CRITICAL = 'critical',
    ERROR = 'error',
    INFO = 'info',
    WARNING = 'warning',
}

export enum ErrorCodes {
    /* Global */
    INVALID_INPUT = 'INVALID_INPUT',
    MISSING_FIELDS = 'MISSING_FIELDS',
    NETWORK_FAILURE = 'NETWORK_FAILURE',
    SERVER_ERROR = 'SERVER_ERROR',
    SUCCESS = 'SUCCESS',
    UNAUTHORIZED_ACCESS = 'UNAUTHORIZED_ACCESS',

    /* Date Range */
    DATES_PASSED = 'DATES_PASSED',
    DATES_PASSED_RANGE_IN_CART = 'DATES_PASSED_RANGE_IN_CART',
    DATES_UNAVAILABLE = 'DATES_UNAVAILABLE',
    INVALID_DATE_RANGE = 'INVALID_DATE_RANGE',
    INVALID_LOCATION = 'INVALID_LOCATION',
    NO_TIMESLOTS_AVAILABLE = 'NO_TIMESLOTS_AVAILABLE',
    NO_COMPANY_LOCATIONS = 'NO_COMPANY_LOCATIONS'
}