import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import Swal from 'sweetalert2';
import { CartService } from 'src/app/services/cart.service';
import jsPDF from 'jspdf';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { RentalService } from 'src/app/services/rental.service';
import { ProductsService } from 'src/app/services/products.service';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import * as QRious from 'qrious';
import { Subscription } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ReceiptComponent } from '../../receipt/receipt.component';
import { environment } from 'src/environments/environment';
import { CookieInteractionService } from 'src/app/v2/services/cookie-interaction.service';
@Component({
  selector: 'app-checkout-success',
  templateUrl: './checkout-success.component.html',
  styleUrls: ['./checkout-success.component.scss'],
})
export class CheckoutSuccessComponent implements OnDestroy {
  @Input() cartObj; // Input to receive cart data
  @Input() pmtLinkRentalDoc;
  @Input() templateID;
  @Output() goBackToCart = new EventEmitter<any>(); // Output event emitter to go back if something is missing
  @Output() goBackToCustomerInfo = new EventEmitter<any>(); // Output event emitter to go back to custmer info if something is missing
  @Output() goBackToPaymentIntent = new EventEmitter<any>(); // Output event emitter to go back to custmer info if something is missing
  @Output() getCollectionUpdates = new EventEmitter<any>();
  @Output() provideNewCart = new EventEmitter();
  @ViewChild(ReceiptComponent) receiptComponent: ReceiptComponent;

  @Input() locationMap;
  dataSort;
  receiptRental
  totalChargeAmount
  receiptRentalReady


  private cartSubscription: Subscription; // Subscription for cart data updates

  constructor(
    private cartService: CartService,
    private rentalService: RentalService,
    private router: Router,
    private cookieInteraction: CookieInteractionService,
    private productService: ProductsService,
    private http: HttpClient,
    private _afFun: AngularFireFunctions
  ) {}

  public qr;
  public cartObject: any;
  public rentalObject: any;
  public doc;
  public isReady = false;
  public dayDiff = 1;

  private rentalSubscription: Subscription | undefined;


  ngOnDestroy(): void {
    if (this.rentalSubscription) {
      this.rentalSubscription.unsubscribe();
    }
  }

  async ngOnInit() {
    localStorage.removeItem(`FM-cart-uuid-${this.templateID}`);

    this.isReady = false; //Variable to show the receipt table
    let rentalID
    if(this.pmtLinkRentalDoc) {
      rentalID = this.pmtLinkRentalDoc.id;
    } else {
      rentalID = this.cartObj.rentalID;
    }
    if (rentalID) {
      this.rentalService
        .getRentalByID(rentalID)
        .subscribe(async (rental) => {
          console.log('Rental', rental);
            // localStorage.clear();
            localStorage.removeItem(`FM-cart-uuid-${this.templateID}`); //hide this if you are doing tests
            const possiblePaymentLinkCookie = this.cookieInteraction.getPaymentLinkCookie(this.templateID);
            this.cookieInteraction.removeCookie(possiblePaymentLinkCookie);
            this.getCollectionUpdates.emit();
            this.provideNewCart.emit();
            //Getting the rental object
            this.rentalObject = this.filterAddons(rental);
            const company = await this.rentalService.getCompanyByID(
              rental.companyID
            ); //Getting the company object
            this.productLocation = await this.rentalService.getDefaultLocation(
              company.defaultLocation
            ); //Getting the locations
            const data = {
              rental: this.filterAddons(rental),
              rentalID: rental.id,
              changeslogs: [],
              charges: [],
              refunds: [],
              deposit: [],
              companyID: rental.companyID,
              totalChargeAmount: 0,
              totalRefund: 0,
              totalDeposit: 0,
              emailTo: rental.userInfo.email,
              company,
              productLocation: this.productLocation,
            };
            if (this.receiptComponent) {
              const processReceipt = (rental, company) => {
                return new Promise<void>((resolve) => {
                  this.receiptRental = this.filterAddons(rental);
                  this.receiptRentalReady = this.filterAddons(rental);
                  this.totalChargeAmount = 0;
                  this.company = company;

                  resolve(); // resolve promise
                });
              };

              processReceipt(rental, company).then(() => {
                this.showMessage(rental); // Show the final message
              });
            }
        });
    } else {
      this.backToSearch();
    }
  }

  backToSearch() {
    if (!this.rentalObject) {
      return
    }
    //We should clear the localStorage at this point
    let url = this.router.url.split('/'); //get url
    url[url.length - 1] = 'cart'; //add to url+

    this.goBackToCart.emit();
  }
  showMessage(rental) {
    let waiverLink = rental ? `https://${environment.app.appDomainName}/waiver/${rental.id}` : '';
    Swal.fire({
      title:
        $localize`Your rental has been reserved. Please check your email for your receipt.`,
      text: $localize`Please sign the waiver with the link below`,
      html: $localize`<a href="${waiverLink}" target="_blank">Click here to sign the waiver</a>`,
      icon: 'success',
      confirmButtonText: $localize`Continue`,
      allowEnterKey: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      this.qr = new QRious({
        value:
          window.location.origin + '/partner/bookings-detail?id=' + rental.id,
      });
    });
  }
  setupPDF() {
    this.doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'letter',
    });
  }
  public pdfDiscount: number = 0;
  public company: any;
  public productLocation: any;
  outputPDF() {
    setTimeout(() => {
      Swal.close();
      var blob = this.doc.output('bloburl');
      try {
        Swal.close();
        window.open(blob, '_blank');
      } catch (e) {
        Swal.fire({
          title: $localize`Error`,
          text: $localize`Error opening PDF`,
          icon: 'error',
          confirmButtonText: 'Ok',
        });
      }
    }, 100);
    // pdf open
  }
  getRentalPDF() {
    if (!this.rentalObject) {
      return
    }
    Swal.fire({
      title: $localize`Generating receipt...`,
      text: $localize`Please wait...`,
      allowOutsideClick: false,
      allowEnterKey: false,
      allowEscapeKey: false,
    });
    Swal.showLoading();
    this.receiptComponent.openReceipt();
  }
  addToGoogleCalendar() {
    if (!this.rentalObject) {
      return
    }
    //https://calendar.google.com/calendar/r/eventedit?text=My+Custom+Event&dates=20180512T230000Z/20180513T030000Z&details=For+details,+link+here:+https://example.com/tickets-43251101208&location=Garage+Boston+-+20+Linden+Street+-+Allston,+MA+02134
    let promises = [];
    let url;
    let promise = this.rentalService
      .getRentalDoc(this.rentalObject.id)
      .then((rental) => {
        // date will be in the format='Ymd\\THi00\\Z'
        //title will be the rental number with + separator
        let title =
          rental.productsNames[0] + ',+' + this.rentalService.companyName;
        let tempStartToDate = rental.dayStart.toDate();
        let tempStart = new Date(
          new Date(tempStartToDate).getFullYear(),
          new Date(tempStartToDate).getMonth(),
          new Date(tempStartToDate).getDate(),
          new Date(tempStartToDate).getHours() + 6,
          new Date(tempStartToDate).getMinutes(),
          0
        );
        let tempEndToDate = rental.dayEnd.toDate();
        let tempEnd = new Date(
          new Date(tempEndToDate).getFullYear(),
          new Date(tempEndToDate).getMonth(),
          new Date(tempEndToDate).getDate(),
          new Date(tempEndToDate).getHours() + 6,
          new Date(tempEndToDate).getMinutes(),
          0
        );
        let startDate = moment(tempStart).format('YYYYMMDDTHHmmss') + 'Z';
        let endDate = moment(tempEnd).format('YYYYMMDDTHHmmss') + 'Z';
        let calendar = 'https://calendar.google.com/calendar/r/eventedit?';
        let text = 'text=' + title;
        let dates = 'dates=' + startDate + '/' + endDate;

        url = calendar + text + '&' + dates + '&';
      });
    promises.push(promise);

    Promise.all(promises).then(() => {
      window.open(url, '_blank');
    });
  }
  goToWaiver() {
    if (!this.rentalObject) {
      return
    }
    this.router.navigate(['/waiver', this.rentalObject.id]);
  }

  private filterAddons(rental) {
    if (!rental?.products) {
      return rental;
    }
    rental.products.forEach((product) => {
      if (product?.addons && product?.addons.length > 0) {
        product.addons.forEach((addon, index) => {
          if (addon.addonType === 'select') {
            const selectedAddon = addon.optionsSelect.find(
              (option) => option.isSelected === true
            );
            if (selectedAddon) {
              addon.optionsSelect = addon.optionsSelect.filter(
                (option) => option.isSelected
              );
            } else {
              addon.optionsSelect = [
                { description: 'N/S', isSelected: true, price: 0 },
              ];
            }
          }
        });
      }
    });
    return rental;
  }

}
