import { Injectable } from '@angular/core';
import { NameValue } from 'src/app/models/name-value.model';

@Injectable({
  providedIn: 'root'
})
export class DataAddressService {

  usStates: NameValue[] = [

    {name: 'Alabama', value: 'AL'},
    {name: 'Alaska', value: 'AK'},
    {name: 'Arizona', value: 'AZ'},
    {name: 'Arkansas', value: 'AR'},
    {name: 'California', value: 'CA'},
    {name: 'Colorado', value: 'CO'},
    {name: 'Connecticut', value: 'CT'},
    {name: 'Delaware', value: 'DE'},
    {name: 'District of Columbia', value: 'DC'},
    {name: 'Florida', value: 'FL'},
    {name: 'Georgia', value: 'GA'},
    {name: 'Hawaii', value: 'HI'},
    {name: 'Idaho', value: 'ID'},
    {name: 'Illinois', value: 'IL'},
    {name: 'Indiana', value: 'IN'},
    {name: 'Iowa', value: 'IA'},
    {name: 'Kansas', value: 'KS'},
    {name: 'Kentucky', value: 'KY'},
    {name: 'Louisiana', value: 'LA'},
    {name: 'Maine', value: 'ME'},
    {name: 'Maryland', value: 'MD'},
    {name: 'Massachusetts', value: 'MA'},
    {name: 'Michigan', value: 'MI'},
    {name: 'Minnesota', value: 'MN'},
    {name: 'Mississippi', value: 'MS'},
    {name: 'Missouri', value: 'MO'},
    {name: 'Montana', value: 'MT'},
    {name: 'Nebraska', value: 'NE'},
    {name: 'Nevada', value: 'NV'},
    {name: 'New Hampshire', value: 'NH'},
    {name: 'New Jersey', value: 'NJ'},
    {name: 'New Mexico', value: 'NM'},
    {name: 'New York', value: 'NY'},
    {name: 'North Carolina', value: 'NC'},
    {name: 'North Dakota', value: 'ND'},
    {name: 'Ohio', value: 'OH'},
    {name: 'Oklahoma', value: 'OK'},
    {name: 'Oregon', value: 'OR'},
    {name: 'Pennsylvania', value: 'PA'},
    {name: 'Rhode Island', value: 'RI'},
    {name: 'South Carolina', value: 'SC'},
    {name: 'South Dakota', value: 'SD'},
    {name: 'Tennessee', value: 'TN'},
    {name: 'Texas', value: 'TX'},
    {name: 'Utah', value: 'UT'},
    {name: 'Vermont', value: 'VT'},
    {name: 'Virginia', value: 'VA'},
    {name: 'Washington', value: 'WA'},
    {name: 'West Virginia', value: 'WV'},
    {name: 'Wisconsin', value: 'WI'},
    {name: 'Wyoming', value: 'WY'}
  ]

  canadaProvinces: NameValue[] = [
    {name: "Alberta", value: "AB"},
    {name: "British Columbia", value: "BC"},
    {name: "Manitoba", value: "MB"},
    {name: "New Brunswick", value: "NB"},
    {name: "Newfoundland and Labrador", value: "NL"},
    {name: "Northwest Territories", value: "NT"},
    {name: "Nova Scotia", value: "NS"},
    {name: "Nunavut", value: "NU"},
    {name: "Ontario", value: "ON"},
    {name: "Prince Edward Island", value: "PE"},
    {name: "Quebec", value: "QC"},
    {name: "Saskatchewan", value: "SK"},
    {name: "Yukon", value: "YT"}
  ]

  phStates: NameValue[] = [
    { name: "Abra", value: "ABR"},
    { name: "Agusan del Norte", value: "AGN"},
    { name: "Agusan del Sur", value: "AGS"},
    { name: "Aklan", value: "AKL"},
    { name: "Albay", value: "ALB"},
    { name: "Antique", value: "ANT"},
    { name: "Apayao", value: "APA"},
    { name: "Aurora", value: "AUR"},
    { name: "Basilan", value: "BAS"},
    { name: "Bataan", value: "BAN"},
    { name: "Batanes", value: "BTN"},
    { name: "Batangas", value: "BTG"},
    { name: "Benguet", value: "BEN"},
    { name: "Biliran", value: "BIL"},
    { name: "Bohol", value: "BOH"},
    { name: "Bukidnon", value: "BUK"},
    { name: "Bulacan", value: "BUL"},
    { name: "Cagayan", value: "CAG"},
    { name: "Camarines Norte", value: "CAN"},
    { name: "Camarines Sur", value: "CAS"},
    { name: "Camiguin", value: "CAM" },
    { name: "Capiz", value: "CAP" },
    { name: "Catanduanes", value: "CAT"},
    { name: "Cavite", value: "CAV"},
    { name: "Cebu", value: "CEB"},
    { name: "Compostela Valley", value: "COM"},
    { name: "Cotabato", value: "NCO"},
    { name: "Davao del Norte", value: "DAV"},
    { name: "Davao del Sur", value: "DAS"},
    { name: "Davao Occidental", value: "DVO"},
    { name: "Davao Oriental", value: "DAO"},
    { name: "Dinagat Islands", value: "DIN"},
    { name: "Eastern Samar", value: "EAS"},
    { name: "Guimaras", value: "GUI"},
    { name: "Ifugao", value: "IFU"},
    { name: "Ilocos Norte", value: "ILN"},
    { name: "Ilocos Sur", value: "ILS"},
    { name: "Iloilo", value: "ILI"},
    { name: "Isabela", value: "ISA"},
    { name: "Kalinga", value: "KAL"},
    { name: "La Union", value: "LUN"},
    { name: "Laguna", value: "LAG"},
    { name: "Lanao del Norte", value: "LAN"},
    { name: "Lanao del Sur", value: "LAS"},
    { name: "Leyte", value: "LEY"},
    { name: "Maguindanao", value: "MAG"},
    { name: "Marinduque", value: "MAD"},
    { name: "Masbate", value: "MAS"},
    { name: "Misamis Occidental", value: "MSC"},
    { name: "Misamis Oriental", value: "MSR"},
    { name: "Mountain Province", value: "MOU"},
    { name: "Negros Occidental", value: "NEC"},
    { name: "Negros Oriental", value: "NER"},
    { name: "Northern Samar", value: "NSA"},
    { name: "Nueva Ecija", value: "NUE"},
    { name: "Nueva Vizcaya", value: "NUV"},
    { name: "Occidental Mindoro", value: "MDC"},
    { name: "Oriental Mindoro", value: "MDR"},
    { name: "Palawan", value: "PLW"},
    { name: "Pampanga", value: "PAM"},
    { name: "Pangasinan", value: "PAN"},
    { name: "Quezon", value: "QUE"},
    { name: "Quirino", value: "QUI"},
    { name: "Rizal", value: "RIZ"},
    { name: "Romblon", value: "ROM"},
    { name: "Samar", value: "WSA"},
    { name: "Sarangani", value: "SAR"},
    { name: "Siquijor", value: "SIG"},
    { name: "Sorsogon", value: "SOR"},
    { name: "South Cotabato", value: "SCO"},
    { name: "Southern Leyte", value: "SLE"},
    { name: "Sultan Kudarat", value: "SUK"},
    { name: "Sulu", value: "SLU"},
    { name: "Surigao del Norte", value: "SUN"},
    { name: "Surigao del Sur", value: "SUR"},
    { name: "Tarlac", value: "TAR"},
    { name: "Tawi - Tawi", value: "TAW"},
    { name: "Zambales", value: "ZMB"},
    { name: "Zamboanga del Norte", value: "ZAN"},
    { name: "Zamboanga del Sur", value: "ZAS"},
    { name: "Zamboanga Sibugay", value: "ZSI"}
  ]

  auStates: NameValue[] = [
    { name: "New South Wales", value: "NSW"},
    { name: "Queensland", value: "QLD"},
    { name: "South Australia", value: "SA"},
    { name: "Tasmania", value: "TAS"},
    { name: "Victoria", value: "VIC"},
    { name: "Western Australia", value: "WA"}
  ]

  ukStates: NameValue[] = [
    { name: "Aberdeenshire", value: "ABD"},
    { name: "Avon", value: "AVN"},
    { name: "Bedfordshire", value: "BDF" },
    { name: "Berkshire", value: "BRK" },
    { name: "Buckinghamshire", value: "BKM" },
    { name: "Cambridgeshire", value: "CAM" },
    { name: "Cheshire", value: "CHS" },
    { name: "Cleveland", value: "CLV" },
    { name: "Co. Durham", value: "DUR" },
    { name: "Cornwall", value: "CON" },
    { name: "Cumberland", value: "CUL" },
    { name: "Cumbria", value: "CMA" },
    { name: "Derbyshire", value: "DBY" },
    { name: "Devon", value: "DEV" },
    { name: "Dorset", value: "DOR" },
    { name: "East Riding of Yorkshire", value: "ERY" },
    { name: "East Sussex", value: "SXE" },
    { name: "Essex", value: "ESS" },
    { name: "Gloucestershire", value: "GLS" },
    { name: "Greater Manchester", value: "GTM" },
    { name: "Hampshire", value: "HAM" },
    { name: "Hereford and Worcester", value: "HWR" },
    { name: "Herefordshire", value: "HEF" },
    { name: "Hertfordshire", value: "HRT" },
    { name: "Humberside", value: "HUM" },
    { name: "Huntingdonshire", value: "HUN" },
    { name: "Isle of Wight", value: "IOW" },
    { name: "Kent", value: "KEN" },
    { name: "Lancashire", value: "LAN" },
    { name: "Leicestershire", value: "LEI" },
    { name: "Lincolnshire", value: "LIN" },
    { name: "Merseyside", value: "MSY" },
    { name: "Norfolk", value: "NFK" },
    { name: "North Riding of Yorkshire", value: "NRY" },
    { name: "North Yorkshire", value: "NYK" },
    { name: "Northamptonshire", value: "NTH" },
    { name: "Northumberland", value: "NBL" },
    { name: "Nottinghamshire", value: "NTT" },
    { name: "Oxfordshire", value: "OXF" },
    { name: "Rutland", value: "RUT" },
    { name: "Shropshire", value: "SAL" },
    { name: "Somerset", value: "SOM" },
    { name: "South Yorkshire", value: "SYK" },
    { name: "Staffordshire", value: "STS" },
    { name: "Suffolk", value: "SFK" },
    { name: "Surrey", value: "SRY" },
    { name: "Sussex", value: "SSX" },
    { name: "Tyne and Wear", value: "TWR" },
    { name: "Warwickshire", value: "WAR" },
    { name: "West Midlands", value: "WMD" },
    { name: "West Riding of Yorkshire", value: "WRY" },
    { name: "West Sussex", value: "SXW" },
    { name: "West Yorkshire", value: "WYK" },
    { name: "Westmorland", value: "WES" },
    { name: "Wiltshire", value: "WIL" },
    { name: "Worcestershire", value: "WOR" },
    { name: "Yorkshire", value: "YKS" },
    { name: "Liverpool", value: "UK"}

  ]

  mxStates: NameValue[] = [
    { name: "Aguascalientes", value: "AG"},
    { name: "Baja California", value: "BC"},
    { name: "Baja California Sur", value: "BS"},
    { name: "Campeche", value: "CM"},
    { name: "Chiapas", value: "CS"},
    { name: "Chihuahua", value: "CH"},
    { name: "Coahuila", value: "CO"},
    { name: "Colima", value: "CL"},
    { name: "Mexico City", value: "DF"},
    { name: "Durango", value: "DG"},
    { name: "Guanajuato", value: "GT"},
    { name: "Guerrero", value: "GR"},
    { name: "Hidalgo", value: "HG"},
    { name: "Jalisco", value: "JA"},
    { name: "México", value: "EM"},
    { name: "Michoacán", value: "MI"},
    { name: "Morelos", value: "MO"},
    { name: "Nayarit", value: "NA"},
    { name: "Nuevo León", value: "NL"},
    { name: "Oaxaca", value: "OA"},
    { name: "Puebla", value: "PU"},
    { name: "Querétaro", value: "QT"},
    { name: "Quintana Roo", value: "QR"},
    { name: "San Luis Potosí", value: "SL"},
    { name: "Sinaloa", value: "SI"},
    { name: "Sonora", value: "SO"},
    { name: "Tabasco", value: "TB"},
    { name: "Tamaulipas", value: "TM"},
    { name: "Tlaxcala", value: "TL"},
    { name: "Veracruz", value: "VE"},
    { name: "Yucatán", value: "YU"},
    { name: "Zacatecas", value: "ZA"}
  ]

  twState: NameValue[] = [
    { name: "Changhua County", value: "CHA"},
    { name: "Chiayi City", value: "CYI"},
    { name: "Chiayi County", value: "CYQ"},
    { name: "Hsinchu City", value: "HSZ"},
    { name: "Hsinchu County", value: "HSQ"},
    { name: "Hualien County", value: "HUA"},
    { name: "Kaohsiung City", value: "KHH"},
    { name: "Keelung City", value: "KEE"},
    { name: "Kinmen County", value: "KIN"},
    { name: "Lienchiang County", value: "LIE"},
    { name: "Miaoli County", value: "MIA"},
    { name: "Nantou County", value: "NAN"},
    { name: "New Taipei City", value: "NWT"},
    { name: "Penghu County", value: "PEN"},
    { name: "Pingtung County", value: "PIF"},
    { name: "Taichung City", value: "TXG"},
    { name: "Tainan City", value: "TNN"},
    { name: "Taipei City", value: "TPE"},
    { name: "Taitung County", value: "TTT"},
    { name: "Taoyuan City", value: "TAO"},
    { name: "Yilan County", value: "ILA"},
    { name: "Yunlin County", value: "YUN"},

  ]

  countries: NameValue[] = [
    {name: "United States", value: "US"},
    {name: "Canada", value: "CA"},
    {name: "Philippines", value: "PH"}
  ]

  getStatesForCountry(value: string): NameValue[] {
    if (value == "CA" || value == "Canada"){
      return this.canadaProvinces
    } else if (value == 'PH' || value == 'Philippines'){
      return this.phStates
    } else if (value == 'AU' || value == 'Australia') {
      return this.auStates
    } else if (value == 'MX'|| value == "Mexico"){
      return this.mxStates
    } else if (value == 'UK' || value == 'United Kingdom'){
      return this.ukStates
    } else if (value == "TW" || value == "Taiwan"){
      return this.twState
    } else{
      return this.usStates
    }
  }

  constructor() { }
}
