
import { DEVELOPER, RAPIDCYCLING, UTPOWERTOYS, BOATCATEGORY } from './constants';

export const environment = {
  production: false,
  app: {
    apiDomainName: "dev-api.fleetmaid.com",
    appDomainName: "dev.fleetmaid.com",
    contactEmail: "support-dev@fleetmaid.com"
  },
  emulators: {
    functions: false,
    firestore: false,
    storage: false
  },
  firebase: {
    apiKey: "AIzaSyCWYy74L2GkXxxcZxhM6phVWQ8W3Vu35oo",
    authDomain: "fleetmaid-dev-1.firebaseapp.com",
    projectId: "fleetmaid-dev-1",
    storageBucket: "fleetmaid-dev-1.appspot.com",
    messagingSenderId: "683033427181",
    appId: "1:683033427181:web:6259dcdee9003c635aa0e4",
    measurementId: "G-00ETVETZBC"
  },
  google: {
    region: 'us-west4'
  },
  hardCodedFeatures: {
    advenireCompanyIDs: [RAPIDCYCLING, DEVELOPER],
    reservationCompanyIDs: [UTPOWERTOYS, DEVELOPER],
    reservationCategoryIDs: [BOATCATEGORY]
  },
  stripe: {
    defaultAccount: 'acct_1ARjJlB8APgzR4zo',
    key: 'pk_test_51Kehx6GsGs3VcMtUiN5TSyLeUWwh8OhwS3lmEBSkSuMCaLwsaHEHpHreCXGdCd0jeK2kVCq3EkI0pSqPtto724Fy00sjGKhEKK'
  }
};
