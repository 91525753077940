import { Component, Input, OnChanges, OnDestroy, Output, SimpleChanges, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

/* Libraries */
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DateTime } from 'luxon';

/* Services */
import { BookingFlowService } from 'src/app/v2/services/booking-flow.service';

/* Models */
import { Product } from 'src/app/models/storage/product.model';
import { BookingPage } from 'src/app/models/storage/booking-page.model';
import { ProductGroup } from 'src/app/models/storage/product-group.model';
import { BookingTemplate, BookingContent } from 'src/app/v2/models/booking-flow.model';
import { MappedGridstackItem } from 'src/app/v2/models/booking-flow.model';
import { GridStackNode, GridStackOptions } from 'gridstack';
import { nodesCB } from 'gridstack/dist/angular';
import { BookingFlowContentAction, BookingFlowContentActionEvent } from 'src/app/v2/models/booking-flow.model';
import { ProductSizeType } from 'src/app/models/storage/product-size-type.model';

@Component({
  selector: 'app-booking-catalog-view',
  templateUrl: './booking-catalog-view.component.html',
  styleUrls: ['./booking-catalog-view.component.scss']
})

export class BookingCatalogViewComponent implements OnDestroy, OnChanges {
  protected BookingFlowContentAction = BookingFlowContentAction; // Expose enum to template

  protected isGridUpdated: boolean = false; // Used to regenerate the grid component after changes have been made
  @Input() public isCreator: boolean = false;
  @Input() public templateObj: BookingTemplate
  private subs: Subscription = new Subscription(); // group of subscriptions

  /* Gridstack */
  protected gridContent: MappedGridstackItem[] = [];
  protected gridstackConfig!: GridStackOptions;
  private prerequisitesFinished: boolean = false;
  protected gridHeight: number = 0;
  @Input() public isMobile: boolean = true;

  /* Creator/Editor Events */
  @Output() private sendCreatorContentID = new EventEmitter<string>();
  @Output() private sendCreatorCardAction = new EventEmitter<BookingFlowContentActionEvent>();

  /* Other Events */
  @Output() private bpoBreakpointChange = new EventEmitter<boolean>();

  /* Front-facing Booking Flow Specific */
  @Input() public dayStart: DateTime;
  @Input() public dayEnd: DateTime;
  @Input() public locationID: string;
  @Input() public currentContentID: string;

  /* Collection Resources */
  @Input() public productSizeTypeCollectionMap: { [id: string]: ProductSizeType };
  @Input() public bookingPagesCollectionMap: { [id: string]: BookingPage };
  @Input() public productGroupCollectionMap: { [id: string]: ProductGroup };
  @Input() public productsCollectionMap: { [id: string]: Product };


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private bpo: BreakpointObserver,
    private cdr: ChangeDetectorRef,
    private bookingFlowService: BookingFlowService
  ) { }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    // Initalize the gridstack and bpo settings
    if (!this.prerequisitesFinished) {
      this.initializeGridstack();
      this.initializeBreakpointObserver(); // A breakpoint observer is used to override angular-gridster2's overflow - replaces it with height modifications for both mobile & desktop
      this.prerequisitesFinished = true;
    }

    if(changes.isMobile){
      this.resizeGridstack();
    }

    // Upon page / contentID change -> re-process the grid
    if (changes.currentContentID) {
      this.loadCurrentContent();
    }
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private initializeGridstack(): void {
    this.gridstackConfig = {
      margin: 5,
      float: false,
      staticGrid: true,
    }

    if (this.isCreator) {
      this.gridstackConfig.staticGrid = false
      this.gridstackConfig.resizable = { handles: 'all' }
    }
  }

  protected onGridstackItemChange($event: nodesCB): void {
    const updatedContArray = $event.nodes as MappedGridstackItem[]; // Items in the grid are already in MappedGridstackItem format but input expects a different type from the gridstack library
    // Fired when a user attempts to move content in the grid via creator/editor
    // NOTE Must also provide height, in case height has updated due to grid item size changes
    this.sendCreatorCardAction.emit({ type: BookingFlowContentAction.GridItemPositionChange, updatedContArray: updatedContArray});
  }

  protected processCreatorCardAction(action: { type: BookingFlowContentAction, contentID?: string, pageID?: string }): void {
    // Fired when a user attempts to EDIT or REMOVE an item from the grid via creator/editor
    switch (action.type) {
      case BookingFlowContentAction.EditPage: // Edits affect the page document upon each individual page update
        this.sendCreatorCardAction.emit({ type: BookingFlowContentAction.EditPage, pageID: action.pageID });
        break;
      case BookingFlowContentAction.Remove: // Content removal affects the page document upon save
        this.sendCreatorCardAction.emit({ type: BookingFlowContentAction.Remove, contentID: action.contentID })
        break;
    }
  }

  private initializeBreakpointObserver(): void {
    this.subs.add(this.bpo.observe(["(max-width: 589px)"]).subscribe((result: BreakpointState) => {
      const initalState: boolean = this.isMobile;
      result.matches ? this.isMobile = true : this.isMobile = false;
      if (result.matches !== initalState) {
        this.bpoBreakpointChange.emit(this.isMobile);
        this.resizeGridstack();
      }
    }));
  }

  private refreshGridstack(): void {
    // Force a re-render of the grid component
    this.isGridUpdated = false;
    this.cdr.detectChanges(); // Forces angular to finish processing the grid component deconstruction
    this.isGridUpdated = true;
  }

  private appendOriginalWidthAndHeight(item: GridStackNode): {originalW: number, originalH: number} {
    // Returns the original width and height of the item as additional properties (for mobile purposes)
    return {originalW: item.w, originalH: item.h, ...item}
  }

  private resizeGridstack(): void {
      this.gridContent.forEach((gridItem: MappedGridstackItem) => {
      gridItem.w = this.isMobile ? 1 : gridItem.originalW;
      gridItem.h = this.isMobile ? 1 : gridItem.originalH;
      this.gridstackConfig.column = this.isMobile ? 1 : 12;
      this.refreshGridstack();
    })
  }

  private getCurrentContentGridHeight(): number {
    let gridHeight: number = 0;
    /* 1.) Determine title & gridheight / rows */
    // If not defined then no page was found, The parent page height should be set to the data stored on the template
    if (this.currentContentID === "") {
      gridHeight = this.templateObj.gridHeight;
    }
    // If not on the parent page, then loop through the content to find the child page the user is on
    else {
      // Using the contentID from the URL, attempt to find the page the user is currently viewing to obtain it's gridHeight
      const currentContent: BookingContent = this.templateObj.content.find(cont => cont.contentID === this.currentContentID && cont.isPage);
      if (currentContent) {
        gridHeight = currentContent.gridHeight;
      }
    }
    return gridHeight;
  }

  public loadCurrentContent(): void {
    let localDashboard: MappedGridstackItem[]  = [];

    /* 1.) Determine current content's grid height */
    const currentContentGridHeight: number = this.getCurrentContentGridHeight();

    /* 2.) Loop through template content and assign current pages content to gridstack dashboard */
    this.templateObj.content.forEach((cont: BookingContent) => {
      // If the content is not on the page the user is currently viewing, then ignore processing it
      if (this.currentContentID !== cont.onPage) {
        return;
      }
      let generatedContentCard: BookingContent;
      try {
        if (cont.isPage) { /* Processing Page */
          generatedContentCard = this.generateContentCardPage(cont as BookingContent);
        }
        else if (cont.isItem) { /* Processing Item */
          generatedContentCard = this.generateContentCardItem(cont as BookingContent);
        }

        /* Get contents positioning */
        const contentGridPosition = this.getContentGridPosition(cont);

        localDashboard.push({ ...contentGridPosition, contentData: {...generatedContentCard} });

      } catch (err) {
        console.error(`Error loading contentID ${cont.contentID}:`, err);
      }
    });
    this.gridHeight = currentContentGridHeight;
    this.gridContent = localDashboard;
    this.resizeGridstack();
  }

  private getContentGridPosition(cont: BookingContent){
    let positionData = null;

    // Check for existance of gridstack position (if so, use it)
    if (cont.gridstackGridPosition) {
      positionData = cont.gridstackGridPosition;
    }
    // Otherwise, look for existance of legacy gridster positioning so it can be mapped to gridstack format
    else {
      positionData = this.bookingFlowService.translateGridsterToGridstack(cont.gridPosition);
    }

    // Append on additional properties needed for supporting mobile
    return this.appendOriginalWidthAndHeight(positionData);
  }

  private generateContentCardItem(cont: BookingContent): BookingContent{
    let dashboardObj: BookingContent;

      const productGroup = this.productGroupCollectionMap[cont.id];
      dashboardObj = {
        title: productGroup?.groupName ?? '',
        id: cont.id,
        gridHeight: cont.gridHeight,
        isExternal: false,
        isItem: true,
        isPage: false,
        img: productGroup?.groupImage ?? '',
        contentID: cont.contentID,
        productGroupID: cont.id,
        itemUnavailable: cont.itemUnavailable ?? false,
        sizesAvail: cont.sizesAvail ?? [],
        onPage: cont.onPage
      };
      return dashboardObj;
  }

  private generateContentCardPage(cont: BookingContent): BookingContent{
      let dashboardObj: BookingContent;

      dashboardObj = {
        ...JSON.parse(JSON.stringify(this.bookingPagesCollectionMap[cont.id])),
        isPage: true,
        isItem: false,
        isExternal: false,
        contentID: cont.contentID,
        onPage: cont.onPage
      }

      return dashboardObj;
  }

  protected navigateByContentCard(contentData: BookingContent): void {
    if (this.isCreator) {
      this.navigationViaCreator(contentData)
    }
    else { // Creator / Editor flow
      this.navigationViaFrontFacing(contentData);
    }
  }

  protected navigationViaCreator(contentData: BookingContent): void {
    // Nothing should occur on the creation portion as of right now if user clicks on an item
    if (contentData.isItem) {
      return;
    }

    this.sendCreatorContentID.emit(contentData.contentID)
  }

  protected navigationViaFrontFacing(contentData: any): void {
    // Do not allow navigation to occur if item is unavailable
    if (contentData.itemUnavailable) {
      return
    }

    /* Navigating to a page */
    if (contentData.isPage) {
      this.router.navigate(['catalog'], { relativeTo: this.activatedRoute, queryParams: { content: contentData.contentID, location: this.locationID, startDate: this.dayStart.toISODate(), endDate: this.dayEnd.toISODate() } });
    }
    /* Navigating to an item */
    else if (contentData.isItem) {
      this.router.navigate(['catalog', contentData.id], { relativeTo: this.activatedRoute, queryParams: { content: contentData.contentID, location: this.locationID, startDate: this.dayStart.toISODate(), endDate: this.dayEnd.toISODate() } });
    }
  }

  /**
* @description Used when using ngFor to track items in a gridster (prevents screen flicker)
*/
  protected trackingGridster(index, item): string {
    return item['contentData']['contentID'];
  }
}
