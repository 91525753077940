import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertPosition, SweetAlertResult } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor() { }

  public fireSweetAlert(title: string, text: string, icon: SweetAlertIcon, isToast: boolean = false, returnPromise?: boolean, error?: any): Promise<SweetAlertResult | void> {
    let position: SweetAlertPosition = isToast ? 'bottom-right' : 'center';
    let showConfirmButton: boolean = isToast ? false : true;
    let timer: number = isToast ? 3000 : 0;

    const swalPromise = Swal.fire({
      toast: isToast,
      position: position,
      icon: icon,
      titleText: title,
      text: text,
      showConfirmButton: showConfirmButton,
      timer: timer,
      reverseButtons: true,
      timerProgressBar: true
    });

    // Report error
    if(error){
      console.error(error);
    }

    if (returnPromise) {
      return swalPromise;
  } else {
      // Resolve the promise immediately if not handling it
      swalPromise.then(() => {});
      return Promise.resolve();
  }
  }
}
