import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CheckoutService } from 'src/app/services/checkout.service';
import { getTestBed } from '@angular/core/testing';
import { Company } from 'src/app/models/storage/company.model';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { CartService } from 'src/app/services/cart.service';
import { lastValueFrom, Subscription, take } from 'rxjs';
import Swal from 'sweetalert2';
import { PricingService } from 'src/app/services/pricing.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { RentalService } from 'src/app/services/rental.service';
import { LogService } from 'src/app/services/log.service';
import { InventoryPageService } from 'src/app/services/inventory-page.service';
import { environment } from 'src/environments/environment';
import { AffiliateService } from 'src/app/services/affiliate.service';
import { Affiliate } from 'src/app/models/storage/affiliate.model';
import { PaymentsService } from 'src/app/services/payments.service';
import { Rental } from 'src/app/models/storage/rental.model';
import { ReservationService } from 'src/app/v2/services/reservation.service';

declare var Stripe;

@Component({
  selector: 'app-payment-intent',
  templateUrl: './payment-intent.component.html',
  styleUrls: ['./payment-intent.component.scss'],
})
export class PaymentIntentComponent {
  // CART
  @Input() cartObj; // Input to receive cart data
  @Input() template
  @Input() companyObject;
  @Input() productMap;
  @Input() productGroupMap;
  @Input() customerInfo;
  @Input() discountCodeMap;
  @Input() isTestStripe: boolean;
  @Input() isSkipPayment: boolean;
  @Input() pmtLinkRentalDoc: Rental | null; //If we are working with a payment link

  @Output() paymentIDAddedToCart = new EventEmitter<any>(); // Output event emitter to notify when payment has been procceced
  @Output() goBackToCart = new EventEmitter<any>(); // Output event emitter to go back if something is missing
  @Output() goBackToCustomerInfo = new EventEmitter<any>(); // Output event emitter to go back to custmer info if something is missing

  private cartSubscription: Subscription; // Subscription for cart data updates
  private cartData = {}; // Private variable to store cart data
  public stripeID: string = '';
  public stripePublicKey: string = '';
  private stripe = Stripe(environment.stripe.key, {
    stripeAccount: environment.stripe.defaultAccount,
  });
  private elements: any;
  private clientSecret: string;
  private paymentElement: any;
  private paymentIntentID: string;
  protected isReserved: boolean = false;
  protected reservationPayment: boolean = false;
  // UTILS
  public isHidden: boolean = true;
  public totalAmount: number = 0;
  public message: string = '';
  public templateID: string = '';
  public rentalModel = {
    amountPendingAbs: 0,
    deliverInformation: {
      name: '',
      address: '',
    },
    isDeliver: false,
    isWOPayment: null,
    fleetTax: null,
    govermentTax: null,
    companyTax: null,
    companyID: '',
    stripeID: '',
    affiliateID: '',
    versionNumber: '',
    rentalType: '',
    products: [],
    allProductsID: [],
    domain: '',
    productsID: [],
    productsNames: [],
    productsNamesMonths: [],
    timeZone: null,
    isConfirmed: null,
    isTest: null,
    sendEmail: null,
    userID: '',
    userName: '',
    dayStart: null,
    dayEnd: null,
    hourStart: Date,
    hourEnd: Date,
    rentalLocationID: null,
    userInfo: null,
    // status: string,
    created: Date,
    isActive: null,
    isReserved: null,
    isCancelled: false,
    isCheckedIn: false,
    isCheckedOut: false,
    isComplete: false,
    isLate: false,
    isDamaged: false,
    isTuneUp: false,
    isCleaning: false,
    isReady: false,
    statusDate: null,
    damagedNotes: '',
    notes: '',
    subtotal: null,
    taxes: null,
    cost: null,
    isReservation: false,
    amountPaid: null,
    amountPending: null,
    reservationPaid: null,
    reservationPending: null,
    waiver: [],
    paymentID: '',
    waiverSignature: '',
    waiverDate: null,
    discount: null,
    discountID: '',
    discountInfo: null,
    chargesID: [
      {
        chargeID: '',
        cost: null,
        date: Date,
        title: '',
      },
    ],
    refundsID: [
      {
        refundID: '',
        amount: null,
        date: Date,
      },
    ],
    waiversSigned: [],
    shopComments: '',
    // clientCheckedOut?: Date,
    // clientReturned?: Date
  };
  productRental = {
    id: '',
    productID: '',
    productName: '',
    cost: null,
    productDiscount: null,
    rentalCost: null,
    addons: [],
    checkInImgs: [],
    checkOutImgs: [],
    isDamaged: false,
    isTuneUp: false,
    isCleaning: false,
    isMaintenance: false,
    maintenanceReason: '',
    description: '',
    subtotal: null,
    price: null,
    days: null,
    isPrepared: false,
    isCheckedOut: false,
    isCheckedIn: false,
    productNumber: null,
    productSize: '',
    maintenanceSelection: {},
    shopCommets: '',
  };
  addons = {
    id: '',
    companyID: '',
    title: '',
    description: '',
    optionsSelect: [
      // {
      // price: null,
      // option: null,
      // qty: null,
      // description: '',
      // isSelected: null
      // }
    ],
    addonType: '',
    sortOrder: null,
    isRequired: null,
  };
  userInfo = {
    name: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
    address2: '',
    city: '',
    country: '',
    state: '',
    zip: '',
  };
  constructor(
    private cartService: CartService,
    private checkoutService: CheckoutService,
    private $afFun: AngularFireFunctions,
    private activatedRoute: ActivatedRoute,
    private currentUserService: CurrentUserService,
    private pricingService: PricingService,
    private _rentalService: RentalService,
    private logService: LogService,
    private inventoryPageService: InventoryPageService,
    private affiliateService: AffiliateService,
    private _paymentsService: PaymentsService,
    private _reservationService: ReservationService
  ) {
      }

  async ngAfterViewInit() {
    Swal.close();
    let companyId
    if(this.pmtLinkRentalDoc) {
      companyId = this.pmtLinkRentalDoc.companyID
    } else {
      companyId = this.cartObj.companyId;
    }
    await this.currentUserService
      .getCompanyInfoByID(companyId)
      .then(async (data) => {
        this.companyObject = data;
        await this.checkCart(); // Initialize the address form
      });
  }
  public rentalID: string
  private async checkCart() {
    try {
      if (!(this.cartObj.customerInfo || this.cartObj.cartObj.customerInfo)) {
        this.showCustomerInfoWarning();
        return;
      }
      if(this.pmtLinkRentalDoc) {
        if (!this.pmtLinkRentalDoc.id) {
          this.goBackToCart.emit();
          return;
        }

        if(this.isSkipPayment) {
          await this.handleSkipPayment(this.cartObj);
        } else {
          await this.prepareSecurePayment(this.cartObj);
        }
      } else {
        const cartID = await this.getCartID();
        if (!this.isRentalAvailable()) return; // Check if rental is available (to be implemented)
        if (!cartID) {
          this.goBackToCart.emit();
          return;
        }
        const cartData = await this.cartService.getCart(cartID).pipe(take(1)).toPromise();
        if (!cartData.payload.exists) {
          this.showCartWarning();
          return;
        }
        const cart = cartData.payload.data();

        if (this.isSkipPayment) {
          await this.handleSkipPayment(cart);
        } else {
          await this.prepareSecurePayment(cart);
        }
      }


    } catch (error) {
      console.error("Error in checkCart:", error);
    }
  }

  private getCartID(): string {
    if (this.pmtLinkRentalDoc) {
      return this.pmtLinkRentalDoc.id;
    } else {
      this.templateID = this.activatedRoute.snapshot.paramMap.get('templateID'); // get the templateID from the URL
      return localStorage.getItem(`FM-cart-uuid-${this.templateID}`) || '';
    }
  }

  private showCartWarning() {
    Swal.fire({
      title: $localize`Please add at least one item to cart`,
      icon: 'warning',
      text: $localize`Back to cart from checkout if not exist`,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then(() => {
      this.goBackToCart.emit();
    });
  }

  private showCustomerInfoWarning() {
    Swal.fire({
      title: $localize`Please fill your information first`,
      html: $localize`Redirecting to the customer data form`,
    }).then(() => {
      this.goBackToCustomerInfo.emit();
    });
  }

  private async handleSkipPayment(cart) {
    Swal.fire({
      title: $localize`Skip Payment`,
      text: $localize`You choose skip payment as option, the rental will be created if you continue`,
      confirmButtonText: $localize`Continue`,
      cancelButtonText: $localize`Load Payment Form`,
      showCancelButton: true,
      reverseButtons: true,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then(async (response) => {
      if (response.isConfirmed) {
        await this.createRentalWithoutPayment();
      } else {
        await this.prepareSecurePayment(cart);
      }
    });
  }

  private async createRentalWithoutPayment(): Promise<void> {
    Swal.fire({
      title: $localize`Saving rental...`,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading();

    try {
      await this.checkForAffiliateCookie();
      let rental: any;

      if (this.pmtLinkRentalDoc) {
        // If pmtLinkRentalDoc exists, retrieve the existing rental
        rental = await this._rentalService.getRentalByIDPromise(this.pmtLinkRentalDoc.id);
        this.totalAmount = (await this.pricingService.addTaxAndFeesToAmount(this.pmtLinkRentalDoc.cartObj, this.cartObj.amountPending)).finalAmount;
      } else {
        // Convert cart object to rental
        rental = await this.inventoryPageService.convertCartObjToRental(
          this.cartObj,
          this.productGroupMap,
          this.productMap,
          this.discountCodeMap,
          this.rentalModel,
          true //Skipping Payment
        );
        // Set rental amounts and cleanup
        rental.amountPending = rental.subtotal;
        this.totalAmount = rental.cost;
        this.deleteNullProperties(rental);
        rental.paymentID = '';

        // Add rental and get the result
        const result = await this._rentalService.addRental(rental);
        rental.id = result.id;

        // Log actions and add rental ID to cart in parallel
        await Promise.all([
          this.logService.addRentalLog(result.id, 'Rental created'),
          this.logService.addRentalLog(result.id, 'Rental without payment'),
          this.cartService.addRentalIDToCart(result.id, this.cartObj.id)
        ]);
        const rentalWithAddonDisplay = await this.inventoryPageService.createAddonsDisplayArrayByProduct(rental);
        const newLogString = this.inventoryPageService.turnAddonDisplayIntoLogString(rentalWithAddonDisplay);
        await this.logService.addRentalLog(rental.id, newLogString);
      }
      Swal.close();
      setTimeout(() => {
        this.cartObj.rentalID = rental.id;
        this.handleRentalorCartChange(rental);
        this.unsubscribeFromCart();
        this.paymentIDAddedToCart.emit();
      }, 1500);

    } catch (error) {
      console.error('Error creating rental without payment:', error);
      Swal.fire({
        title: $localize`Error`,
        text: $localize`There was an error creating the rental. Please try again later.`,
        icon: 'error',
      });
    }
  }

  private async prepareSecurePayment(cart) {
    Swal.fire({
      title: $localize`Preparing Secure Payment Method <span class='dots'></span>`,
      showConfirmButton: false,
      icon: 'info',
      didOpen: () => {
        const dots = Swal.getPopup().querySelector('.dots');
        dots.classList.add('animate-dots');
      },
      willClose: () => {
        const dots = Swal.getPopup().querySelector('.dots');
        dots.classList.remove('animate-dots');
      },
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading();

    if (this.pmtLinkRentalDoc) {
      this.initializeStripe();
    }else {
      if (!cart.paymentIntent) {
        this.initializeStripe();
      } else {
        await this.checkStatus(cart.paymentIntent.clientSecret, this.cartObj.id);
      }
    }
  }

  public async handleSubmit(e) {
    Swal.fire({
      title: $localize`Processing Payment...`,
      allowEnterKey: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    Swal.showLoading();
    const currentURL = window.location.href;

    // Define customer information to the payment method
    const billingDetails = {
      name: this.cartObj.customerInfo.name,
      email: this.cartObj.customerInfo.email,
      phone: this.cartObj.customerInfo.phone,
      address: {
        city: this.cartObj.customerInfo.city,
        country: this.cartObj.customerInfo.country,
        line1: this.cartObj.customerInfo.address,
        line2: this.cartObj.customerInfo.address2 ? this.cartObj.customerInfo.address2 : "",
        postal_code: this.cartObj.customerInfo.zip,
        state: this.cartObj.customerInfo.state,
      },
    };
    const { error, paymentIntent } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        return_url: currentURL,
        payment_method_data: {
          billing_details: billingDetails,
        }
      },
      redirect: 'if_required',
    });

    if (error) {
      Swal.close();
      Swal.fire({
        title: $localize`Oops...`,
        html: $localize`${error.message}`,
        icon: 'error',
      });
    } else {
      // this.checkStatus(paymentIntent.client_secret);

      this.initCartSubscription();
    }
  }

  private async isRentalAvailable() {
    return true; // Function to check if rental is available (to be implemented)
  }

  /**
   * @description Check for affiliate cookie and update affiliateID in rentalModel
   */
  private async checkForAffiliateCookie() {
    // Check for affiliate cookie
    this.rentalModel.affiliateID = ""; // default
    let affiliateID = null;
    let cookieArray = document.cookie.split(';');
    // Attempt to find an affiliate cookie that matches
    for (let i = 0; i < cookieArray.length; i++) {
      let cookiePair = cookieArray[i].split('=');

      // If an affiliate cookie is found and matches the companyID of the template
      if (`fm.AF.${this.template?.companyID}` === cookiePair[0].trim()) {
        let cookieValue = decodeURIComponent(cookiePair[1]) // Decode the cookie value
        affiliateID = cookieValue;
      }
    }

    if (!affiliateID) {
      return; // if companyID does not exist, return
    }

    // check if the affiliate doc exists
    const affiliateDoc = await lastValueFrom(this.affiliateService.getAffiliateByID(affiliateID));
    if (!affiliateDoc.exists) {
      return; // if affiliateID does not exist, return
    }

    // Confirm that affiliate is active
    let affiliate = affiliateDoc.data() as Affiliate;
    if (affiliate.active && !affiliate.softDeleted) {
      // if affiliateID is active, set affiliateID in rentalModel
      this.rentalModel.affiliateID = affiliateDoc.id;
    }
    return;
  }

  private async initializeStripe() {
    try {
      let cartObj
      const company = await this.checkoutService.getCompanyInfo(this.cartObj.companyId);
      const isTest = environment.production
        ? this.cartObj.isTestStripe ?? company.isTesting
        : true;
      await this.setStripeInfo(isTest, this.cartObj.companyId);
      await this.checkForAffiliateCookie();
      let rental
      let companyTaxes: number = 0
      let fleetTaxes: number = 0
      if(this.pmtLinkRentalDoc) {
        cartObj = {...this.pmtLinkRentalDoc.cartObj, statusDate: this.pmtLinkRentalDoc.statusDate}
      } else {
        cartObj = this.cartObj
      }
      const {
        rentalHasReservation,
        reservationPaid,
        fleetTax,
        companyTax,
        reservationTotalToPay,
        amountPending
      } = await this._reservationService.getIfCartIsReservedAndAmountToPay(cartObj, this.productGroupMap, this.discountCodeMap);
      companyTaxes = companyTax;
      fleetTaxes = fleetTax;
      let amount
      this.isReserved = rentalHasReservation;
      this.reservationPayment = reservationPaid;
      if (this.pmtLinkRentalDoc) { //Payment Link Process
        rental = this.pmtLinkRentalDoc;
        this.cartObj = this.pmtLinkRentalDoc.cartObj;

        amount = await this._reservationService.calculateAmount(
          rentalHasReservation,
          reservationPaid,
          reservationTotalToPay,
          this.pmtLinkRentalDoc.cartObj,
          this.pmtLinkRentalDoc.amountPending
        );

      } else { //Normal Booking Flow
        rental = await this.inventoryPageService.convertCartObjToRental(
          this.cartObj,
          this.productGroupMap,
          this.productMap,
          this.discountCodeMap,
          this.rentalModel
        );

        if (rentalHasReservation) {
          amount = reservationTotalToPay;
          this.cartObj.amountPending = amountPending;
          rental.amountPending = amountPending;
        } else {
          amount = rental.cost; // Actual total
          companyTaxes = rental.companyTax
          fleetTaxes = rental.fleetTax
        }
        const rentalWithAddonDisplay = await this.inventoryPageService.createAddonsDisplayArrayByProduct(rental);
        const newLogString = this.inventoryPageService.turnAddonDisplayIntoLogString(rentalWithAddonDisplay);
        await this.logService.addRentalLog(rental.id, newLogString);

        this.deleteNullProperties(rental);
        await this.cartService.addRentalObjToCart(this.cartObj.id, {
          rentalObj: await this.deleteCartObjFromRentalObj(rental),
        });
      }

      this.totalAmount = amount;

      const data = {
        amount: amount,
        currency: 'usd',
        isTesting: isTest,
        fleetTaxes: fleetTaxes,
        companyTaxes: companyTaxes,
        fleetTax: this.companyObject.fleetTax,
        companyTax: this.companyObject.companyTax,
        companyID: this.cartObj.companyId,
        customerInfo: this.cartObj.customerInfo,
        stripeID: isTest ? this.stripeID : company.stripeID,
        customerID: this.cartObj.customerInfo.customerID || '',
        paymentID: this.cartObj.paymentIntent?.paymentID || '',
        isNewCustomer: this.cartObj.paymentIntent?.isNewCustomer || '',
        isRebuildTest: true,
        isModification: true,
        createRental: !Boolean(this.pmtLinkRentalDoc)
      };
      if(this.pmtLinkRentalDoc) {
        data['rentalID'] = this.pmtLinkRentalDoc.id;
      } else {
        data['cartID'] = this.cartObj.id;
      }
      //This will help us to manage Update and Create Payment function

      let paymentFunction: any;
      if (this.pmtLinkRentalDoc) {
        paymentFunction = this.$afFun.httpsCallable('createPaymentIntenWithCustomer');
      } else {
        paymentFunction = this.cartObj.paymentIntent
          ? this.$afFun.httpsCallable('updatePaymentIntenWithCustomer')
          : this.$afFun.httpsCallable('createPaymentIntenWithCustomer');
      }

      paymentFunction(data).subscribe(async (paymentIntent) => {
        if (!paymentIntent) return;

        const paymentObj = {
          paymentID: paymentIntent.id,
          clientSecret: paymentIntent.client_secret,
          status: paymentIntent.status,
          isNewCustomer: paymentIntent.isNewCustomer,
          created: moment(paymentIntent.created * 1000).toDate(),
          stripeID: isTest ? this.stripeID : company.stripeID,
        };

        if (!this.pmtLinkRentalDoc) {
          this.cartObj.customerInfo.customerID = paymentIntent.customer;
          await this.cartService.updateCart(this.cartObj.id, this.cartObj);
          await this.cartService.replacePaymentIntentInCart(paymentObj, this.cartObj.id);
        }

        this.paymentIntentID = paymentIntent.id;
        this.clientSecret = paymentIntent.client_secret;

        await this.setStripeInfo(isTest, this.cartObj.companyId);
            const elements = this.stripe.elements({
          appearance: { theme: 'stripe' },
          clientSecret: paymentIntent.client_secret,
            });

          this.elements = elements;
          const options = {
        wallets: { applePay: 'auto' },
            fields: {
              billingDetails: {
                name: 'auto',
                phone: 'auto',
                email: 'auto',
                address: {
                  line1: 'auto',
                  line2: 'auto',
                  city: 'auto',
                  state: 'auto',
                  postalCode: 'auto',
                  country: 'auto',
                },
              },
            },
          };

        this.paymentElement = elements.create('payment', options);
        this.paymentElement.mount('#payment-element');
        this.paymentElement.on('ready', () => {
          this.isHidden = false;
        });

        Swal.close();
      });
    } catch (error) {
      console.error('Error initializing Stripe:', error);
      // Handle errors here, e.g., show a notification
    }
  }



  private setStripeInfo(isTest: boolean, companyID: string): Promise<any> {
    //set this._rentalService.stripeID and this._rentalService.stripePublicKey
    let promise = new Promise(async (resolve, reject) => {
      if (isTest) {
        this.stripeID = environment.stripe.defaultAccount;
        this.stripePublicKey = environment.stripe.key;
        this.stripe = Stripe(environment.stripe.key, {
          stripeAccount: environment.stripe.defaultAccount,
        });
        resolve(true);
      } else {
        this.stripePublicKey = await this.checkoutService.getStripePKey(isTest);
        this.stripeID = await this.checkoutService.getStripeID(companyID);
        this.stripe = Stripe(environment.stripe.key, {
          stripeAccount: this.stripeID,
        });
        resolve(false);
      }
    });
    return promise;
  }
  private async checkStatus(clientSecret?: string, cartID?) {
    if (!clientSecret) {
      return;
    }
    await this.setStripeInfo(
      this.cartObj['isTestStripe'],
      this.cartObj.companyId
    );

    const { paymentIntent } = await this.stripe.retrievePaymentIntent(
      clientSecret
    );
    if (cartID) {
      const paymentIntentObj = {
        paymentID: paymentIntent.id,
        clientSecret: clientSecret,
        status: paymentIntent.status,
        isNewCustomer: this.cartObj.paymentIntent
            ? this.cartObj.paymentIntent.isNewCustomer
            : '',
        created: moment(paymentIntent.created * 1000).toDate(),
      };
      this.cartService.replacePaymentIntentInCart(paymentIntentObj, cartID)

    }

    // this.paymentIDAddedToCart.emit();
    switch (paymentIntent.status) {
      case 'succeeded':
        //At this point the cart should be closed?
        this.paymentIDAddedToCart.emit();
        break;
      case 'processing':
        Swal.fire({
          title: $localize`Your payment is processing...`,
          allowEnterKey: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        Swal.showLoading();
        break;
      case 'requires_payment_method':
        await this.initializeStripe();
        break;
      default:
        Swal.close();
        Swal.fire({
          title: $localize`Something went wrong`,
          html: $localize`Try to reload the page`,
          icon: 'error',
        });
        break;
    }
  }

  deleteNullProperties(object: any) {
    Object.keys(object).forEach((key) => {
      if (object[key] && typeof object[key] === 'object') {
        this.deleteNullProperties(object[key]);
      } else if (object[key] === null || object[key] === undefined) {
        delete object[key];
      }
    });
  }

  async deleteCartObjFromRentalObj(rentalObj) {
    if (rentalObj.hasOwnProperty('cartObj')) {
      delete rentalObj.cartObj;
    }
    return rentalObj;
  }

  ngOnDestroy(): void {
    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
  }
  //Function to handle error
  private handleError(action: string, error: any): void {
    const err = new Error(`Error during ${action}: ${error.message || error}`);
      console.error(err);
    Swal.fire({
      title: $localize`Error`,
      text: err.message,
      icon: 'error'
    });
  }
  //Subscribe to Cart or Rentals
  initCartSubscription(): void {
    try {
      Swal.fire({
        title: $localize`Your payment was sent`,
        text: $localize`Once payment is confirmed, you will be redirected to the confirmation page. Please wait.`,
        icon: 'info',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      Swal.showLoading();

      //If is a new rental from booking flow, will be true, if not, is from a payment link and could not have cart
      if (this.pmtLinkRentalDoc) {
        this.subscribeToRental();
      } else {
        this.subscribeToCart();
      }
    } catch (error) {
      this.handleError($localize`initializing cart subscription`, error);
    }
  }
  private subscribeToCart(): void {
    const cartID = this.getCartID();
    this.cartSubscription = this.cartService.getCart(cartID).subscribe({
      next: (data) => {
        try {
          const cart = data.payload.data();
          if (cart.isRentalReady) {
            this.cartObj.rentalID = cart.rentalID;
            this.handleRentalorCartChange(cart);
            this.unsubscribeFromCart();
            this.paymentIDAddedToCart.emit();
          }
        } catch (error) {
          this.handleError($localize`Updating Cart`, error);
        }
      },
      error: (error) => {
        this.handleError($localize`subscribing to cart`, error);
      }
    });
  }

  private subscribeToRental(): void {
    this.cartSubscription = this._rentalService.getRentalByID(this.pmtLinkRentalDoc.id).subscribe({
      next: async (data) => {
        try {
          await this.handleRentalorCartChange(this.cartObj);
          this.unsubscribeFromCart();
          this.paymentIDAddedToCart.emit();
        } catch (error) {
          this.handleError($localize`handling rental`, error);
        }
      },
      error: (error) => {
        this.handleError($localize`subscribing to rental`, error);
      }
    });
  }

  private unsubscribeFromCart(): void {
    if (this.cartSubscription) {
      this.cartData = {}
      this.cartSubscription.unsubscribe();
      this.cartSubscription = undefined;
    }
  }

  private async handleRentalorCartChange(rentalOrCart: any): Promise<void> {
    try {
      if (this.pmtLinkRentalDoc) {
        let newPendingAmount = 0
        if (this.isReserved) {
          if (this.reservationPayment) {
            await this._rentalService.updateAmountPending(this.pmtLinkRentalDoc.id, newPendingAmount);
          } else {
            const { reservationSubtotal: reservationSubtotal } = await this.pricingService.calculateReservationTotal(rentalOrCart);
            newPendingAmount = (this.pmtLinkRentalDoc.amountPending - reservationSubtotal)
            await this._rentalService.addReservationPaymentDate(this.pmtLinkRentalDoc.id);
            await this._rentalService.updateAmountPending(this.pmtLinkRentalDoc.id, newPendingAmount);
          }
        } else {
          await this._rentalService.updateAmountPending(this.pmtLinkRentalDoc.id, newPendingAmount);
        }
      } else {
        await this.logService.addRentalLog(rentalOrCart.id, 'Rental Created');
      }
    } catch (error) {
      this.handleError($localize`Error updating Rental Information`, error);
    }
  }
}
