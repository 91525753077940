import { Injectable } from '@angular/core';
import { CurrentUserService } from './current-user.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { firstValueFrom } from 'rxjs';
import { Company } from 'src/app/models/storage/company.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    private $afs: AngularFirestore,
    private _currentUser: CurrentUserService
  ) {}

  getCurrentCompany(companyID?: string): Promise<Company> {
    return firstValueFrom(
      this.$afs
        .collection('companies')
        .doc<Company>(companyID ? companyID : this._currentUser.currentUser.companyId)
        .valueChanges()
    );
  }
}
