import {Injectable} from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {StorageService} from "./storage.service";
import {CurrentUserService} from "./current-user.service";
import {first, map, switchMap, tap} from "rxjs/operators";
import { Company } from 'src/app/models/storage/company.model';
import {Observable, of} from "rxjs";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import { User } from 'src/app/models/storage/user.model';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(private afs: AngularFirestore,
              private _storageService: StorageService,
              private _currentUserService: CurrentUserService,
              private afAuth: AngularFireAuth,
              private db: AngularFireDatabase) {
    //this.updateOnUser().subscribe();
  //  this.updateOnDisconnect().subscribe();
   // this.updateOnAway();
  }


  getCompanyInfo(idCompany: string) {
    return this.afs.collection('companies').doc(idCompany).snapshotChanges().pipe(map(changes => {
      let data = changes.payload.data() as Company;
      data.id = changes.payload.id
      return data
    }))
  }

  getCompanyInfoS(idCompany: string) {
    return this.afs.collection('companies').doc(idCompany).ref.get().then(doc => {
      const data = doc.data() as Company;
      data.id = doc.id
      return data;
    })

  }

  saveSchedule(idCompany: string, schedule: any) {
    return this.afs.collection('companies').doc(idCompany).ref.update({schedule: schedule})
  }

  saveUnavailableDays(idCompany: string, unavailableDays: any) {
    return this.afs.collection('companies').doc(idCompany).ref.update({unavailableDays: unavailableDays})
  }
  updateCompany(company: any){
    return this.afs.collection('companies').doc(company.id).set(company);
  }
  getAllUsers(companyID: string, isDeveloper: boolean, isAdmin: boolean): Observable<User[]> {
    // get all users where companyID = companyID


    if(companyID=='I3ANuzttq4Qrvld5xuYb'){ //developer
      var collection = this.afs.collection('users', ref => ref.where('isActive', "==", true).where('isDeveloper', "==", true))
    }else if(companyID=='7JQQkZqjwxshPILXaoBf'){ //admin
      var collection = this.afs.collection('users', ref => ref.where('isActive', "==", true).where('isDeveloper', "==", false).where('isAdmin', "==", true))
    }else { //partner

      var collection = this.afs.collection('users', ref => ref.where('isActive', "==", true).where('companyId', '==', companyID).where('isDeveloper', "==", false).where('isAdmin', "==", false))
    }


      return collection.snapshotChanges().pipe(map(changes => {
        return changes.map(a => {
          const data = a.payload.doc.data() as User;
          data.id = a.payload.doc.id;
          return data;
        });
      }))

  }

  getPresence(uid: string) {
    return this.db.object(`status/${uid}`).valueChanges();
  }


  getUser() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }


  async setPresence(status: string) {
    const user = await this.getUser();
    if (user) {
      return this.db.object(`status/${user.uid}`).update({status, timestamp: this.timestamp});
    }
  }

  get timestamp() {
    // get local time
    return new Date().getTime();

  }


  updateOnUser() {
    const connection = this.db.object('.info/connected').valueChanges().pipe(
      map(connected => connected ? 'Online' : 'Offline')
    );

    return this.afAuth.authState.pipe(
      switchMap(user => user ? connection : of('Offline')),
      tap(status => this.setPresence(status))
    );
  }

  updateOnDisconnect() {
    return this.afAuth.authState.pipe(
      tap(user => {
        if (user) {
          this.db.object(`status/${user.uid}`).query.ref.onDisconnect()
            .update({
              status: 'Offline',
              timestamp: this.timestamp
            });
        }
      })
    );
  }

  async signOut() {
    await this.setPresence('Offline');
    await this.afAuth.signOut();
  }

  updateOnAway() {
    document.onvisibilitychange = (e) => {

      if (document.visibilityState === 'hidden') {
        this.setPresence('Away');
      } else {
        this.setPresence('Online');
      }
    };
  }


}
