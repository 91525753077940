import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// Components
import { PageFormComponent } from './page-form/page-form.component';
import { BookingFlowConfigModalComponent } from './bookingFlowConfigModal/bookingFlowConfigModal.component';

// Rxjs
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

// Services
import { BookingService } from 'src/app/services/booking.service';
import { DeleteConfirmationService } from 'src/app/services/delete-confirmation.service';
import { ImageLibraryService } from 'src/app/services/image-library.service';

/* Models */
import { BookingTemplate } from 'src/app/v2/models/booking-flow.model';

@Component({
  selector: 'app-booking-suite',
  templateUrl: './booking-suite.component.html',
  styleUrls: ['./booking-suite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingSuiteComponent implements OnInit, OnDestroy {

  protected templateTitlesWhileEditing: {[id: string]: string} = {}; // used to store the template title while editing

  @ViewChild(PageFormComponent) PageFormComponent: PageFormComponent;
  @ViewChild(BookingFlowConfigModalComponent) BookingFlowConfigModalComponent: BookingFlowConfigModalComponent;

  // collection updated variables
  pages = [];
  templates = [];
  templatesMap = {};
  template;

  selectedPage; // selected page Data (re-assigned every time user clicks edit button on a page)
  selectedTemplate: BookingTemplate; // selected template Data (re-assigned every time user clicks config)

  filter: string = 'all';
  searchValue: string = '';

  pagesLoaded: boolean = false;
  templatesLoaded: boolean = false;

  templateForm;

  templatesBeingEdited = [];

  isEditingPage: boolean;

  allowDelete: boolean = true;

  // Rxjs
  subs = new Subscription(); // group of subscriptions
  edit: boolean[] = [];

  constructor(
    private bookingService: BookingService,
    private router: Router,
    private deleteConfirmationService: DeleteConfirmationService,
    private imageLibraryService: ImageLibraryService,
  ) { }

  ngOnInit(): void {
    console.log("On intit loaded")
    this.templateForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
    });

    this.initalSubscriptions();
  }
  get title() {
    return this.templateForm.get('title');
  }
  onSubmit() {
    this.bookingService.createTemplate(this.templateForm.get('title')?.value);
    this.closeTemplateModal();
  }

  editTemplateSettings(template: BookingTemplate){
    this.selectedTemplate = JSON.parse(JSON.stringify(template)); // needs deepclone to re-trigger change detection on open
    $('#bookingFlowConfigModal').modal('show');
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe(); // unsubscribe from all subscriptions to prevent data leak
  }

  closeBookingFlowConfigModal() {
    $('#bookingFlowConfigModal').modal('hide');
  }

  async openCreateTemplateModal() {
    this.templateForm.reset();

    let hasLogo = await this.imageLibraryService.getCompanyLogo();

    if (hasLogo.id != undefined) { // If a logo was found allow user to create a template
      $('#createTemplateModal').modal('show');
    }
    else { // User must first upload a logo
      Swal.fire({
        title: 'A Company Logo must be set in order to create a booking template',
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: "Close",
        showConfirmButton: true,
        confirmButtonText: "Go to Company Settings",
        reverseButtons: true
      }).then((result) => {
        if(result.isConfirmed){
          this.router.navigate(['/partner/company']);
        }
      })
    }
  }

  closeTemplateModal() {
    $('#createTemplateModal').modal('hide');
  }

  editTemplate(template) {
    this.router.navigate(['/partner/booking-suite', template.id]);
  }

  async delete(data, type) {
    console.log(data);
    this.allowDelete = true;

    let obj = { title: data.title, id: data.id, extras: { bookingSuite: {} } };
    obj['extras']['bookingSuite']['datesInactivated'] = data.datesInactivated;

    if (type === 'page') {
      // if deleting a page

      let { listOfAffectedTemplates, listOfTemplatesBeingEditedWithThePage } =
        await this.calculateAffectedTemplatesPerPage(data.id); //n^2

      obj['extras']['bookingSuite']['type'] = 'page'; // assign type to page

      if (listOfTemplatesBeingEditedWithThePage.length > 0) {
        this.allowDelete = false;
      } else {
        if (listOfAffectedTemplates.length > 0) {
          obj['extras']['bookingSuite']['affectedTemplates'] =
            listOfAffectedTemplates;
        }

        console.log(data);
        console.log(listOfAffectedTemplates);
        console.log(obj);
        await this.deleteConfirmationService.giveInfoToDeleteConfirmation(obj);
      }
    } else {
      // else deleting template

      obj['extras']['type'] = 'template';
      await this.deleteConfirmationService.giveInfoToDeleteConfirmation(obj);
    }

    $('#deleteConfirmationModal').modal('show');
  }

  search(searchValue) {
    this.searchValue = searchValue;
  }

  initalSubscriptions(): void {
    console.log("Initial sub")
    // all inital subscriptions that are ran on ngInit lifecycle hook
    this.subs.add(
      this.bookingService.getAllPagesForCompany().subscribe((res) => {
        // get pages by companyID
        this.pages = res;
        this.pagesLoaded = true;
      })
    );

    this.subs.add(
      this.bookingService.getAllTemplatesForCompany().subscribe((res) => {
        // get templates by companyID
        this.templates = res;

        // Convert all templates into a map for easy access
        this.templates.forEach((template) => {
          this.templatesMap[template.id] = template;
        });

        // Go through all templates and push onto templatesBeingEdited list if any are currently being edited
        let templatesBeingEdited = [];
        this.templates.forEach((template) => {
          if (template?.editState?.isEditing == true) {
            templatesBeingEdited.push({
              title: template.title,
              user: template?.editState?.user,
            });
          }
        });
        this.templatesBeingEdited = templatesBeingEdited;
        this.templatesLoaded = true;
      })
    );

    this.subs.add(
      this.deleteConfirmationService.deleteConfirmed.subscribe((res) => {
        console.log(res);
        if (res['extras']['bookingSuite']['type'] === 'page') {
          // deleting a page

          // delete page content from all affected templates
          if (res['extras']['bookingSuite']['affectedTemplates']) {
            res['extras']['bookingSuite']['affectedTemplates'].forEach(
              async (temp) => {
                let { template, info } =
                  await this.bookingService.deleteBreadcrumbsFromTemplate(
                    this.templatesMap[temp.id],
                    res['id']
                  );
                // edit that template

                let tempMap = {};

                // make a map of the affected templates content arr
                template.content.forEach((cont) => {
                  if (cont.isPage === true) {
                    tempMap[cont.id] = cont;
                  }
                });

                // delete all of the children from the map
                info.forEach((id) => {
                  delete tempMap[id];
                });

                // remove the actual page being deleted / set to inactive
                delete tempMap[res['id']];

                let content = [];

                Object.keys(tempMap).forEach((key) => {
                  content.push(tempMap[key]);
                });

                template.content = JSON.parse(JSON.stringify(content));
                this.bookingService.updateTemplate(temp.id, template);
              }
            );
          }

          // delete page from bookingPages
          this.bookingService.delete(
            res['id'],
            res['extras']['bookingSuite']['datesInactivated'],
            'bookingPages'
          );
          $('#deleteConfirmationModal').modal('hide');
        } else {
          // deleting template
          console.log('woo');
          // res['extras']['bookingSuite']['type']
          this.bookingService.delete(
            res['id'],
            res['extras']['bookingSuite']['datesInactivated'],
            'bookingTemplates'
          );
          $('#deleteConfirmationModal').modal('hide');
        }
      })
    );
  }

  async calculateAffectedTemplatesPerPage(pageID) {
    let listOfAffectedTemplates = [];
    let listOfTemplatesBeingEditedWithThePage = [];

    this.templates.forEach((template) => {
      // n^2
      // Pages that will be affected
      template.breadcrumbs.forEach((bc) => {
        if (bc.pageID === pageID) {
          listOfAffectedTemplates.push({
            title: template.title,
            id: template.id,
          });

          // Pages that are currently being edited
          if (template.editState.isEditing) {
            listOfTemplatesBeingEditedWithThePage.push({
              title: template.title,
              user: template.editState.isEditing.user,
            });
          }
        }
      });
    });
    return { listOfAffectedTemplates, listOfTemplatesBeingEditedWithThePage };
  }

  openPageModal(isEditing, page) {
    this.isEditingPage = isEditing;
    if (isEditing) {
      this.selectedPage = page;
      this.PageFormComponent.setFormWithPageValues(page);
    }
    $('#pageModal').modal('show');
  }

  closePageModal() {
    $('#pageModal').modal('hide');
    // reset
    this.resetPageModal();
  }

  resetPageModal() {
    // used by BookingSuite component on modal exit
    this.PageFormComponent.resetForm();
  }

  async setPageFormData($event) {
    console.log($event);
    let { listOfAffectedTemplates } =
      await this.calculateAffectedTemplatesPerPage($event.id);
    console.log(listOfAffectedTemplates);

    let pages = [];
    pages.push({ id: $event.id, title: $event.title });

    this.selectedPage; // == to the last page selected / clicked on to edit

    let pagesWithEditedTitles = [];

    // check if the title was edited on the page, if so, include in page list for below method
    if (this.selectedPage.title != $event.title) {
      pagesWithEditedTitles.push({ title: $event.title, id: $event.id });
    }

    if (pagesWithEditedTitles.length > 0) {
      await this.bookingService.updatePageNamesOnAffectedTemplates(
        this.templates,
        pagesWithEditedTitles
      );
    }

    this.bookingService.updatePage($event.id, {
      subHeading: $event.subHeading,
      title: $event.title,
      img: $event.img,
    });

    // close modal
    $('#pageModal').modal('hide');
    this.PageFormComponent.resetForm(); // in case user goes back to "New Page" - resets form to blank
  }

  //close or open input  title
  protected templateTitleInteraction(index: number, action?: 'open' | 'save' | 'cancel'): void {
    switch(action){
      case 'open':
        this.templateTitlesWhileEditing[this.templates[index].id] = this.templates[index].title;
        break;
      case 'save':
        this.templates[index].title = this.templateTitlesWhileEditing[this.templates[index].id]; // helps to make the change in the UI look more instant before the subscription updates the data
        this.saveTemplate(this.templates[index].id, this.templateTitlesWhileEditing[this.templates[index].id]);
        break;
      case 'cancel':
        if(this.templateTitlesWhileEditing[this.templates[index].id]){
          delete this.templateTitlesWhileEditing[this.templates[index].id];
        }
    }

    this.edit[index] = !this.edit[index]; // Closes an open input field
  }

  //input Edit Title
  private saveTemplate(templateID: string, title: string): void {
    this.bookingService
      .updateTitletemp(templateID, title)
      .then(() => {
        Swal.fire({
          title: 'Title updated successfully',
          toast: true,
          position: 'bottom-right',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        Swal.fire({
          toast: true,
          position: 'bottom-right',
          title: 'Error updating title. Please try again later',
          icon: 'error',
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }
}
