import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DebounceDirective } from '../utils/debounce.directive';
import { GetIndividualRentalItemPricePipe } from '../utils/pipes/get-individual-rental-item-price.pipe';
import { LuxonDisplay } from '../utils/pipes/LuxonDisplay.pipe';
import { NgOptimizedImage } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CopyToClipboardComponent } from '../utils/copyToClipboard/copyToClipboard.component';

@NgModule({
  declarations: [DebounceDirective,
    GetIndividualRentalItemPricePipe,
    LuxonDisplay,
    CopyToClipboardComponent ],
  exports:[DebounceDirective, GetIndividualRentalItemPricePipe, LuxonDisplay, NgOptimizedImage, BrowserAnimationsModule, CopyToClipboardComponent],
  imports: [
    CommonModule, NgOptimizedImage, BrowserAnimationsModule 
  ],
  providers: [LuxonDisplay]
})
export class SharedDirectivesModule { }
