<div class="container noHistory">
    <div class="row">
    <div class="col-md-12">
        <div class="error-template">
            <h1>
                Oops!</h1>
            <h2>
                Not History Found</h2>
        </div>
    </div>
</div>