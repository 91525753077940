import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

/* Services */
import { RouteQueryGuardService } from '../services/guard-related-services/route-query-guard.service';
import { RouteQueryGuardConfigService } from '../services/guard-related-services/route-query-guard-config.service';

/* Models */
import { QueryParamConfig } from '../models/route-query-guard-config.model';

export const RouteQueryGuard: CanActivateFn = async (route, state) => {
  const routeQueryGuardService = inject(RouteQueryGuardService);
  const configService = inject(RouteQueryGuardConfigService); // Holds Route Configs & their requirements

  const routePath = route.routeConfig.path;
  const routeQueryParamConfigs: QueryParamConfig[] = await configService.getConfig(routePath);

  if (!routeQueryParamConfigs) { // No Route configurations were set up but the can active guard was still triggered
    console.error(`Missing configuration for route: ${routePath}`);
    routeQueryGuardService.navigateTo404();
    return false;
  }

  const returnedDocuments: { [key: string]: any } = {};
  const results: boolean[] = await Promise.all(routeQueryParamConfigs.map(async (query: QueryParamConfig) => {
    const documentID = (query.type === 'route' ? route.paramMap.get(query.paramName) : route.queryParamMap.get(query.paramName));
    if (!documentID) {
      console.error(`Missing parameter: ${query.paramName}`);
      return false;
    }
    const document = await routeQueryGuardService.resolveDocument(query.collection, documentID, query.conditions);

    if (document) {
      returnedDocuments[query.paramName] = document;
    }
    return document;
  }));

  // Every promise / query must pass
  if (results.every(result => result !== null)) {
    route.data = { ...route.data, ...returnedDocuments }
    return true;
  } else {
    routeQueryGuardService.navigateTo404();
    return false;
  }
};