<div mat-dialog-title>
  {{data.title}}
</div>
<div mat-dialog-content>
  {{data.message}}
</div>

<div mat-dialog-actions>

  <button mat-button (click) = "close()" > {{data.buttonCancel}} </button>

  <button mat-button [mat-dialog-close] = "true" cdkFocusInitial> {{data.buttonConfirm}}</button>

</div>
