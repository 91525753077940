import { DateTime } from 'luxon';

export interface Timeslot {
    availDaySpan: number;
    dayStart: DateTime;
    dayStartString: string;
    dayEnd: DateTime;
    dayEndString: string;
    type: TimeslotType;
    unavailable?: boolean;
    unavailableData?: { dayEnd: DateTime, dayStart: DateTime, extras: {widget: number, count:number}, reason: string }[];
    prodWidgets?: string[];
    productID?: string;
    productSizeID?: string;
    productSizeName?: string;
    productGroupID?: string;
}

// separated here due to circular dependency
// for availability AlgoMetadata + Info > CartObj > TimeslotType
export enum TimeslotType {
    Hourly = 'hourly',
    ShopDay = 'shopDay',
    TwentyFourHour = '24hr'
}

export function timeslotTypeGuard(slotType: string): slotType is TimeslotType {
  for (const key in TimeslotType) {
    if (TimeslotType[key] == slotType) {
      return true
    }
  }
  return false
}
