import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Models
import { Affiliate } from 'src/app/models/storage/affiliate.model';

@Injectable({
  providedIn: 'root'
})
export class AffiliateService {

  constructor(private afs: AngularFirestore) { }

  getAffiliateByID(affiliateID: string): Observable<any>{
    return this.afs.collection('affiliates').doc(affiliateID).get();
  }

  getAllAffiliates(companyID: string): Observable<any>{
        // subscribe to affiliate collection updates - by companyID
        return this.afs
        .collection('affiliates', (ref) =>
          ref
            .where('companyID', '==', companyID)
            .where('softDeleted', '==', false)
        )
        .stateChanges()
        .pipe(
          map((changes) => {
            return changes.map((action) => {
              let data = action.payload.doc.data();
              data['id'] = action.payload.doc.id;
              data['type'] = action.type;
              return data;
            });
          })
        );
    }

  createAffiliate(affiliate: Affiliate): Promise<any>{
    return this.afs.collection('affiliates').add(affiliate);
  }

  updateAffiliate(affiliateID: string, affiliateFieldUpdates: {}): Promise<any>{
    console.log(affiliateID)
    console.log(affiliateFieldUpdates)
    return this.afs.collection('affiliates').doc(affiliateID).update(affiliateFieldUpdates);
  }

  softDeleteAffiliate(affiliateID: string): Promise<any>{
    return this.afs.collection('affiliates').doc(affiliateID).update({softDeleted: true});
  }

}
