<div id="bookingsID" class="card-body p-0 row scrollable" [class.loading]="dataSource?.loading$ | async">
  <div class="content table-responsive">
    <div class="container-fluid">
      <div class="row justify-content-center mt-2">
        <div class="col-md-4 text-left">
          <h1 class="mt-2 mb-2" style="font-size: 32px">
            Bookings
            <div *ngIf="!buttonShow then old else new"></div>
            <ng-template #old>
              <button
              [style.background]="textColor"
              [style.color]="backgroundColor"
              class="btn mt-1"
              (click)="loadIFrame(true)"
              >
                <i class="fas fa-plus">&ngsp;</i> Add New Booking
              </button>

              <div id="divRental" *ngIf="isIFrameLoaded">
                <button id="buttonfleetmaidclosew" (click)="loadIFrame(false)">
                  <img
                    id="imagebuttonfleetmaid"
                    src="https://fleetmaid.com/assets/images/icons/exit.svg"
                    width="18"
                    height="17"
                  />
                  <!--Exit Shop-->
                </button>
                <iframe id="scrollframe" [src]="urlIframe"></iframe>
              </div>

            </ng-template>

            <ng-template #new>
              <button
              [style.background]="textColor"
              [style.color]="backgroundColor"
              class="btn mt-1"
              (click)="showSelector()"
              >
                <i class="fas fa-plus">&ngsp;</i> Add New Booking
              </button>

              <div id="divRental" *ngIf="isIFrameLoadedNew">
                <button id="buttonfleetmaidclosew" (click)="loadIFrameNew(false)">
                  <img
                    id="imagebuttonfleetmaid"
                    src="https://fleetmaid.com/assets/images/icons/exit.svg"
                    width="18"
                    height="17"
                  />
                  <!--Exit Shop-->
                </button>
                <iframe id="scrollframe" [src]="urlIframeNew"></iframe>
              </div>

            </ng-template>

          </h1>
        </div>
        <div class="col-md-4 text-center">
          <p class="mt-3" style="opacity: 0.74">
            (Scan QR Code to pull up booking)
          </p>
        </div>
        <div class="col-md-4 text-right">
          <div style="display: inline-block" class="form-control-lg mb-3">
            <div class="row g-3 align-items-center">
              <div class="col-6">
                <label for="inputPassword6" class="col-form-label"
                  >Filter by</label
                >
              </div>
              <div class="col-6">
                <ng-select
                  [(ngModel)]="status"
                  (change)="statusChange($event)"
                  placeholder="Select a status"
                  [searchable]="true"
                  [clearable]="true"
                  appendTo="body">
                  <ng-option
                  *ngFor="let status
                              of [statuses.Current, statuses.Upcoming, statuses.CheckedOut, statuses.Completed, statuses.Cancelled, statuses.All]" [value]="status">
                    {{ status === statuses.All ? 'All' : status }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="false" class="container-fluid align-items-end justify-content-end">
      <form (submit)="searchBooking()">
        <div class="row g-3 align-items-end justify-content-end">

          <div class="col-auto my-3">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Search booking"
                name="searchNumber"
                [(ngModel)]="searchNumber"
              />
              <div class="input-group-append">
                <button
                  *ngIf="isSearch"
                  class="btn btn-outline-secondary"
                  type="button"
                  (click)="resetSearch()"
                >
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
            <!-- oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" -->
          </div>

          <div class="col-auto mb-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="BeginsWith"
                id="flexRadioDefault1"
                [checked]="BeginsWith"
                (click)="chageTypeSearch()"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                Begins With
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="ContainsAny"
                id="flexRadioDefault2"
                [checked]="ContainsAny"
                (click)="chageTypeSearch()"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                Contains Any
              </label>
            </div>
          </div>

          <div class="col-auto my-3">
            <button type="submit" class="btn btn-outline-success">
              Search
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="table-container">

      <div *ngIf="dataSource?.loading$ | async" class="loading-overlay">
        <div class="spinner-container">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>

      <table mat-table [dataSource]="dataSource" matSort matSortActive="{{defaultSortField}}" matSortDirection="asc" matSortDisableClear>
        <ng-container matColumnDef="rentalNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">No.</th>
          <td mat-cell *matCellDef="let r" data-label="No."><strong># {{r.rentalNumber}}</strong></td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let r" data-label="Name">{{ r.userInfo?.name }} {{ r.userInfo?.lastName }}</td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef>Phone</th>
          <td mat-cell *matCellDef="let r" data-label="Phone">{{ r.userInfo?.phone }}</td>
        </ng-container>

        <ng-container matColumnDef="dayStart">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date">Rental Date</th>
          <td mat-cell *matCellDef="let rental" data-label="Rental Date">
            {{
            rental.rentalType == "byHour" ?
              (rental.dayStart | luxonDisplayPipe : 'DATETIME_MED' : ((rental?.rentalLocationID) ?
              locationMap[rental.rentalLocationID]['timezone'] : defaultLocationTimezone))
            :
              (rental.dayStart | luxonDisplayPipe : 'DATETIME_MED' : ((rental?.rentalLocationID) ?
              locationMap[rental.rentalLocationID]['timezone'] : defaultLocationTimezone))
            }}
            -
            {{
            rental.rentalType == "byHour" ?
              (rental.dayEnd | luxonDisplayPipe : 'TIME_SIMPLE' : ((rental?.rentalLocationID) ?
              locationMap[rental.rentalLocationID]['timezone'] : defaultLocationTimezone))
            :
              (rental.dayEnd | luxonDisplayPipe : 'DATETIME_MED' : ((rental?.rentalLocationID) ?
              locationMap[rental.rentalLocationID]['timezone'] : defaultLocationTimezone))
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let rental" data-label="Status">
            <span *ngIf="rental.isComplete" class="badge badge-success my-2">Booking Complete</span>
            <span *ngIf="rental.isCheckedIn && !rental.isComplete" class="badge badge-warning my-2">Checked In</span>
            <span *ngIf="rental.isCheckedOut && !rental.isCheckedIn" class="badge badge-warning my-2">Checked Out</span>
            <span *ngIf="!rental.isCheckedOut && !rental.isCancelled" class="badge badge-primary my-2">Pending Prep</span>
            <span *ngIf="rental.isCancelled" class="badge badge-danger my-2">Cancelled</span>
            <span class="badge badge-info" *ngIf="rental.amountPending > 0">Pending Balance</span>
            <span class="badge bg-info" *ngIf="rental.isDeliver">Deliver to {{ rental.deliverInformation.name }}</span>
            <i *ngIf="rental?.shopComments || rental?.cartObj?.additionalComments" class="fa fa-comment icon-shopComment" aria-hidden="true"></i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColumnOrder"></tr>
        <tr mat-row *matRowDef="let rental; columns: tableColumnOrder" (click)="rentalClick(rental)" style="cursor: pointer"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="5">No bookings to display</td>
        </tr>
      </table>

      <mat-paginator #paginator
        [length]="totalRentals"
        [pageSize]="100"
        [disabled]="dataSource?.loading$ | async"
        aria-label="Select page"
      ></mat-paginator>
    </div>

    <div class="container-fluid mt-3" *ngIf="false">
      <div
        class="row"
        id="heyo"
        [style.color]="textColor"
        [style.background]="backgroundColor"
        style="font-weight: 600"
      >
        <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
          <p class="my-2 mx-3">Rental Number</p>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3">
          <p class="my-2 mx-3">Name</p>
        </div>
        <div
          class="col col-sm-4 col-md-3 col-lg-2 col-xl-2 d-none d-sm-block d-md-block d-lg-block d-xl-block"
        >
          <p class="my-2 mx-3">Phone</p>
        </div>
        <div
          class="col col-sm-3 col-md-3 col-lg-3 col-xl-3 d-none d-md-block d-lg-block d-xl-block"
        >
          <p class="my-2 mx-3">Rental Date</p>
        </div>
        <div
          class="col col-sm-2 col-md-2 col-lg-2 col-xl-2 d-none d-lg-block d-xl-block"
        >
          <p class="my-2 mx-3">Status</p>
        </div>
      </div>
      <div class="row">
        <ng-container *ngIf="rentals.length == 0">
          <div class="col">
            <p class="text-center">There's nothing to show here</p>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="rentals?.length > 0">
        <!-- | filterBookings : page -->
        <div
          class="row tables-divs"
          *ngFor="let rental of rentals"
          (click)="rentalClick(rental)"
          style="cursor: pointer"
          data-bs-toggle="modal"
          data-bs-target="#bookings-upload"
        >
          <ng-container *ngIf="rental?.rentalNumber">
            <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
              <p class="my-2 mx-3">
                <b
                  ># {{ rental?.rentalNumber }}
                  <span class="badge bg-info" *ngIf="rental.isDeliver"
                    >Deliver to {{ rental.deliverInformation.name }}</span
                  >
                </b>
              </p>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3">
              <p class="my-2 mx-3">
                {{ rental.userInfo?.name }} {{ rental.userInfo?.lastName }}
              </p>
            </div>
            <div
              class="col col-sm-4 col-md-3 col-lg-2 col-xl-2 d-none d-sm-block d-md-block d-lg-block d-xl-block"
            >
              <p class="my-2 mx-3">{{ rental.userInfo?.phone }}</p>
            </div>
            <div
              class="col col-sm-3 col-md-3 col-lg-3 col-xl-3 d-none d-md-block d-lg-block d-xl-block"
            >
              <p class="my-2 mx-3" >
                {{
                  rental.rentalType == "byHour" ?
                  (rental.dayStart | luxonDisplayPipe : 'DATETIME_MED' : ((rental?.rentalLocationID) ? locationMap[rental.rentalLocationID]['timezone'] : defaultLocationTimezone))
                  :
                  (rental.dayStart | luxonDisplayPipe : 'DATETIME_MED' : ((rental?.rentalLocationID) ? locationMap[rental.rentalLocationID]['timezone'] : defaultLocationTimezone))
                }}
                  -
                {{
                  rental.rentalType == "byHour" ?
                  (rental.dayEnd | luxonDisplayPipe : 'TIME_SIMPLE' : ((rental?.rentalLocationID) ? locationMap[rental.rentalLocationID]['timezone'] : defaultLocationTimezone))
                  :
                  (rental.dayEnd | luxonDisplayPipe : 'DATETIME_MED' : ((rental?.rentalLocationID) ? locationMap[rental.rentalLocationID]['timezone'] : defaultLocationTimezone))
                }}
              </p>
            </div>
            <div
              class="col col-sm-2 col-md-2 col-lg-2 col-xl-2 d-none d-lg-block d-xl-block"
            >
              <span *ngIf="rental.isComplete" class="badge badge-success my-2"
                >Booking Complete</span
              >
              <span
                *ngIf="rental.isCheckedIn && !rental.isComplete"
                class="badge badge-warning my-2"
                >Checked In</span
              >
              <span
                *ngIf="rental.isCheckedOut && !rental.isCheckedIn"
                class="badge badge-warning my-2"
                >Checked Out</span
              >
              <span
                *ngIf="!rental.isCheckedOut &&
                  !rental.isCancelled
                "
                class="badge badge-primary my-2"
                >Pending Prep</span
              >
              <span *ngIf="rental.isCancelled" class="badge badge-danger my-2"
                >Cancelled</span
              >
              <span class="badge badge-info" *ngIf="rental.amountPending > 0">
                Pending Balance
              </span>
              <i
                *ngIf="rental?.shopComments || rental?.cartObj?.additionalComments"
                class="fa fa-comment icon-shopComment"
                aria-hidden="true"
              ></i>
            </div>
            <!---table mobile---->
            <div class="row list-mobile">
              <div class="col-12 p-1">
                <ul class="nav flex-column">
                  <li class="d-block d-sm-none d-md-none">
                    <p class="my-2">
                      <b>Phone : </b>{{ rental.userInfo?.phone }}
                    </p>
                  </li>
                  <li class="d-block d-md-none">
                    <p class="my-2">
                      <b>Rental Date :</b>
                      {{
                        rental.rentalType == "byHour" ?
                        (rental.dayStart | luxonDisplayPipe : 'DATETIME_MED' : ((rental?.rentalLocationID) ?
                        locationMap[rental.rentalLocationID]['timezone'] : defaultLocationTimezone))
                        :
                        (rental.dayStart | luxonDisplayPipe : 'DATETIME_MED' : ((rental?.rentalLocationID) ?
                        locationMap[rental.rentalLocationID]['timezone'] : defaultLocationTimezone))
                      }}
                        -
                      {{
                        rental.rentalType == "byHour" ?
                        (rental.dayEnd | luxonDisplayPipe : 'TIME_SIMPLE' : ((rental?.rentalLocationID) ?
                        locationMap[rental.rentalLocationID]['timezone'] : defaultLocationTimezone))
                        :
                        (rental.dayEnd | luxonDisplayPipe : 'DATETIME_MED' : ((rental?.rentalLocationID) ?
                        locationMap[rental.rentalLocationID]['timezone'] : defaultLocationTimezone))
                      }}
                    </p>
                  </li>

                  <li>
                    <b class="my-2">Status : </b>
                    <span
                      *ngIf="rental.isComplete"
                      class="badge badge-success my-2"
                      >Booking Complete</span
                    >
                    <span
                      *ngIf="rental.isCheckedIn && !rental.isComplete"
                      class="badge badge-warning my-2"
                      >Checked In</span
                    >
                    <span
                      *ngIf="rental.isCheckedOut && !rental.isCheckedIn"
                      class="badge badge-warning my-2"
                      >Checked Out</span
                    >
                    <span
                      *ngIf="!rental.isCheckedOut &&
                        !rental.isCancelled
                      "
                      class="badge badge-primary my-2"
                      >Pending Prep</span
                    >
                    <span
                      *ngIf="rental.isCancelled"
                      class="badge badge-danger my-2"
                      >Cancelled</span
                    >
                    <i
                      *ngIf="rental?.cartObj?.additionalComments"
                      class="fa fa-comment icon-comment"
                      aria-hidden="true"
                    ></i>
                  </li>
                </ul>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>

  </div>
</div>

<div
  class="modal fade"
  id="bookings-upload"
  aria-hidden="true"
  data-bs-focus="false"
  aria-labelledby="exampleModalLabel"
  tabindex="-1"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row d-flex justify-content-between">
          <div class="col-3">
            <h4 class="modal-title" id="exampleModalLabel">
              <ng-container *ngIf="rental && rental?.rentalNumber">
                <b *ngIf="!_currentUser.currentUser.isDeveloper" class="fs-5"
                  >Booking #{{ rental ? rental.rentalNumber : "" }}</b
                >
                <b *ngIf="_currentUser.currentUser.isDeveloper" class="fs-5"
                  ><a
                    href="https://console.firebase.google.com/u/0/project/{{ projectId }}/firestore/data/~2Frentals/{{
                      rental?.id
                    }}"
                    target="_blank"
                    style="text-decoration: none"
                    >Booking #
                    {{ rental ? rental.rentalNumber : "" }}</a
                  ></b
                >
              </ng-container>
              <!--<span class="badge bg-primary titletotal ms-2 justify-content-center align-items-center text-center">Total
                Amount Paid: ${{rental ? rental.cost : ""}}</span>-->
            </h4>
          </div>

          <div class="col-2 text-right align-items right">
            <button
              type="button"
              class="btn btn-danger"
              (click)="displayEditBooking()"
            >
              <i class="fa fa-close"></i>
            </button>
          </div>

          <div class="col-12 rental-paid fw-bold d-flex">
            <fieldset *ngIf="rentalTotalPaid" class="border p-2 firsttotal">
              <legend class="float-none w-auto p-2 firsttotal">
                Booking Total
              </legend>
              <p
                *ngIf="spinner"
                class="spinner-border spinner-border-sm text-dark lasttotal"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </p>
              <p *ngIf="!spinner" class="lasttotal">
                ${{ rental && rental['cost'] ? rental['cost'].toFixed(2) : 0 }}
              </p>
            </fieldset>
            <fieldset *ngIf="!rentalTotalPaid" class="border p-2 firsttotal">
              <legend class="float-none w-auto p-2 firsttotal">
                Bookings Total
              </legend>
              <p
                *ngIf="spinner"
                class="spinner-border spinner-border-sm text-dark lasttotal"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </p>
              <p *ngIf="!spinner" class="lasttotal">
                ${{ rental && rental['cost'] ? rental['cost'].toFixed(2) : 0 }}
              </p>
            </fieldset>
            <fieldset
              *ngIf="rentalTotalPaid == 0"
              class="border p-2 firsttotal ms-2"
            >
              <legend class="float-none w-auto p-2 firsttotal">
                Total Amount Paid
              </legend>
              <p
                *ngIf="spinner"
                class="spinner-border spinner-border-sm text-dark lasttotal"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </p>
              <p *ngIf="!spinner" class="lasttotal">$0.00</p>
            </fieldset>
            <fieldset
              *ngIf="rentalTotalPaid"
              class="border p-2 firsttotal ms-2"
            >
              <legend class="float-none w-auto p-2 firsttotal">
                Total Amount Paid
              </legend>
              <p
                *ngIf="spinner"
                class="spinner-border spinner-border-sm text-dark lasttotal"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </p>
              <p *ngIf="!spinner" class="lasttotal">
                ${{ rental ? rentalTotalPaid.toFixed(2) : "" }}
              </p>
            </fieldset>
            <fieldset
              *ngIf="rental.amountPending >= 0.01"
              class="border p-2 firsttotal ms-2"
            >
              <legend class="float-none w-auto p-2 firsttotal">Pending</legend>
              <p
                *ngIf="spinner"
                class="spinner-border spinner-border-sm text-dark lasttotal"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </p>
              <p *ngIf="!spinner" class="lasttotal">
                ${{
                  rental.amountPending
                    ? rental.amountPendingAbs?.toFixed(2)
                    : "0.00"
                }}
              </p>
              <legend class="float-none w-auto p-2 firsttotal bottomlegend">
                Before Tax
              </legend>
            </fieldset>
            <fieldset
              *ngIf="rentalSecurityPending > 0"
              class="border p-2 firsttotal ms-2"
            >
              <legend class="float-none w-auto p-2 firsttotal">
                Security Deposit Hold
              </legend>
              <p
                *ngIf="spinner"
                class="spinner-border spinner-border-sm text-dark lasttotal"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </p>
              <p *ngIf="!spinner" class="lasttotal">
                ${{ rental ? rentalSecurityPending.toFixed(2) : "" }}
              </p>
            </fieldset>
            <fieldset
            *ngIf="rentalAmountPendingToRefund > 0.01"
            class="border p-2 firsttotal ms-2"
          >
            <legend class="float-none w-auto p-2 firsttotal">
              Amount Pending To Refund
            </legend>
            <p
              *ngIf="spinner"
              class="spinner-border spinner-border-sm text-dark lasttotal"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </p>
            <p *ngIf="!spinner" class="lasttotal">
              ${{ rental ? rentalAmountPendingToRefund.toFixed(2) : "" }}
            </p>
          </fieldset>
          </div>

        </div>
      </div>
      <ng-container *ngIf="bookingsIsOpen">
      <app-bookings-upload2 [locationMap]="locationMap" [locations]="locations" [defaultLocationTimezone]="defaultLocationTimezone"
            [companyObj]="companyObj" [open]="bookingsIsOpen" [rentalTotalPaid]="rentalTotalPaid" [rental]="rental" [isQuickBook]="isQuickBook" [templates]="templates"
            [selectedTemplate]="selectedOption"
            (rentalCostUpdate)="updateRentalTotals($event)" (replaceRental)="replaceRental($event)" (isQuickBookChange)="isQuickBookChange($event)">
      </app-bookings-upload2>
    </ng-container>
    </div>
  </div>
</div>

<!-- Pop-up with Bootstrap -->
<div class="modal fade" id="popupModal" tabindex="-1" aria-labelledby="popupModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="popupModalLabel">Select a Booking Flow</h3>
        <button type="button" class="btn-close" (click)="closeShowSelector()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <select class="form-select mb-3" [ngClass]="{'is-invalid': invalid}" placeholder="Select a Booking Flow" [(ngModel)]="selectedOption" required>
          <option value="" disabled selected>Select a Booking Flow</option>
          <option *ngFor="let option of templates" [value]="option.id">{{ option.title }}</option>
        </select>
        <div class="invalid-feedback">
          Please select a booking flow.
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button *ngIf="_currentUser.currentUser.isDeveloper" type="button" class="btn btn-danger" (click)="openNewBookingIframe()" >Dev Iframe</button>
        <button type="button" class="btn btn-primary" (click)="openNewBookingNewTab()">New Booking</button>
        <button type="button" class="btn btn-primary" (click)="openQuickBooking()">Quick Booking</button>
        <button type="button" class="btn btn-secondary" (click)="closeShowSelector()">Cancel</button>
      </div>
    </div>
  </div>
</div>

