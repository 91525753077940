<!-- &quot; is added because otherwise pictures with spaces in their name, '()', wont load -->
<div [ngClass]="contentInfo?.itemUnavailable ? 'unavailableContainer' : 'contentContainer'"
    [ngStyle]="{'background-image': 'url(&quot;' + contentInfo?.img + '&quot;)'}">
    <div *ngIf="contentInfo?.itemUnavailable" class="text-white unavailableHeading d-flex align-items-center">
        <div *ngIf="contentInfo?.itemUnavailable" class="freeShippingRibbon">Unavailable</div>
    </div>

    <div style="position: absolute; bottom: 20px; left: 16px; width: 90%;" class="text-white">
        <div class="d-flex justify-content-between align-items-center">
            <!-- Text Heading -->
            <div>
                <h3 class="m-0"
                    style="font-size: 2em; text-shadow: 1px 1px 1px rgba(0,0,0,0.75), 0 0 10px rgba(0,0,0,0.5);">
                    {{contentInfo?.title}}</h3>
                <h4 style='font-weight: 200; text-shadow: 1px 1px 1px rgba(0,0,0,0.75), 0 0 10px rgba(0,0,0,0.5);'
                    class="m-0">{{contentInfo?.subHeading}}</h4>
                <h4 style='font-weight: 200; text-shadow: 1px 1px 1px rgba(0,0,0,0.75), 0 0 10px rgba(0,0,0,0.5);'
                    class="m-0" *ngIf="contentInfo?.isItem && contentInfo?.sizesAvail && !contentInfo.itemUnavailable">
                    <!-- Shows available sizes on catalog items that are products -->
                    <span *ngFor="let size of contentInfo.sizesAvail; index as i">
                        <ng-container *ngIf="(size.currentAvail > 0 || size.isAvailable) && size.sizeName != 'NA'">
                            <span>{{size.sizeName}}</span>
                            <span *ngIf="i < contentInfo.sizesAvail.length - 1">,&nbsp; </span>
                        </ng-container>
                    </span>
                </h4>
            </div>

            <!-- Button -->
            <div *ngIf="contentInfo?.isPage">
                <button class="shine">View</button>
            </div>
            <div *ngIf="contentInfo?.isItem && !contentInfo?.itemUnavailable">
                <button class="shine">Book</button>
            </div>
        </div>
    </div>
</div>
