export class Log {

    firstName: string
    lastName: string
    userID: string
    timestamp: any
    details: string
    message: string

    constructor(data: any){
        this.firstName = data["firstName"]
        this.lastName = data["lastName"]
        this.userID = data["userID"]
        this.timestamp = data["timestamp"]
        this.details = data["details"]
        this.message = data["message"]
    }

}
