import { Component, OnInit, Output, ViewChild } from '@angular/core';

import { NgSelectComponent } from '@ng-select/ng-select';

import { Maintenance } from 'src/app/models/storage/maintenance.model';

import { CurrentUserService } from 'src/app/services/current-user.service';
import { MaintenanceService } from 'src/app/services/maintenance.service';
import { ProductsService } from 'src/app/services/products.service';
import { LogService } from 'src/app/services/log.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  public user: any;
  public productsselect = [];
  public productName: string = '';
  public id: string = '';
  public isAvailable: boolean = false;
  public isCleaning: boolean = false;
  public isTuneUp: boolean = false;
  public isDamaged: boolean = false;
  public isInvalid: boolean = false;
  public isInvalidDesc: boolean = false;
  public isInvalidUnv: boolean = false;
  public description: string = '';
  public unavailableReason: string = '';
  public maintenance: Maintenance = {
    companyName: '',
    companyID: '',
    productName: '',
    productID: '',
    date: new Date(),
    isCleaning: false,
    isTuneUp: false,
    isDamaged: false,
    isReady: false,
    description: '',
  };
  constructor(
    public _mService: MaintenanceService,
    private _productService: ProductsService,
    private _currentUserService: CurrentUserService,
    private _logService: LogService
  ) {}

  @Output() public searchQuery: string = '';

  ngOnInit(): void {
    this.user = this._currentUserService.currentUser;
    this.getProductsSelect();
  }

  getProductsSelect() {
    this._productService.getProducts(true).subscribe((element) => {
      this.productsselect = [];
      element.forEach((data) => {
        this.productsselect.push({
          id: data.id,
          number: data.productNumber,
          name: data.productName,
          size: data.productSize,
          isAvailable: data.isAvailable,
          isCleaning: data.isCleaning,
          isTuneUp: data.isTuneUp,
          isDamaged: data.isDamaged,
          unavailableReason: data.unavailableReason
            ? data.unavailableReason
            : '',
          maintenanceReason: data.maintenanceReason
            ? data.maintenanceReason
            : '',
        });
      });
    });
  }

  openModalMaint(product: any) {
    this.productName = product?.name;
    this.id = product?.id;
    this.description = product?.maintenanceReason;
    this.unavailableReason = product?.unavailableReason;
    this.isCleaning = product?.isCleaning;
    this.isTuneUp = product?.isTuneUp;
    this.isDamaged = product?.isDamaged;
    this.isAvailable = product?.isAvailable;
    $('#modalMaintenance').modal('show');
  }

  closeModalMaint() {
    this.productName = '';
    this.id = '';
    this.description = '';
    this.unavailableReason = '';
    this.ngSelectComponent.handleClearClick();
    $('#modalMaintenance').modal('hide');
  }

  availableDiv(isAvailable: boolean) {
    this.isAvailable = isAvailable;
    this.isInvalid = false;
    this.isInvalidDesc = false;
  }

  isInvalidMaint() {
    this.isInvalid = false;
  }

  isInvalidReasonMaint(e) {
    if (e.target.value !== '') {
      this.isInvalidDesc = false;
    } else {
      this.isInvalidDesc = true;
    }
  }

  isInvalidReasonUnav(e) {
    if (e.target.value !== '') {
      this.isInvalidUnv = false;
    } else {
      this.isInvalidUnv = true;
    }
  }

  addProductManint() {
    if (this.isAvailable) {
      if (
        this.isCleaning === false &&
        this.isTuneUp === false &&
        this.isDamaged === false
      ) {
        this.isInvalid = true;
        return;
      }

      if (this.description === '') {
        this.isInvalidDesc = true;
        return;
      }
    }

    if (!this.isAvailable) {
      if (this.unavailableReason === '') {
        this.isInvalidUnv = true;
        return;
      }
    }

    let obj: any = {
      isActive: this.isAvailable ? true : false,
      isAvailable: this.isAvailable,
      isMaintenance: true,
      isCleaning: this.isCleaning,
      isDamaged: this.isDamaged,
      isTuneUp: this.isTuneUp,
      unavailableReason: this.isAvailable ? '' : this.unavailableReason,
      maintenanceReason: this.description ? this.description : '',
    };
    this.maintenance.companyName = this.user.company;
    this.maintenance.companyID = this.user.companyId;
    this.maintenance.productName = this.productName;
    this.maintenance.productID = this.id;
    this.maintenance.date = new Date();
    this.maintenance.isCleaning = this.isCleaning;
    this.maintenance.isTuneUp = this.isTuneUp;
    this.maintenance.isDamaged = this.isDamaged;
    this.maintenance.isReady = false;
    this.maintenance.description = this.description;

    this._mService.addMaintenance(this.maintenance);
    this._logService.addProductLog(
      this.id,
      'Product Maintenance, isDamaged: ' +
        this.isDamaged +
        ', isCleaning: ' +
        this.isCleaning +
        ', isTuneUp: ' +
        this.isTuneUp +
        ', description: ' +
        this.isAvailable
        ? ''
        : this.unavailableReason
    );

    this._productService.updateProduct(this.id, obj).then((_) => {
      Swal.fire({
        title: 'Product maintenance',
        text: 'The product was sent to maintenance',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        this._logService.addProductLog(this.id, 'Edit Product');
        this.ngSelectComponent.handleClearClick();
        this.productName = '';
        this.id = '';
        this.description = '';
        this.unavailableReason = '';
        $('#modalMaintenance').modal('hide');
      });
    });
  }
}
