import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';
import imageSize from '@coderosh/image-size';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/compat/storage';
import { ImageLibrary } from 'src/app/models/image-library.model';
import { CurrentUserService } from '../../services/current-user.service';
import { ImageLibraryService } from '../../services/image-library.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ColorTheme } from 'src/app/services/colorTheme.service';
// Pipe
import { ImgFilterPipe } from '../pipes/objectProperty-filter.pipe';

@Component({
  selector: 'app-image-library',
  templateUrl: './image-library.component.html',
  styleUrls: ['./image-library.component.scss'],
})
export class ImageLibraryComponent implements OnInit, OnDestroy {
  // passed in by other components
  imageSpecs: Array<Number> = [960, 540];
  logoSpecs: Array<Number> = [960, 540];

  @Input() showSelect: boolean = false; // toggles showing the select button if needed

  @Input() defaultTabSelection;

  aspectRatio: number = 1; // Rectangular -> 96 / 54, Square -> 1 / 1
  resizeToWidth: number = 0; // Standard img -> 960, Square logo -> 400

  optionName: string = '';

  onImageLibraryPage: boolean = false;

  subs = new Subscription(); // group of subscriptions

  inputSearch: string = '';

  imageInfo: UntypedFormGroup;
  typeSelected: boolean = false;
  imageType: string = '';

  submitted: boolean = false;
  public imageChangedEvent: any = '';
  public croppedImage: any = '';
  private _croppedFile: Blob;
  isButtonAlert = false;
  public originalImageName;
  public task: AngularFireUploadTask;
  isLocationSelected: boolean = false;
  isProductSelected: boolean = false;
  images = []; // can add type: imageLibrary[] back when we eventually remove previous options ("Location, Category, Product")
  standardImages = [];
  logos = [];
  newImages: ImageLibrary[] = [];
  idUsers: string;
  public Colortext: string;
  public Colotheme: string;

  constructor(
    public _toastr: ToastrService,
    private _fb: UntypedFormBuilder,
    private _storage: AngularFireStorage,
    private _cUserService: CurrentUserService,
    private _imageLib: ImageLibraryService,
    private router: Router,
    public _Theme_color: ColorTheme
  ) {
    this.imageInfo = this._fb.group({
      imageType: ['', [Validators.required]],
      image: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.checkRouteComponentIsBeingAccessedBy();
    this.getImages();
    this.fnColor();
  }

  fnColor() {
    this.idUsers = this._cUserService.currentUser?.id;
    this._Theme_color
      .getcolorPeruser(this.idUsers)
      .then((res) => {
        let objct = {
          theme: res.color.value,
          text: res.color.fontcolor,
        };
        this.Colortext = objct.text;
        this.Colotheme = objct.theme;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  // if the user is accessing the component through a partner path then allow access (companyID match is checked in template editor);
  checkRouteComponentIsBeingAccessedBy() {
    // parse route and if the url has "/*/library" then allow access to all img types, else, disable them
    let path = this.router.url.split('/');

    if (path[2] == 'library') {
      this.onImageLibraryPage = true;
    }
  }

  get f() {
    return this.imageInfo.controls;
  }

  getImages() {
    // if user is using a component that depends on img lib on customer side (without current user info), prevents breakage
    if (this._cUserService.currentUser === undefined) return;

    this.subs.add(this._imageLib.getImages(this._cUserService.currentUser.companyId).subscribe((res: any) => {
      // console.log(res);
      this.images = res
      this.standardImages = this.images.filter(img => img.type !== 'logo')
      this.logos = this.images.filter(img => img.type === 'logo')
      // console.log(this.images);
      // console.log(this.standardImages)
      // console.log(this.logos);
    }))
  }

  resetForm() {
    //close all modals
    $('#addImageModal').modal('hide');
    this.typeSelected = false;
    this.isProductSelected = false;
    this.imageInfo.reset();
    this.submitted = false;
    this.croppedImage = '';
    this.imageType = '';
  }

  validateForm() {
    Swal.fire({
      title: 'Please wait...',
      text: 'We are validating your image',
      icon: 'info',
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
    Swal.showLoading();
    this.submitted = true;
    if (!this.croppedImage) {
      // If angular form validation fails and a cropped img doesn't currently exist... (if user presses cancel, image input will be flagged as an error even if the content was previously saved... this is a work around)
      Swal.fire({
        title: 'Oops',
        text: 'Seems like something went wrong. Please confirm that an image type has been selected, re-upload your image, and try again.',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        allowOutsideClick: false,
        confirmButtonText: 'Done',
      });
      return;
    } else {
      this.saveImage();
    }
  }

  uploadImage(id: string) {
    this.getObject(id).then((obj: any) => {
      this._imageLib.updateImage(obj).then((_) => {
        Swal.fire({
          title: 'Image replaced',
          text: 'The image has been replaced',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          allowOutsideClick: false,
          confirmButtonText: 'Awesome!',
        }).then((_) => {
          this.resetForm();
        });
      });
    });
  }

  getObject(id?: string) {
    return this.getImage().then((obj: any) => {
      let lengthPublicUrl = obj.url.length;
      let imageNameWithoutSpace = obj.imageName.replace(/\s/g, '%20');
      let fileNameIndex = obj.url.indexOf(imageNameWithoutSpace + '.');
      let firstPartUrl = obj.url.substring(0, fileNameIndex);
      let secondPartUrl = obj.url.substring(fileNameIndex, lengthPublicUrl);
      let publicThumbnailURL = firstPartUrl + 'thumb_' + secondPartUrl;
      let objImage = {
        url: obj.url,
        thumbUrl: publicThumbnailURL,
        companyID: this._cUserService.currentUser.companyId,
        isActive: true,
        type: this.imageInfo?.controls?.imageType.value,
        name: obj.imageName,
      };
      id ? (objImage['id'] = id) : null;
      return objImage;
    });
  }

  saveImage() {
    this.getObject().then((obj: any) => {
      this._imageLib.saveImage(obj).then((_) => {
        Swal.fire({
          title: 'Image saved',
          text: 'The image has been saved',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          allowOutsideClick: false,
          confirmButtonText: 'Awesome!',
        }).then((_) => {
          this.resetForm();
        });
      });
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this._croppedFile = base64ToFile(this.croppedImage);
  }

  async fileChangeEvent(target: any) {
    console.log('asdf');

    if (target.target.files.length <= 0) {
      // workaround - if no file was uploaded currently (user pressed cancel), return without attempting to recrop
      return;
    }

    switch (this.imageInfo?.controls?.imageType.value) {
      case 'image':
        this.aspectRatio = 96 / 54; // Rectangle
        this.resizeToWidth = 960; // Standard img -> 960
        await this.crop(target);
        break;
      case 'logo':
        // this.isProductSelected = true;
        this.aspectRatio = 1 / 1; // Square
        this.resizeToWidth = 960; // logo
        await this.crop(target);
        break;
    }
  }

  async crop(target) {
    console.log('cropping');
    let originalImage = target.target.files as FileList;
    if (originalImage.item(0).type.match(/image\/*/) == null) {
      this._toastr.error('Only images are supported.', 'Error', {
        positionClass: 'toast-center-center',
      });
      return;
    }
    this.originalImageName = originalImage[0].name;
    var tmppath = URL.createObjectURL(originalImage[0]);
    const size = await imageSize(tmppath);

    // Size restraints
    if (size.width < 960 || size.height < 540) {
      this.isButtonAlert = true;
      this._toastr.error(
        'The minimum size for the width and height settings is: "960 x 540"  ',
        'Empty Field:',
        { positionClass: 'toast-top-right' }
      );
    } else {
      this.isButtonAlert = false;
      alert('Please Crop Image.');
      $('#crop').modal('show');
    }

    if (this.isButtonAlert === false) {
      this.imageChangedEvent = target;
    }
  }

  getImage() {
    return new Promise((resolve, reject) => {
      if (this._croppedFile != undefined) {
        let pathOptionImage =
          'imageLibrary/' +
          this.originalImageName.split('.')[0] +
          '/' +
          this.originalImageName;

        const fileRef = this._storage.ref(pathOptionImage);

        this.task = this._storage.upload(pathOptionImage, this._croppedFile);
        this.task
          .then(() => {
            fileRef.getDownloadURL().subscribe((url) => {
              resolve({
                url: url,
                imageName: this.originalImageName.split('.')[0],
              });
            });
          })
          .catch((error) => { });

        this._storage.upload(pathOptionImage, this._croppedFile);
      } else {
        reject('no Image');
      }
    });
  }

  showImage(img) {
    // If being used on component that should show the select button
    if (this.showSelect) {
      Swal.fire({
        title: img.name,
        html: '<img src="' + img.url + '" width="100%" height="100%">',
        showConfirmButton: true,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        denyButtonColor: '#bb2d3b',
        confirmButtonText: 'Select',
        denyButtonText: 'Delete',
        cancelButtonText: '<i class="fa fa-close"></i>',
        allowOutsideClick: false,
        reverseButtons: true,
        customClass: { cancelButton: 'swalTopRightClose' },
        didOpen: () => {
          if (img.isCompanyLogo) {
            /* Disable deleting images that are currently set as the company logo */
            $('.swal2-deny')[0]['disabled'] = true;
          }
        },
      }).then((res) => {
        // If item shown here, it's because the type name has been changed. (fail safe)
        let itemType = img?.type;
        if (
          itemType != 'product' &&
          itemType != 'category' &&
          itemType != 'location'
        ) {
          itemType = 'items';
        }

        // switchcase to adjust the name for multiple items (s)

        switch (itemType) {
          case 'product':
            itemType = 'products';
            break;

          case 'category':
            itemType = 'categories';
            break;

          case 'location':
            itemType = 'locations';
        }

        if (res.isConfirmed) {
          this._imageLib.selectImage(img);
        }
        if (res.isDenied && !res.isDismissed) {
          // console.log(img);
          Swal.fire({
            title: 'Are you sure?',
            text: `You won't be able to revert this! Deleting this will only remove it from the imageLibrary. Any ${itemType} containing this image will still retain the image.`,
            confirmButtonText: 'Yes, delete it!',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#9A9A9A',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
          }).then((res) => {
            if (res.isConfirmed) {
              Swal.fire({
                title: 'Deleting...',
                text: 'Your image is being deleted. Please wait...',
                allowEnterKey: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
              Swal.showLoading();
              // console.log("img", img)
              this._imageLib.dropImage(img).then((_) => {
                Swal.fire({
                  title: 'Deleted!',
                  text: 'Your file has been deleted.',
                  icon: 'success',
                  confirmButtonText: 'Ok',
                });
              });
            } else {
              Swal.fire({
                title: 'Cancelled',
                icon: 'error',
                confirmButtonText: 'Ok',
              });
            }
          });
        }
      });
    }
    // If hide select button
    else {
      Swal.fire({
        title: img.name,
        html: '<img src="' + img.url + '" width="100%" height="100%">',
        showConfirmButton: false,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        denyButtonColor: '#bb2d3b',
        confirmButtonText: 'Select',
        cancelButtonText: '<i class="fa fa-close"></i>',
        denyButtonText: 'Delete',
        allowOutsideClick: false,
        reverseButtons: true,
        customClass: { cancelButton: 'swalTopRightClose' },
        didOpen: () => {
          if (img.isCompanyLogo) {
            /* Disable deleting images that are currently set as the company logo */
            $('.swal2-deny')[0]['disabled'] = true;
          }
        },
      }).then((res) => {
        if (res.isDenied && !res.isDismissed) {
          // If item shown here, it's because the type name has been changed. (fail safe)
          let itemType = img?.type;
          if (
            itemType != 'product' &&
            itemType != 'category' &&
            itemType != 'location'
          ) {
            itemType = 'items';
          }

          // switchcase to adjust the name for multiple items (s)

          switch (itemType) {
            case 'product':
              itemType = 'products';
              break;

            case 'category':
              itemType = 'categories';
              break;

            case 'location':
              itemType = 'locations';
          }

          Swal.fire({
            title: 'Are you sure?',
            text: `You won't be able to revert this! Deleting this will only remove it from the imageLibrary. Any ${itemType} containing this image will still retain the image.`,
            confirmButtonText: 'Yes, delete it!',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#9A9A9A',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
          }).then((res) => {
            if (res.isConfirmed) {
              Swal.fire({
                title: 'Deleting...',
                text: 'Your image is being deleted. Please wait...',
                allowEnterKey: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
              Swal.showLoading();
              // console.log("img", img)
              this._imageLib.dropImage(img).then((_) => {
                Swal.fire({
                  title: 'Deleted!',
                  text: 'Your file has been deleted.',
                  icon: 'success',
                  confirmButtonText: 'Ok',
                });
              });
            } else {
              Swal.fire({
                title: 'Cancelled',
                icon: 'error',
                confirmButtonText: 'Ok',
              });
            }
          });
        }
      });
    }
  }

  async setImageType($event, type) {
    let previousImageType = this.imageInfo?.controls?.imageType.value; // The image type selected before clicking on a new one

    // If no croppedImg && the type has been changed
    if (
      this.croppedImage != '' &&
      $event.target.value !== this.imageInfo?.controls?.imageType.value
    ) {
      if (
        confirm(
          'Selecting a different upload type will remove the previously cropped image. Are you sure?'
        )
      ) {
        this.croppedImage = '';
        await this.imageInfo.reset(); // reactive form must be reset before setting new values
        this.imageChangedEvent = null; // resets cropper (needs this or creates an error)
        this.imageInfo?.controls?.imageType.setValue($event.target.value); // set the value of imageType
        return;
      } else {
        this.imageInfo?.controls?.imageType.setValue(previousImageType);
        return;
      }
    }

    this.typeSelected = true;
  }

  interactWithImgGallery() {
    // $('#addImageModal').modal('hide')
    $('#addImageModal').modal('show');
  }
}
