import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AuthService} from "../../../../services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input()
  deviceXs: boolean = false;
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter()
  constructor(
    private afAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.toggleSideBarForMe.emit()
  }
  toggleSideBar(){
    this.toggleSideBarForMe.emit()
  }
  logout(){
    this.authService.logout().then(() => {
      this.router.navigate(['/']).then(() => {
        window.location.reload()
      })
    })
  }
}
