/* Models */
import { AppError, ErrorLevel } from '../../models/errors.model'
import { ErrorCodes } from '../../models/errors.model';

export const DATE_RANGE_ERRORS: { [key: string]: AppError } = {
    [ErrorCodes.DATES_PASSED]: {
        code: ErrorCodes.DATES_PASSED,
        message: "Oops! The selected dates can't be in the past. Please choose future dates and try searching again",
        level: ErrorLevel.ERROR,
        override: false
    },
    [ErrorCodes.INVALID_LOCATION]: {
        code: ErrorCodes.INVALID_LOCATION,
        message: "Oops! A valid location is required. Please enter a location and try searching again.",
        level: ErrorLevel.ERROR,
        override: false
    },
    [ErrorCodes.NO_COMPANY_LOCATIONS]: {
        code: ErrorCodes.NO_COMPANY_LOCATIONS,
        message: "No locations were found. At least 1 company defined location is required to view this content.",
        level: ErrorLevel.ERROR,
        override: false
    },
    [ErrorCodes.INVALID_DATE_RANGE]: {
        code: ErrorCodes.INVALID_DATE_RANGE,
        message: "Oops! A date range is required. Please select a valid range and try searching again.",
        level: ErrorLevel.ERROR,
        override: false
    },
    [ErrorCodes.DATES_PASSED_RANGE_IN_CART]: {
        code: ErrorCodes.DATES_PASSED_RANGE_IN_CART,
        message: "The items in your cart have expired. Please clear your cart to continue browsing!",
        level: ErrorLevel.ERROR,
        override: false
    },
    [ErrorCodes.DATES_UNAVAILABLE]: {
        code: ErrorCodes.DATES_UNAVAILABLE,
        message: "Sorry, it looks like that date isn't available at this location. Please adjust the location or date and try your search again!",
        level: ErrorLevel.ERROR,
        override: false
    },
    [ErrorCodes.NO_TIMESLOTS_AVAILABLE]: {
        code: ErrorCodes.NO_TIMESLOTS_AVAILABLE,
        message: "No available timeslots were found.",
        level: ErrorLevel.ERROR,
        override: false
    },
};