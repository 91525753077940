
<div *ngIf="!isDataAvailable"  class="d-flex text-center justify-content-center" style="padding: 8px;">
  <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>
<div *ngIf="isDataAvailable">

  <ng-container *ngIf="rental?.cartObj?.items && rental?.cartObj?.items.length > 0">
    <div *ngFor="let both of cartAndProducts; let i = index;">
      <div class="border mt-2 ms-1 me-1 p-1" >
        <div class="row" style="display:flex; justify-content:center; align-items:center; align-content:center; text-align:center; padding: 6px;">
          <div class="col-md-1"
            style="display:flex; justify-content:center; align-items:center; align-content:center; text-align:center;">


            <span class="fs-6"><b>
              <a [routerLink]="['/partner/inventory/', both.item.parentId]">#{{both.product.productNumber || both.product.automaticNumber}}</a></b></span>
          </div>
          <div class="col-md-3 fs-6">
            <span><b>{{ both.product.productName }}</b></span>
          </div>
          <div class="col-md-2"
            style="display:flex; justify-content:center; align-items:center; align-content:center; text-align:center;">
            <span class="fs-6 text-primary fw-bold">$ {{both.product.cost.toFixed(2)}} <i class="fas fa-edit hoveredit"
                (click)="editproductPrice(rental, i)"></i> </span>
          </div>
          <div class="col-md-2"
            style="display:flex; justify-content:center; align-items:center; align-content:center; text-align:center;">
            <span class="fs-6"><b>Size:</b> {{both.product.productSize}}</span>
          </div>
          <div class="col-md-2"
            style="display:flex; justify-content:center; align-items:center; align-content:center; text-align:center;">
            <a href="/partner/order/{{ rental.id }}" class="text-light badge bg-danger" *ngIf="rental.isCancelled">Cancelled</a>
            <a href="/partner/order/{{ rental.id }}" class="text-light badge badge-primary" *ngIf="!both.item.isPrepared && !both.item.isCheckedOut || both.item.isPrepared && !both.item.isCheckedOut">Pending Prep</a>
            <a href="/partner/order/{{ rental.id }}" class="text-light badge badge-warning"
              *ngIf="both.item.isCheckedOut && !both.item.isCheckedIn">Checked Out</a>
            <a href="/partner/order/{{ rental.id }}" class="text-light badge badge-warning"
              *ngIf="both.item.isCheckedIn && !rental.isComplete">Checked In</a>
            <a href="/partner/order/{{ rental.id }}" class="text-light badge badge-success"
              *ngIf="rental.isComplete">Booking Complete</a>
            </div>
          <div class="col-md-2"
            style="display:flex; justify-content:center; align-items:center; align-content:center; text-align:center;">
              <ng-container *ngIf="!editExistingRental || editExistingRental &&  i != rentalEditingIndex">
                <div style="margin-right:5px;">
                  <button type="button" class="btn btn-sm btn-success" (click)="editExistingProduct(rental, i)"><i
                    class="fa fa-pencil"></i>
                  </button>
                </div>
                <div>
                  <button type="button" class="btn btn-sm btn-danger" (click)="removeProductFromRental(i)"><i
                      class="fas fa-trash"></i>
                  </button>
                </div>
              </ng-container>

              <div *ngIf="editExistingRental && i == rentalEditingIndex" class="d-flex justify-content-center" >
                <div *ngIf="isLoading">
                  <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
          </div>
          <div class="row" *ngIf="!editExistingRental || editExistingRental &&  i != rentalEditingIndex">
            <div *ngIf="both.product['addonDisplay'] && both.product['addonDisplay'].length > 0">
              <div class="col-lg-1 col-xs-2 col-md-2 d-flex flex-wrap text-align-center align-items-center" >
                <span>Widgets:</span>
              </div>
              <div class="col-lg-11 col-xs-10 col-md-10 d-flex flex-wrap text-align-center align-items-center">
                <div *ngFor="let widg of both.product['addonDisplay']; index as i" style="color:grey">
                  <span>{{widg.title}}</span>
                  <span *ngIf="i != both.product['addonDisplay'].length - 1">,</span> &nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="editExistingRental && i == rentalEditingIndex">
          <app-inventory-page-view [groupInventoryPage]="groupInventoryPage" [companyID]="companyID"
            [selectedProduct]="selectedProduct" [groupName]="rental.cartObj.items[i].productName"
            [numberIndex]="both.product.automaticNumber - 1 || both.product.productNumber - 1"
            [isRentalEdit]="true"
            (addWidgetToProduct)="addWidgetModal($event)"
            (widgetInputChanged)="widgetInputChange()"
            (productInputChanged)="widgetInputChange()"
            (editWidget)="editExisitingWidget($event, groupInventoryPage.widgetList)"
            (deleteWidget)="deleteExisitingWidget($event)">
          </app-inventory-page-view>
        </div>

        <div class="mt-2" *ngIf="editExistingRental && i == rentalEditingIndex">
          <div class="row d-flex align-center text-center" style="padding: 0px 20px;">
            <div class="col-md-6 offset-md-6 mb-2 text-right">
              <div style="padding-top: 5px; padding-bottom: 10px; font-size: 14px">
                Price Changed:
                <span style="margin-left: 5px;">
                  ${{ (groupInventoryPage['totalAddonPrice'] - groupInventoryPage['originalAddonPrice']).toFixed(2) || 0}}
                </span>
                <hr style="margin: 12px 0px">
                Addons Total Price:
                <span style="margin-left: 5px;">
                  ${{ groupInventoryPage['totalAddonPrice'].toFixed(2) || 0}}
                </span>
              </div>
            </div>
          </div>
          <div class="row d-flex" style="padding: 5px;">
            <div class="col-md-3 offset-md-9 text-right mr-2">
                <span style="margin-right: 8px">
                  <button type="button" class="btn btn-secondary" (click)="closeEditExistingProduct()">
                    Cancel
                  </button>
                </span>
                <button type="button" class="btn btn-primary" style="padding: 6px 15px" (click)="saveProductEditChanges(rental, i)">Save</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row border-start border-bottom border-end ms-1 me-1 p-1 accordionitem"
        id="accordion{{i}}{{both.product.productNumber}}">
      </div>
    </div>
  </ng-container>

  <div class="mt-2" *ngIf="!editExistingRental">
    <div *ngIf="!addingNewProduct">
      <a class="text-primary linkaddproduct" style="margin-bottom: 8px !important; padding: 8px" id="linkaddproduct"
        (click)="addNewRentalProductDropdown()"><i class="fas fa-plus-circle"></i>
        Add Product</a>
    </div>
    <div *ngIf="addingNewProduct && !availIsLoading">
      <a class="text-primary" style="margin-bottom: 8px !important; padding: 8px"
        (click)="hideNewRentalProductDisplay()"><i class="fas fa-minus-circle"></i> Hide Add Product</a>
    </div>
  </div>


  <div *ngIf="addingNewProduct && !editExistingRental" style="margin-bottom: 8px;" >
    <div *ngIf="availIsLoading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div *ngIf="!availIsLoading">

      <div *ngIf="availProductsList.length > 0; else noAvailProducts" class="d-inline-flex align-middle" style="margin-left: 5px;">
          <select
            class="form-select"
            name="widgetTypeSelect"
            id=""
            (change)="onSelectedNewProduct()"
            [(ngModel)]="selectedNewProduct">
            <option [ngValue]="null" selected hidden>Select a Product</option>
            <option [ngValue]="choice" *ngFor="let choice of availProductsList">
              {{ choice?.pgName }}, {{ choice?.sizeName }}
            </option>
          </select>
      </div>

      <ng-template #noAvailProducts>
        <span style="color:red; padding: 3px; margin-left: 5px;"><p>No products available to add to rental</p></span>
      </ng-template>

      <div *ngIf="newProductLoading">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="groupInventoryPage && !newProductLoading && !isEditingCartWidgets && !editExistingRental">

        <div *ngIf="groupInventoryPage.widgetList.length > 0">
          <app-inventory-page-view [groupInventoryPage]="groupInventoryPage" [companyID]="companyID"
            [selectedProduct]="selectedNewProduct"
            [isRentalAdd]="true"
            (addWidgetToProduct)="addWidgetModal($event)"
            (widgetInputChanged)="widgetInputChange()"
            (productInputChanged)="widgetInputChange()"
            (editWidget)="editExisitingWidget($event, groupInventoryPage.widgetList)"
            (deleteWidget)="deleteExisitingWidget($event)">
          </app-inventory-page-view>
        </div>

        <div class="row" style="padding-left: 20px;">
          <div class="col-md-6 offset-md-6 mb-2 text-right" style="padding-right: 20px">
            <div style="padding-top: 15px; font-size: 14px">
              Addons Total Price:
              <span style="margin-left: 5px;">
                ${{ groupInventoryPage['totalAddonPrice'] }}
              </span>
            </div>

            <div style="padding-top: 5px; font-size: 14px">
              Product Price:
              <span style="margin-left: 5px;">
                ${{ productPrice }}
              </span>
            </div>

            <hr style="margin: 4px">

            <div style="padding-top: 2px; font-size: 16px">
              Total Price:
              <span style="margin-left: 5px;">
                ${{ groupInventoryPage['totalAddonPrice'] + productPrice }}
              </span>
            </div>
          </div>
        </div>

        <div class="row d-flex" style="padding:5px;">
          <div class="col-md-5 offset-md-7 text-right">
            <span style="margin-right: 8px">
              <button type="button" class="btn btn-secondary" (click)="closeEditExistingProduct()">
                Cancel
              </button>
            </span>
            <button class="btn btn-primary" (click)="addNewProductToRental(selectedNewProduct)">
              Add Product
            </button>
          </div>
        </div>


      </div>
    </div>
  </div>

  <!---- Cart Widgets -->
  <div class="container-fluid mt-3 pb-2" *ngIf="rental.cartObj['cartWidgetList'] && rental.cartObj?.items.length > 0">
    <div
      class="row pt-2 pb-2 rounded-top"
      style="
        background-color: #e0ecff;
        color: #2a0083;
        border: 1px #adcdff solid;
      "
    >
      <div>Cart Widgets</div>
  </div>
  </div>

  <div class="container-fluid" *ngIf="rental.cartObj['cartWidgetList'] && rental.cartObj?.items.length > 0">
    <div class="row d-flex border justify-content-center text-align-center" style="padding: 6px;">

      <div class="col-md-10 d-flex flex-wrap text-align-center align-items-center">
        <ng-container *ngIf="cartWidgetsAddonsDisplay && cartWidgetsAddonsDisplay.length > 0; else noCartWidgetAddonsDisplay">
          <div *ngFor="let widget of cartWidgetsAddonsDisplay; trackBy: trackByTitle; index as i">
            <span>{{widget?.title}}</span>
            <span *ngIf="i != cartWidgetsAddonsDisplay.length - 1">,</span> &nbsp;
          </div>
        </ng-container>

        <ng-template #noCartWidgetAddonsDisplay>
          <span *ngIf="!isEditingCartWidgets">No cart widgets selected</span>
        </ng-template>
      </div>

        <div class="col-md-2" style="display:flex; justify-content:center; align-items:center; align-content:center; text-align:center;"
          *ngIf="!isEditingCartWidgets">
          <button type="button" class="btn btn-sm btn-success" (click)="editCartWidgets(rental)"><i
            class="fa fa-pencil"></i>
          </button>
        </div>
        <div class="col-md-2" style="display:flex; justify-content:center; align-items:center; align-content:center; text-align:center;"
          *ngIf="isEditingCartWidgets">
          <div *ngIf="isLoading">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>

        <div *ngIf="isEditingCartWidgets">
          <app-inventory-page-view [groupInventoryPage]="groupInventoryPage" [companyID]="companyID"
            [selectedProduct]="selectedProduct"
            [isRentalEdit]="true"
            (addWidgetToProduct)="addWidgetModal($event)"
            (widgetInputChanged)="widgetInputChange()"
            (productInputChanged)="widgetInputChange()"
            (editWidget)="editExisitingWidget($event, groupInventoryPage.widgetList)"
            (deleteWidget)="deleteExisitingWidget($event)">
          </app-inventory-page-view>
        </div>

        <div class="mt-2" *ngIf="isEditingCartWidgets">
          <div class="row d-flex align-center text-center" style="padding: 0px 20px;">
            <div class="col-md-6 offset-md-6 mb-2 text-right">
              <div style="padding-top: 5px; padding-bottom: 10px; font-size: 14px">
                Price Changed:
                <span style="margin-left: 5px;">
                  ${{ (groupInventoryPage['totalAddonPrice'] - groupInventoryPage['originalAddonPrice']).toFixed(2) || 0}}
                </span>
                <hr style="margin: 12px 0px">
                Addons Total Price:
                <span style="margin-left: 5px;">
                  ${{ groupInventoryPage['totalAddonPrice'].toFixed(2) || 0}}
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-4 offset-md-8 justify-content-center text-right pb-1">
            <span style="margin-right: 8px">
              <button type="button" class="btn btn-secondary" (click)="closeEditExistingProduct()">
                Cancel
              </button>
            </span>
            <button type="button" class="btn btn-primary" (click)="saveProductEditChanges(rental, null, true)">Save</button>
          </div>
        </div>
    </div>
  </div>

  <div class="row" style="margin-top: 8px;" *ngIf="!editExistingRental && !addingNewProduct && rentalModified && !savingRentalChanges && !isEditingCartWidgets">
    <div class="col-md-4 offset-md-8 mb-2 text-right">
        <div class="d-flex flex-column">
            <span><b>Subtotal: {{rental.subtotal | currency}}</b></span>
            <span *ngIf="rental.discount > 0"><b>Promo Code Discount: -{{rental.discount |
                    currency
                    }}</b></span>
            <span *ngIf="rental.taxes > 0"><b>Taxes & Fees: +{{rental.taxes | currency}}</b></span>
            <hr>
            <span style="color: #1b1e1dee!important;
            font-size: large;" *ngIf="rental.amountPaid !== 0"><b>New Total: {{ rental.cost | currency}}</b></span>
            <span style="color: #1b1e1dee!important;
            font-size: large;" *ngIf="rental.amountPaid !== 0"><b>Total Paid: -{{ rental.amountPaid| currency}}</b></span>
            <span style="color: #1b1e1dee!important;
          font-size: large;"><b>Final Total: {{rental.cost - rental.amountPaid| currency}}</b></span>
        </div>
    </div>
</div>

  <div class="float-right" *ngIf="!editExistingRental && !addingNewProduct && rentalModified && !savingRentalChanges && !isEditingCartWidgets">
    <span style="margin-right: 5px">
      <button class="btn btn-secondary" (click)="onCancelAllChangesToRental()">
        Cancel Changes
      </button>
    </span>
    <button class="btn btn-primary" (click)="onSaveAllChangesToRental()">
      Save Changes
    </button>
  </div>
  <div class="float-right" *ngIf="savingRentalChanges">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <!--Alert to change product price-->
  <div #priceproduct [hidden]="hideEditProductPrice" class="container-fluid mt-2">
    <div class="row g-3">
      <div class="col-6">
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1">Price</span>
          <input type="text" class="form-control" placeholder="Product Price" name="productcost" [(ngModel)]="productcost"
            (keyup)="changeprice()"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
        </div>
      </div>
      <div class="col-6">
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1">Days</span>
          <input type="text" class="form-control" placeholder="Days" name="days" [(ngModel)]="days"
            (keyup)="changeprice()" disabled
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
        </div>
      </div>
      <div class="col-12">
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1">Total</span>
          <input type="text" [ngClass]="{'is-invalid': invalidform}" class="form-control" placeholder="Total"
            name="totalproduct" [(ngModel)]="totalproduct" disabled>
          <span *ngIf="invalidform" class="text-danger fs-6 mt-1"><i class="fas fa-exclamation-circle"></i> The total must
            not be less than or equal to zero.</span>
        </div>
        <div id="emailHelp" class="form-text">Changing the price of the product will also change the total rent. The
          adjusted amount will be added to pending reflecting an additional payment or refund should be made.</div>
      </div>
    </div>
  </div>

  <!--------- Add Widget Modal ---------->

  <div class="modal fade" id="addNewWidgetModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLongTitle">Add New Widget</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeAddWidgetModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="mx-auto" style="margin-bottom: 10px;" *ngIf="widgetStructureListCopy">
            <label for="widgetTypeSelect">Widget Type</label>
            <select
              class="form-select"
              name="widgetTypeSelect"
              id=""
              [(ngModel)]="selectedNewWidget">
              <option [ngValue]="null" selected hidden>Select a Widget Type</option>
              <option [ngValue]="choice" *ngFor="let choice of widgetStructureListCopy">
                {{ choice?.name }}
              </option>
            </select>
          </div>
        <div *ngIf="selectedNewWidget">
          <app-inventory-widget-editor
            [component]="selectedNewWidget"
            [widgetStructureList]="[]"
            [isRentalEdit]="true"
            >
          </app-inventory-widget-editor>
        </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeAddWidgetModal()">Close</button>
          <button type="button" class="btn btn-primary" (click)="addNewWidgetToProduct(selectedNewWidget)">Add widget</button>
        </div>
      </div>
    </div>
  </div>



  <!----- Edit Widget Modal ------->

  <div class="modal fade" id="editExisintWidgetModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLongTitle">Edit Widget</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeEditWidgetModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
        <div *ngIf="selectedNewWidget">
          <app-inventory-widget-editor
            [component]="selectedNewWidget"
            [widgetStructureList]="[]"
            [isRentalEdit]="true"
            >
          </app-inventory-widget-editor>
        </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeEditWidgetModal()">Close</button>
          <button type="button" class="btn btn-primary" (click)="updateExistingWidget(selectedNewWidget)">Update widget</button>
        </div>
      </div>
    </div>
  </div>
