<div class="container-fluid">
  <div class="row align-items-start mt-2">
    <div class="col-md-6 text-left">
      <h1 class="mt-2 mb-2" style="font-size: 32px; cursor: pointer">
        Maintenance
      </h1>
    </div>
    <div class="col-md-6 text-left">
      <div class="row g-2">
        <div
          class="col-md-2 offset-md-1 text-right align-items-right justify-content-right d-flex"
        >
          <label class="form-label" style="color: white">.</label>
        </div>
        <div class="col-md-5">
          <label class="form-label"> Search Products </label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="searchQuery"
            placeholder="Search Name"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card-body p-0 row scrollable">
  <div class="content table-responsive">
    <div class="container-fluid mt-2">
      <nav>
        <div class="nav nav-tabs d-flex flex-column flex-md-row" id="nav-tab" role="tablist">
          <button
            class="nav-link active mb-md-0 position-relative"
            id="nav-all-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-all"
            type="button"
            role="tab"
            aria-controls="nav-all"
            aria-selected="true"
          >
            All<span class="badge bg-warning rounded-circle custom-badge">{{
              _mService.maintenanceQty
            }}</span>
          </button>
          <button
            class="nav-link btn-danger mb-md-0"
            id="nav-damaged-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-damaged"
            type="button"
            role="tab"
            aria-controls="nav-damaged"
            aria-selected="true"
          >
            Damaged
          </button>
          <button
            class="nav-link btn-info mb-md-0"
            id="nav-tune-up-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-tune-up"
            type="button"
            role="tab"
            aria-controls="nav-tune-up"
            aria-selected="true"
          >
            Tune Up
          </button>
          <button
            class="nav-link btn-warning mb-md-0"
            id="nav-cleaning-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-cleaning"
            type="button"
            role="tab"
            aria-controls="nav-cleaning"
            aria-selected="true"
          >
            Cleaning
          </button>
          <button
            class="nav-link btn-secondary mb-2 mb-md-0 ms-md-auto position-relative"
            id="nav-unavailable-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-unavailable"
            type="button"
            role="tab"
            aria-controls="nav-available"
            aria-selected="true"
          >
            Unavailable<span
              class="badge bg-secondary rounded-circle custom-badge"
              >{{ _mService.unavailableProducts }}</span
            >
          </button>
        </div>
      </nav>

      <br />

      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6">
          <span class="icon">
            <mat-icon>add</mat-icon>
          </span>
          <ng-select
            class="placeholderMaint"
            appendTo="body"
            (change)="openModalMaint($event)"
            placeholder="&#160;&#160;&#160;&#160;&#160;&#160;ADD PRODUCT TO MAINTENANCE"
          >
            <ng-option *ngFor="let product of productsselect" [value]="product"
              >{{ product.name }} ({{ product.size }}) #{{ product.number }}
            </ng-option>
          </ng-select>
        </div>
      </div>

      <div class="tab-content mt-2" id="nav-tabContent">
        <div
          class="tab-pane fade show active scrolldiv"
          id="nav-all"
          role="tabpanel"
          aria-labelledby="nav-all-tab"
          tabindex="0"
        >
          <app-product-table
            [productStatus]="'All'"
            [searchQuery]="searchQuery"
          ></app-product-table>
          <!-- <app-products-table [productStatus]="'All'" [searchQuery]="searchQuery"></app-products-table> -->
        </div>
        <div
          class="tab-pane fade scrolldiv"
          id="nav-damaged"
          role="tabpanel"
          aria-labelledby="nav-all-tab"
          tabindex="0"
        >
          <app-product-table
            [productStatus]="'Damaged'"
            [searchQuery]="searchQuery"
          ></app-product-table>
        </div>
        <div
          class="tab-pane fade scrolldiv"
          id="nav-tune-up"
          role="tabpanel"
          aria-labelledby="nav-all-tab"
          tabindex="0"
        >
          <app-product-table
            [productStatus]="'Tune Up'"
            [searchQuery]="searchQuery"
          ></app-product-table>
        </div>
        <div
          class="tab-pane fade scrolldiv"
          id="nav-cleaning"
          role="tabpanel"
          aria-labelledby="nav-all-tab"
          tabindex="0"
        >
          <app-product-table
            [productStatus]="'Cleaning'"
            [searchQuery]="searchQuery"
          ></app-product-table>
        </div>
        <div
          class="tab-pane fade scrolldiv"
          id="nav-available"
          role="tabpanel"
          aria-labelledby="nav-all-tab"
          tabindex="0"
        >
          <app-product-table
            [productStatus]="'Available'"
            [searchQuery]="searchQuery"
          ></app-product-table>
        </div>
        <div
          class="tab-pane fade scrolldiv"
          id="nav-unavailable"
          role="tabpanel"
          aria-labelledby="nav-all-tab"
          tabindex="0"
        >
          <app-product-table
            [productStatus]="'Unavailable'"
            [searchQuery]="searchQuery"
          ></app-product-table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="modalMaintenance"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modalTitleId"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="modalTitleId">{{ productName }}</h3>
        <button
          type="button"
          class="btn-close"
          (click)="closeModalMaint()"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row">
            <div class="row align-items-start mt-2 fs-6">
              <div class="col text-left fw-bolder ml-2">
                Type of Maintenance:
              </div>
            </div>
            <div class="row mt-2 ml-2">
              <div class="col-4">
                <div class="form-group-sm mb-1 input-group-sm">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="maintenanceInCleaning"
                      name="isCleaning1"
                      (click)="isInvalidMaint()"
                      [ngClass]="isInvalid ? 'is-invalid' : ''"
                      [(ngModel)]="isCleaning"
                      [ngModelOptions]="{ standalone: true }"
                    />
                    <label class="form-check-label" for="maintenanceInCleaning">
                      In Cleaning
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group-sm mb-1 input-group-sm">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="maintenanceTuneUp"
                      name="isTuneUp1"
                      (click)="isInvalidMaint()"
                      [ngClass]="isInvalid ? 'is-invalid' : ''"
                      [(ngModel)]="isTuneUp"
                      [ngModelOptions]="{ standalone: true }"
                    />
                    <label class="form-check-label" for="maintenanceTuneUp">
                      Tune-Up
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group-sm mb-1 input-group-sm">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="maintenanceDamaged"
                      name="isDamaged1"
                      (click)="isInvalidMaint()"
                      [ngClass]="isInvalid ? 'is-invalid' : ''"
                      [(ngModel)]="isDamaged"
                      [ngModelOptions]="{ standalone: true }"
                    />
                    <label class="form-check-label" for="maintenanceDamaged">
                      Damaged
                    </label>
                  </div>
                </div>
              </div>
              <div class="text-danger" *ngIf="isInvalid">
                At least one checkbox is required.
              </div>
            </div>
          </div>
          <div class="row mt-2 ml-2">
            <div class="form-group-sm mb-1 input-group-sm">
              <textarea
                name="description"
                id="description"
                class="form form-control"
                placeholder="Provide a detailed reason for maintenance..."
                cols="30"
                rows="3"
                (keyup)="isInvalidReasonMaint($event)"
                [ngClass]="isInvalidDesc ? 'is-invalid' : ''"
                [(ngModel)]="description"
                [ngModelOptions]="{ standalone: true }"
              ></textarea>
              <div class="text-danger" *ngIf="isInvalidDesc">
                Reason for maintenance is required.
              </div>
            </div>
          </div>
          <div class="row">
            <div class="row align-items-start mt-2 fs-6">
              <div class="col text-left fw-bolder ml-2">
                <label class="mb-1" for="notes">Product availability:</label>
              </div>
            </div>
            <div class="row mt-2 ml-2">
              <div class="col-2"></div>
              <div class="col-4">
                <div class="form-group-sm mb-1 input-group-sm">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      value="yes"
                      id="retiredyes"
                      name="retiredradio"
                      [checked]="isAvailable"
                      (change)="availableDiv(true)"
                    />
                    <label class="form-check-label" for="retiredyes">
                      Available
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group-sm mb-1 input-group-sm">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      value="no"
                      id="retiredno"
                      name="retiredradio"
                      [checked]="!isAvailable"
                      (change)="availableDiv(false)"
                    />
                    <label class="form-check-label" for="retiredno">
                      Unavailable
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-2"></div>
            </div>
          </div>
          <ng-container *ngIf="!isAvailable">
            <div class="row mt-2 ml-2">
              <div class="form-group-sm mb-1 input-group-sm">
                <textarea
                  name="reasonretire"
                  class="form form-control"
                  placeholder="Provide a detailed reason for Availability change..."
                  cols="30"
                  rows="3"
                  (keyup)="isInvalidReasonUnav($event)"
                  [ngClass]="isInvalidUnv ? 'is-invalid' : ''"
                  [(ngModel)]="unavailableReason"
                  [ngModelOptions]="{ standalone: true }"
                ></textarea>
                <div class="text-danger" *ngIf="isInvalidUnv">
                  Detailed reason for Availability change is required.
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeModalMaint()"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="addProductManint()"
        >
          Add maint.
        </button>
      </div>
    </div>
  </div>
</div>
