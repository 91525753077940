import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeleteConfirmationService {

  getInfo = new Subject();
  deleteConfirmed = new Subject();

  constructor() {

  }

  giveInfoToDeleteConfirmation(info){
    this.getInfo.next(info);
    return;
  }

  validationPassed(id){
    this.deleteConfirmed.next(id);
  }
}
