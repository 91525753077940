<!-- Formulario de inicio de sesion  centrado a lo largo y ancho de la pantalla -->
<div class="row">
  <div class="login-page">
    <div class="form">
      <h4 class="text-center pt-0 mt-0 pb-2"><i class="fa fa-lock"></i> Member Login</h4>
      <form class="login-form" [formGroup]="loginForm" (ngSubmit)="login()">
        <h5 *ngIf="confirm" class="text-center">Reset Password</h5>
        <input  [(ngModel)]="email" type="email" autocomplete="off" formControlName="email" name="email"
               placeholder="username"/>
        <input *ngIf="!confirm && !checkEmail" type="password" autocomplete="off" formControlName="password"
               name="password" placeholder="password"/>
        <!-- spinner de carga -->
        <div class="spinner-border text-primary" role="status" *ngIf="isProgressVisible">
          <span class="sr-only">Loading...</span>
        </div>
        <p *ngIf="firebaseErrorMessage && !confirm && !checkEmail"
           class="alert alert-danger mt-2">{{firebaseErrorMessage}}</p>
        <p
          *ngIf="firebaseErrorMessage && !checkEmail && !confirm && firebaseErrorMessage != 'There is no user record corresponding to this identifier. The user may have been deleted.' && firebaseErrorMessage != 'The email address is badly formatted.'">
          Forget your password? <span id="linkLike" (click)="resetPassword()">Reset Here</span></p>
        <p *ngIf="checkEmail" class='alert alert-success'>Reset link has been seen to this address. Please check email
          to continue.</p>
        <button *ngIf="checkEmail" class="btn btn-block btn-info" (click)="return()">Return</button>
        <button *ngIf="confirm" class="btn btn-block btn-secondary" (click)="return()">Cancel</button>
        <button *ngIf="confirm" class="btn btn-block btn-info" (click)="confirmReset()">Confirm Email</button>
        <input *ngIf="!isProgressVisible && !confirm && !checkEmail" type="submit" value="Login" class="button">

        <!-- <p class="message">Not registered? <a href="#" routerLink="/register">Create an account</a></p> -->
        <!--      <p *ngIf="loginError && !confirm && !checkEmail" class="alert alert-danger mt-2">{{loginError}}</p>-->
        <!--      <p *ngIf="loginError && !checkEmail && !confirm && loginError != 'There is no user record corresponding to this identifier. The user may have been deleted.' && loginError != 'The email address is badly formatted.'"> Forget your password? <span id="linkLike" (click)="resetPassword()">Reset Here</span> </p>-->
        <!--      <p *ngIf="checkEmail" class='alert alert-success'>Reset link has been seen to this address. Please check email to continue.</p>-->
        <!--      <button *ngIf="confirm" class="btn btn-block btn-info" (click)="confirmReset()">Confirm Email</button>-->
        <!--      <button *ngIf="checkEmail" class="btn btn-block btn-success" (click)="resetLogin()">Login</button>-->
      </form>
    </div>

  </div>
</div>