import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FunctionService {



  constructor() { }

  appleString = "hi"

  fullAddress(address: any, shipTo: boolean = true): string{
    var addressAll = ""

    if (address.city == undefined){
      return null;
    }

    if (address.shipTo && shipTo){
      addressAll = address.shipTo + " - " + address.address
    } else{
      addressAll = address.address;
    }
    
    if (address.address2){
      addressAll += " " + address.address2;
    }
    if (address.address3){
      addressAll += ", " + address.address3;
    }

    addressAll += ", " + address.city + ", " + address.state + ", " + " " + address.zip;

    if (address.country){
      addressAll += ", " + address.country;
    }
    return addressAll;
  }

}
