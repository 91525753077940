<!-- Header -->
<div class="container-fluid">
    <div class="row mb-2 mt-2">
        <!-- Title -->
        <div class="row align-items-start">
            <div class="col-md-12 text-left mt-2 mb-2">
                <h1 style="font-size: 32px;">
                    Booking Suite
                </h1>
            </div>
        </div>

        <!-- Back / Add - Buttons -->
        <div class="d-flex justify-content-between">
            <div>
                <button type="button" class="btn btn-success" (click)="navigateToBookingSuite()"><i
                        class="fa fa-arrow-left" aria-hidden="true"></i>Back</button>
                <button type="button" class="btn btn-success ml-2" data-bs-toggle="modal"
                    data-bs-target="#addModal">Add</button>
            </div>

            <!-- Template Options -->
            <div>
                <div>
                    <button (click)="save()" class="btn btn-warning mr-2" type="button">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Add Modal -->
<div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="addModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <!-- Header -->
            <div class="modal-header">
                <h2 *ngIf="!addProduct">Add to Template | Page</h2>
                <h2 *ngIf="addProduct">Add to Template | Product</h2>
                <button type="button" class="btn btn-danger" (click)="closeAddModal()" aria-label="Close">
                    <i class="fa fa-close"></i>
                </button>
            </div>
            <div class="btn-group btn-group-sm mt-4 ml-3" style="margin-right: 50em;" role="group"
                aria-label="Basic example">
                <button (click)="addProduct = true" type="button" class="btn"
                    [ngClass]="{'btn-outline-primary': !addProduct, 'btn-primary' : addProduct}">Products</button>
                <button (click)="addProduct = false" type="button" class="btn"
                    [ngClass]="{'btn-outline-primary': addProduct, 'btn-primary' : !addProduct}">Pages</button>
            </div>

            <!-- Search Pipe -->
            <div class="col-lg-6 mt-2">
                <!-- <label class="form-label">Search By Title</label> -->
                <input class="form-control" type="text" placeholder="Type to search..."
                    (keyup)="search(searchBox.value)" #searchBox>
            </div>

            <!-- Product Section -->
            <div class="modal-body" [ngStyle]="addProduct ? {'display': 'block'} : {'display': 'none'}">
                <p>Select which products to add to your template with the checkboxes below</p>
                <span style="margin-top: 0.25rem; font-size: .875em; color: #dc3545; margin-bottom: 1em;"
                    *ngIf="selectionListEmpty">At least one product or page must be selected</span>
                <div class="row align-items-start mt-2 fs-6 bg-light" style="padding-left: 10px; padding-right: 10px;">
                    <div class="mt-2">
                        <div class="row listHeaders">
                            <div class="col-4">Product Name</div>
                            <div class="col-4">Location</div>
                            <div class="col-4 text-center">Select</div>
                        </div>

                        <!-- ProductGroup List -->
                        <div class="list" #productGroupList
                            style="max-height: 30em; overflow-y: auto; overflow-x: hidden;">
                            <form [formGroup]="productGroupForm">
                                <div *ngFor="let productGroup of productGroups | productNameFilter: searchValue"
                                    class="row mt-1 py-2">
                                    <div class="col-4">{{productGroup.groupName}}</div>
                                    <div class="col-4"></div>
                                    <div class="col-4" style="position: relative;"><input
                                            style="position: absolute; left: 50%; bottom: 50%; width: 14px; height: 14px;"
                                            type="checkbox" formControlName="{{ productGroup.id }}">
                                    </div>
                                </div>
                            </form>
                        </div>

                        <!-- No matching search -->
                        <div *ngIf="(productGroups| productNameFilter: searchValue)?.length <= 0" class="mt-2 mb-2">
                            <span>No products match your search</span>
                        </div>

                        <!-- No Products found in db -->
                        <div *ngIf="productGroups == undefined" class="mt-2 mb-2">
                            <span>No products found. Would you like to create one? <span (click)="navigateToInventory()"
                                    class="link">Go to inventory</span></span>
                        </div>
                    </div>
                </div>

                <!-- Buttons -->
                <div class="mt-3 d-flex" style="float: right;">
                    <button style="margin-right: 0.7em;" type="button" class="btn btn-success"
                        (click)="addToTemplate()">
                        Add to Template
                    </button>
                </div>
            </div>


            <!-- Page Section -->
            <div class="modal-body" [ngStyle]="!addProduct ? {'display': 'block'} : {'display': 'none'}">
                <p>Select which pages to add to your template with the checkboxes below</p>
                <span style="margin-top: 0.25rem; font-size: .875em; color: #dc3545; margin-bottom: 1em;"
                    *ngIf="selectionListEmpty">At least one page or product must be selected</span>
                <div class="row align-items-start mt-2 fs-6 bg-light" style="padding-left: 10px; padding-right: 10px;">
                    <div class="mt-2">
                        <div class="row listHeaders">
                            <div class="col-4">Page Title</div>
                            <div class="col-4">Attached Pages</div>
                            <div class="col-4 text-center">Select</div>
                        </div>

                        <!-- Page List -->
                        <div class="list" #pageList style="max-height: 30em; overflow-y: auto; overflow-x: hidden;">
                            <form [formGroup]="pageForm">
                                <div *ngFor="let page of pages | booking: searchValue" class="row mt-1 py-2">
                                    <div class="col-4">{{ page.title }}</div>
                                    <div class="col-4">{{ page.content.length }}</div>
                                    <div class="col-4" style="position: relative;">
                                        <input
                                            style="position: absolute; left: 50%; bottom: 50%; width: 14px; height: 14px;"
                                            type="checkbox" formControlName="{{ page.id }}" />
                                    </div>
                                </div>
                            </form>
                        </div>

                        <!-- No matching search -->
                        <div *ngIf="(pages | booking: searchValue)?.length <= 0 && searchValue != ''" class="mt-2 mb-2">
                            <span>No pages match your search</span>
                        </div>

                        <!-- No pages found -->
                        <div *ngIf="pages == undefined" class="mt-2 mb-2">
                            <span>No pages found. <span (click)="createNewPage()" class="link">Click to
                                    Create</span></span>
                        </div>

                        <!-- No pages left / available to use on template still -->
                        <div *ngIf="pages != undefined">
                            <div *ngIf="pages.length <= 0  && searchValue == ''" class="mt-2 mb-2">
                                <span>No useable pages found for this template. <span (click)="createNewPage()"
                                        class="link">Click to Create</span></span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Buttons -->
                <div class="mt-3 d-flex justify-content-between">
                    <div>
                        <span (click)="createNewPage()" class="btn btn-link"> Create a new page</span>
                    </div>
                    <button style="margin-right: 0.7em;" type="button" class="btn btn-success"
                        (click)="addToTemplate()">
                        Add to Template
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>