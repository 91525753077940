import {
  Directive,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  HostListener
} from '@angular/core';
import { Subject } from 'rxjs';
import {
  takeUntil,
  debounceTime,
  distinctUntilChanged,
  tap,
} from 'rxjs/operators';

@Directive({
  selector: '[appDebounce]',
})
export class DebounceDirective {

  @HostListener('input', ['$event'])
  public change(event: any): void {
    this.emitEvent$.next(event)
  }

  @Input() public debounceTime: number;

  @Output() public onEvent: EventEmitter<any>;

  protected emitEvent$: Subject<any>;
  protected subscription$: Subject<void>;

  constructor() {
    this.debounceTime = 700;
    this.onEvent = new EventEmitter<any>();
    this.emitEvent$ = new Subject<any>();
    this.subscription$ = new Subject<void>();
  }

  ngOnInit(): void {
    console.log('init')
    this.emitEvent$
      .pipe(
        takeUntil(this.subscription$),
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        tap((value) => { this.emitChange(value); console.log('debouncing') })
      )
      .subscribe();
  }

  public emitChange(value: any): void {
    console.log(value)
    this.onEvent.emit(value);
  }

  ngOnDestroy(): void {
    this.subscription$.next();
    this.subscription$.complete();
  }



}
