import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { ProductV1 } from 'src/app/models/storage/product.model';
import { LogService } from 'src/app/services/log.service';
import { ProductsService } from 'src/app/services/products.service';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { ColorTheme } from 'src/app/services/colorTheme.service';
/**
 * @description Component that displays a table of products with maintenance data.
 */
@Component({
  selector: 'app-product-table',
  templateUrl: './product-table.component.html',
  styleUrls: ['./product-table.component.scss'],
})
export class ProductTableComponent implements OnInit, OnDestroy {
  @Input() public productStatus: string = 'All';
  @Input() public searchQuery: string = '';
  @Output() productSelected: string = '';
  private destroy$: Subject<void> = new Subject<void>();
  public isNameAscending = true;
  public isSizeAscending = true;
  public loading: boolean = true;
  public modalOpen: any[] = [];
  public products: any[] = [];
  public sortBy: string = 'name';
  public isRebuild: boolean = false;
  private tempProductObj = {};
  private staticProducts: any;
  private currentEditingProductIndex = -1;
  ColorIdus: string;
  ThemeSelect: string;
  ThemeText: string;

  /**
   * Constructs a new instance of the class.
   * @param _pService The ProductsService instance used for product-related operations.
   * @param _lService The LogService instance used for product-related operations.
   * @param $afFun The AngularFireFunctions instance used for Firebase Cloud Functions.
   */
  constructor(
    private _pService: ProductsService,
    private _lService: LogService,
    private $afFun: AngularFireFunctions,
    private _userCurrent: CurrentUserService,
    private _ColorTheme: ColorTheme
  ) {}

  /**
   * @description Lifecycle hook that is called after the component has been initialized.
   * It retrieves all products and their maintenance data upon initialization.
   */
  ngOnInit(): void {
    this.getAllProducts();
    this.fntColortheme();
    this.isRebuild = this._userCurrent.currentUser.currentCompany.isRebuild;
  }

  /**
   * @description Lifecycle hook that is called when the component is about to be destroyed.
   * It performs any necessary cleanup operations.
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * @description Toggles the editing mode for maintenance data of a product.
   * @param {any} p - Product object.
   * @param {boolean} isConfirmed - Indicates if the changes are confirmed.
   * @param {boolean} isCancelled - Indicates if the changes are cancelled.
   */
  async toggleEditMaintenance(
    p: any,
    i: number,
    isConfirmed?: boolean,
    isCancelled?: boolean
  ): Promise<void> {
    p.isEditing = !p.isEditing;
    if (
      this.currentEditingProductIndex !== -1 &&
      this.currentEditingProductIndex !== i
    ) {
      this.cancelEdit(p, this.currentEditingProductIndex);
    }
    if (isConfirmed) {
      const cProduct = this.tempProductObj[p.id];
      console.log('cProduct -->', cProduct);

      await this.checkLogProcess(cProduct, p.id);
      cProduct.id = p.id;
      if (!cProduct.isDamaged && !cProduct.isTuneUp && !cProduct.isCleaning) {
        cProduct.isMaintenance = false;
      } else {
        cProduct.isMaintenance = true;
      }
      this._pService.updateMaintenanceProduct(cProduct);
    } else if (isCancelled) {
      this.cancelEdit(p, i);
    }

    this.currentEditingProductIndex = i;
  }

  cancelEdit(p, index) {
    const product = this.getProducts()[index];
    product.isEditing = false;
    this.currentEditingProductIndex = -1;
    const staticP = JSON.parse(this.staticProducts);
    const cProduct = staticP.find((sp: any) => sp.id === product.id);

    product.isMaintenance = cProduct.isMaintenance;
    product.isTuneUp = cProduct.isTuneUp;
    product.isDamaged = cProduct.isDamaged;
    product.isAvailable = cProduct.isAvailable;
  }

  onCheckboxChange(p: any, propertyName: string, newValue: any): void {
    if (!this.tempProductObj[p.id]) {
      this.tempProductObj[p.id] = {
        isDamaged: p.isDamaged,
        isAvailable: p.isAvailable,
        isTuneUp: p.isTuneUp,
        isMaintenance: p.isMaintenance,
        isCleaning: p.isCleaning,
        isActive: p.isActive,
      };
    }

    const value = newValue.target.checked;
    console.log('nuevo valor', propertyName, newValue.target.checked);

    switch (propertyName) {
      case 'isTuneUp':
        this.tempProductObj[p.id].isTuneUp = value;
        break;
      case 'isMaintenance':
        this.tempProductObj[p.id].isMaintenance = !value;
        break;
      case 'isCleaning':
        this.tempProductObj[p.id].isCleaning = value;
        break;
      case 'isDamaged':
        this.tempProductObj[p.id].isDamaged = value;
        break;
      case 'isAvailable':
        this.tempProductObj[p.id].isAvailable = !value;
        this.tempProductObj[p.id].isActive = !value;
        break;
    }

    console.log('tempProductObj -->', this.tempProductObj);
  }
  /**
   * Checks the changes in the product properties and logs the corresponding maintenance actions.
   * @param p The product object.
   * @returns A Promise that resolves to a boolean value indicating the success of the process.
   */
  checkLogProcess(p: any, productID: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        const staticP = JSON.parse(this.staticProducts);
        const currentProduct = staticP.find((sp: any) => sp.id === productID);

        console.log(p, currentProduct);

        // Check if Maintenance property has changed
        if (
          p.isMaintenance &&
          currentProduct.isMaintenance !== p.isMaintenance
        ) {
          this._lService.addMaintenanceLog(
            p.id,
            `Maintenance changed to ${p.isMaintenance}`
          );
        }

        // Check if TuneUp property has changed
        if (p.isTuneUp && currentProduct.isTuneUp !== p.isTuneUp) {
          this._lService.addMaintenanceLog(
            p.id,
            `Tune Up changed to ${p.isTuneUp}`
          );
        }

        // Check if Damaged property has changed
        if (p.isDamaged && currentProduct.isDamaged !== p.isDamaged) {
          this._lService.addMaintenanceLog(
            p.id,
            `Damaged changed to ${p.isDamaged}`
          );
        }

        // Check if Cleaning property has changed
        if (p.isCleaning && currentProduct.isCleaning !== p.isCleaning) {
          this._lService.addMaintenanceLog(
            p.id,
            `Cleaning changed to ${p.isCleaning}`
          );
        }

        // Check if Active property has changed
        if (p.isAvailable && currentProduct.isAvailable !== p.isAvailable) {
          this._lService.addMaintenanceLog(
            p.id,
            `Available changed to ${p.isAvailable}`
          );
        }

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Opens the history modal for a specific item.
   * @param item - The item for which the history modal is being opened.
   */
  openHistoryModal(item: any): void {
    const modalName = `#pHistory${item.id}${this.productStatus.replace(
      ' ',
      ''
    )}`;
    this.modalOpen[item.id] = true;
    $(modalName).modal({ backdrop: 'static', keyboard: false });
    $(modalName).modal('show');

    console.log('Abriendo', modalName);
  }

  /**
   * Closes the history modal for a specific item.
   * @param id - The ID of the item for which the history modal is being closed.
   */
  closeModal(id: any): void {
    const modalName = `#pHistory${id}${this.productStatus.replace(' ', '')}`;
    this.modalOpen[id] = false;
    $(modalName).modal('hide');
  }
  /**
   * Retrieves all products, including maintenance products and unavailable products.
   * Combines the results from 'getMaintenanceProducts()' and 'getUnavailableProducts()'
   * using 'combineLatest()' to ensure both requests have completed before processing the data.
   * Transforms the retrieved products by adding the 'isEditing' property to each product.
   * Removes duplicate products and filters the remaining products based on specific criteria.
   * Finally, assigns the resulting products to the 'products' array and performs additional operations.
   * Calls 'getProducts()' to perform any necessary actions related to the retrieved products.
   */
  getAllProducts(): void {
    const maintenanceProducts$ = this._pService.getMaintenanceProducts();
    const unavailableProducts$ = this._pService.getUnavailableProducts();

    combineLatest([maintenanceProducts$, unavailableProducts$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ([maintenanceProducts, unavailableProducts]: [
          ProductV1[],
          ProductV1[]
        ]) => {
          const newMaintenanceProducts = maintenanceProducts.map((product) => {
            return { ...product, isEditing: false };
          });

          const newUnavailableProducts = unavailableProducts.map((product) => {
            return { ...product, isEditing: false };
          });

          const combinedProducts = [
            ...newMaintenanceProducts,
            ...newUnavailableProducts,
          ];
          const uniqueProducts = this.removeDuplicates(combinedProducts, 'id');
          this.staticProducts = JSON.stringify(uniqueProducts);
          const filteredProducts = this.filterProductBy(uniqueProducts);

          this.loading = false;
          this.products = filteredProducts;
          this.products = this.getProducts();
        }
      );
  }

  /**
   * Removes duplicate items from an array based on a specified unique property.
   * Takes an array ('arr') and the name of the property ('uniqueProperty') that should be unique for each item.
   * Utilizes a 'Set' data structure to track unique property values and creates a new array with no duplicates.
   * Returns the array with duplicate items removed.
   *
   * @param arr The array from which duplicate items should be removed.
   * @param uniqueProperty The name of the unique property used to determine duplicates.
   * @returns An array with no duplicate items.
   */
  removeDuplicates(arr: any[], uniqueProperty: string): any[] {
    const uniqueValues = new Set();
    const uniqueArr = [];

    arr.forEach((item) => {
      const propertyValue = item[uniqueProperty];
      if (!uniqueValues.has(propertyValue)) {
        uniqueValues.add(propertyValue);
        uniqueArr.push(item);
      }
    });

    return uniqueArr;
  }

  /**
   * @description Filters and sorts products based on the search query and sort options.
   * @returns {ProductV1[] | null} - Array of filtered and sorted products.
   */
  getProducts(): ProductV1[] | null {
    this.loading = true;

    const filteredProducts = this.sortProductBy(this.products);
    const sortedProducts = this.filterProductBy(filteredProducts);
    this.loading = false;
    return sortedProducts;
  }

  /**
   * @description Sorts the products based on the selected sort option.
   * @param {ProductV1[]} products - Array of products to be sorted.
   * @returns {ProductV1[]} - Sorted array of products.
   */
  sortProductBy(products: ProductV1[]): ProductV1[] {
    switch (this.sortBy) {
      case 'name':
        return products.sort((a, b) => {
          const nameA = a.productName.trim().toUpperCase();
          const nameB = b.productName.trim().toUpperCase();

          if (nameA < nameB) {
            return this.isNameAscending ? -1 : 1;
          }
          if (nameA > nameB) {
            return this.isNameAscending ? 1 : -1;
          }
          return 0;
        });
      case 'size':
        return products.sort((a, b) => {
          const sizeA = a.productSize.trim().toUpperCase();
          const sizeB = b.productSize.trim().toUpperCase();

          if (sizeA < sizeB) {
            return this.isSizeAscending ? -1 : 1;
          }
          if (sizeA > sizeB) {
            return this.isSizeAscending ? 1 : -1;
          }
          return 0;
        });
      default:
        return products;
    }
  }

  /**
   * @description Filters the products based on the selected product status.
   * @param {ProductV1[]} products - Array of products to be filtered.
   * @returns {ProductV1[]} - Filtered array of products.
   */
  filterProductBy(products: ProductV1[]): ProductV1[] {
    const filterConfig = {
      All: (p: ProductV1) => !p.isRetired && p.isMaintenance,
      Damaged: (p: ProductV1) => p.isDamaged && p.isMaintenance,
      'Tune Up': (p: ProductV1) => p.isTuneUp && p.isMaintenance,
      Cleaning: (p: ProductV1) => p.isCleaning && p.isMaintenance,
      Available: (p: ProductV1) =>
        p.isAvailable && !p.isRetired && p.isMaintenance,
      Unavailable: (p: ProductV1) => !p.isAvailable,
    };

    const filterFunction = filterConfig[this.productStatus] || filterConfig.All;

    let filteredProducts = products.filter(filterFunction);

    return filteredProducts.filter((p: ProductV1) =>
      p.productName.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  /**
   * @description Toggles the sort order for the selected sort option.
   * @param {string} sortBy - Sort option to toggle the order for.
   */
  toggleOrder(sortBy: string): void {
    switch (sortBy) {
      case 'name':
        this.isNameAscending = !this.isNameAscending;
        break;
      case 'size':
        this.isSizeAscending = !this.isSizeAscending;
        break;
      default:
        break;
    }
    this.sortBy = sortBy;
  }

  /**
   * Edits the products array by setting the isEditing property to false for all products that do not match the given productID.
   * @param {ProductV1[]} products - The array of products.
   * @param {string} productID - The ID of the product to match.
   * @returns {ProductV1[]} - The modified array of products.
   */
  editProduct(products, productID) {
    const productIndex = products.findIndex((p) => p.id === productID);

    return products.map((p, index) => {
      return { ...p, isEditing: index === productIndex }; // Keep the product unchanged if it matches productID
    });
  }

  fntColortheme() {
    this.ColorIdus = this._userCurrent.currentUser.id;
    this._ColorTheme
      .getcolorPeruser(this.ColorIdus)
      .then((selectors) => {
        let objsThemes = {
          background: selectors.color.value,
          color: selectors.color.fontcolor,
        };
        this.ThemeSelect = objsThemes.background;
        this.ThemeText = objsThemes.color;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
