import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ToastrService } from 'ngx-toastr';
import { NameValue } from 'src/app/models/name-value.model';
import { DataAddressService } from 'src/app/services/data/data-address.service';
import { FunctionService } from 'src/app/services/function.service';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/storage/user.model';
import { Router } from '@angular/router';
import { CurrentUserService } from '../../services/current-user.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import Swal from 'sweetalert2';
import { Company } from 'src/app/models/storage/company.model';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CustomValidators } from '../../utils/custom-validatos.component';
import { ColorTheme } from 'src/app/services/colorTheme.service';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent implements OnInit {
  isCompany: boolean = false;
  userForm: UntypedFormGroup;
  public submitted: boolean = false;

  constructor(
    public toastr: ToastrService,
    private afFun: AngularFireFunctions,
    private _addressData: DataAddressService,
    private functionService: FunctionService,
    private userService: UserService,
    private afs: AngularFirestore,
    private router: Router,
    public _currentUser: CurrentUserService,
    private fb: UntypedFormBuilder,
    public colorTh: ColorTheme
  ) {
        this.userForm = new UntypedFormGroup(
      {
        accountType: new UntypedFormControl('', [Validators.required]),
        email: new UntypedFormControl('', [
          Validators.required,
          Validators.email,
        ]),
        password: new UntypedFormControl('', [
          Validators.required,
          Validators.minLength(6),
        ]),
        confirmPassword: new UntypedFormControl('', [Validators.required]),
        phone: new UntypedFormControl('', [
          Validators.required /*, Validators.pattern('^[0-9]+$')*/,
        ]),
        fname: new UntypedFormControl('', [Validators.required]),
        lname: new UntypedFormControl('', [Validators.required]),
        cname: new UntypedFormControl(''),
      },
      // @ts-ignore
      CustomValidators.mustMatch('password', 'confirmPassword')
    );
  }

  get f() {
    return this.userForm.controls;
  }

  public isCreated = false;
  public isCreating = false;

  //inputs
  public email = '';
  public accountType = 'Employee';
  public password = '';
  public repeatPassword = '';
  public fname = '';
  public lname = '';
  public phone = '';
  public cname = '';
  public shippingAddress: any = { country: 'US' };
  public billingAddress: any = { country: 'US' };
  public country = '';
  public currentStatesShipping: NameValue[] = [];
  public currentStatesBilling: NameValue[] = [];

  //alerts
  public isEmailAlert = false;
  public isAccountTypeAlert = false;
  public isPasswordAlert = false;
  public isFnameAlert = false;
  public isLnameAlert = false;
  public isPhoneAlert = false;
  public isShippingAddressAlert = false;
  public isShippingCityAlert = false;
  public isShippingStateAlert = false;
  public isShippingZipAlert = false;
  public isShippingCountryAlert = false;
  public isBillingAddressAlert = false;
  public isBillingCityAlert = false;
  public isBillingStateAlert = false;
  public isBillingZipAlert = false;
  public isBillingCountryAlert = false;
  public isCNameAlert = false;
  iSTheId: string;
  public ColorPt: string;
  public ColorTx: string;

  // flags
  public isEmployee: boolean = false;
  isAdminOrDev: boolean = false;

  public isBillingSameAsShipping = false;

  // step

  public currentStep = 'step1';

  ngOnInit(): void {
    if (this._currentUser.currentUser.levelType === 'Employee') {
      this.isEmployee = true;
    }
    if (
      this._currentUser.currentUser.isAdmin ||
      this._currentUser.currentUser.isDeveloper
    ) {
      this.isAdminOrDev = true;
    }
    console.log(this._currentUser.currentUser);
    this.country = 'US';
    this.getStatesForCountry(this.country);
    this.ColorTe();
  }

  ColorTe() {
    this.iSTheId = this._currentUser.currentUser.id;
    this.colorTh
      .getcolorPeruser(this.iSTheId)
      .then((obj) => {
        let colors = {
          colorTheme: obj.color.value,
          colorText: obj.color.fontcolor,
        };
        this.ColorPt = colors.colorTheme;
        this.ColorTx = colors.colorText;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  resetFormValidation() {
    console.log('Hola');
    this.submitted = false;
  }
  reset() {
    this.email = '';
    this.password = '';
    this.repeatPassword = '';
    this.fname = '';
    this.lname = '';
    this.phone = '';
    this.isEmailAlert = false;
    this.isPasswordAlert = false;
    this.isFnameAlert = false;
    this.isLnameAlert = false;
    this.isPhoneAlert = false;
    this.isShippingAddressAlert = false;
    this.isShippingCityAlert = false;
    this.isShippingStateAlert = false;
    this.isShippingZipAlert = false;
    this.isShippingCountryAlert = false;
    this.isBillingAddressAlert = false;
    this.isBillingCityAlert = false;
    this.isBillingStateAlert = false;
    this.isBillingZipAlert = false;
    this.isBillingCountryAlert = false;
    this.isBillingSameAsShipping = false;
    this.isCNameAlert = false;
    this.currentStep = 'step1';
    this.shippingAddress = { country: 'US' };
    this.billingAddress = { country: 'US' };

    this.country = 'US';
    this.getStatesForCountry(this.country);
    this.isCreating = false;
  }

  getStatesForCountry(country: string, type?: string) {
    if (type === 'shipping' && !this.isBillingSameAsShipping) {
      this.currentStatesShipping =
        this._addressData.getStatesForCountry(country);
    } else if (type === 'billing' && !this.isBillingSameAsShipping) {
      this.currentStatesBilling =
        this._addressData.getStatesForCountry(country);
    } else {
      this.currentStatesShipping =
        this._addressData.getStatesForCountry(country);
      this.currentStatesBilling =
        this._addressData.getStatesForCountry(country);
    }
  }

  getCountry(event: any, type: string) {
    var countryValue = event.srcElement.selectedOptions[0].value;
    this.getStatesForCountry(countryValue, type);
  }

  checkBillingInfo() {
    this.isBillingSameAsShipping = true;
    this.billingAddress = this.shippingAddress;
    this.currentStep = 'step6';
  }

  callModal() {
    $('#accountCreatedModal').modal('show');
  }

  nextStep(step: string) {
    if (step === 'step1') {
      if (this.accountType === 'Company') {
        if (this.cname === '') {
          this.isCNameAlert = true;
          this.toastr.error('Company name is required', 'Empty Field', {
            positionClass: 'toast-top-right',
          });
        } else {
          this.isCNameAlert = false;
        }
      }

      if (this.email === '') {
        this.isEmailAlert = true;
        this.toastr.error('Email is Required', 'Empty Field:', {
          positionClass: 'toast-top-right',
        });
      } else {
        this.isEmailAlert = false;
      }
      if (this.phone === '') {
        this.isPhoneAlert = true;
        this.toastr.error('Phone is Required', 'Empty Field:', {
          positionClass: 'toast-top-right',
        });
      } else {
        this.isPhoneAlert = false;
      }

      if (
        (this.password === '' && this.repeatPassword === '') ||
        this.password !== this.repeatPassword
      ) {
        this.isPasswordAlert = true;
        this.toastr.error('Password is Empty or doesnt match', 'Empty Field:', {
          positionClass: 'toast-top-right',
        });
      } else {
        this.isPasswordAlert = false;
      }
      if (this.fname === '') {
        this.isFnameAlert = true;
        this.toastr.error('Practice First Name is Required', 'Empty Field:', {
          positionClass: 'toast-top-right',
        });
      } else {
        this.isFnameAlert = false;
      }

      if (this.lname === '') {
        this.isLnameAlert = true;
        this.toastr.error('Practice First Name is Required', 'Empty Field:', {
          positionClass: 'toast-top-right',
        });
      } else {
        this.isLnameAlert = false;
      }

      if (
        this.isEmailAlert === false &&
        this.isPasswordAlert === false &&
        this.isFnameAlert === false &&
        this.isLnameAlert === false &&
        this.isPhoneAlert === false
      ) {
        if (this.accountType === 'Company') {
          if (this.isCNameAlert === false) {
            this.submit();
          }
        } else {
          this.submit();
        }
      }
    }

    if (step === 'step3') {
      console.log('accountType', this.accountType);
      if (this.accountType != 'Employee' && this.accountType !== 'Partner') {
        // Shipping Address
        if (
          this.shippingAddress.address === '' ||
          this.shippingAddress.address === undefined
        ) {
          this.isShippingAddressAlert = true;
          this.toastr.error('Shipping Address is Required', 'Empty Field:', {
            positionClass: 'toast-top-right',
          });
        } else {
          this.isShippingAddressAlert = false;
        }

        if (
          this.shippingAddress.city === '' ||
          this.shippingAddress.city === undefined
        ) {
          this.isShippingCityAlert = true;
          this.toastr.error(
            'City is Required for Shipping Address',
            'Empty Field:',
            { positionClass: 'toast-top-right' }
          );
        } else {
          this.isShippingCityAlert = false;
        }

        if (
          this.shippingAddress.state === '' ||
          this.shippingAddress.state === undefined
        ) {
          this.isShippingStateAlert = true;
          this.toastr.error(
            'State is Required for Shipping Address',
            'Empty Field:',
            { positionClass: 'toast-top-right' }
          );
        } else {
          this.isShippingStateAlert = false;
        }

        if (
          this.shippingAddress.zip === '' ||
          this.shippingAddress.zip === undefined
        ) {
          this.isShippingZipAlert = true;
          this.toastr.error(
            'Zip is Required for Shipping Address',
            'Empty Field:',
            { positionClass: 'toast-top-right' }
          );
        } else {
          this.isShippingZipAlert = false;
        }

        if (
          this.shippingAddress.country === '' ||
          this.shippingAddress.country === undefined
        ) {
          this.isShippingCountryAlert = true;
          this.toastr.error(
            'Country is Required for Shipping Address',
            'Empty Field:',
            { positionClass: 'toast-top-right' }
          );
        } else {
          this.isShippingCountryAlert = false;
        }

        if (this.phone === '') {
          this.isPhoneAlert = true;
          this.toastr.error('Phone is Required', 'Empty Field:', {
            positionClass: 'toast-top-right',
          });
        } else {
          this.isPhoneAlert = false;
        }
        if (
          this.isShippingAddressAlert === false &&
          this.isShippingCityAlert === false &&
          this.isShippingStateAlert === false &&
          this.isShippingZipAlert === false &&
          this.isShippingCountryAlert === false &&
          this.isPhoneAlert === false
        ) {
          this.currentStep = 'step4';
        }
      } else {
        this.currentStep = 'step4';
      }
    }

    if (step === 'step4') {
      this.isBillingSameAsShipping = false;
      this.billingAddress = {};
      this.billingAddress.country = 'US';
      this.currentStep = 'step5';
    }

    if (step === 'step5') {
      if (this.accountType != 'Employee' && this.accountType !== 'Partner') {
        // Billing Address

        if (
          this.billingAddress.address === '' ||
          this.billingAddress.address === undefined
        ) {
          this.isBillingAddressAlert = true;
          this.toastr.error('Billing Address is Required', 'Empty Field:', {
            positionClass: 'toast-top-right',
          });
        } else {
          this.isBillingAddressAlert = false;
        }

        if (
          this.billingAddress.city === '' ||
          this.billingAddress.city === undefined
        ) {
          this.isBillingCityAlert = true;
          this.toastr.error(
            'City is Required for Billing Address',
            'Empty Field:',
            { positionClass: 'toast-top-right' }
          );
        } else {
          this.isBillingCityAlert = false;
        }

        if (
          this.billingAddress.state === '' ||
          this.billingAddress.state === undefined
        ) {
          this.isBillingStateAlert = true;
          this.toastr.error(
            'State is Required for Billing Address',
            'Empty Field:',
            { positionClass: 'toast-top-right' }
          );
        } else {
          this.isBillingStateAlert = false;
        }

        if (
          this.billingAddress.zip === '' ||
          this.billingAddress.zip === undefined
        ) {
          this.isBillingZipAlert = true;
          this.toastr.error(
            'Zip is Required for Billing Address',
            'Empty Field:',
            { positionClass: 'toast-top-right' }
          );
        } else {
          this.isBillingZipAlert = false;
        }

        if (
          this.billingAddress.country === '' ||
          this.billingAddress.country === undefined
        ) {
          this.isBillingCountryAlert = true;
          this.toastr.error(
            'Country is Required for Billing Address',
            'Empty Field:',
            { positionClass: 'toast-top-right' }
          );
        } else {
          this.isBillingCountryAlert = false;
        }

        if (
          this.isBillingAddressAlert === false &&
          this.isBillingCityAlert === false &&
          this.isBillingStateAlert === false &&
          this.isBillingZipAlert === false &&
          this.isBillingCountryAlert === false
        ) {
          this.currentStep = 'step6';
        }
      } else {
        this.currentStep = 'step6';
      }
    }
  }

  back(step: string) {
    switch (step) {
      case 'step1':
        $('#creatingAccountModal').modal('hide');
        break;

      case 'step2':
        this.currentStep = 'step1';
        break;

      case 'step3':
        this.currentStep = 'step2';
        break;

      case 'step4':
        this.currentStep = 'step3';
        break;

      case 'step5':
        this.currentStep = 'step4';
        break;

      case 'step6':
        this.currentStep = 'step1';
        break;

      default:
        $('#creatingAccountModal').modal('hide');
    }
  }

  resetForm() {
    this.userForm.reset();
    this.userForm.markAsPristine();
    this.userForm.markAsUntouched();
    this.submitted = false;
  }

  async submit() {
    //put id with sbtButton to disable button
    const sbtButton = document.getElementById('sbtButton') as HTMLButtonElement;
    sbtButton.disabled = true;
    Swal.fire({
      title: 'Creating Account',
      html: 'Please wait while we create your account',
      allowOutsideClick: false,
    });
    Swal.showLoading();
    this.isCreating = true;
    const user: User = {
      companyId: this._currentUser.currentUser.companyId,
      company: this._currentUser.currentUser.company,
      isActive: true,
      isBlocked: false,
      isDeveloper: false,
      isAdmin: false,
      email: this.email,
      firstName: this.fname,
      lastName: this.lname,
      logo: '',
      logoUrl: '',
      phone: this.phone,
      status: 'Offline',
      level: 'Partner',
      levelType: this.accountType,
      icon: {
        code: 'https://firebasestorage.googleapis.com/v0/b/fleet-adventures.appspot.com/o/assets%2Ficons%2FnoFace.png?alt=media&token=b90edfbc-f3d9-471a-a609-773e47a85c81',
        name: 'NoFace',
      },
    };
    await this.afFun
      .httpsCallable('addUser')({
        email: this.email,
        password: this.password,
        user: user,
      })
      .subscribe((id) => {
        console.log('User to save', user);

        if (!id.errorInfo) {
          if (this.accountType === 'Company') {
            const company: Company = {
              active: true,
              isTesting: true,
              fleetTax: 0,
              companyName: this.cname,
              companyPortal: 'bicycling',
              companyURL: '',
              companyWaiver: '',
              rentalNumberCurrent: 0,
              companyTax: 0,
              optionName: 'Location',
              optionSubtitle: 'Select a location',
              rentalType: 'byDay',
              isNew: true,
              isRebuild: true
            };
            user.levelType = 'Partner';
            this.userService
              .addNewCompany(company)
              .then((id) => {
                console.log(id);
                user.companyId = id;
                user.isAdmin = false;
                user.company = company.companyName;
              })
              .then(() => {
                this.createUser(user, id).then(() => {
                  const currentUser = this._currentUser.currentUser;
                  currentUser.companyId = user.companyId;
                  currentUser.company = company.companyName;
                  this._currentUser
                    .updateUser(currentUser.id, currentUser)
                    .then(() => {
                      this.resetForm();
                      setTimeout(() => {
                        window.location.reload();
                      }, 500);
                    });
                });
              });
          } else {
            this.createUser(user, id);
          }
        } else {
          Swal.fire({
            title: 'Error',
            html: id.errorInfo.message,
            icon: 'error',
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: 'Ok',
          }).then(() => {
            this.isCreating = false;
          });
        }
      });
  }

  async createUser(user: User, id) {
    await this.userService.addNewUser(user, id).then(() => {
      this.isCreated = true;
      setTimeout(() => {
        Swal.fire({
          title: 'Account Created',
          html: 'Your account has been created',
          icon: 'success',
          allowOutsideClick: false,
        }).then(() => {
          $('#addUser').modal('hide');
        });
        this.resetForm();
        this.reset();
        this.userForm.reset();
      }, 500);
    });
  }

  activateCompany() {
    this.isCompany = true;
  }

  //Change cname from userForm to required or not
  changeCname() {
    if (this.accountType === 'Company') {
      // reset
      this.userForm.controls['cname'].setValidators([Validators.required]);

      this.userForm.controls.cname.markAsUntouched();
      this.userForm.controls.cname.markAsPristine();
      this.userForm.controls.cname.setValue('');
    } else {
      this.userForm.controls.cname.clearValidators();

      this.userForm.controls['cname'].setValidators([]);
    }
  }

  static mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
      return null;
    };
  }

  checkForm() {
    this.submitted = true;
    if (this.userForm.valid) {
      this.submit();
    } else {
      return;
    }
  }
}
