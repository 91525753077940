<div class="container-fluid" id="successPaymentOptions">
  <div class="row mt-1">
    <div class="col-md-4 offset-md-2 p-1 d-flex align-items-center">
      <div class="container-fluid rounded shadow cardsuccess column1" (click)="addToGoogleCalendar()">
        <div class="block1 fs-4 text-center">
          <span i18n>Add booking to Google Calendar</span> <i class="fas fa-calendar-plus"></i>
        </div>
      </div>
    </div>
    <div class="col-md-4 p-1 d-flex align-items-center">
      <div class="container-fluid rounded shadow cardsuccess column2" (click)="goToWaiver()">
        <div class="block2 fs-4 text-center">
          <span i18n>Sign Waiver Before you Arrive</span> <i class="fas fa-signature"></i>
        </div>
      </div>
    </div>
    <div class="col-md-4 offset-md-2 p-1 d-flex align-items-center">
      <div class="container-fluid rounded shadow cardsuccess column3" (click)="getRentalPDF()">
        <div class="block3 fs-4 text-center">
          <span i18n>Print Receipt</span> <i class="fas fa-file-invoice-dollar"></i>
        </div>
      </div>
    </div>
    <div class="col-md-4 p-1 d-flex align-items-center">
      <div class="container-fluid rounded shadow cardsuccess column4" (click)="backToSearch()">
        <div class="block4 fs-4 text-center">
          <span i18n>Return to Rental</span> <i class="fas fa-shopping-cart"></i>
        </div>
      </div>
    </div>
  </div>

</div>

<app-receipt [rental]="receiptRental" [locationMap]="locationMap" [totalChargeAmount]="totalChargeAmount" [rentalReady]="receiptRentalReady" [rental]="receiptRental" [company]="company" #receiptComponent></app-receipt>
