<div class="card-body">
  <div class="header" class="mb-2">
    <div>
      <div class="row">
        <div class="col">
          <h1
            class="mb-2"
            style="font-size: 32px; cursor: pointer"
            (click)="openDiscountModal(false)"
          >
            Discount Codes
            <button
              type="button"
              [style.background]="colorText"
              [style.color]="colortTheme"
              class="btn"
              data-bs-toggle="modal"
              data-bs-target="#discountCodeModal"
            >
              <i class="fas fa-plus"></i>
            </button>
          </h1>
        </div>
      </div>
    </div>
  </div>

  <!--<div class="card-body p-0 row scrollable" (scroll)="onScroll($event) table version desktop">-->
  <!----------table  version  divs------------------>
  <p class="fs-4 fw-bolder">Active</p>
  <hr>
  <div
    class="row"
    style="font-weight: 500"
    [style.background]="colortTheme"
    [style.color]="colorText"
  >
    <div class="col-4 col-sm-3 col-lg-2 col-md-2 p-2">
      <p class="text-center">Title</p>
    </div>
    <div class="col-4 col-sm col-md-1 col-lg-2 col-md-1 p-2">
      <p class="text-center">Created Date</p>
    </div>
    <div class="col-4 col-sm col-md-1 col-lg-2 p-2">
      <p class="text-center">Expired Date</p>
    </div>
    <div
      class="col-lg-1 col-sm col-md-2 p-2 d-none d-sm-block d-md-block d-lg-block d-xl-block"
    >
      <p class="text-center">Discount Scope</p>
    </div>
    <div
      class="col-lg-1 col-sm col-md-2 p-2 d-none d-sm-block d-md-block d-lg-block d-xl-block"
    >
      <p class="text-center">Discount Type</p>
    </div>
    <div class="col-lg-1 col-md-2 d-none d-md-block d-lg-block d-xl-block">
      <p class="text-center p-2">Discount</p>
    </div>
    <div class="col-lg-1 col-md-2 p-2 d-none d-md-block d-lg-block d-xl-block">
      <p class="text-center">Status</p>
    </div>
    <div class="col-lg-1 p-2 d-none d-lg-block d-xl-block">
      <p class="text-center">Redeemed</p>
    </div>
    <div class="col-lg-1 p-2 d-none d-lg-block d-xl-block">
      <p class="text-center">Actions</p>
    </div>
  </div>
  <div
    class="row table-mobile mt-1 mb-2"
    *ngFor="let discount of discountsActive; let i = index"
  >
    <div class="col-4 col-sm-3 col-md-2 col-lg-2 p-2">
      <p>{{ discount.discountTitle }}</p>
    </div>
    <div class="col-4 col-sm col-md-1 col-lg-2 p-2">
      <p class="text-center ml-1">{{ discount.created.toDate() | date }}</p>
    </div>
    <div class="col-4 col-sm col-md-1 col-lg-2 p-2">
      <p class="text-center p-1">{{ discount.expiredDate.toDate() | date }}</p>
    </div>
    <div
      class="col-lg-1 col-sm col-md-2 p-2 d-none d-sm-block d-md-block d-lg-block d-xl-block"
    >
      <p class="text-center">{{ discount.discountScope }}</p>
    </div>
    <div
      class="col-lg-1 col-md-2 col-sm p-2 d-none d-sm-block d-md-block d-lg-block d-xl-block"
    >
      <p class="text-center">{{ discount.discountType }}</p>
    </div>
    <div
      class="col-lg-1 col-md-2 col-sm p-2 d-none d-md-block d-lg-block d-xl-block"
    >
      <p class="text-center">
        <ng-container *ngIf="discount.discountType === 'fixedAmount'"
          >$
        </ng-container>
        {{ discount.discount }}
        <ng-container *ngIf="discount.discountType === 'percentage'"
          >%</ng-container
        >
      </p>
    </div>
    <div class="col-lg-1 col-md-2 p-2 d-none d-md-block d-lg-block d-xl-block">
      <p class="text-center">
        <ng-container *ngIf="discount.isActive === true">Active </ng-container>
      </p>
      <p class="text-center">
        <ng-container *ngIf="discount.isActive === false"
          >Discontinue</ng-container
        >
      </p>
    </div>
    <div class="col-lg-1 p-2 d-none d-lg-block d-xl-block">
      <p class="text-center">{{ discount.redeemed }}</p>
      <!-- <td>{{discount.globalRedeemCount}} -->
    </div>
    <div class="col-lg-1 p-2 d-none d-lg-block d-xl-block">
      <button
        type="button"
        (click)="openDiscountModal(true, discount)"
        data-bs-toggle="modal"
        data-bs-target="#discountCodeModal"
        class="btn btn-success"
      >
        <i class="fa fa-pencil"></i>
      </button>
    </div>
    <div class="col-12 p-1 main-tr">
      <ul class="nav flex-column">
        <li class="d-block d-dm-none d-sm-none">
          <p><span>Discount Scope : </span>{{ discount.discountScope }}</p>
          <ng-container *ngIf="discount.discountScope === 'product'">
            <p><span>Discount Scope : </span>{{ discount.productName }}</p>
          </ng-container>
        </li>
        <li class="d-block d-sm-none d-md-none">
          <p><span>Discount Type:</span> {{ discount.discountType }}</p>
        </li>
        <li class="d-block d-md-none">
          <p>
            <span>Discount : </span
            ><ng-container *ngIf="discount.discountType === 'fixedAmount'"
              >$
            </ng-container>
            {{ discount.discount }}
            <ng-container *ngIf="discount.discountType === 'percentage'"
              >%</ng-container
            >
          </p>
        </li>
        <li class="d-block d-md-none d-sm-block">
          <p>
            <span>Status :</span>
            <ng-container *ngIf="discount.isActive === true"
              >Active
            </ng-container>
            <ng-container *ngIf="discount.isActive === false"
              >Discontinue</ng-container
            >
          </p>
        </li>
        <li>
          <p><span>Redeemed :</span> {{ discount.redeemed }}</p>
          <!-- <td>{{discount.globalRedeemCount}} -->
        </li>
        <li>
          <div>
            <p>
              <span>Actions : </span
              ><button
                type="button"
                (click)="openDiscountModal(true, discount)"
                data-bs-toggle="modal"
                data-bs-target="#discountCodeModal"
                class="btn btn-success"
              >
                <i class="fa fa-pencil"></i>
              </button>
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <!----------table  version  divs------------------>
  <br>
  <p class="fs-4 fw-bolder">Discontinued</p>
  <hr>
  <div
    class="row"
    style="font-weight: 500"
    [style.background]="colortTheme"
    [style.color]="colorText"
  >
    <div class="col-4 col-sm-3 col-lg-2 col-md-2 p-2">
      <p class="text-center">Title</p>
    </div>
    <div class="col-4 col-sm col-md-1 col-lg-2 col-md-1 p-2">
      <p class="text-center">Created Date</p>
    </div>
    <div class="col-4 col-sm col-md-1 col-lg-2 p-2">
      <p class="text-center">Expired Date</p>
    </div>
    <div
      class="col-lg-1 col-sm col-md-2 p-2 d-none d-sm-block d-md-block d-lg-block d-xl-block"
    >
      <p class="text-center">Discount Scope</p>
    </div>
    <div
      class="col-lg-1 col-sm col-md-2 p-2 d-none d-sm-block d-md-block d-lg-block d-xl-block"
    >
      <p class="text-center">Discount Type</p>
    </div>
    <div class="col-lg-1 col-md-2 d-none d-md-block d-lg-block d-xl-block">
      <p class="text-center p-2">Discount</p>
    </div>
    <div class="col-lg-1 col-md-2 p-2 d-none d-md-block d-lg-block d-xl-block">
      <p class="text-center">Status</p>
    </div>
    <div class="col-lg-1 p-2 d-none d-lg-block d-xl-block">
      <p class="text-center">Redeemed</p>
    </div>
    <div class="col-lg-1 p-2 d-none d-lg-block d-xl-block">
      <p class="text-center">Actions</p>
    </div>
  </div>
  <div
    class="row table-mobile mt-1"
    *ngFor="let discount of discountsInactive; let i = index"
  >
    <div class="col-4 col-sm-3 col-md-2 col-lg-2 p-2">
      <p>{{ discount.discountTitle }}</p>
    </div>
    <div class="col-4 col-sm col-md-1 col-lg-2 p-2">
      <p class="text-center ml-1">{{ discount.created.toDate() | date }}</p>
    </div>
    <div class="col-4 col-sm col-md-1 col-lg-2 p-2">
      <p class="text-center p-1">{{ discount.expiredDate.toDate() | date }}</p>
    </div>
    <div
      class="col-lg-1 col-sm col-md-2 p-2 d-none d-sm-block d-md-block d-lg-block d-xl-block"
    >
      <p class="text-center">{{ discount.discountScope }}</p>
    </div>
    <div
      class="col-lg-1 col-md-2 col-sm p-2 d-none d-sm-block d-md-block d-lg-block d-xl-block"
    >
      <p class="text-center">{{ discount.discountType }}</p>
    </div>
    <div
      class="col-lg-1 col-md-2 col-sm p-2 d-none d-md-block d-lg-block d-xl-block"
    >
      <p class="text-center">
        <ng-container *ngIf="discount.discountType === 'fixedAmount'"
          >$
        </ng-container>
        {{ discount.discount }}
        <ng-container *ngIf="discount.discountType === 'percentage'"
          >%</ng-container
        >
      </p>
    </div>
    <div class="col-lg-1 col-md-2 p-2 d-none d-md-block d-lg-block d-xl-block">
      <p class="text-center">
        <ng-container *ngIf="discount.isActive === true">
          Expired
        </ng-container>
      </p>
      <p class="text-center">
        <ng-container *ngIf="discount.isActive === false"
          >Discontinued</ng-container
        >
      </p>
    </div>
    <div class="col-lg-1 p-2 d-none d-lg-block d-xl-block">
      <p class="text-center">{{ discount.redeemed }}</p>
      <!-- <td>{{discount.globalRedeemCount}} -->
    </div>
    <div class="col-lg-1 p-2 d-none d-lg-block d-xl-block">
      <button
        [disabled]="discount.isActive === false ? true : false"
        type="button"
        (click)="openDiscountModal(true, discount)"
        data-bs-toggle="modal"
        data-bs-target="#discountCodeModal"
        class="btn {{ discount.isActive === true ? 'btn-success' : 'btn-secondary' }}"
      >
        <i class="fa fa-pencil"></i>
      </button>
    </div>
    <div class="col-12 p-1 main-tr">
      <ul class="nav flex-column">
        <li class="d-block d-dm-none d-sm-none">
          <p><span>Discount Scope : </span>{{ discount.discountScope }}</p>
          <ng-container *ngIf="discount.discountScope === 'product'">
            <p><span>Discount Scope : </span>{{ discount.productName }}</p>
          </ng-container>
        </li>
        <li class="d-block d-sm-none d-md-none">
          <p><span>Discount Type:</span> {{ discount.discountType }}</p>
        </li>
        <li class="d-block d-md-none">
          <p>
            <span>Discount : </span
            ><ng-container *ngIf="discount.discountType === 'fixedAmount'"
              >$
            </ng-container>
            {{ discount.discount }}
            <ng-container *ngIf="discount.discountType === 'percentage'"
              >%</ng-container
            >
          </p>
        </li>
        <li class="d-block d-md-none d-sm-block">
          <p>
            <span>Status :</span>
            <ng-container *ngIf="discount.isActive === true"
              >Expired
            </ng-container>
            <ng-container *ngIf="discount.isActive === false"
              >Discontinued</ng-container
            >
          </p>
        </li>
        <li>
          <p><span>Redeemed :</span> {{ discount.redeemed }}</p>
          <!-- <td>{{discount.globalRedeemCount}} -->
        </li>
        <li>
          <div>
            <p>
              <span>Actions : </span
              >
              <button
                type="button"
                (click)="openDiscountModal(true, discount)"
                data-bs-toggle="modal"
                data-bs-target="#discountCodeModal"
                class="btn btn-success"
              >
                <i class="fa fa-pencil"></i>
              </button>
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="modal" id="discountCodeModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          <ng-container *ngIf="isEditing"> Edit Discount Code </ng-container>
          <ng-container *ngIf="!isEditing"> Add Discount Code </ng-container>
        </h4>
        <button
          type="button"
          class="btn btn-danger"
          (click)="reset()"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i class="fa fa-close"></i>
        </button>
      </div>

      <div class="modal-body">
        <form
          class="row g-3 needs-validation"
          [formGroup]="discountInfo"
          (ngSubmit)="validateForm()"
          novalidate
        >
          <div class="col-md-12">
            <label class="mb-2" for="discountTitle"
              >Discount Title<b style="color: red">*</b></label
            >
            <div class="input-group mb-2">
              <input
                class="form-control"
                type="text"
                formControlName="discountTitle"
                name="discountTitle"
                placeholder="Enter Discount Code..."
                (keyup)="validateTitle()"
                [ngClass]="{
                  'is-invalid':
                    isDiscountTitleExistAlert ||
                    (f.discountTitle.errors && submitted)
                }"
              />
              <div class="input-group-append">
                <span
                  class="input-group-text"
                  id="basic-addon2"
                  (click)="generateCode()"
                >
                  <i class="fas fa-refresh"></i>
                </span>
              </div>
            </div>
            <div
              *ngIf="f.discountTitle.errors?.['required'] && submitted"
              class="text-danger"
            >
              Please provide a valid Title
            </div>
            <div *ngIf="isDiscountTitleExistAlert" class="text-danger">
              Discount Code Name Repeated, please change it and try again
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group-sm mb-2 input-group-sm">
              <label class="mb-1" for="expiredDate"
                >ExpiredDate<b style="color: red">*</b></label
              >
              <input
                class="form-control hiddenInput"
                type="date"
                formControlName="expiredDate"
                name="expiredDate"
                [ngClass]="{ 'is-invalid': f.expiredDate.errors && submitted }"
              />
            </div>
            <div
              *ngIf="f.expiredDate.errors?.['required'] && submitted"
              class="text-danger"
            >
              Please provide a valid Expired Date
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group-sm mb-2 input-group-sm">
              <label class="mb-1" for="discountType"
                >Discount Type<b style="color: red">*</b></label
              >
              <select
                name="discountType"
                formControlName="discountType"
                class="form-control custom-select"
                [ngClass]="{ 'is-invalid': f.discountType.errors && submitted }"
              >
                <option value="" selected disabled>Select Option</option>
                <option value="percentage">Percentage</option>
                <option value="fixedAmount">Fixed Amount</option>
              </select>
            </div>
            <div
              *ngIf="f.discountType.errors?.['required'] && submitted"
              class="text-danger"
            >
              Please provide a valid Discount Type
            </div>
          </div>
          <div class="col-md-12" *ngIf="f.discountType.value !== ''">
            <label class="mb-2" for="discount">
              <ng-container *ngIf="f.discountType.value === 'percentage'"
                >Discount Percentage
              </ng-container>
              <ng-container *ngIf="f.discountType.value === 'fixedAmount'">
                Discount Fixed Amount
              </ng-container>
              <b style="color: red">*</b>
            </label>

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon2">
                  <ng-container *ngIf="f.discountType.value === 'percentage'"
                    >%</ng-container
                  >
                  <ng-container *ngIf="f.discountType.value === 'fixedAmount'"
                    >$</ng-container
                  >
                </span>
              </div>
              <input
                class="form-control"
                type="number"
                formControlName="discount"
                name="discount"
                min="1"
                [ngClass]="{
                  'is-invalid':
                    f.discountType.value == 'percentage'
                      ? f.discount.value > 100 || f.discount.value == ''
                      : f.discount.value <= 0 ||
                        f.discount.value == '' ||
                        f.discount.errors
                }"
              />
            </div>
            <div *ngIf="f.discount.errors && submitted" class="text-danger">
              The Discount must be greater than 0
            </div>
            <div
              *ngIf="
                f.discountType.value == 'percentage'
                  ? f.discount.value > 100
                  : ''
              "
              class="text-danger"
            >
              The Discount must be less than 100
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group-sm mb-2 input-group-sm">
              <label class="mb-1" for="discountScope"
                >Discount Scope<b style="color: red">*</b></label
              >
              <select
                name="discountScope"
                formControlName="discountScope"
                class="form-control custom-select"
                [ngClass]="{
                  'is-invalid': f.discountScope.errors && submitted
                }"
              >
                <option value="" selected disabled>Select Scope</option>
                <option value="rental">Rental Total</option>
                <option value="product">Product Specific</option>
              </select>
            </div>
            <div
              *ngIf="f.discountScope.errors?.['required'] && submitted"
              class="text-danger"
            >
              Please provide a valid Discount Scope
            </div>
          </div>

          <div class="col-md-12" *ngIf="f.discountScope.value === 'product'">
            <div class="form-group-sm mb-2 input-group-sm">
              <label class="mb-1" for="selectedProduct">Product</label>
              <select
                name="selectedProduct"
                formControlName="selectedProduct"
                class="form-control custom-select"
                [ngClass]="{ 'is-invalid': !isvalidProduct && submitted }"
              >
                <option value="" selected disabled>Select Product</option>
                <ng-container *ngFor="let product of products">
                  <option value="{{ product.id }}">
                    {{ product.productName }}
                  </option>
                </ng-container>
              </select>
            </div>
            <div *ngIf="!isvalidProduct && submitted" class="text-danger">
              Please provide a valid Product
            </div>
          </div>

          <!--
                    <div class="row mb-2">
                        <label class="mb-2" for="globalRedeemCount">Global Redeem Code (*) </label>
                        <div class="input-group mb-2">
                            <input class="form-control" type="number" [(ngModel)]="globalRedeemCount"
                                name="globalRedeemCount" placeholder="How many times this code can be redeemed"
                                [ngClass]="{'redBorder':isGlobalRedeemCountAlert}">
                        </div>
                        <div class="errormssg" style="color: red;" *ngIf="isGlobalRedeemCountAlert">
                            <i class="fas fa-exclamation-circle"></i> The Global Redeem Count is required and it has to be
                            greater than or equal to User Redeem Code
                        </div>
                    </div>
                    -->

          <!--
                    <div class="row mb-2">
                        <label class="mb-2" for="perUserRedeemCount">User Redeem Code (*) </label>
                        <div class="input-group mb-2">
                            <input class="form-control" type="number" [(ngModel)]="perUserRedeemCount"
                                name="perUserRedeemCount" placeholder="How many times this code can be redeemed per user"
                                [ngClass]="{'redBorder':isPerUserRedeemCountAlert}">
                        </div>
                        <div class="errormssg" style="color: red;" *ngIf="isPerUserRedeemCountAlert">
                            <i class="fas fa-exclamation-circle"></i> The User Redeem Count is required and it has to be
                            lower than or equal to Global Redeem Code
                        </div>
                    </div>
                    -->

          <div class="d-flex justify-content-center">
            <div class="col-4 text-left">
              <button
                type="button"
                class="btn btn-outline-secondary btn-sm mt-1"
                (click)="discontinueCode()"
                *ngIf="isEditing && isActive"
              >
                Discontinued Code (*)
              </button>
            </div>

            <div class="col text-center">
              <button type="submit" id="submitbtn" class="btn btn-success">
                <ng-container *ngIf="isEditing">Update</ng-container>
                <ng-container *ngIf="!isEditing">Submit</ng-container>
              </button>
            </div>

            <div class="col-4"></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
