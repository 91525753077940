import { Component, Input, OnInit, Injector } from '@angular/core';
import * as QRious from 'qrious';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { RentalService } from 'src/app/services/rental.service';
import { ProductsService } from 'src/app/services/products.service';
import * as moment from 'moment-timezone';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { threadId } from 'worker_threads';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { LogService } from 'src/app/services/log.service';
import { ProductLocation } from 'src/app/models/storage/product-location.model';
import { CheckedIn } from '../../models/order-processing.model';
import { ProductLocationService } from 'src/app/services/product-location.service';
import { Rental } from 'src/app/models/storage/rental.model';
import { lastValueFrom } from 'rxjs';
import { LuxonDisplay } from 'src/app/utils/pipes/LuxonDisplay.pipe';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss'],
})
export class ReceiptComponent implements OnInit {
  constructor(
    private _rentalService: RentalService,
    private productService: ProductsService,
    private http: HttpClient,
    private _afFun: AngularFireFunctions,
    private _logService: LogService,
    private locationService: ProductLocationService,
    private injector: Injector
  ) {
    this.luxonDisplay = this.injector.get(LuxonDisplay); // allows injection to occur in pipe (to get timeService methods)
      }
  @Input() public rental;
  @Input() public rentalReady;
  @Input() public totalChargeAmount = 0;
  @Input() public company;
  @Input() public locationMap: { [id: string]: ProductLocation } = {};

  luxonDisplay: LuxonDisplay;

  public pdfDiscount = 0;
  public fees;
  public doc;
  public qr;

  public isTransaction = false;
  public totalRefund = 0;
  public totalDeposit = 0;
  protected totalPaid: number = 0;

  public refunds = [];
  public charges = [];
  public deposit = [];
  public changeslogs = [];

  public isLoading = false;
  public dayDiff = 1;

  public productLocation: ProductLocation = {
    timezoneName: 'MST',
    timezone: 'America/Denver',
  };
  public mainPayment: any;
  public defaultLocation: ProductLocation;

  ngOnInit(): void {
  }

  async sendEmail(emailTo, dev?, devEmail?) {
    await this.getChangesBookings();
    this.totalChargeAmount = 0;
    this.totalRefund = 0;
    this.totalDeposit = 0;
    this.filterAddons(this.rental)
    this.getCharges().then(() => {
      this.getMainPayment().then(() => {
        this.getRefunds().then(() => {
          this.getDeposit().then(async () => {
            if (this.charges.length > 0) {
              this.charges.forEach((charge) => {
                this.totalChargeAmount += charge.amount / 100;
              });
            }
            if (this.refunds.length > 0) {
              this.refunds.forEach((refund) => {
                this.totalRefund += refund.amount / 100;
              });
            }
            if (this.deposit.length > 0) {
              this.deposit.forEach((deposit) => {
                this.totalDeposit += deposit.amount / 100;
              });
            }
            if (this.mainPayment.length > 0) {
              this.mainPayment.forEach((main) => {
                this.totalChargeAmount += main.amount / 100;
              });
            }
            const company = await this._rentalService.getCompanyByID(
              this.rental.companyID
            );
            const productLocation =
              await this._rentalService.getDefaultLocation(
                company.defaultLocation
              );
            let data = {
              rental: this.rental,
              rentalID: this.rental.id,
              changeslogs: this.changeslogs,
              mainPayment: this.mainPayment,
              charges: this.charges,
              refunds: this.refunds,
              deposit: this.deposit,
              companyID: this.rentalReady.companyID,
              totalChargeAmount: this.totalChargeAmount,
              totalRefund: this.totalRefund,
              totalDeposit: this.totalDeposit,
              emailTo: emailTo,
              company: company,
              productLocation: productLocation,
              locationMap: this.locationMap,
              defaultLocation: this.defaultLocation
            };
            this._afFun
              .httpsCallable('sendEmailReceipt')(data)
              .subscribe((res) => {
                if ((dev && emailTo != devEmail) || !dev) {
                  this._logService.addRentalLog(
                    this.rental.id,
                    'Receipt Sent to ' + emailTo
                  );
                }

                Swal.fire({
                  icon: 'success',
                  title: 'Email Sent',
                  text: 'Email Sent Successfully',
                  showConfirmButton: false,
                  timer: 1500,
                });
              }, (error) => {
                console.error(error);

                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'Failed to send email',
                });
              });

          });
        });
      });
    });
  }
  async openReceipt(dev?) {
    this.fees = this.rental.taxes;

    if (this.rental.refundsID !== undefined) {
      this.rental.refundsID.forEach((refund) => {
        this.totalRefund += refund.amount;
      });
    }
    this.filterAddons(this.rental)
    this.filterAddons(this.rentalReady);
    try {
      this.qr = new QRious({
        value:
          window.location.origin +
          '/partner/bookings-detail?id=' +
          this.rental.id,
      });
      await this.getChangesBookings();
      await this.getCharges().then(async () => {
        await this.getMainPayment().then(async () => {
          await this.getRefunds().then(async () => {
            await this.getDeposit().then(async () => {
              await this.setupPDF().then(async () => {
                await this.pdfContent().then(async () => {
                  setTimeout(() => {
                    this.outputPDF();
                    console.log(dev);
                    !dev
                      ? this._logService.addRentalLog(
                        this.rental.id,
                        'Receipt Generated'
                      )
                      : '';
                  }, 1500);
                });
              });
            });
          });
        });
      });
    } catch (error) {
      console.log('Algo pasa', error);
    }
  }
  filterAddons(rental) {
    rental.products.forEach((product) => {
      product.addons.forEach((addon, index) => {
        if (addon.addonType === 'select' || addon.addonType === 'radios') {
          const selectedAddon = addon.optionsSelect.find(
            (option) => option.isSelected === true
          );
          if (selectedAddon) {
            addon.optionsSelect = addon.optionsSelect.filter(
              (option) => option.isSelected
            );
          } else {
            addon.optionsSelect = [
              { description: 'N/S', isSelected: true, price: 0 },
            ];
          }
        }
      });
    });
    if(rental.cartWidgetList != undefined){
      let addonsFilter = []
      rental.cartWidgetList.forEach((addon, index) => {
        if (addon.addonType === 'select' || addon.addonType === 'radios') {
          const selectedAddon = addon.optionsSelect.find(
            (option) => option.isSelected === true
          );
          if (selectedAddon) {
            addon.optionsSelect = addon.optionsSelect.filter(
              (option) => option.isSelected
            );
          } else {
            addon.optionsSelect = [
              { description: 'N/S', isSelected: true, price: 0 },
            ];
          }
        }
        if(addon.addonType === 'textarea') {
          console.log(((addon.addonType === 'select' || addon.addonType === 'radios') && (addon.optionsSelect[0] === undefined || addon.optionsSelect[0].description === 'N/S')) || (addon.addonType === 'heightInput') || (addon.addonType === 'weightInput') || (addon.addonType === 'image') || (addon.addonType === 'textarea') || (addon.addonType === 'checkbox' && addon.optionsSelect[0].qty === 0) || ((addon.addonType === 'quantity' || addon.addonType === 'product') && addon.optionsSelect[0].qty === 0))
          console.log(addon.addonType === 'textarea')
        }
        if(!(((addon.addonType === 'select' || addon.addonType === 'radios') && (addon.optionsSelect[0] === undefined || addon.optionsSelect[0].description === 'N/S')) || (addon.addonType === 'heightInput') || (addon.addonType === 'weightInput') || (addon.addonType === 'image') || (addon.addonType === 'textarea') || (addon.addonType === 'checkbox' && addon.optionsSelect[0].qty === 0) || ((addon.addonType === 'quantity' || addon.addonType === 'product') && addon.optionsSelect[0].qty === 0))){
          addonsFilter.push(addon);
        }
      });
      rental.cartWidgetList = addonsFilter;
    }
    return rental;
  }
  async setupPDF() {
    this.doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'letter',
    });
  }
  calculateRowspan(item, type) {
    let rowspan = 0;
    let addons;
    if (type === 'product') {
      if (item.addons.length > 0) {
        addons = item.addons;
        rowspan = 1;
      } else {
        return 0;
      }
    } else {
      if (item.length > 0) {
        addons = item;
      } else {
        return 0;
      }
    }
    if (addons) {
      addons.forEach((addon) => {
        let selectedAddon = null;
        if (addon.addonType == "select" || addon.addonType == "radios") {
          selectedAddon = addon.optionsSelect.find(option => option.isSelected === true);
        }
        if (
          ((addon.addonType == "select" || addon.addonType == "radios") && selectedAddon) ||
          ((addon.addonType === 'checkbox' || addon.addonType === 'quantity' || addon.addonType === 'product' || addon.addonType === 'number') && addon.optionsSelect[0].qty > 0) ||
          (addon.addonType === 'tip' || addon.addonType === 'price')
        ) {
          rowspan++;
        }
      });
    }
    return rowspan;
  };
  async pdfContent() {
    this.totalChargeAmount = 0;
    this.totalRefund = 0;
    this.totalDeposit = 0;

    // Helper function to accumulate amounts
    const accumulateAmount = (items: any[]) => {
        return items.reduce((acc, item) => acc + item.amount / 100, 0);
    };

    this.totalChargeAmount = accumulateAmount(this.charges) + accumulateAmount(this.mainPayment);
    this.totalRefund = accumulateAmount(this.refunds);
    this.totalDeposit = accumulateAmount(this.deposit);

    this.totalPaid =  Number(((this.totalChargeAmount + this.totalDeposit ) - this.totalRefund + 0.001).toFixed(2))
    // Helper function to calculate rowspan

    this.rentalReady.products.forEach((product) => {
        product.rowspan = this.calculateRowspan(product, 'product');
    });

    if (this.rentalReady.cartWidgetList) {
        this.rentalReady.cartWidgetRowspan = this.calculateRowspan(this.rentalReady.cartWidgetList, 'cart');
    }

    this.pdfDiscount = 0;
    this.isLoading = true;

    try {
        const company = await this._rentalService.getCompanyByID(this.rentalReady.companyID);
        const productLocation = await this._rentalService.getDefaultLocation(company.defaultLocation);
        this.defaultLocation = productLocation;
        this.productLocation = productLocation;

        if (this.rentalReady.discount) {
            this.pdfDiscount = this.rentalReady.discount;
        }

        // Define company information
        let logoUbication = 'assets/base64/mainLogo.txt';
        let companyName = company.companyName || 'Fleet Adventures';
        let address1 = company.companyAddress.address1 || '';
        let address2 = [company.companyAddress.address2, company.companyAddress.city, company.companyAddress.state, company.companyAddress.zip].filter(Boolean).join(' ');
        let phone = company.companyPhone || '';
        let url = company.companyWebsite || '';
        let logoFormat = 'jpg';

        if (this._rentalService.companyID === 'wYxvJNGijJ5bJgNsckzg') {
            logoUbication = 'assets/base64/logorapid.txt';
            logoFormat = 'png';
            companyName = 'Rapid Cycling';
            address1 = 'North Bluff Street';
            address2 = 'St. George, UT 84770';
            phone = '(435) 703-9880';
            url = 'www.rapidcyclingbikes.com';
        }

        // Define rental date range
        const formatDate = (date, format, timezone) => moment.tz(date.seconds * 1000, timezone).format(format);
        let dayStart = formatDate(this.rentalReady.dayStart, 'MMM Do, YYYY, hh:mm a', productLocation.timezone);
        let dayEnd = formatDate(this.rentalReady.dayEnd, this.rentalReady.rentalType === 'byHour' ? ' - hh:mm a' : ' - MMM DD, YYYY', productLocation.timezone);
        let finalDate = dayStart + dayEnd;

        const ds = this.luxonDisplay.transform(this.rentalReady.dayStart, this.rentalReady.rentalType === 'byDay' ? 'DATE_MED' : 'DATETIME_MED', this.defaultLocation.timezone);
        const de = this.luxonDisplay.transform(this.rentalReady.dayEnd, this.rentalReady.rentalType === 'byDay' ? 'DATE_MED' : 'TIME_SIMPLE', this.defaultLocation.timezone) + (this.rentalReady.rentalType === 'byHour' ? ' ' + this.defaultLocation.timezoneName : '');
        const rentalDateRange = ds + ' - ' + de;

        // Generate PDF
        this.http.get(logoUbication, { responseType: 'text' }).pipe(take(1)).subscribe(async (logo) => {
            this.doc.addImage(logo, logoFormat, 14, 6, 28, 28);
            this.doc.addImage(this.qr.toDataURL(), 'PNG', 187, 10, 22, 22, 'right');

            this.doc.setFontSize(12.5);
            this.doc.setFont('helvetica', 'bold');
            this.doc.text(companyName, 11, 40);
            this.doc.setFont('helvetica', 'normal');
            this.doc.text(address1, 11, 45);
            this.doc.text(address2.trim(), 11, 50);
            this.doc.text(`Phone: ${phone}`, 11, 55);
            this.doc.text(url, 11, 65);

            this.doc.text('Payment Receipt', 210, 45, null, null, 'right');
            this.doc.text('Rental #' + this.rentalReady.rentalNumber, 210, 50, null, null, 'right');
            this.doc.text('Date: ' + rentalDateRange, 210, 55, null, null, 'right');
            this.doc.text('Customer Name: ' + this.rentalReady.userInfo.name + ' ' + this.rentalReady.userInfo.lastName, 210, 60, null, null, 'right');
            this.doc.text('Rental Type: ' + (this.rentalReady.rentalType === 'byHour' ? 'By Hour' : 'By Day'), 210, 65, null, null, 'right');

            // Table Invoice
            this.doc.autoTable({
                html: '#paymentReceipt',
                theme: 'striped',
                startY: 75,
                margin: { left: 11 },
                tableWidth: 193,
                headStyles: { lineColor: [41, 128, 186], lineWidth: 0.1, halign: 'center' },
                bodyStyles: { lineColor: [55, 55, 55], lineWidth: 0.1, halign: 'center' },
                columnStyles: {
                    0: { cellWidth: this.rentalReady.discount ? 30 : 30, halign: 'left', lineColor: [221, 221, 221] },
                    1: { cellWidth: this.rentalReady.discount ? 40 : 50, halign: 'left', lineColor: [221, 221, 221] },
                    2: { cellWidth: this.rentalReady.discount ? 70 : 70, halign: 'right', lineColor: [221, 221, 221] },
                    3: { cellWidth: this.rentalReady.discount ? 50 : 50, halign: 'right', lineColor: [221, 221, 221] },
                },
                styles: { halign: 'center', cellPadding: 1, overflow: 'linebreak' },
                showHead: 'firstPage',
                pageBreak: 'auto',
            });

            // Text under the table
            let finalY = this.doc.lastAutoTable.finalY;
            this.doc.setFont('helvetica', 'bold');
            this.doc.setFontSize(10);
            const pLocation = await this._rentalService.getDefaultLocation(company.defaultLocation);
            this.defaultLocation = pLocation;

            let text = this.rentalReady.isDeliver
                ? `Bike will be available for pickup at ${this.rentalReady.deliverInformation.name} on ${moment(this.rentalReady.dayStart.seconds * 1000).format('dddd, MMM DD')} at 10:00 AM ${this.productLocation.timezoneName} and must be returned to the hotel on ${moment(this.rentalReady.dayEnd.seconds * 1000).format('dddd, MMM DD')} by 6:00 PM ${this.productLocation.timezoneName}`
                : `Rental available for pickup: ${this.luxonDisplay.transform(this.rentalReady.dayStart, 'DATETIME_MED', this.defaultLocation.timezone)} ${this.productLocation.timezoneName}`;

            this.doc.text(56, finalY + 5, text);
        });
    } catch (error) {
        console.error('Error:', error);
    }
}

  outputPDF() {
    console.log('outputPDF SALIENDO');
    setTimeout(() => {
      Swal.close();
      var blob = this.doc.output('bloburl');
      try {
        Swal.close();
        window.open(blob, '_blank');
      } catch (e) {
        Swal.fire({
          title: 'Error',
          text: 'Error opening PDF',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
      }
    }, 100);
    // pdf open
  }

  convertTimeStamp(data: any) {
    //Parameter timestamp
    var ds = new Date(data * 1000); //Get the date of the timestamp
    var date1 = moment(ds).format('L'); //Format dd/mm/aaaa
    var date2 = moment(ds).format('LT'); //Format hh:mm am
    return date1 + ' ' + date2; //Return
  }

  getRefunds() {
    return this._rentalService
      .getRefunds(this.rentalReady.id)
      .then((res: any) => {
        this.refunds = res;
        console.log('Refunds', this.refunds);
      });
  }
  getChangesBookings() {
    return this._logService
      .getLogsChangedWithCollection('rentals', this.rental.id)
      .then((res) => {
        res.forEach((log, index) => {
          log.difference > 0
            ? (res[index].negative = false)
            : (res[index].negative = true);
          res[index].difference = Math.abs(log.difference);
        });
        this.changeslogs = res;
        console.log(res);
      });
  }
  getCharges() {
    return this._rentalService
      .getCharges(this.rentalReady.id)
      .then((res: any) => {
        this.charges = res;
        console.log('Charges', this.charges);
      });
  }
  getMainPayment() {
    return this._rentalService
      .getMainPayment(this.rentalReady.id)
      .then((res: any) => {
        this.mainPayment = res;
      });
  }
  getDeposit() {
    this.deposit = [];
    return this._rentalService
      .getDeposit(this.rentalReady.id)
      .then((res: any) => {
        res.forEach((element) => {
          if (element.status == 'succeeded') {
            this.deposit.push(element);
          }
        });

        console.log('Deposit', this.deposit);
      });
  }
}
