<div *ngIf="!isDataAvailable"  class="d-flex text-center justify-content-center" style="margin-top: 15px;" >
  <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>
<span *ngIf="isDataAvailable">
  <!---------- Check Box -------------->
  <div
    *ngIf="component.widgetType ==  'checkbox' && component.widgetName == 'New' || component.widgetType == 'checkbox' && savedWidgetEditor == true || component.widgetType ==  'checkbox' && isRentalEdit">
    <div class="form-row">
      <label class="mb-1 col-sm-12">Label</label>
      <div class="col-sm-12 mb-2">
        <input type="text" class="form-control form-control-sm" [(ngModel)]="component.element.label" />
      </div>
    </div>

    <div class="row mb-2">
      <label class="col-sm-12">Description</label>
      <div class="col-sm-12 mb-2">
        <textarea style="overflow-y: hidden;" [(ngModel)]="component.element.description"
          class="form-control form-control-sm" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1">
          </textarea>
      </div>
    </div>

    <div class="row mb-2">
      <label class="col-sm-12">Price</label>
      <div class="row" *ngIf="!isRentalEdit">
          <form>
            <div style="padding: 0px;">
              <input type="radio" id="oneTime" name="fav_language" 
                    (change)="component.element.recurringPrice = false; cleanRecurringPriceValues(false)" 
                    [checked]="!component.element.recurringPrice"
              >
              <label for="oneTime">One time charge</label>
            </div>
            <div style="padding: 0px;">
                <input type="radio" id="recur" name="fav_language" 
                  (change)="component.element.recurringPrice = true; cleanRecurringPriceValues(true)" 
                  [checked]="component.element.recurringPrice">
                <label for="recur">Recurring charge</label>
            </div>
              <br>
          </form>
        </div>
      <div class="col-sm-12" *ngIf="!component.element.recurringPrice; else recurringPriceTrue">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text">
            $
          </span>
            <input type="number" name="field_name" [(ngModel)]="component.element.price" class="form-control form-control-sm"
              oninput="this.value = this.value.replace(/[^0-9]/g, '');">
          </div>
      </div>

      <ng-template #recurringPriceTrue>
        <div class="col-sm-12">
          <div class="input-group input-group-sm mb-3">
            <span class="input-group-text">
              Per Day $
            </span>
            <input type="number" class="form-control" [(ngModel)]="component.element.price"
              oninput="this.value = this.value.replace(/[^0-9]/g, '')">
          </div>
        </div>
      </ng-template>
    </div>

    <div class="form-check" style="margin-top: 5px;" *ngIf="!isRentalEdit">
      <input type="checkbox" class="form-check-input" [(ngModel)]="component.element.is_required" id="is_required" />
      <label for="is_required" class="form-check-label">Set as required</label>
    </div>

  </div>

  <!---------- Number Quantity -------------->
  <div
    *ngIf="component.widgetType ==  'quantity' && component.widgetName == 'New' || component.widgetType == 'quantity' && savedWidgetEditor == true || component.widgetType ==  'quantity' && isRentalEdit">
    <div class="form-row">
      <label class="mb-1 col-sm-12">Label</label>
      <div class="col-sm-12 mb-2">
        <input type="text" class="form-control form-control-sm" [(ngModel)]="component.element.label" />
      </div>
    </div>

    <div class="row mb-2">
      <label class="col-sm-12">Description</label>
      <div class="col-sm-12 mb-2">
        <textarea style="overflow-y: hidden;" [(ngModel)]="component.element.description"
          class="form-control form-control-sm" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1">
          </textarea>
      </div>
    </div>

    <div class="row mb-2">
      <label class="col-sm-12">Price</label>
      <div class="row" *ngIf="!isRentalEdit">
        <form>
          <div style="padding: 0px;">
            <input type="radio" id="oneTime" name="fav_language" 
                  (change)="component.element.recurringPrice = false; cleanRecurringPriceValues(false)" 
                  [checked]="!component.element.recurringPrice"
            >
            <label for="oneTime">One time charge</label>
          </div>
          <div style="padding: 0px;">
              <input type="radio" id="recur" name="fav_language" 
                (change)="component.element.recurringPrice = true; cleanRecurringPriceValues(true)" 
                [checked]="component.element.recurringPrice">
              <label for="recur">Recurring charge</label>
          </div>
            <br>
        </form>
      </div>

      <div class="col-sm-12" *ngIf="!component.element.recurringPrice; else recurringPriceTrue">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text">
            $
          </span>
          <input type="number" name="field_name" [(ngModel)]="component.element.price" class="form-control form-control-sm"
          oninput="this.value = this.value.replace(/[^0-9]/g, '');">
        </div>
      </div>

      <ng-template #recurringPriceTrue>
        <div class="col-sm-12">
          <div class="input-group input-group-sm mb-3">
            <span class="input-group-text">
              Per Day $
            </span>
            <input type="number" class="form-control" [(ngModel)]="component.element.price"
              oninput="this.value = this.value.replace(/[^0-9]/g, '')">
          </div>
        </div>
      </ng-template>
    </div>


    <div class="form-check" *ngIf="!isRentalEdit">
      <input type="checkbox" class="form-check-input" [(ngModel)]="component.element.is_required" id="is_required" />
      <label for="is_required" class="form-check-label">Set as required</label>
    </div>

    <div class="padding: 5px; margin-top: 5px;" *ngIf="!isRentalEdit">
      <div class="row" *ngIf="component.element.is_required">
        <label class="col-sm-6">Minimum Input</label>
        <div class="col-sm-6">
          <input type="number" min="0" class="form-control form-control-sm" [(ngModel)]="component.element.min"
            oninput="this.value = this.value.replace(/[^0-9]/g, '')" />
        </div>
      </div>
      <div class="row  mb-2" *ngIf="component.element.is_required">
        <label class="col-sm-6">Maximum Input</label>
        <div class="col-sm-6">
          <input type="number" min="0" class="form-control form-control-sm" [(ngModel)]="component.element.max"
            oninput="this.value = this.value.replace(/[^0-9]/g, '')" />
        </div>
      </div>
    </div>

  </div>

  <!---------- Text Area -------------->
  <div
    *ngIf="component.widgetType ==  'textarea' && component.widgetName == 'New' || component.widgetType == 'textarea' && savedWidgetEditor == true || component.widgetType ==  'textarea' && isRentalEdit">
    <div class="form-row">
      <label class="mb-1 col-sm-12">Please edit this widget by addding text directly to the display.</label>
      <div class="col-sm-12">
      </div>
    </div>
   

  </div>

  <!---------- Price -------------->
  <div
    *ngIf="component.widgetType ==  'price' && component.widgetName == 'New' || component.widgetType == 'price' && savedWidgetEditor == true || component.widgetType ==  'price' && isRentalEdit">
    <div class="row mb-2">
      <label class="mb-1 col-sm-12">Label</label>
      <div class="col-sm-12">
        <input type="text" class="form-control form-control-sm" [(ngModel)]="component.element.label"/>
      </div>
    </div>

    <div class="row mb-2">
      <label class="col-sm-12">Description</label>
      <div class="col-sm-12 mb-2">
        <textarea style="overflow-y: hidden;" [(ngModel)]="component.element.description"
          class="form-control form-control-sm" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1">
        </textarea>
      </div>
    </div>

    <div class="row mb-2">
      <label class="col-sm-12">Price</label>

      <div class="row" *ngIf="!isRentalEdit">
        <form>
          <div style="padding: 0px;">
            <input type="radio" id="oneTime" name="fav_language" 
                  (change)="component.element.recurringPrice = false; cleanRecurringPriceValues(false)" 
                  [checked]="!component.element.recurringPrice"
            >
            <label for="oneTime">One time charge</label>
          </div>
          <div style="padding: 0px;">
              <input type="radio" id="recur" name="fav_language" 
                (change)="component.element.recurringPrice = true; cleanRecurringPriceValues(true)" 
                [checked]="component.element.recurringPrice">
              <label for="recur">Recurring charge</label>
          </div>
            <br>
        </form>
      </div>

      

      <div class="col-sm-12" *ngIf="!component.element.recurringPrice; else recurringPriceTrue">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text">
            $
          </span>
          <input type="number" name="field_name" [(ngModel)]="component.element.price" class="form-control form-control-sm"
          oninput="this.value = this.value.replace(/[^0-9]/g, '');">
        </div>
      </div>

      <ng-template #recurringPriceTrue>
        <div class="col-sm-12">
          <div class="input-group input-group-sm mb-3">
            <span class="input-group-text">
              Per Day $
            </span>
            <input type="number" class="form-control" [(ngModel)]="component.element.price"
              oninput="this.value = this.value.replace(/[^0-9]/g, '')">
          </div>
        </div>
      </ng-template>

    </div>


  </div>

  <!---------- Image -------------->
  <div
    *ngIf="component.widgetType ==  'image' && component.widgetName == 'New' || component.widgetType == 'image' && savedWidgetEditor == true || component.widgetType ==  'image' && isRentalEdit">
    <button class="btn btn-file" (click)="openImgGallery()" data-bs-target="#imgGallery" type="button"
      style="width:150px !important">
      <i class="fa fa-picture-o" aria-hidden="true"></i> Image Library
    </button>
    <!-- <div class="row my-2">
      <label class="mb-1 col-sm-12">Alternative text</label>
      <div class="col-sm-12">
        <input
          class="form-control form-control-sm"
          [(ngModel)]="component.element.label"
        />
      </div>
    </div> -->
    <div class="row mb-2">
      <label class="mb-1 col-sm-12">Link</label>
      <div class="col-sm-12">
        <input type="text" class="form-control form-control-sm" [(ngModel)]="component.element.image_link" />
      </div>
    </div>
    <div class="row mb-2">
      <label class="col-sm-6">Image width</label>
      <div class="col-sm-6">
        <div class="input-group input-group-sm">
          <input type="number" class="form-control form-control-sm" max="100" min="1"
            [(ngModel)]="component.element.width" />
          <span class="input-group-text">%</span>
        </div>
      </div>
    </div>
  </div>

  <!--------- Radios -------->
  <div
    *ngIf="component.widgetType ==  'radios' && component.widgetName == 'New' || component.widgetType == 'radios' && savedWidgetEditor == true || component.widgetType ==  'radios' && isRentalEdit">
    <div class="form-row">
      <label class="mb-1 col-sm-12">Label</label>
      <div class="col-sm-12 mb-2">
        <input type="text" class="form-control form-control-sm" [(ngModel)]="component.element.label" />
      </div>
    </div>

    <div class="row mb-2">
      <label class="col-sm-12">Price</label>
      <div class="row" >
          <form>
            <div>
              <input type="radio" id="oneTime" name="fav_language" 
                    (change)="component.element.recurringPrice = false; cleanRecurringPriceValues(false)" 
                    [checked]="!component.element.recurringPrice"
              >
              <label for="oneTime">One time charge</label>
            </div>
            <div>
                <input type="radio" id="recur" name="fav_language" 
                  (change)="component.element.recurringPrice = true; cleanRecurringPriceValues(true)" 
                  [checked]="component.element.recurringPrice">
                <label for="recur">Recurring charge</label>
            </div>
              <br>
          </form>
        </div>

    </div>

    <div class="row">
      <label class="mb-1 col-sm-12">Options list</label>
      <div class="col-sm-12 mb-2">
        <table class="table table-sm">
          <tbody>
            <tr *ngFor="let option of component.element.options; let idx = index">
              <td class="text-center align-middle">
                <div>
                  <input type="radio" name="is_selected" [value]="option.id" [checked]="option.is_selected"
                    (change)="setAsDefault($event)" />
                </div>
                <button class="btn btn-sm text-danger" (click)="removeOption(option.id)"
                  [disabled]="component.element.options.length < 3">
                  <i class="fas fa-trash"></i>
                </button>
              </td>
              <td>
                <div class="input-group input-group-sm mb-1">
                  <span class="input-group-text option-key-value">Text</span>
                  <input type="text" class="form-control form-control-sm" [placeholder]="'Option'"
                    [(ngModel)]="option.text" />
                </div>
                <div class="input-group input-group-sm"
                  *ngIf="!component.element.recurringPrice; else recurringPriceTrue">
                  <span class="input-group-text option-key-value">$</span>

                    <input type="number" name="field_name" [(ngModel)]="option.price" [placeholder]="'Price'"
                    class="form-control form-control-sm"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '');">
                </div>
                <ng-template #recurringPriceTrue>
                  <div class="input-group input-group-sm">
                    <span class="input-group-text option-key-value">
                      $
                    </span>
                    <input type="number" class="form-control form-control-sm" [placeholder]="'Price per day'"
                      [(ngModel)]="option.price" oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                  </div>
                </ng-template>
              </td>
            </tr>
          </tbody>
        </table>
        <button class="btn btn-link btn-sm" (click)="addOption()">
          <i class="fas fa-plus me-2"></i><span>Add option</span>
        </button>
      </div>
    </div>
    <!-- <div class="form-check">
      <input type="checkbox" class="form-check-input" [(ngModel)]="component.element.is_required" id="is_required" />
      <label for="is_required" class="form-check-label">Set as required</label>
    </div> -->


  </div>

  <!------------ Dropdowns ------------->
  <div
    *ngIf="component.widgetType == 'dropdown' && component.widgetName == 'New' || component.widgetType == 'dropdown' && savedWidgetEditor == true || component.widgetType == 'dropdown' && isRentalEdit">

    <div class="row mb-2">
      <div class="col-sm-12">
        <label class="mb-1">Label</label>
      </div>
      <div class="col-sm-12">
        <input type="text" class="form-control form-control-sm" [(ngModel)]="component.element.label" />
      </div>
    </div>

    <div class="row mb-2">
      <label class="col-sm-12">Price</label>
      <div class="row" >
        <form>
          <div>
            <input type="radio" id="oneTime" name="fav_language" 
                  (change)="component.element.recurringPrice = false; cleanRecurringPriceValues(false)" 
                  [checked]="!component.element.recurringPrice"
            >
            <label for="oneTime">One time charge</label>
          </div>
          <div>
              <input type="radio" id="recur" name="fav_language" 
                (change)="component.element.recurringPrice = true; cleanRecurringPriceValues(true)" 
                [checked]="component.element.recurringPrice">
              <label for="recur">Recurring charge</label>
          </div>
            <br>
        </form>
      </div>

    </div>
    <div class="row mb-2">
      <label class="mb-1 col-sm-12">Options list</label>
      <div class="col-sm-12">
        <table class="table table-sm">
          <tbody>
            <tr *ngFor="let option of component.element.options; let idx = index;">
              <td class="text-center align-middle">
                <div>
                  <input type="radio" name="is_selected" [value]="option.id" [checked]="option.is_selected"
                    (change)="setAsDefault($event)" />
                </div>
                <button class="btn btn-sm text-danger" (click)="removeOption(option.id)"
                  [disabled]="component.element.options.length < 3">
                  <i class="fas fa-trash"></i>
                </button>
              </td>
              <td>
                <div class="input-group input-group-sm mb-1">
                  <span class="input-group-text option-key-value">Text</span>
                  <input type="text" class="form-control" [placeholder]="'Option'" [(ngModel)]="option.text" />
                </div>

                <div class="input-group input-group-sm"
                  *ngIf="!component.element.recurringPrice; else recurringPriceTrue">
                  <span class="input-group-text option-key-value">$</span>

                  <input type="number" name="field_name" [(ngModel)]="option.price" [placeholder]="'Price'"
                  class="form-control form-control-sm" 
                  oninput="this.value = this.value.replace(/[^0-9]/g, '');">
                </div>
                <ng-template #recurringPriceTrue>
                  <div class="input-group input-group-sm">
                    <span class="input-group-text option-key-value">
                      $
                    </span>
                    <input type="number" class="form-control form-control-sm" [placeholder]="'Price per day'"
                      [(ngModel)]="option.price" oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                  </div>
                </ng-template>
              </td>
            </tr>
          </tbody>
        </table>
        <button class="btn btn-link btn-sm" (click)="addOption()">
          <i class="fas fa-plus me-2"></i> <span>Add option</span>
        </button>
      </div>
    </div>
    <!-- <div class="form-check">
      <input type="checkbox" class="form-check-input" [(ngModel)]="component.element.is_required" id="is_required" />
      <label for="is_required" class="form-check-label">Set as required</label>
    </div> -->

  </div>

  <!---------- Product -------------->
  <div
    *ngIf="component.widgetType == 'product' && component.widgetName == 'New' || component.widgetType == 'product' && savedWidgetEditor == true || component.widgetType == 'product' && isRentalEdit">

    <div class="row mb-1">
      <label class="mb-1 col-sm-12">Product Name</label>
      <div class="col-sm-12 mb-1" *ngIf="!isRentalEdit; else editingRentalProductWidget">
      
        <select id="dropdown_{{ component.widgetName }}" class="form-select" [(ngModel)]="selectedGroup" (change)="setProductsValue();">
          <ng-container *ngIf="!selectedGroup">
            <option [ngValue]="null" selected>- Please select a product group-</option>
            <option [ngValue]="choice" *ngFor="let choice of allProductGroups">
              {{ choice.groupName }}
            </option>
          </ng-container>
          <ng-container *ngIf="selectedGroup">
            <option [ngValue]="choice" *ngFor="let choice of allProductGroups">
              {{ choice.groupName }}
            </option>
          </ng-container>
        </select>
      </div>
      <ng-template #editingRentalProductWidget>
        <div class="col-sm-12 mb-1">
          <select id="dropdown_{{ component.widgetName }}" class="form-select" [(ngModel)]="selectedGroup" disabled>
              <option [ngValue]="choice" *ngFor="let choice of allProductGroups">
                {{ choice.groupName }}
              </option>
          </select>
        </div>
      </ng-template>
    </div>
    <div *ngIf="component.element.options.length > 0 && !isRentalEdit">
      <div class="mb-1 form-switch">
        <input type="checkbox" class="form-check-input" [(ngModel)]="component.element.isDropdown" id="is_select"
          [disabled]="component.element.options.length == 0" />
        <label for="is_select" class="form-check-label">Dropdown Mode</label>
      </div>
    </div>
    <hr *ngIf="component.element.options.length > 0 && !isRentalEdit">
    <div class="row mb-2" *ngIf="component.element.isDropdown">
      <div class="col-sm-12">
        <label class="mb-1">Label</label>
      </div>

      <div class="col-sm-12">
        <input type="text" class="form-control form-control-sm" [(ngModel)]="component.element.label" />
      </div>
    </div>
    <div class="row mb-2" *ngIf="component.element.isDropdown">
      <div class="form-group">
        <div class="mb-2 form-check">
          <input type="checkbox" class="form-check-input" [(ngModel)]="component.element.show_price_label"
            id="show_show_price_labelprice" [disabled]="component.element.options.length == 0" />
          <label for="show_price_label" class="form-check-label">Show price on Label</label>
        </div>
        <div class="mb-2 form-check">
          <input type="checkbox" class="form-check-input" [(ngModel)]="component.element.show_name"
            id="show_name" [disabled]="component.element.options.length == 0" />
          <label for="show_name" class="form-check-label">Show Product Name</label>
        </div>
      </div>
    </div>
    <hr *ngIf="component.element.isDropdown">
    <div class="row mb-2" *ngIf="component.element.options.length > 0 && !isRentalEdit">
      <div class="col-sm-12">
        <label class="mb-1">Display Product Size</label>
      </div>
      <div class="form-group">
        <div>
          <div *ngFor="let option of component.element.options; index as ind">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" [(ngModel)]="option.show" id="groupSize" />
              <label for="groupSize" class="form-check-label">{{option.size}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="component.element.options.length > 0 && !isRentalEdit">
      <div>
        <hr>
        <div class="mb-1 form-check" *ngIf="!component.isCartWidget">
          <input type="checkbox" class="form-check-input" [(ngModel)]="component.element.is_required" id="is_required"
            [disabled]="component.element.options.length == 0" />
          <label for="is_required" class="form-check-label">Set as required</label>
        </div>
        <div class="mb-2 padding: 8px;">
          <div class="row mb-1" *ngIf="component.element.is_required && !component.element.isDropdown">
            <label class="col-sm-6">Minimum Input</label>
            <div class="col-sm-6">
              <input type="number" min="0" class="form-control form-control-sm" [(ngModel)]="component.element.min"
                oninput="this.value = this.value.replace(/[^0-9]/g, '')" />
            </div>
          </div>
        </div>


        <div class="mb-2 form-check">
          <input type="checkbox" class="form-check-input" [(ngModel)]="component.element.show_description"
            id="show_description" [disabled]="component.element.options.length == 0" />
          <label for="show_description" class="form-check-label">Show description</label>
        </div>
        <div class="mb-2 form-check">
          <input type="checkbox" class="form-check-input" [(ngModel)]="component.element.show_price" id="show_price"
            [disabled]="component.element.options.length == 0" />
          <label for="show_price" class="form-check-label">Show price</label>
        </div>

        <div class="mb-1 form-check">
          <input type="checkbox" class="form-check-input" [(ngModel)]="component.element.appliesToTourProductCount"
            id="for_widget_total" [disabled]="component.element.options.length == 0" />
          <label for="for_widget_total" class="form-check-label">Counts towards tour widget total</label>
        </div>
    
        <hr>
      </div>
      <div>
        <div
        style="margin-top: 12px;">
          <div>
            <div *ngIf="!isRentalEdit">
              <div  style="padding: 0px; margin-bottom: 10px; font-weight: 500; font-size: 14px">
                  Widget Price 
              </div>
                <div>

                  <ng-container
                  *ngFor="let priceday of component.element.priceByDay; let i = index">

                  <div class="row">
                  <div class="col-6" style="margin-bottom: 0px; padding-bottom: 0px;">
                      <div class="input-group input-group-sm mb-3">
                          <span class="input-group-text"
                            >Day
                            </span>
                          <input type="text" class="form-control" disabled [(ngModel)]="component.element.priceByDay[i].day" 
                            (change)="productPriceChanged(i)"
                            >
                      </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text"
                            >Price
                        </span>
                        <input type="number" class="form-control" [(ngModel)]="component.element.priceByDay[i].price"
                          oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                            
                    </div>
                </div>
                    <div class="col-sm-7">
                      <label>Increment Per Day</label>
                    </div>
                    <div class="col-sm-5">
                      <input type="number" class="form-control form-control-sm "  
                        [(ngModel)]="component.element.priceByDay[i].increment"
                        oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                    </div>
                  </div>

              </ng-container>
            <hr>
          <ng-container>
            <div class="row">

              <div class="col-6">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text">
                          Hour
                        </span>
                      <input type="text" class="form-control" disabled 
                        [(ngModel)]="component.element.priceByHour[0].hour"
                        oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                    </div>
                </div>
                <div class="col-6">
                  <div class="input-group input-group-sm mb-3">
                      <span class="input-group-text"
                          >Price
                      </span>
                      <input type="number" class="form-control" 
                        [(ngModel)]="component.element.priceByHour[0].price"
                        oninput="this.value = this.value.replace(/[^0-9]/g, '')">   
                  </div>
                </div>
              </div>
              <div class="row">
                  <div class="col-sm-7">
                    <label>Increment Per Hour</label>
                  </div>
                  <div class="col-sm-5">
                    <input type="number" class="form-control form-control-sm "  
                      [(ngModel)]="component.element.priceByHour[0].increment"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                  </div>
              </div>
          </ng-container>

                      <!-- <div class="row mb-2">
                <label for="" class="col-sm-6">Font Size</label>
                <div class="col-sm-6">
                  <input
                    type="number"
                    min="0"
                    class="form-control form-control-sm"
                    [(ngModel)]="component.componentSettings.text_size"
                  />
                </div>
                  </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isRentalEdit">
      <label class="col-sm-12">Price</label>

      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text">
          $
        </span>
        <input type="number" name="field_name" [(ngModel)]="component.element.price" class="form-control form-control-sm"
        oninput="this.value = this.value.replace(/[^0-9]/g, '');">
      </div>
    </div>

  </div>

  <!---------- Height Input -------------->
  <div
    *ngIf="component.widgetType == 'heightInput' && component.widgetName == 'New' || component.widgetType == 'heightInput' && savedWidgetEditor == true || component.widgetType == 'heightInput' && isRentalEdit">
    <div class="row mb-2">
      <label class="mb-1 col-sm-12">Label</label>
      <div class="col-sm-12">
        <input type="text" class="form-control form-control-sm" [(ngModel)]="component.element.label"/>
      </div>
    </div>

    <div class="row mb-2">
      <label class="col-sm-12">Description</label>
      <div class="col-sm-12 mb-2">
        <textarea style="overflow-y: hidden;" [(ngModel)]="component.element.description"
          class="form-control form-control-sm" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1">
        </textarea>
      </div>
    </div>
    <div class="form-check mb-2" *ngIf="!component.isCartWidget && !isRentalEdit">
      <input type="checkbox" class="form-check-input" [(ngModel)]="component.element.is_required" id="is_required" />
      <label for="is_required" class="form-check-label">Set as required</label>
    </div>

  </div>

    <!---------- Weight Input -------------->
  <div
    *ngIf="component.widgetType == 'weightInput' && component.widgetName == 'New' || component.widgetType == 'weightInput' && savedWidgetEditor == true || component.widgetType == 'weightInput' && isRentalEdit">
    <div class="row mb-2">
      <label class="mb-1 col-sm-12">Label</label>
      <div class="col-sm-12">
        <input type="text" class="form-control form-control-sm" [(ngModel)]="component.element.label"/>
      </div>
    </div>

    <div class="row mb-2">
      <label class="col-sm-12">Description</label>
      <div class="col-sm-12 mb-2">
        <textarea style="overflow-y: hidden;" [(ngModel)]="component.element.description"
          class="form-control form-control-sm" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1">
        </textarea>
      </div>
    </div>
    <div class="form-check mb-2" *ngIf="!component.isCartWidget && !isRentalEdit">
      <input type="checkbox" class="form-check-input" [(ngModel)]="component.element.is_required" id="is_required" />
      <label for="is_required" class="form-check-label">Set as required</label>
    </div>

  </div>

  <!---------- Tip -------------->
  <div
    *ngIf="component.widgetType ==  'tip' && component.widgetName == 'New' || component.widgetType == 'tip' && savedWidgetEditor == true || component.widgetType ==  'tip' && isRentalEdit">
    <div class="row mb-2">
      <label class="mb-1 col-sm-12">Label</label>
      <div class="col-sm-12">
        <input type="text" class="form-control form-control-sm" [(ngModel)]="component.element.label"/>
      </div>
    </div>

    <div class="row mb-2">
      <label class="col-sm-12">Description</label>
      <div class="col-sm-12 mb-2">
        <textarea style="overflow-y: hidden;" [(ngModel)]="component.element.description"
          class="form-control form-control-sm" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1">
        </textarea>
      </div>
    </div>

    <div class="row mb-2">
      <label class="col-sm-12">Price</label>

      <div class="col-sm-12">
        <div class="input-group input-group-sm mb-3">
          <input [ngClass]="{'is-invalid': component.element.invalid}" type="number" name="field_name" [(ngModel)]="component.element.price" class="form-control form-control-sm"
          oninput="if(parseInt(this.value) > 100 ) this.value = '100'; else if((parseInt(this.value) <= 0)) this.value = '1';">
          <span class="input-group-text">
            %
          </span>
        </div>
        <span *ngIf="component.element.invalid" class="form-text text-danger">
          <i class="fas fa-info-circle"></i> The percentage must be greater than zero
        </span>
      </div>

    </div>


  </div>

  <!------- Widget Settings Accordian ------->
  <span *ngIf="!savedWidgetEditor && !isRentalEdit">
    <span *ngIf="component.widgetName == 'New' && component && component.widgetType !== 'section' &&
      component.widgetType !== 'image' && component.widgetType !== 'textarea'">
      <hr />
    </span>

    <div
      *ngIf="component && component.widgetType !== 'section' && component.widgetType !== 'image' && component.widgetType !== 'textarea'">
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <span class="accordion-header" id="headingOne" style="padding: 0px; margin: 0px;">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseWidgetSettings" style="font-weight: 500; font-size: 14px;">
              Widget Settings
            </button>
          </span>
          <!-- <h4 style="font-weight: bold"></h4> -->
          <div id="collapseWidgetSettings" class="accordion-collapse collapse" aria-labelledby="headingOne"
            data-bs-parent="#myAccordian">
            <div class="accordion-body">

              <div class="row mb-2">
                <label for="" class="col-sm-6">Background color</label>
                <div class="col-sm-6">
                  <div class="input-group">
                    <span class="input-group-text" [style.background]="
                    component.componentSettings.background_color
                    "></span>

                    <input class="form-control form-control-sm" [(colorPicker)]="
                    component.componentSettings.background_color
                    " [cpPosition]="'bottom'" [cpDisableInput]="true" [cpAlphaChannel]="'disabled'"
                      [cpPositionOffset]="'-100%'" [value]="component.componentSettings.background_color" />
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <label for="" class="col-sm-6">Border</label>
                <div class="col-sm-6">
                  <input type="checkbox" [(ngModel)]="component.componentSettings.border" />
                </div>
              </div>
              <div class="row mb-2">
                <label for="" class="col-sm-6">Rounded Corners</label>
                <div class="col-sm-6">
                  <input type="number" min="0" class="form-control form-control-sm"
                    [(ngModel)]="component.componentSettings.rounded_corners" />
                </div>
              </div>
              <div class="row mb-2" *ngIf="component.widgetType !== 'spacer'">
                <label for="" class="col-sm-6">Height</label>
                <div class="col-sm-6">
                  <input type="number" min="0" class="form-control form-control-sm"
                    [(ngModel)]="component.componentSettings.height" />
                </div>
              </div>
              <div class="row" style="padding:0px 3px">
                <label for="" class="col-sm-12 mb-1">The inner padding</label>
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-3 text-center" style="padding:0px">
                      <input type="number" min="0" class="form-control form-control-sm"
                        [(ngModel)]="component.componentSettings.padding_top" />
                      <span>Top</span>
                    </div>
                    <div class="col-sm-3 text-center" style="padding:0px">
                      <input type="number" min="0" class="form-control form-control-sm"
                        [(ngModel)]="component.componentSettings.padding_bottom" />
                      <span>Bottom</span>
                    </div>
                    <div class="col-sm-3 text-center" style="padding:0px">
                      <input type="number" min="0" class="form-control form-control-sm"
                        [(ngModel)]="component.componentSettings.padding_left" />
                      <span>Left</span>
                    </div>
                    <div class="col-sm-3 text-center" style="padding:0px">
                      <input type="number" min="0" class="form-control form-control-sm"
                        [(ngModel)]="component.componentSettings.padding_right" />
                      <span>Right</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
        <label for="" class="col-sm-12 mb-1">Alignment</label>
        <div class="col-sm-12">
          <div class="row">
            <mat-button-toggle-group name="fontStyle" aria-label="Font Style"
            [(ngModel)]="component.componentSettings.alignment"
            (change)="printSome()">
              <mat-button-toggle value="align-left"><i class="fas fa-align-left"></i></mat-button-toggle>
              <mat-button-toggle value="align-middle"><i class="fas fa-align-center"></i></mat-button-toggle>
              <mat-button-toggle value="align-right"><i class="fas fa-align-right"></i></mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div> -->


    <div
      *ngIf="component && component.widgetType !== 'section' && component.widgetType !== 'image' 
            && component.widgetType !== 'textarea'"
      style="margin-top: 12px;">
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <span class="accordion-header" id="headingOne" style="padding: 0px; margin: 0px;">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseFontSettings" style="font-weight: 500; font-size: 14px;">
              Font Settings
            </button>
          </span>
          <div id="collapseFontSettings" class="accordion-collapse collapse" aria-labelledby="headingOne"
            data-bs-parent="#myAccordian">
            <div class="accordion-body">
              <div class="row mb-2">
                <label for="" class="col-sm-6">Font color</label>
                <div class="col-sm-6">
                  <div class="input-group">
                    <span class="input-group-text" [style.background]="
                      component.componentSettings.text_color
                      "></span>

                    <input class="form-control form-control-sm" [(colorPicker)]="
                      component.componentSettings.text_color
                      " [cpPosition]="'bottom'" [cpDisableInput]="true" [cpAlphaChannel]="'disabled'"
                      [cpPositionOffset]="'-100%'" [value]="component.componentSettings.text_color" />
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <label for="" class="col-sm-6">Font Size</label>
                <div class="col-sm-6">
                  <input type="number" min="0" class="form-control form-control-sm"
                    [(ngModel)]="component.componentSettings.text_size" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </span>

  <span *ngIf="!savedWidgetEditor && !isRentalEdit">
    <hr>
  </span>

  <div style="margin-top: 5px;" *ngIf="component.widgetName == 'New' && !isRentalEdit">
    <button type="button"  class="btn btn-sm btn-primary" (click)="saveAsCompanyWidget()">Create Saved Widget
    </button>
  </div>
  <div style="margin-top: 5px;" *ngIf="component.widgetName != 'New' && component.savedWidgetId && !isRentalEdit">
    <!-- <a href="#">Edit Widget</a> -->
    <div *ngIf="!savedWidgetEditor">
      <button
      class="btn btn-sm btn-primary"
      (click)="onEditSavedWidgetClick()"
      > Edit Widget
      </button>
    </div>

  </div>
  

  <!----------- Image Library ------------>
  <div class="modal fade" data-bs-backdrop="false" id="imgGallery">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 90vw; max-height: 90vh;">
      <div class="modal-content">
        <div class="modal-body">
          <!-- Button used to close the modal -->
          <div style="position: relative; width: 100%">
            <button id="closeImgLibraryBtn" type="button" class="btn btn-danger rowBtn" data-bs-dismiss="modal">
              <i class="fa fa-close"></i>
            </button>
          </div>
          <!-- The image library component -->
          <!-- defaultTabSelection determines what tab should be active by default -->
          <!-- showSelect bool set to true if the select option needs to be active upon clicking on img -->
          <app-image-library [showSelect]="true" [defaultTabSelection]="'image'"></app-image-library>
        </div>
      </div>
    </div>
  </div>


</span>
