<div *ngIf="isDataAvailable">

<!------------------- Real Display of All Widgets --------------------------------->
    <ng-template #recursiveList let-elements>
    
      <div *ngFor="let item of elements; let i = index"
        class="align-middle"
        style="margin: 5px 0px">
  
        <div class="align-middle">

          <div *ngIf="!isRentalEdit">
            <app-inventory-widget-display [widget]="item" [displayID]="groupInventoryPage['displayID']"
              (widgetInputChange)="onWidgetInputChanged($event)" (productInputChange)="onProductInputChange($event)">
            </app-inventory-widget-display></div>
        </div>

        
          <div *ngIf="isRentalEdit" class="row d-flex justify-content-center text-center align-items-center">
            <div class="col-md-10" style="padding-right:0px">
              <app-inventory-widget-display [widget]="item" [displayID]="groupInventoryPage['displayID']"
                (widgetInputChange)="onWidgetInputChanged($event)" (productInputChange)="onProductInputChange($event)">
              </app-inventory-widget-display>
            </div>
            <div class="col-md-2 d-flex justify-content-center">
              <div style="margin-right: 6px"> 
                <button type="button" class="btn btn-sm btn-success" (click)="editWidget.emit(i)"><i
                  class="fa fa-pencil"></i>
                </button>
              </div>
              <button type="button" class="btn btn-sm btn-danger" (click)="deleteWidget.emit({item: item, index: i})"><i
                class="fas fa-trash"></i>
              </button>
            </div>
          </div>

      </div>
    </ng-template>
  
    <!------------- Calender ----------------->

      <!-- <div class="col-sm-3 dateSelectArea" style="padding-right: 20px;" >
        <mat-card class="demo-inline-calendar-card" style="margin-top: 10px;">
          <mat-calendar [(selected)]="selectedDate"></mat-calendar>
        </mat-card>
        <p>
          Selected date: {{selectedDate}}
        </p>
      </div> -->

        <div *ngIf="!cartWidgets" 
          class="form-body" style="padding:20px;">
          <div class="mx-auto widgetList-wrapper" style="justify-content: center;">
              <div *ngIf="!isRentalEdit && !isRentalAdd"
                style="background-color: rgb(240, 240, 240); padding: 8px 0px; border-radius: 8px 8px 0px 0px">
                <span style="margin-left: 10px;">
                  <b>
                    <ng-container *ngIf="!customHeader">
                      {{numberIndex}} - {{groupName}}, {{selectedProduct?.sizeName}}
                    </ng-container>
                  
                    <ng-container *ngIf="customHeader">
                      {{customHeader}}
                    </ng-container>
                  </b>
                </span>
              </div>
              <div style="padding: 10px;">

              <ng-container
              *ngTemplateOutlet="
                recursiveList;
                context: {
                  $implicit: groupInventoryPage.widgetList
                }
              "
              ></ng-container>
            </div>

            <div *ngIf="isRentalEdit" style="padding: 8px;">
              <a class="text-primary linkaddproduct" style="margin-bottom: 5px !important;" id="linkaddproduct"
              (click)="addWidgetToProduct.emit($event)"><i class="fas fa-plus-circle"></i>
              Add Widget</a>
            </div>

            </div>
          </div>
  
          <div *ngIf="cartWidgets"
            class="form-body" >
            <div class="mx-auto widgetList-wrapper" style="justify-content: center; padding: 20px;">
              <!-- <div style="background-color: rgb(240, 240, 240); padding: 8px 0px; border-radius: 8px 8px 0px 0px">
                <span style="margin-left: 10px;">
                  <b>Add-on</b>
                </span>
              </div> -->
              <ng-container
              *ngTemplateOutlet="
                recursiveList;
                context: {
                  $implicit: groupInventoryPage.widgetList
                }
              "
              ></ng-container>
            </div>
          </div>


  
  </div>
  
  