import { Injectable } from '@angular/core';
import * as seedrandom from 'seedrandom';
import { ColorTheme } from './colorTheme.service';
import { CurrentUserService } from './current-user.service';

@Injectable({
  providedIn: 'root',
})
export class ColorsService {
  public Colors = {};
  public users;
  public itemcolor;
  public UserId;
  public colorFontheme;
  public colorText;
  constructor(
    private UserCurrent: CurrentUserService,
    public ColorTheme: ColorTheme
  ) {}

  public initColors(firstName, lastname) {
    this.UserId = this.UserCurrent.currentUser.color ? this.UserCurrent.currentUser.color.value : '#114463'; // default color
    this.colorText = this.UserCurrent.currentUser.color ? this.UserCurrent.currentUser.color.fontcolor : '#FFFFFF'; // default font color
    let key = firstName + ' ' + lastname;
    let seedNumber = seedrandom(key)();
    console.log(this.colorText);

    if (this.UserId === '#114463') {
      if (this.Colors[key] !== undefined) return;
      if (seedNumber < 0.05) {
        this.Colors[key] = '#114463';
      } else if (seedNumber < 0.1) {
        this.Colors[key] = '#0d4b70';
      } else if (seedNumber < 0.15) {
        this.Colors[key] = '#045585';
      } else if (seedNumber < 0.2) {
        this.Colors[key] = '#036ead';
      } else if (seedNumber < 0.25) {
        this.Colors[key] = '#1a79b0';
      } else if (seedNumber < 0.3) {
        this.Colors[key] = '#2874a1';
      } else if (seedNumber < 0.35) {
        this.Colors[key] = '#367396';
      } else if (seedNumber < 0.4) {
        this.Colors[key] = '#478cb5';
      } else if (seedNumber < 0.45) {
        this.Colors[key] = '#538cad';
      } else if (seedNumber < 0.5) {
        this.Colors[key] = '#608ca6';
      } else if (seedNumber < 0.55) {
        this.Colors[key] = '#71add1';
      } else if (seedNumber < 0.6) {
        this.Colors[key] = '#7dc0e8';
      } else if (seedNumber < 0.65) {
        this.Colors[key] = '#11405c';
      } else if (seedNumber < 0.7) {
        this.Colors[key] = '#387aa1';
      } else if (seedNumber < 0.75) {
        this.Colors[key] = '#002033';
      } else if (seedNumber < 0.8) {
        this.Colors[key] = '#114869';
      } else if (seedNumber < 0.85) {
        this.Colors[key] = '#8f9dbf';
      } else if (seedNumber < 0.9) {
        this.Colors[key] = '#052166';
      } else if (seedNumber < 0.95) {
        this.Colors[key] = '#98b4fa';
      } else {
        this.Colors[key] = '#7a96de';
      }
      this.colorText;
    } else if (this.UserId === '#E5E1DA') {
      if (this.Colors[key] !== undefined) return;
      if (seedNumber < 0.05) {
        this.Colors[key] = '#e8ca8b';
      } else if (seedNumber < 0.1) {
        this.Colors[key] = '#c2a26b';
      } else if (seedNumber < 0.15) {
        this.Colors[key] = '#dbd4c8';
      } else if (seedNumber < 0.2) {
        this.Colors[key] = '#cfc5b4';
      } else if (seedNumber < 0.25) {
        this.Colors[key] = '#c7baa5';
      } else if (seedNumber < 0.3) {
        this.Colors[key] = '#d4c8b4';
      } else if (seedNumber < 0.35) {
        this.Colors[key] = '#bdaf97';
      } else if (seedNumber < 0.4) {
        this.Colors[key] = '#d1be9d';
      } else if (seedNumber < 0.45) {
        this.Colors[key] = '#ffeac7';
      } else if (seedNumber < 0.5) {
        this.Colors[key] = '#ccb691';
      } else if (seedNumber < 0.55) {
        this.Colors[key] = '#decaa6';
      } else if (seedNumber < 0.6) {
        this.Colors[key] = '#cfbea3';
      } else if (seedNumber < 0.65) {
        this.Colors[key] = '#d9b275';
      } else if (seedNumber < 0.7) {
        this.Colors[key] = '#d9ba89';
      } else if (seedNumber < 0.75) {
        this.Colors[key] = '#ebc994';
      } else if (seedNumber < 0.8) {
        this.Colors[key] = '#f0d1a1';
      } else if (seedNumber < 0.85) {
        this.Colors[key] = '#f0d7b1';
      } else if (seedNumber < 0.9) {
        this.Colors[key] = '#f5e1c4';
      } else if (seedNumber < 0.95) {
        this.Colors[key] = '#f7e8d2';
      } else {
        this.Colors[key] = '#ede3d3';
      }
      this.colorText;
    } else if (this.UserId === '#C2B59B') {
      if (this.Colors[key] !== undefined) return;

      if (seedNumber < 0.05) {
        this.Colors[key] = '#cfc8ba';
      } else if (seedNumber < 0.1) {
        this.Colors[key] = '#d1c7b4';
      } else if (seedNumber < 0.15) {
        this.Colors[key] = '#dbceb4';
      } else if (seedNumber < 0.2) {
        this.Colors[key] = '#dbcbab';
      } else if (seedNumber < 0.25) {
        this.Colors[key] = '#d6c29a';
      } else if (seedNumber < 0.3) {
        this.Colors[key] = '#d6bf90';
      } else if (seedNumber < 0.35) {
        this.Colors[key] = '#d6bb83';
      } else if (seedNumber < 0.4) {
        this.Colors[key] = '#dbbd7d';
      } else if (seedNumber < 0.45) {
        this.Colors[key] = '#e6c277';
      } else if (seedNumber < 0.5) {
        this.Colors[key] = '#f7cc72';
      } else if (seedNumber < 0.55) {
        this.Colors[key] = '#e3b85d';
      } else if (seedNumber < 0.6) {
        this.Colors[key] = '#c9a24f';
      } else if (seedNumber < 0.65) {
        this.Colors[key] = '#cfac63';
      } else if (seedNumber < 0.7) {
        this.Colors[key] = '#c4a76a';
      } else if (seedNumber < 0.75) {
        this.Colors[key] = '#dbc597';
      } else if (seedNumber < 0.8) {
        this.Colors[key] = '#cfbd97';
      } else if (seedNumber < 0.85) {
        this.Colors[key] = '#bfb091';
      } else if (seedNumber < 0.9) {
        this.Colors[key] = '#c9bca1';
      } else if (seedNumber < 0.95) {
        this.Colors[key] = '#e3d8c1';
      } else {
        this.Colors[key] = '#C2B59B';
      }
      this.colorText;
    } else if (this.UserId === '#586775') {
      if (this.Colors[key] !== undefined) return;

      if (seedNumber < 0.05) {
        this.Colors[key] = '#6d7e8f';
      } else if (seedNumber < 0.1) {
        this.Colors[key] = '#515a61';
      } else if (seedNumber < 0.15) {
        this.Colors[key] = '#b3bfc9';
      } else if (seedNumber < 0.2) {
        this.Colors[key] = '#677c8f';
      } else if (seedNumber < 0.25) {
        this.Colors[key] = '#8a9cab';
      } else if (seedNumber < 0.3) {
        this.Colors[key] = '#7a8e9e';
      } else if (seedNumber < 0.35) {
        this.Colors[key] = '#778ea1';
      } else if (seedNumber < 0.4) {
        this.Colors[key] = '#68849c';
      } else if (seedNumber < 0.45) {
        this.Colors[key] = '#58738a';
      } else if (seedNumber < 0.5) {
        this.Colors[key] = '#4e6d87';
      } else if (seedNumber < 0.55) {
        this.Colors[key] = '#486985';
      } else if (seedNumber < 0.6) {
        this.Colors[key] = '#3e607d';
      } else if (seedNumber < 0.65) {
        this.Colors[key] = '#466f91';
      } else if (seedNumber < 0.7) {
        this.Colors[key] = '#5181a8';
      } else if (seedNumber < 0.75) {
        this.Colors[key] = '#1b354a';
      } else if (seedNumber < 0.8) {
        this.Colors[key] = '#586f82';
      } else if (seedNumber < 0.85) {
        this.Colors[key] = '#306591';
      } else if (seedNumber < 0.9) {
        this.Colors[key] = '#6da0c9';
      } else if (seedNumber < 0.95) {
        this.Colors[key] = '#668ead';
      } else {
        this.Colors[key] = '#586775';
      }
      this.colorText;
    } else if (this.UserId === '#000000') {
      if (this.Colors[key] !== undefined) return;

      if (seedNumber < 0.05) {
        this.Colors[key] = '#000000';
      } else if (seedNumber < 0.1) {
        this.Colors[key] = '#0a0a0a';
      } else if (seedNumber < 0.15) {
        this.Colors[key] = '#242323';
      } else if (seedNumber < 0.2) {
        this.Colors[key] = '#3b3939';
      } else if (seedNumber < 0.25) {
        this.Colors[key] = '#4a4848';
      } else if (seedNumber < 0.3) {
        this.Colors[key] = '#121111';
      } else if (seedNumber < 0.35) {
        this.Colors[key] = '#7d7a7a';
      } else if (seedNumber < 0.4) {
        this.Colors[key] = '#211f1f';
      } else if (seedNumber < 0.45) {
        this.Colors[key] = '#ada8a8';
      } else if (seedNumber < 0.5) {
        this.Colors[key] = '#171313';
      } else if (seedNumber < 0.55) {
        this.Colors[key] = '#332c2c';
      } else if (seedNumber < 0.6) {
        this.Colors[key] = '#1a1414';
      } else if (seedNumber < 0.65) {
        this.Colors[key] = '#0f0a0a';
      } else if (seedNumber < 0.7) {
        this.Colors[key] = '#0a0404';
      } else if (seedNumber < 0.75) {
        this.Colors[key] = '#120f0f';
      } else if (seedNumber < 0.8) {
        this.Colors[key] = '#050000';
      } else if (seedNumber < 0.85) {
        this.Colors[key] = '#383636';
      } else if (seedNumber < 0.9) {
        this.Colors[key] = '#9c9898';
      } else if (seedNumber < 0.95) {
        this.Colors[key] = '#211e1e';
      } else {
        this.Colors[key] = '#403e3e';
      }
      this.colorText;
    } else if (this.UserId === '#FFFFFF') {
      if (this.Colors[key] !== undefined) return;

      if (seedNumber < 0.05) {
        this.Colors[key] = '#949191';
      } else if (seedNumber < 0.1) {
        this.Colors[key] = '#c4c4c4';
      } else if (seedNumber < 0.15) {
        this.Colors[key] = '#cfcfcf';
      } else if (seedNumber < 0.2) {
        this.Colors[key] = '#bdb7b7';
      } else if (seedNumber < 0.25) {
        this.Colors[key] = '#9e9d9d';
      } else if (seedNumber < 0.3) {
        this.Colors[key] = '#b5b5b5';
      } else if (seedNumber < 0.35) {
        this.Colors[key] = '#a3a0a0';
      } else if (seedNumber < 0.4) {
        this.Colors[key] = '#bfbfbf';
      } else if (seedNumber < 0.45) {
        this.Colors[key] = '#c9cdd1';
      } else if (seedNumber < 0.5) {
        this.Colors[key] = '#b6b8ba';
      } else if (seedNumber < 0.55) {
        this.Colors[key] = '#b5b5b5';
      } else if (seedNumber < 0.6) {
        this.Colors[key] = '#bec0c2';
      } else if (seedNumber < 0.65) {
        this.Colors[key] = '#c8d5e0';
      } else if (seedNumber < 0.7) {
        this.Colors[key] = '#a6a6a6';
      } else if (seedNumber < 0.75) {
        this.Colors[key] = '#899091';
      } else if (seedNumber < 0.8) {
        this.Colors[key] = '#7c888a';
      } else if (seedNumber < 0.85) {
        this.Colors[key] = '#787f80';
      } else if (seedNumber < 0.9) {
        this.Colors[key] = '#8b8c8c';
      } else if (seedNumber < 0.95) {
        this.Colors[key] = '#9e9e9e';
      } else {
        this.Colors[key] = '#aeb0b0';
      }
      this.colorText;
    } else if (this.UserId === '#9AA8AC') {
      if (this.Colors[key] !== undefined) return;

      if (seedNumber < 0.05) {
        this.Colors[key] = '#9AA6AC';
      } else if (seedNumber < 0.1) {
        this.Colors[key] = '#8b9ba3';
      } else if (seedNumber < 0.15) {
        this.Colors[key] = '#7e97a3';
      } else if (seedNumber < 0.2) {
        this.Colors[key] = '#5a7a8a';
      } else if (seedNumber < 0.25) {
        this.Colors[key] = '#496370';
      } else if (seedNumber < 0.3) {
        this.Colors[key] = '#375f73';
      } else if (seedNumber < 0.35) {
        this.Colors[key] = '#33708f';
      } else if (seedNumber < 0.4) {
        this.Colors[key] = '#94a8b3';
      } else if (seedNumber < 0.45) {
        this.Colors[key] = '#5a798a';
      } else if (seedNumber < 0.5) {
        this.Colors[key] = '#88aec2';
      } else if (seedNumber < 0.55) {
        this.Colors[key] = '#749eb5';
      } else if (seedNumber < 0.6) {
        this.Colors[key] = '#648191';
      } else if (seedNumber < 0.65) {
        this.Colors[key] = '#758994';
      } else if (seedNumber < 0.7) {
        this.Colors[key] = '#326480';
      } else if (seedNumber < 0.75) {
        this.Colors[key] = '#1b597a';
      } else if (seedNumber < 0.8) {
        this.Colors[key] = '#437c9c';
      } else if (seedNumber < 0.85) {
        this.Colors[key] = '#5295ba';
      } else if (seedNumber < 0.9) {
        this.Colors[key] = '#6b8999';
      } else if (seedNumber < 0.95) {
        this.Colors[key] = '#68818f';
      } else {
        this.Colors[key] = '#7196ab';
      }
      this.colorText;
    }
  }

  getColor(firstName, lastname) {
    let key = firstName + ' ' + lastname;
    return this.Colors[key];
  }
}
