export interface Options {
  id: string
  title: string
  path: string
  description: string,
  isAvailable: boolean,
  subtitle: string,
  imageUrl: string,
  resize960x540?: string,
  resize1280x720?: string,
  sortOrder?: number
}

// RentalStatus enum for rental statuses, instead of
// isSTATUS properties
export enum RentalStatus {
  All = 0, // any status,
  Archived = 'Archived', // same as {isActive, active} = false
  Cancelled = 'Cancelled',
  CheckedIn = 'CheckedIn',  // DEPRECATED, DO NOT USE! Is same as Completed.
  CheckedOut = 'CheckedOut',
  Completed = 'Completed',
  Current = 'Current',
  Preparing = 'Preparing', // DEPRECATED, DO NOT USE! Is same as Upcoming.
  Upcoming = 'Upcoming',
}

export interface ProductRental {
  id?: string,
  productID?: string,
  productName?: string,
  cost?: number,
  productDiscount?: number,
  rentalCost?: number,
  addons?: any[],
  checkInImgs?: string[],
  checkOutImgs?: string[],
  isDamaged?: boolean,
  isTuneUp?: boolean,
  isCleaning?: boolean,
  isMaintenance?: boolean,
  maintenanceReason?: string,
  description?: string,
  subtotal?: number,
  price?: number,
  days?: number,
  isPrepared?: boolean,
  isCheckedOut?: boolean,
  isCheckedIn?: boolean,
  productNumber?,
  productSize?: string,
  maintenanceSelection?: object,
  shopCommets?: string
  automaticNumber?: number
  waivers?: string[]
  addonDisplay?: any[]

}
// DEPRECATED Only for rental.products[]
export type RentalProductItem = ProductRental


/**
 * newAddons is deprecated and only used in rental.products[] array
 * DO NOT USE this anymore.
 */
export interface newAddons {
  id: string,
  companyID: string,
  title: string,
  description?: string,
  optionsSelect?: {
    price?: number,
    option?: boolean,
    qty?: number,
    description?: string,
    isSelected?: boolean,
    feetInput?: number,
    inchInput?:number,
    input?: number,
    size?: string }[],
  addonType: string,
  sortOrder?: number,
  isRequired?: boolean,
  isPercentage? : boolean,
  applyTax?: boolean,
}

export interface UserInfo {
  name?: string,
  lastName?: string,
  phone?: string,
  email?: string,
  address?: string,
  address2?: string,
  city?: string,
  country?: string,
  state?: string,
  zip?: string
}

export interface DatesSelected {
  dateStart: Date,
  dateEnd: Date,
}

const defaultProductRental: Partial<ProductRental> = {
  id: '',
  productID: '',
  productName: '',
  cost: null,
  productDiscount: null,
  rentalCost: null,
  addons: [],
  checkInImgs: [],
  checkOutImgs: [],
  isDamaged: false,
  isTuneUp: false,
  isCleaning: false,
  isMaintenance: false,
  maintenanceReason: '',
  description: '',
  subtotal: null,
  price: null,
  days: null,
  isPrepared: false,
  isCheckedOut: false,
  isCheckedIn: false,
  productNumber: null,
  productSize: '',
  maintenanceSelection: {},
  shopCommets: '',
  automaticNumber: null,
  waivers: [],
  addonDisplay: []
}

const defaultNewAddons: Partial<newAddons> = {
  id: '',
  companyID: '',
  title: '',
  description: '',
  optionsSelect: [],
  addonType: '',
  sortOrder: 0,
  isRequired: false,
}

export function makeNewProductObject(productRental?: ProductRental) {
  return { ...defaultProductRental, ...productRental } as ProductRental
}
export const makeNewRentalProductItem = makeNewProductObject

export function makeNewAddonsObject(newAddons?: newAddons) {
  console.warn('addons are DEPRECATED, any code using them should be considered for refactor and removal as soon as possible.')
  return { ...defaultNewAddons, ...newAddons } as newAddons
}

export function rentalStatusGuard(rentalStatus: string | number): rentalStatus is RentalStatus {
  for (const key in RentalStatus) {
    if (RentalStatus[key] == rentalStatus) {
      return true
    }
  }
  return false
}

