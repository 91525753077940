<!--<button class="btn btn-primary" type="button" (click)="deleteAllProductsGroups()">
    Delete All products
</button>-->
<!--<button type="button" class="btn btn-primary" (click)="getAndFix()">
    Fix Automatic Number
</button>-->
<!--<button type="button" class="btn btn-primary" (click)="fixAllProducts()">
    Fix StartTime
</button>-->
<!--<button type="button" class="btn btn-primary" (click)="FixAvailableLocationsProducts()">
    Fix availableLocations
</button>-->
<!--<button type="button" class="btn btn-primary" (click)="FixAvailableGroupsProducts()">
    Fix availableLocations Group
</button>-->
<!--<button type="button" class="btn btn-primary" (click)="createProductGroups()">
    Create Product Groups
</button>-->
<!--<button class="button" class="btn btn-primary" (click)="FixIncrementPerProduct()">
    Fix Increment
</button>-->
<div class="container-fluid mb-3">
    <div class="row  align-items-start mt-2">
        <div class="col-md-6 text-left">
            <h1 class="mt-2 mb-2" style="font-size: 32px; cursor: pointer;">
                Inventory
                <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#modalProductAdd"
                    (click)="openAddProduct()">Add Product</button>
            </h1>
        </div>
        <div class="col-md-6 text-left">
            <form class="row g-2">
                <div class="col-md-4 offset-md-8">
                    <label class="form-label ">
                        Search All Products
                    </label>
                    <input type="text" name="searchProductInput" [(ngModel)]="searchProductInput"
                        class="form-control form-control-sm" placeholder="Search..." (keyup)="searchProductGroup()" />
                </div>

            </form>
        </div>
    </div>
</div>
<ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
        <button class="nav-link bg-light text-dark border me-1" [ngClass]="{'selected':tabCategory == ''}" id="home-tab"
            data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home"
            aria-selected="true" (click)="changeCategory('')">All</button>
    </li>
    <ng-container *ngIf="categories.length > 0">
        <ng-container *ngFor="let category of categories">
            <li class="nav-item" role="presentation">
                <button class="nav-link bg-light text-dark border me-1"
                    [ngClass]="{'selected':tabCategory == category.id}" id="home-tab" data-bs-toggle="tab"
                    data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"
                    (click)="changeCategory(category.id)">{{category.title}}</button>
            </li>
        </ng-container>
    </ng-container>
</ul>
<div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
        <div class="container-fluid pt-2">
            <table id="productsTableGroup" class="table table-hovered text-center">
                <thead class="table-light">
                    <tr id="tableHeader">
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col">Category</th>
                        <th scope="col">Product Name</th>
                        <th scope="col">Location</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="filteredGroups?.length > 0">
                        <ng-container *ngFor="let group of filteredGroups">
                            <tr>
                                <td>
                                    <span *ngIf="group.isTour" class="badge bg-primary">Tour</span>
                                    <span *ngIf="!group.isTour" class="badge bg-secondarylight">Tour</span>
                                    <span *ngIf="group.isTracked" class="ms-1 badge bg-success">Tracked</span>
                                    <span *ngIf="!group.isTracked" class="ms-1 badge bg-secondarylight">Tracked</span>
                                </td>
                                <td>
                                    <img width="70" height="40" [src]="group.groupImage" alt="{{group.groupName}}">
                                </td>
                                <td>{{group.categoryName}}</td>
                                <td *ngIf="_currentUser.currentUser.isDeveloper">
                                    <a href="https://console.firebase.google.com/u/0/project/{{ projectId }}/firestore/data/~2FproductGroup/{{group?.id}}"
                                        target="_blank" style="text-decoration: none">
                                        {{group.groupName}}
                                    </a>
                                </td>
                                <td *ngIf="!_currentUser.currentUser.isDeveloper">
                                    {{group.groupName}}
                                </td>
                                <td>{{group.productLocationName}}</td>
                                <td><span class="badge bg-info">{{group.quantity}}</span></td>
                                <td>
                                    <button class="btn btn-primary btn-sm" (click)="editProduct(group)"
                                        data-bs-toggle="modal" data-bs-target="#modalProductEditGroup">Edit</button>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="filteredGroups?.length == 0">
                        <tr>
                            <td colspan="7" class="text-center">
                                There are no products to show
                            </td>
                        </tr>
                    </ng-container>

                </tbody>
            </table>
        </div>
    </div>
</div>
<!-- Modal to add Products -->
<div class="modal fade" id="modalProductAdd" aria-labelledby="modalProductAddLabel" tabindex="-1"
    data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalProductAddLabel">Add New Product</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <!--Information Form-->
                <mat-horizontal-stepper linear #stepper>
                    <mat-step [stepControl]="informationFormAddGroup" label="Information of product"
                        state="location_on">
                        <form (keydown.enter)="$event.preventDefault()" [formGroup]="informationFormAddGroup"
                            class="row g-3">
                            <ng-template matStepLabel>Information</ng-template>
                            <!--Track product-->
                            <div class="col-md-12">
                                <label class="form-label">Do you want to track history for this product? <b
                                        style="color:red;">*</b></label>
                                <div class="border rounded p-2">
                                    <div *ngIf="!selectedTrack" class="row">
                                        <div class="col-6 rounded pb-1">
                                            <div class="form-text text-justify">
                                                Tracking history for this product means that the product will collect a
                                                history of individual rental usage, maintenance activities, and the
                                                revenue generated. Quantity management will be manual.
                                            </div>
                                            <div class="container-fluid d-flex justify-content-center">
                                                <button type="button" class="btn btn-primary mt-1"
                                                    (click)="trackProduct(true)">Yes, track
                                                    history</button>
                                            </div>
                                        </div>
                                        <div class="col-6 rounded pb-1">
                                            <div class="form-text text-justify">
                                                Not Tracking history enables the ability to add or remove quantity by
                                                simply adjusting the qty amount. No individual item history will be
                                                available and only summary data regarding this product will be provided.
                                            </div>
                                            <div class="container-fluid d-flex justify-content-center">
                                                <button type="button" class="btn btn-primary mt-1"
                                                    (click)="trackProduct(false)">No, do not
                                                    track</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="selectedTrack" class="row">
                                        <div class="col-12">
                                            <span class="badge bg-success" *ngIf="trackProducts">This product group is
                                                being Tracked</span>
                                            <span class="badge bg-secondary" *ngIf="!trackProducts">This product group
                                                is
                                                not being tracked</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Product Rules-->
                            <div *ngIf="trackProducts" class="col-md-12">
                                <label class="form-label">Product Rules</label>
                                <div class="border rounded p-2">
                                    <div class="form-check">
                                        <input [(ngModel)]="maintenanceRuleAdd" [ngModelOptions]="{standalone: true}"
                                            class="form-check-input" type="checkbox"
                                            (change)="setProductRules('Maintenance', $event)">
                                        <label class="form-check-label">
                                            Enable Maintenance Tracking
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input [(ngModel)]="checkoutRuleAdd" [ngModelOptions]="{standalone: true}"
                                            class="form-check-input" type="checkbox"
                                            (change)="setProductRules('Checkout', $event)">
                                        <label class="form-check-label">
                                            Enable CheckOut Images
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input [(ngModel)]="checkineRuleAdd" [ngModelOptions]="{standalone: true}"
                                            class="form-check-input" type="checkbox"
                                            (change)="setProductRules('Checkin', $event)">
                                        <label class="form-check-label">
                                            Enable CheckIn Images
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <label class="form-label">
                                    Product Category
                                </label>
                                <div class="row">
                                    <div class="col-12">
                                        <ng-select [(ngModel)]="selectedCategory" (change)="defineCategory($event)"
                                            placeholder="Search a Category" [ngModelOptions]="{standalone: true}">
                                            <ng-option *ngFor="let category of categories"
                                                [value]="category.id">{{category.title}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                            <!--Tour-->
                            <div class="col-md-12">
                                <label class="form-label">Select Type of Product <i
                                        class="fas fa-question-circle hoverrow text-info"
                                        (click)="showAlertDescription('type')"></i></label>
                                <div class="border rounded p-2">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="productType"
                                                    [(ngModel)]="productType" value="rental"
                                                    [ngModelOptions]="{standalone: true}" checked
                                                    (change)="changeProductType()">
                                                <label class="form-check-label">
                                                    Product Rental
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="productType"
                                                    [(ngModel)]="productType" value="tour"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (change)="changeProductType()">
                                                <label class="form-check-label">
                                                    Product Tour
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="container-fluid bg-light mt-1">
                                        <div class="row">
                                            <!--Column prices-->
                                            <div *ngIf="productType == 'rental'" class="col-12 ps-3 pe-3 mt-1">
                                                <div class="row border rounded p-2">
                                                    <!--Price by day-->
                                                    <div class="col-6">
                                                        <div class="container-fluid p-1">
                                                            <div class="form-check form-switch">
                                                                <input class="form-check-input" type="checkbox"
                                                                    role="switch" name="isPriceByDay"
                                                                    [(ngModel)]="isPriceByDay"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    (change)="changePriceByDayOption()"
                                                                    [disabled]="!isPriceByHour">
                                                                <label class="form-check-label">Allow Rental By Shop
                                                                    Day</label>
                                                            </div>
                                                            <div *ngIf="isPriceByDay" class="form-check checkbox24hrs">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="isPrice24hrs" [(ngModel)]="isPrice24hrs"
                                                                    [ngModelOptions]="{standalone: true}">
                                                                <label class="form-check-label">
                                                                    24 hours days
                                                                </label>
                                                            </div>
                                                            <div *ngIf="isPriceByDay"
                                                                class="row g-3 mt-2 pb-3 p-2 maxheightprice rounded bg-light">
                                                                <ng-container *ngIf="arrayPricesDayBase.length == 0">
                                                                    <div class="col-12 text-center mb-1">
                                                                        There's not prices defined, add new one.
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container *ngIf="arrayPricesDayBase.length > 0">
                                                                    <ng-container
                                                                        *ngFor="let priceday of arrayPricesDayBase; let i = index">
                                                                        <div class="col-6">
                                                                            <div
                                                                                class="input-group input-group-sm mb-3">
                                                                                <span class="input-group-text"
                                                                                    id="basic-addon1">Day</span>
                                                                                <input type="text" class="form-control"
                                                                                    value="{{priceday.day}}"
                                                                                    [ngClass]="{'is-invalid':priceday?.isInvalid}"
                                                                                    (keyup)="restrictNumbersDay(i, 'day', $event)"
                                                                                    oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <div
                                                                                class="input-group input-group-sm mb-3">
                                                                                <span class="input-group-text"
                                                                                    id="basic-addon1">Price</span>
                                                                                <input type="text" class="form-control"
                                                                                    value="{{priceday.price}}"
                                                                                    [ngClass]="{'is-invalid':priceday?.isZero}"
                                                                                    (keyup)="restrictNumbersDay(i, 'price', $event)"
                                                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');">
                                                                                <button *ngIf="i != 0" type="button"
                                                                                    class="btn btn-danger btn-sm"
                                                                                    (click)="deleteBasePriceDay(i)"><i
                                                                                        class="fas fa-minus-circle"></i></button>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="col-12 align-items-end text-right lineincrement">
                                                                            <div class="row justify-content-end">
                                                                                <div
                                                                                    class="col-auto align-items-right text-right pt-1">
                                                                                    <span>Increment per Day</span>
                                                                                </div>
                                                                                <div class="col-auto">
                                                                                    <input type="text"
                                                                                        class="form-control form-control-sm inputincrement"
                                                                                        value="{{priceday.increment}}"
                                                                                        (keyup)="restrictNumbersDay(i, 'increment', $event)"
                                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </div>
                                                            <div *ngIf="isPriceByDay" class="col-12 text-right mt-1">
                                                                <span class="text-primary pointer"
                                                                    (click)="createNewBasePriceDay()"><i
                                                                        class="fas fa-plus-circle"></i> Add New
                                                                    Day</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--Price by hour-->
                                                    <div class="col-6">
                                                        <div class="container-fluid p-1">
                                                            <div class="form-check form-switch">
                                                                <input class="form-check-input" type="checkbox"
                                                                    role="switch" name="isPriceByHour"
                                                                    [(ngModel)]="isPriceByHour"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    (change)="changePriceByHourOption()"
                                                                    [disabled]="!isPriceByDay">
                                                                <label class="form-check-label">Allow Rental By Shop
                                                                    Hour</label>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="isPriceByHour"
                                                            class="row g-3 mt-2 pb-3 p-2 maxheighthour rounded bg-light">
                                                            <ng-container *ngIf="arrayPricesHourBase.length == 0">
                                                                <div class="col-12 text-center mb-1">
                                                                    There's not hours defined, add new one.
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="arrayPricesHourBase.length > 0">
                                                                <ng-container
                                                                    *ngFor="let pricehour of arrayPricesHourBase; let i = index">
                                                                    <div class="col-6">
                                                                        <div class="input-group input-group-sm mb-3">
                                                                            <span class="input-group-text"
                                                                                id="basic-addon1">Hour</span>
                                                                            <input type="text" class="form-control"
                                                                                value="{{pricehour.hour}}"
                                                                                [ngClass]="{'is-invalid':pricehour?.isInvalid}"
                                                                                (keyup)="restrictNumbersHour(i, 'hour', $event)"
                                                                                oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="input-group input-group-sm mb-3">
                                                                            <span class="input-group-text"
                                                                                id="basic-addon1">Price</span>
                                                                            <input type="text" class="form-control"
                                                                                value="{{pricehour.price}}"
                                                                                [ngClass]="{'is-invalid':pricehour?.isZero}"
                                                                                (keyup)="restrictNumbersHour(i, 'price', $event)"
                                                                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');">
                                                                            <button *ngIf="i != 0" type="button"
                                                                                class="btn btn-danger btn-sm"
                                                                                (click)="deleteBasePriceHour(i)"><i
                                                                                    class="fas fa-minus-circle"></i></button>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </div>
                                                        <div *ngIf="isPriceByHour" class="col-12 text-right mt-1">
                                                            <span class="text-primary pointer"
                                                                (click)="createNewBasePriceHour()"><i
                                                                    class="fas fa-plus-circle"></i> Add New Hour</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--<div *ngIf="productType == 'rental'" class="col-12 ps-3 pe-3 mt-1">
                                                <div class="row border rounded p-2">
                                                    <div class="col-6">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox"
                                                                name="defaultTime" [(ngModel)]="defaultTime"
                                                                [ngModelOptions]="{standalone: true}"
                                                                (click)="changeProductRental()">
                                                            <label class="form-check-label">
                                                                Default Rental Times <i
                                                                    class="fas fa-question-circle hoverrow text-info"
                                                                    (click)="showAlertDescription('default')"></i>
                                                            </label>
                                                        </div>
                                                        <div class="row g-3 pb-1 maxheighthour2 rounded bg-light">
                                                            <ng-container *ngIf="defaultTime">
                                                                <div class="col-12">
                                                                    <img class="imgdefaultrental"
                                                                        src="../../../assets/images/defaultrentaltimes.png"
                                                                        alt="defaultTime">
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                        <div *ngIf="defaultTime" class="col-12 text-center mb-1">
                                                            <span class="form-text"><i class="fas fa-info-circle"></i>
                                                                Example of product rental times provided when this
                                                                option is selected</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox"
                                                                name="minimumStartPrice" [(ngModel)]="minimumStartPrice"
                                                                [ngModelOptions]="{standalone: true}"
                                                                (click)="changeProductRental()">
                                                            <label class="form-check-label">
                                                                Custom Rental Times <i
                                                                    class="fas fa-question-circle hoverrow text-info"
                                                                    (click)="showAlertDescription('start')"></i>
                                                            </label>
                                                        </div>
                                                        <div class="row g-3 pb-3 maxheighthour2 rounded bg-light">
                                                            <ng-container
                                                                *ngIf="minimumStartPrice && startTime.length == 0">
                                                                <div class="col-12 text-center mb-1">
                                                                    There's not Start Time defined, add new one.
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="!minimumStartPrice">
                                                                <div class="col-12 text-center mb-1">
                                                                    This option is not activated.
                                                                </div>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="minimumStartPrice && startTime.length > 0">
                                                                <ng-container
                                                                    *ngFor="let hour of startTime; let i = index">
                                                                    <div class="col-12">
                                                                        <div
                                                                            class="input-group maxheighthour2 input-group-sm">
                                                                            <button *ngIf="i != 0" type="button"
                                                                                class="input-group-text bg-danger text-light"
                                                                                (click)="deleteStartTime(i)">
                                                                                <i class="fas fa-minus-circle"></i>
                                                                            </button>
                                                                            <input type="time" [value]="hour.hour"
                                                                                [ngClass]="{'is-invalid': hour?.isInvalid}"
                                                                                class="form-control"
                                                                                (change)="setStartTime(i, $event)">
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </div>
                                                        <div *ngIf="minimumStartPrice"
                                                            class="col-12 text-right mt-1 mb-1">
                                                            <span class="text-primary pointer"
                                                                (click)="addNewStartTime()"><i
                                                                    class="fas fa-plus-circle"></i> Add New Start
                                                                Time</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>-->
                                            <div *ngIf="productType == 'tour'" class="col-6 mt-1">
                                                <div class="mb-3">
                                                    <label class="form-label">Minimum
                                                        Products</label>
                                                    <input type="number" class="form-control form-control-sm"
                                                        [(ngModel)]="minProductsAttached"
                                                        [ngClass]="{'is-invalid':minProductsAttached < 0 || minProductsAttached == undefined}"
                                                        (keyup)="restrictTourValue($event)"
                                                        [ngModelOptions]="{standalone: true}"
                                                        oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                                                </div>
                                            </div>
                                            <div *ngIf="productType == 'tour'" class="col-6 mt-1">
                                                <div class="mb-3">
                                                    <label class="form-label">Maximum
                                                        Products</label>
                                                    <input type="number" class="form-control form-control-sm"
                                                        [(ngModel)]="maxProductsAttached"
                                                        [ngClass]="{'is-invalid':maxProductsAttached < 0 || maxProductsAttached == undefined}"
                                                        (keyup)="restrictTourValue($event)"
                                                        [ngModelOptions]="{standalone: true}"
                                                        oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                                                </div>
                                            </div>
                                            <div *ngIf="productType == 'tour'" class="col-6 mt-1">
                                                <label class="form-check-label">
                                                    Custom Rental Times <i
                                                        class="fas fa-question-circle hoverrow text-info"
                                                        (click)="showAlertDescription('start')"></i>
                                                </label>
                                                <div class="row g-3 pb-3 p-2 maxheighthour2 rounded bg-light">
                                                    <ng-container *ngIf="startTime.length == 0">
                                                        <div class="col-12 text-center mb-1">
                                                            There's not Start Time defined, add new one.
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="startTime.length > 0">
                                                        <ng-container *ngFor="let hour of startTime; let i = index">
                                                            <div class="col-12">
                                                                <div class="input-group maxheighthour2 input-group-sm">
                                                                    <button *ngIf="i != 0" type="button"
                                                                        class="input-group-text bg-danger text-light"
                                                                        (click)="deleteStartTime(i)">
                                                                        <i class="fas fa-minus-circle"></i>
                                                                    </button>
                                                                    <input type="time"
                                                                        [ngClass]="{'is-invalid': hour?.isInvalid}"
                                                                        [value]="hour.hour" class="form-control"
                                                                        (change)="setStartTime(i, $event)">
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                                <div class="col-12 text-right mt-1 mb-1">
                                                    <span class="text-primary pointer" (click)="addNewStartTime()"><i
                                                            class="fas fa-plus-circle"></i> Add New Start Time</span>
                                                </div>
                                            </div>
                                            <div *ngIf="productType == 'tour'" class="col-6 mt-1">
                                                <label class="form-check-label">
                                                    Price By Hour <i class="fas fa-question-circle hoverrow text-info"
                                                        (click)="showAlertDescription('hour')"></i>
                                                </label>
                                                <div *ngIf="isPriceByHour"
                                                    class="row g-3 pb-3 p-2 maxheighthour2 rounded bg-light">
                                                    <ng-container *ngIf="arrayPricesHourBase.length == 0">
                                                        <div class="col-12 text-center mb-1">
                                                            There's not hours defined, add new one.
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="arrayPricesHourBase.length > 0">
                                                        <ng-container
                                                            *ngFor="let pricehour of arrayPricesHourBase; let i = index">
                                                            <div class="col-6">
                                                                <div class="input-group input-group-sm">
                                                                    <span class="input-group-text"
                                                                        id="basic-addon1">Hour</span>
                                                                    <input type="text" class="form-control"
                                                                        value="{{pricehour.hour}}"
                                                                        [ngClass]="{'is-invalid':pricehour?.isInvalid}"
                                                                        (keyup)="restrictNumbersHour(i, 'hour', $event)"
                                                                        oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                                                                </div>
                                                            </div>
                                                            <div class="col-6">
                                                                <div class="input-group input-group-sm">
                                                                    <span class="input-group-text"
                                                                        id="basic-addon1">Price</span>
                                                                    <input type="text" class="form-control"
                                                                        value="{{pricehour.price}}"
                                                                        [ngClass]="{'is-invalid':pricehour?.isZero}"
                                                                        (keyup)="restrictNumbersHour(i, 'price', $event)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');">
                                                                    <button *ngIf="i != 0" type="button"
                                                                        class="btn btn-danger btn-sm"
                                                                        (click)="deleteBasePriceHour(i)"><i
                                                                            class="fas fa-minus-circle"></i></button>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                                <div *ngIf="isPriceByHour" class="col-12 text-right mt-1 mb-1">
                                                    <span class="text-primary pointer"
                                                        (click)="createNewBasePriceHour()"><i
                                                            class="fas fa-plus-circle"></i> Add New Hour</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Information Product-->
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-md-12 mt-1">
                                        <label class="form-label">Product Name <b style="color:red">*</b></label>
                                        <input type="text" class="form-control form-control-sm"
                                            formControlName="productName" name="productName"
                                            [ngClass]="{'is-invalid': info.productName.errors && submittedInfo}"
                                            (keyup)="updateProductsInfo()">
                                    </div>
                                    <div class="col-md-12 mt-1">
                                        <label class="form-label">Downtime</label>
                                        <div class="input-group input-group-sm mb-3">
                                            <input type="number" class="form-control" formControlName="downtime"
                                                name="downtime" value="0"
                                                [ngClass]="{'is-invalid': info.downtime.errors && submittedInfo}"
                                                (keyup)="updateProductsInfo()"
                                                oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                                            <span class="input-group-text">minutes</span>
                                            <div class="form-text">
                                                Downtime is the amount of time before a product can be rented again
                                                following the
                                                end time of a prior rental
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <label class="form-label">Product Description</label>
                                        <div class="form-floating">
                                            <textarea class="form-control form-control-sm"
                                                placeholder="Leave a comment here" formControlName="productDescription"
                                                name="productDescription" (keyup)="updateProductsInfo()"></textarea>
                                            <label>Type a product description</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="col-12 mt-2">
                                    <div class=" text-center align-items-center justify-content-center d-flex">
                                        <img [src]="imgGallery" class="imgproductgroup">
                                    </div>
                                    <div class="text-center mt-2 align-items-center justify-content-center">
                                        <button class="btn btn-primary btn-sm" (click)="openImgGallery()"
                                            data-bs-target="#imgGallery" type="button"
                                            [disabled]="info.productName.value ==''">
                                            <i class="fa fa-picture-o" aria-hidden="true"></i> Image
                                            Library
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <label class="form-label">Notes</label>
                                <div class="form-floating">
                                    <textarea class="form-control form-control-sm" placeholder="Leave a comment here"
                                        formControlName="productNotes" name="productNotes"
                                        (keyup)="updateProductsInfo()"></textarea>
                                    <label>Type a note</label>
                                </div>
                            </div>
                            <div *ngIf="company?.multipleWaivers" class="col-12">
                                <label class="form-label">Products Waiver</label>
                                <div class="border rounded p-2">
                                    <div class="row">
                                        <ng-container *ngFor="let waiver of waivers; let i = index">
                                            <div class="col-4">
                                                <div class="form-check">
                                                    <input id="waiver{{i}}" class="form-check-input" type="checkbox" [(ngModel)]="waiver.isSelected"
                                                        [ngModelOptions]="{standalone: true}" (change)="selectWaiver(waiver, 'add')">
                                                    <label class="form-check-label">
                                                        {{waiver.title}}
                                                    </label>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-right">
                                <button
                                    [disabled]="startTimeInvalid || hourInvalidInfo || dayInvalid || !selectedTrack || numberToursInvalid"
                                    mat-button matStepperNext class="btn btn-primary"
                                    (click)="nextStep('info', 'add')">Next</button>
                            </div>
                        </form>
                    </mat-step>
                    <!--Quantity Form-->
                    <mat-step [stepControl]="quantityFormAddGroup" label="Location and Sizes">
                        <form (keydown.enter)="$event.preventDefault()" [formGroup]="quantityFormAddGroup"
                            class="row g-3">
                            <ng-template matStepLabel>Quantity</ng-template>
                            <!--Physical Location-->
                            <div class="col-md-12">
                                <label class="form-label">Physical Location <b style="color:red">*</b> <span
                                        class="text-info pointer" (click)="locationView()"><i
                                            class="fas fa-plus-circle ms-1"></i> Add new location</span></label>
                                <select class="form-select form-select-sm" name="productLocationID"
                                    formControlName="productLocationID" (change)="checkRequiredFields('location')">
                                    <option disabled>Select a Physical Location...</option>
                                    <ng-container *ngFor="let location of locations">
                                        <option [value]="location.id"
                                            [selected]="location.id == qty.productLocationID.value">{{location.name}}
                                        </option>
                                    </ng-container>
                                </select>
                                <!--Available Locations-->
                                <div class="mt-1">
                                    <label class="form-label">Available at Locations</label>
                                    <div class="border rounded p-2">
                                        <div class="row">
                                            <ng-container *ngFor="let location of locations; let i = index">
                                                <div *ngIf="location.notShow" class="col-4">
                                                    <div class="form-check">
                                                        <input id="location{{i}}" class="form-check-input"
                                                            type="checkbox" [checked]="location.isSelected"
                                                            (change)="selectAvailableLocations(location)">
                                                        <label class="form-check-label">
                                                            {{location.name}}
                                                        </label>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <div class="form-text">
                                                When you select a physical location, it is automatically preselected for
                                                availability, if you uncheck the box it means that it will not be
                                                available
                                                even if the product is there.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Product Number-->
                            <div class="col-12">
                                <label class="form-label">Product Number</label>
                                <div class="border rounded p-2">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value=""
                                                    [(ngModel)]="productNumbers" (change)="changeIfProductNumber()"
                                                    [ngModelOptions]="{standalone: true}">
                                                <label class="form-check-label">
                                                    Add product numbers manually
                                                </label>
                                                <div class="form-text">
                                                    When a new inventory document is created, it's assigned an automatic
                                                    number for internal control, there is also the option to add a
                                                    custom product number.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Qty Sizes-->
                            <div class="col-12">
                                <label class="form-label">Sizes Quantity<span class="linkprimary ms-1"
                                        (click)="addSizeRow()"><i class="fas fa-plus-circle"></i> Add
                                        Another
                                        Size</span></label>
                                <div class="border rounded p-2">
                                    <div class="bg-light p-2 col-12 productslist">
                                        <div class="row">
                                            <ng-container *ngFor="let sizes of arraysizesqty; let i = index">
                                                <div class="col-md-5">
                                                    <label class="form-label">Size Type <b
                                                            style="color:red">*</b></label>
                                                    <div class="input-group mb-3">
                                                        <span *ngIf="i != 0" class="input-group-text linkprimary"
                                                            (click)="deleteSize(i)">
                                                            <i class="fas fa-minus-circle"></i>
                                                        </span>
                                                        <select class="form-select form-select-sm"
                                                            (change)="editSizeRow(i,'type',$event)" [disabled]="i > 0">
                                                            <ng-container *ngFor="let sizeType of sizes.sizeType">
                                                                <option [value]="sizeType.id"
                                                                    [selected]="i > 0 ? sizeType.isSelected : sizeType.isDefault">
                                                                    {{sizeType.productType}}
                                                                </option>
                                                            </ng-container>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-5">
                                                    <label class="form-label">Size <b style="color:red">*</b></label>
                                                    <select class="form-select form-select-sm"
                                                        (change)="editSizeRow(i,'size',$event)">
                                                        <ng-container *ngFor="let size of sizes.sizes">
                                                            <option [value]="size.id">
                                                                {{size.size}}
                                                            </option>
                                                        </ng-container>
                                                    </select>
                                                </div>
                                                <div class="col-md-2">
                                                    <label class="form-label">Quantity <b
                                                            style="color:red">*</b></label>
                                                    <input id="clean{{0}}" type="text"
                                                        class="form-control form-control-sm"
                                                        (keyup)="restrictNumbersQty(i,'qty',$event)" [value]="sizes.qty"
                                                        oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-12 align-items-center text-center"
                                        *ngIf="loadingLocSize && quantitytype">
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Products Table-->
                            <div class="col-12">
                                <span *ngIf="!validateRequired" class="text-danger">
                                    <i class="fas fa-exclamation-circle"></i> You need to fill in all the required
                                    fields
                                    marked
                                    with an asterisk to continue.
                                </span>
                            </div>
                            <div class="col-12 bg-light rounded">
                                <div class="mt-1">
                                    <form (keydown.enter)="$event.preventDefault()" class="row g-3">
                                        <div class="col-8">
                                            <button *ngIf="selectedProducts" type="button"
                                                class="btn btn-primary btn-sm" (click)="deleteSelectedProduct()">
                                                Delete selected Products
                                            </button>
                                            <button *ngIf="selectedProducts" type="button"
                                                class="btn btn-secondary btn-sm ms-1" (click)="UnselectProduct()">
                                                Unselect Products
                                            </button>
                                        </div>
                                        <div class="col-4">
                                            <input type="text" name="searchProductInput"
                                                [(ngModel)]="searchProductInput" class="form-control form-control-sm"
                                                placeholder="Search..." (keyup)="searchProductAdd()" />
                                        </div>
                                    </form>
                                </div>
                                <div class="border rounded p-2 productslist mt-2">
                                    <table id="productsTable" class="table text-center">
                                        <thead class="bg-customblue text-white">
                                            <tr id="tableHeader">
                                                <th></th>
                                                <th>Status</th>
                                                <th *ngIf="productNumbers">Product Number</th>
                                                <th>Name</th>
                                                <th>Location</th>
                                                <th>Size</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="qtyproducts.length > 0">
                                            <ng-container *ngFor="let product of qtyproducts; let i = index">
                                                <tr>
                                                    <td>
                                                        <input type="checkbox" name="productcheck"
                                                            (click)="selectProduct(product,i)"
                                                            [checked]="product.isSelected">
                                                    </td>
                                                    <td>
                                                        <span *ngIf="!product.product.isEdited"
                                                            class="badge bg-secondary">Default</span>
                                                        <span *ngIf="product.product.isEdited"
                                                            class="badge bg-primary">Edited</span>
                                                    </td>
                                                    <td *ngIf="productNumbers && !autoNumber">
                                                        <input
                                                            [ngClass]="{'is-invalid':(product.product.isRepeated || product.product.isExisted)}"
                                                            (keyup)="changeProductNumber($event, i)" type="text"
                                                            class="form-control form-control-sm"
                                                            value="{{product.product.productNumber}}">
                                                        <span
                                                            *ngIf="product.product.isRepeated && !product.product.isExisted"
                                                            class="text-danger font12px"><i
                                                                class="fas fa-exclamation-circle"></i> Product Number
                                                            repeated</span>
                                                        <span
                                                            *ngIf="product.product.isExisted && !product.product.isRepeated"
                                                            class="text-danger font12px"><i
                                                                class="fas fa-exclamation-circle"></i> This number
                                                            already exists</span>
                                                        <span
                                                            *ngIf="product.product.isRepeated && product.product.isExisted"
                                                            class="text-danger font12px"><i
                                                                class="fas fa-exclamation-circle"></i> Existing and
                                                            repeating number</span>
                                                    </td>
                                                    <td *ngIf="productNumbers && autoNumber">
                                                        {{product.product.productNumber}}
                                                    </td>
                                                    <td>{{product.product.productName}}</td>
                                                    <td>{{product.product.productLocationName}}</td>
                                                    <td>{{product.product.productSize}}</td>
                                                    <td>

                                                        <button type="button" class="btn btn-primary btn-sm"
                                                            data-bs-toggle="modal" data-bs-target="#modalProductEdit"
                                                            (click)="editProductList(product, i,'add')">Edit</button>
                                                        <button type="button" class="ms-1 btn btn-danger btn-sm"
                                                            (click)="deleteSingleProduct(i)">
                                                            <i class="fas fa-trash-alt"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                        <tbody *ngIf="qtyproducts.length == 0">
                                            <tr>
                                                <td colspan="6">There's no set Quantity</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div *ngIf="!validateRequired" class="divover rounded">
                                </div>
                            </div>
                            <div class="col-12 text-right">
                                <button mat-button matStepperPrevious class="btn btn-secondary me-1">Back</button>
                                <button [disabled]="!validateRequired || (repeatedNumber || existingNumber)" mat-button
                                    matStepperNext (click)="nextStep('qty', 'add')" class="btn btn-success">Save
                                    Changes</button>
                            </div>
                        </form>
                    </mat-step>
                </mat-horizontal-stepper>
            </div>
        </div>
    </div>
</div>

<!--Modal to edit products-->
<div class="modal fade" id="modalProductEdit" aria-labelledby="modalProductEditLabel" tabindex="-1"
    data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalProductEditLabel">Edit Product</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="backAddModal()"></button>
            </div>
            <div class="modal-body">
                <form (keydown.enter)="$event.preventDefault()" [formGroup]="productEditForm" class="row g-3">
                    <div class="col-12 bg-light p-3 rounded-top acc">
                        <i class="fas fa-chevron-down mr-1"></i>
                        Products Information
                    </div>
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-md-12 mt-1">
                                <label class="form-label">Product Name <b style="color:red">*</b></label>
                                <input type="text" class="form-control form-control-sm" formControlName="productName"
                                    name="productName" [readonly]="true" (keyup)="checkEditFields()">
                            </div>
                            <div [ngClass]="{'col-md-6':productNumbers, 'col-md-12':!productNumbers}" class="mt-2">
                                <label class="form-label">Downtime</label>
                                <div class="input-group input-group-sm mb-3">
                                    <input type="text" class="form-control" formControlName="downtime" name="downtime"
                                        (keyup)="checkEditFields()"
                                        oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                                    <span class="input-group-text">minutes</span>
                                    <div class="form-text">
                                        Downtime is the amount of time before a product can be rented again following
                                        the
                                        end time of a prior rental
                                    </div>
                                </div>

                            </div>
                            <div *ngIf="productNumbers" class="col-md-6 mt-2">
                                <label class="form-label">Product Number <b style="color:red">*</b></label>
                                <input type="text" class="form-control form-control-sm" formControlName="productNumber"
                                    name="productNumber" (click)="checkEditFields()">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="col-12 mt-2">
                            <div class=" text-center align-items-center justify-content-center d-flex">
                                <img [src]="editImage" class="imgproductgroup">
                            </div>

                        </div>
                    </div>
                    <div class="col-12">
                        <label class="form-label">Product Description</label>
                        <div class="form-floating">
                            <textarea class="form-control form-control-sm" placeholder="Leave a comment here"
                                formControlName="productDescription" name="productDescription"
                                [readonly]="true"></textarea>
                            <label>Type a product description</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <label class="form-label">Notes</label>
                        <div class="form-floating">
                            <textarea class="form-control form-control-sm" placeholder="Leave a comment here"
                                formControlName="productNotes" name="productNotes"></textarea>
                            <label>Type a note</label>
                        </div>
                    </div>
                    <div *ngIf="company?.multipleWaivers" class="col-12">
                        <label class="form-label">Products Waiver</label>
                        <div class="border rounded p-2">
                            <div class="row">
                                <ng-container *ngFor="let waiver of waiversedit">
                                    <div class="col-4">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value=""
                                                (click)="selectWaiver(waiver, 'edit')" [checked]="waiver.isSelected">
                                            <label class="form-check-label">
                                                {{waiver.title}}
                                            </label>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="cancelEditChanges()">Cancel</button>
                <button type="button" class="btn btn-primary" (click)="submitEditChanges()"
                    [disabled]="!validateEditRequired">Save changes</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal to Edit Product Group -->
<div class="modal fade" id="modalProductEditGroup" aria-labelledby="modalProductEditGroupLabel" tabindex="-1"
    data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalProductEditGroupLabel">Edit Product
                    <ng-container *ngIf="productGroupEdit != undefined && _currentUser.currentUser.isDeveloper">
                        <a href="https://console.firebase.google.com/u/0/project/{{ projectId }}/firestore/data/~2FproductGroup/{{productGroupEdit?.id}}"
                            target="_blank" style="text-decoration: none">
                            {{productGroupEdit.groupName}}
                        </a>
                    </ng-container>
                    <ng-container *ngIf="!_currentUser.currentUser.isDeveloper">
                        {{productGroupEdit?.groupName}}
                    </ng-container>
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <mat-horizontal-stepper linear #stepperEdit>
                    <!--Information Form-->
                    <mat-step [stepControl]="informationFormEditGroup" label="Information of product"
                        state="location_on" [completed]="true">
                        <form (keydown.enter)="$event.preventDefault()" [formGroup]="informationFormEditGroup"
                            class="row g-3">
                            <ng-template matStepLabel>Information</ng-template>
                            <!--Track product-->
                            <div class="col-md-12">
                                <label class="form-label">Do you want to track history for this product? <b
                                        style="color:red;">*</b></label>
                                <div class="border rounded p-2">
                                    <div class="row">
                                        <div class="col-12">
                                            <span class="badge bg-success" *ngIf="trackProducts">This product group is
                                                being Tracked</span>
                                            <span class="badge bg-secondary" *ngIf="!trackProducts">This product group
                                                is
                                                not being tracked</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Product Rules-->
                            <div *ngIf="trackProducts" class="col-md-12">
                                <label class="form-label">Product Rules</label>
                                <div class="border rounded p-2">
                                    <div class="form-check">
                                        <input [(ngModel)]="maintenanceRuleEdit" [ngModelOptions]="{standalone: true}"
                                            class="form-check-input" type="checkbox"
                                            (change)="setProductRules('Maintenance', $event)">
                                        <label class="form-check-label">
                                            Enable Maintenance Tracking
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input [(ngModel)]="checkoutRuleEdit" [ngModelOptions]="{standalone: true}"
                                            class="form-check-input" type="checkbox"
                                            (change)="setProductRules('Checkout', $event)">
                                        <label class="form-check-label">
                                            Enable CheckOut Images
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input [(ngModel)]="checkineRuleEdit" [ngModelOptions]="{standalone: true}"
                                            class="form-check-input" type="checkbox"
                                            (change)="setProductRules('Checkin', $event)">
                                        <label class="form-check-label">
                                            Enable CheckIn Images
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <label class="form-label">
                                    Product Category
                                </label>
                                <div class="row">
                                    <div class="col-12">
                                        <ng-select [(ngModel)]="selectedCategory" (change)="defineCategory($event)"
                                            placeholder="Search a Category" [ngModelOptions]="{standalone: true}">
                                            <ng-option *ngFor="let category of categories"
                                                [value]="category.id">{{category.title}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                            <!--Tour-->
                            <div class="col-md-12">
                                <label class="form-label">Select Type of Rental</label>
                                <div class="border rounded p-2">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="productType"
                                                    [(ngModel)]="productType" value="rental"
                                                    [ngModelOptions]="{standalone: true}" checked
                                                    (change)="changeProductType()" disabled>
                                                <label class="form-check-label">
                                                    Product Rental
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="productType"
                                                    [(ngModel)]="productType" value="tour"
                                                    [ngModelOptions]="{standalone: true}" (change)="changeProductType()"
                                                    disabled>
                                                <label class="form-check-label">
                                                    Product Tour
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="container-fluid bg-light mt-1">
                                        <div class="row">
                                            <div *ngIf="productType == 'rental'" class="col-12 ps-3 pe-3 mt-1">
                                                <div class="row border rounded p-2">
                                                    <!--Price by day-->
                                                    <div class="col-6">
                                                        <div class="container-fluid p-1">
                                                            <div class="form-check form-switch">
                                                                <input class="form-check-input" type="checkbox"
                                                                    role="switch" name="isPriceByDay"
                                                                    [(ngModel)]="isPriceByDay"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    (change)="changePriceByDayOption()"
                                                                    [disabled]="!isPriceByHour">
                                                                <label class="form-check-label">Allow Rental By Shop
                                                                    Day</label>
                                                            </div>
                                                            <div *ngIf="isPriceByDay" class="form-check checkbox24hrs">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="isPrice24hrs" [(ngModel)]="isPrice24hrs"
                                                                    [ngModelOptions]="{standalone: true}">
                                                                <label class="form-check-label">
                                                                    24 hours days
                                                                </label>
                                                            </div>
                                                            <div *ngIf="isPriceByDay"
                                                                class="row g-3 mt-2 pb-3 p-2 maxheightprice rounded bg-light">
                                                                <ng-container *ngIf="arrayPricesDayBase.length == 0">
                                                                    <div class="col-12 text-center mb-1">
                                                                        There's not prices defined, add new one.
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container *ngIf="arrayPricesDayBase.length > 0">
                                                                    <ng-container
                                                                        *ngFor="let priceday of arrayPricesDayBase; let i = index">
                                                                        <div class="col-6">
                                                                            <div
                                                                                class="input-group input-group-sm mb-3">
                                                                                <span class="input-group-text"
                                                                                    id="basic-addon1">Day</span>
                                                                                <input type="text" class="form-control"
                                                                                    value="{{priceday.day}}"
                                                                                    [ngClass]="{'is-invalid':priceday?.isInvalid}"
                                                                                    (keyup)="restrictNumbersDay(i, 'day', $event)"
                                                                                    oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <div
                                                                                class="input-group input-group-sm mb-3">
                                                                                <span class="input-group-text"
                                                                                    id="basic-addon1">Price</span>
                                                                                <input type="text" class="form-control"
                                                                                    value="{{priceday.price}}"
                                                                                    [ngClass]="{'is-invalid':priceday?.isZero}"
                                                                                    (keyup)="restrictNumbersDay(i, 'price', $event)"
                                                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');">
                                                                                <button *ngIf="i != 0" type="button"
                                                                                    class="btn btn-danger btn-sm"
                                                                                    (click)="deleteBasePriceDay(i)"><i
                                                                                        class="fas fa-minus-circle"></i></button>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="col-12 align-items-end text-right lineincrement">
                                                                            <div class="row justify-content-end">
                                                                                <div
                                                                                    class="col-auto align-items-right text-right pt-1">
                                                                                    <span>Increment per Day</span>
                                                                                </div>
                                                                                <div class="col-auto">
                                                                                    <input type="text"
                                                                                        class="form-control form-control-sm inputincrement"
                                                                                        value="{{priceday.increment}}"
                                                                                        (keyup)="restrictNumbersDay(i, 'increment', $event)"
                                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </div>
                                                            <div *ngIf="isPriceByDay" class="col-12 text-right mt-1">
                                                                <span class="text-primary pointer"
                                                                    (click)="createNewBasePriceDay()"><i
                                                                        class="fas fa-plus-circle"></i> Add New
                                                                    Day</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--Price by hour-->
                                                    <div class="col-6">
                                                        <div class="container-fluid p-1">
                                                            <div class="form-check form-switch">
                                                                <input class="form-check-input" type="checkbox"
                                                                    role="switch" name="isPriceByHour"
                                                                    [(ngModel)]="isPriceByHour"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    (change)="changePriceByHourOption()"
                                                                    [disabled]="!isPriceByDay">
                                                                <label class="form-check-label">Allow Rental By Shop
                                                                    Hour</label>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="isPriceByHour"
                                                            class="row g-3 mt-2 pb-3 p-2 maxheighthour rounded bg-light">
                                                            <ng-container *ngIf="arrayPricesHourBase.length == 0">
                                                                <div class="col-12 text-center mb-1">
                                                                    There's not hours defined, add new one.
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="arrayPricesHourBase.length > 0">
                                                                <ng-container
                                                                    *ngFor="let pricehour of arrayPricesHourBase; let i = index">
                                                                    <div class="col-6">
                                                                        <div class="input-group input-group-sm mb-3">
                                                                            <span class="input-group-text"
                                                                                id="basic-addon1">Hour</span>
                                                                            <input type="text" class="form-control"
                                                                                value="{{pricehour.hour}}"
                                                                                [ngClass]="{'is-invalid':pricehour?.isInvalid}"
                                                                                (keyup)="restrictNumbersHour(i, 'hour', $event)"
                                                                                oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="input-group input-group-sm mb-3">
                                                                            <span class="input-group-text"
                                                                                id="basic-addon1">Price</span>
                                                                            <input type="text" class="form-control"
                                                                                value="{{pricehour.price}}"
                                                                                [ngClass]="{'is-invalid':pricehour?.isZero}"
                                                                                (keyup)="restrictNumbersHour(i, 'price', $event)"
                                                                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');">
                                                                            <button *ngIf="i != 0" type="button"
                                                                                class="btn btn-danger btn-sm"
                                                                                (click)="deleteBasePriceHour(i)"><i
                                                                                    class="fas fa-minus-circle"></i></button>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </div>
                                                        <div *ngIf="isPriceByHour" class="col-12 text-right mt-1">
                                                            <span class="text-primary pointer"
                                                                (click)="createNewBasePriceHour()"><i
                                                                    class="fas fa-plus-circle"></i> Add New Hour</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--<div *ngIf="productType == 'rental'" class="col-12 ps-3 pe-3 mt-1">
                                                <div class="row border rounded p-2">
                                                    <div class="col-6">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox"
                                                                name="defaultTime" [(ngModel)]="defaultTime"
                                                                [ngModelOptions]="{standalone: true}"
                                                                (click)="changeProductRental()">
                                                            <label class="form-check-label">
                                                                Default Rental Times <i
                                                                    class="fas fa-question-circle hoverrow text-info"
                                                                    (click)="showAlertDescription('default')"></i>
                                                            </label>
                                                        </div>
                                                        <div class="row g-3 pb-1 maxheighthour2 rounded bg-light">
                                                            <ng-container *ngIf="defaultTime">
                                                                <div class="col-12">
                                                                    <img class="imgdefaultrental"
                                                                        src="../../../assets/images/defaultrentaltimes.png"
                                                                        alt="defaultTime">
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                        <div *ngIf="defaultTime" class="col-12 text-center mb-1">
                                                            <span class="form-text"><i class="fas fa-info-circle"></i>
                                                                Example of product rental times provided when this
                                                                option is selected</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox"
                                                                name="minimumStartPrice" [(ngModel)]="minimumStartPrice"
                                                                [ngModelOptions]="{standalone: true}"
                                                                (click)="changeProductRental()">
                                                            <label class="form-check-label">
                                                                Custom Rental Times <i
                                                                    class="fas fa-question-circle hoverrow text-info"
                                                                    (click)="showAlertDescription('start')"></i>
                                                            </label>
                                                        </div>
                                                        <div class="row g-3 pb-3 maxheighthour2 rounded bg-light">
                                                            <ng-container
                                                                *ngIf="minimumStartPrice && startTime.length == 0">
                                                                <div class="col-12 text-center mb-1">
                                                                    There's not Start Time defined, add new one.
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="!minimumStartPrice">
                                                                <div class="col-12 text-center mb-1">
                                                                    This option is not activated.
                                                                </div>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="minimumStartPrice && startTime.length > 0">
                                                                <ng-container
                                                                    *ngFor="let hour of startTime; let i = index">
                                                                    <div class="col-12">
                                                                        <div
                                                                            class="input-group maxheighthour2 input-group-sm">
                                                                            <button *ngIf="i != 0" type="button"
                                                                                class="input-group-text bg-danger text-light"
                                                                                (click)="deleteStartTime(i)">
                                                                                <i class="fas fa-minus-circle"></i>
                                                                            </button>
                                                                            <input type="time" [value]="hour.hour"
                                                                                [ngClass]="{'is-invalid': hour?.isInvalid}"
                                                                                class="form-control"
                                                                                (change)="setStartTime(i, $event)">
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </div>
                                                        <div *ngIf="minimumStartPrice"
                                                            class="col-12 text-right mt-1 mb-1">
                                                            <span class="text-primary pointer"
                                                                (click)="addNewStartTime()"><i
                                                                    class="fas fa-plus-circle"></i> Add New Start
                                                                Time</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>-->
                                            <div *ngIf="productType == 'tour'" class="col-6 mt-1">
                                                <div class="mb-3">
                                                    <label class="form-label">Minimum
                                                        Products</label>
                                                    <input type="number" class="form-control form-control-sm"
                                                        [(ngModel)]="minProductsAttached"
                                                        [ngClass]="{'is-invalid':minProductsAttached < 0 || minProductsAttached == undefined}"
                                                        (keyup)="restrictTourValue($event)"
                                                        [ngModelOptions]="{standalone: true}"
                                                        oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                                                </div>
                                            </div>
                                            <div *ngIf="productType == 'tour'" class="col-6 mt-1">
                                                <div class="mb-3">
                                                    <label class="form-label">Maximum
                                                        Products</label>
                                                    <input type="number" class="form-control form-control-sm"
                                                        [(ngModel)]="maxProductsAttached"
                                                        [ngClass]="{'is-invalid':maxProductsAttached < 0 || maxProductsAttached == undefined}"
                                                        (keyup)="restrictTourValue($event)"
                                                        [ngModelOptions]="{standalone: true}"
                                                        oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                                                </div>
                                            </div>
                                            <div *ngIf="productType == 'tour'" class="col-6 mt-1">
                                                <label class="form-check-label">
                                                    Custom Rental Times
                                                </label>
                                                <div class="row g-3 pb-3 p-2 maxheighthour2 rounded bg-light">
                                                    <ng-container *ngIf="startTime.length == 0">
                                                        <div class="col-12 text-center mb-1">
                                                            There's not Start Time defined, add new one.
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="startTime.length > 0">
                                                        <ng-container *ngFor="let hour of startTime; let i = index">
                                                            <div class="col-12">
                                                                <div class="input-group maxheighthour2 input-group-sm">
                                                                    <button *ngIf="i != 0" type="button"
                                                                        class="input-group-text bg-danger text-light"
                                                                        (click)="deleteStartTime(i)">
                                                                        <i class="fas fa-minus-circle"></i>
                                                                    </button>
                                                                    <input type="time" [value]="hour.hour"
                                                                        [ngClass]="{'is-invalid': hour?.isInvalid}"
                                                                        class="form-control"
                                                                        (change)="setStartTime(i, $event)">
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                                <div class="col-12 text-right mt-1 mb-1">
                                                    <span class="text-primary pointer" (click)="addNewStartTime()"><i
                                                            class="fas fa-plus-circle"></i> Add New Start Time</span>
                                                </div>
                                            </div>
                                            <div *ngIf="productType == 'tour'" class="col-6 mt-1">
                                                <label class="form-check-label">
                                                    Price By Hour
                                                </label>
                                                <div *ngIf="isPriceByHour"
                                                    class="row g-3 pb-3 p-2 maxheighthour2 rounded bg-light">
                                                    <ng-container *ngIf="arrayPricesHourBase.length == 0">
                                                        <div class="col-12 text-center mb-1">
                                                            There's not hours defined, add new one.
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="arrayPricesHourBase.length > 0">
                                                        <ng-container
                                                            *ngFor="let pricehour of arrayPricesHourBase; let i = index">
                                                            <div class="col-6">
                                                                <div class="input-group input-group-sm">
                                                                    <span class="input-group-text"
                                                                        id="basic-addon1">Hour</span>
                                                                    <input type="text" class="form-control"
                                                                        value="{{pricehour.hour}}"
                                                                        [ngClass]="{'is-invalid':pricehour?.isInvalid}"
                                                                        (keyup)="restrictNumbersHour(i, 'hour', $event)"
                                                                        oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                                                                </div>
                                                            </div>
                                                            <div class="col-6">
                                                                <div class="input-group input-group-sm">
                                                                    <span class="input-group-text"
                                                                        id="basic-addon1">Price</span>
                                                                    <input type="text" class="form-control"
                                                                        value="{{pricehour.price}}"
                                                                        [ngClass]="{'is-invalid':pricehour?.isZero}"
                                                                        (keyup)="restrictNumbersHour(i, 'price', $event)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');">
                                                                    <button *ngIf="i != 0" type="button"
                                                                        class="btn btn-danger btn-sm"
                                                                        (click)="deleteBasePriceHour(i)"><i
                                                                            class="fas fa-minus-circle"></i></button>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                                <div *ngIf="isPriceByHour" class="col-12 text-right mt-1 mb-1">
                                                    <span class="text-primary pointer"
                                                        (click)="createNewBasePriceHour()"><i
                                                            class="fas fa-plus-circle"></i> Add New Hour</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-md-12 mt-1">
                                        <label class="form-label">Product Name <b style="color:red">*</b></label>
                                        <input type="text" class="form-control form-control-sm"
                                            formControlName="productName" name="productName"
                                            (keyup)="updateProductsInfo()"
                                            [ngClass]="{'is-invalid': infoedit.productName.errors && submittedInfo}">
                                    </div>
                                    <div class="col-md-12">
                                        <label class="form-label">Downtime</label>
                                        <div class="input-group input-group-sm mb-3">
                                            <input type="number" class="form-control" formControlName="downtime"
                                                name="downtime" (keyup)="updateProductsInfo()"
                                                [ngClass]="{'is-invalid': infoedit.downtime.errors && submittedInfo}"
                                                oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                                            <span class="input-group-text">minutes</span>
                                            <div class="form-text">
                                                Downtime is the amount of time before a product can be rented again
                                                following the
                                                end time of a prior rental
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-12">
                                        <label class="form-label">Product Description</label>
                                        <div class="form-floating">
                                            <textarea class="form-control form-control-sm"
                                                placeholder="Leave a comment here" formControlName="productDescription"
                                                name="productDescription" (keyup)="updateProductsInfo()"></textarea>
                                            <label>Type a product description</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="col-12 mt-2">
                                    <div class=" text-center align-items-center justify-content-center d-flex">
                                        <img [src]="imgGallery" class="imgproductgroup">
                                    </div>
                                    <div class="text-center mt-2 align-items-center justify-content-center">
                                        <button class="btn btn-primary btn-sm" (click)="openImgGallery()"
                                            data-bs-target="#imgGallery" type="button"
                                            [disabled]="infoedit.productName.value ==''">
                                            <i class="fa fa-picture-o" aria-hidden="true"></i> Image
                                            Library
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <label class="form-label">Notes</label>
                                <div class="form-floating">
                                    <textarea class="form-control form-control-sm" placeholder="Leave a comment here"
                                        formControlName="productNotes" name="productNotes"
                                        (keyup)="updateProductsInfo()"></textarea>
                                    <label>Type a note</label>
                                </div>
                            </div>
                            <div *ngIf="company?.multipleWaivers" class="col-12">
                                <label class="form-label">Products Waiver</label>
                                <div class="border rounded p-2">
                                    <div class="row">
                                        <ng-container *ngFor="let waiver of waivers">
                                            <div class="col-4">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" [(ngModel)]="waiver.isSelected"
                                                        [ngModelOptions]="{standalone: true}" (change)="selectWaiver(waiver, 'add')">
                                                    <label class="form-check-label">
                                                        {{waiver.title}}
                                                    </label>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <button class="btn btn-outline-secondary" type="button"
                                    (click)="deleteAllProductByID()">
                                    Delete Product Group
                                </button>
                            </div>
                            <div class="col-4 offset-md-4 text-right">
                                <button [disabled]="startTimeInvalid || hourInvalidInfo || numberToursInvalid"
                                    mat-button matStepperNext class="btn btn-primary"
                                    (click)="nextStep('info', 'edit')">Next</button>
                            </div>
                        </form>
                    </mat-step>
                    <!--Quantity Form-->
                    <mat-step [stepControl]="quantityFormEditGroup" label="Location and Sizes" [completed]="true">
                        <form (keydown.enter)="$event.preventDefault()" [formGroup]="quantityFormEditGroup"
                            class="row g-3">
                            <ng-template matStepLabel>Quantity</ng-template>
                            <div class="col-md-12">
                                <label class="form-label">Physical Location <b style="color:red">*</b> <span
                                        class="text-info pointer" (click)="locationView()"><i
                                            class="fas fa-plus-circle ms-1"></i> Add new location</span></label>
                                <select class="form-select form-select-sm" name="productLocationID"
                                    formControlName="productLocationID" (change)="checkRequiredFields('location')">
                                    <option disabled>Select a Physical Location...</option>
                                    <ng-container *ngFor="let location of locations">
                                        <option [value]="location.id"
                                            [selected]="location.id == qty.productLocationID.value">
                                            {{location.name}}
                                        </option>
                                    </ng-container>
                                </select>
                                <!--Available Locations-->
                                <div class="mt-1">
                                    <label class="form-label">Available at Locations</label>
                                    <div class="border rounded p-2">
                                        <div class="row">
                                            <ng-container *ngFor="let location of locations">
                                                <div *ngIf="location.notShow" class="col-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox"
                                                            [checked]="location.isSelected"
                                                            (click)="selectAvailableLocations(location)">
                                                        <label class="form-check-label">
                                                            {{location.name}}
                                                        </label>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <div class="form-text">
                                                When you select a physical location, it is automatically preselected for
                                                availability, if you uncheck the box it means that it will not be
                                                available
                                                even if the product is there.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Product Number-->
                            <div class="col-12">
                                <label class="form-label">Product Number</label>
                                <div class="border rounded p-2">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value=""
                                                    [(ngModel)]="productNumbers" (change)="changeIfProductNumber()"
                                                    [ngModelOptions]="{standalone: true}">
                                                <label class="form-check-label">
                                                    Add product numbers manually
                                                </label>
                                                <div class="form-text">
                                                    When a new inventory document is created, it's assigned an automatic
                                                    number for internal control, there is also the option to add a
                                                    custom product number.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Qty Sizes-->
                            <div class="col-12">
                                <label class="form-label">Sizes Quantity<span class="linkprimary ms-1"
                                        (click)="addSizeRow()"><i class="fas fa-plus-circle"></i> Add
                                        Another
                                        Size</span></label>
                                <div class="border rounded p-2">
                                    <div class="bg-light p-2 col-12 productslist">
                                        <div class="row">

                                            <ng-container *ngFor="let sizes of arraysizesqty; let i = index">
                                                <div class="col-md-5">
                                                    <label class="form-label">Size Type <b style="color:red">*</b>
                                                        <span *ngIf="sizes.fromdb == undefined"
                                                            class="ms-1 badge bg-primary">NEW!</span></label>
                                                    <div class="input-group mb-3">
                                                        <span *ngIf="i != 0" class="input-group-text linkprimary"
                                                            (click)="deleteSize(i, 'edit')">
                                                            <i class="fas fa-minus-circle"></i>
                                                        </span>
                                                        <select class="form-select form-select-sm"
                                                            (change)="editSizeRow(i,'type',$event)" [disabled]="true && !sizes.newSize">
                                                            <ng-container *ngFor="let sizeType of sizes.sizeType">
                                                                <option [value]="sizeType.id"
                                                                    [selected]="sizeType.isSelected">
                                                                    {{sizeType.productType}}
                                                                </option>
                                                            </ng-container>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-5">
                                                    <label class="form-label">Size <b style="color:red">*</b></label>
                                                    <select class="form-select form-select-sm"
                                                        (change)="editSizeRow(i,'size',$event)"
                                                        [disabled]="sizes.fromdb != undefined">
                                                        <ng-container *ngFor="let size of sizes.sizes">
                                                            <option [value]="size.id" [selected]="size.isSelected">
                                                                {{size.size}}
                                                            </option>
                                                        </ng-container>
                                                    </select>
                                                </div>
                                                <div class="col-md-2">
                                                    <label class="form-label">Quantity <b
                                                            style="color:red">*</b></label>
                                                    <input id="qtysize{{i}}" type="text"
                                                        class="form-control form-control-sm"
                                                        (keyup)="restrictNumbersQty(i,'qty',$event, 'edit')"
                                                        [value]="sizes.qty"
                                                        oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-12 align-items-center text-center"
                                        *ngIf="loadingLocSize && quantitytype">
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Products Table-->
                            <div class="col-12">
                                <span *ngIf="!validateRequired" class="text-danger">
                                    <i class="fas fa-exclamation-circle"></i> You need to fill in all the required
                                    fields
                                    marked
                                    with an asterisk to continue.
                                </span>
                            </div>
                            <div class="col-12 bg-light rounded">
                                <div class="mt-1">
                                    <form (keydown.enter)="$event.preventDefault()" class="row g-3">
                                        <div class="col-8">
                                            <button *ngIf="selectedProducts" type="button"
                                                class="btn btn-primary btn-sm" (click)="deleteSelectedProduct()">
                                                Delete selected Products
                                            </button>
                                            <button *ngIf="selectedProducts" type="button"
                                                class="btn btn-secondary btn-sm ms-1" (click)="UnselectProduct()">
                                                Unselect Products
                                            </button>
                                        </div>
                                        <div class="col-4">
                                            <input type="text" name="searchProductInput"
                                                [(ngModel)]="searchProductInput" class="form-control form-control-sm"
                                                placeholder="Search..." (keyup)="searchProductEdit()" />
                                        </div>
                                    </form>
                                </div>
                                <div class="border rounded p-2 productslist mt-2">
                                    <table id="productsTableEdit" class="table text-center">
                                        <thead class="bg-customblue text-white">
                                            <tr id="tableHeader">
                                                <th></th>
                                                <th>Status</th>
                                                <th *ngIf="productNumbers">Product Number</th>
                                                <th>Name</th>
                                                <th>Location</th>
                                                <th>Size</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="qtyproducts.length > 0">
                                            <ng-container *ngFor="let product of qtyproducts; let i = index">
                                                <tr>
                                                    <td>
                                                        <input type="checkbox" name="productcheck"
                                                            (click)="selectProduct(product,i)"
                                                            [checked]="product.isSelected">
                                                    </td>
                                                    <td>
                                                        <span *ngIf="!product.product.isEdited"
                                                            class="badge bg-secondary">Default</span>
                                                        <span *ngIf="product.product.isEdited"
                                                            class="badge bg-primary">Edited</span>
                                                    </td>
                                                    <td *ngIf="productNumbers && !autoNumber">
                                                        <input
                                                            [ngClass]="{'is-invalid':(product.product.isRepeated || product.product.isExisted)}"
                                                            (keyup)="changeProductNumber($event, i)" type="text"
                                                            class="form-control form-control-sm"
                                                            value="{{product.product.productNumber}}">
                                                        <span
                                                            *ngIf="product.product.isRepeated && !product.product.isExisted"
                                                            class="text-danger font12px"><i
                                                                class="fas fa-exclamation-circle"></i> Product Number
                                                            repeated</span>
                                                        <span
                                                            *ngIf="product.product.isExisted && !product.product.isRepeated"
                                                            class="text-danger font12px"><i
                                                                class="fas fa-exclamation-circle"></i> This number
                                                            already exists</span>
                                                        <span
                                                            *ngIf="product.product.isRepeated && product.product.isExisted"
                                                            class="text-danger font12px"><i
                                                                class="fas fa-exclamation-circle"></i> Existing and
                                                            repeating number</span>
                                                    </td>
                                                    <td *ngIf="productNumbers && autoNumber">
                                                        {{product.product.productNumber}}
                                                    </td>
                                                    <ng-container
                                                        *ngIf="product.product.id != undefined && _currentUser.currentUser.isDeveloper">
                                                        <td>
                                                            <a href="https://console.firebase.google.com/u/0/project/{{ projectId }}/firestore/data/~2Fproducts/{{ product.product?.id }}"
                                                                target="_blank" style="text-decoration: none">
                                                                {{product.product.productName}}
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="product.product.id == undefined && _currentUser.currentUser.isDeveloper">
                                                        <td>{{product.product.productName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container *ngIf="!_currentUser.currentUser.isDeveloper">
                                                        <td>{{product.product.productName}}
                                                        </td>
                                                    </ng-container>
                                                    <td>{{product.product.productLocationName}}</td>
                                                    <td>{{product.product.productSize}}</td>
                                                    <td>
                                                        <button type="button" class="btn btn-primary btn-sm"
                                                            data-bs-toggle="modal" data-bs-target="#modalProductEdit"
                                                            (click)="editProductList(product, i, 'edit')">Edit</button>
                                                        <button type="button" class="ms-1 btn btn-danger btn-sm"
                                                            (click)="deleteSingleProduct(i)">
                                                            <i class="fas fa-trash-alt"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                        <tbody *ngIf="qtyproducts.length == 0">
                                            <tr>
                                                <td colspan="6">There's no set Quantity</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div *ngIf="!validateRequired" class="divover rounded">
                                </div>
                            </div>
                            <div class="col-4">
                                <button class="btn btn-outline-secondary" type="button"
                                    (click)="deleteAllProductByID()">
                                    Delete Product Group
                                </button>
                            </div>
                            <div class="col-4 offset-md-4 text-right">
                                <button mat-button matStepperPrevious class="btn btn-secondary me-1">Back</button>
                                <button mat-button matStepperNext
                                    [disabled]="!validateRequired || (repeatedNumber || existingNumber)"
                                    (click)="nextStep('qty', 'edit')" class="btn btn-success">Save
                                    Changes</button>
                            </div>
                        </form>
                    </mat-step>
                </mat-horizontal-stepper>
            </div>
        </div>
    </div>
</div>

<!-- ImgGallery Modal -->
<div class="modal fade" data-bs-backdrop="false" id="imgGallery">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 90vw; max-height: 90vh">
        <div class="modal-content">
            <div class="modal-body">
                <!-- Button used to close the modal -->
                <div style="position: relative; width: 100%">
                    <button id="closeImgLibraryBtn" type="button" class="btn btn-danger rowBtn" data-bs-dismiss="modal">
                        <i class="fa fa-close"></i>
                    </button>
                </div>

                <!-- The image library component -->
                <app-image-library [showSelect]="true" [defaultTabSelection]="'image'"></app-image-library>
            </div>
        </div>
    </div>
</div>
