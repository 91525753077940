<mat-toolbar color="primary">
  <mat-toolbar-row fxLayoutAlign="space-between center">
    <div fxLayout="row">
      <button mat-button class="logo" (click)="toggleSideBar()">
        <mat-icon>menu</mat-icon>
        <span>MAD MOOSE RENTALS</span>
      </button>


      <div *ngIf="!deviceXs">
        <button mat-button>Sand Hollow</button>
        <button mat-button>Quail Creek</button>
        <button mat-button>Zion</button>
        <button mat-button>Shop</button>
      </div>
    </div>
    <div>
      <button mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger>
        <mat-icon *ngIf="deviceXs">more_vert</mat-icon>
      </button>
      <button mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger>
        <mat-icon *ngIf="!deviceXs">person_outline</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          Log Out
        </button>
      </mat-menu>


    </div>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="deviceXs" fxLayoutAlign="space-between">
    <button mat-button>Sand Hollow</button>
    <button mat-button>Quail Creek</button>
    <button mat-button>Zion</button>
    <button mat-button>Shop</button>
  </mat-toolbar-row>
</mat-toolbar>


