import { connectFirestoreEmulator } from 'firebase/firestore';
import { connectStorageEmulator } from 'firebase/storage';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

// Models
import { FirebaseEmulators, FirebaseEnabled } from '../models/firebase-emulators.model';

@Injectable({
    providedIn: 'root'
})
export class FirebaseEmulatorsService {
  private static localhost: string = "127.0.0.1"
  private static functionsPort: number = 5001
  private static firestorePort: number = 8083
  private static storagePort: number = 9199

  constructor() {}

  // useEmulators will enable the given emulators if the current environment
  // meets the minimum requirements for emulators to be allowed.
  useEmulators(emulators: FirebaseEmulators): FirebaseEnabled {
    const enabled = {
      firestore: false,
      functions: false,
      storage: false
    }

    if (this.canUseEmulators()) {
        const
          emulatorConf = this.defaultEmulatorsConf(environment),
          funcs = emulatorConf.functions || false,
          firestore = emulatorConf.firestore || false,
          storage = emulatorConf.storage || false,
          svc = FirebaseEmulatorsService
        ;

      try {
        if (emulatorConf && funcs && emulators?.afFunctions) {
          emulators.afFunctions.useEmulator(svc.localhost, svc.functionsPort)
          connectFirestoreEmulator(emulators.firestore, svc.localhost, svc.functionsPort)
          enabled.functions = true
        }

        if (emulatorConf && firestore && emulators?.afFirestore) {
          connectFirestoreEmulator(emulators.afFirestore.firestore, svc.localhost, svc.firestorePort)
          enabled.firestore = true
        }

        if (emulatorConf && storage && emulators?.afStorage) {
          connectStorageEmulator(emulators.afStorage.storage, svc.localhost, svc.storagePort)
          enabled.storage = true
        }

      } catch (error) {
        console.error(`Error while attaching emulators`, error)
        return enabled
      }
    }

    return enabled
  }

  // defaultEmulatorsConf returns map of default environment enabled emulator booleans
  private defaultEmulatorsConf(env) {
    if (Object.prototype.hasOwnProperty.call(env, 'emulators')) {
      return env.emulators
    }
    return { functions: false, firestore: false, storage: false }
  }

  // canUseEmulators will determine if the current environment meets the
  // minimum requirements before emulators can be enabled.
  private canUseEmulators(): boolean {
    const
      requirements = [],
      svc = FirebaseEmulatorsService
    ;
    requirements.push(!environment.production)
    requirements.push(Object.prototype.hasOwnProperty.call(environment, 'emulators'))
    requirements.push(window.location.port == '4200')
    requirements.push([svc.localhost, 'localhost'].includes(window.location.hostname))

    return requirements.every(Boolean)
  }

}

