<!-- <form class="needs-validations" #groupf="ngForm" (ngSubmit)="onGroupFormSubmit()"> -->
<form class="needs-validations" #groupf="ngForm" [formGroup]="pageForm" (ngSubmit)="onSubmit()">

    <!-- title -->
    <div class="row">
        <div class="form-group-sm mb-2 input-group-sm">
            <label class="form-check-label" for="pageTitle">Title</label>
            <span class="text-danger">*</span> <!-- Required Symbl -->
            <input class="form-control" type="text" name="pageTitle" id="pageTitle" formControlName="title">
        </div>
        <span class="text-danger mb-2" *ngIf="title.errors?.['required'] && title.touched">
            All pages must have a title.
        </span>
    </div>

    <!-- subHeading -->
    <div class="row">
        <div class="form-group-sm mb-2 input-group-sm">
            <label class="form-check-label" for="pageSubHeading">Sub Heading</label>
            <!-- <span class="text-danger">*</span> -->
            <!-- Required Symbl -->
            <input class="form-control" type="text" name="pageSubHeading" id="pageSubHeading"
                formControlName="subHeading">
        </div>
        <!-- <span class="text-danger mb-2" *ngIf="subHeading.errors?.['required'] && subHeading.touched">
            All pages must have a sub heading.
        </span> -->
    </div>

    <!-- img -->
    <div class="row">
        <div class="form-group-sm mb-2 input-group-sm">
            <div>
                <label class="form-check-label" for="pageImg">Page Image</label>
                <span class="text-danger">*</span> <!-- Required Symbl -->
            </div>
            <span *ngIf="(croppedImage == undefined || croppedImage == '') && hasImgGalleryBtnBeenClicked"
                class="text-danger">Please select an image from the image library to apply to the page.</span>
        </div>
        <div>
            <button class="btn btn-success" (click)="openImgGallery()" type="button" style="width:150px !important">
                <i class="fa fa-picture-o" aria-hidden="true"></i> Image Library
            </button>


            <div *ngIf="croppedImage != undefined && croppedImage != ''" class="col-lg-12 mt-4">
                <img style="width:100%;" [src]="croppedImage" />
            </div>
        </div>


    </div>

    <!-- Buttons -->
    <div class="container-xxl">
        <div class="row justify-content-center mt-2">

            <div class="text-center">

                <button *ngIf="!isEditing" type="submit" class="btn btn-success"
                    [disabled]="pageForm.invalid || (croppedImage == undefined || croppedImage == '')">
                    Create
                </button>

                <!-- isEditing -->
                <button *ngIf="isEditing" type="submit" class="btn btn-success"
                    [disabled]="pageForm.invalid || (croppedImage == undefined || croppedImage == '')">
                    Done
                </button>
            </div>
        </div>
    </div>
</form>

<!-- Img Gallery Modal -->
<div class="modal fade" data-bs-backdrop="false" id="imgGallery">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 90vw; max-height: 90vh;">
        <div class="modal-content">
            <div class="modal-body">

                <!-- Button used to close the modal -->
                <div style="position: relative; width: 100%">
                    <button style="float: right;" id="closeImgLibraryBtn" type="button" class="btn btn-danger rowBtn"
                        data-bs-dismiss="modal">
                        <i class="fa fa-close"></i>
                    </button>
                </div>

                <!-- The image library component -->
                <!-- defaultTabSelection determines what tab should be active by default -->
                <!-- showSelect bool set to true if the select option needs to be active upon clicking on img -->
                <app-image-library [showSelect]="true" [defaultTabSelection]="'image'"></app-image-library>
            </div>
        </div>
    </div>
</div>