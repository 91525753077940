<!-- Header -->
<div style="background: radial-gradient(circle at top left, rgb(231, 231, 231) 40%, transparent 45%), linear-gradient(#eaeaea, #e5e1e1); z-index: 2; padding-top: 1em;">

  <section class="d-flex justify-content-between" style="padding-left: 8px; padding-right: 8px">

    <!-- Logo -->
    <div class="d-flex align-items-center">
      <div>
        <img *ngIf="templateObj?.companyLogo" id="logo" i18n-alt alt="Company Logo" src="{{templateObj?.companyLogo}}">
        <span *ngIf="(!templateObj?.companyLogo || templateObj?.companyLogo === '') && isCreator">
          <span i18n>Missing company logo</span>
        </span>
      </div>

      <h1 *ngIf="isScreenLarge" style="margin-bottom: 0px !important; font-size: 25px;" class="ml-3">
        {{templateObj?.companyName}}</h1>
    </div>

    <!-- Iframe Exit Button -->
    <div class="justify-content-end">
      <!-- Buttons -->
      <div class="mb-1 d-flex justify-content-end">
        <!-- Search by date -->
        <div>
          <button
            *ngIf="currentBookingFlowNavigationType === BookingFlowNavigationType.Catalog"
            id="searchByDateOptionBtn" type="button" (click)="bookingFlowNavigate.emit(BookingFlowNavigationType.DateRangeSearch)"
            style="background-color: #33628b; color: white;" class="btn mr-2"><i class="fa fa-calendar bounce"></i>
            <span class="underline ml-1 customerViewOptionBtns" i18n>Browse by Date</span>
          </button>

          <button *ngIf="currentBookingFlowNavigationType === BookingFlowNavigationType.DateRangeSearch" (click)="bookingFlowNavigate.emit(BookingFlowNavigationType.Catalog)"
            style="background-color: #33628b; color: white;" type="button"
            class="btn mr-2 customerViewOptionBtns"><span><i class="fa fa-columns" aria-hidden="true"></i>
              <span class="ml-1" i18n>Browse by Catalog</span>
            </span>
          </button>

          <!-- Cart popover content -->
          <ng-template #popoverCartBtns>
            <div class="d-flex justify-content-center">
            </div>
            <div class="d-flex justify-content-between mb-2 mt-2">
              <button (click)="bookingFlowNavigate.emit(BookingFlowNavigationType.Catalog)" style="background-color: #33628b; color: white;"
                type="button" class="btn mr-2 customerViewOptionBtns"><span><i class="fa fa-columns"
                    aria-hidden="true"></i>
                    <span class="ml-1" i18n>Browse by Catalog</span>
                  </span>
              </button>

              <button id="searchByDateOptionBtn" type="button" (click)="bookingFlowNavigate.emit(BookingFlowNavigationType.DateRangeSearch)"
                style="background-color: #33628b; color: white;" class="btn mr-2"><i class="fa fa-calendar bounce"></i>
                <span class="underline ml-1 customerViewOptionBtns">Browse by Date</span>
              </button>
            </div>
          </ng-template>

          <button [ngbPopover]="popoverCartBtns" #popover="ngbPopover" popoverClass="width: 100%;"
            [disablePopover]="isCreator" style="border: 2px solid #06305c; color: #06305c;" *ngIf="currentBookingFlowNavigationType === BookingFlowNavigationType.Cart"
            style="background-color: #33628b; color: white;" type="button"
            class="btn mr-2 customerViewOptionBtns"><span><i class="fa fa-reply" aria-hidden="true"></i><span
              class="ml-1" i18n>Back to shopping</span></span>
          </button>
        </div>

        <!-- Shopping Cart -->
        <button class="text-right btn btn-primary position-relative mr-2" id="cartBtn"
          (click)="bookingFlowNavigate.emit(BookingFlowNavigationType.Cart)">
          <i id="cartIcon" class="fa fa-shopping-cart" aria-hidden="true"></i>
          <span style="display: none;"
            [ngStyle]="cartObj?.items?.length > 0 ? {'display': 'block'} : {'display': 'none'}"
            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            {{cartObj?.items?.length}}
          </span>
        </button>

        <!-- Close Iframe -->
        <!-- <button id="exitBtn" type="button" class="btn" [autoClose]="'outside'" [ngbPopover]="popoverContent"
          #popover="ngbPopover" popoverClass="width: 100%;" [disablePopover]="isCreator"
          style="border: 2px solid #06305c; color: #06305c; display: none;">
          <i class="fa fa-close"></i>
        </button> -->
      </div>

      <!-- Custom Cart -->
      <ng-container *ngIf="cartObj?.isCustomCart">
        <span style="background-color: #bfd5e5; float:right;"
          class="badge rounded-pill bg-warning text-dark mr-1 hoverrow" [delay]="100"
          i18n-tooltip tooltip="This cart was created custom for you! Permissons and prices may be altered">
          <span i18n>Custom Cart</span>
        </span>
      </ng-container>
    </div>
  </section>

  <!-- Divider -->
  <div class="d-flex mt-2 mb-2" style="border-bottom: solid 2px#ffffff;"></div>
</div>

<!-- Exit popover content -->
<!-- <ng-template #popoverContent>
  <div class="d-flex justify-content-center">
    <span style="font-size: medium;"><strong>Leave {{templateObj?.companyName}}?</strong></span>
  </div>
  <span>Items in your cart will remain for your convinence.</span>
  <div class="d-flex justify-content-between mb-2 mt-2">
    <button type="button" style="background-color: #dc3545; color: white; width: 50%;"
      class="btn mr-1 lightenOnHover">Exit</button>
    <button (click)="popover.close()" type="button" style="background-color: #33628b; color: white; width: 50%;"
      class="btn ml-1 lightenOnHover">Stay</button>
  </div>
</ng-template> -->
