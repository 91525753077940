<ng-container *ngIf="mode === 'button'">
    <div class="row">
      <button class="btn btn-file" [disabled]="!isEditing" [ngClass]="isButtonAlert ? 'btn-danger': 'btn-primary'" style="width:150px !important">
        <i class="fa fa-upload"></i>  Upload Image <input type="file" accept="image/png, image/jpeg, image/jpg,.pdf" (change)="startUpload($event.target)"
          [disabled]="!isEditing">
        <!-- <i class="fa fa-upload"></i> {{fileName}} <input type="file" (change)="startUpload($event.target)"
          [disabled]="!isEditing"> -->
      </button>
    </div>
    <div class="row mt-2" *ngIf="uploading">
      <!-- <i class="fa fa-spinner" style="color: #0069D9;"></i> -->
      <div class="d-flex justify-content-center">

        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  <!-- <div class="row">
    <button class="btn btn-file" [disabled]="!isEditing" [ngClass]="isButtonAlert ? 'btn-danger': 'btn-primary'">
      <i class="fa fa-upload"></i> {{fileName}} <input type="file" (change)="startUpload($event.target)"
        [disabled]="!isEditing">
    </button>
  </div>
  <div class="row mt-2" *ngIf="uploading">
    <i class="fa fa-spinner" style="color: #0069D9;"></i>
  </div> -->

  <!--
    <div class="row mt-2" *ngIf="isButtonAlert">
      <b style="color: red;"> Document Name Required</b>
    </div>
    -->
</ng-container>

<div *ngIf="mode === 'dropzone' ">
  <input type="file" id="dropzoneFileInput" name="file" (change)="startUpload($event.target)" multiple hidden>
  <div class="dropzone" dropZone (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()" (dropped)="startUpload($event)"
    [class.hovering]="isHovering" (click)="clickDropzone()">

    <h3 style="width: 100%; text-align: center; font-size: 1.5em; opacity:  0.54;;">Upload</h3>
    <i class="fas fa-upload" aria-hidden="true" style="opacity: 0.54;"></i>

    <!-- <p>Drag and Drop a File</p> -->
    <!-- <div class="file mt-3">
      <label class="file-label">
        <input type="file" (change)="startUpload($event.target)" multiple hidden>
        <span class="file-cta chooseFile">
          <span class="file-icon">
            <i class="fa fa-upload"></i>
          </span>
          <span class="custom-file-label" for="customFile">
            or choose a file...
          </span>
        </span>
      </label>
    </div> -->

  </div>



  <div *ngIf="percentage | async as pct">
    <progress style="width: 100%" [value]="pct" max="100"></progress>
  </div>

  <div *ngIf="(snapshot | async) as snap">
    <!-- {{ snap.bytesTransferred | fileSize }} of {{ snap.totalBytes | fileSize }}  -->

    <div *ngIf="downloadURL | async as url">
      <h3>Results!</h3>
      <img [src]="url">
      <a [href]="url">Download Me!</a>
    </div>

    <button (click)="task.pause()" [disabled]="!isActive(snap)">Pause</button>
    <button (click)="task.cancel()" [disabled]="!isActive(snap)">Cancel</button>
    <button (click)="task.resume()" [disabled]="!(snap?.state === 'paused')">Resume</button>


  </div>

</div>