import { Injectable } from '@angular/core';
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { firstValueFrom, Observable, Subject, take } from 'rxjs';

// @angular/fire/firestore next api
import {
  collection as getCollectionRef,
  DocumentData,
  endBefore,
  Firestore,
  getAggregateFromServer,
  getCountFromServer,
  getDocsFromServer,
  limit,
  limitToLast,
  orderBy,
  query,
  Query,
  QueryFilterConstraint,
  startAfter,
  where,
} from '@angular/fire/firestore';

// Models
import {
  Aggregate,
  Condition,
  DataSourceIface,
  Junction,
  JunctionKey,
  OrderBy,
  PageBy,
  PaginateBy,
  QueryConfig,
} from "src/app/v2/models/firestore-interaction.model";
import { Collection } from "src/app/v2/models/collection-reference.model";

// Services
import { FirebaseEmulatorsService } from 'src/app/services/firebase-emulators.service';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService implements DataSourceIface {

  constructor(
    private fs: Firestore,
    private afs: AngularFirestore,
    private fsEmulatorService: FirebaseEmulatorsService,
  ) {
    this.fsEmulatorService.useEmulators({ afFirestore: this.afs, firestore: this.fs })
  }

  /**
   * batchReader retrieve all docs for a given set of QueryConfig
   *
   * do NOT use, keep it for now.
   * originally built to address getting docs without hitting firestore limits
   * however it doesn't completely fit the needs.
   * @param collection
   * @param configs
   * @param orderBy
   * @returns
   */
  private async batchReader(collection: Collection, configs: QueryConfig[], orderBy?: OrderBy[]) {
    const retrievedDocuments = [];  // initialize an empty array to store all retrieved documents
    const limit = 25;  // number of documents to retrieve per batch
    let lastDocument = null;  // keep track of the last document for pagination
    let currentRecordsReturned = 0;  // total number of records returned so far

    try {
      // Continuously query Firestore in batches of the defined limit until no more documents are found
      while (true) { // eslint-disable-line no-constant-condition

        const paginate: PaginateBy = {
          limit,
        };

        if (lastDocument) { // If lastDoc found, we have to reference it's ID for the next pagination
          paginate.startAfter = lastDocument.id; // Set startAfter if we have a previous document
        }

        // Fetch the next batch of documents
        const batchOfQueriedDocuments = await firstValueFrom(
          this.getCollection(collection, configs, orderBy, paginate
          )
        );
        // If no more documents are found, exit the loop
        if (!batchOfQueriedDocuments || batchOfQueriedDocuments.length === 0) {
          console.info('no more documents to retrieve. Exiting loop.');
          break;
        }

        // Add the retrieved batch to the total collection of product groups
        retrievedDocuments.push(...batchOfQueriedDocuments);
        currentRecordsReturned += batchOfQueriedDocuments.length;

        // Update the last document for pagination
        lastDocument = batchOfQueriedDocuments[batchOfQueriedDocuments.length - 1];

        // Log the progress
        console.log(`retrieved batch of ${batchOfQueriedDocuments.length} documents, total so far: ${currentRecordsReturned}`);

        // If the number of returned documents is less than the limit, assume end of collection
        if (batchOfQueriedDocuments.length < limit) {
          console.info('final batch retrieved, less than limit. No more documents to query.');
          break;
        }
      }

      // Return all accumulated documents
      console.log(`total docs retrieved: ${currentRecordsReturned}`);
      return retrievedDocuments;
    } catch (error) {
      console.error('error retrieving documents:', error);
      throw new Error('failed to retrieve documents. Please check your query or network connection.');
    }
  }

  private _buildQuery(q: Query, configs: QueryConfig[]): Query {
    configs.forEach(item => {
      if (JunctionKey in item) {
        const junc = item as Junction
        q = query(q, junc.fn(...this._join(junc.conditions)))
      } else {
        const cond = item as Condition
        q = query(q, where(cond.field, cond.operator, cond.value))
      }
    })
    return q
  }

  /**
   * count will get total documents that match query conditions
   *
   * @param collection firestore document collection to query
   * @param configs array of where clause query details to filter by
   * @returns Promise<number>
   */
  public async count(collection: Collection, configs: QueryConfig[]): Promise<number> {
    let total: number = 0;
    try {
      const response = await this.getCollectionAggregate(
        collection,
        configs,
        { count: true },
      )
      total = response.count
    } catch(err) {
      console.error(`count(${collection}, ..) failure,`, err, { configs })
    }

    console.debug(`count(${collection}, ..)`, { count: total, configs })
    return total
  }

  /**
   * find is an alias to getCollection, also see DataSourceIface
   *
   * @param collection firestore document collection to query
   * @param configs array of where clause query details to filter by
   * @param sort optional, order to in which to sort results by
   * @param page optional, results page size and reference point
   * @returns Observable[]
   */
  public find(collection: Collection, configs: QueryConfig[], sort?: OrderBy[], page?: PaginateBy): Observable<any[]> {
    return this.getCollection(collection, configs, sort, page)
  }

  /**
   * getCollection executes a query for the given firestore document collection
   *
   * @param collection firestore document collection to query
   * @param configs array of where clause query details to filter by
   * @param sort optional, order to in which to sort results by
   * @param paginate optional, results page size and reference point
   * @returns Observable[]
   */
  public getCollection(
    collection: Collection,
    configs: QueryConfig[],
    sort?: OrderBy[],
    paginate?: PaginateBy,
  ): Observable<any[]> { // eslint-disable-line @typescript-eslint/no-explicit-any
    const lim = this._limit(paginate)

    let qry = this._buildQuery(
      query(getCollectionRef(this.fs, collection)),
      configs
    )

    // Apply order by conditions
    if (sort?.length > 0) {
      sort.forEach(order => {
        qry = query(qry, orderBy(order.field, order.direction));
      });
    } else if (paginate?.startAfter || paginate?.endBefore) {
      // order by required for pagination
      // FirebaseError: Too many arguments provided to startAfter(). The number of arguments must be less than or equal to the number of orderBy() clauses
      qry = query(qry, orderBy('__name__', 'asc'))
    }

    if (paginate?.limit === PageBy.NoLimitSize) {
      // NEVER use, except with explict permission
      null
      // specific case for querying full queryset
    } else if (paginate?.startAfter) {
      qry = query(qry, startAfter(paginate.startAfter), limit(lim))
    } else if (paginate?.endBefore) {
      qry = query(qry, endBefore(paginate.endBefore), limitToLast(lim))
    } else {
      qry = query(qry, limit(lim))
    }

    console.debug(`getCollection('${collection}', configs..${configs?.length || 0}, sort?..${sort?.length || 0}, paginate?..${paginate && 'present' || undefined})`,
      {configs, sort, paginate, limit: { requested: paginate?.limit, allowed: lim}}
    )

    const sub = new Subject<any[]>()
    const docs = []
    getDocsFromServer(qry).then(snapshot => {
      snapshot.docChanges().forEach(snap => {
        const data = snap.doc.data()
        data['id'] = snap.doc.id
        docs.push(data)
      })
      sub.next(docs)
    }).catch(err => {
      console.error(`failure in getCollection => getDocsFromServer(${collection}, configs..${configs.length}, sort?..${sort && 'present' || undefined}, paginate?..${paginate && 'present' || undefined})`, err, {qry})
    }).finally(() => {
      sub.complete()
    })

    return sub.asObservable().pipe(take(1))
  }

  /**
   * getCollectionAggregate runs a collection query and then the aggregate
   * function(s) against the results.
   *
   * @param collection firestore document collection to query
   * @param configs array of where clause query details to filter by
   * @param aggregate aggregate spec to use
   * @returns
   *
   * For details on how to use aggregate functions see firestore documentation
   * https://firebase.google.com/docs/firestore/query-data/aggregation-queries#web
   */
  public async getCollectionAggregate(
    collection: Collection,
    configs: QueryConfig[],
    aggregate: Aggregate,
  ): Promise<DocumentData> {
    if (!aggregate.count && !aggregate.spec) {
      throw new Error('missing required arggregate spec, must have count: true or AggregateSpec from firebase/firestore')
    }

    const q = this._buildQuery(
      query(getCollectionRef(this.fs, collection)),
      configs
    )

    let snapshot: DocumentData
    if (aggregate.count) {
      snapshot = await getCountFromServer(q)
      console.debug(`getCollectionAggregate('${collection}', configs..) get count`, snapshot.data(), {configs})
    } else {
      snapshot = await getAggregateFromServer(q, aggregate.spec)
      console.debug(`getCollectionAggregate('${collection}', configs..) get aggregate spec`, snapshot.data(), {configs})
    }

    return snapshot.data()
  }

  public getDocument(collection: Collection, documentID: string): Promise<any> {
    return this.afs
      .collection(collection)
      .doc(documentID)
      .ref.get()
      .then((doc) => {
        if (!doc.exists) {
          throw new Error('Document does not exist');
        }

        const data = doc.data()
        data['id'] = doc.id;
        return data;
      });
  }

  private _join(conditions: Condition[]): QueryFilterConstraint[] {
    const constraints: QueryFilterConstraint[] = []

    // Apply where conditions
    conditions.forEach(condition => {
      constraints.push(where(condition.field, condition.operator, condition.value))
    });

    return constraints
  }

  private _limit(paginate?: PaginateBy): number {
    return Math.min(paginate?.limit || PageBy.DefaultSize, PageBy.MaximumSize)
  }

  public updateDocument<T>(collection: Collection, documentID: string, updateData: Partial<T>, returnDocument?: boolean): Promise<T> {
    return this.afs
      .collection(collection)
      .doc(documentID)
      .update(updateData)
      .then(() => {
        if (returnDocument) {
          // Fetch the updated document
          return this.afs.collection(collection).doc(documentID).ref.get()
            .then(doc => {
              if (doc.exists) {
                const data = doc.data();
                if (data) {
                  data['id'] = doc.id;
                  return data;
                } else {
                  throw new Error('No data found in document');
                }
              } else {
                throw new Error('Document does not exist');
              }
            });
        } else {
          // Return a resolved promise if fetching the document is not required
          const p = Promise<null>
          return p.resolve(null);
        }
      })
      .catch(error => {
        console.error('Error updating document:', error);
        throw error;
      });
  }

  public createDocument<T>(collection: Collection, documentData: T): Promise<T> {
    return this.afs
      .collection(collection)
      .add(documentData)
      .then(docRef => {
        const data = { ...documentData, id: docRef.id };
        return data;
      })
      .catch(error => {
        console.error('Error creating document:', error);
        throw error;
      });
  }

  public createCollectionMap<T extends { id?: string }>(res: T[]): { [key: string]: T } {
    const collectionMap: { [key: string]: T } = {};
    res.forEach((document: T) => {
      collectionMap[document.id] = document;
    });
    return collectionMap;
  }
}
