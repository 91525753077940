import { Component, HostListener, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { Observable, Subscription } from 'rxjs';
import { v4 as uuid } from 'uuid';
import * as _ from 'lodash';
import { WidgetInterface } from 'src/app/models/widget.model';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DndDropEvent } from 'ngx-drag-drop';
import { lastValueFrom } from 'rxjs';
import Swal from 'sweetalert2';
import { WidgetService } from 'src/app/services/widget.service';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { InventoryWidgetEditorComponent } from '../inventory-widget-editor/inventory-widget-editor.component';


@Component({
  selector: 'app-inventory-page',
  templateUrl: './inventory-page.component.html',
  styleUrls: ['./inventory-page.component.scss'],
})

export class InventoryPageComponent implements OnInit{
  @ViewChild('ckEditor') ckEditor: any;
  @ViewChild('InventoryWidgetEditor') InventoryWidgetEditorComponent: InventoryWidgetEditorComponent;

  @Input() childPageId: any;

//   canDeactivate(target: ViewGuard ) {
//     if (target.hasChanges) {
//         return window.confirm('cancel this page?');
//     }
//     return true;
// }

  inventoryPageId: string;
  isDataAvailable:boolean = false;

  _formDesign = {
    fullWidth: false,
    width: 600,
    page_alignment: 'top',
    background_color: '#f5f5f5',
    container_background_color: '#ffffff',
    text_color: '#000000',
    font_family: "'Helvetica Neue', Helvetica, sans-serif",
    font_size: 16,
    rounded_corners: 8,
    page_paddings: 15,
    form_paddings: 15,
    field_rounded_corners: 5,
    form_border_width: 1,
    form_border_color: '#ced4da',
    field_background_color: '#ffffff',
    field_border_width: 1,
    field_border_color: '#ced4da',
    label_font_color: '#000000',
    label_font_size: 14,
    label_font_bold: false,
    label_font_italic: false,
    field_size: 'm',
  }

  public editorConfig: any = {
    toolbarGroups: [
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['align', 'list', 'indent'] },
      { name: 'styles', groups: ['styles'] },
      { name: 'colors', groups: ['colors'] },
      { name: 'insert', groups: ['insert'] },
      { name: 'links', groups: ['links'] },
    ],
    removeButtons:
      'Source,Save,Templates,NewPage,ExportPdf,Preview,Print,Cut,Undo,Redo,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Replace,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,HiddenField,Strike,Superscript,Subscript,BidiLtr,BidiRtl,Language,CreateDiv,Blockquote,Anchor,Unlink,Image,Flash,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,ShowBlocks,About,Maximize,ImageButton',
    height: '65vh',
  };

  public model = {
    editorData: '<p>Write Someting...</p>'
  };

  subs = new Subscription(); // group of subscriptions

  public allProducts;
  public companyWidgetList;
  public widgets;
  public productWidgetList = [];

  public selectedDate : Date;
  public editWidget: boolean = false;

  public widgetToSave;
  public formName;
  public toggleAccordion;
  public dragStartClass;

  public optionChoice;
  public checkoutOptions;

  public expandedIndex = 0;

  public someId = uuid();

  public customerView: boolean = false;

  public components;
  public selectedComponent;
  public newInventoryPage;
  public inventoryPage;
  public originalInventoryPage;

  public addedWidgets: Array<WidgetInterface>;

  public savedWidgetList

  public productSizesLoaded;
  public defaultSizeTypesLoaded;

  public componentSettings = {
    background_color: '',
    border: false,
    rounded_corners: 0,
    padding_top: 10,
    padding_right: 0,
    padding_bottom: 10,
    padding_left: 0,
    height: 0,
    alignment: 'left',
    text_color: '',
    text_size: 15,
  };

  public groupsLoaded = false;
  public widgetsLoaded = false;
  public invPageLoaded = false;

  public sizeTypesMap = {};
  public allProductSizesMap = {};

  public isAlertClosed = false;

  public allProductGroups; // All product groups for a company
  public productGroups; // The groups that product widgets point to
  public productGroupsMap = {};
  public productGroupsFirstLoad = true; // To avoid calling a function the first time the subscription is triggered
  public companyID;
  public navbarfixed: boolean = false;

  public widgetStructureList = [];
  public saveWidgetStructureList = [];
  public activeTab;

  public savedCartWidgetCount: number = null; // How mamy cart widgets arent saved widgets

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private productsService: ProductsService,
      private widgetService: WidgetService, private currentUserService: CurrentUserService){};


  async ngOnInit() {
    this.activeTab = 1;

    // Gets Id from params
    this.subs.add(this.activatedRoute.params.subscribe(
      (params: Params) => {
        if(params['pageId']){
          this.inventoryPageId = params['pageId'];
          this.customerView = false;
        }

       this.callAllSubs();
      }));
      // this.checkHowComponentIsBeingAccessed();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe(); // unsubscribe from all subscriptions in this group
    document.body.classList.remove('drag-drop-page');
  }

  async callAllSubs(){

    // Initially sets widgetList
    // Get inventory page
    await this.productsService.getInventoryPageById(this.inventoryPageId).then(data => {
      this.newInventoryPage = data;

    
      if (this.newInventoryPage.widgetList && Array.isArray(data.widgetList)) {
        // Iterate over each element in widgetList
        this.newInventoryPage.widgetList.forEach(widget => {
          if (widget.widgetType === 'product') {
            // If the "element" property exists and does not have the "is_selected" property
            if (widget.element && !widget.element?.isSelected) {
              // Add the "is_selected" property initialized to false
              widget.element['isSelected'] = false;
              widget.element.show_price_label = false;
              widget.element.show_name = true;
              widget.element.label = 'Select a size';
              // Sets the options to is_selected is false
              widget.element.options.map(op => {
                op.is_selected = false
                return op
              })
            }
          }
        });
      }
      // Ensure that widgetList exists and is an array



      this.inventoryPage = data;

      this.invPageLoaded = true;
      this.getAllData();
    });

    // // Gets all products for a company
    // this.subs.add(this.productsService.getProducts().subscribe(data => {
    //   this.allProducts = data;
    //   this.productsSub = true;
    //   this.nextSubscriptions();
    // }))

    // Gets saved widgets for the accordian display and set display
    this.subs.add(this.widgetService.getWidgets().subscribe(data =>{
      this.savedWidgetList = data;
      this.widgetsLoaded = true;
      this.getAllData();
    }))

    this.subs.add(this.productsService.getProductGroups().subscribe(data => {
      this.productGroups = data;

      data.forEach((productGroup) => {
        this.productGroupsMap[productGroup.id] = productGroup;
      })

      // Calls function after first load
      if(this.groupsLoaded){
        this.getProductWidgetData();
      }
      this.groupsLoaded = true;
      this.getAllData();
    }))

    // Get sizes wiht a promise in and then get default sizes
    this.productsService.getCustomSizeTypesPromiseDefault().then((d) => { // get default size type (1 doc for all companies)
      d.forEach((sizeType) => {
        this.sizeTypesMap[sizeType.id] = sizeType;

        // Get default sizes for a company
        this.productsService.getCustomSizesPromise(sizeType.id).then((data) => {
          data.forEach((size) => {
            this.allProductSizesMap[size.id] = size;          
          })
            this.defaultSizeTypesLoaded = true;
            this.getAllData();
        })
      })
    })

    //Gets all product sizes for a company
    this.subs.add(this.productsService.getProductSizesForCompany().subscribe(data => {
      data.forEach((size) => {
        this.allProductSizesMap[size.id] = size;
      })
      // Only run once because we are query based right now
        this.productSizesLoaded = true;
        this.getAllData();
    }))
    
    document.body.classList.add('drag-drop-page');

  }

  async getAllData(){
    
    if(this.widgetsLoaded && this.groupsLoaded && this.invPageLoaded && this.defaultSizeTypesLoaded && this.productSizesLoaded){
      this.companyID = this.currentUserService.currentUser.companyId;
      this.inventoryPage = await this.widgetService.getSavedWidgetsInfo(this.inventoryPage, this.savedWidgetList);
      // this.inventoryPage = await this.widgetService.getWidgetSectionsAddedWidgets(this.inventoryPage);
      this.inventoryPage = await this.widgetService.getProductWidgetData(this.inventoryPage,  this.productGroupsMap);
      this.inventoryPage = await this.widgetService.getProductOptionSizes(this.inventoryPage, this.allProductSizesMap, this.sizeTypesMap, this.companyID);

      this.originalInventoryPage = JSON.parse(JSON.stringify(this.inventoryPage));

      // Make sure all the saved product widgets product groups still exist
      let cleanSavedWidgetList = [];

      this.savedWidgetList.forEach(async widget => {
        if(widget.widgetType == 'product'){
          // If a product widgets id is not found make sure to show it as inactive in the database
          if(!widget['element']['groupId'] || this.productGroupsMap[widget['element']['groupId']] === undefined){
            widget['isActive'] = false;
            await this.productsService.updateWidget(widget.id, widget);
          }
          else{
            cleanSavedWidgetList.push(widget)
          }
        }
        else{
          cleanSavedWidgetList.push(widget)
        }
      })
      this.savedWidgetList = cleanSavedWidgetList
      this.addSortOrder();
      this.setComponents();
      // await this.getProductWidgetData();
      // this.unselectComponents();
      if(!this.isDataAvailable){
        this.isDataAvailable = true;
      }
    }
  }


  // Gets the data for saved widgets to make sure they are up to date when loaded in
  updateSavedWidgets(){
    for(let i = this.inventoryPage.widgetList.length - 1; i >= 0; i--){
      let found = false;
        if(this.inventoryPage.widgetList[i].savedWidgetId){
          this.savedWidgetList.forEach(item => {
            if(item.id == this.inventoryPage.widgetList[i].savedWidgetId){
              this.inventoryPage.widgetList[i]['element'] = item.element;
              this.inventoryPage.widgetList[i]['savedWidgetId'] = item.id;

              // this.inventoryPage.widgetList[i]['id'] = this.inventoryPage.widgetList[i].id;
              found = true;
            }
          })
          // If a saved widget is not found then remove the widget
          if(!found){
            this.inventoryPage.widgetList.splice(i, 1);
          }
        }
        // If the item widget doesn not have a unique id then add one
        if(!this.inventoryPage.widgetList[i]['id']){
          this.inventoryPage.widgetList[i]['id'] = uuid();
        }
    }

  }

  // Catches any updates to a products data
  // Loops throught the added widgets list to find product widgets
  getProductWidgetData(){
    this.inventoryPage['widgetList'].forEach((widget, ind) => {
      // Checking the section widgets for price
      if(widget.widgetType == "section"){
        widget.addedWidgets.forEach((addedWidgetArray, i) => {
          addedWidgetArray.forEach((addedWidget, index) => {
            if(addedWidget.widgetType == 'product'){
              this.inventoryPage['widgetList'][ind]['addedWidgets'][i][index]['element'] = this.setProductWidgetData(addedWidget);
            }
          });
        })
      }
      else{
        if(widget.widgetType == 'product'){
          this.inventoryPage['widgetList'][ind]['element'] = this.setProductWidgetData(widget);
        }
      }
    })

  }


  // Catches any updates to a products data
  // This is apecific for accordian saved widgets so they can pass the correct info to children
  getProductWidgetDataForSavedWidgets(){
    this.savedWidgetList.forEach((widget, ind) => {
      if(widget.widgetType == 'product'){
        widget['element'] = this.setProductWidgetData(widget);
      }
    })
  }

  setProductWidgetData(widget){
    let newWidgetElement = JSON.parse(JSON.stringify(widget['element']));

    // If product widget has a manual price do not update prices saved
    this.productGroups.forEach(group => {
        if(group.id == newWidgetElement['groupId']){
          newWidgetElement.name = group.groupName;
          newWidgetElement.description = group.groupDescription;
          newWidgetElement.groupId = group.id;
          newWidgetElement.is24hrsPrice = group.is24hrsPrice;
          newWidgetElement['priceByDayOriginal'] = group.priceByDay;
          newWidgetElement['priceByHourOriginal'] = group.priceByHour;
        }
    })

    return newWidgetElement

  }

  //----------------- DRAG FUNCTIONS ------------------//

  onDragStart($event: DragEvent){
    this.dragStartClass = 'bg-light';
  }

  onDragged(item, list, effect) {
    if (effect === 'move') {
      const index = this.inventoryPage.widgetList.findIndex((i: any) => {
        if (item.id && item.id === i.id) {
          return true;
        }
        return false;
      });
      this.inventoryPage.widgetList.splice(index, 1);
    }
  }

  onDragEnd($event: DragEvent){
    this.dragStartClass = '';
  }

  // When widget is dropped into form, it creates a deep clone of widget, sets the isSelected variable
  // creates a new id and then adds the widget to the widgetList
  onDrop(event: DndDropEvent, list, isCartWidgetBool){
    if (['copy', 'move'].indexOf(event.dropEffect) === -1) {
      return false;
    }
    let index = event.index;

    if (typeof index === 'undefined') {
      index = list.length;
    }
    this.unselectComponents();
    const component = JSON.parse(JSON.stringify(event.data));
    component.isSelected = true;
    component.isCartWidget = isCartWidgetBool;

    if(component.widgetName != "New" && component.widgetType != "section"){

      if(!component.savedWidgetId){
        console.log("WEIRD THING FRIED")
        console.log(component.id)
        component.savedWidgetId = component.id;
      }
    }

    component['id'] = uuid();

    //Open widget editor if widget is not a section
    if(component.widgetType != 'section'){
      this.editWidget = true;
    }

    if(isCartWidgetBool){
      let adjustedIndex = this.countAmountOfRegularWidgets(list);
      list.splice(adjustedIndex + index, 0, component);
    }
    else{
      list.splice(index, 0, component);
    }

    this.selectedComponent = component;

    return true;
  }

  countAmountOfRegularWidgets(list){
    let count = 0;
    if(list.length == 0){
      count = 0;
    }
    else{
      list.forEach(item => {
        if(!item.isCartWidget){
          count += 1;
        }
      })

    }
    return count;
  }

  // Uses the isSelected variable to indicate inside of componenet if it is being used
  // Called when a component on form is clicked
  setSelectedComponent($event, component){
    if(!this.customerView){
    $event.stopPropagation();
    this.unselectComponents();

    component.isSelected = true;
    //Open widget editor if widget is not a section
    if(component.widgetType != 'section'){
      this.editWidget = true;
    }
    this.selectedComponent = component;
    this.isAlertClosed = false;
    }
  }

  // Called when exiting widget editor or when clicking off widget
  // Changes editWidget, isSelected and selectedComponent to false and empty values
  changeComponent(){
    
    if(this.selectedComponent){
      this.selectedComponent.isSelected = false;
      this.selectedComponent = null;
      this.unselectComponents();
    }
  }

  // Unset the isSelected for any previous components
  unselectComponents(){
    this.editWidget = false;
    // Inv Page
    this.inventoryPage.widgetList.forEach(item => {
      item.isSelected = false;
    });

  }

  deleteWidget($event, id){
    $event.stopPropagation();
    // Inv Page
    this.inventoryPage.widgetList.forEach((item, index) => {
      if(item.id == id){
        this.inventoryPage.widgetList.splice(index, 1);
      }
    })

    this.editWidget = false;
    this.selectedComponent = null;
  }

  // Allows edit when pencil is clicked
  onEditWidget(){
    this.editWidget = true;
  }

  // Closes edit tab for a component
  onStopEditWidget(){
    this.editWidget = false;
  }

  // Splits the widget list and returns either regular widgets or cart widgets for display based off bool passed in
  filterWidgetList(list, cartBool){
    return this.widgetService.filterWidgetCartList(list, cartBool);
  }

  // Sets saved widget Id when it is chnaged in the widget editor
  addSavedWidgetId(savedId){
    this.selectedComponent['savedWidgetId'] = savedId;
    this.inventoryPage.widgetList.forEach(widget => {
      if(widget['id'] == this.selectedComponent['id']){
        widget['savedWidgetId'] = savedId;
        widget['widgetName'] = this.selectedComponent['widgetName'] ;

      }
    })
    // this.editWidget = false;
    // this.selectedComponent = null;
  }

  // Updates the invenetory page with new widgetList
  async onSavePage(){

      Swal.fire({
        title: 'Saving',
      })

      Swal.showLoading();

      this.savedCartWidgetCount = await this.checkCartWidgets();
      if(this.savedCartWidgetCount > 0){
        Swal.fire({
          title: 'Please convert all cart widgets to saved widgets before saving page',
          html: this.savedCartWidgetCount + ' widget(s) remaining that need to be converted',
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonColor: '#7066e0',
          confirmButtonText: 'OK',
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
        })
        return
      }

      await this.addSortOrder();
      this.originalInventoryPage =  JSON.parse(JSON.stringify(this.inventoryPage));
      let newInvPage = await this.getOriginalWidgetInputsAndRemoveExcessFields();
      this.productsService.updateInventoryPage(this.inventoryPageId, newInvPage).then(data =>{
        // this.originalInventoryPage = newInvPage;
        Swal.close();
        Swal.fire({
          title: "Completed!",
          icon: "success",
          showConfirmButton: true,
          allowEnterKey: true,
        })
      })
  }

  // Make sure all cart widgets are saved widgets
  checkCartWidgets(){
    let count = 0
    this.inventoryPage.widgetList.forEach(widget => {
      if(widget.isCartWidget && !widget.savedWidgetId){
        count += 1;
      }
    })
    return count
  }

  // Keep track of the sort order in the array for the shopping cart purposes
  addSortOrder(){
    let invNum = 0;
    // let cartNum = 1;
    this.inventoryPage['widgetList'].sort((a, b) => {
      if (a.isCartWidget === b.isCartWidget) {
        return 0; // Maintain the relative order if both have the same active status
      }
      if (!a.isCartWidget) {
        return -1; // `a` goes before `b` if `a.active` is true
      } else {
        return 1; // `b` goes before `a` if `b.active` is true
      }
    });

    this.inventoryPage['widgetList'].forEach((widget, ind) => {
        widget['sortOrder'] = invNum;
        invNum += 1;
    })
  }


  // Resets the input values
  // Clears the tempProductsList
  // Removes unecessary fields from saved widgets stored on inventory page
  // Updates the product widget to store the correct by day and by horu data just in case
  getOriginalWidgetInputsAndRemoveExcessFields(){
    let widgetForm = JSON.parse(JSON.stringify(this.inventoryPage));

    // Resetting input values
    widgetForm.widgetList.forEach(widget => {

      // Change input to original value
      this.widgetStructureList.forEach(item => {

        if (item.widgetType == widget.widgetType) {
          // Height input doesnt use the inputValue variable, it only has feetInput and heightInput
          if (widget.widgetType == 'heightInput') {
            widget.element.feetInput = item.element?.feetInput || null;
            widget.element.inchInput = item.element?.inchInput || null;
          }

          else if (widget.widgetType == 'dropdown' || widget.widgetType == 'radios') {
            widget.element.inputValue = item.element.inputValue;
            widget.element.options.forEach((option, i) => {
              option.is_selected = item.element.options[i]?.is_selected || false;
            })
          }

          else if (widget.widgetType == 'quantity' || widget.widgetType == 'checkbox' || widget.widgetType == 'weightInput') {
            widget.element.inputValue = item.element?.inputValue || null;
          }

          else if (widget.widgetType == "product") {
            delete widget['tempProductWidgets']
            delete widget.element.priceByDayOriginal;
            delete widget.element.priceByHourOriginal;

            if (widget['element']['isDropdown']) {
              widget['element']['options'].forEach(option => {
                if (option?.is_selected) {
                  option.is_selected = false;
                }
              })
            }
            else {
              widget['element']['options'].forEach(option => {
                option.inputValue = 0;
              })
            }
          }
        }
      });

      // Unselecting widgets
      if (widget?.isSelected == true) {
        widget.isSelected = false;
      }

      // Removing saved widgets unecessary info
      if (widget?.savedWidgetId) {
        delete widget['element'];
        delete widget['lastModified'];
      }

      // Remove extra variables
      delete widget['iconClass'];
      delete widget['name'];
      
    })

    return widgetForm;
  }

  // Navigates back to inventory page builder home page
  async onGoBack(){
    let needSave = await this.doesPageNeedSave();
    // let newWidgetList = this.cleanWidgetList();

    if(needSave){
      Swal.fire({
        title: 'Leave Page?',
        html: 'Changes that were made will not be saved',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#7066e0',
        cancelButtonText: 'Leave',
        showConfirmButton: true,
        confirmButtonColor: '#6e7881',
        confirmButtonText: 'Cancel',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isDismissed) {
          this.router.navigate(['/partner/inventoryPageBuilder'])
        }
      });
    }
    else{
      this.router.navigate(['/partner/inventoryPageBuilder'])
    }

    // this.router.navigate(['/partner/inventoryPageBuilder'])
  }

  doesPageNeedSave(){

    // Anything selected will not count
    this.inventoryPage.widgetList.forEach(widget => {
      if(widget['isSelected']){
        widget['isSelected'] = false;
      }
    })

    let needToSavePage = false;
    if(this.originalInventoryPage.widgetList.length != this.inventoryPage.widgetList.length){
      needToSavePage = true;
    }

    if(!_.isEqual(this.originalInventoryPage.widgetList, this.inventoryPage.widgetList)){
      needToSavePage = true;
    }
 
    return needToSavePage

  }

  closeAlert(){
    this.isAlertClosed = true;
  }

  //add a title variable to savedWidget and updates widgets collection in database
  closeTemplateModal() {
    $('#createTemplateModal').modal('hide')
  }

  onOpenSavedWidgetModal() {
    $('#createTemplateModal').modal('show')
  }

  onSaveWidget(){
    this.createSavedWidget(this.formName);
  }


  // Cleans the saved widget before saving it
  createSavedWidget(formName) {

    Swal.fire({
      title: 'Saving',
    })

    Swal.showLoading();

    let newSavedWidget;
    this.selectedComponent["widgetName"] = formName? formName : "";
    newSavedWidget = JSON.parse(JSON.stringify(this.selectedComponent))
    newSavedWidget.isSelected = false;
    newSavedWidget.sectionContentId = '';
    newSavedWidget.sectionPosition = [];
    newSavedWidget['lastModified'] = new Date();

    // Remove any extra fields
    delete newSavedWidget['sortOrder'];
    delete newSavedWidget['sectionContentId'];
    delete newSavedWidget['sectionPosition'];
    delete newSavedWidget['isSelected'];


    if(newSavedWidget.tempProductWidgets){
      delete newSavedWidget.tempProductWidgets
    }

    // Make sure the saved widget keep the right price information based on if its a manual price or not
    // Make sure products inputs are reset
    if(newSavedWidget.widgetType == "product"){
      delete newSavedWidget.element.priceByDayOriginal;
      delete newSavedWidget.element.priceByHourOriginal;
      newSavedWidget['element']['options'].forEach(option => {
        option.inputValue = 0;
      })
    }
    // Reset widget input value
    // Change input to original value
    this.widgetStructureList.forEach(widget => {
      if(this.selectedComponent.widgetType == widget.widgetType){ 
        if(widget.widgetType !=  "section" && widget.widgetType != "price" && widget.widgetType != "tip"
          && widget.widgetType != 'textarea' && widget.widgetType != "image" && widget.widgetType != "heightInput"){
          widget.element.inputValue = this.selectedComponent.element.inputValue;
        }
        // Height input doesnt use the inputValue variable, it only has feetInput and heightInput
      else if(widget.widgetType == "heightInput"){
        widget.element.feetInput = this.selectedComponent.element.feetInput;
        widget.element.inchInput = this.selectedComponent.element.inchInput;
      }
      }
    });

    this.productsService.addWidget(newSavedWidget).then((data => {
      this.selectedComponent['savedWidgetId'] = data;
      this.addSavedWidgetId(this.selectedComponent['savedWidgetId'])
      Swal.fire({
        title: "Completed!",
        icon: "success",
        showConfirmButton: true,
        allowEnterKey: true,
      });
    }));

    this.formName = '';
    this.closeTemplateModal();
  }
  

  public sectionWidgets = [    {
    name: '2 columns',
    widgetType: 'section',
    image: '/../assets/images/sections/col2.png',
    column: '2',
    addedWidgets: new Array(2).fill([] as WidgetInterface[]),
    isSelected: false,
  },
  {
    name: '3 columns',
    widgetType: 'section',
    image: '../../assets/images/sections/col3.png',
    column: '3',
    addedWidgets: new Array(3).fill([] as WidgetInterface[]),
    isSelected: false,
  },
  {
    name: '4 columns',
    widgetType: 'section',
    image: '/../assets/images/sections/col4.png',
    column: '4',
    addedWidgets: new Array(4).fill([] as WidgetInterface[]),
    isSelected: false,
  },
  {
    name: '5 columns',
    widgetType: 'section',
    image: '/../assets/images/sections/col5.png',
    column: '5',
    addedWidgets: new Array(5).fill([] as WidgetInterface[]),
    isSelected: false,
  },
  {
    name: '2 Columns (2:1)',
    widgetType: 'section',
    image: '/../assets/images/sections/col2-1-3.png',
    column: '2-1-3',
    addedWidgets: new Array(2).fill(<WidgetInterface[]>[]),
    isSelected: false,
  },
  {
    name: '2 Column (1:2)',
    widgetType: 'section',
    image: '/../assets/images/sections/col2-3-1.png',
    column: '2-3-1',
    addedWidgets:  new Array(2).fill([] as WidgetInterface[]),
    isSelected: false,
  },];

// Added components are checkout addons a widget type could possibly be used for
// sets objects of widget options with possible addon types

// Setting up accoridan structure for widget info to be copied when dragged onto screen
// Only saved widgets and "New" widgets are dealt with here
  setComponents(){

    // ------ Set checkbox list ------ //
    let checkboxList = [];
    let textareaList = [];
    let quantityList = [];
    let priceList = [];
    let imageList = [];
    let radiosList = [];
    let dropdownList = [];
    let productList = [];
    let heightInputList = [];
    let weightInputList = [];
    let tipList = [];

  //Adding saved widgets to their correct components list to be added to the accordian later
  this.savedWidgetList.forEach(item =>{

    if(item.widgetType == "checkbox"){
      
      item['name'] = 'Checkbox'
      item['iconClass'] = 'far fa-check-square fa-2x'
      item['isCartWidget'] = false

      item['componentSettings'] =
      {
        background_color: '',
        border: true,
        rounded_corners: 10,
        padding_top: 10,
        padding_right: 24,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      }
      checkboxList.push(item);
    }
    else if(item.widgetType == "quantity"){
      item['name'] = 'Quantity'
      item['iconClass'] = 'fa fa-hashtag fa-2x'
      item['isCartWidget'] = false

      item['componentSettings'] =
      {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 15,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      }
      quantityList.push(item);
    }
    else if(item.widgetType == "price"){

      item['name'] = 'Price'
      item['iconClass'] = 'fa fa-dollar-sign fa-2x'
      item['isCartWidget'] = false

      item['componentSettings'] =
      {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 15,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      }
      priceList.push(item);
    }

    else if(item.widgetType == "textarea"){

      item['name'] = 'Textarea'
      item['iconClass'] = 'far fa-window-maximize fa-2x'
      item['isCartWidget'] = false

      item['componentSettings'] = this.componentSettings;
      textareaList.push(item);
    }

    else if(item.widgetType == "image"){

      item['name'] = 'Image'
      item['iconClass'] = 'far fa-image fa-2x'
      item['isCartWidget'] = false

      item['componentSettings'] = 
      {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 0,
        padding_bottom: 10,
        padding_left: 0,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      };
    
      imageList.push(item);
    }
    else if(item.widgetType == "radios"){

      item['name'] = 'Radios'
      item['iconClass'] = 'fas fa-list-ul fa-2x'
      item['isCartWidget'] = false

      item['componentSettings'] =
      {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 15,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,

      }
      radiosList.push(item);
    }
    else if(item.widgetType == "dropdown"){

      item['name'] = 'Dropdown'
      item['iconClass'] = 'far fa-caret-square-down fa-2x'
      item['isCartWidget'] = false

      item['componentSettings'] =
      {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 15,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
        width: 100,
      }
      dropdownList.push(item);
    }
    else if(item.widgetType == "product"){

      item['name'] = 'Product'
      item['iconClass'] = 'fas fa-box fa-2x'
      item['isCartWidget'] = false

      item['componentSettings'] =
      {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 0,
        padding_bottom: 10,
        padding_left: 0,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      }
      productList.push(item);
    }
    else if(item.widgetType == "heightInput"){

      item['name'] = 'Height Input'
      item['iconClass'] = 'fa fa-ruler-vertical fa-2x'
      item['isCartWidget'] = false

      item['componentSettings'] =
      {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 15,
        padding_bottom: 10,
        padding_left: 0,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      }
      heightInputList.push(item);
    }
    else if(item.widgetType == "weightInput"){

      item['name'] = 'Weight Input'
      item['iconClass'] = 'fa fa-weight-scale fa-2x'
      item['isCartWidget'] = false

      item['componentSettings'] =
      {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 5,
        padding_right: 15,
        padding_bottom: 0,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      }
      weightInputList.push(item);
    }
    else if(item.widgetType == "tip"){

      item['name'] = 'Tip'
      item['iconClass'] = 'fa fa-percent fa-2x'
      item['isCartWidget'] = false

      item['componentSettings'] =
      {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 15,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      }
      tipList.push(item);
    }

  })

  // Add all the structure saved widgets to a list ot be displayed or drag n drop menu

  this.saveWidgetStructureList.push(...checkboxList);
  this.saveWidgetStructureList.push(...textareaList)
  this.saveWidgetStructureList.push(...quantityList)
  this.saveWidgetStructureList.push(...priceList)
  this.saveWidgetStructureList.push(...imageList)
  this.saveWidgetStructureList.push(...radiosList)
  this.saveWidgetStructureList.push(...dropdownList)
  this.saveWidgetStructureList.push(...productList)
  this.saveWidgetStructureList.push(...heightInputList)
  this.saveWidgetStructureList.push(...weightInputList)
  this.saveWidgetStructureList.push(...tipList) 
  


    // // ------ Set uploader list ------ //
    // uploaderList.splice(0, 0,{
    //   widgetType: 'uploader',
    //   defaultWidgetId: 9,
    //   widgetName: 'New',
    //   savedWidgetId: '',
    //   sectionContentId: '',
    //   sectionPosition: [],
    //   isSelected: false,
    //   element: {
    //     label: 'Uploader label',
    //     is_required: false,
    //     show_label: true,
    //     content: 'No File Uploaded',
    //     price: null,
    //     description: '',
    //   },
    //   componentSettings: this.componentSettings,

    // })


  this.widgetStructureList =

  [
  // ------ Set checkbox list ------ //
  {
    widgetType: 'checkbox',
    isCartWidget: null,
    widgetName: 'New',
    savedWidgetId: '',
    sectionContentId: '',
    sectionPosition: [],
    isSelected: false,
    name: 'Checkbox',
    iconClass: 'far fa-check-square fa-2x',
    element: {
      label: 'Checkbox label',
      is_required: false,
      price: null,
      description: '',
      inputValue: false,
      recurringPrice: false,
    },
    componentSettings: {
      background_color: '',
      border: true,
      rounded_corners: 10,
      padding_top: 10,
      padding_right: 24,
      padding_bottom: 10,
      padding_left: 15,
      height: 0,
      alignment: 'left',
      text_color: '',
      text_size: 15,
    },
  },

// ------ Set quantity list ------ //
  {
    widgetType: 'quantity',
    isCartWidget: null,
    widgetName: 'New',
    savedWidgetId: '',
    sectionContentId: '',
    sectionPosition: [],
    isSelected: false,
    name: 'Quantity',
    iconClass: 'fa fa-hashtag fa-2x',
    element: {
      label: 'Quantity label',
      is_required: false,
      price: null,
      description: '',
      inputValue: 0,
      recurringPrice: false,
      min: 0,
      max: null,
    },

    componentSettings: {
      background_color: '',
      border: false,
      rounded_corners: 0,
      padding_top: 10,
      padding_right: 15,
      padding_bottom: 10,
      padding_left: 15,
      height: 0,
      alignment: 'left',
      text_color: '',
      text_size: 15,
    },

  },

// ------ Set price list ------ //
  {
    widgetType: 'price',
    isCartWidget: null,
    widgetName: 'New',
    savedWidgetId: '',
    sectionContentId: '',
    sectionPosition: [],
    isSelected: false,
    name: 'Price',
    iconClass: 'fa fa-dollar-sign fa-2x',
    element: {
      label: 'Price label',
      is_required: false,
      price: null,
      description: '',
      inputValue: true,
      recurringPrice: false,
    },
    componentSettings: {
      background_color: '',
      border: false,
      rounded_corners: 0,
      padding_top: 10,
      padding_right: 15,
      padding_bottom: 10,
      padding_left: 15,
      height: 0,
      alignment: 'left',
      text_color: '',
      text_size: 15,
    },

  },

// ------ Set textarea list ------ //
  {
    widgetType: 'textarea',
    isCartWidget: null,
    widgetName: 'New',
    savedWidgetId: '',
    sectionContentId: '',
    sectionPosition: [],
    isSelected: false,
    name: 'Textarea',
    iconClass: 'far fa-window-maximize fa-2x',
    element: {
      label: 'Textarea label',
      is_required: false,
      show_label: true,
      content: 'Placeholder text',
      description: '',
    },
    componentSettings: this.componentSettings,

  },

  // ------ Set image list ------ //
  
  {
    widgetType: 'image',
    isCartWidget: null,
    widgetName: 'New',
    savedWidgetId: '',
    sectionContentId: '',
    sectionPosition: [],
    isSelected: false,
    name: 'Image',
    iconClass: 'far fa-image fa-2x',
    element: {
      image_link: '../assets/images/notAvailable.png',
      label: '',
      alignment: 'center',
      alt_text: '',
      width: 35,
      description: '',
    },
    componentSettings: this.componentSettings,
  },


  // ------ Set radios list ------ //
    
    {
      widgetType: 'radios',
      isCartWidget: null,
      fontSize: 16,
      widgetName: 'New',
      savedWidgetId: '',
      sectionContentId: '',
      sectionPosition: [],
      isSelected: false,
      name: 'Radios',
      iconClass: 'fas fa-list-ul fa-2x',
      element: {
        label: 'Radio buttons label',
        recurringPrice: false,
        description: '',
        inputValue: '',
        options: [
          {
            id: uuid(),
            text: 'Option 1',
            description: '',
            is_selected: true,
            price: null,
  
          },
          {
            id: uuid(),
            text: 'Option 2',
            description: '',
            is_selected: false,
            price: null,
          },
          {
            id: uuid(),
            text: 'Option 3',
            description: '',
            is_selected: false,
            price: null,
          },
        ],
        is_required: false,
        show_label: true,
      },

      componentSettings: {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 15,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,

      },
    },
    


  // ------ Set dropdown list ------ //
    
    {
      widgetType: 'dropdown',
      isCartWidget: null,
      widgetName: 'New',
      savedWidgetId: '',
      sectionContentId: '',
      sectionPosition: [],
      isSelected: false,
      name: 'Dropdown',
      iconClass: 'far fa-caret-square-down fa-2x',
      element: {
        label: 'Dropdown label',
        recurringPrice: false,
        description: '',
        is_required: false,
        show_label: true,
        inputValue: '',
        options: [
          {
            id: uuid(),
            text: 'Option 1',
            is_selected: true,
            price: null,
          },
          {
            id: uuid(),
            text: 'Option 2',
            is_selected: false,
            price: null,
          },
          {
            id: uuid(),
            text: 'Option 3',
            is_selected: false,
            price: null,
          },
        ],
      },
      componentSettings: {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 15,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
        width: 100,
      },
    },
    

  // ------ Set product list ------ //
    {
      widgetType: 'product',
      isCartWidget: null,
      widgetName: 'New',
      savedWidgetId: '',
      sectionContentId: '',
      sectionPosition: [],
      isSelected: false,
      name: 'Product',
      iconClass: 'fas fa-box fa-2x',
      element: {
        groupId: null,
        label: 'Select a size ',
        name: '',
        size: '',
        description: '',
        appliesToTourProductCount: true,
        is_required: false,
        isDropdown: false,
        min: 0,
        max: null,
        priceByDay: null,
        priceByHour: null,
        is24hrsPrice: null,
        price: null,
        show_label: true,
        show_description: true,
        show_price: true,
        show_price_label: false,
        show_name: true,
        inputValue: 0,
        options: [],
      },
      componentSettings : {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 15,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
        width: 100
        }
    },
    

  // ------ Set height input list ------ //
  {
    widgetType: 'heightInput',
    isCartWidget: null,
    widgetName: 'New',
    savedWidgetId: '',
    sectionContentId: '',
    sectionPosition: [],
    isSelected: false,
    name: 'Height Input',
    iconClass: 'fas fa-ruler-vertical fa-2x',
    element: {
      label: 'Height Input Label',
      description: '',
      is_required: false,
      price: null,
      recurringPrice: false,
      feetInput: null,
      inchInput: null
    },
    componentSettings: {
      background_color: '',
      border: false,
      rounded_corners: 0,
      padding_top: 10,
      padding_right: 15,
      padding_bottom: 10,
      padding_left: 15,
      height: 0,
      alignment: 'left',
      text_color: '',
      text_size: 15,
    },
  },

  // ------ Set weight input list ------ //
  {
    widgetType: 'weightInput',
    isCartWidget: null,
    widgetName: 'New',
    savedWidgetId: '',
    sectionContentId: '',
    sectionPosition: [],
    isSelected: false,
    name: 'Weight Input',
    iconClass: 'fas fa-weight-hanging fa-2x',
    element: {
      label: 'Weight Input Label',
      description: '',
      is_required: false,
      price: null,
      recurringPrice: false,
      inputValue: null,
    },
    componentSettings: {
      background_color: '',
      border: false,
      rounded_corners: 0,
      padding_top: 10,
      padding_right: 15,
      padding_bottom: 10,
      padding_left: 15,
      height: 0,
      alignment: 'left',
      text_color: '',
      text_size: 15,
    },
  },
  // ------ Set price list ------ //
  {
    widgetType: 'tip',
    isCartWidget: null,
    widgetName: 'New',
    savedWidgetId: '',
    sectionContentId: '',
    sectionPosition: [],
    isSelected: false,
    name: 'Tip',
    iconClass: 'fa fa-percent fa-2x',
    element: {
      label: 'Tip label',
      is_required: true,
      price: null,
      description: '',
      inputValue: true,
      recurringPrice: false,
    },
    componentSettings: {
      background_color: '',
      border: false,
      rounded_corners: 0,
      padding_top: 10,
      padding_right: 15,
      padding_bottom: 10,
      padding_left: 15,
      height: 0,
      alignment: 'left',
      text_color: '',
      text_size: 15,
    },
  },
  ]
  }


  
} //End of class
