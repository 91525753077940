<table class="table">
  <thead>
    <tr>
      <th scope="col">User</th>
      <th scope="col">Details</th>
      <th scope="col" style="min-width: 80px;">Date</th>

    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let log of logs">
      <td>{{ log.firstName }} {{ log.lastName }}</td>
      <td>{{ log.details }}</td>
      <td>
        {{ log.timestamp.toDate() | date: 'M/d/yy' }}
        <br>
        {{ log.timestamp.toDate() | date: 'h:mm a' }}
      </td>
    </tr>
  </tbody>
</table>