import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
// Additional custom validation functions for FleetMaid
export class ValidatorService {
  constructor() {}

  // csvEmails validates a string of comma separated emails with white space removed
  static csvEmails (control:AbstractControl) : ValidationErrors | null {

    const value = control.value;

    if (!value) {
        return null;
    }

    const emails = value.split(",")
    if (!emails.length) {
      return null
    }

    const results = emails.map(addr => {
      const val = (addr || '').trim()
      if (!val) {
        return null
      }
      return Validators.email(new FormControl(val)) as ValidationErrors
    })

    let response = null
    results.forEach(res => {
      if (res?.email) {
        response = {email:true}
      }
    })

    return response
  }
}
