import { Component, ViewChild} from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

// Services
import { CurrentUserService } from 'src/app/services/current-user.service';
import { AffiliateService } from 'src/app/services/affiliate.service';
import { CompanyService } from 'src/app/services/company.service';

// Models
import { Affiliate } from 'src/app/models/storage/affiliate.model';
import { User } from 'src/app/models/storage/user.model';

// Libraries
import Swal from 'sweetalert2';

@Component({
  selector: 'app-affiliate',
  templateUrl: './affiliate.component.html',
  styleUrls: ['./affiliate.component.css']
})
export class AffiliateComponent {
  protected isEditingAf: boolean = false;
  protected affiliatesLoaded: boolean = false;
  protected affiliatesCollection: Affiliate[] = [];
  protected companyWebsite: string = '';
  protected companyID: string = '';
  protected publicUrl: string = '';
  private subs = new Subscription(); // group of subscriptions
  @ViewChild('affiliateCRUD_Modal') private affiliateCRUD_Modal; // Reference to the child component via template reference variable (can't use ViewChild with the component due to conditional rendering)

  constructor(private currentUserService: CurrentUserService, private affiliateService: AffiliateService, private companyService: CompanyService) { }

  public async ngAfterViewInit(): Promise<void> {
    let currentUser: User = await this.currentUserService.getCurrentUserOnce();
    this.companyID = currentUser.companyId

    // Query by companyID
    let companyDoc = await this.companyService.getCurrentCompany();
    this.companyWebsite = companyDoc.companyWebsite;

    this.publicUrl = `https://${environment.app.apiDomainName}`;

    this.subs.add(this.affiliateService.getAllAffiliates(this.companyID).subscribe(affiliates => {
      if (!this.affiliatesLoaded) { // If first time loading
        for (let affiliate of affiliates) {
          delete affiliate['type'] // no need for change type on init
          this.affiliatesCollection.push(affiliate)
        }
        this.affiliatesLoaded = true;
      }
      else {
        for (let change of affiliates) { // Loop through all changes from collection sub
          switch (change['type']) {
            case 'added': // new doc
              this.affiliatesCollection.push(change); // updates change detection / results being shown on page (includes the new item)
              break;
            case 'modified': // doc changed
              // find index in arr and replace with newly modified obj
              let modifiedIndex = this.affiliatesCollection.findIndex((pg) => { return pg.id == change.id })
              if (modifiedIndex != -1) { // if -1 is returned, index wasn't found (failsafe)
                delete change['type']
                this.affiliatesCollection[modifiedIndex] = change; // deep cloning not necessary (messes up timestamp)
              }
              break;
            case 'removed': // doc deleted
              let removedIndex = this.affiliatesCollection.findIndex((pg) => { return pg.id == change.id })
              if (removedIndex != -1) { // if -1 is returned, index wasn't found (failsafe)
                this.affiliatesCollection.splice(removedIndex, 1); // delete obj from arr
              }
              break;
          }
        }
      }
    }));
  }

  public openAffiliateModal(isEditing: boolean, affiliateObj?: Affiliate) {
    this.isEditingAf = false;
    this.affiliateCRUD_Modal.resetForm();

    if (affiliateObj) { // Provided if editing
      this.isEditingAf = isEditing;
      this.affiliateCRUD_Modal.setFormWithPageValues(affiliateObj);
    }
    $('#affiliateModal').modal('show');
  }

  public onAffiliateFormSubmission($event): void {
    $('#affiliateModal').modal('hide');
  }

  public async softDeleteAffiliate(affiliateID: string): Promise<void> {
    let result = await Swal.fire({
      position: 'center',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete Affiliate',
      icon: 'warning',
      reverseButtons: true,
      title: 'Deleting Affiliate?',
      text: 'Are you sure you want to delete this affiliate? This action cannot be undone.'
    })

    if (!result.isConfirmed) {
      return
    }

    await this.affiliateService.softDeleteAffiliate(affiliateID);
    Swal.fire({ toast: true, position: 'bottom-right', showConfirmButton: false, timer: 3000, timerProgressBar: true, icon: 'success', title: 'Affiliate Deleted' });
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
