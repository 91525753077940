import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { Maintenance } from '../models/storage/maintenance.model';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  public unavailableProducts: number = 0;
  public maintenanceQty: number = 0;

  constructor(private _afs: AngularFirestore) { }

  addMaintenance(maintenance: Maintenance){
    return this._afs.collection('maintenance').add(maintenance);
  }
  getMaintenance(productID: any){
    return this._afs.collection('maintenance', ref =>
    ref.where('productID', '==', productID)
    ).snapshotChanges();
  }

  getMaintenancePromise(productID: any){
    return this._afs.collection('maintenance', ref =>
    ref.where('productID', '==', productID)
    ).get().toPromise();
  }
}
