<!--NavBar Top-->
<nav id="Navtop" class="navbar navbar-expand-lg navbar-dark bg-blue" [style.background]="backgroundColor">
  <!---<a class="navbar-brand fs-4" [style.color]="colorFont" ><img *ngIf="companyImg" src="{{companyImg}}"
      class="imagecompany" />{{_currentUser.currentUser.company}}</a>---->
  <ng-template #defaultcolor>
    <a class="navbar-brand fs-4" [style.color]="this.colorFont"><img src="../../assets/images/logos/theme2.png"
        class="imagecompany" />
      <ng-container *ngIf="!_currentUser.currentUser.isDeveloper">{{
        _currentUser.currentUser.company
        }}</ng-container>
      <ng-container *ngIf="_currentUser.currentUser.isDeveloper"><a target="_blank" style="text-decoration: none"
          [style.color]="this.colorFont" [href]="
            'https://console.firebase.google.com/u/0/project/'
            + projectId + '/'
            + 'firestore/data/~2Fcompanies~2F'
            + _currentUser.currentUser.companyId
          ">
          {{ _currentUser.currentUser.company }}</a>
      </ng-container>
    </a>
  </ng-template>

  <div *ngIf="backgroundColor; else defaultcolor">
    <a class="navbar-brand fs-4" [style.color]="colorFont"><img [src]="logo" class="imagecompany" />
      <ng-container *ngIf="!_currentUser.currentUser.isDeveloper">{{
        _currentUser.currentUser.company
        }}</ng-container>
      <ng-container *ngIf="_currentUser.currentUser.isDeveloper"><a target="_blank" style="text-decoration: none"
          [style.color]="this.colorFont" [href]="
            'https://console.firebase.google.com/u/0/project/'
            + projectId + '/'
            + 'firestore/data/~2Fcompanies~2F'
            + _currentUser.currentUser.companyId
          ">
          {{ _currentUser.currentUser.company }}</a>
      </ng-container>
    </a>
  </div>

  <div class="collapse navbar-collapse" id="navbarText">
    <ul class="navbar-nav mr-auto"></ul>
    <ul class="navbar-nav">
      <a class="nav-item dropdown">
        <a class="nav-link" href="#" id="dropUser" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          <img *ngIf="_currentUser.currentUser.icon.code" src="{{ _currentUser.currentUser.icon.code }}" width="30px"
            height="30px" class="rounded-circle" alt="User" />
          <img *ngIf="!_currentUser.currentUser.icon.code" src="assets/user/noFace.png" width="30px" height="30px"
            class="rounded-circle" alt="User" />
        </a>
        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropUser">
          <!-- <a class="dropdown-item" href="/{{companyportal}}/{{companyurl}}"><b>Visit Web Portal</b></a> -->
          <a *ngIf="(_currentUser.currentUser.isAdmin || _currentUser.currentUser.isDeveloper)"
              class="dropdown-item dropcompany selectable icon-link collapseC"
              id="collapseC"
              #collapseC
              data-bs-toggle="collapse" href="#collapseCompanies"
              (click)="isExpanded = !isExpanded; $event.stopPropagation()" [attr.aria-expanded]="isExpanded"
              role="button" aria-expanded="false"
              aria-controls="collapseCompanies">
            <i class="fa fa-exchange fa-fw" aria-hidden="true"></i>
              Switch company
            <i [ngClass]="{'fa': true, 'fa-angle-down': !isExpanded, 'fa-angle-up': isExpanded}" aria-hidden="true"></i>
          </a>
          <div class="collapse" id="collapseCompanies">
            <div>
              <div class="company-container">
                <div *ngFor="let company of companies">
                  <a class="dropdown-item dropcompany selectable"
                    (click)="changeActiveCompany(company.id, company.companyName)">{{ company.companyName }}</a>
                </div>
              </div>
            </div>
          </div>
          <hr  *ngIf="(_currentUser.currentUser.isAdmin || _currentUser.currentUser.isDeveloper)" />
          <div>
            <a class="dropdown-item dropcompany selectable icon-link"
              (click)="openAccount(this._currentUser.currentUser)">
              <i class="fa fa-user fa-fw" aria-hidden="true"></i>
              Account
            </a>
            <hr />
          </div>
          <a class="dropdown-item dropcompany selectable icon-link" href="" (click)="logOut()">
            <i class="fa fa-sign-out fa-fw" aria-hidden="true"></i>
            Logout
          </a>
        </div>
      </a>
    </ul>
  </div>
</nav>
<!-- NavBar END -->
<!--NavBar Top to Tablets-Phones-->
<nav id="topbar" class="navbar navbar-expand-xl navbar-dark bg-blue" [style.background]="backgroundColor">
  <div class="d-flex">
    <ng-template #defaultNavbar>
      <div>
        <button class="navbar-toggler" type="button" (click)="navbarCollapsed = !navbarCollapsed"
          [attr.aria-expanded]="!navbarCollapsed" aria-controls="navbarContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <span><i class="fas fa-bars"></i></span>
        </button>
      </div>
    </ng-template>

    <div *ngIf="backgroundColor; else defaultNavbar">
      <button class="navbar-toggler" type="button" [style.background]="colorFont"
        (click)="navbarCollapsed = !navbarCollapsed" [attr.aria-expanded]="!navbarCollapsed"
        aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
        <span><i class="fas fa-bars" [style.color]="backgroundColor"></i></span>
      </button>
    </div>

    <ng-template #defaultcolorMobile>
      <div class="flex-grow-0">
        <a class="navbar-brand fs-4 ml-1" [style.color]="colorFont"><img src="../../assets/images/logos/theme2.png"
            class="imagecompany" />{{ _currentUser.currentUser.company }}</a>
      </div>
    </ng-template>
    <div class="flex-grow-0" *ngIf="backgroundColor; else defaultcolorMobile">
      <a class="navbar-brand fs-4 ml-1" [style.color]="colorFont"><img [src]="logo" class="imagecompany" />{{
        _currentUser.currentUser.company
        }}</a>
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <ul class="navbar-nav user">
      <li class="nav-item version mr-3">
        <a class="nav-link" [style.color]="colorFont" href="" [routerLink]="'releaseNotes'">
          v{{ oldVersion }}
        </a>
      </li>
      <a class="nav-item dropdown">
        <a class="nav-link" href="#" id="dropUser" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          <img *ngIf="_currentUser.currentUser.icon.code" src="{{ _currentUser.currentUser.icon.code }}" width="30px"
            height="30px" class="rounded-circle" alt="User" />
          <img *ngIf="!_currentUser.currentUser.icon.code" src="assets/user/noFace.png" width="30px" height="30px"
            class="rounded-circle" alt="User" />
        </a>
        <div class="dropdown-menu" aria-labelledby="dropUser">
          <a *ngIf="(_currentUser.currentUser.isAdmin || _currentUser.currentUser.isDeveloper)"
                class="dropdown-item dropcompany selectable icon-link collapseC"
                id="collapseC"
                #collapseC
                data-bs-toggle="collapse" href="#collapseCompanies"
                (click)="isExpanded = !isExpanded; $event.stopPropagation()" [attr.aria-expanded]="isExpanded"
                role="button" aria-expanded="false"
                aria-controls="collapseCompanies"
              >
              <i class="fa fa-exchange fa-fw" aria-hidden="true"></i>
                Switch company
              <i [ngClass]="{'fa': true, 'fa-angle-down': !isExpanded, 'fa-angle-up': isExpanded}" aria-hidden="true"></i>
            </a>
          <div class="collapse" id="collapseCompanies">
            <div>
              <div class="company-container">
                <div *ngFor="let company of companies">
                  <a class="dropdown-item dropcompany selectable"
                    (click)="changeActiveCompany(company.id, company.companyName)">{{ company.companyName }}</a>
                </div>
              </div>
            </div>
          </div>
          <hr  *ngIf="(_currentUser.currentUser.isAdmin || _currentUser.currentUser.isDeveloper)" />
          <div>
            <a class="dropdown-item dropcompany" (click)="openAccount(this._currentUser.currentUser)">Account</a>
            <hr />
          </div>
          <a class="dropdown-item dropcompany" href="" (click)="logOut()">Logout</a>
        </div>
      </a>
    </ul>
  </div>

  <div class="collapse navbar-collapse" [ngbCollapse]="navbarCollapsed" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" [style.color]="colorFont" href="" [routerLink]="'dashboard'"><span
            class="fa fa-dashboard fa-fw"></span> Dashboard</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [style.color]="colorFont" href="" [routerLink]="'order'"><span class="fas fa-store"></span>
          Order Processing</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [style.color]="colorFont" href="" [routerLink]="'bookings'"><span
            class="fa fa-book fa-fw"></span> Bookings</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [style.color]="colorFont" href="" [routerLink]="'maintenance'"><span
            class="fas fa-toolbox fa-fw"></span> Maintenance</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" [style.color]="colorFont" href="" id="dropCalendar" role="button"
          data-bs-toggle="dropdown" aria-expanded="false">
          <span class="far fa-calendar fa-fw"></span> Calendar
        </a>
        <ul class="dropdown-menu" [style.color]="colorFont" [style.background]="backgroundColor" id="candelar"
          aria-labelledby="dropCalendar">
          <li>
            <a class="dropdown-item" [style.color]="colorFont" href="" [routerLink]="'inventoryCalendar'"><span
                class="fas fa-calendar-week fa-fw"></span> Inventory
              Calendar</a>
          </li>
          <!-- [routerLink]="_currentUser.currentUser.currentCompany.isRebuild ? 'inventoryCalendarRebuild' : 'inventoryCalendar'" -->
          <li>
          <li>
            <a class="dropdown-item" [style.color]="colorFont" href="" [routerLink]="'bookingCalendar'"><span
                class="fas fa-calendar-alt fa-fw"></span> Booking
              Calendar</a>
          </li>
        </ul>
      </li>
      <li class="nav-item" *ngIf="!this.isEmployee && isFleetmaid">
        <a class="nav-link" href="" [style.color]="colorFont" [routerLink]="'applicationFees'"><i
            class="far fa-credit-card"></i> Fees Collected</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" [style.color]="colorFont" href="" id="dropMenus" role="button"
          data-bs-toggle="dropdown" aria-expanded="false">
          <span class="fa fa-chart-line fa-fw"></span> Reports
        </a>
        <ul class="dropdown-menu" [style.background]="backgroundColor" id="reports" aria-labelledby="dropMenus">
          <li>
            <a class="dropdown-item" href="" [style.color]="colorFont" [routerLink]="'sales-report'"><span
                class="fas fa-chart-area fa-fw"></span> Sales Report</a>
          </li>
          <li>
            <a class="dropdown-item" href="" [style.color]="colorFont" [routerLink]="'tax-report'"><span
                class="fas fa-chart-area fa-fw"></span> Tax Report</a>
          </li>
          <li>
            <a class="dropdown-item" href="" [style.color]="colorFont" [routerLink]="'report'"><span
                class="fas fa-chart-area fa-fw"></span> Rental Report</a>
          </li>

          <li>
            <a class="dropdown-item" href="" [style.color]="colorFont" [routerLink]="'reports'"><span
                class="fas fa-chart-bar fa-fw"></span> Revenue Report</a>
          </li>
        </ul>
      </li>
      <li class="nav-item">
        <a class="nav-link" [style.color]="colorFont" href="" [routerLink]="'usersManagement'"><i
            class="fas fa-users"></i> Users</a>
      </li>

      <li class="nav-item dropdown" [style.background]="backgroundColor" [style.color]="colorFont">
        <a class="nav-link dropdown-toggle" [style.color]="colorFont" href="" id="dropMenus" role="button"
          data-bs-toggle="dropdown" aria-expanded="false">
          <span class="fa fa-folder-open fa-fw"></span> Setup
        </a>
        <ul id="setup" class="dropdown-menu" [style.background]="backgroundColor" aria-labelledby="dropMenus">
          <li>
            <a class="dropdown-item" [style.color]="colorFont" href="" [routerLink]="'company'"><span
                class="fas fa-building fa-fw mr-3"></span> Company</a>
          </li>
          <!-- <li>
            <a
              class="dropdown-item"
              [style.color]="colorFont"
              href=""
              [routerLink]="'shop-hours'"
              ><span class="fa fa-clock-o fa-fw mr-3"></span> Shop Hours</a
            >
          </li> -->
          <li>
            <a class="dropdown-item" [style.color]="colorFont" href="" [routerLink]="'shop'"><span
                class="fa fa-clock-o fa-fw mr-3"></span> Shop
              Information</a>
          </li>
          <li>
            <a class="dropdown-item" [style.color]="colorFont" href="" [routerLink]="'categories'"><span
                class="fa fa-tags fa-fw mr-3"></span> Categories</a>
          </li>
          <li>
            <a class="dropdown-item" [style.color]="colorFont" href="" [routerLink]="'product-sizes'"><span
                class="fa fa-sliders fa-fw mr-3"></span> Product Sizes</a>
          </li>
          <li>
            <a class="dropdown-item" [style.color]="colorFont" href="" [routerLink]="'library'"><span
                class="fas fa-images fa-fw mr-3"></span> Image Library</a>
          </li>
          <li>
            <a class="dropdown-item" [style.color]="colorFont" href="" [routerLink]="'inventory'"><span
                class="fas fa-shopping-cart fa-fw mr-3"></span> {{isNew ? "Inventory" : "Products"}}</a>
          </li>
          <li *ngIf="isNew">
            <a class="dropdown-item" [style.color]="colorFont" href="" [routerLink]="'booking-suite'"><span
                class="fa fa-book fa-fw mr-3"></span> Booking Suite</a>
          </li>
          <li *ngIf="isNew">
            <a class="dropdown-item" [style.color]="colorFont" href="" [routerLink]="'inventoryPageBuilder'"><span
                class="fas fa-hammer fa-fw mr-3"></span> Inventory Page Builder</a>
          </li>
          <li *ngIf="!isNew">
            <a class="dropdown-item" [style.color]="colorFont" href="" [routerLink]="'checkoutOptions'"><span
                class="fa fa-gear fa-fw mr-3"></span> Checkout Options</a>
          </li>
          <li>
            <a class="dropdown-item" [style.color]="colorFont" href="" [routerLink]="'waiver'"><span
                class="fas fa-file-signature mr-3"></span> Waiver</a>
          </li>
          <ng-container *ngIf="isNew">
            <li>
              <a class="dropdown-item" [style.color]="colorFont" href="" [routerLink]="'affiliate'"><span
                  class="fas fa-handshake-o mr-3"></span> Affiliate</a>
            </li>
          </ng-container>
          <li>
            <a class="dropdown-item" [style.color]="colorFont" href="/partner/discounts" [routerLink]="'discounts'"><span
                class="fas fa-money fa-fw mr-3"></span> Discount Codes</a>
          </li>
          <li>
            <a class="dropdown-item" [style.color]="colorFont" href="" [routerLink]="'page-embeds'"><span
                class="fas fa-code fa-fw mr-3"></span> Page Embeds</a>
          </li>
        </ul>
      </li>

      <hr />
    </ul>
  </div>
</nav>
<!--End BarTop for Tablets-Phones-->

<!-- Bootstrap row -->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-2" id="sidebar-container" [style.background]="backgroundColor">
      <!-- d-* hiddens the Sidebar in smaller devices. Its itens can be kept on the Navbar 'Menu' -->
      <!-- Bootstrap List Group -->
      <ul id="sidebar-left" class="list-group" [style.background]="backgroundColor">
        <!-- /END Separator -->
        <!-- Menu with submenu-->
        <a id="dashboard" href="#" class="bg-blue list-group-item list-group-item-action"
          [style.background]="backgroundColor" [routerLink]="'dashboard'" (click)="closeSubMenu()">
          <div class="d-flex w-100 justify-content-start align-items-center" [style.color]="colorFont">
            <span class="fa fa-dashboard fa-fw mr-3"></span>
            <span class="menu-collapsed">Dashboard</span>
          </div>
        </a>
        <a id="order" href="#" class="bg-blue list-group-item list-group-item-action" *ngIf="isSchedule"
          [style.background]="backgroundColor" [routerLink]="'order'" (click)="closeSubMenu()">
          <div class="d-flex w-100 justify-content-start align-items-center" [style.color]="colorFont">
            <span class="fas fa-store mr-3"></span>
            <span class="menu-collapsed" style="display: block !important">Order Processing</span>
          </div>
        </a>
        <span class="disabledButtonFancy list-group-item list-group-item-action" [style.color]="colorFont"
          [style.background]="backgroundColor" *ngIf="!isSchedule" (click)="noSchedule()">
          <div class="d-flex w-100 justify-content-start align-items-center">
            <span class="fas fa-store mr-3"></span>
            <span class="menu-collapsed" style="display: block !important">Order Processing</span>
          </div>
        </span>
        <a id="bookings" href="#" class="bg-blue list-group-item list-group-item-action" [style.color]="colorFont"
          [style.background]="backgroundColor" [routerLink]="'bookings'" (click)="closeSubMenu()">
          <div class="d-flex w-100 justify-content-start align-items-center">
            <span class="fa fa-book fa-fw mr-3"></span>
            <span class="menu-collapsed">Bookings</span>
          </div>
        </a>
        <a id="maintenance" href="#" class="bg-blue list-group-item list-group-item-action" [style.color]="colorFont"
          [style.background]="backgroundColor" [routerLink]="'maintenance'" (click)="closeSubMenu()">
          <div class="d-flex w-100 justify-content-start align-items-center">
            <span class="fas fa-toolbox fa-fw mr-3"></span>
            <span class="menu-collapsed">Maintenance</span>
            <small class="badge bg-warning ml-2">{{ maintenanceQty }}</small>
            <small class="badge bg-secondary ml-2">{{ unavailableQty }}</small>
          </div>
        </a>

        <a href="#" id="applicationFees" [style.color]="colorFont" [style.background]="backgroundColor"
          class="bg-blue list-group-item list-group-item-action" [routerLink]="'applicationFees'">
          <div class="d-flex w-100 justify-content-start align-items-center">
            <span class="far fa-credit-card mr-3"></span>
            <span class="menu-collapsed">Fees Collected</span>
          </div>
        </a>
        <a id="Calendarcollapse" href="#" [style.color]="colorFont" data-bs-toggle="collapse"
          [style.background]="backgroundColor" [attr.aria-expanded]="ariaExpend.ariaexpend1 ? 'true' : 'false'"
          class="bg-blue list-group-item list-group-item-action flex-column align-items-start"
          [attr.data-bs-target]="'#submenu3'" (click)="toggleSubMenu(0, 'ariaexpend1')">
          <span class="fa fa-calendar fa-fw mr-3"></span>

          <span [style.color]="colorFont" class="menu-collapsed">Calendar</span>
          <span [style.color]="colorFont" class="submenu-icon ml-auto"></span>
        </a>
        <!-- Submenu content -->
        <div [style.color]="colorFont" [style.background]="backgroundColor" id="submenu3" #submenu3 class="collapse"
          [class.show]="!collapseStates[0]">
          <div matTooltip="At least one {{ optionName }} needed" [matTooltipDisabled]="
              isProduct &&
              isCategory &&
              isLocation &&
              isSchedule &&
              isCompanyInfo
            " [matTooltipPosition]="'right'">
            <button id="element-inventory-calendar" [style.color]="colorFont"
              class="list-group-item list-group-item-action" [class.btn-selected]="isButtonSelected === 'iCalendar'"
              [class.text-white]="
                isButtonSelected === 'iCalendar' && colorFont === '#000000'
              " (click)="setBtnSelected('iCalendar')" [disabled]="
                !isProduct ||
                !isCategory ||
                !isLocation ||
                !isSchedule ||
                !isCompanyInfo
              " [ngClass]="{
                disabledButtonFancy:
                  !isProduct ||
                  !isCategory ||
                  !isLocation ||
                  !isSchedule ||
                  !isCompanyInfo,
                ' bg-dark': isProduct && isCategory && isLocation && isSchedule
              }" [routerLink]="'inventoryCalendar'" [style.color]="colorFont">
              <span class="fas fa-calendar-week fa-fw text-left mr-3"></span>
              <span class="menu-collapsed">Inventory Calendar</span>
            </button>
            <!-- [routerLink]="_currentUser.currentUser.currentCompany.isRebuild ? 'inventoryCalendarRebuild' : 'inventoryCalendar'" -->
          </div>
          <div [style.color]="colorFont" matTooltip="At least one {{ optionName }} needed" [matTooltipDisabled]="
              isProduct &&
              isCategory &&
              isLocation &&
              isSchedule &&
              isCompanyInfo
            " [matTooltipPosition]="'right'">
            <button id="element-inventory-bookings" class="list-group-item list-group-item-action"
              [class.btn-selected]="isButtonSelected === 'bCalendar'" [class.text-white]="
                isButtonSelected === 'bCalendar' && colorFont === '#000000'
              " (click)="setBtnSelected('bCalendar')" [disabled]="
                !isProduct ||
                !isCategory ||
                !isLocation ||
                !isSchedule ||
                !isCompanyInfo
              " [ngClass]="{
                disabledButtonFancy:
                  !isProduct ||
                  !isCategory ||
                  !isLocation ||
                  !isSchedule ||
                  !isCompanyInfo,
                ' bg-dark': isProduct && isCategory && isLocation && isSchedule
              }" [routerLink]="'bookingCalendar'" [style.color]="colorFont">
              <span class="fas fa-calendar-alt fa-fw text-left mr-3"></span>
              <span class="menu-collapsed">Booking Calendar</span>
            </button>
          </div>
        </div>

        <a id="reportsCollapsed" [style.color]="colorFont" href="#" data-bs-toggle="collapse"
          [style.background]="backgroundColor" [attr.aria-expanded]="ariaExpend.ariaexpend2 ? 'true' : 'false'"
          class="bg-blue list-group-item list-group-item-action flex-column align-items-start"
          [attr.data-bs-target]="'#submenu2'" (click)="toggleSubMenu(1, 'ariaexpend2')">
          <span [style.color]="colorFont" class="fa fa-chart-line fa-fw mr-3"></span>

          <span [style.color]="colorFont" class="menu-collapsed">Reports</span>
          <span class="submenu-icon ml-auto"></span>
        </a>

        <!-- Submenu content -->
        <div [style.color]="colorFont" [style.background]="backgroundColor" id="submenu2" #submenu2 class="collapse"
          [class.show]="!collapseStates[1]">
          <div matTooltip="At least one {{ optionName }} needed" [matTooltipDisabled]="
              isProduct &&
              isCategory &&
              isLocation &&
              isSchedule &&
              isCompanyInfo
            " [matTooltipPosition]="'right'">
            <button id="element-item-sales" href="#" class="list-group-item list-group-item-action"
              [class.btn-selected]="isButtonSelected === 'sReport'" [class.text-white]="
                isButtonSelected === 'sReport' && colorFont === '#000000'
              " (click)="setBtnSelected('sReport')" [disabled]="
                !isProduct ||
                !isCategory ||
                !isLocation ||
                !isSchedule ||
                !isCompanyInfo
              " [ngClass]="{
                'btn-selected': currentRoute === '/partner/sales-report',
                disabledButtonFancy:
                  !isProduct ||
                  !isCategory ||
                  !isLocation ||
                  !isSchedule ||
                  !isCompanyInfo,
                ' bg-dark': isProduct && isCategory && isLocation && isSchedule
              }" [routerLink]="'sales-report'" [style.color]="colorFont">
              <span class="fas fa-chart-area fa-fw text-left mr-3"></span>
              <span class="menu-collapsed">Sales Report</span>
            </button>
          </div>
          <div matTooltip="At least one {{ optionName }} needed" [matTooltipDisabled]="
              isProduct &&
              isCategory &&
              isLocation &&
              isSchedule &&
              isCompanyInfo
            " [matTooltipPosition]="'right'">
            <button id="element-item-tax" href="#" class="list-group-item list-group-item-action"
              [class.btn-selected]="isButtonSelected === 'tReport'" [class.text-white]="
                isButtonSelected === 'tReport' && colorFont === '#000000'
              " (click)="setBtnSelected('tReport')" [disabled]="
                !isProduct ||
                !isCategory ||
                !isLocation ||
                !isSchedule ||
                !isCompanyInfo
              " [ngClass]="{
                'btn-selected': currentRoute === '/partner/tax-report',
                disabledButtonFancy:
                  !isProduct ||
                  !isCategory ||
                  !isLocation ||
                  !isSchedule ||
                  !isCompanyInfo,
                ' bg-dark': isProduct && isCategory && isLocation && isSchedule
              }" [routerLink]="'tax-report'" [style.color]="colorFont">
              <span class="fas fa-chart-area fa-fw text-left mr-3"></span>
              <span class="menu-collapsed">Tax Report</span>
            </button>
          </div>
          <div matTooltip="At least one {{ optionName }} needed" [matTooltipDisabled]="
              isProduct &&
              isCategory &&
              isLocation &&
              isSchedule &&
              isCompanyInfo
            " [matTooltipPosition]="'right'">
            <button id="element-item-rental" href="#" class="list-group-item list-group-item-action"
              [class.btn-selected]="isButtonSelected === 'rReports'" [class.text-white]="
                isButtonSelected === 'rReports' && colorFont === '#000000'
              " (click)="setBtnSelected('rReports')" [disabled]="
                !isProduct ||
                !isCategory ||
                !isLocation ||
                !isSchedule ||
                !isCompanyInfo
              " [ngClass]="{
                'btn-selected': currentRoute === '/partner/report',
                disabledButtonFancy:
                  !isProduct ||
                  !isCategory ||
                  !isLocation ||
                  !isSchedule ||
                  !isCompanyInfo,
                ' bg-dark': isProduct && isCategory && isLocation && isSchedule
              }" [routerLink]="'report'" [style.color]="colorFont">
              <span class="fas fa-chart-area fa-fw text-left mr-3"></span>
              <span class="menu-collapsed">Rental Report</span>
            </button>
          </div>
          <div matTooltip="At least one {{ optionName }} needed" [matTooltipDisabled]="
              isProduct &&
              isCategory &&
              isLocation &&
              isSchedule &&
              isCompanyInfo
            " [matTooltipPosition]="'right'">
            <button id="element-item-revenue" href="#" class="list-group-item list-group-item-action"
              [class.btn-selected]="isButtonSelected === 'rManagement'" [class.text-white]="
                isButtonSelected === 'rManagement' && colorFont === '#000000'
              " (click)="setBtnSelected('rManagement')" [disabled]="
                !isProduct ||
                !isCategory ||
                !isLocation ||
                !isSchedule ||
                !isCompanyInfo
              " [ngClass]="{
                'btn-selected': currentRoute === '/partner/reports',
                disabledButtonFancy:
                  !isProduct ||
                  !isCategory ||
                  !isLocation ||
                  !isSchedule ||
                  !isCompanyInfo,
                ' bg-dark': isProduct && isCategory && isLocation && isSchedule
              }" [routerLink]="'reports'" [style.color]="colorFont">
              <span class="fas fa-chart-bar fa-fw text-left mr-3"></span>
              <span class="menu-collapsed">Revenue Report</span>
            </button>
          </div>
        </div>

        <a id="info-user" href="#" class="bg-blue list-group-item list-group-item-action"
          [style.background]="backgroundColor" [routerLink]="'usersManagement'" (click)="closeSubMenu()">
          <div [style.color]="colorFont" class="d-flex w-100 justify-content-start align-items-center">
            <span class="fas fa-users mr-3"></span>
            <span class="menu-collapsed">Users</span>
          </div>
        </a>
        <a id="collapseSetup" href="#" [style.color]="colorFont" [style.background]="backgroundColor"
          data-bs-toggle="collapse" [attr.aria-expanded]="ariaExpend.ariaexpend3 ? 'true' : 'false'"
          class="bg-blue list-group-item list-group-item-action flex-column align-items-start"
          [attr.data-bs-target]="'#submenu1'" (click)="toggleSubMenu(2, 'ariaexpend3')">
          <span [style.color]="colorFont" class="fa fa-folder-open fa-fw mr-3"></span>

          <span [style.color]="colorFont" class="menu-collapsed">Setup</span>
          <span [style.color]="colorFont" class="submenu-icon ml-auto"></span>
        </a>
        <!-- SETUP  -->
        <!-- Submenu content -->
        <div [style.color]="colorFont" id="submenu1" #submenu1 class="collapse" [class.show]="!collapseStates[2]">
          <!-- Company  -->
          <!-- STEP 1-->
          <button id="element-item-company" class="bg-dark list-group-item list-group-item-action"
            [class.btn-selected]="isButtonSelected === 'company'" [class.text-white]="
              isButtonSelected === 'company' && colorFont === '#000000'
            " (click)="setBtnSelected('company')" [routerLink]="'company'" [style.color]="colorFont">
            <span class="fas fa-building fa-fw text-left mr-3"></span>
            <span class="menu-collapsed">Company</span>
          </button>
          <!-- <div
            matTooltip="Schedule needed"
            [matTooltipDisabled]="isCompanyInfo"
          >
            <button
              [style.background]="backgroundColor"
              class="list-group-item list-group-item-action bg-dark text-white text-left align-items-left"
              [routerLink]="'shop-hours'"
            >
              <span
                [style.color]="colorFont"
                class="fa fa-sliders fa-fw text-left mr-3"
              ></span>
              <span [style.color]="colorFont" class="menu-collapsed"
                >Shop Hours</span
              >
            </button>
          </div> -->
          <!-- Shop Information  -->
          <!-- STEP 2-->
          <div matTooltip="Company info needed" [matTooltipDisabled]="isCompanyInfo" [matTooltipPosition]="'right'">
            <button id="element-item-shop" href="#" class="list-group-item list-group-item-action"
              [class.btn-selected]="isButtonSelected === 'pLocation'" [class.text-white]="
                isButtonSelected === 'pLocation' && colorFont === '#000000'
              " (click)="setBtnSelected('pLocation')" [disabled]="!isCompanyInfo" [style.color]="colorFont" [ngClass]="{
                disabledButtonFancy: !isCompanyInfo,
                ' bg-dark': isCompanyInfo
              }" [routerLink]="'shop'">
              <span class="fas fa-location-arrow fa-fw text-left mr-3"></span>
              <span class="menu-collapsed">Shop Information</span>
            </button>
          </div>
          <!-- STEP 4-->
          <div matTooltip="At least one {{ optionName }} needed"
            [matTooltipDisabled]="isLocation && isSchedule && isCompanyInfo" [matTooltipPosition]="'right'">
            <button id="element-item-categories" href="#" class="list-group-item list-group-item-action"
              [class.btn-selected]="isButtonSelected === 'category'" [class.text-white]="
                isButtonSelected === 'category' && colorFont === '#000000'
              " (click)="setBtnSelected('category')" [disabled]="!isLocation || !isSchedule || !isCompanyInfo"
              [ngClass]="{
                disabledButtonFancy:
                  !isLocation || !isSchedule || !isCompanyInfo,
                ' bg-dark': isLocation && isSchedule && isCompanyInfo
              }" [routerLink]="'categories'" [style.color]="colorFont">
              <span class="fa fa-tags fa-fw text-left mr-3"></span>
              <span class="menu-collapsed" style="display: contents">Categories</span>
            </button>
          </div>
          <!-- Product Sizes  -->
          <!-- STEP 5-->
          <div matTooltip="Please fill the data" [matTooltipDisabled]="
              ((isCategory && isLocation) || isNew || isDev) &&
              isSchedule &&
              isCompanyInfo
            " [matTooltipPosition]="'right'" [style.background]="backgroundColor">
            <button id="element-item-product-sizes" href="#" class="list-group-item list-group-item-action"
              [class.btn-selected]="isButtonSelected === 'pSizes'" [class.text-white]="
                isButtonSelected === 'pSizes' && colorFont === '#000000'
              " (click)="setBtnSelected('pSizes')" [disabled]="
                ((!isCategory || !isLocation) && (!isNew || !isDev)) ||
                !isSchedule ||
                !isCompanyInfo ||
                !isCompanyInfo
              " [ngClass]="{
                disabledButtonFancy:
                  ((!isCategory || !isLocation) && (!isNew || !isDev)) ||
                  !isSchedule ||
                  !isCompanyInfo,
                ' bg-dark ':
                  ((isCategory && isLocation) || isNew || isDev) &&
                  isSchedule &&
                  isCompanyInfo
              }" [routerLink]="'product-sizes'" [style.color]="colorFont">
              <span class="fa fa-sliders fa-fw text-left mr-3"></span>
              <span class="menu-collapsed">Product Sizes</span>
            </button>
          </div>
          <!-- Image Library -->
          <!-- STEP 6-->
          <div matTooltip="At least one Category needed" [matTooltipDisabled]="
              (isCategory || isNew || isDev) && isSchedule && isCompanyInfo
            " [matTooltipPosition]="'right'">
            <button id="element-item-Library" href="#" class="list-group-item list-group-item-action"
              [class.btn-selected]="isButtonSelected === 'iLibrary'" [class.text-white]="
                isButtonSelected === 'iLibrary' && colorFont === '#000000'
              " (click)="setBtnSelected('iLibrary')" [disabled]="
                ((!isCategory || !isLocation) && (!isNew || !isDev)) ||
                !isLocation ||
                !isSchedule ||
                !isCompanyInfo
              " [ngClass]="{
                disabledButtonFancy:
                  ((!isCategory || !isLocation) && (!isNew || !isDev)) ||
                  !isSchedule ||
                  !isCompanyInfo,
                ' bg-dark ':
                  ((isCategory && isLocation) || isNew || isDev) &&
                  isSchedule &&
                  isCompanyInfo
              }" [routerLink]="'library'" [style.color]="colorFont">
              <span class="fas fa-images fa-fw text-left mr-3"></span>
              <span class="menu-collapsed">Image Library</span>
            </button>
          </div>
          <!-- TODO: Inventory Information  -->
          <!-- NEW STEP 3-->
          <div *ngIf="isNew ">
            <div matTooltip="At least one Product Location is needed" [matTooltipDisabled]="isSchedule && isCompanyInfo"
              [matTooltipPosition]="'right'">
              <button id="element-item-inventory" [disabled]="!isSchedule || !isCompanyInfo"
                class="list-group-item list-group-item-action" [class.btn-selected]="isButtonSelected === 'inventory'"
                [class.text-white]="
                  isButtonSelected === 'inventory' && colorFont === '#000000'
                " (click)="setBtnSelected('inventory')" [ngClass]="{
                  disabledButtonFancy: !isSchedule || !isCompanyInfo,
                  ' bg-dark ': isSchedule && isCompanyInfo
                }" [routerLink]="'inventory'" [style.color]="colorFont">
                <span class="fa fa-shopping-cart fa-fw text-left mr-3"></span>
                <span class="menu-collapsed" style="display: contents">Inventory</span>
              </button>
            </div>
          </div>
          <!-- Options -->

          <!-- TODO: Inventory Information  -->
          <!-- NEW STEP 4-->
          <div *ngIf="isNew ">
            <div matTooltip="At least one Product Location is needed" [matTooltipDisabled]="isSchedule && isCompanyInfo"
              [matTooltipPosition]="'right'">
              <button id="element-item-booking-suite" routerLinkActive="element-item"
                [disabled]="!isSchedule || !isCompanyInfo" class="list-group-item list-group-item-action"
                [class.btn-selected]="isButtonSelected === 'booking-suite'" [class.text-white]="
                  isButtonSelected === 'booking-suite' && colorFont === '#000000'
                " (click)="setBtnSelected('booking-suite')" [ngClass]="{
                  disabledButtonFancy: !isSchedule || !isCompanyInfo,
                  ' bg-dark ': isSchedule && isCompanyInfo
                }" [routerLink]="'booking-suite'" [style.color]="colorFont">
                <span class="fa fa-book fa-fw text-left mr-3"></span>
                <span class="menu-collapsed" style="display: contents">Booking Suite</span>
              </button>
            </div>
          </div>
          <!-- TODO: Inventory Page Builder  -->
          <!-- NEW STEP 4-->
          <div *ngIf="isNew ">
            <div matTooltip="At least one Product Location is needed" [matTooltipDisabled]="isSchedule && isCompanyInfo"
              [matTooltipPosition]="'right'">
              <button id="item-select-inventoryPageBuilder" [disabled]="!isSchedule || !isCompanyInfo"
                class="list-group-item list-group-item-action" [class.btn-selected]="
                  isButtonSelected === 'inventoryPageBuilder'
                " [class.text-white]="
                  isButtonSelected === 'inventoryPageBuilder' &&
                  colorFont === '#000000'
                " (click)="setBtnSelected('inventoryPageBuilder')" [ngClass]="{
                  disabledButtonFancy: !isSchedule || !isCompanyInfo,
                  ' bg-dark ': isSchedule && isCompanyInfo
                }" [routerLink]="'inventoryPageBuilder'" [style.color]="colorFont">
                <span class="fas fa-hammer fa-fw text-left mr-3"></span>
                <span class="menu-collapsed" style="display: contents">Inventory Page Builder</span>
              </button>
            </div>
          </div>
          <!-- Products -->
          <!-- STEP 7-->
          <div *ngIf="!isNew">
            <div matTooltip="At least one Category needed" [matTooltipDisabled]="
                (isCategory) && isSchedule && isCompanyInfo
              " [matTooltipPosition]="'right'">
              <button id="element-item-products" href="#" class="list-group-item list-group-item-action"
                [class.btn-selected]="isButtonSelected === 'products'" [class.text-white]="
                  isButtonSelected === 'products' && colorFont === '#000000'
                " (click)="setBtnSelected('products')" [disabled]="
                  ((!isCategory || !isLocation) ) ||
                  !isSchedule ||
                  !isCompanyInfo
                " [ngClass]="{
                  disabledButtonFancy:
                    (!isCategory || !isLocation) ||
                    !isSchedule ||
                    !isCompanyInfo,
                  ' bg-dark ':
                    (isCategory && isLocation) &&
                    isSchedule &&
                    isCompanyInfo
                }" [routerLink]="'inventory'" [style.color]="colorFont">
                <span class="fas fa-shopping-cart fa-fw text-left mr-3"></span>
                <span class="menu-collapsed" style="display: contents">Products</span>
              </button>
            </div>
          </div>

          <!-- Checkout Options  -->
          <!-- STEP 8-->
          <div *ngIf="!isNew">
            <button id="element-item-Checkout" href="#" class="list-group-item list-group-item-action"
              [class.btn-selected]="isButtonSelected === 'cOptions'" [class.text-white]="
                isButtonSelected === 'cOptions' && colorFont === '#000000'
              " (click)="setBtnSelected('cOptions')" [disabled]="
                !isProduct ||
                (isCategory &&
                  isLocation &&
                  (!isNew || !isDev) &&
                  !isSchedule) ||
                !isCompanyInfo
              " [ngClass]="{
                disabledButtonFancy:
                  !isProduct ||
                  (isCategory &&
                    isLocation &&
                    (!isNew || !isDev) &&
                    !isSchedule) ||
                  !isCompanyInfo,
                ' bg-dark ': isProduct && isCategory && isLocation && isSchedule
              }" [routerLink]="'checkoutOptions'" [style.color]="colorFont">
              <span class="fa fa-gear fa-fw text-left mr-3"></span>
              <span class="menu-collapsed" style="display: contents">Checkout Options</span>
            </button>
          </div>
          <!-- Waiver  -->
          <!-- STEP 9-->
          <div>
            <button id="element-item-wiver" href="#" class="list-group-item list-group-item-action"
              [class.btn-selected]="isButtonSelected === 'waiver'" [class.text-white]="
                isButtonSelected === 'waiver' && colorFont === '#000000'
              " (click)="setBtnSelected('waiver')" [disabled]="
                !isProduct ||
                ((!isCategory || (!isLocation && (!isNew || !isDev))) &&
                  !isSchedule) ||
                !isCompanyInfo
              " [ngClass]="{
                disabledButtonFancy:
                  !isProduct ||
                  (isCategory &&
                    isLocation &&
                    (!isNew || !isDev) &&
                    !isSchedule) ||
                  !isCompanyInfo,
                ' bg-dark ': isProduct && isCategory && isLocation && isSchedule
              }" [style.color]="colorFont" [routerLink]="'waiver'">
              <span class="fas fa-file-signature text-left mr-3"></span>
              <span class="menu-collapsed">Waiver</span>
            </button>
          </div>
          <!-- Affiliate -->
          <!-- STEP 10-->
          <ng-container *ngIf="isNew">
            <div>
              <button id="element-item-wiver" href="#" class="list-group-item list-group-item-action"
                [class.btn-selected]="isButtonSelected === 'affiliate'" [class.text-white]="
                  isButtonSelected === 'affiliate' && colorFont === '#000000'
                " (click)="setBtnSelected('affiliate')" [disabled]="
                  !isProduct ||
                  ((!isCategory || (!isLocation && (!isNew || !isDev))) &&
                    !isSchedule) ||
                  !isCompanyInfo
                " [ngClass]="{
                  disabledButtonFancy:
                    !isProduct ||
                    (isCategory &&
                      isLocation &&
                      (!isNew || !isDev) &&
                      !isSchedule) ||
                    !isCompanyInfo,
                  ' bg-dark ': isProduct && isCategory && isLocation && isSchedule
                }" [style.color]="colorFont" [routerLink]="'affiliate'">
                <span class="fas fa-handshake-o text-left mr-3"></span>
                <span>Affiliate</span>
              </button>
            </div>
          </ng-container>
          <!-- STEP 11-->
          <div>
            <button id="element-item-cupons" href="#" class="list-group-item list-group-item-action"
              [class.btn-selected]="isButtonSelected === 'cCodes'" [class.text-white]="
                isButtonSelected === 'cCodes' && colorFont === '#000000'
              " (click)="setBtnSelected('cCodes')" [disabled]="
                !isProduct ||
                ((!isCategory || (!isLocation && (!isNew || !isDev))) &&
                  !isSchedule) ||
                !isCompanyInfo
              " [ngClass]="{
                disabledButtonFancy:
                  !isProduct ||
                  ((!isCategory || (!isLocation && (!isNew || !isDev))) &&
                    !isSchedule) ||
                  !isCompanyInfo,
                ' bg-dark ': isProduct && isCategory && isLocation && isSchedule
              }" [routerLink]="'discountsCodesGenerator'" [style.color]="colorFont">
              <span class="fas fa-money text-left mr-3"></span>
              <span class="menu-collapsed">Discount Codes</span>
            </button>
          </div>
          <!-- Page Embeds  -->
          <!-- STEP 12-->
          <div>
            <button id="element-item-embedes" href="#" class="list-group-item list-group-item-action"
              [class.btn-selected]="isButtonSelected === 'pEmbeds'" [class.text-white]="
                isButtonSelected === 'pEmbeds' && colorFont === '#000000'
              " (click)="setBtnSelected('pembeds')" [disabled]="
                !isProduct ||
                ((!isCategory || (!isLocation && (!isNew || !isDev))) &&
                  !isSchedule) ||
                !isCompanyInfo
              " [ngClass]="{
                disabledButtonFancy:
                  !isProduct ||
                  ((!isCategory || (!isLocation && (!isNew || !isDev))) &&
                    !isSchedule) ||
                  !isCompanyInfo,
                ' bg-dark ': isProduct && isCategory && isLocation && isSchedule
              }" [routerLink]="'page-embeds'" [style.color]="colorFont">
              <span class="fas fa-code text-left mr-3"></span>
              <span class="menu-collapsed">Page Embeds</span>
            </button>
          </div>
        </div>
      </ul>
    </div>
    <!-- sidebar-container END -->
    <div class="col" style="width: 100%;" [class.overFlowXHiddenStyle]="showOverflowXStyle">
      <!-- MAIN -->
      <!-- <button class="btn btn-success" (click)="runScript()">Run Script</button> -->

      <router-outlet></router-outlet>
    </div>
    <!-- Main Col END -->
  </div>
</div>
<!-- body-row END -->

<div class="modal fade" id="account" tabindex="-1" role="dialog" aria-labelledby="addUserLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-bs-dismiss="modal" aria-bs-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="row">
            <div class="col" *ngIf="currentUser != undefined">
              <!-- FORM with user information -->
              <form class="needs-validation" [formGroup]="userInfo" (ngSubmit)="validateForm()">
                <div class="row">
                  <div class="form-group col-lg-6 col-md-12">
                    <label for="name">First Name</label>
                    <input type="text" class="form-control" id="name" [(ngModel)]="currentUser.firstName"
                      [readonly]="!isEditing" formControlName="firstName" placeholder="Name" required />
                  </div>
                  <div class="invalid-tooltip">
                    Please provide a valid name.
                  </div>
                  <div class="form-group col-lg-6 col-md-12">
                    <label for="lastName">Last Name</label>
                    <input type="text" class="form-control" id="lastName" [(ngModel)]="currentUser.lastName"
                      [readonly]="!isEditing" formControlName="lastName" placeholder="Last Name" required />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-lg-6 col-md-12">
                    <label for="email">Email</label>
                    <input type="email" class="form-control" id="email" [(ngModel)]="currentUser.email"
                      [readonly]="!isEditing" formControlName="email" placeholder="Email" required />
                  </div>
                  <!-- Password field -->
                  <div class="form-group col-lg-6 col-md-12">
                    <label for="password">Password</label>
                    <input type="password" class="form-control" id="password" [(ngModel)]="currentUser.password"
                      [readonly]="!isEditing" formControlName="password" placeholder="*******" />
                  </div>
                  <div class="form-group col-lg-6 col-md-12">
                    <label for="phone">Phone</label>
                    <input type="text" class="form-control" id="phone" [(ngModel)]="currentUser.phone"
                      [readonly]="!isEditing" formControlName="phone" placeholder="Phone" required />
                  </div>
                  <div class="form-group col-lg-6 col-md-12">
                    <label for="levelType">Level type</label>
                    <select class="form-select" id="levelType" [(ngModel)]="currentUser.levelType"
                      formControlName="levelType">
                      <option value="Partner">Partner</option>
                      <option value="Employee">Employee</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <!--Logo-->
                  <div class="form-group col-lg-6 col-md-12" *ngIf="isEditing">
                    <label for="icon">Icon</label>
                    <br />
                    <p-dropdown id="icon" [options]="icons" [(ngModel)]="currentUser.icon" optionLabel="name"
                      formControlName="icon" [panelStyleClass]="'form-control'">
                      <ng-template let-icon pTemplate="selectedItem">
                        <div class="icon-item icon-item-value" *ngIf="icon">
                          <img src="{{ icon.code }}" alt="" [class]="'flag flag-' + icon.name.toLowerCase()" />
                          <div>{{ icon.name }}</div>
                        </div>
                      </ng-template>
                      <ng-template let-icon pTemplate="item">
                        <div class="icon-item">
                          <img src="{{ icon.code }}" alt="" [class]="'flag flag-' + icon.name.toLowerCase()" />
                          <div>{{ icon.name }}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>

                  <div class="from-group col-lg-6 col-md-12 mt-2" *ngIf="isEditing">
                    <label for="color">Color theme</label>

                    <p-dropdown id="color" [options]="ColorThemeservice.colors" [(ngModel)]="currentUser.color"
                      optionLabel="name" (onChange)="colorDinamic($event)" formControlName="color"
                      [panelStyleClass]="'form-control'">
                      <ng-template let-color pTemplate="selectedItem">
                        <option class="color-item color-item-value" [style.background]="color.value">
                          {{ color.name }}
                        </option>
                      </ng-template>
                      <ng-template let-color pTemplate="item">
                        <option class="color-item" [style.background]="color.value">
                          {{ color.name }}
                        </option>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>

                <!-- submit and cancel buttons align right -->
                <div class="row mt-1" *ngIf="isEditing">
                  <div class="col">
                    <button type="submit" class="btn btn-primary float-end">
                      Save
                    </button>
                    <!-- close dialog-->
                    <button type="button" class="btn btn-secondary float-end me-2" data-bs-dismiss="modal">
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
              <div class="row" *ngIf="!isEditing">
                <div class="col-3">
                  <button type="button" [disabled]="!currentUser.isActive" class="btn btn-primary"
                    (click)="enableEdition()">
                    Edit
                  </button>
                </div>
              </div>
            </div>

            <!-- Button to enable edition -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="confirmUpdateP" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 470px !important">
      <div class="modal-body">
        <div style="padding: 5px"></div>
        <div class="row d-flex justify-content-center textSize25 text-center">
          <h3>
            <b> Version update required: </b>
            <b class="versionText"> v{{ newVersion }} </b>
          </h3>
        </div>
        <div style="padding: 10px"></div>

        <div class="row d-flex justify-content-center" style="padding: 15px !important">
          <div id="buttonsFancy">
            <a href="javascript:void(0)" class="btnFancy redButtonFancy" (click)="remindMe()">Remind me in 15 min</a>
            <a href="javascript:void(0)" class="btnFancy blueButtonFancy" (click)="confirm()">Update Now</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
